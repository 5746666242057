import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

// GET ALL PAYMENT METHOD
export const getPaymentMethods = createAsyncThunk('payments/getPaymentMethods', async (payload, thunkAPI) => {
  let mainUrl = `${BASE_URL}/${API_VERSION}/payments`;

  if (payload) {
    mainUrl += `?total_payment=${payload}`;
  }

  try {
    const response = await axios.get(mainUrl);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

// to update payment type from HALF to FULL

export const updatePaymentType = createAsyncThunk('payments/updatePaymentType', async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/payments`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});
