import { createSlice } from '@reduxjs/toolkit';
import { getAllCategories, getCategoryById, getVehicleByCategory, getSearchVehicleByCategory } from './actions';

const initialState = {
  data: {},
  selected: {},
  vehicles: {},
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  isError: false,
  errorMessage: {},
};

export const category = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategories.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(getCategoryById.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = {};
        state.selected = {};
      })
      .addCase(getCategoryById.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getCategoryById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selected = action.payload;
      })
      .addCase(getVehicleByCategory.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = {};
        state.vehicles = {};
      })
      .addCase(getVehicleByCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getVehicleByCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.vehicles = action.payload;
      })
      .addCase(getSearchVehicleByCategory.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = {};
        state.vehicles = {};
      })
      .addCase(getSearchVehicleByCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getSearchVehicleByCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.vehicles = action.payload;
      });
  },
});

export const getCategory = (state) => state.category.data;
export const getCategoryStatus = (state) => state.category.status;

export default category.reducer;
