import React from 'react';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

const ToastWrapper = ({ onClick, children, id, ...rest }) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition
      nodeRef={nodeRef}
      timeout={200}
      classNames={{
        enter: 'toast-enter',
        enterActive: 'toast-enter-active',
        exit: 'toast-exit',
        exitActive: 'toast-exit-active',
      }}
      {...rest}
    >
      <div data-id={id} ref={nodeRef} onClick={onClick}>
        {children}
      </div>
    </CSSTransition>
  );
};

export default ToastWrapper;
