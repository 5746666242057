import React from 'react';
import clsx from 'clsx';

const PaginationTable = ({ currentPage, totalCount, onPageChange }) => {
  const pagerCount = 5;
  const halfPagerCount = (pagerCount - 1) / 2;
  const pagerCountOffset = pagerCount - 2;
  const pageCount = Math.ceil(totalCount / 10);

  let showPrevMore = false;
  let showNextMore = false;

  if (pageCount > pagerCount) {
    if (currentPage > pagerCount - halfPagerCount) {
      showPrevMore = true;
    }

    if (currentPage < pageCount - halfPagerCount) {
      showNextMore = true;
    }
  }

  const pagesLinks = [];

  if (showPrevMore && !showNextMore) {
    const startPage = pageCount - (pagerCount - 2);
    for (let i = startPage; i < pageCount; i += 1) {
      pagesLinks.push(i);
    }
  } else if (!showPrevMore && showNextMore) {
    for (let i = 2; i < pagerCount; i += 1) {
      pagesLinks.push(i);
    }
  } else if (showPrevMore && showNextMore) {
    const offset = Math.floor(pagerCount / 2) - 1;
    for (let i = currentPage - offset; i <= currentPage + offset; i += 1) {
      pagesLinks.push(i);
    }
  } else {
    for (let i = 2; i < pageCount; i += 1) {
      pagesLinks.push(i);
    }
  }

  const handlePrevMoreClick = (offset) => {
    const newPage = currentPage - offset;
    onPageChange(newPage < 1 ? 1 : newPage);
  };

  const handleNextMoreClick = (offset) => {
    const newPage = currentPage + offset;
    onPageChange(newPage > pageCount ? pageCount : newPage);
  };

  return pageCount > 1 ? (
    <div className="table-footer">
      <button onClick={() => onPageChange(1)} className={clsx('btn-page', currentPage === 1 && 'active')}>
        1
      </button>
      {showPrevMore && (
        <button onClick={() => handlePrevMoreClick(pagerCountOffset)} className="btn-page">
          ...
        </button>
      )}

      {pagesLinks.map((pageLink, idx) => (
        <button
          key={idx}
          className={clsx('btn-page', currentPage === pageLink && 'active')}
          onClick={() => onPageChange(pageLink)}
        >
          {pageLink}
        </button>
      ))}

      {showNextMore && (
        <button onClick={() => handleNextMoreClick(pagerCountOffset)} className="btn-page">
          ...
        </button>
      )}
      <button
        onClick={() => onPageChange(pageCount)}
        className={clsx('btn-page', currentPage === pageCount && 'active')}
      >
        {pageCount}
      </button>
    </div>
  ) : null;
};

export default PaginationTable;
