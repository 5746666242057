import React from 'react';
import RolesList from './RolesList';

const RolesComponent = () => {
  return (
    <div className="roles">
      <RolesList />
    </div>
  );
};

export default RolesComponent;
