import React from 'react';

const Thumbnail = ({ image, id, onClick }) => {
  return (
    <>
      <div className="thumbnail" onClick={onClick}>
        <img src={image} alt="thumbnail" data-id={id} />
      </div>
    </>
  );
};

export default Thumbnail;
