import clsx from 'clsx';
import React, { useState } from 'react';
import { ReactComponent as ArrowDown } from '../../../icons/arrow-down-icon.svg';
import countryCodes from '../../../utils/country-codes.json';
import { useRef } from 'react';

const InputTelpField = ({
  label,
  placeholder,
  value, // state
  onChange, // setState
  className,
  style,
  htmlFor,
  required,
  disable,
  countryCodeValue,
  onSelectCountry,
  onWheel = () => {},
  ...restProps
}) => {
  const codeComponentRef = useRef();

  // SHOW DROPDOWN STATE
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  // CHOOSE COUNTRY FUNCTION
  const handleChoose = (code) => {
    onSelectCountry(code);
    setTimeout(() => {
      setIsShowDropdown(false);
      codeComponentRef?.current.blur();
    }, 200);
  };

  return (
    <div className={clsx(className, 'input-telp-field')} style={style}>
      {label && (
        <label htmlFor={htmlFor} className="input-telp-field__label">
          {label}
        </label>
      )}
      <div className="input-telp-field__field" style={{ width: '100%' }}>
        <div
          className={
            disable
              ? 'input-telp-field__field__telp-country-code disable'
              : 'input-telp-field__field__telp-country-code'
          }
          style={{ width: '120px' }}
          tabIndex={0}
          onFocus={() => setIsShowDropdown(true)}
          onBlur={() => setIsShowDropdown(false)}
          ref={codeComponentRef}
        >
          <span>{countryCodeValue}</span>
          <ArrowDown />
          <img
            className="input-telp-field__field__telp-country-code__image"
            src={`https://flagcdn.com/w2560/${countryCodes
              .find((item) => item.dial_code == countryCodeValue)
              .code.toLowerCase()}.png`}
            alt={`${countryCodes.find((item) => item.dial_code == countryCodeValue).name} nation flag`}
          />

          {/* DROPDOWN FLAG */}
          {isShowDropdown && (
            <ul className="input-telp-field__field__telp-country-code__dropdown-code">
              {countryCodes.map((item) => (
                <li
                  key={item.code}
                  onClick={() => handleChoose(item.dial_code)}
                  className={countryCodeValue === item.dial_code ? 'choosed' : ''}
                >
                  <img
                    src={`https://flagcdn.com/w2560/${item.code.toLowerCase()}.png`}
                    alt={`${item.name} nation flag`}
                    loading="lazy"
                  />
                  <span>
                    <p>{item.dial_code}</p>
                    <p>{`(${item.code})`}</p>
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>

        <input
          id={htmlFor}
          type="number"
          value={value}
          onChange={onChange}
          disabled={disable}
          placeholder={placeholder}
          {...restProps}
          required={required}
          className={disable ? 'input-telp-field__field__telp-number disable' : 'input-telp-field__field__telp-number'}
          onWheel={onWheel}
        />
      </div>
    </div>
  );
};

export default InputTelpField;
