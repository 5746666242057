import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createNewVehicle, editVehicle, getVehicleById } from 'features/vehicle/action';
import { useAppContext } from 'components/Context/AppContext';
import ContentLeft from './ContentLeft';
import Contentright from './Contentright';
import { deleteImageVehicle, resetSelectedVehicle } from 'features/vehicle/vehicleSlice';
import { getAllCategories } from 'features/category/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { checkEmptyObject } from 'utils/functionality';
import { getAllBrands } from 'features/brands/actions';
import { getAllGarages } from 'features/garages/actions';

// initial data
const initialValue = {
  id: '',
  name: '',
  year: '',
  price: 0,
  slash_price: 0,
  price_with_driver: 0,
  license_number: '',
  max_passanger: '',
  min_suitcase: '',
  max_suitcase: '',
  disability_allowed: false,
  smoke_allowed: false,
  pet_allowed: false,
  status: 'available',
  support_driver: false,
  required_driver: false,
  transmission: 'manual',
  brand_vehicles: 0,
  garage_vehicles: 0,
  photo: [],
  category_vehicles: 0,
};

const VehicleDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: vehicleId } = useParams();

  const isAddVehicleState = location.pathname.includes('add-vehicle');

  // global state
  const selectedVehicle = useSelector((state) => state.vehicle?.selected);
  const { isLoading, isError, errorMessage } = useSelector((state) => state.vehicle);

  // context
  const { setShowConfirmation, showToast, setShowSpinner } = useAppContext();

  // data updated car
  const [dataCar, setDataCar] = useState(initialValue);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // driver availibility list
  const [withoutDriver, setWithoutDriver] = useState(false);
  const [withDriver, setWithDriver] = useState(false);

  // destructured the data
  const {
    id,
    name,
    year,
    price,
    slash_price,
    price_with_driver,
    license_number,
    max_passanger,
    min_suitcase,
    max_suitcase,
    disability_allowed,
    smoke_allowed,
    pet_allowed,
    status,
    support_driver,
    required_driver,
    transmission,
    brand_vehicles,
    garage_vehicles,
    photo,
    category_vehicles,
  } = dataCar;

  // delete image vehicle
  const handleRemoveImageVehicle = (image) => {
    const updatedPhotos =
      selectedVehicle.photo &&
      selectedVehicle.photo.filter((item) => {
        if (typeof item === 'string') {
          return item !== image;
        } else {
          return item.name !== image;
        }
      });

    // console.log(updatedPhotos);
    dispatch(deleteImageVehicle(updatedPhotos));
  };

  // function sendPayload action
  const sendPayloadData = async (action, payload, fulfilledMessage, errorMessage1, errorMessage2) => {
    try {
      await dispatch(action === 'ADD' ? createNewVehicle(payload) : editVehicle({ payload, id })).unwrap();
      setShowSpinner(true);

      showToast({ type: 'success', message: fulfilledMessage });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: `${errorMessage2}: ${errorMessage1}-${error}` });
    } finally {
      navigate('/vehicles');
      setShowSpinner(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    // payload
    const payload = {
      name,
      year: +year,
      price: +price,
      slash_price: +slash_price,
      price_with_driver: +price_with_driver,
      license_number: license_number.toLocaleUpperCase(),
      max_passanger: +max_passanger,
      min_suitcase: +min_suitcase,
      max_suitcase: +max_suitcase,
      disability_allowed,
      smoke_allowed,
      pet_allowed,
      status,
      support_driver,
      required_driver,
      transmission,
      brand_vehicles: +brand_vehicles,
      garage_vehicles: +garage_vehicles,
      photo: photo.map((item) => (typeof item === 'string' ? item : item.name)),
      category_vehicles: +category_vehicles,
    };

    // loop through payload to check if there were invalid form
    for (let prop in payload) {
      if (!payload[prop]) {
        if (prop === 'brand_vehicles') {
          return showToast({ type: 'error', message: 'Merk mobil tidak boleh kosong' });
        } else if (prop === 'name') {
          return showToast({ type: 'error', message: 'Nama mobil tidak boleh kosong' });
        } else if (prop === 'year') {
          return showToast({ type: 'error', message: 'Tahun mobil tidak boleh Kosong' });
        } else if (prop === 'category_vehicles') {
          return showToast({ type: 'error', message: 'Pilih Kategori Mobil' });
        } else if (prop === 'price') {
          if (withoutDriver) {
            return showToast({ type: 'error', message: 'Harga Mobil Tanpa Supir tidak boleh Kosong' });
          } else {
            continue;
          }
        } else if (prop === 'price_with_driver') {
          if (withDriver) {
            return showToast({ type: 'error', message: 'Harga Mobil Dengan Supir tidak boleh Kosong' });
          } else {
            continue;
          }
        } else if (prop === 'max_passanger') {
          return showToast({ type: 'error', message: 'Jumlah kursi tidak boleh Kosong' });
        } else if (prop === 'max_suitcase') {
          return showToast({ type: 'error', message: 'Maksimal koper tidak boleh Kosong' });
        } else if (prop === 'garage_vehicles') {
          return showToast({ type: 'error', message: 'Garasi tidak boleh Kosong' });
        }
      }
    }

    if (!withoutDriver && !withDriver) {
      showToast({ type: 'error', message: 'Harga Mobil tidak boleh Kosong' });
      return;
    }

    // check if license number is valid
    const regexLicenseNumber = /(^([A-Z]{2}|[A-Z])\s(\d+)\s(\w{1}|\w{2}|\w{3})$)/gi;
    if (!license_number.match(regexLicenseNumber)) {
      return showToast({ type: 'error', message: 'Plat nomor tidak valid' });
    }

    // check if Add
    if (isAddVehicleState) {
      sendPayloadData('ADD', payload, 'Berhasil Menambahkan Mobil', 'Gagal Menambahkan Mobil', 'Terjadi Kesalahan');
      return;
    }

    // if update
    setShowConfirmation({
      message: 'Apakah anda yakin ingin mengubah data Mobil',
      show: true,
      onClick: () =>
        sendPayloadData(
          'UPDATE',
          payload,
          'Berhasil Update Data Mobil',
          'Gagal Mengupdate Data Mobil',
          'Terjadi Kesalahan',
        ),
    });
  };

  // Spinner show
  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else setShowSpinner(false);
  }, [isLoading]);

  // Error Handling
  useEffect(() => {
    if (!isError) return;

    if (Object.prototype.hasOwnProperty.call(errorMessage, 'slug')) {
      showToast({ type: 'error', message: errorMessage.slug });
    } else {
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }
  }, [isError, errorMessage]);

  // set data car with selected vehicle when showVehicleDetail true
  useEffect(() => {
    if (!isAddVehicleState && !checkEmptyObject(selectedVehicle)) {
      setDataCar({ ...selectedVehicle, category_vehicles: selectedVehicle.category?.id });
      return;
    }

    setDataCar({ ...dataCar, photo: selectedVehicle?.photo === undefined ? [] : selectedVehicle?.photo });
  }, [selectedVehicle]);

  // reset selected vehicle when showVehicledetail false
  useEffect(() => {
    if (isAddVehicleState && !checkEmptyObject(selectedVehicle)) {
      dispatch(resetSelectedVehicle());
    }
  }, []);

  // set inital value driver availibility
  useEffect(() => {
    if (!isFirstLoad) return;

    if (isAddVehicleState) return;

    if (dataCar.support_driver && dataCar.required_driver) {
      setWithDriver(true);
      setWithoutDriver(false);
      return;
    }

    if (!dataCar.support_driver && !dataCar.required_driver) {
      setWithDriver(false);
      setWithoutDriver(true);
      return;
    }

    if (dataCar.support_driver && !dataCar.required_driver) {
      setWithDriver(true);
      setWithoutDriver(true);
      return;
    }
  }, [dataCar.support_driver, dataCar.required_driver]);

  // get all necessary data
  useEffect(() => {
    if (!isAddVehicleState) {
      dispatch(getVehicleById(vehicleId));
    }
    dispatch(getAllBrands());
    dispatch(getAllGarages());
    dispatch(getAllCategories({ page: 1 }));
  }, []);

  return (
    <div className="vehicle-detail__component">
      <header className="vehicle-detail__header" onClick={() => navigate(-1)}>
        <LeftArrow />
        <p>Kembali</p>
      </header>
      <form className="vehicle-detail__content" onSubmit={submitHandler}>
        <ContentLeft
          dataCar={dataCar}
          setDataCar={setDataCar}
          isFirstLoad={isFirstLoad}
          setIsFirstLoad={setIsFirstLoad}
          withoutDriver={withoutDriver}
          setWithoutDriver={setWithoutDriver}
          withDriver={withDriver}
          setWithDriver={setWithDriver}
        />
        <Contentright imageFiles={dataCar.photo} handleRemove={handleRemoveImageVehicle} />
      </form>
    </div>
  );
};

export default VehicleDetailPage;
