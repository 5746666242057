import { createSlice } from '@reduxjs/toolkit';

import { downloadOrderDetail } from './actions';

const downloadOrderDetailSlice = createSlice({
  name: 'download',
  initialState: {
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadOrderDetail.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(downloadOrderDetail.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(downloadOrderDetail.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default downloadOrderDetailSlice.reducer;
