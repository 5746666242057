import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import CardDashboardItem from 'components/Global/Card/CardDashboardItem';
import { useSelector } from 'react-redux';

ChartJS.register(...registerables);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 8,
          weight: 600,
        },
      },
      border: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      max: 200,
      min: 0,
      ticks: {
        stepSize: 50,
        font: {
          size: 8,
          weight: 600,
        },
      },
      border: {
        display: false,
      },
    },
  },
};

const UserTransactions = () => {
  const { data: trxMonthData } = useSelector((state) => state.trxMonthToMonth);

  const data = {
    labels: trxMonthData.map((item) => item.month.toUpperCase().slice(0, 3)),
    datasets: [
      {
        data: trxMonthData.map((item) => item.total),
        pointBorderWidth: 0,
        lineTension: 0.4,
        borderWidth: 5.79,
        borderCapStyle: 'round',
        borderColor: function (context) {
          const { ctx } = context.chart;

          const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
          gradientStroke.addColorStop(1, '#bbe6ff');
          gradientStroke.addColorStop(0, '#009EF7');

          ctx.strokeStyle = gradientStroke;
        },
      },
    ],
  };

  return (
    <CardDashboardItem title="Transaksi User" separator asChild>
      {trxMonthData && <Line options={options} data={data} />}
    </CardDashboardItem>
  );
};

export default UserTransactions;
