import { InputField } from 'components/Global';
import Modals from 'components/Modals/Modals';
import React, { useEffect, useState } from 'react';
import { ReactComponent as DPFilledIcon } from 'icons/dp-filled-icon.svg';
import { checkEmptyObject, setCurrency } from 'utils/functionality';

const PriceSimulationModal = ({
  selectedFormula,
  formulaValue,
  setShowPriceSimulationModal,
  numberInputOnWheelPreventChange,
}) => {
  const [data, setData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (checkEmptyObject(selectedFormula)) return;
    setData(
      selectedFormula.map((item) =>
        item.name.toLowerCase() === 'percentage' ? { ...item, value: formulaValue } : { ...item, value: 0 },
      ),
    );
  }, [selectedFormula]);

  const onChangeHandler = (e) => {
    const { value, id } = e.target;
    const selected = id.split('-')[0];
    setData((prev) => prev.map((item) => (item.id == selected ? { ...item, value: +value } : item)));
  };

  useEffect(() => {
    if (!data.length) return;

    const timeout = setTimeout(() => {
      const total = data
        .filter((item) => item.name.toLowerCase() !== 'percentage')
        .reduce((acc, cur) => acc + cur.value, 0);
      setTotalPrice(() => (formulaValue ? total * (formulaValue / 100) : total));
    }, 1000);

    return () => clearTimeout(timeout);
  }, [data]);

  return (
    <Modals
      setState={setShowPriceSimulationModal}
      icon={<DPFilledIcon fill="#009EF7" width="25px" height="25px" />}
      title="Simulasi DP"
    >
      <div className="price-simulation-modal">
        <div className="price-simulation__content">
          {data?.map((item) => (
            <div className="price-simulation__formula" key={item.id}>
              <h5>{item.name}</h5>
              <InputField
                placeholder="0"
                type="number"
                value={item.value || ''}
                onChange={onChangeHandler}
                htmlFor={`${item.id}-simulate-${item.name}`}
                min={0}
                onWheel={numberInputOnWheelPreventChange}
                disable={item.name.toLowerCase() === 'percentage' || item.name.toLowerCase() === 'no dp'}
              />
            </div>
          ))}
        </div>
        <div className="price-simulation__result">
          <h5>Harga DP</h5>
          <InputField disable placeholder="0" value={setCurrency(totalPrice)} />
        </div>
      </div>
    </Modals>
  );
};

export default PriceSimulationModal;
