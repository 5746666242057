import { createSlice } from '@reduxjs/toolkit';
import { getAllGarages, getGarageAdditionalSettings, getGarageDetail, updateGarages } from './actions';

const initialState = {
  data: [],
  garageDetail: {},
  garageAdditionalSetting: [],
  isLoading: false,
  isError: false,
  errorMessage: {}, // kalo retry setelah error, harus hapus errorMessage dan isError!
  isUpdateSuccess: false,
};

export const garages = createSlice({
  name: 'garages',
  initialState,
  reducers: {
    // reducers goes here
    resetGarageDetail: (state) => {
      state.garageDetail = {};
    },
    resetGarageAdditionalSetting: (state) => {
      state.garageAdditionalSetting = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGarages.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = [];
        state.isUpdateSuccess = false;
      })
      .addCase(getAllGarages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllGarages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateGarages.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.isUpdateSuccess = false;
      })
      .addCase(updateGarages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateGarages.fulfilled, (state) => {
        state.isLoading = false;
        state.isUpdateSuccess = true;
      })
      .addCase(getGarageDetail.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.garageDetail = {};
      })
      .addCase(getGarageDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getGarageDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.garageDetail = action.payload;
      })
      .addCase(getGarageAdditionalSettings.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.garageAdditionalSetting = [];
      })
      .addCase(getGarageAdditionalSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getGarageAdditionalSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.garageAdditionalSetting = action.payload;
      });
  },
});

export const { resetGarageDetail, resetGarageAdditionalSetting } = garages.actions;
export const selectAllGarages = (state) => state.garages.data;
export const getGaragesStatus = (state) => state.garages.isLoading;

export default garages.reducer;
