import { createSlice } from '@reduxjs/toolkit';
import { getEligibleVehicle } from './action';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const eligibleVehicle = createSlice({
  name: 'eligibleVehicle',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEligibleVehicle.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getEligibleVehicle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getEligibleVehicle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});

export default eligibleVehicle.reducer;
