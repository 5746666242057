import { useAppContext } from 'components/Context/AppContext';
import { logout } from 'features/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import OffCanvasMenu from './offcanvas-menu';
import React, { useEffect, useState } from 'react';
import { useMapAdminPermissions } from 'utils/useMapAdminPermissions';
import { resetSelectedMenu, setOffCanvasMenu } from 'features/menu/menuSlice';

const items = [
  {
    header: 'Admin Dashboard',
    sub_items: [
      {
        name: 'Dashboard',
        url: '/',
        icon: '/assets/icon/dashboard-icon.svg',
      },
      {
        name: 'Task Admin',
        url: '/task-admin',
        icon: '/assets/icon/task-admin-icon.svg',
      },
    ],
  },
  {
    header: 'Transaction',
    sub_items: [
      {
        name: 'Lepas Kunci',
        url: '/without-driver?status=ALL&locationId=',
        icon: '/assets/icon/without-driver-icon.svg',
      },
      {
        name: 'Dengan Supir',
        url: '/with-driver?status=ALL&locationId=',
        icon: '/assets/icon/with-driver-icon.svg',
      },
      {
        name: 'Airport Transfer',
        url: '/airport-transfer?status=ALL&locationId=',
        icon: '/assets/icon/airport-icon.svg',
      },
    ],
  },
  {
    header: 'Customer Area',
    sub_items: [
      {
        name: 'User Customer',
        url: '/user-customer',
        icon: '/assets/icon/user-tab.svg',
      },
      {
        name: 'User Influencer',
        url: '/user-influencer',
        icon: '/assets/icon/user-influencer-icon.svg',
      },
    ],
  },
  {
    header: 'System Management',
    sub_items: [
      {
        name: 'Notifikasi',
        url: '/notification',
        icon: '/assets/icon/notif-icon.svg',
      },
      {
        name: 'Role',
        url: '/roles',
        icon: '/assets/icon/role-icon.svg',
      },
      {
        name: 'Assign Role',
        url: '/assign-roles',
        icon: '/assets/icon/assign-role-icon.svg',
      },
      {
        name: 'Banner',
        url: '/banner',
        icon: '/assets/icon/banner-icon.svg',
      },
      {
        name: 'About Us',
        url: '/selling-point',
        icon: '/assets/icon/about-us-icon.svg',
      },
      {
        name: 'Hubungi Kami',
        url: '/contact-us',
        icon: '/assets/icon/contact-icon.svg',
      },
    ],
  },
  {
    header: 'Master Data',
    sub_items: [
      {
        name: 'Kendaraan',
        url: '/vehicles',
        icon: '/assets/icon/vehicle-tab.svg',
      },
      {
        name: 'Merk Mobil',
        url: '/car-brands',
        icon: '/assets/icon/brands-icon.svg',
      },
      {
        name: 'Airport Transfer Car',
        url: '/airport-transfer-car',
        icon: '/assets/icon/airport-transfer-car-icon.svg',
      },
      {
        name: 'Kategori Mobil',
        url: '/category',
        icon: '/assets/icon/category-icon.svg',
      },
      {
        name: 'Daerah Sewa',
        url: '/rental-area',
        icon: '/assets/icon/location-icon.svg',
      },
      {
        name: 'Pengantaran dan Pengembalian',
        url: '/return-and-delivery',
        icon: '/assets/icon/delivery-icon.svg',
      },
      {
        name: 'Garasi',
        url: '/garages',
        icon: '/assets/icon/garage-icon.svg',
      },
      {
        name: 'Deposit',
        url: '/deposit',
        icon: '/assets/icon/deposit-icon.svg',
      },
      {
        name: 'Bahasa',
        url: '/language',
        icon: '/assets/icon/language-icon.svg',
      },
      {
        name: 'Additional',
        url: '/additional',
        icon: '/assets/icon/additional-icon.svg',
      },
    ],
  },
  {
    header: 'Configuration',
    sub_items: [
      {
        name: 'Setting DP',
        url: '/setting-dp',
        icon: '/assets/icon/down-payment-icon.svg',
      },
      {
        name: 'Zona',
        url: '/zone',
        icon: '/assets/icon/zone-icon.svg',
      },
      {
        name: 'Syarat & Ketentuan',
        url: '/term-and-condition',
        icon: '/assets/icon/term-and-condition-icon.svg',
      },
      {
        name: 'Overtime',
        url: '/overtime',
        icon: '/assets/icon/overtime-icon.svg',
      },
      {
        name: 'Aturan Overtime',
        url: '/overtime-rule',
        icon: '/assets/icon/overtime-rule-icon.svg',
      },
      {
        name: 'Minimal Rental',
        url: '/min-rent',
        icon: '/assets/icon/min-rent-icon.svg',
      },
    ],
  },
  {
    header: 'Promotion',
    sub_items: [
      {
        name: 'Promo',
        url: '/promo',
        icon: '/assets/icon/promo-icon.svg',
      },
      // {
      //   name: 'Partner Bisnis',
      //   url: '/business-partner',
      //   icon: '/assets/icon/business-partner-icon.svg',
      // },
      // {
      //   name: 'Voucher',
      //   url: '/voucher',
      //   icon: '/assets/icon/voucher-icon.svg',
      // },
      {
        name: 'Schedule Price',
        url: '/schedule-price',
        icon: '/assets/icon/schedule-price-icon.svg',
      },
      {
        name: 'Schedule Price Airport',
        url: '/schedule-price-airport',
        icon: '/assets/icon/schedule-price-airport-icon.svg',
      },
    ],
  },
];

const SidebarHomepage = ({ selectedPage, setSelectedPage }) => {
  const { setShowSpinner, showToast } = useAppContext();
  const dispatch = useDispatch();
  const selectedMenu = useSelector((state) => state.menu.data);
  const mappedMenuList = useMapAdminPermissions(items, selectedMenu);

  const [show, setShow] = useState(true);

  useEffect(() => {
    const getSubItems = () => {
      let tempArr = [];
      [...mappedMenuList].map((item) => item.sub_items.map((itm) => tempArr.push(itm)));
      return tempArr;
    };
    dispatch(setOffCanvasMenu(getSubItems()));
  }, [mappedMenuList]);

  const toggleSidebar = () => {
    setShow(!show);
  };

  const logoutHandler = async () => {
    try {
      setShowSpinner(true);
      await dispatch(logout()).unwrap();
      dispatch(resetSelectedMenu());
    } catch (error) {
      showToast({ type: 'error', message: 'Fail to Logout' });
    } finally {
      setShowSpinner(false);
    }
  };

  return (
    <div className="offcanvas" style={{ width: show ? '287px' : '80px' }}>
      <header className="offcanvas-header" style={{ width: show ? '287px' : '80px' }}>
        <div className="header-logo" style={{ opacity: show ? '1' : '0', left: show ? '0' : '-400px' }}>
          <img src="/assets/icon/logo-icon.svg" alt="logo" />
          <h1>Get & Ride</h1>
        </div>
        <img
          src="/assets/icon/arrow-left-icon.svg"
          alt="arrow icon"
          onClick={toggleSidebar}
          style={{ left: show ? '250px' : '25px' }}
          className={`arrow-icon ${show ? '' : 'collapse'}`}
        />
      </header>

      <nav className="offcanvas-body no-scrollbar" style={{ width: show ? '287px' : '80px' }}>
        <ul className="list">
          {mappedMenuList?.map((item, i) => (
            <OffCanvasMenu
              key={i}
              menu={item}
              show={show}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          ))}
        </ul>
        <div
          className="list-item logout"
          onClick={logoutHandler}
          style={{
            padding: show ? '12px 24px' : '12px 20px',
            width: show ? '287px' : '80px',
            justifyContent: show ? 'normal' : 'center',
          }}
        >
          <figure
            className="icon"
            style={{
              marginRight: show ? '18px' : '0',
            }}
          >
            <img src="/assets/icon/logout-icon.svg" alt="logout icon" />
          </figure>
          <p style={{ display: show ? 'flex' : 'none' }}>Log out</p>
        </div>
      </nav>
    </div>
  );
};

export default React.memo(SidebarHomepage);
