import { useAppContext } from 'components/Context/AppContext';
import { Button } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import Modals from '../Modals';
import { deleteCity } from 'features/cities/actions';
import { resetSelectCity } from 'features/cities/slice';

const DeleteCityConfirmation = () => {
  const { setShowCityDeleteConfirmation, showToast } = useAppContext();
  const selectedCityId = useSelector((state) => state.cities.selected);

  const dispatch = useDispatch();

  const onConfirm = async () => {
    try {
      const res = await dispatch(deleteCity(selectedCityId));

      if (res?.type.includes('fulfilled')) {
        showToast({ type: 'success', message: 'Berhasil Hapus Kota' });
        return;
      }

      showToast({ type: 'error', message: 'Gagal Hapus Kota' });
    } catch (error) {
      showToast({ type: 'error', message: error });
    } finally {
      setShowCityDeleteConfirmation(false);
      dispatch(resetSelectCity());
    }
  };

  return (
    <Modals setState={setShowCityDeleteConfirmation}>
      <div className="delete-confirmation">
        <p className="delete-confirmation__desc">Apakah anda yakin ingin menghapus kota?</p>
        <div className="delete-confirmation__btn-group">
          <Button variant="outline" wfull onClick={() => setShowCityDeleteConfirmation(false)}>
            Kembali
          </Button>
          <Button variant="primary" wfull onClick={onConfirm}>
            Iya, Lanjutkan
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default DeleteCityConfirmation;
