import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as FileIcon } from 'icons/file-icon.svg';

const UploadVariationImage = ({ uploadImageAction, id }) => {
  const uploadImageRef = useRef(null);

  const [dragActive, setDragActive] = useState(false);

  const uploadImage = (files) => {
    for (let image in files) {
      uploadImageAction(files[image]);
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files) {
      // eslint-disable-next-line no-unused-vars
      const { length, ...newImages } = e.dataTransfer.files;
      // check if there are files
      if (newImages) {
        uploadImage(newImages);
      }
    }
  };

  const onImageChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // eslint-disable-next-line no-unused-vars
    const { length, ...newImages } = e.target.files;
    // check if there are files
    if (newImages) {
      uploadImage(newImages);
    }
  };

  return (
    <div
      className="upload-image"
      onDragEnter={handleDrag}
      onDrop={handleDrop}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
    >
      <input
        id={id}
        type="file"
        name="upload-image-custom-order"
        accept="image/*"
        hidden
        multiple
        ref={uploadImageRef}
        onChange={onImageChange}
      />
      <div className={clsx('wrapper', dragActive ? 'active' : '')}>
        <FileIcon className="icon" />
        <p>
          Drop your image here, or{' '}
          <span role="button" onClick={() => uploadImageRef.current.click()}>
            browse
          </span>
        </p>
      </div>
    </div>
  );
};

export default UploadVariationImage;
