import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { setCurrency } from 'utils/functionality';

const baseUrl = process.env.REACT_APP_IMAGES;

const SelectFieldAirportPackage = ({
  id,
  label,
  className,
  style,
  disable,
  placeholder,
  data,
  selectedCar,
  onSelectCar,
}) => {
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  // const [isChoosed, setIsChoosed] = useState(false);
  // const [choosedVehicle, setChoosedVehicle] = useState(undefined);
  const [vehicleValue, setVehicleValue] = useState('');
  const [filteredData, setfilteredData] = useState([]);

  // SET FILTERED DATA BASE ON DATA
  useEffect(() => {
    if (!data) return;

    setfilteredData(data);
  }, [data]);

  // WHEN SELECTED CAR DOESNT EMPTY
  // useEffect(() => {
  //   if (!selectedCar) return;

  //   setChoosedVehicle(selectedCar);
  //   setIsChoosed(true);
  // }, [selectedCar]);

  // CLICK OUTSIDE
  useEffect(() => {
    // Add a click event listener to the document to handle clicks outside the dropdown.
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts.
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // FOCUS INPUT FIELD
  // useEffect(() => {
  //   if (!isChoosed) {
  //     inputRef.current?.focus();
  //   }
  // }, [isChoosed]);

  // SEARCH FUNCTION
  useEffect(() => {
    if (!data) return;

    const timeout = setTimeout(() => {
      const fileteredData = data?.filter((item) => item.category.toLowerCase().includes(vehicleValue));
      setfilteredData(fileteredData);
      // if (!isChoosed) {
      //   setIsShowDropdown(true);
      // }
    }, 200);

    return () => clearTimeout(timeout);
  }, [vehicleValue]);

  // DROPDOWN SHOW FUNCTION
  const toggleDropdown = () => {
    setIsShowDropdown(!isShowDropdown);
    // setIsChoosed(false);
  };

  // CHOOSE CAR FUNCTION
  const handleChooseVehicle = (id) => {
    // setIsChoosed(true);
    const choosedVehicle = data?.find((item) => item.vehicle_id === id);
    // setChoosedVehicle(choosedVehicle);
    onSelectCar(choosedVehicle);
    setIsShowDropdown(false);
  };

  return (
    <div className={clsx('select-field-airport-package', className)} style={style} ref={dropdownRef}>
      {label && (
        <label htmlFor={id} className="select-field-airport-package__label">
          {label}
        </label>
      )}
      <div className={clsx('select-field-airport-package__field', disable ? 'disable' : '')} onClick={toggleDropdown}>
        {!selectedCar ? (
          <input
            ref={inputRef}
            className="select-field-airport-package__field__input"
            id={id}
            placeholder={placeholder}
            value={vehicleValue}
            onChange={(e) => setVehicleValue(e.target.value)}
            readOnly={disable}
          />
        ) : (
          <div className="select-field-airport-package__field__car">
            <div>
              {selectedCar?.images && selectedCar?.images.length !== 0 && (
                <img src={`${baseUrl}${selectedCar?.images[0]}`} alt={selectedCar?.name} loading="lazy" />
              )}
            </div>
            <div>
              <h3>{`${selectedCar?.category} - ${selectedCar?.vehicle_name} - ${selectedCar?.licence_number}`}</h3>
              <ul>
                <li>{`${selectedCar?.max_passenger} Seats`}</li>
                <li>{`${selectedCar?.max_suitecase} Koper`}</li>
                <li>{`Transmisi ${selectedCar?.transmission}`}</li>
              </ul>
            </div>
            <span>{setCurrency(selectedCar?.package_price)?.replace('Rp', 'IDR')}</span>
          </div>
        )}
      </div>

      {/* drop down */}
      {isShowDropdown && (
        <ul className="select-field-airport-package__dropdown">
          {filteredData?.length !== 0 ? (
            filteredData.map((item) => (
              <li key={item.vehicle_id} onClick={() => handleChooseVehicle(item.vehicle_id)}>
                <div>
                  {item.images && item.images.length !== 0 && (
                    <img src={`${baseUrl}${item.images[0]}`} alt={item.name} loading="lazy" />
                  )}
                </div>
                <div>
                  <h3>{`${item.category} - ${item.vehicle_name} - ${item.licence_number}`}</h3>
                  <span>
                    <p>{`${item.max_passenger} Seats`}</p>
                    <span />
                    <p>{`${item.max_suitecase} Koper`}</p>
                    <span />
                    <p>{`Transmisi ${item.transmission}`}</p>
                  </span>
                  <span>{setCurrency(item.package_price)?.replace('Rp', 'IDR')}</span>
                </div>
              </li>
            ))
          ) : (
            <li>Data Not Found</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SelectFieldAirportPackage;
