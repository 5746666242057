import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { createLanguage, updateLanguage } from 'features/language/actions';
import Modals from '../Modals';
import { Button, InputField } from 'components/Global';
import { ReactComponent as LanguageFilledIcon } from 'icons/language-filled-icon.svg';

const LanguageModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setShowAddNewLanguage, showToast, setShowSpinner } = useAppContext();
  const { selectedLanguage } = useSelector((state) => state.language);

  const [languageName, setLanguageName] = useState('');
  const [languageCode, setLanguageCode] = useState('');

  const [searchParams] = useSearchParams();
  const languageId = searchParams.get('language_id');

  // show and hide modal
  const stateModalHandler = (state) => {
    setShowAddNewLanguage(state);
    navigate('/language');
  };

  // back handler
  const backHandler = () => {
    setShowAddNewLanguage(false);
    navigate('/language');
  };

  // function mutation
  const actionMutation = async (method, payload, successMessage, errorMessage) => {
    if (method === 'ADD') {
      try {
        setShowSpinner(true);
        await dispatch(createLanguage(payload)).unwrap();
        showToast({ type: 'success', message: successMessage });
        setShowAddNewLanguage(false);
        setShowSpinner(false);
      } catch (error) {
        showToast({ type: 'error', message: errorMessage });
        setShowSpinner(false);
      }
    } else {
      try {
        setShowSpinner(true);
        await dispatch(updateLanguage({ languageId: payload.id, payloadData: payload.body })).unwrap();
        showToast({ type: 'success', message: successMessage });
        setShowAddNewLanguage(false);
        setShowSpinner(false);
      } catch (error) {
        showToast({ type: 'error', message: errorMessage });
        setShowSpinner(false);
      }
    }
  };

  // submit add function
  const submitHandler = async (e) => {
    e.preventDefault();

    if (!languageName) {
      showToast({ type: 'error', message: 'Masukan Nama Bahasa' });
      return;
    }

    if (!languageCode) {
      showToast({ type: 'error', message: 'Masukan Kode Bahasa' });
      return;
    }

    const payload = {
      name: languageName,
      code: languageCode,
    };

    if (languageId) {
      actionMutation('UPDATE', { id: languageId, body: payload }, 'Berhasil Update Item', 'Gagal Update Item');
    } else {
      actionMutation('ADD', payload, 'Berhasil Tambah Item', 'Gagal Tambah Item');
    }
  };

  useEffect(() => {
    if (!Object.keys(selectedLanguage).length || !languageId) return;

    setLanguageName(selectedLanguage.name);
    setLanguageCode(selectedLanguage.code);
  }, [selectedLanguage]);

  return (
    <Modals
      setState={stateModalHandler}
      title="Tambah Bahasa"
      icon={<LanguageFilledIcon fill="#009EF7" width="25px" height="25px" />}
    >
      <div className="language-modal">
        <form className="language-modal-form">
          <InputField
            label="Nama Bahasa"
            htmlFor="nama-bahasa"
            value={languageName}
            onChange={(e) => setLanguageName(e.target.value)}
            placeholder="Tulis nama bahasa"
          />

          <InputField
            label="Kode Bahasa"
            htmlFor="kode-bahasa"
            value={languageCode}
            onChange={(e) => setLanguageCode(e.target.value)}
            placeholder="Tulis kode bahasa"
          />

          <div className="action-button">
            <Button width={192} role="button" variant="outline" type="button" onClick={backHandler}>
              Kembali
            </Button>

            <Button width={192} role="button" type="button" onClick={submitHandler}>
              Tambah
            </Button>
          </div>
        </form>
      </div>
    </Modals>
  );
};

export default LanguageModal;
