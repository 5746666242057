/* eslint-disable no-console */
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((err) => {
      console.log('Service Worker registration failed:', err);
    });
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGIN_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
};

const app = initializeApp(firebaseConfig);

let messaging;
if (typeof window !== 'undefined') {
  messaging = getMessaging(app);
}
export { messaging };

export const requestPermission = async () => {
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    // Generate Device Token for notification
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FB_VAPID_KEY,
    });
    return token;
  } else if (permission === 'denied') {
    console.log('Denied for the notification');
  }
};
