import { useAppContext } from 'components/Context/AppContext';
import Modals from '../Modals';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { Button, InputField } from 'components/Global';
import { useState } from 'react';
import { createNewBrand } from 'features/brands/actions';
import { useDispatch } from 'react-redux';

const AddBrandCarModal = () => {
  const dispatch = useDispatch();

  // context
  const { setShowAddBrandCar, showToast, setShowSpinner } = useAppContext();

  // new brand car state
  const [newBrandCar, setNewBrandCar] = useState('');

  // function submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await dispatch(createNewBrand(newBrandCar));

      setShowSpinner(true);

      if (res.meta?.requestStatus === 'fulfilled') {
        showToast({ type: 'success', message: 'Berhasil Menambahkan Merk Mobil' });
        return;
      }

      showToast({ type: 'error', message: 'Gagal Menambahkan Merk Mobil' });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: `Terjadi Kesalahan: ${error}` });
    } finally {
      setShowSpinner(false);
      setShowAddBrandCar(false);
    }
  };

  return (
    <Modals
      setState={setShowAddBrandCar}
      title="Tambah Merk Mobil"
      icon={<CarIcon fill="#009EF7" width="25px" height="25px" />}
    >
      <div className="add-brand-car">
        <InputField
          label="Nama Merk Mobil"
          htmlFor="nama-merk-mobil"
          placeholder="Tulis nama merk mobil"
          value={newBrandCar}
          onChange={(e) => setNewBrandCar(e.target.value)}
        />
        <div className="add-brand-car__button-field">
          <Button size="sm" variant="outline" width="192px" onClick={() => setShowAddBrandCar(false)}>
            Kembali
          </Button>
          <Button size="sm" width="192px" onClick={handleSubmit}>
            Simpan
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default AddBrandCarModal;
