import { createSlice } from '@reduxjs/toolkit';
import { getAllLanguage, createLanguage, getLanguageById, updateLanguage, deleteLanguage } from './actions';

const initialState = {
  data: {},
  selectedLanguage: {},
  isLoading: false,
  isError: false,
  errorMessage: '', // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const language = createSlice({
  name: 'language',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLanguage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getAllLanguage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllLanguage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createLanguage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(createLanguage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createLanguage.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getLanguageById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getLanguageById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getLanguageById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedLanguage = action.payload;
      })
      .addCase(updateLanguage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(updateLanguage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateLanguage.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteLanguage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(deleteLanguage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteLanguage.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export default language.reducer;
