import React from 'react';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utils/functionality';

const NotPermitted = ({ children }) => {
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const readPermission = checkPermission(offCanvasMenu, currentMenu, 'read');

  return <>{((readPermission && readPermission !== undefined) || currentMenu === 'Notifikasi Inbox') && children}</>;
};

export default NotPermitted;
