/* eslint-disable no-unused-vars */
import { useAppContext } from 'components/Context/AppContext';
import { Button, PaginationTable, Table, TableWrapper } from 'components/Global';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from 'icons/plus-icon.svg';
import { ReactComponent as NotifIcon } from 'icons/notif-icon.svg';
import { getTemplateDetail } from 'features/notification/actions';
import ButtonAdd from 'components/Global/ButtonAdd';

const NotificationTemplateList = ({ setShowDetail, page, setPage }) => {
  const dispatch = useDispatch();
  const { setShowSpinner, showToast } = useAppContext();

  const { pagination, templates } = useSelector((state) => state.notification?.data);

  const deleteHandler = (e) => {
    // const vehicleId = e.target.parentElement.parentElement.dataset['key'];
    // dispatch(deleteCity(vehicleId));
  };

  const editHandler = (e) => {
    const templateCode = e.target.parentElement.parentElement.dataset['key'];
    dispatch(getTemplateDetail(templateCode));
    setShowDetail('edit');
  };

  // useEffect(() => {
  //   if (cities.isLoading) {
  //     setShowSpinner(true);
  //   } else {
  //     setShowSpinner(false);
  //   }
  // }, [cities.isLoading]);

  // useEffect(() => {
  //   if (cities.isLoading || !cities.isCreateSuccessful) return;

  //   showToast({ type: 'success', message: 'Berhasil menambahkan kota' });
  // }, [cities.isLoading, cities.isCreateSuccessful]);

  // useEffect(() => {
  //   if (cities.isLoading || !cities.isError) return;

  //   showToast({ type: 'error', message: 'Gagal menambahkan kota!' });
  // }, [cities.isLoading, cities.isError]);

  return (
    <div className="notif">
      <ButtonAdd onClick={() => setShowDetail('add')} label="Tambah Template" />

      <TableWrapper title="Template Notifikasi" icon={<NotifIcon fill="#FF9900" width="25px" height="25px" />}>
        <Table
          column={tableColumn}
          data={templates}
          actionBtn
          onEdit={editHandler}
          onDel={deleteHandler}
          currentPage={page}
        />
        <PaginationTable
          currentPage={page}
          onPageChange={(newPage) => setPage(newPage)}
          totalCount={pagination?.total_data}
        />
      </TableWrapper>
    </div>
  );
};

export default NotificationTemplateList;

const tableColumn = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Template', value: 'name' },
  { header: 'Kategori', value: 'code' },
];

// const tableData = [
//   {
//     id: 123,
//     nama: 'Notifikasi Pemberitahuan',
//     category: 'Notifikasi',
//   },
// ];
