import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, InputField, RadioButton, TableWrapper } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMatrix } from 'features/matrix/actions';
import { createOvertimeRules, getDetailOvertimeRules, updateOvertimeRules } from 'features/overtime/actions';
import { useAppContext } from 'components/Context/AppContext';

import { ReactComponent as OvertimeIcon } from 'icons/overtime-icon.svg';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as ChevronDown } from 'icons/chevron-down.svg';
import { ReactComponent as LessThan } from 'icons/less-than.svg';
import { ReactComponent as GreaterThan } from 'icons/greater-than.svg';
import { ReactComponent as GreateThanEqual } from 'icons/greater-than-equal.svg';
import { ReactComponent as Equal } from 'icons/equals.svg';
import { ReactComponent as LessThanEqual } from 'icons/less-than-equal.svg';
import { checkPermission } from 'utils/functionality';

const overtimeRulesData = {
  name: '',
  price_value: '',
  price_type: '',
  limit_type: '',
  limit_value: '',
  limit_operator: '',
  overtime_rule_matrixes: [],
};

const OvertimeRulesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setShowSpinner, showToast, setShowConfirmation } = useAppContext();

  const { matrix } = useSelector((state) => state.matrix);
  const { selectedOvertimeRules } = useSelector((state) => state.overtime);

  const selectRef = useRef(null);
  const matrixRef = useRef(null);

  const [searchParams] = useSearchParams();
  const location = useLocation();

  const overtimeRulesId = searchParams.get('id');
  const isEditPage = location.pathname === '/overtime/edit-rules';

  const [showOptions, setShowOptions] = useState(false);
  const [showMatrixOptions, setShowMatrixOptions] = useState(false);
  const [priceType, setPriceType] = useState('');
  const [limitType, setLimitType] = useState('');
  const [selectedMatrix, setSelectedMatrix] = useState([]);
  const [overtimeRules, setOvertimeRules] = useState(overtimeRulesData);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  useEffect(() => {
    dispatch(getAllMatrix());
  }, []);

  useEffect(() => {
    if (!isEditPage) return;

    dispatch(getDetailOvertimeRules(overtimeRulesId));
  }, [isEditPage]);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleValueType = (id) => {
    setPriceType(id);

    const ValueType = arrayOvertimeCharge.find((item) => item.id === id)?.value;
    setOvertimeRules({ ...overtimeRules, price_type: ValueType });
  };

  const handleLimitType = (id) => {
    setLimitType(id);

    const ValueType = arrayOvertimeLimit.find((item) => item.id === id)?.value;
    setOvertimeRules({ ...overtimeRules, limit_type: ValueType });
  };

  const handleValueInput = (e) => {
    setOvertimeRules({ ...overtimeRules, price_value: isNaN(e.target.value) ? 0 : +e.target.value });
  };

  const handleValueInputLimit = (e) => {
    setOvertimeRules({ ...overtimeRules, limit_value: isNaN(e.target.value) ? 0 : +e.target.value });
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const iconList = {
    1: <LessThan />,
    2: <LessThanEqual />,
    3: <Equal />,
    4: <GreaterThan />,
    5: <GreateThanEqual />,
  };

  const getIconById = (id) => {
    return iconList[id] || null;
  };

  const handleOptionClick = (id) => {
    setOvertimeRules({ ...overtimeRules, limit_operator: id });
    setShowOptions(false);
  };

  const handleMatrixChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedMatrix((prevState) =>
      prevState.includes(value) ? prevState.filter((option) => option !== value) : [...prevState, value],
    );
  };

  const renderSelectedOptions = () => {
    const selected = arrayConditions.find((option) => option.id === overtimeRules.limit_operator);
    return selected ? selected.label : 'Pilih Kondisi';
  };

  const renderSelectedMatrix = () => {
    return matrix.data
      ? matrix.data
          .filter((option) => selectedMatrix.includes(option.id))
          .map((option) => option.name)
          .join(', ')
      : '';
  };

  const handleSubmit = async () => {
    const transformedMatrix = selectedMatrix.map((i) => ({ id: i }));

    // validation
    const validName = overtimeRules.name.trim();
    const validPriceType = overtimeRules.price_type !== '';
    // const validPriceValue = overtimeRules.price_value !== '';
    // const validPriceValue2 = overtimeRules.price_value !== 0;
    // const validMatrix = selectedMatrix.length !== 0;
    const validLimitOperator = overtimeRules.limit_operator !== '';
    const validLimitType = overtimeRules.limit_type !== '';
    const validLimitValue = overtimeRules.limit_value !== '';
    const validLimitValue2 = overtimeRules.limit_value !== 0;

    const payload = {
      ...overtimeRules,
      overtime_rule_matrixes: transformedMatrix,
      price_value: overtimeRules.price_value === '' ? 0 : overtimeRules.price_value,
    };

    if (!validName) {
      return showToast({ type: 'error', message: 'Nama aturan overtime tidak boleh kosong' });
    } else if (!validPriceType) {
      return showToast({ type: 'error', message: 'Silakan pilih tipe denda overtime' });
    } else if (!validLimitOperator) {
      return showToast({ type: 'error', message: 'Silakan pilih kondisi' });
    } else if (!validLimitType) {
      return showToast({ type: 'error', message: 'Silakan pilih tipe batas overtime' });
    } else if ((validLimitType && !validLimitValue) || !validLimitValue2) {
      return showToast({ type: 'error', message: 'nilai batas overtime tidak boleh kosong' });
    }

    if (!isEditPage) {
      setShowConfirmation({
        message: 'Apakah anda yakin ingin menambah aturan overtime?',
        show: true,
        onClick: async () => {
          try {
            setShowSpinner(true);
            await dispatch(createOvertimeRules(payload)).unwrap();
            showToast({ type: 'success', message: 'Berhasil Menambah Aturan Overtime' });
            navigate('/overtime-rule');
            return;
          } catch (error) {
            showToast({ type: 'error', message: 'Gagal Menambah Aturan Overtime' });
          } finally {
            setShowSpinner(false);
          }
        },
      });
    } else {
      setShowConfirmation({
        message: 'Apakah anda yakin ingin Mengubah aturan overtime?',
        show: true,
        onClick: async () => {
          try {
            setShowSpinner(true);
            await dispatch(updateOvertimeRules({ id: overtimeRulesId, payload })).unwrap();
            showToast({ type: 'success', message: 'Berhasil Menambah Aturan Overtime' });
            navigate('/overtime-rule');
            return;
          } catch (error) {
            showToast({ type: 'error', message: 'Gagal Mengubah Aturan Overtime' });
          } finally {
            setShowSpinner(false);
          }
        },
      });
    }
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setShowOptions(false);
    }

    if (matrixRef.current && !matrixRef.current.contains(event.target)) {
      setShowMatrixOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(selectedOvertimeRules).length === 0) return;
    if (!isEditPage) return;

    const selectedPriceType = arrayOvertimeCharge.find((item) => item.value === selectedOvertimeRules.price_type)?.id;
    const selectedLimitType = arrayOvertimeLimit.find((item) => item.value === selectedOvertimeRules.limit_type)?.id;
    const selectedMatrix = selectedOvertimeRules.overtime_rule_matrixes
      ? selectedOvertimeRules.overtime_rule_matrixes.map((item) => item.id)
      : [];

    setPriceType(selectedPriceType);
    setLimitType(selectedLimitType);
    setSelectedMatrix(selectedMatrix);

    setOvertimeRules(selectedOvertimeRules);
  }, [selectedOvertimeRules, isEditPage]);

  return (
    <>
      <div className="overtime-rules__header" onClick={() => navigate(-1)}>
        <LeftArrow />
        <p>Kembali</p>
      </div>

      <TableWrapper
        icon={<OvertimeIcon fill="#009EF7" width="25px" height="25px" />}
        title="Aturan Overtime"
        style={{ position: 'relative' }}
      >
        <div className="overtime-rules">
          <InputField
            label="Aturan Overtime"
            htmlFor="overtime-rules"
            placeholder="Tulis Nama Aturan Overtime"
            value={overtimeRules.name}
            onChange={(e) => setOvertimeRules({ ...overtimeRules, name: e.target.value })}
          />

          <div className="overtime-rules__select-container" ref={matrixRef}>
            <label
              className="overtime-rules__select-label"
              htmlFor="select-box"
              onClick={() => setShowMatrixOptions((prev) => !prev)}
              style={{ marginBottom: '12px' }}
            >
              matrix:
            </label>
            <div
              id="select-box"
              className="overtime-rules__select-box"
              onClick={() => setShowMatrixOptions((prev) => !prev)}
            >
              {renderSelectedMatrix() || 'Pilih Matrix'}
              <ChevronDown
                className={!showMatrixOptions ? 'overtime-rules__arrow' : 'overtime-rules__arrow arrow-down'}
              />
            </div>

            {showMatrixOptions && (
              <div className={`create-overtime__options-container ${setShowMatrixOptions ? 'show' : ''}`}>
                {matrix.data ? (
                  matrix.data.map((item) => (
                    <div className="option" key={item.id}>
                      <label htmlFor={`option-${item.id}`}>{item.name}</label>
                      <input
                        type="checkbox"
                        id={`option-${item.id}`}
                        value={item.id}
                        checked={selectedMatrix.includes(item.id)}
                        onChange={handleMatrixChange}
                      />
                    </div>
                  ))
                ) : (
                  <div className="option option-empty">data tidak ditemukan!</div>
                )}
              </div>
            )}
          </div>

          <div className="overtime-rules__overtime-charge">
            <h3>Denda Overtime:</h3>
            <div className="overtime-rules__radio-group">
              <RadioButton name="price" data={arrayOvertimeCharge} state={priceType} setState={handleValueType} />
            </div>
            <InputField
              type="number"
              placeholder={priceType === 'pr1' ? 'Rp 0' : priceType === 'pr2' ? '0%' : 'Rp 0'}
              value={overtimeRules.price_value || ''}
              onChange={handleValueInput}
              onWheel={numberInputOnWheelPreventChange}
              disable={!priceType}
            />
          </div>

          <div className="overtime-rules__overtime-limit">
            <h3>Batas Overtime:</h3>

            <div className="overtime-rules__overtime-limit-wrapper">
              <div className="overtime-rules__condition">
                <div className="overtime-rules__select-container" ref={selectRef}>
                  <label
                    className="overtime-rules__select-label"
                    htmlFor="select-box"
                    onClick={() => setShowOptions((prev) => !prev)}
                  >
                    Kondisi:
                  </label>
                  <div id="select-box" className="overtime-rules__select-box" onClick={toggleOptions}>
                    {renderSelectedOptions()}
                    <ChevronDown
                      className={!showOptions ? 'overtime-rules__arrow' : 'overtime-rules__arrow arrow-down'}
                    />
                  </div>

                  {showOptions && (
                    <div className={`overtime-rules__options-container ${showOptions ? 'show' : ''}`}>
                      {arrayConditions.map((option) => (
                        <div className="option" key={option.id} onClick={() => handleOptionClick(option.id)}>
                          <div>
                            <span>{getIconById(option.id)}</span>
                            {option.label}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="overtime-rules__time">
                <div className="overtime-rules__radio-group">
                  <RadioButton name="limit" data={arrayOvertimeLimit} state={limitType} setState={handleLimitType} />
                </div>
                <InputField
                  type="number"
                  placeholder={limitType === 'hour' ? '0 Jam' : limitType === 'minute' ? '0 Minute' : '0'}
                  value={overtimeRules.limit_value || ''}
                  onChange={handleValueInputLimit}
                  onWheel={numberInputOnWheelPreventChange}
                  disable={!limitType}
                />
              </div>
            </div>
            <div className="overtime-rules__btn-group">
              <Button type="button" onClick={() => navigate(-1)} width="192px" height="39px" variant="outline">
                Kembali
              </Button>
              {(!isEditPage || checkPermission(offCanvasMenu, currentMenu, 'update')) && (
                <Button type="button" width="192px" height="39px" variant="primary" onClick={handleSubmit}>
                  Simpan
                </Button>
              )}
            </div>
          </div>
        </div>
      </TableWrapper>
    </>
  );
};

export default OvertimeRulesPage;

const arrayOvertimeCharge = [
  {
    id: 'pr1',
    label: 'Harga Fixed',
    value: 'fixed',
  },
  {
    id: 'pr2',
    label: 'Harga Percentage',
    value: 'percentage',
  },
];

const arrayOvertimeLimit = [
  {
    id: 'hours',
    label: 'Jam',
    value: 'hours',
  },
  {
    id: 'minutes',
    label: 'Menit',
    value: 'minutes',
  },
];

const arrayConditions = [
  { id: 'lt', label: 'Lebih kecil dari' },
  { id: 'lte', label: 'Lebih kecil dari sama dengan' },
  { id: 'eq', label: 'Sama dengan' },
  { id: 'gt', label: 'Lebih besar dari' },
  { id: 'gte', label: 'lebih besar dari sama dengan' },
];
