import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getServices = createAsyncThunk('users/getServices', async (__, thunkAPI) => {
  // const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/services`, {
      // headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// GET ALL SERVICES BY RENTAL LOCATION
export const getServicesByRentalLocation = createAsyncThunk(
  'rentalLocation/getServicesByRentalLocation',
  async (location_id, thunkAPI) => {
    try {
      const response = await axios.get(`${BASE_URL}/${API_VERSION}/location/${location_id}/subservices`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data.message);
    }
  },
);
