import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { setCurrency } from 'utils/functionality';
import ListItemAdditional from './ListItemAdditional';
import { ReactComponent as XSignIcon } from 'icons/x-sign-black-icon.svg';

const SelectFieldAdditionalItem = ({
  label,
  data,
  className,
  style,
  id,
  name,
  value,
  onChange,
  placeholder,
  selectedAdditionalItem,
  setSelectedAdditionalItem,
  disable,
  readOnly,
  filterParams,
}) => {
  const dropdownRef = useRef(null);

  const [newData, setNewData] = useState([]);
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  // FUNCTION TOGGLE DROPDOWN
  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsShowDropdown(!isShowDropdown);
  };

  const chooseAdditionalItemHandler = (additionalItem) => {
    if (!selectedAdditionalItem || selectedAdditionalItem.length === 0) {
      if (additionalItem.total === 0) return;
      setSelectedAdditionalItem([additionalItem]);
    } else {
      const existingItem = selectedAdditionalItem.find((item) => item.additional.id === additionalItem.additional.id);
      if (existingItem) {
        if (additionalItem.total === 0) {
          const filteredAdditional = [...selectedAdditionalItem].filter(
            (item) => item.additional.id !== existingItem.additional.id,
          );

          setSelectedAdditionalItem(filteredAdditional);
        } else {
          const copySelectedAdditional = [...selectedAdditionalItem];
          copySelectedAdditional[copySelectedAdditional.indexOf(existingItem)].total = additionalItem.total;
          copySelectedAdditional[copySelectedAdditional.indexOf(existingItem)].additional = additionalItem.additional;

          setSelectedAdditionalItem(copySelectedAdditional);
        }
      } else {
        setSelectedAdditionalItem([...selectedAdditionalItem, additionalItem]);
      }
    }
  };

  const deleteAdditionalHandler = (id, e) => {
    e.stopPropagation();
    const filteredAdditional = [...selectedAdditionalItem].filter((item) => item.additional.id !== id);

    setSelectedAdditionalItem(filteredAdditional);
  };

  // EFFECT CLICK OUTSIDE
  useEffect(() => {
    // Add a click event listener to the document to handle clicks outside the dropdown.
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts.
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setNewData(data);
  }, [data]);

  return (
    <div className={clsx('select-field-additional-item', className)} style={style} ref={dropdownRef}>
      {label && (
        <label htmlFor={id} className="select-field-additional-item__label">
          {label}
        </label>
      )}
      <div
        className={clsx('select-field-additional-item__field', disable && 'disable')}
        onClick={(e) => toggleDropdown(e)}
      >
        {isShowDropdown ? (
          <input
            id={id}
            name={name}
            className="select-field-additional-item__field__input"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            autoFocus
            readOnly={readOnly}
          />
        ) : selectedAdditionalItem?.length ? (
          <div className={clsx('select-field-additional-item__field__items-wrapper', disable && 'disable')}>
            {selectedAdditionalItem.map((item) => (
              <div key={item.id} className="item">
                <div className="item__image">
                  {item.additional?.varieties[0]?.images ? (
                    <img src={item.additional?.varieties[0]?.images[0].file_name} alt={item.additional?.name} />
                  ) : (
                    <div style={{ width: '100%', height: '100%', background: '#eaeaeaea' }} />
                  )}
                </div>
                <div className="item__info">
                  <h5>{item.additional.name}</h5>
                  <div>
                    <p>
                      Jumlah Sewa : <span>{item.total}</span>
                    </p>
                    <p>
                      Warna : <span>{item.additional.varieties[0]?.color}</span>
                    </p>
                  </div>
                </div>
                <div className="item__price">
                  <p>{setCurrency(item.additional.unit_price)?.replace('Rp', 'IDR')}</p>
                  <button type="button" onClick={(e) => deleteAdditionalHandler(item.additional.id, e)}>
                    <XSignIcon className="icon" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <input
            id={id}
            name={name}
            className="select-field-additional-item__field__input"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={readOnly}
          />
        )}
      </div>

      {isShowDropdown && (
        <div className="select-field-additional-item__dropdown">
          <ul className="list-container">
            {newData && newData.length !== 0 ? (
              newData.map((item) => (
                <ListItemAdditional
                  key={item.id}
                  item={item}
                  onChoose={chooseAdditionalItemHandler}
                  selectedAdditionalItem={selectedAdditionalItem}
                  filterParams={filterParams}
                  data={newData}
                  setData={setNewData}
                />
              ))
            ) : (
              <li className="list-no-data">No Data found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectFieldAdditionalItem;
