import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const fetchCustomerInfo = createAsyncThunk('users/fetchCustomerInfo', async (customerId, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/users/${customerId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchAllUsers = createAsyncThunk('users/fetchAllUsers', async (page = 1, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/users?limit=10&page=${page}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const changeUserStatus = createAsyncThunk(
  'users/changeUserStatus',
  async ({ customerId, payload }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.put(
        `${BASE_URL}/${API_VERSION}/users/${customerId}`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET USER STATS
export const getUserStats = createAsyncThunk('users/getUserStats', async (__, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/dashboard/reports/user-stats`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAdminProfile = createAsyncThunk('users/getAdminProfile', async (_, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const getProfile = await axios.get(`${BASE_URL}/${API_VERSION}/profile`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/users/${getProfile.data.id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchAllUsersInfluencer = createAsyncThunk('users/fetchAllUsersInfluencer', async (page = 1, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/influencers?limit=10&page=${page}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchInfluencerInfo = createAsyncThunk('users/fetchInfluencerInfo', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/influencers/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteUserById = createAsyncThunk('users/deleteUserById', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${BASE_URL}/${API_VERSION}/admin/influencers/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchInfluencerReferralHistory = createAsyncThunk(
  'users/fetchInfluencerReferralHistory',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/referrer-history?referrer_id=${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const createUserInfluencer = createAsyncThunk('users/createUserInfluencer', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/admin/influencers`,
      { ...payload },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const editUserInfluencer = createAsyncThunk('users/editUserInfluencer', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { id, data } = payload;

  try {
    const response = await axios.put(
      `${BASE_URL}/${API_VERSION}/admin/influencers/${id}`,
      { ...data },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const generateReferralCode = createAsyncThunk('users/generateReferralCode', async (name, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/generate-referral-code?name=${name}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
