import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllAirportLocation = createAsyncThunk(
  'airportLocation/getAllAirportLocation',
  async ({ limit = 10, page = 1, search, sortBy, sortOrder, filters }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let newUrl = `${BASE_URL}/${API_VERSION}/admin/airport-location?limit=${limit}&page=${page}`;

    if (search) {
      newUrl += `&search=${search}`;
    }

    if (sortBy) {
      newUrl += `&sort_by=${sortBy}`;
    }

    if (sortOrder) {
      newUrl += `&sort_order=${sortOrder}`;
    }

    if (filters && filters.length !== 0) {
      filters.forEach((item) => (newUrl += `&filter_key=${item.filter_key}&filter_value=${item.filter_value}`));
    }

    try {
      const response = await axios.get(newUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getDetailAirportLocation = createAsyncThunk(
  'airportLocation/getDetailAirportLocation',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/airport-location/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const createAirportLocation = createAsyncThunk(
  'airportLocation/createAirportLocation',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/${API_VERSION}/admin/airport-location`,
        { ...payload },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const data = await response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const updateAirportLocation = createAsyncThunk(
  'airportLocation/updateAirportLocation',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { payloadData, airportLocationId } = payload;

    try {
      const response = await axios.put(
        `${BASE_URL}/${API_VERSION}/admin/airport-location/${airportLocationId}`,
        { ...payloadData },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const data = await response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const deleteAirportLocation = createAsyncThunk('airportLocation/deleteAirportLocation', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${BASE_URL}/${API_VERSION}/admin/airport-location/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
