import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as Mappin } from '../../../icons/mappin-icon.svg';
import { ReactComponent as MappinAirport } from '../../../icons/mappin-airport-icon.svg';
import { ReactComponent as Checklist } from '../../../icons/check-icon.svg';
import { setCurrency } from 'utils/functionality';
import { useInView } from 'react-intersection-observer';
import ClickOutside from '../ClickOutside';

// component ini hasil copy dari komponen SelectFieldShuttle
// dengan sedikit penyesuaian

const SelectFieldShuttleAlt = ({
  label,
  name,
  htmlFor,
  value, // state
  onChange, // setState
  data = [],
  className,
  placeholder,
  checkbox = false,
  disable,
  intersectionAction,
  searchFeature = false,
  isAirport = false,
}) => {
  // INSTERSECTION HOOKS
  const { ref, entry } = useInView();

  // SHOW DROPDOWN FUNCTION
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [isLoadingIntersection, setIsLoadingIntersection] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  // LOCAL STATE
  const [dataList, setDataList] = useState([]);
  const pathName = location.pathname.split('/').filter((item) => item !== '')[0];

  // FUNCTION TO HANDLE HIDE DROPDOWN
  const handleHide = () => {
    setTimeout(() => {
      setIsShowDropdown(false);
    }, 200);
  };

  // WILL CALLED WHEN LAST VEHICLE LIST WAS IN VIEW
  useEffect(() => {
    let timeout;

    if (entry) {
      if (entry.isIntersecting) {
        setIsLoadingIntersection(true);

        timeout = setTimeout(() => {
          intersectionAction();
          setIsLoadingIntersection(false);
        }, 1000);
      }
    }

    return () => clearTimeout(timeout);
  }, [entry]);

  // FUNCTION WHEN CHECK THE CHECKBOX
  const handleCheck = (e) => {
    const choosedItem = dataList.find((item) => item.id == e.target.id);
    choosedItem.checked = e.target.checked;

    const newDataList = [...dataList];
    newDataList[dataList.indexOf(choosedItem)] = choosedItem;

    onChange(choosedItem.id, choosedItem.checked);
    setDataList(newDataList);
  };

  // MAPPING CHECKED PROP WHEN FIRST LOAD
  useEffect(() => {
    if (!checkbox) return;
    if (!data || data.length === 0) return;

    if (value.length === 0) {
      const mappingCheckProp = data.map((item) => {
        return { ...item, checked: false };
      });
      setDataList(mappingCheckProp);
    } else if (value.length !== 0) {
      const mappingCheckProp = data.map((item) => {
        return { ...item, checked: value.find((id) => id == item.id) ? true : false };
      });
      setDataList(mappingCheckProp);
    }
  }, [data, value]);

  useEffect(() => {
    if (!searchFeature) return;
    // if (!data || data.length === 0) return;

    if (inputValue === '') {
      setFilteredData(data);
    } else {
      const dataFilter = data?.filter((item) => item.name.toLowerCase().includes(inputValue.toLowerCase()));
      setFilteredData(dataFilter);
    }
  }, [data, inputValue]);

  return (
    <div className={clsx('select-field-shuttle', className)}>
      {label && (
        <label htmlFor={htmlFor} className="select-field-shuttle__label">
          {label}
        </label>
      )}
      <div
        className={disable ? 'select-field-shuttle__input-field disable' : 'select-field-shuttle__input-field'}
        style={{ width: '100%', backgroundColor: 'transparent' }}
        id={htmlFor}
        type="text"
        placeholder={placeholder}
        name={name}
        onFocus={() => setIsShowDropdown(true)}
        onBlur={!checkbox ? handleHide : () => {}}
        tabIndex={0}
      >
        {/*  */}
        {isAirport ? (
          <MappinAirport className="select-field-shuttle__input-field__arrow" />
        ) : (
          <Mappin className="select-field-shuttle__input-field__arrow" />
        )}
        {!checkbox && !searchFeature && (
          <>
            <p
              className={
                !value || value === ''
                  ? 'select-field-shuttle__input-field__input placeholder'
                  : 'select-field-shuttle__input-field__input'
              }
            >
              {!value || value === '' ? placeholder : data?.find((item) => item.id == value)?.name}
            </p>
            <span>
              {!value || value === ''
                ? ''
                : data?.find((item) => item.id == value)?.fee !== undefined
                ? '+ ' + 'IDR ' + setCurrency(data?.find((item) => item.id == value)?.fee)?.replace('Rp', '')
                : ''}
            </span>
          </>
        )}
        {checkbox && !searchFeature && (
          <div className="select-field-shuttle__input-field__list">
            {value.length !== 0 && dataList.length !== 0 ? (
              value.map((li) => <span key={li + Math.random()}>{dataList.find((item) => item.id == li).name}</span>)
            ) : (
              <>Masukan Lokasi Pengiriman</>
            )}
          </div>
        )}
        {searchFeature && (
          <input
            id={htmlFor}
            value={
              !isShowDropdown && data?.find((item) => item.id == value)?.name
                ? data?.find((item) => item.id == value)?.name
                : inputValue
            }
            onChange={(e) => setInputValue(e.target.value)}
            className="select-field-shuttle__input-field__search"
            placeholder={placeholder}
            disabled={disable}
          />
        )}
      </div>
      {isShowDropdown && !checkbox && !searchFeature && (
        <ul className="select-field-shuttle__dropdown">
          {data && data.length !== 0 ? (
            data.map((item, i) => (
              <li
                key={item.id}
                data-code={item.code ? item.code : null}
                id={item.id}
                onClick={() => onChange(item)}
                ref={intersectionAction && i === data.length - 1 ? ref : null}
              >
                <span style={pathName === 'airport-transfer' ? { paddingTop: '5px', paddingBottom: '5px' } : {}}>
                  {item.name}
                </span>
                {pathName !== 'airport-transfer' && pathName !== 'schedule-price' && (
                  <span>{`+ IDR ${setCurrency(item.fee).replace('Rp', '')}`}</span>
                )}
              </li>
            ))
          ) : (
            <li>No data found</li>
          )}
          {isLoadingIntersection && <li>Loading ...</li>}
        </ul>
      )}
      {isShowDropdown && checkbox && !searchFeature && (
        <ClickOutside onClickOutside={() => setIsShowDropdown(false)}>
          <ul className="select-field-shuttle__dropdown">
            {dataList.length !== 0 ? (
              dataList.map((item) => (
                <li
                  key={item.id}
                  className="list-shuttle-check"
                  data-code={item.code ? item.code : null}
                  id={item.id}
                  onClick={() => onChange(item)}
                >
                  <span className="list-shuttle-check__title">{item.name}</span>
                  <div
                    className={item.checked ? 'list-shuttle-check__chechkbox check' : 'list-shuttle-check__chechkbox'}
                  >
                    {item.checked && <Checklist />}
                    <input type="checkbox" id={item.id} value={item.id} onChange={handleCheck} />
                  </div>
                </li>
              ))
            ) : (
              <li>No data found</li>
            )}
          </ul>
        </ClickOutside>
      )}
      {isShowDropdown && searchFeature && (
        <ul className="select-field-shuttle__dropdown" style={{ top: label ? '84px' : '54px' }}>
          {filteredData && filteredData.length !== 0 ? (
            filteredData.map((item, i) => (
              <li
                key={item.id}
                data-code={item.code ? item.code : null}
                id={item.id}
                onClick={() => onChange(item)}
                ref={intersectionAction && i === data.length - 1 ? ref : null}
              >
                <span style={pathName === 'airport-transfer' ? { paddingTop: '5px', paddingBottom: '5px' } : {}}>
                  {item.name}
                </span>
                {pathName !== 'airport-transfer' && (item.fee || item.fee === 0) && (
                  <span>{`+ IDR ${setCurrency(item.fee).replace('Rp', '')}`}</span>
                )}
              </li>
            ))
          ) : (
            <li>No data found</li>
          )}
          {isLoadingIntersection && <li>Loading ...</li>}
        </ul>
      )}
    </div>
  );
};

export default React.memo(SelectFieldShuttleAlt);
