import { useContext } from 'react';
import Modals from '../Modals';
import { AppContext } from 'components/Context/AppContext';
import { Button } from 'components/Global';

const ConfirmationModal = () => {
  const { setShowConfirmation, showConfirmation } = useContext(AppContext);

  const continueHandler = () => {
    showConfirmation.onClick && showConfirmation.onClick();
    setShowConfirmation((prev) => ({ ...prev, show: false }));
  };

  return (
    <Modals setState={setShowConfirmation}>
      <div className="confirmation-modal">
        <p className="confirmation-desc">{showConfirmation.message}</p>
        <div className="confirmation-buttons">
          <Button
            width="165px"
            height="39px"
            variant="outline"
            size="md"
            className="button"
            onClick={() => setShowConfirmation((prev) => ({ ...prev, show: false }))}
          >
            Kembali
          </Button>
          <Button
            width="165px"
            height="39px"
            size="md"
            className="button"
            onClick={continueHandler}
            variant={showConfirmation.variant}
          >
            {showConfirmation.valueButton ? showConfirmation.valueButton : 'Iya, Lanjutkan'}
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default ConfirmationModal;
