import React from 'react';
import Modals from '../Modals';

const PreviewImage = ({ image, setShow }) => {
  return (
    <Modals setState={setShow}>
      <img src={image} alt="thumbnail" className="preview-image" />
    </Modals>
  );
};

export default PreviewImage;
