import React from 'react';

const RadioButton = ({ data, name, setState, state }) => {
  const refHandler = (e) => {
    setState(e.target.previousElementSibling.value);
  };

  return (
    <>
      {data.map((item, idx) => (
        <div className="g-radio" key={idx}>
          <input
            type="radio"
            name={name}
            id={item.id}
            hidden
            value={item.id}
            checked={state == item.id}
            onChange={(e) => setState(e.target.value)}
          />
          <div className="bullet" onClick={refHandler}></div>
          <label htmlFor={item.id}>{item.label}</label>
        </div>
      ))}
    </>
  );
};

export default RadioButton;
