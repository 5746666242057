import { createSlice } from '@reduxjs/toolkit';
import { getAllPromo, createPromo, getDetailPromo, deletePromo, updatePromo, uploadImagePromo } from './actions';

const initialState = {
  data: {},
  selectedPromo: {},
  imagePromo: '',
  isLoading: false,
  isError: false,
  errorMessage: '', // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const promo = createSlice({
  name: 'promo',
  initialState,
  reducers: {
    // reducers goes here
    resetSelectedPromo: (state) => {
      state.selectedPromo = {};
    },

    resetImagePromo: (state) => {
      state.imagePromo = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPromo.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getAllPromo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllPromo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createPromo.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(createPromo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDetailPromo.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getDetailPromo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDetailPromo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedPromo = action.payload;
      })
      .addCase(deletePromo.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(deletePromo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updatePromo.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(updatePromo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(uploadImagePromo.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
        state.imagePromo = '';
      })
      .addCase(uploadImagePromo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(uploadImagePromo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.imagePromo = action.payload;
      });
  },
});

export const { resetSelectedPromo, resetImagePromo } = promo.actions;
export default promo.reducer;
