import TextAreaInput from 'components/Global/TextArea';
import Modals from '../Modals';
import { Button } from 'components/Global';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { rejectOrder } from 'features/orders/actions';
import { getDetailOrder } from 'features/orders/detailOrderSlice';
import { useState } from 'react';

const RejectOrderModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showToast, setShowRejectOrderModal, setShowConfirmation, setShowSpinner } = useAppContext();

  const orderData = useSelector(getDetailOrder);
  const [reason, setReason] = useState('');

  const handleSubmit = async () => {
    // validation
    const validCancelMessage = reason.replace(/\s/g, '').length !== 0;

    // check if not valid return error toast
    if (!validCancelMessage) {
      return showToast({ type: 'error', message: 'Tulis Alasan Penolakan' });
    }

    // reject if form valid
    const payload = {
      transaction_key: orderData.transaction_key,
      message: reason,
    };

    setShowConfirmation({
      message: 'Apakah anda yakin ingin menolak orderan?',
      show: true,
      onClick: async () => {
        setShowRejectOrderModal(false);

        try {
          setShowSpinner(true);
          await dispatch(rejectOrder(payload)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Menolak Orderan' });
          setReason('');
          navigate(-1);
        } catch (err) {
          showToast({ type: 'error', message: 'Gagal Menolak Orderan!' });
        } finally {
          setShowSpinner(false);
        }
      },
    });
  };

  return (
    <Modals setState={setShowRejectOrderModal}>
      <div className="reject-order">
        <h3>Tulis Alasan Penolakan</h3>

        <div className="reject-order__message">
          <span>Keterangan Penolakan</span>
          <TextAreaInput
            className="reject-order__textarea"
            style={{ height: '292px' }}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Tulis keterangan penolakan"
          />
        </div>

        <div className="reject-order__button">
          <Button
            type="button"
            width="165px"
            height="39px"
            variant="primary"
            size="md"
            className="button"
            onClick={handleSubmit}
          >
            Lanjutkan
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default RejectOrderModal;
