import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const OffCanvasMenu = ({ menu, show, selectedPage, setSelectedPage }) => {
  const rentalLocation = useSelector((state) => state.rentalLocation.data);

  const getSubItemURL = (url) => {
    if (url.includes('locationId')) {
      if (!rentalLocation) return;
      const baliLocation = rentalLocation.find((item) => item.name?.toLowerCase() == 'bali');
      return baliLocation ? url.concat(baliLocation.id) : url.concat('ALL');
    }
    return url;
  };

  return (
    menu.sub_items.map((item) => item?.action?.read).reduce((acc, val) => acc + val, 0) > 0 && (
      <div
        key={menu.header}
        style={{
          width: show ? '287px' : '80px',
          justifyContent: show ? 'normal' : 'center',
        }}
        id={menu.header}
        className={`list-item ${selectedPage === menu.header ? 'active' : ''}`}
      >
        <div className="list-header" style={{ padding: show ? '0 24px 14px 24px' : '0 20px 14px 20px' }}>
          <p
            style={{
              fontWeight: '700',
              color: selectedPage === menu.header ? '#FFFFFF' : '#E0E0E0',
              display: show ? 'block' : 'none',
            }}
          >
            {menu.header}
          </p>
        </div>
        <div className="list-menu">
          {menu.sub_items.map(
            (sub_item) =>
              sub_item?.action?.read === 1 && (
                <Link to={getSubItemURL(sub_item.url)} key={sub_item.name}>
                  <div
                    className={`list-sub-menu ${selectedPage === sub_item.name ? 'active' : ''}`}
                    id={sub_item.name}
                    style={{
                      padding: show ? '12px 24px' : '12px 20px',
                      width: show ? '287px' : '80px',
                      justifyContent: show ? 'normal' : 'center',
                    }}
                    onClick={() => setSelectedPage(sub_item.name)}
                  >
                    <div
                      className={`icon ${selectedPage === sub_item.name ? 'active' : ''}`}
                      style={{
                        marginRight: show ? '18px' : '0',
                      }}
                    >
                      <img src={sub_item.icon} className={`${sub_item.name}-icon`} alt={`${sub_item.name} icon`} />
                    </div>
                    <span style={{ display: show ? 'block' : 'none' }}>{sub_item.name}</span>
                  </div>
                </Link>
              ),
          )}
        </div>
      </div>
    )
  );
};

export default OffCanvasMenu;
