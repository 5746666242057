import { createSlice } from '@reduxjs/toolkit';
import {
  getAllSchedulePrice,
  getDetailSchedulePrice,
  deleteSchedulePrice,
  createSchedulePrice,
  updateSchedulePrice,
} from './actions';

const initialState = {
  data: {},
  selectedSchedulePrice: {},
  isLoading: false,
  isError: false,
  errorMessage: '', // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const schedulePrice = createSlice({
  name: 'schedulePrice',
  initialState,
  reducers: {
    // reducers goes here
    resetSelectedSchedulePrice: (state) => {
      state.selectedSchedulePrice = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSchedulePrice.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getAllSchedulePrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllSchedulePrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getDetailSchedulePrice.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getDetailSchedulePrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDetailSchedulePrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedSchedulePrice = action.payload;
      })
      .addCase(deleteSchedulePrice.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(deleteSchedulePrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createSchedulePrice.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(createSchedulePrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateSchedulePrice.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(updateSchedulePrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export const { resetSelectedSchedulePrice } = schedulePrice.actions;
export default schedulePrice.reducer;
