import React from 'react';
import ReactQuill from 'react-quill';
import EditorToolbar, { modules, formats } from './editor-toolbar';
import 'react-quill/dist/quill.snow.css';

const TextAreaEditor = ({ value, onChange }) => {
  return (
    <div className="text-area-editor">
      <EditorToolbar />
      <ReactQuill
        placeholder="Tulis Template"
        className="text-area-editor__text-area"
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        // modules={{
        //   toolbar: [
        //     ['bold', 'italic', 'underline', 'strike'],
        //     ['link', 'image'],
        //     ['blockquote', 'code-block'],
        //     [{ header: [1, 2, 3, 4, 5, 6, false] }],
        //     [{ size: ['small', false, 'large', 'huge'] }],
        //     [{ color: [] }, { background: [] }],
        //     [{ font: [] }],
        //     [{ align: [] }],
        //     // ["undo", "redo"],
        //   ],
        //   history: {
        //     delay: 2000, // Delay in milliseconds before recording a new undo step
        //     maxStack: 500, // Maximum stack size for undo/redo
        //     userOnly: false, // Whether to only track changes made by the user
        //   },
        // }}
      />
    </div>
  );
};

export default TextAreaEditor;
