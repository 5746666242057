import { PaginationTable, Table, TableWrapper } from 'components/Global';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as MinimumRentIcon } from 'icons/min-rent-icon.svg';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBusinessRule, getAllBusinessRules } from 'features/business-rules/actions';
import EmptyState from 'components/Global/EmptyState';
import { getVehicleById } from 'features/vehicle/action';
import { useAppContext } from 'components/Context/AppContext';
import { resetBusinessRuleSelected } from 'features/business-rules/slice';
import { indonesianDateFormat } from 'utils/helpers';
import ButtonAdd from 'components/Global/ButtonAdd';

const MinimumRent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setShowSpinner, setShowConfirmation, showToast } = useAppContext();

  const { data: businessRuleData, status } = useSelector((state) => state.businessRules);

  const [currentPage, setCurrentPage] = useState(1);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    dispatch(getAllBusinessRules({ page: currentPage, type: 'Minimum Rental' }));
  }, [currentPage]);

  const handleEdit = (e) => {
    const id = e.target.parentElement.parentElement.dataset['key'];
    dispatch(resetBusinessRuleSelected());
    navigate(`/min-rent/edit-min-rent?id=${id}`);
  };

  const handleDelete = (e) => {
    const id = e.target.parentElement.parentElement.dataset['key'];
    setShowConfirmation({
      message: 'Apakah anda ingin menghapus data ini?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deleteBusinessRule(id)).unwrap();
          showToast({ type: 'success', message: 'Berhasil menghapus data!' });
          dispatch(getAllBusinessRules({ page: 1, type: 'Minimum Rental' }));
          setCurrentPage(1);
        } catch (err) {
          showToast({ type: 'error', message: 'Gagal menghapus data!' });
        }
      },
    });
  };

  useEffect(() => {
    if (Object.keys(businessRuleData).length === 0) return;
    if (!businessRuleData.business_rules) return;

    let vehiclesIds = [];

    for (const child of businessRuleData.business_rules) {
      if (Array.isArray(child.vehicles_id)) {
        for (const id of child.vehicles_id) {
          vehiclesIds.push(id);
        }
      }
    }

    const fetchVehicles = async () => {
      const promises = [...new Set(vehiclesIds)].map((id) => dispatch(getVehicleById(id)).unwrap());

      if (promises) {
        const results = await Promise.allSettled(promises);
        const fulfilledResults = results
          .filter((result) => result.status === 'fulfilled')
          .map((vehicle) => vehicle.value);

        setVehicles(fulfilledResults);
        return;
      }

      setVehicles([]);
    };

    fetchVehicles();
  }, [businessRuleData]);

  const RULE_LIST = useMemo(() => {
    if (!businessRuleData?.business_rules || vehicles.length === 0) return [];

    return businessRuleData.business_rules.map((item) => formatBusinessRule(item, vehicles));
  }, [businessRuleData, vehicles]);

  const handleAdd = () => {
    dispatch(resetBusinessRuleSelected());
    navigate('/min-rent/create-min-rent');
  };

  useEffect(() => {
    if (status === 'loading') {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [status]);

  return (
    <div className="min-rent">
      <div className="button-wrapper">
        <ButtonAdd onClick={handleAdd} label="Tambah Minimal Rental" />
      </div>

      {status !== 'loading' && RULE_LIST.length > 0 ? (
        <TableWrapper icon={<MinimumRentIcon fill="#009EF7" width="25px" height="25px" />} title="Minimal Rental">
          <Table
            column={column}
            actionBtn
            onEdit={handleEdit}
            onDel={handleDelete}
            data={RULE_LIST}
            currentPage={currentPage}
          />
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={businessRuleData?.Pagination?.total_data}
          />
        </TableWrapper>
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

export default MinimumRent;

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Periode Mulai', value: 'applied_start_date' },
  { header: 'Periode Selesai', value: 'applied_end_date' },
  { header: 'Jenis Mobil', value: 'min_rent_vehicles' },
  { header: 'Lokasi Sewa', value: 'business_rule_location' },
];

export const getVehicleNameWithCount = (id, vehicles, vehiclesName) => {
  const foundVehicle = vehicles?.find((vehicle) => vehicle.id === id);
  if (!foundVehicle) return vehiclesName;

  const existingVehicle = vehiclesName.find((v) => v.name === foundVehicle.name);
  if (existingVehicle) {
    existingVehicle.total += 1;
  } else {
    vehiclesName.push({ name: foundVehicle.name, total: 1 });
  }

  return vehiclesName;
};

const formatBusinessRule = (item, vehicles) => {
  const vehiclesName = item.vehicles_id.reduce((acc, id) => getVehicleNameWithCount(id, vehicles, acc), []);

  return {
    ...item,
    min_rent_vehicles: vehiclesName,
    applied_start_date: indonesianDateFormat(item.applied_start_date),
    applied_end_date: indonesianDateFormat(item.applied_end_date),
  };
};
