import React from 'react';
import Modals from '../Modals';
import { setCurrency } from 'utils/functionality';
import { Button } from 'components/Global';

const PriceDifferenceModal = ({ setShowPriceDifference, Icon, title, data }) => {
  return (
    <Modals
      setState={setShowPriceDifference}
      icon={<Icon fill="#009EF7" width="25px" height="25px" />}
      title={`${title} - Detail Perbedaan`}
    >
      <div className="price-difference-modal">
        <div className="price-difference__content">
          <table>
            <tr>
              <td>Harga Sesudah</td>
              <td>{setCurrency(data?.order_approval_history?.total_payment_after)}</td>
            </tr>
            <tr>
              <td>Harga Sebelum</td>
              <td>{setCurrency(data?.order_approval_history?.total_payment_before)}</td>
            </tr>
            <tr>
              <td>Selisih Harga</td>
              <td>{setCurrency(data?.order_approval_history?.total_refund_amount)}</td>
            </tr>
          </table>
          <p>*Selisih harga tersebut harus di transfer kembali kepada customer atau refund</p>
        </div>
        <div className="price-difference__button">
          <Button
            variant="outline"
            className="button"
            width={162}
            size="sm"
            onClick={() => setShowPriceDifference(false)}
          >
            Kembali
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default PriceDifferenceModal;
