import { createSlice } from '@reduxjs/toolkit';
import { fetchCancelledOrder, fetchOrderCancelation, updateOrderCancelation } from './actions';

const initialState = {
  data: {},
  orderCancelation: {},
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const cancelledOrder = createSlice({
  name: 'cancelledOrder',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCancelledOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCancelledOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchCancelledOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchOrderCancelation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderCancelation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchOrderCancelation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orderCancelation = action.payload;
      })
      .addCase(updateOrderCancelation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOrderCancelation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateOrderCancelation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orderCancelation = action.payload;
      });
  },
});

export default cancelledOrder.reducer;
