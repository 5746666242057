import React, { useEffect, useState } from 'react';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { PaginationTable, Table, TableWrapper } from 'components/Global';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCategory, getAllCategories } from 'features/category/actions';
import LoadingText from 'components/Global/LoadingText';
import EmptyState from 'components/Global/EmptyState';
import { getCategory, getCategoryStatus } from 'features/category/categorySlice';
import ButtonAdd from 'components/Global/ButtonAdd';

const VehicleCategory = () => {
  const dispatch = useDispatch();
  const { category, pagination } = useSelector(getCategory);
  const status = useSelector(getCategoryStatus);

  const { setShowAddCategory, setShowConfirmation, setShowSpinner, showToast } = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);

  const column = [
    { header: 'No.', value: 'no' },
    { header: 'Kategori', value: 'name' },
  ];

  const handleAddCategory = () => {
    setShowAddCategory(true);
  };

  const handleDelete = (e) => {
    const categoryId = e.target.parentElement.parentElement.dataset['key'];

    setShowConfirmation({
      message: 'Apakah anda yakin ingin menghapus Item',
      show: true,
      onClick: async () => {
        try {
          setShowSpinner(true);
          await dispatch(deleteCategory(categoryId)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Hapus Category' });
          setCurrentPage(1);
        } catch (error) {
          showToast({ type: 'error', message: 'Gagal Hapus Category' });
        } finally {
          setShowSpinner(false);
        }
      },
    });
  };

  useEffect(() => {
    dispatch(getAllCategories({ page: currentPage }));
  }, [currentPage]);

  if (status === 'loading') {
    return <LoadingText />;
  }

  if (!category || category.length === 0) {
    return (
      <div className="category">
        <ButtonAdd onClick={handleAddCategory} label="Tambah Kategori" />
        <EmptyState />
      </div>
    );
  }

  return (
    <div className="category">
      <ButtonAdd onClick={handleAddCategory} label="Tambah Kategori" />

      <TableWrapper title="Kategori" icon={<CarIcon fill="#009EF7" width="25px" height="25px" />}>
        <Table column={column} data={category} actionBtn onDel={handleDelete} currentPage={currentPage} />
        <PaginationTable
          currentPage={currentPage}
          onPageChange={(newPage) => setCurrentPage(newPage)}
          totalCount={pagination.total_data}
        />
      </TableWrapper>
    </div>
  );
};

export default VehicleCategory;
