import React from 'react';
import { useState } from 'react';
import RoleAssignmentRow from './RoleRow';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../LoadingSpinner';

export const decimalToBinary = (value) => value?.toString(2).padStart(4, '0');

const RoleAssignmentComponent = ({
  permissionList,
  selectedPermissions,
  setUpdateRolePermissionData,
  data,
  setData,
  setAddRolePermissionData,
}) => {
  const isLoading = useSelector((state) => state.permissions.isLoading);
  const [selected, setSelected] = useState({ id: 0, permission_name: '' });

  const binaryToDecimal = (binaryValue) => parseInt(Object.values(binaryValue)?.join(''), 2);

  useEffect(() => {
    if (!permissionList.length) return;
    const processData = () => {
      let tempArr = [];
      permissionList?.map((permission_item) => {
        const isExist = selectedPermissions?.find((role_item) => role_item.permission_id === permission_item.id);
        if (isExist) {
          tempArr.push({
            ...permission_item,
            role_permission_id: isExist.id,
            access: binaryToDecimal({
              delete: isExist.action.delete,
              update: isExist.action.update,
              create: isExist.action.create,
              read: isExist.action.read,
            }),
          });
        } else {
          tempArr.push({
            ...permission_item,
            access: 0,
          });
        }
      });

      setData(tempArr.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)));
    };

    processData();
  }, [permissionList, selectedPermissions]);

  useEffect(() => {
    if (!selected.id) return;
    const prevRolePermission = selectedPermissions?.find((item) => item.permission_id === selected?.id);
    // for updating access level
    if (prevRolePermission) {
      setUpdateRolePermissionData((prev) => {
        const isExist = prev?.find((item) => item.id === selected.id);
        const selectedData = data.find((item) => item.id === selected.id);

        if (isExist) {
          if (selectedData.access === prevRolePermission?.access) {
            return prev.filter((item) => item.id !== selected.id);
          }
          return prev.map((item) => (item.id === selected.id ? selectedData : item));
        } else {
          return [...prev, selectedData];
        }
      });
      return;
    }
    // add new role permission, if there is no role permission
    setAddRolePermissionData((prev) => {
      const isExist = prev?.find((item) => item.id === selected.id);
      const selectedData = data?.find((item) => item.id === selected?.id);

      if (isExist) {
        return prev.map((item) => (item.id === selected.id ? selectedData : item));
      } else {
        return [...prev, selectedData];
      }
    });
  }, [data, selectedPermissions]);

  return (
    <div className="role-assignment">
      <div>
        <table className="role-assignment-table">
          <thead className="role-assignment-table__header">
            <tr>
              <td rowSpan={2} style={{ paddingLeft: '24px' }}>
                Nama Menu
              </td>
              <td className="td-center br-left-bottom" colSpan={4}>
                Permission
              </td>
            </tr>
            <tr>
              <td className="td-center br-left">Create</td>
              <td className="td-center br-left">Update</td>
              <td className="td-center br-left">Delete</td>
              <td className="td-center br-left">Read</td>
            </tr>
          </thead>

          <tbody className="role-assignment-table__body">
            {isLoading ? null : data.length > 0 ? (
              data?.map((item, idx) => (
                <RoleAssignmentRow key={idx} item={item} setData={setData} setSelected={setSelected} />
              ))
            ) : (
              <tr className="no-permission">
                <td colSpan={5}>Role Ini Belum Memiliki Permission</td>
              </tr>
            )}
          </tbody>
        </table>
        {isLoading ? <LoadingSpinner /> : null}
      </div>
    </div>
  );
};

export default RoleAssignmentComponent;
