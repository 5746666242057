import { createSlice } from '@reduxjs/toolkit';
import { deleteCity, createCity, getAllCities, getMasterCities, getMasterProvinces } from './actions';

const initialState = {
  data: [],
  masterCities: {},
  masterProvinces: {},
  selected: {},
  isLoading: false,
  isCreateSuccessful: false,
  isError: false,
  errorMessage: '', // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const cities = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    // reducers goes here
    resetCreateCity: (state) => {
      state.isCreateSuccessful = false;
      state.masterCities = {};
    },
    resetMasterCity: (state) => {
      state.masterCities = {};
    },
    selectCity: (state, action) => {
      state.selected = action.payload;
    },
    resetSelectCity: (state) => {
      state.selected = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCities.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getAllCities.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllCities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getMasterCities.pending, (state) => {
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getMasterCities.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getMasterCities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.masterCities = action.payload;
      })
      .addCase(getMasterProvinces.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
        state.masterCities = {};
      })
      .addCase(getMasterProvinces.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getMasterProvinces.fulfilled, (state, action) => {
        state.isLoading = false;
        state.masterProvinces = action.payload;
      })
      .addCase(createCity.pending, (state) => {
        state.isCreateSuccessful = false;
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(createCity.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createCity.fulfilled, (state) => {
        state.isLoading = false;
        state.isCreateSuccessful = true;
      })
      .addCase(deleteCity.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(deleteCity.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteCity.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export const { resetCreateCity, resetMasterCity, selectCity, resetSelectCity } = cities.actions;

export default cities.reducer;
