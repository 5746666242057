import { createSlice } from '@reduxjs/toolkit';
import { fetchViolationsOrder } from '.';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const violations = createSlice({
  name: 'violations',
  initialState,
  reducers: {
    // reducers goes here

    addViolations: (state, action) => {
      if (state.data.violations) {
        state.data.violations = [...state.data.violations, action.payload];
      } else {
        let violationsArray = [action.payload];
        state.data.violations = violationsArray;
      }
    },

    deleteViolations: (state, action) => {
      const copyViolationsArray = [...state.data.violations];
      state.data.violations = copyViolationsArray.filter((item) => item.id !== action.payload);
    },

    resetViolations: (state) => {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchViolationsOrder.pending, (state) => {
        state.data = {};
        state.isLoading = true;
      })
      .addCase(fetchViolationsOrder.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.errorMessage = action.payload;
      })
      .addCase(fetchViolationsOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});

export const { addViolations, deleteViolations, resetViolations } = violations.actions;
export default violations.reducer;
