import { Button, InputField, PaginationTable, RadioButton, TableWrapper } from 'components/Global';
import { ReactComponent as PeopleIcon } from 'icons/people-filled-icon.svg';
import { ReactComponent as ArrowLeft } from 'icons/arrow-left.svg';
import { ReactComponent as History } from 'icons/history.svg';
import { useAppContext } from 'components/Context/AppContext';
import { checkEmptyObject, checkPermission, setCurrency } from 'utils/functionality';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  editUserInfluencer,
  fetchAllUsersInfluencer,
  fetchInfluencerInfo,
  fetchInfluencerReferralHistory,
  generateReferralCode,
} from 'features/user/actions';
import LoadingSpinner from 'components/Global/LoadingSpinner';
import EmptyState from 'components/Global/EmptyState';
import { fetchOrderById } from 'features/orders/actions';
import { indonesianDateFormat } from 'utils/helpers';
import StatusButton from 'components/Global/Table/StatusButton';
import { isDecimal } from '../AddUserInfluencer';

const historyColumn = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Lengkap', value: 'user_name' },
  { header: 'Kode Order', value: 'order_transaction_key' },
  { header: 'Tanggal Redeem', value: 'date' },
  { header: 'Tanggal Sewa', value: 'order_date' },
  { header: 'Status', value: 'status' },
];

const arrayRadionButton = [
  {
    id: 'pr1',
    label: 'Harga Fixed',
    value: 'fixed',
  },
  {
    id: 'pr2',
    label: 'Harga Percentage',
    value: 'percentage',
  },
];

const InfluencerDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { singleUser: data, historyReferrer, isLoading } = useSelector((state) => state.userInfluencer);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  const [editData, setEditData] = useState({
    name: '',
    referral: '',
  });
  const [priceType, setPriceType] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [comissionValue, setComissionValue] = useState({
    value_type: '',
    value: '',
  });
  const [isUserBlocked, setIsUserBlocked] = useState(false);

  const { setShowBlockConfirmation, showToast } = useAppContext();

  const params = useParams();

  const disableSaveButton =
    data?.name === editData.name &&
    data?.referral === editData.referral &&
    data?.referral_setting?.value === comissionValue.value &&
    data?.referral_setting?.value_type === comissionValue.value_type &&
    data?.is_influencer_active === !isUserBlocked;

  const blockUserHandler = () => {
    setIsUserBlocked((prev) => !prev);
  };

  const saveHandler = async () => {
    if (editData.name.trim() === '') {
      return showToast({ type: 'warning', message: 'Harap Memasukan Nama Influencer' });
    } else if (editData.referral.trim() === '') {
      return showToast({ type: 'warning', message: 'Harap Memilih Kode Referral' });
    } else if (isNaN(comissionValue.value) || comissionValue.value === '') {
      return showToast({ type: 'warning', message: 'Harap Mengisi Nilai Komisi' });
    } else if (comissionValue.value < 0) {
      return showToast({ type: 'warning', message: 'Nilai Komisi Tidak Valid' });
    } else if (comissionValue.value_type === 'fixed' && isDecimal(comissionValue.value)) {
      return showToast({ type: 'warning', message: 'Nilai Komisi Tidak Valid' });
    }

    const payload = {
      fullname: editData.name,
      referral: editData.referral,
      value_type: comissionValue.value_type,
      value: comissionValue.value,
      is_influencer_active: !isUserBlocked,
    };

    if (data.is_influencer_active !== !isUserBlocked) {
      setShowBlockConfirmation({
        show: true,
        message: !isUserBlocked
          ? 'Apakah anda yakin ingin mengaktifkan user kembali?'
          : 'Apakah anda yakin ingin Memblokir User?',
        onClick: async () => {
          try {
            await dispatch(editUserInfluencer({ id: params.id, data: payload })).unwrap();
            showToast({ type: 'success', message: 'Berhasil Update User Influencer' });
            navigate('/user-influencer');
            dispatch(fetchAllUsersInfluencer(1));
          } catch (error) {
            showToast({ type: 'error', message: 'Gagal Update User Influencer' });
          } finally {
            setShowBlockConfirmation(false);
          }
        },
      });
    } else {
      try {
        await dispatch(editUserInfluencer({ id: params.id, data: payload })).unwrap();
        showToast({ type: 'success', message: 'Berhasil Update User Influencer' });
        navigate('/user-influencer');
        dispatch(fetchAllUsersInfluencer(1));
      } catch (error) {
        showToast({ type: 'error', message: 'Gagal Update User Influencer' });
      } finally {
        setShowBlockConfirmation(false);
      }
    }
  };

  const getURLDestination = async (transaction_key) => {
    const data = await dispatch(fetchOrderById(transaction_key));

    let orderType = '';

    if (data?.payload?.order_type_id === 1) {
      if (data?.payload?.order_detail.without_driver) {
        orderType = 'without-driver';
      } else {
        orderType = 'with-driver';
      }
    } else if (data?.payload?.order_type_id === 2) {
      orderType = 'airport-transfer';
    }

    return orderType;
  };

  const handleNavigate = async (transactionKey, customerId) => {
    if (!transactionKey) return;
    const urlDestination = await getURLDestination(transactionKey);

    if (!urlDestination) return showToast({ type: 'error', message: 'Gagal Mendapatkan Detail Order' });

    navigate({
      pathname: `/${urlDestination}/detail/${transactionKey}`,
      search: createSearchParams({
        customerId: customerId,
      }).toString(),
    });
  };

  const handleValueInput = (e) => {
    const normalizedValue = e.target.value.replace(',', '.');

    if (priceType === 'pr1') {
      // Allow only digits and negative sign
      if (/^\d*$/.test(normalizedValue)) {
        setComissionValue({ ...comissionValue, value: isNaN(parseInt(e.target.value)) ? '' : +normalizedValue });
      }
    } else {
      setComissionValue({ ...comissionValue, value: isNaN(parseInt(e.target.value)) ? '' : +normalizedValue });
    }
  };

  const handleValueType = (id) => {
    setPriceType(id);
    const valueType = arrayRadionButton.find((item) => item.id == id)?.value;

    setComissionValue({ ...comissionValue, value_type: valueType });
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const generateRandomCode = async () => {
    const res = await dispatch(generateReferralCode(editData.name)).unwrap();
    setEditData((prev) => ({ ...prev, referral: res.code }));
  };

  useEffect(() => {
    if (!params?.id) return;
    dispatch(fetchInfluencerInfo(params.id));
    dispatch(fetchInfluencerReferralHistory(params.id));
  }, [params]);

  useEffect(() => {
    if (checkEmptyObject(data)) return;
    setEditData({ name: data.name, referral: data.referral });
    setIsUserBlocked(!data.is_influencer_active);
    setComissionValue({
      value: data.referral_setting.value,
      value_type: data.referral_setting.value_type,
    });
    setPriceType(arrayRadionButton.find((item) => item.value === data.referral_setting.value_type).id);
  }, [data]);

  const pageNumber = (page, idx) => (page > 1 ? (page - 1) * 10 + idx : idx);

  const referralHistoryData = historyReferrer?.data?.map((item) => ({
    user_name: item.user_name,
    user_id: item.user_id,
    order_transaction_key: item?.metadata?.order_code ? item.metadata.order_code : '-',
    transaction_key: item?.metadata?.order_transaction_key ? item.metadata.order_transaction_key : '',
    date: item.date,
    order_date: item?.metadata?.order_date ? item.metadata.order_date.split(' ')[0] : '',
    status: item?.metadata?.status ? item.metadata.status.toLowerCase() : item.status.toLowerCase(),
  }));

  const rows = 10 - referralHistoryData?.length;

  return (
    <div className="user__add-user-influencer">
      <button className="user__back-btn" onClick={() => navigate(-1)}>
        <ArrowLeft />
        <span>Kembali</span>
      </button>

      <div className="user-influencer__data">
        <TableWrapper title="Detail Influencer" icon={<PeopleIcon fill="#FF9900" width="25px" height="25px" />}>
          <div className="user__add-user-influencer__form">
            <InputField
              label="Nama Influencer"
              placeholder="Tulis Nama Influencer"
              value={editData.name || ''}
              onChange={(e) => setEditData((prev) => ({ ...prev, name: e.target.value }))}
            />

            <div className="referral-code-field">
              <InputField
                label="Kode Referral"
                placeholder="Kode Referral"
                value={editData.referral || ''}
                onChange={(e) => setEditData((prev) => ({ ...prev, referral: e.target.value }))}
              />
              <Button className="random-code-btn" disabled={!editData.name} onClick={generateRandomCode}>
                Random Code
              </Button>
            </div>

            <div className="comission-field">
              <h3>Komisi</h3>
              <div className="radio">
                <RadioButton name="price" data={arrayRadionButton} state={priceType} setState={handleValueType} />
              </div>

              {(priceType === 'pr1' || priceType === '') && (
                <InputField
                  type="number"
                  placeholder="Rp 0"
                  value={comissionValue.value}
                  onChange={handleValueInput}
                  onWheel={numberInputOnWheelPreventChange}
                  disable={!priceType}
                  min={0}
                />
              )}

              {priceType === 'pr2' && (
                <InputField
                  type="number"
                  placeholder="0%"
                  value={comissionValue.value}
                  onChange={handleValueInput}
                  onWheel={numberInputOnWheelPreventChange}
                  disable={!priceType}
                  min={0}
                />
              )}
            </div>

            <div className="double-column">
              <InputField
                label="Total Komisi"
                placeholder="Rp. 0"
                disable
                value={setCurrency(historyReferrer?.total_commission) || 0}
              />
              <InputField label="Redeem" placeholder="0" disable value={historyReferrer?.total_redeem || 0} />
            </div>

            <div className="status-info">
              <h3>Status Influencer</h3>
              <div className="status-info__toggle-wrapper">
                <div className="user__toggle-status">
                  <label className="switch">
                    <input type="checkbox" onChange={blockUserHandler} role={'switch'} checked={!isUserBlocked} />
                    <span className="slider"></span>
                  </label>
                </div>
                <div className="user__status-info">
                  {isUserBlocked ? (
                    <span className="user__status-badge user__status-badge--blocked">Inactive</span>
                  ) : (
                    <span className="user__status-badge user__status-badge--active">Active</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          {checkPermission(offCanvasMenu, currentMenu, 'update') && (
            <div className="user__save-btn">
              <Button width={208} size="sm" onClick={saveHandler} disabled={disableSaveButton}>
                Simpan
              </Button>
            </div>
          )}
        </TableWrapper>
      </div>

      <div className="user-influencer__history">
        <TableWrapper title="History Redeem Referral" icon={<History fill="#FF9900" width="25px" height="25px" />}>
          {isLoading ? (
            <LoadingSpinner />
          ) : referralHistoryData?.length === 0 ? (
            <EmptyState />
          ) : (
            <>
              <div className="table">
                <table className="table-wrapper">
                  <thead className="table-head">
                    <tr>
                      {historyColumn.map((item, i) => (
                        <th key={i}>{item.header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {referralHistoryData?.length > 0 &&
                      referralHistoryData.map((item, no) => (
                        <tr key={no}>
                          {historyColumn.map((col, idx) => {
                            if (col.value === 'no') {
                              return (
                                <td
                                  key={idx}
                                  data-type="no"
                                  className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                                >
                                  {pageNumber(currentPage, no + 1)}
                                </td>
                              );
                            } else if (col.value === 'order_transaction_key') {
                              return (
                                <td
                                  key={idx}
                                  data-type={'order_transaction_key'}
                                  className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                                  data-key={item.id}
                                >
                                  <span
                                    className="order-transaction-key"
                                    onClick={() => handleNavigate(item?.transaction_key, item.user_id)}
                                  >
                                    {item[col.value]}
                                  </span>
                                </td>
                              );
                            } else if (col.value === 'date' || col.value === 'order_date') {
                              return (
                                <td
                                  key={idx}
                                  data-type={'date'}
                                  className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                                  data-key={item.id}
                                >
                                  {item[col.value] ? indonesianDateFormat(item[col.value]) : '-'}
                                </td>
                              );
                            } else if (col.value === 'status') {
                              return (
                                <td
                                  key={idx}
                                  data-type={'status'}
                                  className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                                >
                                  {<StatusButton status={item[col.value]} />}
                                </td>
                              );
                            } else {
                              return (
                                <td
                                  key={idx}
                                  data-type={col.value}
                                  className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                                >
                                  {item[col.value]}
                                </td>
                              );
                            }
                          })}
                        </tr>
                      ))}
                    {rows > 0 &&
                      [...Array(rows)].map((_, idx) => (
                        <tr key={idx}>
                          {historyColumn.map((_, idx) => (
                            <td key={idx}></td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <PaginationTable
                currentPage={currentPage}
                onPageChange={(newPage) => setCurrentPage(newPage)}
                totalCount={historyReferrer?.pagination?.total}
              />
            </>
          )}
        </TableWrapper>
      </div>
    </div>
  );
};

export default InfluencerDetail;
