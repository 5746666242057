import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Carousel from 'react-multi-carousel';
import { useAppContext } from 'components/Context/AppContext';
import { checkEmptyObject } from 'utils/functionality';
import Modals from '../Modals';
import { ReactComponent as AdditionalImageIcon } from 'icons/additional-item-custom-order-icon.svg';
import { ReactComponent as LeftArrow } from 'icons/arrow-left-circle-white-icon.svg';
import { ReactComponent as RightArrow } from 'icons/arrow-right-circle-white-icon.svg';

import 'react-multi-carousel/lib/styles.css';

// RESPONSIVE BREAKPOINT FOR CARAOUSEL
const responsive = {
  desktop: {
    breakpoint: {
      max: 8000,
      min: 1024,
    },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 700,
    },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: {
      max: 699,
      min: 0,
    },
    items: 1,
    slidesToSlide: 1,
  },
};

// CUSTOM ARROW COMPONENT
const CustomLeftArrow = ({ onClick }) => (
  <div
    onClick={() => onClick?.()}
    style={{
      position: 'absolute',
      left: 16,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 10,
      width: '24px',
      height: '24px',
      cursor: 'pointer',
    }}
  >
    <LeftArrow className="text-[16px] text-primary-500 transition-colors lg:text-[20px]" />
  </div>
);

// CUSTOM ARROW COMPONENT
const CustomRightArrow = ({ onClick }) => (
  <div
    onClick={() => onClick?.()}
    style={{
      position: 'absolute',
      right: 36,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 10,
      width: '24px',
      height: '24px',
      cursor: 'pointer',
    }}
  >
    <RightArrow className="text-[16px] text-primary-500 transition-colors lg:text-[20px]" />
  </div>
);

const AdditionalImageModal = () => {
  const carouselRef = useRef(null);

  const { setShowAdditionalImageModal } = useAppContext();

  const { selectedAddon } = useSelector((state) => state.addon);

  const [visibleSlide, setVisibleSlide] = useState(0);
  const [images, setImages] = useState([]);

  const afterCahngeHandler = (__, { currentSlide }) => {
    setVisibleSlide(currentSlide);
  };

  const imageListClickHandler = (index) => {
    carouselRef?.current?.goToSlide(index);
  };

  useEffect(() => {
    if (!selectedAddon || checkEmptyObject(selectedAddon)) return;

    const additionalImages = selectedAddon.varieties[0]?.images.map((img) => img.file_name);
    setImages(additionalImages);
  }, [selectedAddon]);

  return (
    <Modals
      setState={setShowAdditionalImageModal}
      title="Additional (Optional)"
      icon={<AdditionalImageIcon fill="#009EF7" width="25px" height="25px" />}
    >
      <div className="additional-images">
        <div className="additional-images__show-image">
          <Carousel
            ref={carouselRef}
            responsive={responsive}
            swipeable
            arrows={true}
            renderDotsOutside
            renderArrowsWhenDisabled
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            className="image-wrapper"
            afterChange={(previousSlide, { currentSlide }) => afterCahngeHandler(previousSlide, { currentSlide })}
          >
            {images.map((item, idx) => (
              <div className="image" key={idx}>
                <img src={item} alt={`${item}-image`} />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="additional-images__image-list">
          <div className="image-wrapper">
            {images.map((item, idx) => (
              <div
                className={clsx('image', visibleSlide === idx ? 'active' : '')}
                key={idx}
                onClick={() => imageListClickHandler(idx)}
              >
                <img src={item} alt={`${item}-image`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modals>
  );
};

export default AdditionalImageModal;
