import Modals from '../Modals';

const Spinner = () => (
  <Modals>
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </Modals>
);

export default Spinner;
