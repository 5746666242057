import React from 'react';
import { useAppContext } from 'components/Context/AppContext';
import Modals from '../Modals';
import { ReactComponent as Xmark } from '../../../icons/x-sign-icon.svg';
import { useSelector } from 'react-redux';

const ZonePrice = () => {
  // CONTEXT
  const { setShowZonePrice } = useAppContext();

  const { selectedZone } = useSelector((state) => state.zone);
  const { data: categoryData } = useSelector((state) => state.category);

  return (
    <Modals setState={setShowZonePrice}>
      <div className="zone-price">
        <div className="zone-price__head">Area {selectedZone?.name}</div>
        <p className="zone-price__desc">
          Area {selectedZone?.name} penyewaan meliputi harga tambahan mobil menyesuaikan harga jenis mobil, dengan harga
          sebagai berikut :
        </p>
        <ul className="zone-price__category-price">
          {selectedZone?.category.map((item) => (
            <li key={item.category_id}>
              {categoryData.category?.find((cat) => cat.id === item.category_id).name} {`(+IDR ${item.price})`}
            </li>
          ))}
        </ul>
        <Xmark className="zone-price__x-mark" onClick={() => setShowZonePrice(false)} />
      </div>
    </Modals>
  );
};

export default ZonePrice;
