import { createSlice } from '@reduxjs/toolkit';
import { getServices, getServicesByRentalLocation } from './actions';

const initialState = {
  data: [],
  selected: {
    id: 0,
    name: '',
    sub_services: [],
  },
  selectedSubService: {
    id: 0,
    name: '',
    facilities: [],
  },
  selectedFacility: 0,
  isLoading: false,
  isError: false,
  errorMessage: '',
};

export const services = createSlice({
  name: 'services',
  initialState,
  reducers: {
    // reducers goes here

    setSelectedService: (state, action) => {
      state.selected = action.payload;
    },
    setSelectedSubService: (state, action) => {
      state.selectedSubService = action.payload;
    },
    setSelectedFacility: (state, action) => {
      state.selectedFacility = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServices.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = [];
      })
      .addCase(getServices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getServicesByRentalLocation.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getServicesByRentalLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getServicesByRentalLocation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});

export const { setSelectedService, setSelectedSubService, setSelectedFacility } = services.actions;

export default services.reducer;
