import { Table } from 'components/Global';
import { ReactComponent as SortIcon } from 'icons/sort-icon.svg';
import React from 'react';
import { indonesianDateFormat } from 'utils/helpers';
import { columnReview } from 'utils/order/order-column';

const RefundTable = () => {
  return (
    <div className="refund-table">
      <header className="table-header">
        <div className="table-title">
          <div className="title">
            <div className="desc">
              <SortIcon width="25px" height="25px" />
              <h1>Total Pengembalian</h1>
            </div>
            <p>IDR 5.000.000</p>
          </div>
          <div className="title">
            <div className="desc">
              <SortIcon width="25px" height="25px" />
              <h1>Jumlah</h1>
            </div>
            <p>10</p>
          </div>
        </div>
        <div className="table-filter">
          <p className="link">Lihat Semua</p>
        </div>
      </header>
      <Table column={columnReview} maxRows={4} data={tableData} actionBtn onDetail={() => {}} />
    </div>
  );
};

export default RefundTable;

const tableData = [
  {
    id: 3,
    name: 'Kevin Sanjaya',
    car_type: 'Toyota Fortuner',
    start_date: indonesianDateFormat('2022-11-20'),
  },
];
