import React, { useEffect } from 'react';
import clsx from 'clsx';
import { ReactComponent as ChevronDown } from '../../../icons/chevron-down.svg';
import { useState } from 'react';
import CheckboxField from '../CheckboxField';
import { useRef } from 'react';

const VehiclesFilter = ({ data, onChange, className, style, disable, placeholder }) => {
  const dropdownRef = useRef(null);
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  useEffect(() => {
    // Add a click event listener to the document to handle clicks outside the dropdown.
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts.
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsShowDropdown(!isShowDropdown);
  };

  return (
    <div className={clsx('vehicles-filter', className)} style={style} ref={dropdownRef}>
      <div className={clsx('vehicles-filter__field', disable ? 'disable' : '')} onClick={toggleDropdown}>
        <div className="vehicles-filter__field__input">{placeholder}</div>
        <ChevronDown className={`vehicles-filter__field__chevron ${isShowDropdown ? 'down' : ''}`} />
      </div>

      {/* drop down */}
      {isShowDropdown && (
        <ul className="vehicles-filter__field__dropdown">
          {data ? (
            data.map((item) => (
              <li key={item.id}>
                <CheckboxField
                  label={item.name}
                  value={item.id}
                  checked={item.checked}
                  readOnly={true}
                  id={item.id}
                  onChange={onChange}
                />
              </li>
            ))
          ) : (
            <li>Data Not Found</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default VehiclesFilter;
