import { useAppContext } from 'components/Context/AppContext';
import Modals from '../Modals';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { Button, InputField } from 'components/Global';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createCategory } from 'features/category/actions';

const AddCategoryModal = () => {
  const dispatch = useDispatch();
  const { setShowAddCategory, showToast, setShowSpinner } = useAppContext();

  const [newCategory, setNewCategory] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setShowSpinner(true);
      await dispatch(createCategory({ name: newCategory })).unwrap();
      showToast({ type: 'success', message: 'Berhasil Tambah Category' });
    } catch {
      showToast({ type: 'error', message: 'Gagal Tambah Category' });
    } finally {
      setShowSpinner(false);
      setShowAddCategory(false);
    }
  };

  return (
    <Modals
      setState={setShowAddCategory}
      title="Tambah Kategori"
      icon={<CarIcon fill="#009EF7" width="25px" height="25px" />}
    >
      <div className="add-brand-car">
        <InputField
          label="Nama Kategori"
          htmlFor="category"
          placeholder="Tulis nama kategori"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
        />
        <div className="add-brand-car__button-field">
          <Button size="sm" variant="outline" width="192px" onClick={() => setShowAddCategory(false)}>
            Kembali
          </Button>
          <Button size="sm" width="192px" onClick={handleSubmit}>
            Simpan
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default AddCategoryModal;
