import { createSlice } from '@reduxjs/toolkit';
import { getAllAirportPackages, createAirportPackages, deleteAirportPackages } from './actions';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  errorMessage: {}, // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const airportPackages = createSlice({
  name: 'airportPackages',
  initialState,
  reducers: {
    // reducers goes here

    resetAirportPackageData: (state) => {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAirportPackages.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllAirportPackages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllAirportPackages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createAirportPackages.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(createAirportPackages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createAirportPackages.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteAirportPackages.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(deleteAirportPackages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteAirportPackages.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export const { resetAirportPackageData } = airportPackages.actions;
export default airportPackages.reducer;
