import React from 'react';
import Modals from '../Modals';
import { useAppContext } from 'components/Context/AppContext';
import { ReactComponent as RoleFilledIcon } from 'icons/role-filled-icon.svg';
import { Button } from 'components/Global';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assignUserRole } from 'features/roles/actions';
import ClickOutside from 'components/Global/ClickOutside';
import { ReactComponent as ArrowDown } from 'icons/arrow-down-icon.svg';
import { getAllEmployees } from 'features/employee/actions';

const AddRolesAssignModal = () => {
  const { setShowAddRolesAssignModal, setShowSpinner, showToast } = useAppContext();
  const [roleId, setRoleId] = useState({
    id: 0,
    name: '',
  });
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.roles.data);
  const user = useSelector((state) => state.user.singleUser);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (roleId.name.trim() === '') {
      showToast({ type: 'warning', message: 'Harap Memilih Role' });
      return;
    }

    try {
      await dispatch(assignUserRole({ user_id: user.id, role_id: roleId.id })).unwrap();

      setShowSpinner(true);
      showToast({ type: 'success', message: 'Berhasil Menambahkan Data' });
      return;
    } catch (error) {
      showToast({ type: 'error', message: `Terjadi Kesalahan: ${error}` });
    } finally {
      setShowSpinner(false);
      setShowAddRolesAssignModal(false);
      dispatch(getAllEmployees());
    }
  };

  return (
    <Modals
      setState={() => setShowAddRolesAssignModal(false)}
      icon={<RoleFilledIcon fill="#009EF7" width="25px" height="25px" />}
      title="Assign Role"
    >
      <div className="add-roles-modal">
        <div className="add-roles-content">
          <div className={'g-input select-permission'}>
            <label htmlFor={'role'} className="input-title">
              Pilih Role
            </label>
            <div
              className={'select-permission-field'}
              placeholder="Pilih role tersedia"
              onClick={() => setShowDropdown((prev) => !prev)}
            >
              {!roleId.id ? <option value="null">Pilih role tersedia</option> : <p>{roleId.name}</p>}
              <ArrowDown className="arrow-down-icon" />
            </div>

            {showDropdown ? (
              <ClickOutside onClickOutside={() => setShowDropdown(false)}>
                <div className="select-permission-list">
                  <h4 className="select-permission-list__title">Pilih Role Tersedia</h4>
                  <ul className="select-permission-list__dropdown">
                    {roles.roles.map((item) => (
                      <li
                        key={item.id}
                        id={item.id}
                        onClick={() => {
                          setRoleId(item);
                          setShowDropdown(false);
                        }}
                        className="select-permission-list__item"
                      >
                        <div>{item.name}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              </ClickOutside>
            ) : null}
          </div>
        </div>
        <div className="add-roles-buttons">
          <Button
            className="button"
            size="sm"
            width="192px"
            role="submit"
            disabled={roleId.name === ''}
            onClick={handleSubmit}
          >
            Simpan
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default AddRolesAssignModal;
