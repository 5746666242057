export const isEmpty = (value) => value.trim() === '';
export const isSixChars = (value) => value.trim().length > 5;

export const indonesianDateFormat = (date) => {
  const months = 'Januari_Februari_Maret_April_Mei_Juni_Juli_Agustus_September_Oktober_November_Desember'.split('_');
  const arr = date?.split('-');
  const newDateFormat = arr === undefined ? '-' : arr[2] + ' ' + months[+arr[1] - 1] + ' ' + arr[0];

  return newDateFormat;
};

// ADD ZERO AT THE BEGINNING OF NUMBER
export function addLeadingZero(code, phoneNumber) {
  // Check if the phone number has min 11 digits and starts with "8"
  if ((phoneNumber.length === 11 || phoneNumber.length === 12) && phoneNumber.charAt(0) === '8') {
    // Add a leading zero to the phone number if indonesian region
    return '0' + phoneNumber;
  }

  // Return an original phone number if the number starts with "0"
  if ((phoneNumber.length === 11 || phoneNumber.length === 12) && phoneNumber.charAt(0) === '0') {
    return phoneNumber;
  }

  // If the phone number doesn't meet the conditions above,
  // return original number if user typed the dial code
  // return number that has been added a dial code if user didnt type the dial code
  const dialNumber = code
    .split('')
    .slice(1, code.length - 1)
    .join('');
  const userFrontNumber = phoneNumber.split('').slice(0, dialNumber.length).join('');

  if (dialNumber === userFrontNumber) {
    return phoneNumber;
  } else {
    return `${dialNumber}${phoneNumber}`;
  }
}

// FORMAT HOUR TO 12 CLOCK FORMAT WITH AM OR PM
export const addedHourPeriod = (time) => {
  // const hour = time.split(':')[0];
  // const minute = time.split(':')[1];

  // if (+hour < 13) {
  //   return `${time} AM`;
  // } else if (+hour >= 13) {
  //   const formatedHour = +hour - 12;
  //   if (formatedHour < 10) {
  //     return `0${formatedHour}:${minute} PM`;
  //   }
  //   return `${time} PM`;
  // }

  const splitTime = time.split(':');
  const hours = parseInt(splitTime[0]);
  const minutes = parseInt(splitTime[1]);

  const period = hours >= 12 ? 'PM' : 'AM';

  let formattedHours = hours % 12;
  formattedHours = formattedHours ? formattedHours : 12;

  const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;

  return formattedTime;
};
