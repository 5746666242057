import React, { useState } from 'react';
import Modals from '../Modals';
import { Button, InputField } from 'components/Global';
import { useAppContext } from 'components/Context/AppContext';

const AddAdditionalModal = ({ setShowAddAdditionalModal, setAdditionalList, onConfirm }) => {
  const { showToast } = useAppContext();
  const [data, setData] = useState({
    name: '',
    value: '',
  });

  const confirmationHandler = () => {
    if (data.name.trim() === '' || (typeof data.value === 'string' && data.value.trim() === '') || data.value === 0) {
      showToast({ type: 'warning', message: 'Harap isi semua data' });
      return;
    }
    if (onConfirm) {
      onConfirm({ name: data?.name, value: data?.value });
      setShowAddAdditionalModal(false);
      return;
    }
    setAdditionalList((prev) => [...prev, { ...data, id: prev.length + 1 }]);
    setShowAddAdditionalModal(false);
  };

  return (
    <Modals setState={setShowAddAdditionalModal}>
      <div className="modal">
        <div className="add-violation">
          <header className="add-violation__header">
            <h1>Biaya Tambahan</h1>
          </header>
          <div className="add-violation__fine-detail">
            <InputField
              value={data.name}
              label="Keterangan Biaya Tambahan"
              placeholder="Masukan keterangan"
              className="add-violation__fine-name"
              onChange={(e) => setData((prev) => ({ ...prev, name: e.target.value }))}
            />
            <InputField
              value={data.value}
              label="Jumlah Biaya Tambahan"
              placeholder="Masukan Harga"
              price
              type="number"
              min={0}
              onChange={(e) => setData((prev) => ({ ...prev, value: parseInt(e.target.value) }))}
            />
          </div>
          <div>
            <Button wfull height={50} onClick={confirmationHandler}>
              Konfirmasi
            </Button>
          </div>
        </div>
      </div>
    </Modals>
  );
};

export default AddAdditionalModal;
