import React from 'react';
import { PaginationTable, Table, TableWrapper } from 'components/Global';
import { ReactComponent as RoleFilledIcon } from 'icons/role-filled-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { useState } from 'react';
import { useEffect } from 'react';
import { deleteRoleById, getAllRoles } from 'features/roles/actions';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/Global/LoadingSpinner';
import { resetSelectedPermission } from 'features/permissions/slice';
import { resetSelectedRole } from 'features/roles/slice';
import ButtonAdd from 'components/Global/ButtonAdd';

const RolesList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const column = [
    { header: 'No.', value: 'no' },
    { header: 'Role', value: 'name' },
    { header: 'Deskripsi', value: 'description' },
  ];
  const { setShowConfirmation, setShowSpinner, showToast, setShowAddRolesModal } = useAppContext();
  const { isLoading, data: rolesData } = useSelector((state) => state.roles);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllRoles({ page: currentPage, limit: 10 }));
  }, [currentPage]);

  const handleDelete = (e) => {
    const roleId = e.target.parentElement.parentElement.dataset['key'];
    setShowConfirmation({
      message: 'Apakah anda yakin ingin menghapus Item',
      show: true,
      onClick: async () => {
        try {
          setShowSpinner(true);
          await dispatch(deleteRoleById(roleId)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Hapus Item' });
          setCurrentPage(1);
        } catch (error) {
          showToast({ type: 'error', message: 'Gagal Hapus Item' });
        } finally {
          setShowSpinner(false);
        }
      },
    });
  };

  const handleEdit = (e) => {
    const roleId = e.target.parentElement.parentElement.dataset['key'];
    dispatch(resetSelectedRole());
    dispatch(resetSelectedPermission());
    navigate(`detail?id=${roleId}`);
  };

  return (
    <>
      <ButtonAdd onClick={() => setShowAddRolesModal(true)} label="Tambah Role" />

      <TableWrapper title="Role" icon={<RoleFilledIcon fill="#009EF7" width="25px" height="25px" />}>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Table
              column={column}
              data={rolesData.roles}
              actionBtn
              onDel={handleDelete}
              onEdit={handleEdit}
              currentPage={currentPage}
            />
            <PaginationTable
              currentPage={currentPage}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              totalCount={rolesData.pagination?.total_data}
            />
          </>
        )}
      </TableWrapper>
    </>
  );
};

export default RolesList;
