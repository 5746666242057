import { createSlice } from '@reduxjs/toolkit';
import { fetchAllUsersInfluencer, fetchInfluencerInfo, fetchInfluencerReferralHistory } from './actions';

const initialState = {
  data: [],
  singleUser: {},
  historyReferrer: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const userInfluencer = createSlice({
  name: 'userInfluencer',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfluencerInfo.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.singleUser = {};
      })
      .addCase(fetchInfluencerInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchInfluencerInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.singleUser = action.payload;
      })
      .addCase(fetchAllUsersInfluencer.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = [];
      })
      .addCase(fetchAllUsersInfluencer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchAllUsersInfluencer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.data = action.payload;
      })
      .addCase(fetchInfluencerReferralHistory.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.historyReferrer = {};
      })
      .addCase(fetchInfluencerReferralHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchInfluencerReferralHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.historyReferrer = action.payload;
      });
  },
});

export const getCustomerData = (state) => state.userInfluencer.singleUser;

export default userInfluencer.reducer;
