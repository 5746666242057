import { createSlice } from '@reduxjs/toolkit';

import { getAllOvertime, getAllOvertimeRules, getDetailOvertime, getDetailOvertimeRules } from './actions';

const initialState = {
  overtimes: [],
  overtimeRules: [],
  selectedOvertime: {},
  selectedOvertimeRules: {},
  status: 'loading', //'idle' | 'loading' | 'succeeded' | 'failed'
  isError: false,
  errorMessage: '',
};

export const overtimeSlice = createSlice({
  name: 'overtime',
  initialState,
  reducers: {
    resetSelectedOvertime: (state) => {
      state.selectedOvertime = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOvertime.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getAllOvertime.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllOvertime.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.overtimes = action.payload;
      })
      .addCase(getDetailOvertime.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getDetailOvertime.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDetailOvertime.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selectedOvertime = action.payload;
      })
      .addCase(getAllOvertimeRules.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getAllOvertimeRules.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllOvertimeRules.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.overtimeRules = action.payload;
      })
      .addCase(getDetailOvertimeRules.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getDetailOvertimeRules.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDetailOvertimeRules.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selectedOvertimeRules = action.payload;
      });
  },
});

export const { resetSelectedOvertime } = overtimeSlice.actions;

export default overtimeSlice.reducer;
