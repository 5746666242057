import { Button, InputField, PaginationTable, Table, TableWrapper } from 'components/Global';
import { ReactComponent as PeopleIcon } from 'icons/people-filled-icon.svg';
import { ReactComponent as ArrowLeft } from 'icons/arrow-left.svg';
import { ReactComponent as History } from 'icons/history.svg';
import { ReactComponent as PointsIcon } from 'icons/points-icon.svg';
import { useAppContext } from 'components/Context/AppContext';
import { checkEmptyObject, checkPermission, phoneWithCode } from 'utils/functionality';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { changeUserStatus, fetchAllUsers, fetchCustomerInfo } from 'features/user/actions';

const fallbackImg = '/assets/image/ktp-fallback.png';

const history = [
  {
    name: 'Kevin Sanjaya',
    vehicle: 'New Avanza 2022',
    rent_start: '12 Agustus 2022',
    hour_start: '10:00 AM',
    total_price: '607.000',
    type: 'FULL',
  },
];
const historyColumn = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Lengkap', value: 'name' },
  { header: 'Jenis Mobil', value: 'vehicle' },
  { header: 'Tanggal Sewa', value: 'rent_start' },
  { header: 'Jam Mulai', value: 'hour_start' },
  { header: 'Total Harga', value: 'total_price' },
  { header: 'Status Pembayaran', value: 'type' },
];

const CustomerDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.user?.singleUser);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const { name, email, phone, wa_number, phone_code, PersonalInfos, referral } = data;

  const { setShowBlockConfirmation, showToast } = useAppContext();
  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(1);
  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [userStatus, setUserStatus] = useState('');

  const params = useParams();

  const blockUserHandler = () => {
    setIsUserBlocked((prev) => !prev);
  };

  const saveHandler = () => {
    setShowBlockConfirmation({
      show: true,
      message: !isUserBlocked
        ? 'Apakah anda yakin ingin mengaktifkan user kembali?'
        : 'Apakah anda yakin ingin Memblokir User?',
      onClick: async () => {
        try {
          await dispatch(changeUserStatus({ customerId: params.id, payload: { status: userStatus } })).unwrap();
          showToast({ type: 'success', message: 'Berhasil Update User' });
          navigate('/user-customer');
          dispatch(fetchAllUsers());
        } catch (error) {
          showToast({ type: 'error', message: 'Gagal Update User' });
        } finally {
          setShowBlockConfirmation(false);
        }
      },
    });
  };

  useEffect(() => {
    if (!params?.id) return;
    dispatch(fetchCustomerInfo(params.id));
  }, [params]);

  useEffect(() => {
    if (checkEmptyObject(data)) return;
    setIsUserBlocked(() => (data.status == 'approved' || data.status == 'new' ? false : true));
    setUserStatus(data.status);
  }, [data]);

  useEffect(() => {
    setUserStatus(isUserBlocked ? 'blocked' : 'approved');
  }, [isUserBlocked]);

  return (
    <div className="user__detail">
      <button className="user__back-btn" onClick={() => navigate(-1)}>
        <ArrowLeft />
        <span>Kembali</span>
      </button>

      <div className="user__header">
        <div className="user__status-wrapper">
          <div className="user__status-info">
            <p>Status</p>
            {isUserBlocked ? (
              <span className="user__status-badge user__status-badge--blocked">Terblokir</span>
            ) : (
              <span className="user__status-badge user__status-badge--active">Aktif</span>
            )}
          </div>

          <div className="user__toggle-status">
            <label className="switch">
              <input type="checkbox" onChange={blockUserHandler} role={'switch'} checked={!isUserBlocked} />
              <span className="slider"></span>
            </label>
          </div>
        </div>
      </div>

      <div className="user__data">
        <div className="user__form-data">
          <InputField label="Name Lengkap" type="text" name="name" value={name || ''} disable />
          <InputField label="Email" type="email" name="email" value={email || ''} disable />
          <InputField
            label="No. Handphone"
            type="text"
            name="phone"
            value={phoneWithCode(phone_code, phone) || ''}
            disable
          />
          <InputField
            label="Whatsapp"
            type="text"
            name="whatsapp"
            value={phoneWithCode(phone_code, wa_number) || ''}
            disable
          />
        </div>

        <div className="user__form-data">
          <InputField label="Kode Referral" type="text" name="referral" value={referral || ''} disable />
          <InputField label="Loyalty Level" type="text" name="loyalty" value={'-'} disable />
          <InputField
            icon={<PointsIcon />}
            iconPosition="start"
            label="Jumlah Point"
            type="text"
            name="point"
            value={'-'}
            disable
          />
        </div>
      </div>

      <div className="user__identity">
        <TableWrapper title="Foto Identitas Diri" icon={<PeopleIcon fill="#FF9900" width="25px" height="25px" />}>
          <div className="user__image-data">
            <div className="user__identity user__identity--id">
              <p className="user__identity-subtitle">Foto KTP</p>
              <div className="user__identity-image-wrapper">
                <img
                  src={PersonalInfos?.ktp ? process.env.REACT_APP_IMAGES + PersonalInfos?.ktp : fallbackImg}
                  alt="foto-ktp"
                />
              </div>
            </div>

            <div className="user__identity user__identity--license">
              <p className="user__identity-subtitle">Foto SIM</p>
              <div className="user__identity-image-wrapper">
                <img
                  src={PersonalInfos?.sim ? process.env.REACT_APP_IMAGES + PersonalInfos?.sim : fallbackImg}
                  alt="foto-sim"
                />
              </div>
            </div>
          </div>
        </TableWrapper>
      </div>

      <div className="user__history">
        <TableWrapper title="History" icon={<History fill="#FF9900" width="25px" height="25px" />}>
          <Table column={historyColumn} data={history} maxRows={5} actionBtn onDetail={() => {}} />
          <PaginationTable currentPage={currentPage} />
        </TableWrapper>
      </div>

      {checkPermission(offCanvasMenu, currentMenu, 'update') && (
        <div className="user__save-btn">
          <Button width={208} size="sm" onClick={saveHandler} disabled={userStatus === data?.status}>
            Simpan
          </Button>
        </div>
      )}
    </div>
  );
};

export default CustomerDetail;
