import React, { useEffect } from 'react';
import { Button, InputField, TableWrapper } from 'components/Global';
import { ReactComponent as SchedulePriceFilledIcon } from 'icons/schedule-price-filled-icon.svg';
import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSchedulePriceValue,
  getAllSchedulePriceValue,
  updateSchedulePriceValue,
} from 'features/schedule-price/actions';
import { useAppContext } from 'components/Context/AppContext';

const HargaPotonganDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { setShowSpinner, showToast, setShowConfirmation } = useAppContext();

  const { data: hargaPotonganData, isLoading } = useSelector((state) => state.schedulePriceValue);

  const [potonganValue, setPotonganValue] = useState(0);

  const [searchParams] = useSearchParams();

  // FUNCTION TO PREVENT SCROLLING ON INPUT NUMBER TYPE
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  // FUNCTION ACTION REQUEST
  const sendPayloadData = async (action, payload, fulfilledMessage, errorMessage1, errorMessage2) => {
    try {
      const res =
        action === 'ADD'
          ? await dispatch(createSchedulePriceValue(payload))
          : action === 'UPDATE'
          ? await dispatch(updateSchedulePriceValue(payload))
          : () => {};

      if (res.meta?.requestStatus === 'fulfilled') {
        showToast({ type: 'success', message: fulfilledMessage });
        navigate('/schedule-price?tab-active=List+Harga+Potongan');
        return;
      }
      showToast({ type: 'error', message: errorMessage1 });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: `${errorMessage2}: ${error}` });
    }
  };

  useEffect(() => {
    // call detail if add item false
    if (location.pathname.split('/')[2] !== 'add-item') {
      dispatch(getAllSchedulePriceValue());
    }
  }, []);

  // FUNCTION SUBMIT
  const handleSubmit = () => {
    // validation
    const validPotonganValue = potonganValue !== '' && potonganValue !== 0;

    // check if valid
    if (!validPotonganValue) {
      return showToast({ type: 'error', message: 'Masukan Harga Potongan' });
    }

    // if valid
    let payload;

    if (location.pathname.split('/')[2] === 'add-item') {
      payload = {
        value: +potonganValue,
      };
    } else {
      const schedulePriceValueId = searchParams.get('harga-potongan_id');
      payload = {
        payloadData: { value: +potonganValue },
        schedulePriceValueId,
      };
    }

    // if add item
    if (location.pathname.split('/')[2] === 'add-item') {
      setShowConfirmation({
        message: 'Apakah anda yakin ingin lanjut membuat Harga Potongan',
        show: true,
        onClick: () =>
          sendPayloadData(
            'ADD',
            payload,
            'Berhasil Tambah Harga Potongan',
            'Gagal Tambah Harga Potongan',
            'Terjadi Kesalahan',
          ),
      });
    }

    // if update item
    if (location.pathname.split('/')[2] !== 'add-item') {
      setShowConfirmation({
        message: 'Apakah anda yakin ingin lanjut update Harga Potongan',
        show: true,
        onClick: () =>
          sendPayloadData(
            'UPDATE',
            payload,
            'Berhasil Update Harga Potongan',
            'Gagal Update Harga Potongan',
            'Terjadi Kesalahan',
          ),
      });
    }
  };

  // SET POTONGAN VALUE WHEN ADD ITEM FALSE
  useEffect(() => {
    if (location.pathname.split('/')[2] === 'add-item') return;
    if (Object.keys(hargaPotonganData).length === 0) return;

    const potonganHargaId = searchParams.get('harga-potongan_id');
    const selectedPotonganValue = hargaPotonganData.data.find((item) => item.id == potonganHargaId);

    if (!selectedPotonganValue) {
      showToast({ type: 'error', message: 'Harga Potongan Tidak Ditemukan' });
      return;
    }

    setPotonganValue(selectedPotonganValue.reward);
  }, [hargaPotonganData]);

  // SHOW SPINNER WHEN LOADING
  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  return (
    <TableWrapper
      icon={<SchedulePriceFilledIcon fill="#009EF7" width="25px" height="25px" />}
      title="Tambah List Harga"
    >
      <div className="harga-potongan-detail">
        <h1>Harga</h1>

        {/* INPUT PRICE */}
        <InputField
          type="number"
          price={true}
          label={'Tambah Harga Potongan'}
          placeholder={'0'}
          value={potonganValue || ''}
          onChange={(e) => setPotonganValue(e.target.value)}
          onWheel={numberInputOnWheelPreventChange}
        />

        {/* ACTION BUTTON */}
        <div className="actions-button">
          <Button type="button" onClick={() => navigate(-1)} width="192px" height="39px" variant="outline">
            Kembali
          </Button>
          <Button type="button" onClick={handleSubmit} width="192px" height="39px" variant="primary">
            Simpan
          </Button>
        </div>
      </div>
    </TableWrapper>
  );
};

export default HargaPotonganDetail;
