import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowLeft } from 'icons/arrow-left.svg';
import { Button, InputField } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import { resetSelectedRole } from 'features/roles/slice';
import { useAppContext } from 'components/Context/AppContext';
import { checkPermission, phoneWithCode } from 'utils/functionality';
import { ReactComponent as ArrowDown } from 'icons/arrow-down-icon.svg';
import ClickOutside from 'components/Global/ClickOutside';
import { assignUserRole, editUserRole } from 'features/roles/actions';
import { getAllEmployees } from 'features/employee/actions';

const RoleAssignDetail = ({ setShowRoleDetail }) => {
  const { singleUser: employee, isLoading } = useSelector((state) => state.user);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const { showToast, setShowSpinner, setShowConfirmation } = useAppContext();
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const roles = useSelector((state) => state.roles.data);
  const [roleId, setRoleId] = useState({
    id: 0,
    name: '',
  });

  useEffect(() => {
    if (Object.keys(employee).length === 0) return;
    if (employee.role === '') return;

    const selectedRole = roles.roles.find((item) => item.name === employee.role);
    setRoleId(() => ({ id: selectedRole?.id, name: selectedRole?.name }));
  }, [employee]);

  const backHandler = () => {
    setShowRoleDetail(false);
    dispatch(resetSelectedRole());
  };

  const updateHandler = async () => {
    if (roleId.name.trim() === '') {
      showToast({ type: 'warning', message: 'Harap Memilih Role User' });
      return;
    }

    const payload = {
      user_id: employee.id,
      role_id: roleId.id,
    };

    setShowConfirmation({
      message: 'Apakah anda yakin ingin mengubah data',
      show: true,
      onClick: async () => {
        try {
          roleId.name.trim() === ''
            ? await dispatch(assignUserRole(payload)).unwrap()
            : await dispatch(editUserRole(payload)).unwrap();

          setShowSpinner(true);
          if (roleId.name.trim() === '') {
            showToast({ type: 'success', message: 'Berhasil Menambahkan Data' });
            return;
          }
          showToast({ type: 'success', message: 'Berhasil Merubah Data' });
          setShowRoleDetail(false);
        } catch (error) {
          showToast({ type: 'error', message: `Terjadi Kesalahan: ${error.slug}` });
        } finally {
          setShowSpinner(false);
          dispatch(getAllEmployees());
        }
      },
    });
  };

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  return (
    <div className="roles-assign-detail">
      <button className="roles-assign-detail__back-btn" onClick={backHandler}>
        <ArrowLeft />
        <span>Kembali</span>
      </button>

      <div className="roles-assign-detail__body">
        <div className="roles-assign-detail__content">
          <InputField
            label="Nama Lengkap"
            htmlFor="name"
            className="name"
            autoComplete="off"
            disable={true}
            readOnly
            value={employee?.name || ''}
          />
        </div>
        <div className="roles-assign-detail__content">
          <InputField
            label="Email"
            htmlFor="email"
            className="email"
            autoComplete="off"
            disable={true}
            readOnly
            value={employee?.email || ''}
          />
        </div>
        <div className="roles-assign-detail__content">
          <InputField
            label="No. Handphone"
            htmlFor="phone"
            className="phone"
            autoComplete="off"
            disable={true}
            readOnly
            value={phoneWithCode(employee?.phone_code, employee?.phone) || ''}
          />
        </div>
        <div className="roles-assign-detail__content">
          <InputField
            label="Whatsapp"
            htmlFor="wa"
            className="wa"
            autoComplete="off"
            disable={true}
            readOnly
            value={phoneWithCode(employee?.phone_code, employee?.wa_number) || ''}
          />
        </div>
        <div className="roles-assign-detail__content">
          <div className={'g-input select-permission'}>
            <label htmlFor={'role'} className="input-title">
              Pilih Role
            </label>
            <div
              className={'select-permission-field'}
              placeholder="Pilih role tersedia"
              onClick={() => setShowDropdown((prev) => !prev)}
            >
              {!roleId.id ? <option value="null">Pilih role tersedia</option> : <p>{roleId.name}</p>}
              <ArrowDown className="arrow-down-icon" />
            </div>

            {showDropdown ? (
              <ClickOutside onClickOutside={() => setShowDropdown(false)}>
                <div className="select-permission-list">
                  <h4 className="select-permission-list__title">Pilih Role Tersedia</h4>
                  <ul className="select-permission-list__dropdown">
                    {roles.roles.map((item) => (
                      <li
                        key={item.id}
                        id={item.id}
                        onClick={() => {
                          setRoleId(item);
                          setShowDropdown(false);
                        }}
                        className="select-permission-list__item"
                      >
                        <div>{item.name}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              </ClickOutside>
            ) : null}
          </div>
        </div>
      </div>

      {checkPermission(offCanvasMenu, currentMenu, 'update') && (
        <div className="roles-assign-detail__button">
          <Button
            className="button"
            size="sm"
            width="192px"
            onClick={updateHandler}
            disabled={employee.role === roleId.name}
          >
            Simpan
          </Button>
        </div>
      )}
    </div>
  );
};

export default RoleAssignDetail;
