import { Button, InputField, RadioButton, TableWrapper } from 'components/Global';
import { ReactComponent as PeopleIcon } from 'icons/people-filled-icon.svg';
import { ReactComponent as ArrowLeft } from 'icons/arrow-left.svg';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch } from 'react-redux';
import { createUserInfluencer, generateReferralCode } from 'features/user/actions';

const arrayRadionButton = [
  {
    id: 'pr1',
    label: 'Harga Fixed',
    value: 'fixed',
  },
  {
    id: 'pr2',
    label: 'Harga Percentage',
    value: 'percentage',
  },
];

export function isDecimal(num) {
  return num % 1 !== 0;
}

const AddUserInfluencer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    name: '',
    referral: '',
  });
  const [priceType, setPriceType] = useState('');
  const [comissionValue, setComissionValue] = useState({
    value_type: '',
    value: '',
  });

  const { showToast } = useAppContext();

  const handleValueType = (id) => {
    setPriceType(id);
    const valueType = arrayRadionButton.find((item) => item.id == id)?.value;

    setComissionValue({ ...comissionValue, value_type: valueType });
  };

  const handleValueInput = (e) => {
    const normalizedValue = e.target.value.replace(',', '.');

    if (priceType === 'pr1') {
      // Allow only digits and negative sign
      if (/^\d*$/.test(normalizedValue)) {
        setComissionValue({ ...comissionValue, value: isNaN(parseInt(e.target.value)) ? '' : +normalizedValue });
      }
    } else {
      setComissionValue({ ...comissionValue, value: isNaN(parseInt(e.target.value)) ? '' : +normalizedValue });
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const submitHandler = async () => {
    if (data.name.trim() === '') {
      return showToast({ type: 'warning', message: 'Harap Memasukan Nama Influencer' });
    } else if (data.referral.trim() === '') {
      return showToast({ type: 'warning', message: 'Harap Memilih Kode Referral' });
    } else if (isNaN(comissionValue.value) || comissionValue.value === '') {
      return showToast({ type: 'warning', message: 'Harap Mengisi Nilai Komisi' });
    } else if (comissionValue.value < 0) {
      return showToast({ type: 'warning', message: 'Nilai Komisi Tidak Valid' });
    } else if (comissionValue.value_type === 'fixed' && isDecimal(comissionValue.value)) {
      return showToast({ type: 'warning', message: 'Nilai Komisi Tidak Valid' });
    }

    const payload = {
      fullname: data.name,
      referral: data.referral,
      value_type: comissionValue.value_type,
      value: comissionValue.value,
    };

    try {
      await dispatch(createUserInfluencer(payload)).unwrap();
      showToast({ type: 'success', message: 'Berhasil Membuat User Baru' });
      navigate('/user-influencer');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      showToast({ type: 'error', message: 'Gagal Membuat User Baru' });
    }
  };

  const generateRandomCode = async () => {
    const res = await dispatch(generateReferralCode(data.name)).unwrap();
    setData((prev) => ({ ...prev, referral: res.code }));
  };

  return (
    <div className="user__add-user-influencer">
      <button className="user__back-btn" onClick={() => navigate(-1)}>
        <ArrowLeft />
        <span>Kembali</span>
      </button>

      <TableWrapper title="Tambah Influencer" icon={<PeopleIcon fill="#FF9900" width="25px" height="25px" />}>
        <div className="user__add-user-influencer__form">
          <InputField
            label="Nama Influencer"
            placeholder="Tulis Nama Influencer"
            value={data.name || ''}
            onChange={(e) => setData((prev) => ({ ...prev, name: e.target.value }))}
          />

          <div className="referral-code-field">
            <InputField
              label="Kode Referral"
              placeholder="Kode Referral"
              value={data.referral || ''}
              onChange={(e) => setData((prev) => ({ ...prev, referral: e.target.value }))}
            />
            <Button className="random-code-btn" disabled={!data.name} onClick={generateRandomCode}>
              Random Code
            </Button>
          </div>

          <div className="comission-field">
            <h3>Komisi</h3>
            <div className="radio">
              <RadioButton name="price" data={arrayRadionButton} state={priceType} setState={handleValueType} />
            </div>

            {(priceType === 'pr1' || priceType === '') && (
              <InputField
                type="number"
                placeholder="Rp 0"
                value={comissionValue.value}
                onChange={handleValueInput}
                onWheel={numberInputOnWheelPreventChange}
                disable={!priceType}
                min={0}
              />
            )}

            {priceType === 'pr2' && (
              <InputField
                type="number"
                placeholder="0%"
                value={comissionValue.value}
                onChange={handleValueInput}
                onWheel={numberInputOnWheelPreventChange}
                disable={!priceType}
                min={0}
              />
            )}
          </div>
        </div>
        <div className="buttons">
          <Button variant="outline" className="button" width={208} size="sm" onClick={() => navigate(-1)}>
            Kembali
          </Button>
          <Button className="button" width={208} size="sm" onClick={submitHandler}>
            Simpan
          </Button>
        </div>
      </TableWrapper>
    </div>
  );
};

export default AddUserInfluencer;
