import { InputField } from 'components/Global';
import React from 'react';
import { setCurrency } from 'utils/functionality';

const DetailVoucher = ({ data }) => {
  return (
    <div className="detail-order__voucher-detail">
      <div className="detail-order__voucher-detail-title">
        <h1>Voucher</h1>
      </div>
      <div className="detail-order__voucher-detail-body">
        <InputField label="Nama Voucher" disabled value={data?.vouchers?.[0].name || '-'} />
        <InputField label="Kode Voucher" disabled value={data?.vouchers?.[0].code || '-'} />
        <InputField label="Harga Potongan" disabled value={setCurrency(data?.vouchers?.[0].value) || '-'} />
      </div>
    </div>
  );
};

export default DetailVoucher;
