import Button from 'components/Global/Button';
import React, { useState } from 'react';
import { ReactComponent as FilterIcon } from 'icons/filter-icon.svg';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const FilterBar = () => {
  const { data } = useSelector((state) => state.rentalLocation);
  const [searchParams, setSearchParams] = useSearchParams();

  const [open, setOpen] = useState(false);

  const sortByCity = (loc) => {
    setSearchParams({ page: 1, locationId: loc.id });
    setOpen(false);
  };

  const handleSeeAllLocations = () => {
    if (searchParams.has('locationId')) {
      searchParams.delete('locationId');
      setSearchParams(searchParams);
      setOpen(false);
    }
  };

  const id = searchParams.get('locationId');
  const currentLocation = data.find((item) => item.id === Number(id));
  const locationName = currentLocation ? currentLocation.name : 'Semua';

  return (
    <div className="filter-bar">
      <Button width={119} height={30} bgColor="#E6E6E6" textColor="#000" onClick={() => setOpen(!open)}>
        <FilterIcon />
        <span className="filter-title">{locationName}</span>
      </Button>

      {open && (
        <div className="filter-bar__button">
          <Button
            wfull
            height={50}
            bgColor="#FFFFFF"
            textColor="#000"
            onClick={handleSeeAllLocations}
            style={{ fontWeight: 400 }}
          >
            Semua
          </Button>
          {data.map((loc) => (
            <div key={loc.id}>
              <div className="line" />
              <Button
                wfull
                height={50}
                bgColor="#FFFFFF"
                textColor="#000"
                onClick={() => sortByCity(loc)}
                style={{ fontWeight: 400 }}
              >
                {loc.name}
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterBar;

{
  /* <div className="line" />
          <Button
            wfull
            height={50}
            bgColor="#FFFFFF"
            textColor="#000"
            onClick={() => sortByCity('Jakarta')}
            style={{ fontWeight: 400 }}
          >
            Jakarta
          </Button>
        </div> */
}
