import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllDeliveryLocations = createAsyncThunk(
  'deliveryLocation/getAllDeliveryLocations',
  async ({ currentPage = 1, id, limit = 10 }, thunkAPI) => {
    let NEW_URL = `${BASE_URL}/${API_VERSION}/shuttle?limit=${limit}&page=${currentPage}`;

    if (id) {
      NEW_URL += `&location_id=${id}`;
    }

    try {
      const accessToken = thunkAPI.getState().auth.data.access_token;

      const response = await axios.get(NEW_URL, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// export const getAllDeliveryLocationsById = createAsyncThunk(
//   'deliveryLocation/getAllDeliveryLocations',
//   async ({ id, currentPage }, thunkAPI) => {
//     try {
//       const accessToken = thunkAPI.getState().auth.data.access_token;

//       const response = await axios.get(`${BASE_URL}/${API_VERSION}/shuttle?location_id=${id}&limit=10&page=${currentPage}`, {
//         headers: { Authorization: `Bearer ${accessToken}` },
//       });

//       return response.data;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.response.data);
//     }
//   },
// );

export const createDeliveryLocation = createAsyncThunk(
  'deliveryLocation/createDeliveryLocation',
  async (payload, thunkAPI) => {
    try {
      const accessToken = thunkAPI.getState().auth.data.access_token;

      const response = await axios
        .post(
          `${BASE_URL}/${API_VERSION}/shuttle`,
          { ...payload },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          },
        )
        .then((res) => res);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const deleteDeliveryLocation = createAsyncThunk(
  'deliveryLocation/deleteDeliveryLocation',
  async (id, thunkAPI) => {
    try {
      const accessToken = thunkAPI.getState().auth.data.access_token;

      const response = await axios
        .delete(`${BASE_URL}/${API_VERSION}/shuttle/${id}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => res);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const updateDeliveryLocation = createAsyncThunk(
  'deliveryLocation/updateDeliveryLocation',
  async ({ id, payload }, thunkAPI) => {
    try {
      const accessToken = thunkAPI.getState().auth.data.access_token;

      await axios.put(
        `${BASE_URL}/${API_VERSION}/shuttle/${id}`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getDeliveryLocationDetail = createAsyncThunk(
  'deliveryLocation/getDeliveryLocationDetail',
  async (id, thunkAPI) => {
    try {
      const accessToken = thunkAPI.getState().auth.data.access_token;

      const response = await axios
        .get(`${BASE_URL}/${API_VERSION}/shuttle/${id}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => res);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
