import { PaginationTable, TableWrapper } from 'components/Global';
import React, { useEffect, useState } from 'react';
import { ReactComponent as DPFilledIcon } from 'icons/dp-filled-icon.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBusinessRule, getAllBusinessRules } from 'features/business-rules/actions';
import { useAppContext } from 'components/Context/AppContext';
import ActionButtons from 'components/Global/Table/ActionButtons';
import { checkPermission, setCurrency } from 'utils/functionality';
import { resetBusinessRuleSelected } from 'features/business-rules/slice';
import StatusButton from 'components/Global/Table/StatusButton';
import ButtonAdd from 'components/Global/ButtonAdd';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama', value: 'name' },
  { header: 'Lokasi', value: 'business_rule_location' },
  { header: 'Layanan', value: 'business_rule_subservices' },
  { header: 'Nilai', value: 'rule_threshold' },
  { header: 'Status', value: 'is_applied' },
];

const DownPaymentComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { data: businessRuleData, status } = useSelector((state) => state.businessRules);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const { setShowSpinner, setShowConfirmation, showToast } = useAppContext();

  useEffect(() => {
    dispatch(getAllBusinessRules({ page: currentPage, type: 'Minimum Down Payment' }));
  }, []);

  const pageNumber = (page, idx) => (page > 1 ? (page - 1) * 10 + idx : idx);

  const rows = 10 - businessRuleData?.business_rules?.length;

  useEffect(() => {
    if (status == 'loading') {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [status]);

  const onDelete = (e) => {
    const id = e.target.parentElement.parentElement.dataset['key'];
    setShowConfirmation({
      message: 'Apakah anda ingin menghapus data ini?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deleteBusinessRule(id)).unwrap();
          showToast({ type: 'success', message: 'Berhasil menghapus setting!' });
          dispatch(getAllBusinessRules({ page: 1, type: 'Minimum Down Payment' }));
          setCurrentPage(1);
        } catch (err) {
          showToast({ type: 'error', message: 'Gagal menghapus setting!' });
        }
      },
    });
  };

  const onDetail = (e) => {
    const id = e.target.parentElement.parentElement.dataset['key'];
    dispatch(resetBusinessRuleSelected());
    navigate(`/setting-dp/detail?id=${id}`);
  };

  const onAdd = () => {
    dispatch(resetBusinessRuleSelected());
    navigate('/setting-dp/add-item');
  };

  return (
    <div className="down-payment">
      <ButtonAdd onClick={onAdd} label="Tambah Setting" />

      <TableWrapper icon={<DPFilledIcon fill="#009EF7" width="25px" height="25px" />} title="Setting DP">
        <div className="table">
          <table className="table-wrapper">
            <thead className="table-head">
              <tr>
                {column.map((item, i) => (
                  <th key={i} style={{ width: item.header === 'Jenis Mobil' ? '240px' : '' }}>
                    {item.header}
                  </th>
                ))}
                <th className="action-col">Action</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {businessRuleData?.business_rules?.length > 0 &&
                businessRuleData?.business_rules?.map((item, no) => (
                  <tr key={no}>
                    {column.map((col, idx) => {
                      if (col.value === 'no') {
                        return (
                          <td
                            key={idx}
                            data-type="no"
                            className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                          >
                            {pageNumber(currentPage, no + 1)}
                          </td>
                        );
                      } else if (col.value === 'business_rule_location' || col.value === 'business_rule_subservices') {
                        return (
                          <td
                            key={idx}
                            className={`pre-white-space ${
                              col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                            }`}
                            style={{ width: '240px' }}
                          >
                            <div className="schedule-price-locations">
                              {!item[col.value] || item[col.value]?.length === 0 ? (
                                <>-</>
                              ) : (
                                item[col.value].map((li, i) => (
                                  <div key={Math.random() + i}>
                                    <span>{li.name}</span>
                                  </div>
                                ))
                              )}
                            </div>
                          </td>
                        );
                      } else if (col.value === 'rule_threshold') {
                        return (
                          <td
                            key={idx}
                            data-type={col.value}
                            className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                          >
                            <p>Minimal : {setCurrency(item[col.value].min)}</p>
                            <p>Maksimal : {setCurrency(item[col.value].max)}</p>
                          </td>
                        );
                      } else if (col.value === 'is_applied') {
                        return (
                          <td
                            key={idx}
                            data-type={col.value}
                            className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                          >
                            <StatusButton status={item[col.value]} />
                          </td>
                        );
                      } else {
                        return (
                          <td
                            key={idx}
                            data-type={col.value}
                            className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                          >
                            {item[col.value]}
                          </td>
                        );
                      }
                    })}
                    <td className="btn-action" data-type="actionButtons" data-key={!item.id ? item.code : item.id}>
                      <ActionButtons
                        del={checkPermission(offCanvasMenu, currentMenu, 'delete') ? onDelete : false}
                        detail={onDetail}
                      />
                    </td>
                  </tr>
                ))}
              {rows > 0 &&
                [...Array(rows)].map((_, idx) => (
                  <tr key={idx}>
                    {column.map((_, idx) => (
                      <td key={idx}></td>
                    ))}
                    <td className="btn-action"></td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <PaginationTable
          currentPage={currentPage}
          onPageChange={(newPage) => setCurrentPage(newPage)}
          totalCount={businessRuleData?.Pagination?.total_data}
        />
      </TableWrapper>
    </div>
  );
};

export default DownPaymentComponent;
