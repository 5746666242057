import React, { useEffect, useState } from 'react';
import { PaginationTable, Table, TableWrapper } from 'components/Global';

import { ReactComponent as OvertimeIcon } from 'icons/overtime-icon.svg';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import EmptyState from 'components/Global/EmptyState';
import { deleteOvertime, getAllOvertime } from 'features/overtime/actions';
import { getAllRentalLocation } from 'features/rental-location/actions';
import { useNavigate } from 'react-router-dom';
import { resetSelectedOvertime } from 'features/overtime/slice';
import ButtonAdd from 'components/Global/ButtonAdd';

const OvertimeList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setShowConfirmation, showToast, setShowSpinner } = useAppContext();
  const { overtimes, status } = useSelector((state) => state.overtime);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getAllOvertime(currentPage));
    dispatch(getAllRentalLocation());
  }, [currentPage]);

  const handleEdit = (e) => {
    const overtimeId = e.target.parentElement.parentElement.dataset['key'];

    navigate(`/overtime/edit-overtime?id=${overtimeId}`);
    dispatch(resetSelectedOvertime());
  };

  const handleDelete = (e) => {
    const overtimeId = e.target.parentElement.parentElement.dataset['key'];

    setShowConfirmation({
      message: 'Apakah anda yakin ingin menghapus overtime?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deleteOvertime(overtimeId)).unwrap();

          showToast({ type: 'success', message: 'Berhasil Menghapus Overtime' });
          dispatch(getAllOvertime(currentPage));
          return;
        } catch (error) {
          showToast({ type: 'error', message: 'Gagal Menghapus Overtime' });
        }
      },
    });
  };

  const locationMap = rentalLocationData.reduce((acc, loc) => {
    acc[loc.id] = loc.name;
    return acc;
  }, {});

  const myNewData = overtimes.data
    ? overtimes?.data.map((item) => {
        const transformLocation = item.overtime_location.location_ids
          ? item.overtime_location.location_ids.map((id) => locationMap[id])
          : ['-'];

        return {
          id: item.id,
          name: item.name,
          overtime_location: transformLocation?.length > 0 ? transformLocation.join(', ') : transformLocation,
        };
      })
    : [];

  useEffect(() => {
    if (status === 'loading') {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [status]);

  return (
    <div className="overtime">
      <div className="overtime__button-wrapper">
        <ButtonAdd onClick={() => navigate('/overtime/create-overtime')} label="Tambah Overtime" />
      </div>
      {!overtimes.data || overtimes.data.length === 0 ? (
        <EmptyState />
      ) : (
        <TableWrapper icon={<OvertimeIcon fill="#009EF7" width="25px" height="25px" />} title="Overtime">
          <Table
            column={column}
            actionBtn
            onEdit={handleEdit}
            onDel={handleDelete}
            data={myNewData}
            currentPage={currentPage}
          />
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={Object.keys(overtimes.data).length === 0 ? 0 : overtimes.pagination.total}
          />
        </TableWrapper>
      )}
    </div>
  );
};

export default OvertimeList;

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Overtime', value: 'name' },
  { header: 'Lokasi', value: 'overtime_location' },
];
