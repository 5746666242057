import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, InputField, RadioButton, TableWrapper } from 'components/Global';

import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';

import { ReactComponent as SchedulePriceFilledIcon } from 'icons/schedule-price-filled-icon.svg';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import { useDispatch, useSelector } from 'react-redux';
import { indonesianDateFormat } from 'utils/helpers';
import { format, parseISO } from 'date-fns';
import { addDays } from 'date-fns/esm';
import { getRentalLocationByServices } from 'features/rental-location/actions';
import SelectFieldShuttleAlt from 'components/Global/SelectFieldShuttle/SelectFieldShuttleAlt';

import { ReactComponent as ChevronDown } from 'icons/chevron-down.svg';
import { ReactComponent as Mappin } from '../../../../../icons/mappin-icon.svg';
import { ReactComponent as ArrowRightFilled } from 'icons/arrow-right-filled.svg';
import { getShuttleAirportZone, getShuttleTheAirport } from 'features/shuttle-airport/actions';
import { ReactComponent as PlusIcon } from 'icons/plus-icon.svg';
import { getAllVehicleAirportPackage } from 'features/vehicle/action';
import { createSchedulePrice, getDetailSchedulePrice, updateSchedulePrice } from 'features/schedule-price/actions';
import { getAllCategories } from 'features/category/actions';
import { useAppContext } from 'components/Context/AppContext';
import { getServices } from 'features/services/actions';

const initialValue = {
  start_time: '',
  end_time: '',
  change_price: '',
  price: 0,
  value_type: '',
};

const SchedulePriceAirportTransfer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const locationRef = useRef(null);
  const packageRef = useRef(null);

  const [searchParams] = useSearchParams();
  const location = useLocation();

  const schedulePriceId = searchParams.get('id');
  const isEditPage = location.pathname === '/schedule-price/edit-schedule-price-at';

  const { showToast, setShowConfirmation, setShowSpinner } = useAppContext();

  const { data: services } = useSelector((state) => state.services);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const { data: categories } = useSelector((state) => state.category);
  const { selectedSchedulePrice } = useSelector((state) => state.schedulePrice);

  const [showLocationOptions, setShowLocationOptions] = useState(false);
  const [showPackageOptions, setShowPackageOptions] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);
  const [reverse, setReverse] = useState(false);

  const [schedulePriceData, setSchedulePriceData] = useState(initialValue);
  const [startSchedulePriceDate, setStartSchedulePriceDate] = useState('');
  const [endSchedulePriceDate, setEndSchedulePriceDate] = useState('');
  const [priceType, setPriceType] = useState('');
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [airportList, setAirportList] = useState([]);
  const [zoneList, setZoneList] = useState([]);

  const [inputs, setInputs] = useState([{ pickUp: {}, dropOff: {} }]);
  const [submittedValues, setSubmittedValues] = useState([]);

  // actually this is used to check if the package is available
  const getVehiclePackage = async (pickup_id, dropoff_id) => {
    try {
      setShowSpinner(true);
      const res = await dispatch(
        getAllVehicleAirportPackage({
          pickUpLocationId: pickup_id,
          dropOffLocationId: dropoff_id,
        }),
      ).unwrap();

      const filteredPackages = res.packages.filter((pkg) => selectedCategories.includes(pkg.category_id));

      const missingCategories = selectedCategories.filter(
        (categoryId) => !filteredPackages.some((pkg) => pkg.category_id === categoryId),
      );

      if (missingCategories.length > 0) {
        const formatArray = (arr) => {
          if (arr.length === 0) return '';
          if (arr.length === 1) return arr[0];
          if (arr.length === 2) return arr.join(' and ');

          const lastItem = arr.pop();
          return `${arr.join(', ')}, and ${lastItem}`;
        };

        const missingCategoriesName = categories.category
          .filter((category) => missingCategories.includes(category.id))
          .map((category) => category.name);

        const formattedCategories = formatArray(missingCategoriesName);

        throw new Error(`${formattedCategories} tidak tersedia untuk rute yang dipilih`);
      }

      handleSubmitValue();

      setShowSpinner(false);
    } catch (error) {
      if (error.message.includes('tidak tersedia')) {
        showToast({ type: 'error', message: error.message });
        setInputs([{ pickUp: {}, dropOff: {} }]);
        return;
      }

      if (error.message === 'Invalid time value') {
        showToast({ type: 'error', message: 'Pastikan sudah memilih periode' });
        setInputs([{ pickUp: {}, dropOff: {} }]);
        return;
      }

      showToast({ type: 'error', message: 'Terjadi kesalahan!' });
      setInputs([{ pickUp: {}, dropOff: {} }]);
    } finally {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    const checkAllFieldsFilled = inputs.every((input) => input.pickUp.id && input.dropOff.id);
    if (checkAllFieldsFilled && inputs.length > 0) {
      const isDuplicate = submittedValues.some(
        (item) =>
          item.pickUp.id === inputs[0].pickUp.id &&
          item.pickUp.name === inputs[0].pickUp.name &&
          item.dropOff.id === inputs[0].dropOff.id &&
          item.dropOff.name === inputs[0].dropOff.name,
      );

      const isInTheSameLocation = inputs[0].pickUp.location_id === inputs[0].dropOff.location_id;

      if (!isInTheSameLocation) {
        showToast({
          type: 'error',
          message: `Pastikan lokasi pengantaran dan penjemputan berada di wilayah yang sama`,
        });

        setInputs([{ pickUp: {}, dropOff: {} }]);
        return;
      }

      if (isDuplicate) {
        showToast({
          type: 'error',
          message: `Lokasi pengantaran ${inputs[0].pickUp.name} dan lokasi penjemputan ${inputs[0].dropOff.name} sudah dipilih`,
        });
        setInputs([{ pickUp: {}, dropOff: {} }]);
      } else {
        getVehiclePackage(inputs[0].pickUp.id, inputs[0].dropOff.id);
        // handleSubmitValue();
      }
    }
  }, [inputs]);

  useEffect(() => {
    dispatch(getServices());
    dispatch(getAllCategories({ page: 1 }));
  }, []);

  useEffect(() => {
    if (services.length === 0) return;

    const carRentalService = services.find((item) => item.name.toLowerCase() === 'sewa mobil');
    const airportSubservice = carRentalService.sub_services.find((item) => item.name === 'Airport Transfer');

    dispatch(getRentalLocationByServices({ service_id: carRentalService.id, sub_service_id: airportSubservice.id }));
  }, [services]);

  useEffect(() => {
    if (!isEditPage) return;
    if (!schedulePriceId) return;

    dispatch(getDetailSchedulePrice(schedulePriceId));
  }, [isEditPage, schedulePriceId]);

  useEffect(() => {
    if (selectedLocations.length === 0) return;
    if (selectedCategories.length === 0) return;

    const fetchAirportZone = async () => {
      let convertedAirportList = [];

      const promises = selectedLocations.map((location) =>
        dispatch(getShuttleTheAirport({ locationId: location, limit: 100, categoryIds: selectedCategories })).unwrap(),
      );

      if (promises) {
        const results = await Promise.allSettled(promises);
        const fulfilledResults = results
          .filter((result) => result.status === 'fulfilled')
          .map((vehicle) => vehicle.value);

        fulfilledResults.forEach((item) => {
          if (item.shuttle !== null) {
            convertedAirportList = convertedAirportList.concat(item.shuttle);
          }
        });

        setAirportList(convertedAirportList);
        return;
      }
    };

    const fetchRegularZone = async () => {
      let convertedZoneList = [];

      const promises = selectedLocations.map((location) =>
        dispatch(getShuttleAirportZone({ locationId: location, limit: 100, categoryIds: selectedCategories })).unwrap(),
      );

      if (promises) {
        const results = await Promise.allSettled(promises);
        const fulfilledResults = results
          .filter((result) => result.status === 'fulfilled')
          .map((vehicle) => vehicle.value);

        fulfilledResults.forEach((item) => {
          if (item.shuttle !== null) {
            convertedZoneList = convertedZoneList.concat(item.shuttle);
          }
        });

        setZoneList(convertedZoneList);
      }
    };

    fetchAirportZone();
    fetchRegularZone();
  }, [selectedLocations, selectedCategories]);

  const handleLocationChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedLocations((prevState) =>
      prevState.includes(value) ? prevState.filter((option) => option !== value) : [...prevState, value],
    );
  };

  const handleCategoryClick = (id) => {
    setSelectedCategories([id]);
    setSubmittedValues([]);
    setShowPackageOptions(false);
  };

  const handleValueType = (id) => {
    setPriceType(id);
    const ValueType = arrayRadionButton.find((item) => item.id == id)?.value;

    setSchedulePriceData({ ...schedulePriceData, value_type: ValueType });
  };

  const handleValueInput = (e) => {
    setSchedulePriceData({ ...schedulePriceData, price: isNaN(e.target.value) ? 0 : +e.target.value });
  };

  const renderSelectedLocation = () => {
    return rentalLocationData
      .filter((option) => selectedLocations.includes(option.id))
      .map((option) => option.name)
      .join(', ');
  };

  const renderSelectedPackage = () => {
    const selected =
      categories.category?.length > 0
        ? categories?.category.find((option) => option.id === selectedCategories[0])
        : null;
    return selected ? selected.name : 'Pilih Package';
  };

  const handleClickOutside = (event) => {
    if (locationRef.current && !locationRef.current.contains(event.target)) {
      setShowLocationOptions(false);
    }

    if (packageRef.current && !packageRef.current.contains(event.target)) {
      setShowPackageOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const handleSubmit = async () => {
    const validLocations = selectedLocations.length !== 0;
    const validStartTime = schedulePriceData.start_time !== '';
    const validEndTime = schedulePriceData.end_time !== '';
    const validCategories = selectedCategories.length !== 0;
    const validChangePrice = schedulePriceData.change_price !== '';
    const validValueType = schedulePriceData.value_type !== '';
    const validPrice = schedulePriceData.price !== 0;
    const validselectedLocations = submittedValues.length !== 0;

    if (!validLocations) {
      return showToast({ type: 'error', message: 'Pilih lokasi terlebih dahulu' });
    }
    if (!validStartTime) {
      return showToast({ type: 'error', message: 'Pilih tanggal mulai' });
    }
    if (!validEndTime) {
      return showToast({ type: 'error', message: 'Pilih tanggal selesai' });
    }
    if (!validCategories) {
      return showToast({ type: 'error', message: 'Pilih package airport transfer' });
    }
    if (!validChangePrice) {
      return showToast({ type: 'error', message: 'Pilih perubahan harga' });
    }
    if (!validValueType) {
      return showToast({ type: 'error', message: 'Pilih tipe potongan harga' });
    }
    if (!validPrice) {
      return showToast({ type: 'error', message: 'harap masukkan harga potongan' });
    }
    if (!validselectedLocations) {
      return showToast({ type: 'error', message: 'pastikan sudah memilih lokasi pengantaran dan penjemputan' });
    }

    const payload = {
      ...schedulePriceData,
      locations: selectedLocations,
      change_price: priceChange.find((item) => item.id == schedulePriceData.change_price)?.change_to,
      min_rental_durations: 1,
      airport_transfer: true,
    };

    let packages = [];
    const selectedPackages = [];

    const newPayload = { ...payload, airport_package_id: selectedPackages };

    const message = isEditPage
      ? 'Apakah anda yakin ingin mengubah schedule price airport transfer?'
      : 'Apakah anda yakin ingin menambahkan schedule price airport transfer?';

    const ACTION = isEditPage
      ? updateSchedulePrice({ payloadData: newPayload, schedulePriceId: schedulePriceId })
      : createSchedulePrice(newPayload);

    setShowConfirmation({
      message,
      show: true,
      onClick: async () => {
        try {
          setShowSpinner(true);

          // to gathered all airport_package_id
          const promises = submittedValues.map((value) =>
            dispatch(
              getAllVehicleAirportPackage({
                pickUpLocationId: value.pickUp.id,
                dropOffLocationId: value.dropOff.id,
              }),
            ).unwrap(),
          );

          const results = await Promise.all(promises);

          results.forEach((item) => {
            item.packages.forEach((pkg) => {
              packages.push(pkg);
            });
          });

          const packageIds = packages
            .filter((item) => selectedCategories.includes(item.category_id))
            .map((item) => item.id);

          selectedPackages.push(...packageIds);

          if (selectedPackages.length === 0) {
            throw new Error('vehicle-package-empty');
          }

          await dispatch(ACTION).unwrap();
          showToast({ type: 'success', message: `Berhasil ${isEditPage ? 'Mengubah' : 'Menambah'} Schedule Price` });
          navigate('/schedule-price-airport');
          return;
        } catch (error) {
          if (error.message === 'vehicle-package-empty') {
            showToast({ type: 'error', message: 'Package yang dipilih tidak tersedia' });
            return;
          }

          showToast({ type: 'error', message: `Gagal ${isEditPage ? 'Mengubah' : 'Menambah'} Schedule Price` });
        } finally {
          setShowSpinner(false);
        }
      },
    });
  };

  const handleAddInput = () => {
    const checkAllFieldsFilled = inputs.every((input) => input.pickUp.id && input.dropOff.id);

    if (!checkAllFieldsFilled) {
      showToast({
        type: 'error',
        message: 'Silakan pilih lokasi pengantaran dan penjemputan',
      });
      return;
    }

    setInputs([...inputs, { pickUp: {}, dropOff: {} }]);
    setIsDropdownVisible(true);
  };

  const handleInputChange = (index, item, locationInfo) => {
    const newInputs = inputs.map((input, i) => {
      if (i === index) {
        return { ...input, [locationInfo]: item };
      }
      return input;
    });
    setInputs(newInputs);
  };

  const handleSubmitValue = () => {
    setSubmittedValues([...submittedValues, ...inputs]);
    setInputs([]);
    setIsDropdownVisible(false);
  };

  const handleRemoveSubmitted = (index) => {
    setSubmittedValues(submittedValues.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (!isEditPage) return;
    if (Object.keys(selectedSchedulePrice).length === 0) return;

    const startTime = `${new Date(selectedSchedulePrice.start_time).toISOString().slice(0, 10)} ${new Date(
      selectedSchedulePrice.start_time,
    )
      .toTimeString()
      .slice(0, 8)}`;

    const endTime = `${new Date(selectedSchedulePrice.end_time).toISOString().slice(0, 10)} ${new Date(
      selectedSchedulePrice.end_time,
    )
      .toTimeString()
      .slice(0, 8)}`;

    const categoryIds = selectedSchedulePrice.airport_packages.map((item) => item.category_id);

    setStartSchedulePriceDate(new Date(selectedSchedulePrice.start_time));
    setEndSchedulePriceDate(new Date(selectedSchedulePrice.end_time));
    setSelectedLocations(selectedSchedulePrice.locations);
    setPriceType(arrayRadionButton.find((item) => item.value === selectedSchedulePrice.value_type)?.id);
    setSelectedCategories(categoryIds);

    //to make sure location saved only once if user choose more than one package
    //ex: from kuta - ngurah rai, user choose premium and standart
    const uniqueFormattedLocations = [];
    const seen = {};

    selectedSchedulePrice.airport_packages.forEach((item) => {
      const key = `${item.airport_pickup_id}-${item.airport_dropoff_id}`;

      if (!seen[key]) {
        seen[key] = true;
        uniqueFormattedLocations.push({
          pickUp: {
            id: item.airport_pickup_id,
            name: item.airport_pickup_name,
          },
          dropOff: {
            id: item.airport_dropoff_id,
            name: item.airport_dropoff_name,
          },
        });
      }
    });

    setSubmittedValues(uniqueFormattedLocations);

    setSchedulePriceData({
      start_time: startTime,
      end_time: endTime,
      change_price: priceChange.find((item) => item.change_to === selectedSchedulePrice.change_price)
        ? priceChange.find((item) => item.change_to === selectedSchedulePrice.change_price).id
        : 0,
      price: selectedSchedulePrice.price,
      value_type: selectedSchedulePrice.value_type,
    });
  }, [isEditPage, selectedSchedulePrice]);

  return (
    <TableWrapper
      icon={<SchedulePriceFilledIcon fill="#009EF7" width="25px" height="25px" />}
      title="Schedule Price"
      style={{ position: 'relative' }}
    >
      <div className="schedule-price-detail">
        <h1>Set Airport Transfer Price</h1>
        <div className="first-section">
          {/* LOCATION */}
          <div className="schedule-price-detail__select-container" ref={locationRef}>
            <label
              className="schedule-price-detail__select-label"
              htmlFor="select-box"
              onClick={() => setShowLocationOptions((prev) => !prev)}
            >
              Lokasi Sewa:
            </label>
            <div
              id="select-box"
              className="schedule-price-detail__select-box"
              onClick={() => setShowLocationOptions((prev) => !prev)}
            >
              <div>
                <Mappin />
                <div>{renderSelectedLocation() || 'Pilih Lokasi'}</div>
              </div>

              <ChevronDown
                className={
                  !showLocationOptions ? 'schedule-price-detail__arrow' : 'schedule-price-detail__arrow arrow-down'
                }
              />
            </div>

            {showLocationOptions && (
              <div className={`schedule-price-detail__options-container ${showLocationOptions ? 'show' : ''}`}>
                {rentalLocationData.length > 0 ? (
                  rentalLocationData.map((location) => (
                    <div className="option" key={location.id}>
                      <label htmlFor={`option-${location.id}`}>{location.name}</label>
                      <input
                        type="checkbox"
                        id={`option-${location.id}`}
                        value={location.id}
                        checked={selectedLocations.includes(location.id)}
                        onChange={handleLocationChange}
                      />
                    </div>
                  ))
                ) : (
                  <div className="option option-empty">data tidak tersedia!</div>
                )}
              </div>
            )}
          </div>
          {/* END OF LOCATION */}

          {/* INPUT DATE */}
          <div className="first-section__schedule-periode">
            <h3>Periode Schedule Price</h3>
            <div className="first-section__schedule-periode__date">
              <SelectFieldDate
                htmlFor="tanggal-mulai"
                name="tanggal-mulai"
                placeholder="Pilih Tanggal Mulai"
                selectedDay={startSchedulePriceDate}
                value={
                  schedulePriceData.start_time !== ''
                    ? startSchedulePriceDate !== ''
                      ? indonesianDateFormat(format(startSchedulePriceDate, 'yyyy-MM-dd').toString())
                      : indonesianDateFormat(format(new Date(schedulePriceData.start_time), 'yyyy-MM-dd').toString())
                    : schedulePriceData.start_time
                }
                handleDaySelect={(date) => {
                  if (date) {
                    setStartSchedulePriceDate(date);
                    setSchedulePriceData({
                      ...schedulePriceData,
                      start_time: `${addDays(date, 1).toISOString().slice(0, 10)} ${addDays(date, 1)
                        .toTimeString()
                        .slice(0, 8)}`,
                    });
                  }
                }}
                disable={selectedLocations.length === 0}
                dateContainerXOffset="left"
              />
              <SelectFieldDate
                htmlFor="tanggal-selesai"
                name="tanggal-selesai"
                placeholder="Pilih Tanggal Selesai"
                selectedDay={endSchedulePriceDate}
                value={
                  schedulePriceData.end_time !== ''
                    ? endSchedulePriceDate !== ''
                      ? indonesianDateFormat(format(endSchedulePriceDate, 'yyyy-MM-dd').toString())
                      : indonesianDateFormat(format(new Date(schedulePriceData.end_time), 'yyyy-MM-dd').toString())
                    : schedulePriceData.end_time
                }
                fromDate={addDays(
                  startSchedulePriceDate === '' ? parseISO(startSchedulePriceDate) : startSchedulePriceDate,
                  1,
                )}
                handleDaySelect={(date) => {
                  if (date) {
                    setEndSchedulePriceDate(date);
                    setSchedulePriceData({
                      ...schedulePriceData,
                      end_time: `${addDays(date, 1).toISOString().slice(0, 10)} ${addDays(date, 1)
                        .toTimeString()
                        .slice(0, 8)}`,
                    });
                  }
                }}
                disable={startSchedulePriceDate === '' ? true : false}
                dateContainerXOffset="left"
              />
            </div>
          </div>
          {/* END OF INPUT DATE */}

          {/* PACKAGE */}
          <div className="schedule-price-detail__select-container" ref={packageRef}>
            <label
              className="schedule-price-detail__select-label"
              htmlFor="select-box"
              onClick={() => setShowPackageOptions((prev) => !prev)}
              style={{ marginBottom: '12px' }}
            >
              Pilih Package Airport Transfer:
            </label>
            <div
              id="select-box"
              // className="schedule-price-detail__select-box disabled"
              className={
                schedulePriceData.end_time === ''
                  ? 'schedule-price-detail__select-box disabled'
                  : 'schedule-price-detail__select-box'
              }
              onClick={() => setShowPackageOptions((prev) => !prev)}
            >
              {renderSelectedPackage()}

              <ChevronDown
                className={
                  !showPackageOptions ? 'schedule-price-detail__arrow' : 'schedule-price-detail__arrow arrow-down'
                }
              />
            </div>

            {showPackageOptions && (
              <div className={`schedule-price-detail__options-container ${showPackageOptions ? 'show' : ''}`}>
                {categories.category?.length > 0 ? (
                  categories.category.map((item) => (
                    <div className="option" key={item.id} onClick={() => handleCategoryClick(item.id)}>
                      <div>{item.name}</div>
                    </div>
                  ))
                ) : (
                  <div className="option option-empty">data tidak tersedia!</div>
                )}
              </div>
            )}
          </div>
          {/* END OF PACKAGE */}

          {/* PERUBAHAN HARGA */}
          <SelectFieldDropdown
            label="Perubahan Harga Mobil"
            htmlFor="perubahan-harga-mobil"
            data={priceChange}
            placeholder="Perubahan Harga Mobil"
            value={schedulePriceData.change_price}
            onChange={(e) => {
              setSchedulePriceData({ ...schedulePriceData, change_price: e.target.id });
            }}
          />
        </div>

        <div className="second-section">
          {/* PRICE */}
          <h3>Potongan Harga:</h3>
          <div className="radio">
            <RadioButton name="price" data={arrayRadionButton} state={priceType} setState={handleValueType} />
          </div>
          <InputField
            type="number"
            placeholder={priceType === 'pr1' ? 'Rp 0' : priceType === 'pr2' ? '0%' : 'Rp 0'}
            value={schedulePriceData.price || ''}
            onChange={handleValueInput}
            onWheel={numberInputOnWheelPreventChange}
            disable={!priceType}
          />
        </div>

        <div className="schedule-price-detail__route-section">
          <h3>Pilih Rute</h3>

          <div>
            <div className="schedule-price-detail__route-header">
              <div>
                <h4>Lokasi Penjemputan</h4>
              </div>
              <button onClick={() => setReverse((prev) => !prev)}>
                <ArrowRightFilled />
              </button>
              <div>
                <h4>Lokasi Pengantaran</h4>
              </div>
            </div>
            {submittedValues.map((value, index) => {
              return value.pickUp.id && value.dropOff.id ? (
                <div key={index} className="schedule-price-detail__route-value-group">
                  <div className="schedule-price-detail__route-item">{value.pickUp.name || ''}</div>
                  <div className="schedule-price-detail__route-item">{value.dropOff.name || ''}</div>
                  <button
                    className="schedule-price-detail__btn-remove-item"
                    type="button"
                    onClick={() => handleRemoveSubmitted(index)}
                  >
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.806664 0.808617C0.864721 0.750413 0.93369 0.704234 1.00962 0.672726C1.08555 0.641218 1.16695 0.625 1.24916 0.625C1.33137 0.625 1.41277 0.641218 1.48871 0.672726C1.56464 0.704234 1.63361 0.750413 1.69166 0.808617L4.99916 4.11737L8.30666 0.808617C8.36477 0.750507 8.43376 0.704412 8.50968 0.672963C8.58561 0.641514 8.66698 0.625327 8.74916 0.625327C8.83134 0.625327 8.91272 0.641514 8.98864 0.672963C9.06457 0.704412 9.13355 0.750507 9.19166 0.808617C9.24977 0.866727 9.29587 0.935713 9.32732 1.01164C9.35877 1.08756 9.37495 1.16894 9.37495 1.25112C9.37495 1.3333 9.35877 1.41467 9.32732 1.4906C9.29587 1.56652 9.24977 1.63551 9.19166 1.69362L5.88291 5.00112L9.19166 8.30862C9.24977 8.36673 9.29587 8.43571 9.32732 8.51164C9.35877 8.58756 9.37495 8.66894 9.37495 8.75112C9.37495 8.8333 9.35877 8.91467 9.32732 8.9906C9.29587 9.06652 9.24977 9.13551 9.19166 9.19362C9.13355 9.25173 9.06457 9.29782 8.98864 9.32927C8.91272 9.36072 8.83134 9.37691 8.74916 9.37691C8.66698 9.37691 8.58561 9.36072 8.50968 9.32927C8.43376 9.29782 8.36477 9.25173 8.30666 9.19362L4.99916 5.88487L1.69166 9.19362C1.63355 9.25173 1.56457 9.29782 1.48864 9.32927C1.41272 9.36072 1.33134 9.37691 1.24916 9.37691C1.16698 9.37691 1.08561 9.36072 1.00968 9.32927C0.93376 9.29782 0.864774 9.25173 0.806664 9.19362C0.748554 9.13551 0.702458 9.06652 0.67101 8.9906C0.639561 8.91467 0.623374 8.8333 0.623374 8.75112C0.623374 8.66894 0.639561 8.58756 0.67101 8.51164C0.702458 8.43571 0.748554 8.36673 0.806664 8.30862L4.11541 5.00112L0.806664 1.69362C0.74846 1.63556 0.702281 1.56659 0.670773 1.49066C0.639265 1.41473 0.623047 1.33333 0.623047 1.25112C0.623047 1.16891 0.639265 1.08751 0.670773 1.01157C0.702281 0.935643 0.74846 0.866674 0.806664 0.808617Z"
                        fill="#A8A8A8"
                      />
                    </svg>
                  </button>
                </div>
              ) : null;
            })}
            {isDropdownVisible &&
              inputs.map((input, index) => (
                <div key={index} className="schedule-price-detail__route-select-group">
                  <SelectFieldShuttleAlt
                    htmlFor="pick-up"
                    value={input?.pickUp?.id}
                    // data={reverse ? convertedAirportList : convertedZoneList}
                    data={reverse ? airportList : zoneList}
                    onChange={(item) => {
                      handleInputChange(index, item, 'pickUp');
                    }}
                    placeholder="Masukkan Lokasi Penjemputan"
                    searchFeature
                    isAirport={reverse}
                    // disable
                  />

                  <SelectFieldShuttleAlt
                    htmlFor="drop-off"
                    value={input?.dropOff?.id}
                    // data={reverse ? convertedZoneList : convertedAirportList}
                    data={reverse ? zoneList : airportList}
                    onChange={(item) => {
                      handleInputChange(index, item, 'dropOff');
                    }}
                    placeholder="Masukkan Lokasi Pengantaran"
                    searchFeature
                    isAirport={!reverse}
                  />
                </div>
              ))}

            <div className="schedule-price-detail__route-btn-wrapper">
              <button className="schedule-price-detail__route-btn" onClick={handleAddInput}>
                <span>
                  <PlusIcon />
                </span>
                Tambah Rute
              </button>
            </div>
          </div>
        </div>

        {/* ACTION BUTTON */}
        <div className="actions-button">
          <Button type="button" onClick={() => navigate(-1)} width="192px" height="39px" variant="outline">
            Kembali
          </Button>
          <Button type="button" width="192px" height="39px" variant="primary" onClick={handleSubmit}>
            Simpan
          </Button>
        </div>
      </div>
    </TableWrapper>
  );
};

export default SchedulePriceAirportTransfer;

const priceChange = [
  {
    name: 'Harga Berkurang',
    id: 'pc1',
    change_to: 'min',
  },
  {
    name: 'Harga Bertambah',
    id: 'pc2',
    change_to: 'plus',
  },
];

// ARRAY RADIO BUTTON
const arrayRadionButton = [
  {
    id: 'pr1',
    label: 'Harga Fixed',
    value: 'FIXED_PRICE',
  },
  {
    id: 'pr2',
    label: 'Harga Percentage',
    value: 'PERCENTAGE',
  },
];
