import React, { useEffect } from 'react';
import Modals from '../Modals';
import { useAppContext } from 'components/Context/AppContext';
import { ReactComponent as RoleFilledIcon } from 'icons/role-filled-icon.svg';
import { Button, InputField } from 'components/Global';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createNewRole, getAllRoles } from 'features/roles/actions';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import { getAllRentalLocation } from 'features/rental-location/actions';

const AddRolesModal = () => {
  const { setShowAddRolesModal, setShowSpinner, showToast } = useAppContext();
  const dispatch = useDispatch();
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const [roleData, setRoleData] = useState({
    name: '',
    description: '',
  });
  const [rentalLocationId, setRentalLocationId] = useState({
    id: 0,
    name: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (roleData.name.trim() === '' || roleData.description.trim() === '' || rentalLocationId.name.trim() === '') {
      showToast({ type: 'warning', message: 'Harap Mengisi Semua Field' });
      return;
    }

    try {
      setShowSpinner(true);
      await dispatch(
        createNewRole({
          role_name: roleData.name,
          description: roleData.description,
          location_id: rentalLocationId.id,
        }),
      ).unwrap();

      showToast({ type: 'success', message: 'Berhasil Menambahkan Data' });
    } catch (error) {
      showToast({ type: 'error', message: `Gagal Menambahkan Data: ${error}` });
    } finally {
      setShowSpinner(false);
      setShowAddRolesModal(false);
      dispatch(getAllRoles({ page: 1, limit: 10 }));
    }
  };

  useEffect(() => {
    dispatch(getAllRentalLocation());
  }, []);

  return (
    <Modals
      setState={() => setShowAddRolesModal(false)}
      icon={<RoleFilledIcon fill="#009EF7" width="25px" height="25px" />}
      title="Tambah Role"
    >
      <form className="add-roles-modal" onSubmit={handleSubmit}>
        <div className="add-roles-content">
          <InputField
            label="Nama Role"
            htmlFor="role-name"
            className="role-name"
            autoComplete="off"
            required
            placeholder="Tulis Nama Role"
            style={{ marginBottom: '24px' }}
            value={roleData.name || ''}
            onChange={(e) => setRoleData((prev) => ({ ...prev, name: e.target.value }))}
          />
          <InputField
            label="Deskripsi"
            htmlFor="role-description"
            className="role-description"
            autoComplete="off"
            required
            placeholder="Tulis Deskripsi Role"
            style={{ marginBottom: '24px' }}
            value={roleData.description || ''}
            onChange={(e) => setRoleData((prev) => ({ ...prev, description: e.target.value }))}
          />
          <SelectFieldShuttle
            label="Lokasi Rental"
            htmlFor="lokasi-rental"
            className="without-driver-form__rental-location"
            data={rentalLocationData}
            value={rentalLocationId.id}
            onChange={setRentalLocationId}
            placeholder="Lokasi Rental"
            searchFeature
          />
        </div>
        <div className="add-roles-buttons">
          <Button
            className="button"
            size="sm"
            width="192px"
            variant="outline"
            onClick={() => setShowAddRolesModal(false)}
          >
            Kembali
          </Button>
          <Button className="button" size="sm" width="192px" role="submit">
            Tambah
          </Button>
        </div>
      </form>
    </Modals>
  );
};

export default AddRolesModal;
