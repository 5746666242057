import React, { useState } from 'react';

const Tooltip = (props) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  const contentStyle = `Tooltip-Tip Tooltip-Tip--${props.direction || 'bottom'}`;

  return (
    <div className="Tooltip-Wrapper" onMouseEnter={showTip} onMouseLeave={hideTip}>
      {props.children}
      {active && <div className={contentStyle}>{props.content}</div>}
    </div>
  );
};

export default Tooltip;
