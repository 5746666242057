import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

// GET ALL REVENUE
export const getAllRevenue = createAsyncThunk(
  'revenue/getAllRevenue',
  async (payload = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/admin/dashboard/revenues`;

    const { startDate, endDate } = payload;

    if (startDate) {
      url = url + `?start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET ALL REVENUE TOTAL
export const getAllRevenueTotal = createAsyncThunk(
  'revenue/getAllRevenueTotal',
  async (payload = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/admin/dashboard/revenues`;

    const { startDate, endDate } = payload;

    if (startDate) {
      url = url + `?start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data.total;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET ALL REVENUE COUNT
export const getAllRevenueCount = createAsyncThunk(
  'revenue/getAllRevenueCount',
  async (payload = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/admin/dashboard/revenues`;

    const { startDate, endDate } = payload;

    if (startDate) {
      url = url + `?start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data.count;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET WITH DRIVER REVENUE
export const getWithDriverRevenue = createAsyncThunk(
  'revenue/getWithDriverRevenue',
  async (payload = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/admin/dashboard/revenues?with_driver=1&order_type_id=1`;

    const { startDate, endDate } = payload;

    if (startDate) {
      url = url + `&start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET WITH DRIVER REVENUE TOTAL
export const getWithDriverRevenueTotal = createAsyncThunk(
  'revenue/getWithDriverRevenueTotal',
  async (payload = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/admin/dashboard/revenues?with_driver=1&order_type_id=1`;

    const { startDate, endDate } = payload;

    if (startDate) {
      url = url + `&start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data.total;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET WITH DRIVER REVENUE COUNT
export const getWithDriverRevenueCount = createAsyncThunk(
  'revenue/getWithDriverRevenueCount',
  async (payload = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/admin/dashboard/revenues?with_driver=1&order_type_id=1`;

    const { startDate, endDate } = payload;

    if (startDate) {
      url = url + `&start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data.count;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET WITHOUT DRIVER REVENUE
export const getWithoutDriverRevenue = createAsyncThunk(
  'revenue/getWithoutDriver',
  async (payload = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/admin/dashboard/revenues?without_driver=1&order_type_id=1`;

    const { startDate, endDate } = payload;

    if (startDate) {
      url = url + `&start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios.get(`${url}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET WITHOUT DRIVER REVENUE TOTAL
export const getWithoutDriverRevenuetTotal = createAsyncThunk(
  'revenue/getWithoutDriverRevenuetTotal',
  async (payload = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/admin/dashboard/revenues?without_driver=1&order_type_id=1`;

    const { startDate, endDate } = payload;

    if (startDate) {
      url = url + `&start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios.get(`${url}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data.total;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
// GET WITHOUT DRIVER REVENUE
export const getWithoutDriverRevenueCount = createAsyncThunk(
  'revenue/getWithoutDriverRevenueCount',
  async (payload = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/admin/dashboard/revenues?without_driver=1&order_type_id=1`;

    const { startDate, endDate } = payload;

    if (startDate) {
      url = url + `&start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios.get(`${url}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data.count;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET AIRPORT TRANSFER REVENUE
export const getAirportTransferRevenue = createAsyncThunk(
  'revenue/getAirportTransferRevenue',
  async (payload = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/admin/dashboard/revenues?&order_type_id=2`;

    const { startDate, endDate } = payload;

    if (startDate) {
      url = url + `&start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios.get(`${url}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET AIRPORT TRANSFER REVENUE TOTAL
export const getAirportTransferRevenuetotal = createAsyncThunk(
  'revenue/getAirportTransferRevenuetotal',
  async (payload = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/admin/dashboard/revenues?&order_type_id=2`;

    const { startDate, endDate } = payload;

    if (startDate) {
      url = url + `&start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios.get(`${url}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data.total;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
// GET AIRPORT TRANSFER REVENUE COUNT
export const getAirportTransferRevenueCount = createAsyncThunk(
  'revenue/getAirportTransferRevenueCount',
  async (payload = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/admin/dashboard/revenues?&order_type_id=2`;

    const { startDate, endDate } = payload;

    if (startDate) {
      url = url + `&start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios.get(`${url}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data.count;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
