import { createSlice } from '@reduxjs/toolkit';
import { fetchOrderById, fetchOrderByIdTaskAdmin, fetchOrderByIdWithApproval, getOrderAddOnsById } from './actions';

const initialState = {
  data: {},
  addOns: [],
  status: 'idle',
};

export const detailOrder = createSlice({
  name: 'detailOrder',
  initialState,
  reducers: {
    saveDetailData: (state, action) => {
      state.data = action.payload;
    },
    resetDetailData: (state) => {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderById.pending, (state) => {
        state.status = 'loading';
        state.data = {};
      })
      .addCase(fetchOrderById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchOrderById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchOrderByIdWithApproval.pending, (state) => {
        state.status = 'loading';
        state.data = {};
      })
      .addCase(fetchOrderByIdWithApproval.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchOrderByIdWithApproval.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(getOrderAddOnsById.pending, (state) => {
        state.status = 'loading';
        state.addOns = [];
      })
      .addCase(getOrderAddOnsById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getOrderAddOnsById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.addOns = action.payload;
      })
      .addCase(fetchOrderByIdTaskAdmin.pending, (state) => {
        state.status = 'loading';
        state.data = {};
      })
      .addCase(fetchOrderByIdTaskAdmin.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchOrderByIdTaskAdmin.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      });
  },
});

export const { saveDetailData, resetDetailData } = detailOrder.actions;

export const getDetailOrder = (state) => state.detailOrder?.data;

export default detailOrder.reducer;
