import { createSlice } from '@reduxjs/toolkit';
import { createDepositConfirmation, fetchDepositDetail, fetchDepositOrder, getDepositReports } from './actions';

const initialState = {
  data: {},
  depositDetail: {},
  depositPrice: 0,
  statusConfirmation: false,
  page: 1,
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const depositOrder = createSlice({
  name: 'depositOrder',
  initialState,
  reducers: {
    // reducers goes here
    saveDepositPrice: (state, action) => {
      state.depositPrice = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepositDetail.pending, (state) => {
        state.status = 'loading';
        state.depositDetail = {};
      })
      .addCase(fetchDepositDetail.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchDepositDetail.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.depositDetail = action.payload;
      })
      .addCase(fetchDepositOrder.pending, (state) => {
        state.status = 'loading';
        state.data = {};
      })
      .addCase(fetchDepositOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchDepositOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(createDepositConfirmation.pending, (state) => {
        state.status = 'loading';
        state.statusConfirmation = false;
      })
      .addCase(createDepositConfirmation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createDepositConfirmation.fulfilled, (state) => {
        state.status = 'succeeded';
        state.statusConfirmation = true;
      })
      .addCase(getDepositReports.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getDepositReports.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getDepositReports.fulfilled, (state) => {
        state.status = 'succeeded';
      });
  },
});

export const { saveDepositPrice } = depositOrder.actions;

export const getDepositOrder = (state) => state.depositOrder?.data;
export const getDepositDetail = (state) => state.depositOrder?.depositDetail;

export default depositOrder.reducer;
