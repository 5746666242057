import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllBrands = createAsyncThunk('search/getAllBrands', async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/brands`).then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

export const createNewBrand = createAsyncThunk('vehicle/createNewBrand', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  try {
    const response = await axios
      .post(
        `${BASE_URL}/${API_VERSION}/brands`,
        { name: payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )
      .then((res) => res);

    thunkAPI.dispatch(getAllBrands());

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

export const deleteBrand = createAsyncThunk('vehicle/deleteBrand', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  try {
    const response = await axios
      .delete(`${BASE_URL}/${API_VERSION}/brands/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => res);

    thunkAPI.dispatch(getAllBrands());

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});
