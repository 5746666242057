import { useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ReactComponent as TaskAdminIcon } from 'icons/task-admin-filled-icon.svg';
import { PaginationTable } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import ActionButtons from 'components/Global/Table/ActionButtons';
import EmptyState from 'components/Global/EmptyState';
import { resetSelectedCourierTask } from 'features/couriers/courierTaskSlice';
import { getAllCouriers } from 'features/couriers/actions';
import { getAllApprovalData } from 'features/approvals/actions';
import StatusButton from 'components/Global/Table/StatusButton';
import LoadingSpinner from 'components/Global/LoadingSpinner';
import DetailDisabledIcon from 'icons/detail-disabled-icon.svg';

// const FILTER_DATA = [
//   {
//     id: 'RECONFIRMATION',
//     name: 'Re-Konfirmasi',
//     value: false,
//   },
//   {
//     id: 'CHECKOUT',
//     name: 'Konfirmasi Order',
//     value: false,
//   },
//   {
//     id: 'PAID',
//     name: 'Belum Diproses',
//     value: false,
//   },
//   {
//     id: 'COMPLETED',
//     name: 'Sedang Berjalan',
//     value: false,
//   },
//   {
//     id: 'FINISHED',
//     name: 'Selesai Sewa',
//     value: false,
//   },
//   {
//     id: 'CANCELLED',
//     name: 'Batal Sewa',
//     value: false,
//   },
// ];

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Lengkap', value: 'user_name' },
  { header: 'Task', value: 'name' },
  { header: 'Status Sewa', value: 'status' },
];

const TaskAdminComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: approvalList, isLoading } = useSelector((state) => state.approvals);
  const pageNumber = (page, idx) => (page > 1 ? (page - 1) * 10 + idx : idx);

  useEffect(() => {
    dispatch(
      getAllApprovalData({
        page: currentPage,
        limit: 10,
      }),
    );
  }, [currentPage]);

  const handleDetail = (e) => {
    const approvalId = e.target.parentElement.parentElement.dataset['key'];
    const filteredOrderById = approvalList.data.filter((item) => item.id === +approvalId);

    let params = {
      id: filteredOrderById[0].approval_id,
    };

    if (filteredOrderById[0].approval.data.customer_id) {
      params = { ...params, customerId: filteredOrderById[0].approval.data.customer_id };
    }

    navigate({
      pathname: `detail/${filteredOrderById[0].approval.data.transaction_key}`,
      search: createSearchParams(params).toString(),
    });
    dispatch(resetSelectedCourierTask());
    dispatch(getAllCouriers());
  };

  const data =
    approvalList?.data?.map((item) => ({
      id: item.id,
      user_name: item.approval.data.user_name,
      name: item.approval.approval_setting.description.includes('update order') ? 'Recalculate' : 'Delete Permission',
      status:
        item.status === 'pending'
          ? 'baru'
          : item.status === 'rejected'
          ? 'ditolak'
          : item.status === 'approved'
          ? 'selesai'
          : item.status,
      created_by: item.created_by,
      is_processor: item.is_processor,
    })) || [];

  const rows = 10 - data?.length;

  return (
    <div className="task-admin">
      <div className="task-admin__wrapper">
        <div className="task-admin__header">
          <div className="task-admin__title">
            <TaskAdminIcon fill="#009EF7" width="25px" height="25px" />
            <h2>Task Admin</h2>
          </div>
        </div>

        {isLoading ? (
          <LoadingSpinner />
        ) : data?.length === 0 ? (
          <EmptyState />
        ) : (
          <div className={clsx('table')}>
            <table className="table-wrapper">
              <thead className="table-head">
                <tr>
                  {column.map((item, i) => (
                    <th key={i}>{item.header}</th>
                  ))}
                  <th className="action-col">Action</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {data?.length > 0 &&
                  data.map((item, no) => (
                    <tr key={no}>
                      {column.map((col, idx) => {
                        if (col.value === 'no') {
                          return (
                            <td
                              key={idx}
                              data-type="no"
                              className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                            >
                              {pageNumber(currentPage, no + 1)}
                            </td>
                          );
                        } else if (col.value === 'status') {
                          return (
                            <td
                              key={idx}
                              className={`pre-white-space address_details ${
                                col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                              }`}
                            >
                              {<StatusButton status={item[col.value].toLowerCase()} />}
                            </td>
                          );
                        } else {
                          return (
                            <td
                              key={idx}
                              data-type={col.value}
                              className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                            >
                              {item[col.value]}
                            </td>
                          );
                        }
                      })}
                      <td className="btn-action" data-type="actionButtons" data-key={!item.id ? item.code : item.id}>
                        <ActionButtons
                          detail={
                            item.name === 'Delete Permission' && item.status === 'selesai'
                              ? false
                              : item.status !== 'processed'
                              ? handleDetail
                              : item.status === 'processed' && item.is_processor
                              ? handleDetail
                              : false
                          }
                        />
                        {item.name === 'Delete Permission' && item.status === 'selesai' ? (
                          <img src={DetailDisabledIcon} />
                        ) : item.status !== 'processed' ? null : item.status === 'processed' &&
                          item.is_processor ? null : (
                          <img src={DetailDisabledIcon} />
                        )}
                      </td>
                    </tr>
                  ))}
                {rows > 0 &&
                  [...Array(rows)].map((_, idx) => (
                    <tr key={idx}>
                      {column.map((_, idx) => (
                        <td key={idx}></td>
                      ))}
                      <td className="btn-action"></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        <PaginationTable
          currentPage={currentPage}
          onPageChange={(newPage) => setCurrentPage(newPage)}
          totalCount={approvalList.pagination?.total}
        />
      </div>
    </div>
  );
};

export default TaskAdminComponent;
