import { createSlice } from '@reduxjs/toolkit';
import {
  getAllTermAndconditionLocation,
  getAllTermAndconditionService,
  createTermAndConditionLocation,
  createTermAndConditionService,
  deleteTermAndCondition,
  getTermAndConditionById,
  updateTermAndCondition,
} from './actions';

const initialState = {
  data: {},
  selectedData: {},
  isLoading: false,
  isError: false,
  errorMessage: '', // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const TermAndConditionSlice = createSlice({
  name: 'TermAndConditionSlice',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTermAndconditionLocation.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
        state.data = {};
      })
      .addCase(getAllTermAndconditionLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllTermAndconditionLocation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getAllTermAndconditionService.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
        state.data = {};
      })
      .addCase(getAllTermAndconditionService.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllTermAndconditionService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createTermAndConditionLocation.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
        state.data = {};
      })
      .addCase(createTermAndConditionLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createTermAndConditionLocation.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createTermAndConditionService.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
        state.data = {};
      })
      .addCase(createTermAndConditionService.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createTermAndConditionService.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteTermAndCondition.pending, (state) => {
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(deleteTermAndCondition.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteTermAndCondition.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getTermAndConditionById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
        state.selectedData = {};
      })
      .addCase(getTermAndConditionById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getTermAndConditionById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedData = action.payload;
      })
      .addCase(updateTermAndCondition.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(updateTermAndCondition.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateTermAndCondition.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export default TermAndConditionSlice.reducer;
