import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllAddon = createAsyncThunk('addon/getAllAddon', async ({ limit = 10, page = 1 }, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/addons?limit=${limit}&page=${page}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getDetailAddon = createAsyncThunk('addon/getDetailAddon', async (params, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { id, varietiesId, locationId, locTimeId, startDate, endDate, startTime, endTime } = params;

  let newUrl = `${BASE_URL}/${API_VERSION}/addons/${id}/varieties/${varietiesId}`;

  if (locationId) {
    if (newUrl.includes('?')) {
      newUrl += `&location_id=${locationId}`;
    } else {
      newUrl += `?location_id=${locationId}`;
    }
  }

  if (locTimeId) {
    if (newUrl.includes('?')) {
      newUrl += `&loc_time_id=${locTimeId}`;
    } else {
      newUrl += `?loc_time_id=${locTimeId}`;
    }
  }

  if (startDate) {
    if (newUrl.includes('?')) {
      newUrl += `&start_booking_date=${startDate}`;
    } else {
      newUrl += `?start_booking_date=${startDate}`;
    }
  }

  if (endDate) {
    if (newUrl.includes('?')) {
      newUrl += `&end_booking_date=${endDate}`;
    } else {
      newUrl += `?end_booking_date=${endDate}`;
    }
  }

  if (startTime) {
    if (newUrl.includes('?')) {
      newUrl += `&start_booking_time=${startTime}`;
    } else {
      newUrl += `?start_booking_time=${startTime}`;
    }
  }

  if (endTime) {
    if (newUrl.includes('?')) {
      newUrl += `&end_booking_time=${endTime}`;
    } else {
      newUrl += `?end_booking_time=${endTime}`;
    }
  }

  try {
    const response = await axios.get(newUrl, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getOrderAddon = createAsyncThunk('orderAddon/getOrderAddon', async (transactionKey, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/orders/${transactionKey}/addons`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createAddon = createAsyncThunk('addon/createAddon', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/admin/addons`,
      { ...payload },
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    const data = await response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// UPLOAD FILE IMAGE
export const uploadImageAddon = createAsyncThunk('addon/uploadImageAddon', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/addons/upload`,
      { file: payload },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data.file;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// UPLOAD FILE IMAGE
export const uploadImageAddonUpddate = createAsyncThunk(
  'addon/uploadImageAddonUpddate',
  async ({ payload, id }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/${API_VERSION}/addons/upload`,
        { file: payload },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      return { data: response.data.file, id };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const deleteAddon = createAsyncThunk('addon/deleteAddon', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${BASE_URL}/${API_VERSION}/admin/addons/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// UPDATE ADDON
export const updateAddon = createAsyncThunk('addon/updateAddon', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { payloadData, addonId } = payload;

  try {
    const response = await axios.put(
      `${BASE_URL}/${API_VERSION}/admin/addons/${addonId}`,
      { ...payloadData },
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    const data = await response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAllAddonByParams = createAsyncThunk('addon/getAllAddonByParams', async (params, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { limit = 10, page = 1, locationId, locTimeId, startDate, endDate, startTime, endTime } = params;

  let newUrl = `${BASE_URL}/${API_VERSION}/addons?limit=${limit}&page=${page}`;

  if (locationId) {
    newUrl += `&location_id=${locationId}`;
  }

  if (locTimeId) {
    newUrl += `&loc_time_id=${locTimeId}`;
  }

  if (startDate) {
    newUrl += `&start_booking_date=${startDate}`;
  }

  if (endDate) {
    newUrl += `&end_booking_date=${endDate}`;
  }

  if (startTime) {
    newUrl += `&start_booking_time=${startTime}`;
  }

  if (endTime) {
    newUrl += `&end_booking_time=${endTime}`;
  }

  try {
    const response = await axios.get(newUrl, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
