import React from 'react';
import Modals from '../Modals';
import { useAppContext } from 'components/Context/AppContext';
import { Button, InputField } from 'components/Global';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addViolations } from 'features/violations/violationsSlice';

const Addviolations = () => {
  const dispatch = useDispatch();

  // CONTEXT
  const { setShowAddViolationsModal, showToast } = useAppContext();

  // LOCAL STATE
  const [keteranganDenda, setKeteranganDenda] = useState('');
  const [jumlahDenda, setJumlahDenda] = useState(0);

  // FUCNTION SUBMIT
  const handleSubmit = () => {
    // validation
    const validKeterangan = keteranganDenda.replace(/\s/, '').length !== 0;
    const validJumlah = jumlahDenda !== 0;

    // check if valid
    if (!validKeterangan) {
      return showToast({ type: 'error', message: 'Masukan Keterangan Denda' });
    } else if (!validJumlah) {
      return showToast({ type: 'error', message: 'Masukan Jumlah Denda' });
    }

    // if valid
    const payload = {
      id: Math.random() + 1,
      violation: keteranganDenda,
      fee: +jumlahDenda,
    };

    dispatch(addViolations(payload));
    setShowAddViolationsModal(false);
  };

  return (
    <Modals setState={setShowAddViolationsModal}>
      <div className="add-violations">
        {/* TITLE */}
        <h1 className="add-violations__title">Tambah Denda</h1>

        {/* FORM */}
        <div className="add-violations__form">
          <InputField
            label="Keterangan Denda"
            htmlFor="keterangan-denda"
            value={keteranganDenda}
            onChange={(e) => setKeteranganDenda(e.target.value)}
            placeholder="Masukan keterangan denda"
          />
          <InputField
            type="number"
            label="Jumlah Denda"
            htmlFor="jumlah-denda"
            value={jumlahDenda || ''}
            onChange={(e) => setJumlahDenda(e.target.value)}
            placeholder="Masukan jumlah denda"
          />
        </div>

        {/* BUTTON */}
        <Button type="button" variant="primary" onClick={handleSubmit} style={{ padding: '15px' }}>
          Konfirmasi
        </Button>
      </div>
    </Modals>
  );
};

export default Addviolations;
