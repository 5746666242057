import Modals from '../Modals';
import { useAppContext } from 'components/Context/AppContext';
import { ReactComponent as LocationFilledIcon } from 'icons/location-filled-icon.svg';
import { Button, InputField, SelectFieldSearch } from 'components/Global';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createRentalLocation, updateRentalLocation } from 'features/rental-location/actions';
import timeZones from '../../../utils/time-zones.json';
import { checkPermission, mappingLongTimeZones } from 'utils/functionality';
import CheckboxField from 'components/Global/CheckboxField';
import { useNavigate, useSearchParams } from 'react-router-dom';

const AddCityModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // All Time Zone
  const allTimeZone = mappingLongTimeZones(timeZones).map((item, idx) => ({ id: idx + 1, name: item }));

  // Context
  const { setShowAddNewCity, showToast, setShowSpinner } = useAppContext();
  const { data: serviceData } = useSelector((state) => state.services);
  const { selected: selectedLocation } = useSelector((state) => state.rentalLocation);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  const [searchParams] = useSearchParams();
  const locationId = searchParams.get('location_id');

  // state of city name
  const [cityName, setCityName] = useState('');
  // const [timeZone, setTimeZone] = useState('');
  const [timeZoneIdentifier, setTimeZoneIdentifier] = useState(0);
  const [allServices, setAllServices] = useState([]);
  const [services, setServices] = useState([]);

  // show and hide modal
  const stateModalHandler = (state) => {
    setShowAddNewCity(state);
    navigate('/rental-area');
  };

  // check service function
  const checkServiceHandler = (e) => {
    const checked = e.target.checked;

    if (checked) {
      setServices((prevState) => [...prevState, +e.target.value]);

      const copyAllServices = [...allServices];
      const checkedService = copyAllServices.find((item) => item.id == e.target.value);
      if (checkedService) {
        checkedService.checked = true;
        setAllServices(copyAllServices);
      }
    } else {
      const filteredServices = services.filter((item) => item != e.target.value);
      setServices(filteredServices);

      const copyAllServices = [...allServices];
      const unCheckedService = copyAllServices.find((item) => item.id == e.target.value);
      if (unCheckedService) {
        unCheckedService.checked = false;
        setAllServices(copyAllServices);
      }
    }
  };

  // function mutation
  const actionMutation = async (method, payload, successMessage, errorMessage) => {
    if (method === 'ADD') {
      try {
        setShowSpinner(true);
        await dispatch(createRentalLocation(payload)).unwrap();
        showToast({ type: 'success', message: successMessage });
        setShowAddNewCity(false);
        setShowSpinner(false);
      } catch (error) {
        showToast({ type: 'error', message: errorMessage });
        setShowSpinner(false);
      }
    } else {
      try {
        setShowSpinner(true);
        await dispatch(updateRentalLocation({ id: payload.id, payload: payload.body })).unwrap();
        showToast({ type: 'success', message: successMessage });
        setShowAddNewCity(false);
        setShowSpinner(false);
      } catch (error) {
        showToast({ type: 'error', message: errorMessage });
        setShowSpinner(false);
      }
    }
  };

  // submit add function
  const submitHandler = async (e) => {
    e.preventDefault();

    if (!cityName) {
      showToast({ type: 'error', message: 'Masukan Nama Daerah Sewa' });
      return;
    }

    if (!timeZoneIdentifier) {
      showToast({ type: 'error', message: 'Pilih Time Zone Identifier' });
      return;
    }

    // if (!timeZone) {
    //   showToast({ type: 'error', message: 'Masukan Time Zone' });
    //   return;
    // }

    if (!services.length) {
      showToast({ type: 'error', message: 'Pilih Service' });
      return;
    }

    const payload = {
      name: cityName,
      // time_zone: timeZone,
      time_zone_identifier: allTimeZone.find((item) => item.id == timeZoneIdentifier)?.name,
      sub_service_ids: services,
    };

    if (locationId) {
      actionMutation('UPDATE', { id: locationId, body: payload }, 'Berhasil Update Item', 'Gagal Update Item');
    } else {
      actionMutation('ADD', payload, 'Berhasil Tambah Item', 'Gagal Tambah Item');
    }
  };

  // Mapping data if selected data doesnt empty
  useEffect(() => {
    let mappingServiceData = [];

    if (Object.keys(selectedLocation).length && locationId && serviceData.length) {
      // set time zone identifier
      const selectedTimeZoneIdentifier = allTimeZone.find(
        (item) => item.name === selectedLocation.time_zone_identifier,
      )?.id;

      // set services that checked
      const selectedServices = selectedLocation.sub_services?.map((item) => item.id);

      const serviceSewaMobil = serviceData.find((item) => item.name === 'Sewa Mobil');

      // mapping services
      serviceSewaMobil?.sub_services.forEach((item) => {
        if (item.name !== 'Daily' && item.name !== 'Airport Transfer') return;

        if (item.facilities.length !== 0) {
          for (let facilitiesChild of item.facilities) {
            if (
              selectedLocation.sub_services &&
              selectedLocation.sub_services.find((ser) => ser.id === facilitiesChild.id)
            ) {
              mappingServiceData.push({ id: facilitiesChild.id, name: facilitiesChild.name, checked: true });
            } else {
              mappingServiceData.push({ id: facilitiesChild.id, name: facilitiesChild.name, checked: false });
            }
          }
        } else {
          if (selectedLocation.sub_services && selectedLocation.sub_services.find((ser) => ser.id === item.id)) {
            mappingServiceData.push({ id: item.id, name: item.name, checked: true });
          } else {
            mappingServiceData.push({ id: item.id, name: item.name, checked: false });
          }
        }
      });

      setCityName(selectedLocation.name);
      setTimeZoneIdentifier(selectedTimeZoneIdentifier);
      setServices(selectedServices || []);
      setAllServices(mappingServiceData);
    } else if (serviceData.length) {
      const serviceSewaMobil = serviceData.find((item) => item.name === 'Sewa Mobil');

      serviceSewaMobil?.sub_services.forEach((item) => {
        if (item.name !== 'Daily' && item.name !== 'Airport Transfer') return;

        if (item.facilities.length !== 0) {
          item.facilities.forEach((fac) => mappingServiceData.push({ id: fac.id, name: fac.name, checked: false }));
        } else {
          mappingServiceData.push({ id: item.id, name: item.name, checked: false });
        }
      });
      setAllServices(mappingServiceData);
    }
  }, [selectedLocation, serviceData]);

  return (
    <Modals
      setState={stateModalHandler}
      title={locationId ? 'Edit Daerah Sewa' : 'Tambah Daerah Sewa'}
      icon={<LocationFilledIcon fill="#009EF7" width="25px" height="25px" />}
    >
      <div className="city-modal">
        <form className="city-modal-form" onSubmit={submitHandler}>
          <InputField
            label="Nama Daerah Sewa"
            htmlFor="nama-daerah-sewa"
            value={cityName}
            onChange={(e) => setCityName(e.target.value)}
            placeholder="Nama Daerah Sewa"
          />

          <SelectFieldSearch
            label="Time Zone Identifier"
            placeholder="Pilih Time Zone Identifier"
            htmlFor="time-zone-identifier"
            data={allTimeZone}
            value={timeZoneIdentifier}
            onClick={setTimeZoneIdentifier}
          />

          {/* <InputField
            label="Time Zone"
            htmlFor="time-zone"
            value={timeZone}
            onChange={(e) => setTimeZone(e.target.value)}
            placeholder="Time Zone"
          /> */}

          <div className="city-modal-form__services">
            {allServices.length !== 0 &&
              allServices.map((item) => (
                <div key={item.id}>
                  <CheckboxField
                    label={item.name}
                    name={item.name}
                    value={item.id}
                    checked={item.checked}
                    onChange={checkServiceHandler}
                  />
                </div>
              ))}
          </div>

          {(!locationId || checkPermission(offCanvasMenu, currentMenu, 'update')) && (
            <Button width={192} style={{ marginLeft: 'auto' }} role="submit">
              {locationId ? 'Submit' : 'Tambah'}
            </Button>
          )}
        </form>
      </div>
    </Modals>
  );
};

export default AddCityModal;
