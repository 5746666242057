import { useAppContext } from 'components/Context/AppContext';
import { Button, InputField, RadioButton, SelectFieldVehicle } from 'components/Global';
import CheckboxField from 'components/Global/CheckboxField';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import TextAreaInput from 'components/Global/TextArea';
import UploadImageCustomOrder from 'components/Global/UploadImageCustomOrder';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addDays, format, parseISO } from 'date-fns';
import { indonesianDateFormat } from 'utils/helpers';
import { getAllRentalLocation } from 'features/rental-location/actions';
import { getAllVehicles } from 'features/vehicle/action';
import { createPromo, updatePromo, uploadImagePromo } from 'features/promo/actions';
import { getAllCategories } from 'features/category/actions';
import { getDetailPromo } from 'features/promo/actions';
import { checkPermission } from 'utils/functionality';

// TIPE SEWA ARRAY
const tipeSewa = [
  {
    id: 0,
    name: 'Dengan Supir',
    value: 'with_driver',
  },
  {
    id: 1,
    name: 'Tanpa Supir',
    value: 'without_driver',
  },
  {
    id: 2,
    name: 'Airport Transfer',
    value: 'airport_transfer',
  },
];

// ARRAY RADIO BUTTON
const arrayRadionButton = [
  {
    id: 'pr1',
    label: 'Harga Fixed',
    value: 'fixed_price',
  },
  {
    id: 'pr2',
    label: 'Harga Percentage',
    value: 'percentage',
  },
];

// LAMA RENTAL  ARRAY
const lamaRental = [
  {
    name: '2 Hari',
    id: 2,
  },
  {
    name: '3 Hari',
    id: 3,
  },
  {
    name: '4 Hari',
    id: 4,
  },
  {
    name: '5 Hari',
    id: 5,
  },
];

// INITAL VALUE  / PAYLOAD
const initialStateValue = {
  name: '',
  value_type: '',
  value: '',
  with_same_value: true,
  min_purchase: 0,
  min_rent_durations: 0,
  start_date: '',
  end_date: '',
  rent_type: [],
  for_all_product: false,
  promotion_promo_value: [],
  locations: [],
  for_new_user: false,
  airport_packages: [],
  description: '',
  image: '',
};

const DetailPromoList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { setShowSpinner, showToast, setShowConfirmation } = useAppContext();
  const { selectedPromo, isLoading, imagePromo } = useSelector((state) => state.promo);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const { data: vehiclesData } = useSelector((state) => state.vehicle);
  const { data: categoryData } = useSelector((state) => state.category);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  const [searchParams] = useSearchParams();
  const [promoData, setPromoData] = useState(initialStateValue);
  const [promoUpdateData, setPromoUpdateData] = useState({});
  const [newUser, setNewUser] = useState(false);
  const [promoName, setPromoName] = useState('');
  const [priceType, setPriceType] = useState('');
  const [rentType, setRentType] = useState([]);
  const [vechiclePackage, setVehiclePackage] = useState([]);
  const [selectedCar, setSelectedCar] = useState([]);

  const pathName = location.pathname.split('/')[2];

  // FUNCTION ACTION REQUEST
  const sendPayloadData = async (action, payload, fulfilledMessage, errorMessage1, errorMessage2) => {
    try {
      const res =
        action === 'ADD'
          ? await dispatch(createPromo(payload))
          : action === 'UPDATE'
          ? await dispatch(updatePromo(payload))
          : () => {};

      if (res.meta?.requestStatus === 'fulfilled') {
        showToast({ type: 'success', message: fulfilledMessage });
        navigate('/promo?tab-active=Promo');
        return;
      }
      showToast({ type: 'error', message: errorMessage1 });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: `${errorMessage2}: ${error}` });
    }
  };

  // SUBMIT FUNCTION
  const handleSubmit = (e) => {
    e.preventDefault();

    // some validation
    const validPromoName = promoData.name.trim().length !== 0;
    const validMinRentalDurations = promoData.min_rent_durations !== 0;
    const validStartDate = promoData.start_date !== '';
    const validEndDate = promoData.end_date !== '';
    // const validVehicles = promoData.promotion_promo_value.length !== 0;
    let validVehicles;
    if (pathName === 'add-item') {
      validVehicles = promoData.promotion_promo_value.length !== 0;
    } else {
      validVehicles = selectedCar.length !== 0;
    }
    const validRentType = promoData.rent_type.length !== 0;
    const validPriceType = promoData.value_type !== '';
    const validPrice = promoData.value !== '' && promoData.value !== 0;
    const validPackage = promoData.airport_packages.length !== 0;
    const validDescription = promoData.description.trim() !== '';
    const validPromoImage = promoData.image !== '';

    if (!validPromoName) {
      return showToast({ type: 'error', message: 'Masukan nama promo' });
    } else if (!validMinRentalDurations) {
      return showToast({ type: 'error', message: 'Pilih lama rental' });
    } else if (!validStartDate) {
      return showToast({ type: 'error', message: 'Pilih periode mulai' });
    } else if (!validEndDate) {
      return showToast({ type: 'error', message: 'Pilih periode selesai' });
    } else if (!validVehicles) {
      return showToast({ type: 'error', message: 'Pilih mobil' });
    } else if (!validRentType) {
      return showToast({ type: 'error', message: 'Pilih tipe rental' });
    } else if (!validPriceType) {
      return showToast({ type: 'error', message: 'Pilih Potongan Harga' });
    } else if (!validPrice) {
      return showToast({ type: 'error', message: 'Masukan potongan harga' });
    } else if (!validDescription) {
      return showToast({ type: 'error', message: 'Masukan deskripsi' });
    } else if (!validPromoImage) {
      return showToast({ type: 'error', message: 'Upload banner promo' });
    }

    if (rentType.find((item) => item === 'airport_transfer')) {
      if (!validPackage) {
        return showToast({ type: 'error', message: 'Masukan airport package' });
      }
    }

    // create or update promo
    const baliLocationId = rentalLocationData.find((item) => item.name === 'BALI')?.id;

    let payload;

    if (pathName === 'add-item') {
      payload = { ...promoData, locations: [baliLocationId] };
    } else {
      const promoId = searchParams.get('promo_id');
      payload = {
        payloadData: promoUpdateData,
        promoId,
      };
    }

    // if add item
    if (pathName === 'add-item') {
      setShowConfirmation({
        message: 'Apakah anda yakin ingin menambah promo?',
        show: true,
        onClick: () =>
          sendPayloadData('ADD', payload, 'Berhasil Tambah Promo', 'Gagal Tambah Promo', 'Terjadi Kesalahan'),
      });
    }

    // if update item
    if (pathName !== 'add-item') {
      setShowConfirmation({
        message: 'Apakah anda yakin ingin lanjut update promo',
        show: true,
        onClick: () =>
          sendPayloadData('UPDATE', payload, 'Berhasil Update Promo', 'Gagal Update Promo', 'Terjadi Kesalahan'),
      });
    }
  };

  // FUNCTION NEW USER CHECKBOX
  const handleCheck = (e) => {
    setNewUser(e.target.checked);

    if (pathName === 'add-item') {
      setPromoData({ ...promoData, for_new_user: e.target.checked });
    } else {
      setPromoUpdateData({ ...promoUpdateData, for_new_user: e.target.checked });
    }
  };

  // FUNTION PROMO NAME
  const handlePromoName = (e) => {
    const name = e.target.value;

    setPromoName(name);

    if (pathName === 'add-item') {
      setPromoData({ ...promoData, name });
    } else {
      setPromoUpdateData({ ...promoUpdateData, name });
    }
  };

  // FUNCTION DURATION RENT
  const handleDurationRent = (e) => {
    const duration = +e.target.id;

    setPromoData({ ...promoData, min_rent_durations: duration });

    if (pathName !== 'add-item') setPromoUpdateData({ ...promoUpdateData, min_rent_durations: duration });
  };

  // FUNCTION START DATE
  const handleStartDate = (date) => {
    if (date) {
      setPromoData({
        ...promoData,
        start_date: `${date.toISOString()}`,
        end_date: '',
      });

      if (pathName !== 'add-item') setPromoUpdateData({ ...promoUpdateData, start_date: `${date.toISOString()}` });
    }
  };

  // FUNCTION START DATE
  const handleEndDate = (date) => {
    if (date) {
      const endDate = new Date(date);
      endDate.setHours(23, 59, 59, 999);

      setPromoData({
        ...promoData,
        end_date: `${endDate.toISOString()}`,
      });

      if (pathName !== 'add-item') setPromoUpdateData({ ...promoUpdateData, end_date: `${endDate.toISOString()}` });
    }
  };

  // SET TIPE SEWA WHEN CHECKED
  const handleTipeSewa = (id, status) => {
    const existedType = rentType.find((item) => item === tipeSewa.find((el) => el.id == id)?.value);

    // if exist will return nothing
    if (existedType && status) return;

    // set tipe sewa if false
    const rentTypeValue = tipeSewa.find((el) => el.id == id)?.value;

    if (status) {
      setRentType((prevState) => [...prevState, rentTypeValue]);

      if (pathName === 'add-item') {
        setPromoData({ ...promoData, rent_type: [...promoData.rent_type, rentTypeValue] });
      } else {
        if (promoUpdateData.rent_type?.to_be_added) {
          setPromoUpdateData({
            ...promoUpdateData,
            rent_type: { to_be_added: [...promoUpdateData.rent_type.to_be_added, rentTypeValue] },
          });
        } else {
          setPromoUpdateData({ ...promoUpdateData, rent_type: { to_be_added: [rentTypeValue] } });
        }
      }
    } else {
      const filteredData = rentType.filter((item) => item !== rentTypeValue);
      setRentType(filteredData);

      if (pathName === 'add-item') {
        setPromoData({
          ...promoData,
          rent_type: promoData.rent_type.filter((item) => item != rentTypeValue),
        });
        if (rentTypeValue === 'airport_transfer') {
          setPromoData({ ...promoData, airport_packages: [] });
          setVehiclePackage([]);
        }
      } else {
        if (promoUpdateData.rent_type?.to_be_deleted) {
          const newPromoUpdateData = { ...promoUpdateData };
          newPromoUpdateData.rent_type = {
            to_be_deleted: [...promoUpdateData.rent_type.to_be_deleted, rentTypeValue],
          };
          if (rentTypeValue === 'airport_transfer') {
            newPromoUpdateData.airport_packages = { to_be_deleted: [...promoData.airport_packages] };
            setVehiclePackage([]);
          }
          setPromoUpdateData(newPromoUpdateData);
        } else {
          const newPromoUpdateData = { ...promoUpdateData };
          newPromoUpdateData.rent_type = { to_be_deleted: [rentTypeValue] };
          if (rentTypeValue === 'airport_transfer') {
            newPromoUpdateData.airport_packages = { to_be_deleted: [...promoData.airport_packages] };
            setVehiclePackage([]);
          }
          setPromoUpdateData(newPromoUpdateData);
        }
      }
    }
  };

  // SET VEHICLE PACKAGE WHEN CHEKED
  const handleVehiclePackage = (id, status) => {
    const existedType = vechiclePackage.find(
      (item) => item === categoryData?.category?.find((el) => el.id == id)?.name,
    );

    // if exist will return nothing
    if (existedType && status) return;

    // set tipe sewa if false
    const vehiclePackageValue = categoryData?.category?.find((el) => el.id == id);

    if (status) {
      setVehiclePackage((prevState) => [...prevState, vehiclePackageValue?.name]);
      setPromoData({ ...promoData, airport_packages: [...promoData.airport_packages, vehiclePackageValue?.id] });

      if (pathName !== 'add-item') {
        if (promoUpdateData.airport_packages?.to_be_added) {
          setPromoUpdateData({
            ...promoUpdateData,
            airport_packages: {
              to_be_added: [...promoUpdateData.airport_packages.to_be_added, vehiclePackageValue?.id],
            },
          });
        } else {
          setPromoUpdateData({ ...promoUpdateData, airport_packages: { to_be_added: [vehiclePackageValue?.id] } });
        }
      }
    } else {
      const filteredData = vechiclePackage.filter((item) => item !== vehiclePackageValue?.name);
      setVehiclePackage(filteredData);

      setPromoData({
        ...promoData,
        airport_packages: promoData.airport_packages.filter((item) => item != vehiclePackageValue?.id),
      });

      if (pathName !== 'add-item') {
        if (promoUpdateData.airport_packages?.to_be_deleted) {
          setPromoUpdateData({
            ...promoUpdateData,
            airport_packages: {
              to_be_deleted: [...promoUpdateData.airport_packages.to_be_deleted, vehiclePackageValue?.id],
            },
          });
        } else {
          setPromoUpdateData({ ...promoUpdateData, airport_packages: { to_be_deleted: [vehiclePackageValue?.id] } });
        }
      }
    }
  };

  // SET VALUE TYPE
  const handleValueType = (id) => {
    setPriceType(id);
    const ValueType = arrayRadionButton.find((item) => item.id == id)?.value;

    if (pathName === 'add-item') {
      setPromoData({ ...promoData, value_type: ValueType });
    } else {
      setPromoUpdateData({ ...promoUpdateData, value_type: ValueType });
    }
  };

  // SET VALUE INPUT
  const handleValueInput = (e) => {
    setPromoData({ ...promoData, value: isNaN(e.target.value) ? 0 : +e.target.value });

    if (pathName !== 'add-item')
      setPromoUpdateData({ ...promoUpdateData, value: isNaN(e.target.value) ? 0 : +e.target.value });
  };

  // SET DESCRIPTION VALUE
  const handleDescription = (e) => {
    setPromoData({ ...promoData, description: e.target.value });

    if (pathName !== 'add-item') setPromoUpdateData({ ...promoUpdateData, description: e.target.value });
  };

  // UPLOAD IMAGE
  const handleUploadImage = (imageFile) => {
    dispatch(uploadImagePromo(imageFile));
  };

  // SET VEHICLE
  const handleSelectedCar = (cars) => {
    let choosedVehicles = [];
    for (let choosedData of cars) {
      for (let chooseCar of choosedData.cars) {
        choosedVehicles.push({ product_id: chooseCar.id, value: !promoData.value ? 0 : promoData.value });
      }
    }

    setSelectedCar(cars);

    if (pathName === 'add-item') {
      setPromoData({ ...promoData, promotion_promo_value: choosedVehicles });
    } else {
      const vehicleIds = promoData.promotion_promo_value?.map((item) => item.product_id);
      const choosedVehicleIds = choosedVehicles.map((item) => item.product_id);

      if (choosedVehicleIds.length > vehicleIds.length) {
        const addedVehicleId = choosedVehicleIds.filter((item) => !vehicleIds.includes(item));

        setPromoUpdateData({
          ...promoUpdateData,
          promotion_promo_value: {
            to_be_added: [...addedVehicleId],
          },
        });

        // if (promoUpdateData.promotion_promo_value?.to_be_added) {
        //   setPromoUpdateData({
        //     ...promoUpdateData,
        //     promotion_promo_value: {
        //       to_be_added: [...promoUpdateData.promotion_promo_value.to_be_added, ...addedVehicleId],
        //     },
        //   });
        // } else {
        //   setPromoUpdateData({
        //     ...promoUpdateData,
        //     promotion_promo_value: {
        //       to_be_added: [...addedVehicleId],
        //     },
        //   });
        // }
      } else if (choosedVehicleIds.length < vehicleIds.length) {
        const deletedVehicleId = vehicleIds.filter((item) => !choosedVehicleIds.includes(item));

        setPromoUpdateData({
          ...promoUpdateData,
          promotion_promo_value: {
            to_be_deleted: [...deletedVehicleId],
          },
        });

        // if (promoUpdateData.promotion_promo_value?.to_be_deleted) {
        //   setPromoUpdateData({
        //     ...promoUpdateData,
        //     promotion_promo_value: {
        //       to_be_deleted: [...promoUpdateData.promotion_promo_value.to_be_deleted, ...deletedVehicleId],
        //     },
        //   });
        // } else {
        //   setPromoUpdateData({
        //     ...promoUpdateData,
        //     promotion_promo_value: {
        //       to_be_deleted: [...deletedVehicleId],
        //     },
        //   });
        // }
      }
    }
  };

  // FUNCTION TO PREVENT SCROLLING ON INPUT NUMBER TYPE
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  // INTERSECTION FUNCTION OF VEHICLES
  const intersectionAction = () => {
    // if last page then just return nothing
    if (vehiclesData.pagination.page >= vehiclesData.pagination.last_page) return;

    dispatch(getAllVehicles(vehiclesData.pagination.page + 1));
  };

  // SCROLL TO TOP
  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(getAllReward());
    dispatch(getAllRentalLocation());
    dispatch(getAllVehicles());
    dispatch(getAllCategories({ page: 1 }));

    if (pathName !== 'add-item') {
      const promoId = searchParams.get('promo_id');
      if (promoId) {
        dispatch(getDetailPromo(promoId));
      }
    }
  }, []);

  // SET INITIAL VALUE IF SELECTED PROMO EXIST
  useEffect(() => {
    if (pathName !== 'detail') return;
    if (Object.keys(selectedPromo).length === 0) return;
    if (Object.keys(categoryData).length === 0) return;

    // loop the vehicle schedule price to set selected car
    let newSelectedCar = [];

    for (let vehicle of selectedPromo.promotion_promo_value) {
      if (newSelectedCar.length === 0) {
        newSelectedCar.push({ cars: [{ ...vehicle.vehicle, checked: true }], total: 1 });
      } else {
        const existingVehicle = newSelectedCar.find((item) => item.cars[0].name === vehicle.vehicle.name);
        if (existingVehicle) {
          const copyNewSelectedCar = [...newSelectedCar];
          copyNewSelectedCar[copyNewSelectedCar.indexOf(existingVehicle)] = {
            cars: [...existingVehicle.cars, { ...vehicle.vehicle, checked: true }],
            total: existingVehicle.total + 1,
          };
          newSelectedCar = copyNewSelectedCar;
        } else {
          newSelectedCar = [...newSelectedCar, { cars: [{ ...vehicle.vehicle, checked: true }], total: 1 }];
        }
      }
    }

    const selectedValueTypeId = arrayRadionButton.find((item) => item.value === selectedPromo.value_type)?.id;
    const selectedPackage = selectedPromo.airport_packages?.map(
      (item) => categoryData?.category?.find((cat) => cat.id === item)?.name,
    );

    // set inital value with selected promo data
    setNewUser(selectedPromo.for_new_user);
    setPromoName(selectedPromo.name);
    setSelectedCar(newSelectedCar);
    setRentType(selectedPromo.rent_type);
    setPriceType(selectedValueTypeId);
    setVehiclePackage(selectedPackage);

    setPromoData({
      name: selectedPromo.name,
      value_type: selectedPromo.value_type,
      value: selectedPromo.value,
      with_same_value: selectedPromo.with_same_value,
      min_purchase: selectedPromo.min_purchase,
      min_rent_durations: selectedPromo.min_rent_durations,
      start_date: selectedPromo.start_date,
      end_date: selectedPromo.end_date,
      rent_type: selectedPromo.rent_type,
      for_all_product: selectedPromo.for_all_product,
      promotion_promo_value: selectedPromo.promotion_promo_value,
      locations: selectedPromo.locations,
      for_new_user: selectedPromo.for_new_user,
      airport_packages: selectedPromo.airport_packages,
      description: selectedPromo.description,
      image: selectedPromo.image,
    });
  }, [selectedPromo, categoryData]);

  // SET VALUE TO ALL VEHICLE
  useEffect(() => {
    if (!promoData.value) return;

    setPromoData({
      ...promoData,
      promotion_promo_value: promoData.promotion_promo_value.map((item) => ({
        product_id: item.product_id,
        value: promoData.value,
      })),
    });
  }, [promoData.value]);

  // SET IMAGE PROMO
  useEffect(() => {
    if (!imagePromo) return;

    setPromoData({ ...promoData, image: imagePromo });
    if (pathName !== 'add-item') setPromoUpdateData({ ...promoUpdateData, image: imagePromo });
  }, [imagePromo]);

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  return (
    <div className="detail-promo-list">
      <h2>Promo</h2>

      {/* NEW USER */}
      <CheckboxField
        label="Khusus Pengguna Baru"
        htmlfor="new-user"
        className="checkbox-new-user"
        checked={newUser}
        value="new-user"
        onChange={handleCheck}
      />

      <form>
        <div className="form-field">
          <div className="form-field__name-time">
            {/* PROMO NAME */}
            <InputField
              label="Nama Promo"
              htmlFor="nama-promo"
              placeholder="Masukan Nama Promo"
              value={promoName}
              onChange={handlePromoName}
            />
            {/* RENT TIME */}
            <SelectFieldDropdown
              label="Lama Rental"
              htmlFor="lama-rental"
              placeholder="Pilih Lama Rental"
              data={lamaRental}
              value={promoData.min_rent_durations}
              onChange={handleDurationRent}
            />
          </div>

          {/* PROMO PERIOD */}
          <div className="form-field__period">
            <h3>Periode Promo</h3>
            <div className="form-field__period__date">
              <SelectFieldDate
                htmlFor="tanggal-mulai"
                name="tanggal-mulai"
                placeholder="Pilih Tanggal Mulai"
                selectedDay={promoData.start_date ? new Date(promoData.start_date) : promoData.start_date}
                value={
                  promoData.start_date
                    ? indonesianDateFormat(format(new Date(promoData.start_date), 'yyyy-MM-dd')).toString()
                    : promoData.start_date
                }
                handleDaySelect={handleStartDate}
                dateContainerXOffset="left"
              />
              <SelectFieldDate
                htmlFor="tanggal-selesai"
                name="tanggal-selesai"
                placeholder="Pilih Tanggal Selesai"
                selectedDay={promoData.end_date ? new Date(promoData.end_date) : promoData.end_date}
                value={
                  promoData.end_date
                    ? indonesianDateFormat(format(new Date(promoData.end_date), 'yyyy-MM-dd')).toString()
                    : promoData.end_date
                }
                fromDate={addDays(parseISO(promoData.start_date), 1)}
                handleDaySelect={handleEndDate}
                disable={promoData.start_date === ''}
                dateContainerXOffset="left"
              />
            </div>
          </div>

          {/* VEHICLE */}
          <SelectFieldVehicle
            label="Jenis Mobil"
            htmlFor="jenis-mobil"
            placeholder="Cari Mobil.."
            data={vehiclesData}
            selectedCar={selectedCar}
            onSelectCar={handleSelectedCar}
            intersectionAction={intersectionAction}
          />

          {/* RENT TYPE */}
          <SelectFieldDropdown
            label="Tipe Rental"
            htmlFor="tipe-sewa"
            data={tipeSewa}
            placeholder="Tipe Sewa"
            value={rentType}
            onChange={handleTipeSewa}
            checkBox={true}
          />

          {/* PRICE */}
          <div className="form-field__price">
            <h3>Potongan Harga:</h3>
            <div className="form-field__price__radio">
              <RadioButton name="price" data={arrayRadionButton} state={priceType} setState={handleValueType} />
            </div>
            <InputField
              type="number"
              placeholder={priceType === 'pr1' ? 'Rp 0' : priceType === 'pr2' ? '0%' : 'Rp 0'}
              value={promoData.value || ''}
              onChange={handleValueInput}
              onWheel={numberInputOnWheelPreventChange}
              disable={!priceType}
            />
          </div>

          {/* PACKAGE AIRPORT TRANSFER */}
          <SelectFieldDropdown
            label="Pilih Package Airport Transfer"
            className="form-field__package"
            htmlFor="package-airport"
            data={categoryData?.category?.map((item) => ({
              id: item.id,
              name: item.name,
              value: item.name,
            }))}
            placeholder="Pilih Package"
            value={vechiclePackage}
            onChange={handleVehiclePackage}
            checkBox={true}
            disable={!rentType.find((item) => item === 'airport_transfer')}
          />

          {/* DESCRIPTION */}
          <TextAreaInput
            label="Deskripsi"
            htmlFor="deskripsi"
            className="form-field__text-area-input"
            value={promoData.description}
            onChange={handleDescription}
          />

          {/* PROMO IMAGE */}
          <div className="form-field__promo-image">
            <h3>Banner Promo</h3>
            {/* upload image field */}
            <UploadImageCustomOrder
              htmlFor="banner-promo"
              uploadAction={handleUploadImage}
              imageFile={promoData.image}
            />
          </div>
        </div>

        {/* BUTTON */}
        <div className="action-button">
          <Button type="button" onClick={() => navigate(-1)} width="192px" height="39px" variant="outline">
            Kembali
          </Button>
          {(pathName === 'add-item' || checkPermission(offCanvasMenu, currentMenu, 'update')) && (
            <Button type="button" onClick={handleSubmit} width="192px" height="39px" variant="primary">
              Simpan
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default DetailPromoList;
