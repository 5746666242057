import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

// GET TOTAL DEPOSIT
export const getTotalDeposit = createAsyncThunk(
  'deposit/getTotalDeposit',
  async (payload = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/admin/dashboard/deposits`;

    const { startDate, endDate } = payload;

    if (startDate) {
      url = url + `?start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET TOTAL DEPOSIT WITH DRIVER
export const getTotalDepositWithDriver = createAsyncThunk(
  'deposit/getTotalDepositWithDriver',
  async (payload = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/admin/dashboard/deposits?with_driver=1&order_type_id=1`;

    const { startDate, endDate } = payload;

    if (startDate) {
      url = url + `&start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET TOTAL DEPOSIT WITHOUT DRIVER
export const getTotalDepositWithoutDriver = createAsyncThunk(
  'deposit/getTotalDepositWithoutDriver',
  async (payload = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/admin/dashboard/deposits?without_driver=1&order_type_id=1`;

    const { startDate, endDate } = payload;

    if (startDate) {
      url = url + `&start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET TOTAL DEPOSIT AIRPORT TRANSFER
export const getTotalDepositAirportTransfer = createAsyncThunk(
  'deposit/getTotalDepositAirportTransfer',
  async (payload = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/admin/dashboard/deposits?order_type_id=2`;

    const { startDate, endDate } = payload;

    if (startDate) {
      url = url + `&start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
