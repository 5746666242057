import React from 'react';
import { useAppContext } from 'components/Context/AppContext';

const PreviewImageIdentity = () => {
  const { showPreviewImageIdentity, setShowPreviewImageIdentity } = useAppContext();

  return (
    <div className="preview-image-identity">
      <div className="wrapper">
        <div
          className="overlay"
          onClick={() => setShowPreviewImageIdentity({ ...showPreviewImageIdentity, show: false })}
        />
        <img src={showPreviewImageIdentity.image} alt="image preview" className="img" />
      </div>
    </div>
  );
};

export default PreviewImageIdentity;
