import React from 'react';
import { TableWrapper } from 'components/Global';
import { ReactComponent as PromoFilledIcon } from 'icons/promo-filled-icon.svg';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import DetailPromoList from '../PromoList/DetailPromoList';

const PromoDetailComponent = () => {
  const navigate = useNavigate();

  // GET PATHNAME
  const location = useLocation();

  // GET PARAMS
  const [searchParams] = useSearchParams();

  // LOCAL STATE
  const [paramsName, setParamsName] = useState('');

  // SET PATH AND PARAMS NAME
  useEffect(() => {
    const params = searchParams.get('tab');
    setParamsName(params);
  }, [location, searchParams]);

  return (
    <div className="promo-detail-component">
      {/* ARROW BACK */}
      <header className="promo-detail-component__header" onClick={() => navigate(-1)}>
        <LeftArrow />
        <p>Kembali</p>
      </header>

      {/* TABLE */}
      <TableWrapper
        icon={<PromoFilledIcon fill="#009EF7" width="25px" height="25px" />}
        title={`Tambah ${paramsName.charAt(0).toUpperCase() + paramsName.slice(1)}`}
      >
        <DetailPromoList />
      </TableWrapper>
    </div>
  );
};

export default PromoDetailComponent;
