import { Button, TableWrapper, UploadVehicleImage } from 'components/Global';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utils/functionality';

const Contentright = ({ imageFiles, handleRemove }) => {
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const isAddVehicleState = location.pathname.includes('add-vehicle');
  // console.log(imageFiles);

  return (
    <div className="content--right">
      <div className="content--right__upload">
        <TableWrapper icon={<CarIcon fill="#009EF7" width="25px" height="25px" />} title="Foto Mobil">
          <p>Foto Mobil</p>
          <UploadVehicleImage previewable imageFiles={imageFiles} handleRemove={handleRemove} />
        </TableWrapper>
      </div>
      {/* SUBMIT BUTTON */}
      {(isAddVehicleState || checkPermission(offCanvasMenu, currentMenu, 'update')) && (
        <div className="content--right__button">
          <Button type="submit" size="sm" className="button" width="267px">
            Simpan
          </Button>
        </div>
      )}
    </div>
  );
};

export default Contentright;
