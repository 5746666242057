import React from 'react';

const CardItem = ({ icon, title, text }) => {
  return (
    <div className="card">
      <div className="card__icon">{icon}</div>
      <h2 className="card__title">{title}</h2>
      <p className="card__text">{text}</p>
    </div>
  );
};

export default CardItem;
