import { useState } from 'react';
import clsx from 'clsx';
import React from 'react';
import ClickOutside from '../ClickOutside';
import { ReactComponent as Duration } from '../../../icons/duration-icon.svg';
import { addDays, format } from 'date-fns';

const SelectFieldDuration = ({
  label,
  name,
  htmlFor,
  value, // state
  className,
  style,
  placeholder,
  onClick,
  startDate,
  disable,
  icon = true,
  ...rest
}) => {
  // SHOW DROPDOWN FUNCTION
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  // CHOOSEN DURATION
  const [choosenDuration, setChoosenDuration] = useState(0);

  const date = startDate ? format(startDate, 'dd') : '00';
  // const futureDate = startDate ? format(addDays(startDate), 'dd') : '00';
  const month = startDate ? format(startDate, 'MMMM') : 'January';
  const year = startDate ? format(startDate, 'yyyy') : '2000';

  // FUNCTION SUBMIT DURATION
  const handleChooseDuration = () => {
    onClick(choosenDuration);
    setIsShowDropdown(false);
  };

  return (
    <div className={clsx('select-field-duration', className)} style={{ width: '100%' }}>
      {label && (
        <label htmlFor={htmlFor} className="select-field-duration__label">
          {label}
        </label>
      )}
      <div
        className={disable ? 'select-field-duration__input-field disable' : 'select-field-duration__input-field'}
        style={{ width: '100%' }}
      >
        {icon && <Duration className="select-field-duration__input-field__calendar" />}
        <input
          id={htmlFor}
          type="text"
          name={name}
          style={style}
          disabled={disable}
          className="select-field-duration__input-field__input"
          value={value ? `${value} Hari` : placeholder}
          onFocus={() => setIsShowDropdown(true)}
          readOnly
          {...rest}
        />
      </div>
      {isShowDropdown && (
        <ClickOutside onClickOutside={() => setIsShowDropdown(false)}>
          <div className="select-field-duration__dropdown">
            <div className="select-field-duration__dropdown__container">
              {/* TITLE */}
              <span className="title">Durasi</span>

              {/* LIST */}
              <div className="duration-list">
                <ul>
                  {Array.from({ length: 14 }, (_, i) => i + 1).map((item, idx) => (
                    <li
                      key={idx}
                      onClick={() => setChoosenDuration(item)}
                      className={item == choosenDuration ? 'active' : ''}
                    >
                      <p>
                        <span>{item} Hari</span>{' '}
                        {`(${date}${
                          idx === 0 ? '' : ` - ${startDate ? format(addDays(startDate, idx), 'dd') : '00'}`
                        } ${month} ${year})`}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>

              {/* SELESAI BUTTON */}
              <div className="selesai">
                <p onClick={handleChooseDuration}>Selesai</p>
              </div>
            </div>
          </div>
        </ClickOutside>
      )}
    </div>
  );
};

export default SelectFieldDuration;
