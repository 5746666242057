import { createSlice } from '@reduxjs/toolkit';

import { getAllApprovalData, getApprovalDataById, getApprovalSettings } from './actions';

const initialState = {
  data: [],
  approvalSetting: [],
  selected: {},
  isLoading: false,
  isError: false,
  errorMessage: '', // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const approvals = createSlice({
  name: 'approvals',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllApprovalData.pending, (state) => {
        state.data = [];
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getAllApprovalData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllApprovalData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getApprovalSettings.pending, (state) => {
        state.approvalSetting = [];
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getApprovalSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getApprovalSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.approvalSetting = action.payload;
      })
      .addCase(getApprovalDataById.pending, (state) => {
        state.selected = {};
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getApprovalDataById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getApprovalDataById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selected = action.payload;
      });
  },
});

export default approvals.reducer;
