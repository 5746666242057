import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

// GET ALL TERM AND CONDITION LOCATION
export const getAllTermAndconditionLocation = createAsyncThunk(
  'TermAndConditionSlice/getAllTermAndconditionLocation',
  async (params, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { page = 1, limit = 10 } = params;

    try {
      const response = await axios.get(
        `${BASE_URL}/${API_VERSION}/term-conditions?page=${page}&limit=${limit}&reference_type=location`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET ALL TERM AND CONDITION LOCATION
export const getAllTermAndconditionService = createAsyncThunk(
  'TermAndConditionSlice/getAllTermAndconditionService',
  async (params, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { page = 1, limit = 10 } = params;

    try {
      const response = await axios.get(
        `${BASE_URL}/${API_VERSION}/term-conditions?page=${page}&limit=${limit}&reference_type=sub_service`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET TERM AND CONDITION BY ID
export const getTermAndConditionById = createAsyncThunk(
  'TermAndConditionSlice/getTermAndConditionById',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.get(`${BASE_URL}/${API_VERSION}/term-conditions/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// CREATE TERM AND CONDITION LOCATION
export const createTermAndConditionLocation = createAsyncThunk(
  'TermAndConditionSlice/createTermAndConditionLocation',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/${API_VERSION}/term-conditions`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      thunkAPI.dispatch(getAllTermAndconditionLocation({ page: 1, limit: 10 }));

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// CREATE TERM AND CONDITION LOCATION
export const createTermAndConditionService = createAsyncThunk(
  'TermAndConditionSlice/createTermAndConditionService',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/${API_VERSION}/term-conditions`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      thunkAPI.dispatch(getAllTermAndconditionLocation({ page: 1, limit: 10 }));

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// DELETE TERM AND CONDITION
export const deleteTermAndCondition = createAsyncThunk(
  'TermAndConditionSlice/deleteTermAndCondition',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.delete(`${BASE_URL}/${API_VERSION}/term-conditions/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// UPDATE TERM AND CONDITION
export const updateTermAndCondition = createAsyncThunk(
  'TermAndConditionSlice/updateTermAndCondition',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { payloadData, tacId } = payload;

    try {
      const response = await axios.put(
        `${BASE_URL}/${API_VERSION}/term-conditions/${tacId}`,
        { ...payloadData },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const data = await response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
