import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getTemplateDetail = createAsyncThunk('notification/getTemplateDetail', async (code, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/notifications/templates/${code}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getNotificationsTemplate = createAsyncThunk(
  'notification/getNotificationsTemplate',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;
    const { page, limit = 10 } = payload;

    try {
      const response = await axios.get(
        `${BASE_URL}/${API_VERSION}/notifications/templates?limit=${limit}&page=${page}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const createPlayerNotification = createAsyncThunk(
  'notification/createPlayerNotification',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/${API_VERSION}/notifications/players/token`,
        { token: payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const sendNotification = createAsyncThunk('notification/sendNotification', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/backoffices/notifications`,
      { ...payload },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
