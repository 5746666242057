import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { ReactComponent as MappinBlue } from '../../../icons/mappin-icon-blue.svg';

const SelectFieldZone = ({
  label,
  name,
  id,
  value, // state
  onChange, // setState
  className,
  placeholder,
  disable,
}) => {
  const dropdownRef = useRef(null);

  const { data: zoneData } = useSelector((state) => state.zone);

  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [allZoneData, setAllZoneData] = useState([]);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isChoosingList, setIsChoosingList] = useState(true);
  const [isFocus, setIsFocus] = useState('');

  // FUNCTION DROPDOWN
  const toggleDropdown = () => {
    setIsShowDropdown(!isShowDropdown);
    setIsChoosingList(false);
  };

  // FUNCTION CHOOSING LIST
  const handleChoose = (zone) => {
    // temporary value
    const e = { target: { value: zone.name } };

    onChange(e);
    setIsShowDropdown(false);
    setIsChoosingList(true);
  };

  // SET ALL ZONE DATA
  useEffect(() => {
    if (!zoneData || zoneData.length === 0) return;

    setAllZoneData(zoneData);
  }, [zoneData]);

  // FUCNTION OUTSIDE CLICK
  useEffect(() => {
    // Add a click event listener to the document to handle clicks outside the dropdown.
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts.
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // SEARCH FUNCTION
  useEffect(() => {
    if (!zoneData || zoneData.length === 0) return;

    if (value === '') {
      const copyZoneData = [...zoneData];
      const sortedZoneData = copyZoneData.sort(
        (a, b) => parseInt(a.name.split(' ')[1]) - parseInt(b.name.split(' ')[1]),
      );

      setAllZoneData(sortedZoneData);

      setIsChoosingList(false);
      return;
    }

    const timeout = setTimeout(() => {
      if (!isFocus) return;

      if (isChoosingList) {
        const copyZoneData = [...zoneData];
        const sortedZoneData = copyZoneData.sort(
          (a, b) => parseInt(a.name.split(' ')[1]) - parseInt(b.name.split(' ')[1]),
        );

        setAllZoneData(sortedZoneData);
      } else {
        const splittedFirstLetter = value[0].toUpperCase();
        const splittedRestLetter = value.slice(1, value.length);

        const capitalizeValue = `${splittedFirstLetter}${splittedRestLetter}`;

        const copyZoneData = [...zoneData];
        const sortedZoneData = copyZoneData.sort(
          (a, b) => parseInt(a.name.split(' ')[1]) - parseInt(b.name.split(' ')[1]),
        );

        const filteredZoneData = sortedZoneData.filter((item) => item.name.includes(capitalizeValue));
        setIsLoadingSearch(true);

        setTimeout(() => {
          setAllZoneData(filteredZoneData);
          setIsLoadingSearch(false);
        }, 500);
      }

      setIsChoosingList(false);

      if (!isChoosingList) {
        setIsShowDropdown(true);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [value, isFocus]);

  useEffect(() => {
    !isShowDropdown && setIsChoosingList(true);
  }, [isShowDropdown]);

  return (
    <div className={clsx('select-field-zone', className)} ref={dropdownRef}>
      {label && (
        <label htmlFor={id} className="select-field-zone__label">
          {label}
        </label>
      )}
      <div
        className={disable ? 'select-field-zone__input-field disable' : 'select-field-zone__input-field'}
        onClick={toggleDropdown}
      >
        <MappinBlue className="select-field-zone__input-field__icon" />
        <input
          className={`select-field-zone__input-field__input ${disable ? 'disable' : ''}`}
          id={id}
          name={name}
          value={value}
          disabled={disable}
          onChange={onChange}
          placeholder={placeholder}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
        />
      </div>

      {/* DROPDOWN */}
      {isShowDropdown && (
        <ul className="select-field-zone__dropdown">
          {allZoneData && allZoneData.length !== 0 && !isLoadingSearch && (
            <li className="select-field-zone__dropdown__head-list">Area Penyewaan</li>
          )}

          {allZoneData &&
            allZoneData.length !== 0 &&
            !isLoadingSearch &&
            allZoneData.map((item) => (
              <li
                key={item.id}
                id={item.id}
                onClick={() => handleChoose(item)}
                className="select-field-zone__dropdown__content-list"
              >
                <>
                  <div className="head">
                    <MappinBlue />
                    <p>{item.name}</p>
                  </div>
                </>
              </li>
            ))}

          {allZoneData && allZoneData.length === 0 && !isLoadingSearch && (
            <li className="select-field-zone__dropdown__empty-list">No data found</li>
          )}

          {!allZoneData && !isLoadingSearch && (
            <li className="select-field-zone__dropdown__empty-list">No data found</li>
          )}

          {isLoadingSearch && value && <li className="select-field-zone__dropdown__empty-list">Loading ...</li>}
        </ul>
      )}
    </div>
  );
};

export default SelectFieldZone;
