import { createSlice } from '@reduxjs/toolkit';
import { fetchWithoutDriverOrder } from './actions';

const initialState = {
  data: {},
  page: 1,
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const withoutDriverOrder = createSlice({
  name: 'withoutDriverOrder',
  initialState,
  reducers: {
    // reducers goes here
    saveVehicleData: (state, action) => {
      return { ...state, vehicleList: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWithoutDriverOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWithoutDriverOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchWithoutDriverOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      });
  },
});

export const { saveVehicleData } = withoutDriverOrder.actions;

export default withoutDriverOrder.reducer;
