import React from 'react';
import TextAreaEditor from 'components/Global/TextAreaEditor';

const LanguageEditor = ({ lang, allValue, value, onChange }) => {
  const changeHandler = (value) => {
    const copyAllValue = [...allValue];
    const choosedValue = copyAllValue.find((item) => item.id === lang.id);

    if (choosedValue) {
      choosedValue.value = value;
      onChange(copyAllValue);
    }
  };

  return (
    <div className="language-editor">
      <TextAreaEditor value={value} onChange={changeHandler} />
    </div>
  );
};

export default LanguageEditor;
