import React from 'react';
import { ReactComponent as Checklist } from '../../../icons/check-icon.svg';
import clsx from 'clsx';

const CheckboxField = ({
  label,
  htmlfor,
  checked,
  onChange,
  name,
  value,
  className,
  style,
  disable = false,
  variants = 'light',
}) => {
  const variantsColor = {
    primary: 'checkbox-field__checkbox primary',
    light: 'checkbox-field__checkbox light',
  };

  return (
    <span className={clsx('checkbox-field', className)} style={style}>
      <span className={clsx(checked ? variantsColor[variants] : 'checkbox-field__checkbox', disable && 'disabled')}>
        <Checklist className="checkbox-field__checkbox__icon" />
        <input
          type="checkbox"
          id={htmlfor}
          name={name}
          onChange={onChange}
          value={value}
          className="checkbox-field__checkbox__input"
          disabled={disable}
          checked={checked}
        />
      </span>
      {label && (
        <label htmlFor={htmlfor} className="checkbox-field__label">
          {label}
        </label>
      )}
    </span>
  );
};

export default CheckboxField;
