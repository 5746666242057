import { useAppContext } from 'components/Context/AppContext';
import { Button } from 'components/Global';
import Modals from '../Modals';

const BlockConfirmationModal = () => {
  const { setShowBlockConfirmation, showBlockConfirmation } = useAppContext();

  return (
    <Modals setState={() => setShowBlockConfirmation({ show: false })}>
      <div className="block-confirmation">
        <p className="block-confirmation__desc">{showBlockConfirmation.message}</p>
        <div className="block-confirmation__btn-group">
          <Button variant="outline" wfull onClick={() => setShowBlockConfirmation({ show: false })}>
            Kembali
          </Button>
          <Button variant="primary" wfull onClick={showBlockConfirmation.onClick}>
            Iya, Lanjutkan
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default BlockConfirmationModal;
