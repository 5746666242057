import React from 'react';

const SearchBar = ({ value, placeholder, style, onChange }) => {
  return (
    <div className="table-searchbar" style={style}>
      <section className="field-wrapper">
        <input
          className="search-field"
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        <img src="/assets/icon/search-icon.svg" className="search-icon" alt="search icon" />
      </section>
    </div>
  );
};

export default SearchBar;
