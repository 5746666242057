import { CardItem, TableWrapper } from 'components/Global';
import { ReactComponent as OrderInIcon } from 'icons/order-in-icon.svg';
import { ReactComponent as OrderDoneIcon } from 'icons/order-done-icon.svg';
import { ReactComponent as OrderProcessIcon } from 'icons/order-process-icon.svg';
import { ReactComponent as IncomeIcon } from 'icons/income-icon.svg';
import React from 'react';
import ChartComponent from './ChartComponent';
import TransactionInTable from './TransactionInTable';
import RefundTable from './RefundTable';
import UnderDevelopment from 'components/Global/UnderDevelopment';

const TransactionRecapComponent = () => {
  return (
    <>
      <UnderDevelopment>
        <div className="transaction-recap">
          <div className="transaction-recap__banner">
            <CardItem icon={<OrderInIcon width="25px" height="25px" />} text="101" title="Jumlah Pesanan Masuk" />
            <CardItem icon={<OrderDoneIcon width="25px" height="25px" />} text="99" title="Jumlah Pesanan Selesai" />
            <CardItem
              icon={<OrderProcessIcon width="25px" height="25px" />}
              text="98"
              title="Jumlah Pesanan Diproses"
            />
          </div>
          <div className="transaction-recap__chart">
            <TableWrapper
              icon={<IncomeIcon width="25px" height="25px" />}
              title="Penghasilan"
              CustomFilterComponent={
                <select
                  // name={name}
                  // value={value}
                  // onChange={onChange}
                  // defaultValue={defaultValue}
                  className="input-field"
                  style={{ paddingRight: '40px' }}
                >
                  <option value={1}>Per-Hari</option>
                </select>
              }
            >
              <div className="transaction-recap__chart--total">
                <h2>IDR 18.000.000</h2>
              </div>
              <div className="transaction-recap__chart--chart">
                <ChartComponent />
              </div>
            </TableWrapper>
          </div>
          <div className="transaction-recap__tables">
            <TransactionInTable />
            <RefundTable />
          </div>
        </div>
      </UnderDevelopment>
    </>
  );
};

export default TransactionRecapComponent;
