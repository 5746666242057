import { createSlice } from '@reduxjs/toolkit';
import { fetchFinishedOrder } from './actions';

const initialState = {
  data: {},
  page: 1,
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const finishedOrder = createSlice({
  name: 'finishedOrder',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFinishedOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFinishedOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchFinishedOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      });
  },
});

export const getFinishedOrder = (state) => state.finishedOrder.data;
export const getFinishedOrderStatus = (state) => state.finishedOrder.status;

export default finishedOrder.reducer;
