import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { PaginationTable, Table, TableWrapper } from 'components/Global';
import { getAllLanguage, getLanguageById } from 'features/language/actions';
import EmptyState from 'components/Global/EmptyState';
// import { ReactComponent as PlusIcon } from 'icons/plus-icon.svg';
import { ReactComponent as LanguageFilledIcon } from 'icons/language-filled-icon.svg';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Bahasa', value: 'name' },
  { header: 'Kode Bahasa', value: 'code' },
];

const LanguageComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showToast, setShowAddNewLanguage } = useAppContext();
  const { data: languageData, isLoading, isError } = useSelector((state) => state.language);

  const [currentPage, setCurrentPage] = useState(1);

  const [searchParams] = useSearchParams();
  const languageId = searchParams.get('language_id');

  // const addLanguageHandler = () => {
  //   setShowAddNewLanguage(true);
  // };

  // const deleteLanguagehandler = (e) => {
  //   const languageId = e.target.parentElement.parentElement.dataset['key'];

  //   setShowConfirmation({
  //     message: 'Apakah anda yakin ingin menghapus Item',
  //     show: true,
  //     onClick: async () => {
  //       try {
  //         await dispatch(deleteLanguage(languageId)).unwrap();
  //         showToast({ type: 'success', message: 'Berhasil Hapus Item' });
  //         setCurrentPage(1);
  //       } catch (error) {
  //         showToast({ type: 'error', message: 'Gagal Hapus Item' });
  //       }
  //     },
  //   });
  // };

  // const editHandler = (e) => {
  //   const languageId = e.target.parentElement.parentElement.dataset['key'];

  //   dispatch(getLanguageById(languageId));
  //   navigate(`/language?language_id=${languageId}`);
  //   setShowAddNewLanguage(true);
  // };

  useEffect(() => {
    dispatch(getAllLanguage(currentPage));

    if (languageId) {
      dispatch(getLanguageById(languageId));
      navigate(`/language?language_id=${languageId}`);
      setShowAddNewLanguage(true);
    }
  }, [currentPage]);

  useEffect(() => {
    if (!isLoading && isError) {
      return showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }
  }, [isError, isLoading]);

  return (
    <div className="language">
      {/* <div className="language__button">
        <Button width="192px" height="39px" className="button-add" onClick={addLanguageHandler}>
          <PlusIcon />
          <span className="button-label">Tambah Bahasa</span>
        </Button>
      </div> */}

      {!Object.keys(languageData).length || !languageData.data || !languageData.data.length ? (
        <EmptyState />
      ) : (
        <TableWrapper title="Bahasa" icon={<LanguageFilledIcon fill="#009EF7" width="25px" height="25px" />}>
          <Table
            column={column}
            data={languageData.data}
            // actionBtn
            // onDel={deleteLanguagehandler}
            // onEdit={editHandler}
          />
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={languageData?.pagination?.total_data}
          />
        </TableWrapper>
      )}
    </div>
  );
};

export default LanguageComponent;
