import { createSlice } from '@reduxjs/toolkit';
import { fetchCheckoutOrder } from './actions';

const initialState = {
  data: {},
  page: 1,
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const checkoutOrder = createSlice({
  name: 'checkoutOrder',
  initialState,
  reducers: {
    // reducers goes here
    saveVehicleData: (state, action) => {
      return { ...state, vehicleList: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCheckoutOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCheckoutOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchCheckoutOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      });
  },
});

export const getCheckoutOrder = (state) => state.checkoutOrder.data;
export const getCheckoutOrderStatus = (state) => state.checkoutOrder.status;
export const { saveVehicleData } = checkoutOrder.actions;

export default checkoutOrder.reducer;
