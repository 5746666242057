import React, { useEffect } from 'react';
import { useState } from 'react';
import RoleAssignDetail from './RoleAssignDetail';
import { PaginationTable, TableWrapper } from 'components/Global';
import { ReactComponent as RoleAssignFilledIcon } from 'icons/role-assign-filled-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmployees } from 'features/employee/actions';
import { fetchCustomerInfo } from 'features/user/actions';
import { useAppContext } from 'components/Context/AppContext';
import { getAllRoles } from 'features/roles/actions';
import StatusButton from 'components/Global/Table/StatusButton';
import ActionButtons from 'components/Global/Table/ActionButtons';
import LoadingSpinner from 'components/Global/LoadingSpinner';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Lengkap', value: 'name' },
  { header: 'Email', value: 'email' },
  { header: 'No. Handphone', value: 'phone' },
  { header: 'Status User', value: 'status' },
  { header: 'Whatsapp', value: 'wa_number' },
];

const RolesAssignComponent = () => {
  const [showRoleAssignDetail, setShowRoleAssignDetail] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { data: employeesData, isLoading } = useSelector((state) => state.employees);
  const { setShowAddRolesAssignModal } = useAppContext();
  const pageNumber = (page, idx) => (page > 1 ? (page - 1) * 10 + idx : idx);

  useEffect(() => {
    dispatch(getAllEmployees(currentPage));
  }, [currentPage]);

  useEffect(() => {
    dispatch(getAllRoles({ limit: 20 }));
  }, []);

  const handleDetail = (e) => {
    const employeeId = e.target.parentElement.parentElement.dataset['key'];
    const selectedEmployee = employeesData.employee.find((item) => item.id === employeeId);

    dispatch(fetchCustomerInfo(selectedEmployee.id));
    setShowRoleAssignDetail(true);
  };

  const handleSelectRole = (e) => {
    const employeeId = e.target.parentElement.parentElement.dataset['key'];
    const selectedEmployee = employeesData.employee.find((item) => item.id === employeeId);

    dispatch(fetchCustomerInfo(selectedEmployee.id));
    setShowAddRolesAssignModal(true);
  };

  const rows = 10 - employeesData.employee?.length;

  return (
    <div className="roles">
      {showRoleAssignDetail ? (
        <RoleAssignDetail setShowRoleDetail={setShowRoleAssignDetail} />
      ) : (
        <>
          <TableWrapper title="Assign Role" icon={<RoleAssignFilledIcon fill="#009EF7" width="25px" height="25px" />}>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <div className={'table'}>
                <table className="table-wrapper">
                  <thead className="table-head">
                    <tr>
                      {column.map((item, i) => (
                        <th key={i}>{item.header}</th>
                      ))}
                      <th className="action-col">Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {employeesData.employee?.length > 0 &&
                      employeesData.employee.map((item, no) => (
                        <tr key={no}>
                          {column.map((col, idx) => {
                            if (col.value === 'no') {
                              return (
                                <td
                                  key={idx}
                                  data-type="no"
                                  className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                                >
                                  {pageNumber(currentPage, no + 1)}
                                </td>
                              );
                            } else if (col.value === 'status') {
                              return (
                                <td
                                  key={idx}
                                  data-type={'status'}
                                  className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                                >
                                  {<StatusButton status={item[col.value]} />}
                                </td>
                              );
                            } else {
                              return (
                                <td
                                  key={idx}
                                  data-type={col.value}
                                  className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                                >
                                  {item[col.value]}
                                </td>
                              );
                            }
                          })}
                          <td
                            className="btn-action"
                            data-type="actionButtons"
                            data-key={!item.id ? item.code : item.id}
                          >
                            <ActionButtons detail={handleDetail} />
                            {item?.role === '' && <ActionButtons success={handleSelectRole} />}
                          </td>
                        </tr>
                      ))}
                    {rows > 0 &&
                      [...Array(rows)].map((_, idx) => (
                        <tr key={idx}>
                          {column.map((_, idx) => (
                            <td key={idx}></td>
                          ))}
                          <td className="btn-action"></td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            <PaginationTable
              currentPage={currentPage}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              totalCount={employeesData.pagination?.total_data}
            />
          </TableWrapper>
        </>
      )}
    </div>
  );
};

export default RolesAssignComponent;
