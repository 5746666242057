import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowLeft } from 'icons/arrow-left.svg';
import { Button, InputField } from 'components/Global';
import RoleAssignmentComponent, { decimalToBinary } from 'components/Global/RoleAssignment';
// import { ReactComponent as AddIcon } from 'icons/plus-filled-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { resetSelectedRole } from 'features/roles/slice';
import { useAppContext } from 'components/Context/AppContext';
import { getAllRoles, getRoleById, updateRole } from 'features/roles/actions';
import { assignRolePermission, getRolePermissionsById, updateAssignRolePermission } from 'features/permissions/actions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetSelectedPermission } from 'features/permissions/slice';
import { checkEmptyObject, checkPermission } from 'utils/functionality';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import { getAllRentalLocation } from 'features/rental-location/actions';

const RoleDetail = () => {
  const { selected: data } = useSelector((state) => state.roles);
  const { data: permissions, selectedPermission } = useSelector((state) => state.permissions);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const { showToast, setShowConfirmation } = useAppContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [roleData, setRoleData] = useState({
    id: 0,
    name: '',
    description: '',
  });
  const [rentalLocation, setRentalLocation] = useState({
    id: 0,
    name: '',
  });
  const [addRolePermissionData, setAddRolePermissionData] = useState([]);
  const [updateRolePermissionData, setUpdateRolePermissionData] = useState([]);
  const [rolePermissionsData, setRolePermissionsData] = useState([]);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  useEffect(() => {
    if (data === undefined) return;
    setRoleData((prev) => ({ ...prev, name: data?.name, id: data?.id, description: data?.description }));
  }, [data]);

  useEffect(() => {
    if (checkEmptyObject(data) || checkEmptyObject(rentalLocationData)) return;
    setRentalLocation(() =>
      rentalLocationData.find((item) => item.id === data.location_id)
        ? rentalLocationData.find((item) => item.id === data.location_id)
        : {
            id: 0,
            name: '',
          },
    );
  }, [rentalLocationData, data]);

  useEffect(() => {
    if (id) {
      dispatch(getRoleById(id));
      dispatch(getRolePermissionsById(id));
    }
  }, [id]);

  useEffect(() => {
    dispatch(getAllRentalLocation());
  }, []);

  const backHandler = () => {
    navigate(-1);
    dispatch(resetSelectedRole());
    dispatch(resetSelectedPermission());
  };

  const updateHandler = async () => {
    if (roleData.name.trim() === '' || roleData.description.trim() === '' || rentalLocation.name.trim() === '') {
      showToast({ type: 'warning', message: 'Harap Isi Nama dan Deskripsi Role' });
      return;
    }

    const permissionToAddPayload = addRolePermissionData.map((item) => ({
      permission_id: item.id,
      action: {
        read: +decimalToBinary(item.access)[3],
        create: +decimalToBinary(item.access)[2],
        update: +decimalToBinary(item.access)[1],
        delete: +decimalToBinary(item.access)[0],
      },
    }));
    const permissionToUpdatePayload = updateRolePermissionData.map((item) => ({
      id: item.role_permission_id,
      permission_id: item.id,
      action: {
        read: +decimalToBinary(item.access)[3],
        create: +decimalToBinary(item.access)[2],
        update: +decimalToBinary(item.access)[1],
        delete: +decimalToBinary(item.access)[0],
      },
    }));

    setShowConfirmation({
      message: 'Apakah anda yakin ingin mengubah data?',
      show: true,
      onClick: async () => {
        if (addRolePermissionData.length > 0) {
          try {
            await dispatch(
              assignRolePermission({
                role_id: +id,
                permission_action: permissionToAddPayload,
              }),
            ).unwrap();
            showToast({ type: 'success', message: 'Berhasil Menambahkan Permission Role' });
            setAddRolePermissionData([]);
          } catch (error) {
            showToast({ type: 'error', message: `Terjadi Kesalahan: ${error.slug}` });
          }
        }

        if (updateRolePermissionData.length > 0) {
          try {
            await dispatch(
              updateAssignRolePermission({
                role_id: +id,
                action_permission: permissionToUpdatePayload,
              }),
            ).unwrap();
            showToast({ type: 'success', message: 'Berhasil Mengubah Permission Role' });
            setUpdateRolePermissionData([]);
          } catch (error) {
            showToast({ type: 'error', message: `Terjadi Kesalahan: ${error.slug}` });
          }
        }

        if (
          roleData.name !== data?.name ||
          roleData.description !== data?.description ||
          roleData.location_id !== rentalLocation.id
        ) {
          try {
            await dispatch(
              updateRole({
                role_name: roleData?.name,
                description: roleData?.description,
                id: roleData?.id,
                location_id: rentalLocation.id,
              }),
            ).unwrap();
            showToast({ type: 'success', message: 'Berhasil Mengubah Data Role' });
          } catch (error) {
            showToast({ type: 'error', message: `Terjadi Kesalahan: ${error}` });
          }
        }

        navigate(-1);
        dispatch(getAllRoles());
        dispatch(resetSelectedRole());
        dispatch(getRolePermissionsById(id));
      },
    });
  };

  return (
    <div className="roles">
      <div className="roles-detail">
        <button className="roles-detail__back-btn" onClick={backHandler}>
          <ArrowLeft />
          <span>Kembali</span>
        </button>

        <div className="roles-detail__header">
          <div className="roles-detail__content">
            <InputField
              label="Nama Role"
              htmlFor="role-name"
              className="role-name"
              autoComplete="off"
              value={roleData?.name || ''}
              onChange={(e) => setRoleData((prev) => ({ ...prev, name: e.target.value }))}
            />
          </div>
          <div className="roles-detail__content">
            <InputField
              label="Deskripsi"
              htmlFor="role-description"
              className="role-description"
              autoComplete="off"
              value={roleData?.description || ''}
              onChange={(e) => setRoleData((prev) => ({ ...prev, description: e.target.value }))}
            />
          </div>
          <div className="roles-detail__content">
            <SelectFieldShuttle
              label="Lokasi Rental"
              htmlFor="lokasi-rental"
              className="without-driver-form__rental-location"
              data={rentalLocationData}
              value={rentalLocation.id}
              onChange={setRentalLocation}
              placeholder="Lokasi Rental"
              searchFeature
            />
          </div>
        </div>

        <div className="roles-detail__body">
          <div className="roles-detail__content">
            <RoleAssignmentComponent
              selectedPermissions={selectedPermission}
              permissionList={permissions || []}
              setUpdateRolePermissionData={setUpdateRolePermissionData}
              data={rolePermissionsData}
              setData={setRolePermissionsData}
              setAddRolePermissionData={setAddRolePermissionData}
            />
          </div>
        </div>

        {checkPermission(offCanvasMenu, currentMenu, 'update') && (
          <div className="roles-detail__button">
            <Button
              className="button"
              size="sm"
              width="192px"
              onClick={updateHandler}
              disabled={
                roleData.name === data?.name &&
                roleData.description === data?.description &&
                updateRolePermissionData?.length == 0 &&
                addRolePermissionData?.length == 0
              }
            >
              Simpan
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RoleDetail;
