import { createSlice } from '@reduxjs/toolkit';
import { getOrderAddon } from './actions';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  errorMessage: {}, // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const orderAddon = createSlice({
  name: 'orderAddon',
  initialState,
  reducers: {
    // reducers goes here
    resetSelectedOrderAddon: (state) => {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderAddon.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getOrderAddon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getOrderAddon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});

export const { resetSelectedOrderAddon } = orderAddon.actions;

export default orderAddon.reducer;
