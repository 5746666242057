import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { getAllRentalLocation } from 'features/rental-location/actions';
import { deleteAddon, getAllAddon } from 'features/addon/actions';
import { resetImageVarieties, resetImageVarietiesUpdate, resetSelectedAddon } from 'features/addon/slice';
import { checkEmptyObject } from 'utils/functionality';
import { Button, PaginationTable, Table, TableWrapper } from 'components/Global';
import EmptyState from 'components/Global/EmptyState';
import { ReactComponent as PlusIcon } from 'icons/plus-icon.svg';
import { ReactComponent as AdditionalFilledIcon } from 'icons/additional-filled-icon.svg';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Additional', value: 'name' },
  { header: 'Lokasi', value: 'location_additional_item' },
  { header: 'Harga', value: 'unit_price' },
  { header: 'Variasi Warna', value: 'color_variation' },
  { header: 'Total Stock', value: 'total_stock' },
];

const AdditionalComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setShowSpinner, setShowConfirmation, showToast } = useAppContext();

  const { data: addonData, isLoading } = useSelector((state) => state.addon);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);

  const [currentPage, setCurrentPage] = useState(1);
  const [newData, setNewData] = useState([]);

  const addAdditionalHandler = () => {
    navigate(`/additional/add-item`);
  };

  const deleteAdditionalhandler = (e) => {
    const additionalId = e.target.parentElement.parentElement.dataset['key'];

    setShowConfirmation({
      show: true,
      message: 'Apakah anda yakin ingin menghapus additional?',
      onClick: async () => {
        try {
          await dispatch(deleteAddon(additionalId)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Hapus Additional' });
          dispatch(getAllAddon({ page: currentPage, limit: 10 }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          showToast({ type: 'error', message: `${error.message}` });
        }
      },
    });
  };

  const editHandler = (e) => {
    const additionalId = e.target.parentElement.parentElement.dataset['key'];

    const additionalArray = e.target.parentElement.parentElement.parentElement.children[4]?.dataset['key'];
    const additionalVarieties = JSON.parse(additionalArray)?.map((item) => item.id);

    let url = `/additional/detail?id=${additionalId}`;

    for (let variety of additionalVarieties) {
      url += `&variety_id=${variety}`;
    }

    dispatch(resetSelectedAddon());
    dispatch(resetImageVarieties());
    dispatch(resetImageVarietiesUpdate());
    navigate(url);
  };

  useEffect(() => {
    dispatch(getAllAddon({ page: currentPage, limit: 10 }));
    dispatch(getAllRentalLocation());
  }, []);

  useEffect(() => {
    if (!addonData || checkEmptyObject(addonData)) return;
    if (!rentalLocationData || rentalLocationData.length === 0) return;

    const mappingData = addonData.data.map((item) => ({
      ...item,
      color_variation: item.varieties,
      total_stock: item.varieties?.reduce((acc, cur) => acc + cur.stock, 0) || '-',
      location_additional_item: rentalLocationData.find((loc) => loc.id === item.warehouse_id)?.name || '-',
    }));

    setNewData(mappingData);
  }, [addonData, rentalLocationData]);

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  return (
    <div className="additional">
      <div className="additional__button">
        <Button width="192px" height="39px" className="button-add" onClick={addAdditionalHandler}>
          <PlusIcon />
          <span className="button-label">Tambah Additional</span>
        </Button>
      </div>

      <TableWrapper
        title="Additional"
        icon={<AdditionalFilledIcon fill="#009EF7" width="25px" height="25px" />}
        style={{ minHeight: '100vh' }}
      >
        {newData && newData.length !== 0 ? (
          <>
            <Table column={column} data={newData} actionBtn onDel={deleteAdditionalhandler} onEdit={editHandler} />
            <PaginationTable
              currentPage={currentPage}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              totalCount={addonData?.pagination?.total}
            />
          </>
        ) : (
          <EmptyState />
        )}
      </TableWrapper>
    </div>
  );
};

export default AdditionalComponent;
