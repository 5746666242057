import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as ChevronDown } from '../../../icons/chevron-down.svg';
import ClickOutside from '../ClickOutside';
import { ReactComponent as Checklist } from '../../../icons/check-icon.svg';
import { setCurrency } from 'utils/functionality';

const SelectFieldDropdown = ({
  label,
  name,
  htmlFor,
  value, // state
  onChange, // setState
  data = [],
  className,
  placeholder,
  disable,
  checkBox = false,
  onClick,
  icon,
  disableDropdown,
  ...rest
}) => {
  const fieldHeightRef = useRef(null);

  // SHOW DROPDOWN FUNCTION
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  // LOCAL STATE
  const [dataList, setDataList] = useState([]);
  const [fieldHeight, setFieldHeight] = useState(44);

  // FUNCTION TO HANDLE HIDE DROPDOWN
  const handleHide = () => {
    setTimeout(() => {
      setIsShowDropdown(false);
    }, 200);
  };

  // FUNCTION WHEN CHECK THE CHECKBOX
  const handleCheck = (e) => {
    e.stopPropagation();
    const choosedItem = dataList.find((item) => item.id == e.target.id);
    choosedItem.checked = e.target.checked;

    const newDataList = [...dataList];
    newDataList[dataList.indexOf(choosedItem)] = choosedItem;

    onChange(choosedItem.id, choosedItem.checked);
    setDataList(newDataList);
  };

  // MAPPING CHECKED PROP WHEN FIRST LOAD
  useEffect(() => {
    if (!checkBox) return;
    if (data.length === 0) return;

    if (value.length === 0) {
      const mappingCheckProp = data.map((item) => {
        return { ...item, checked: false };
      });
      setDataList(mappingCheckProp);
    } else if (value.length !== 0) {
      const mappingCheckProp = data.map((item) => {
        return { ...item, checked: value.find((id) => (id?.value || id) == item.value) ? true : false };
      });
      setDataList(mappingCheckProp);
    }
  }, [data, value]);

  // CATCH THE FIELD HEIGHT
  useEffect(() => {
    if (fieldHeightRef.current) {
      const height = fieldHeightRef.current.clientHeight;
      setFieldHeight(height);
    }
  }, []);

  return (
    <div className={clsx('select-field-dropdown', className)} onClick={onClick}>
      {label && (
        <label htmlFor={htmlFor} className="select-field-dropdown__label">
          {label}
        </label>
      )}
      <div
        className={
          disable
            ? 'select-field-dropdown__input-field disable'
            : htmlFor === 'assign-to-driver'
            ? 'select-field-dropdown__input-field driver'
            : 'select-field-dropdown__input-field'
        }
        style={{ width: '100%' }}
        ref={fieldHeightRef}
      >
        {icon && icon}
        {!checkBox && (
          <input
            id={htmlFor}
            type="text"
            placeholder={placeholder}
            name={name}
            disabled={disable}
            className="select-field-dropdown__input-field__input"
            value={
              value
                ? data?.find((item) => item.id == value)?.name
                  ? data?.find((item) => item.id == value)?.name
                  : data?.find((item) => item.id == value)?.method
                  ? data?.find((item) => item.id == value)?.method
                  : data?.find((item) => item.id == value)?.title
                  ? data?.find((item) => item.id == value)?.title
                  : !isNaN(data?.find((item) => item.id == value)?.reward)
                  ? setCurrency(+data?.find((item) => item.id == value)?.reward)
                  : placeholder
                : placeholder
            }
            onFocus={() => setIsShowDropdown(true)}
            onBlur={!checkBox ? handleHide : () => {}}
            readOnly
            {...rest}
          />
        )}
        {checkBox && (
          <div
            className="select-field-dropdown__input-field__list"
            tabIndex={0}
            onFocus={() => setIsShowDropdown(true)}
          >
            {value.length !== 0 && dataList.length !== 0 ? (
              value.map((li) => (
                <span key={li + Math.random()}>{dataList.find((item) => item.value == (li?.value || li))?.name}</span>
              ))
            ) : (
              <p>{placeholder}</p>
            )}
          </div>
        )}
        {disable ? null : (
          <ChevronDown
            className={
              !isShowDropdown
                ? 'select-field-dropdown__input-field__arrow'
                : 'select-field-dropdown__input-field__arrow down'
            }
            onFocus={onClick ? () => {} : () => setIsShowDropdown(true)}
            onBlur={handleHide}
            readOnly
            {...rest}
          />
        )}
      </div>

      {/* DROPDOWN */}
      {isShowDropdown && !checkBox && !disableDropdown && (
        <ul className="select-field-dropdown__dropdown">
          {data?.length !== 0 ? (
            data?.map((item) => (
              <li
                className="list"
                key={item.id}
                data-code={item.code ? item.code : null}
                id={item.id}
                onClick={onChange}
              >
                <span>
                  {item.name
                    ? item.name
                    : item.method
                    ? item.method
                    : item.title
                    ? item.title
                    : setCurrency(+item.reward)}
                </span>
              </li>
            ))
          ) : (
            <li>No data found</li>
          )}
        </ul>
      )}

      {/* DROPDOWN CHECKBOX */}
      {isShowDropdown && checkBox && !disableDropdown && (
        <ClickOutside onClickOutside={() => setIsShowDropdown(false)}>
          <ul className="select-field-dropdown__dropdown" style={{ top: `${fieldHeight + 40}px` }}>
            {dataList.length !== 0 ? (
              dataList.map((item) => (
                <li className="list-check" key={item.id} data-code={item.code ? item.code : null}>
                  <label htmlFor={item.id}>
                    <span>{item.name ? item.name : item.method ? item.method : item.title}</span>
                    <div className={item.checked ? 'check' : ''}>
                      {item.checked && <Checklist />}
                      <input
                        type="checkbox"
                        id={item.id}
                        value={item.id}
                        onChange={handleCheck}
                        checked={item.checked}
                      />
                    </div>
                  </label>
                </li>
              ))
            ) : (
              <li>No data found</li>
            )}
          </ul>
        </ClickOutside>
      )}
    </div>
  );
};

export default React.memo(SelectFieldDropdown);
