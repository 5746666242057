import { createSlice } from '@reduxjs/toolkit';
import { getTotalDepositWithoutDriver } from './actions';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const totalDepositWithoutDriver = createSlice({
  name: 'totalDepositWithoutDriver',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTotalDepositWithoutDriver.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getTotalDepositWithoutDriver.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getTotalDepositWithoutDriver.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.data = action.payload;
      });
  },
});

export default totalDepositWithoutDriver.reducer;
