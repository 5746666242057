import { createSlice } from '@reduxjs/toolkit';
import { getTrxMonthToMonth } from './actions';

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const trxMonthToMonth = createSlice({
  name: 'trxMonthToMonth',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrxMonthToMonth.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = [];
      })
      .addCase(getTrxMonthToMonth.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getTrxMonthToMonth.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.data = action.payload;
      });
  },
});

export default trxMonthToMonth.reducer;
