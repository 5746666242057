import { createSlice } from '@reduxjs/toolkit';
import { getAllZone, getAllZoneAdmin, getListZone, getZoneDetailById } from './actions';

const initialState = {
  data: [],
  dataAdmin: {},
  zoneList: {
    list_zones: [],
    pagination: {},
  },
  selectedZone: {},
  isLoading: false,
  isError: false,
  errorMessage: '',
};

export const zone = createSlice({
  name: 'zone',
  initialState,
  reducers: {
    resetZoneData: (state) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllZone.pending, (state) => {
        state.data = [];
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getAllZone.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllZone.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = '';
        state.data = action.payload;
      })
      .addCase(getAllZoneAdmin.pending, (state) => {
        state.dataAdmin = [];
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getAllZoneAdmin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllZoneAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = '';
        state.dataAdmin = action.payload;
      })
      .addCase(getZoneDetailById.pending, (state) => {
        state.selectedZone = {};
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getZoneDetailById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getZoneDetailById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = '';
        state.selectedZone = action.payload;
      })
      .addCase(getListZone.pending, (state) => {
        state.zoneList = {};
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(getListZone.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getListZone.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = {};
        state.zoneList = action.payload;
      });
  },
});

export const { resetZoneData } = zone.actions;
export default zone.reducer;
