import { useAppContext } from 'components/Context/AppContext';
import { Button, InputField } from 'components/Global';
import SelectFieldBanks from 'components/Global/SelectFieldBanks';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import UploadImageCustomOrder from 'components/Global/UploadImageCustomOrder';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getGMTOffsetString } from 'utils/functionality';
import timeZones from 'utils/time-zones.json';

// PAYMENT METHOD ARRAY
const paymentMethod = [
  {
    id: 1,
    method: 'Bank Transfer',
    description: 'Bank Manual Transfer',
    code: 'BCA',
  },
];

// REGEX VALIDATION
// const regexAccountBankNumber = /^(\d{3,12})$/g;

const MetodePembayaran = ({
  prevPage,
  nextPage,
  orderData,
  setOrderData,
  paymentImageLink,
  setPaymentImageLink,
  isOrderConfirmation,
}) => {
  // CONTEXT
  const { showToast, setShowSpinner } = useAppContext();

  // GLOBAL STATE
  const { data: dataBanks } = useSelector((state) => state.banks);
  const { status } = useSelector((state) => state.createDisbursements);

  // LOCAL STATE
  const [paymentBankName, setPaymentBankName] = useState('');

  // FUNCTION TO HANDLE UPLOAD IMAGE
  // eslint-disable-next-line no-unused-vars
  const handleImage = (imageFile, name, link) => {
    setPaymentImageLink(link);
    setOrderData({
      ...orderData,
      disbursement: { ...orderData.disbursement, disbursement_confirmation_image: imageFile.file },
    });
  };

  // SCROLL TO TOP WHEN COMPONENT IS LOAD
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // FUNCTION CLICK DROPDOWN BANKS
  useEffect(() => {
    if (paymentBankName === '') return;
    setOrderData({ ...orderData, disbursement: { ...orderData.disbursement, sender_bank_name: paymentBankName } });
  }, [paymentBankName]);

  // FUNCTION TO SUBMIT PAYMENT
  const submitHandler = (e) => {
    e.preventDefault();

    // some validation
    const validPaymentMethod = orderData.disbursement.payment_method_id !== 0;
    const discoveredBank = dataBanks?.data.find((item) => item.name === orderData.disbursement.sender_bank_name);
    const validPaymentBank = orderData.disbursement.sender_bank_name !== '' && discoveredBank;
    const validAccountNumber = orderData.disbursement.sender_bank_number != 0;
    const validImagePayment = orderData.disbursement.disbursement_confirmation_image !== '';

    if (!validPaymentMethod) {
      return showToast({ type: 'error', message: 'Pilih Metode Pembayaran' });
    } else if (!validPaymentBank) {
      return showToast({ type: 'error', message: 'Pilih Bank Pembayaran' });
    } else if (!validAccountNumber) {
      return showToast({ type: 'error', message: 'Nomor Rekening Tidak Valid' });
    } else if (!validImagePayment) {
      return showToast({ type: 'error', message: 'Upload Bukti Pembayaran' });
    }

    const locationTimeZone = timeZones.find(
      (item) => item.offsetString === getGMTOffsetString() && item.friendlyName.toLowerCase().includes('indonesia'),
    );
    setOrderData({
      ...orderData,
      disbursement: { ...orderData.disbursement, time_zone: locationTimeZone?.longTimezones[0] },
    });

    nextPage();
  };

  // SHOW LOADING SPINNER
  useEffect(() => {
    if (status === 'loading') {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [status]);

  return (
    <form className="metode-pembayaran">
      {/* METODE PEMBAYARAN FORM */}
      <div className="metode-pembayaran__form">
        <h2 className="metode-pembayaran__form__title">Detail Pembayaran</h2>
        <div className="metode-pembayaran__form__form">
          <SelectFieldDropdown
            data={paymentMethod}
            label="Metode Pembayaran"
            htmlFor="metode-pembayaran"
            placeholder="Pilih Metode Pembayaran"
            value={orderData.disbursement.payment_method_id}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                disbursement: { ...orderData.disbursement, payment_method_id: parseInt(e.target.id) },
              })
            }
            disable={isOrderConfirmation}
          />
          <SelectFieldBanks
            htmlFor="metode-pembayaran-bank"
            label="Nama Bank"
            placeholder="Cari Bank..."
            data={dataBanks.data}
            value={orderData.disbursement.sender_bank_name}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                disbursement: { ...orderData.disbursement, sender_bank_name: e.target.value },
              })
            }
            onClick={setPaymentBankName}
            disable={isOrderConfirmation}
          />
          <InputField
            label="Nama Pemilik rekening"
            htmlFor="nama-pemilik-rekening"
            placeholder="Tulis Nama "
            value={orderData.disbursement.sender_name}
            onChange={(e) =>
              setOrderData({ ...orderData, disbursement: { ...orderData.disbursement, sender_name: e.target.value } })
            }
            disable={isOrderConfirmation}
          />
          <InputField
            type="number"
            label="Nomor Rekening"
            htmlFor="detail-deposit-nomor-rekening"
            placeholder="Tulis Nomor Rekening"
            value={orderData.disbursement.sender_bank_number || ''}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                disbursement: { ...orderData.disbursement, sender_bank_number: e.target.value },
              })
            }
            disable={isOrderConfirmation}
          />
          {/* upload image */}
          <div>
            <div>
              <h3>Bukti Transfer</h3>
            </div>
            <UploadImageCustomOrder
              htmlFor="bukti-transfer"
              uploadAction={handleImage}
              base64={true}
              imageFile={paymentImageLink}
              disable={isOrderConfirmation}
            />
          </div>
        </div>
      </div>

      {/* ACTION BUTTON */}
      {!isOrderConfirmation && (
        <div className="metode-pembayaran__button-action">
          <Button
            type="button"
            width="165px"
            height="39px"
            variant="outline"
            size="md"
            className="button"
            onClick={() => prevPage()}
          >
            Kembali
          </Button>
          <Button type="button" width="165px" height="39px" size="md" className="button" onClick={submitHandler}>
            Lanjutkan
          </Button>
        </div>
      )}
    </form>
  );
};

export default MetodePembayaran;
