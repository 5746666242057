import { getAllPermissions } from 'features/permissions/actions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useMapAdminPermissions = (arrayOfMenu, arrayOfPermissions) => {
  const dispatch = useDispatch();
  const permissionData = useSelector((state) => state.permissions.data);
  const [newPermission, setNewPermission] = useState([]);
  const getPermissionName = (id) => permissionData?.find((item) => item.id === id);

  useEffect(() => {
    dispatch(getAllPermissions());
  }, []);

  useEffect(() => {
    if (!permissionData?.length || !arrayOfPermissions?.length) return;
    const matchPermissionAndMenu = arrayOfMenu.map((menu) => ({
      ...menu,
      sub_items: menu.sub_items.map((sub_item) =>
        arrayOfPermissions?.find(
          (item) => getPermissionName(item.permission_id)?.name.toLowerCase() === sub_item.name.toLowerCase(),
        )
          ? {
              ...sub_item,
              action: arrayOfPermissions?.find(
                (item) => getPermissionName(item.permission_id)?.name.toLowerCase() === sub_item.name.toLowerCase(),
              )?.action,
            }
          : { ...sub_item, action: { read: 0, create: 0, update: 0, delete: 0 } },
      ),
    }));
    setNewPermission(matchPermissionAndMenu);
  }, [permissionData, arrayOfMenu, arrayOfPermissions]);

  return newPermission.length === 0 ? arrayOfMenu : newPermission;
};
