import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

const InputPriceAirportPackage = ({
  label,
  placeholder,
  value, // state
  onChange, // setState
  className,
  type = 'text',
  style,
  icon,
  htmlFor,
  onIconClick,
  price,
  disable,
  onWheel = () => {},
  iconPosition,
  readOnly = false,
  ...restProps
}) => {
  const inputRef = useRef(null);

  const [inputValueTemp, setInputFieldTemp] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const changeInputHandler = (e) => {
    setInputFieldTemp(e.target.value);
    onChange(e.target.value);
  };

  // DROPDOWN SHOW FUNCTION
  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };

  // CLICK OUTSIDE
  useEffect(() => {
    // Add a click event listener to the document to handle clicks outside the dropdown.
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsEdit(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts.
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!isEdit) {
      setInputFieldTemp('');
    }
  }, [isEdit]);

  return (
    <div className={clsx('g-input', className)} style={style} ref={inputRef}>
      {label && (
        <label htmlFor={htmlFor} className="input-text-title" style={{ textAlign: 'left' }}>
          {label}
        </label>
      )}
      <section className="field-wrapper with-icon" style={{ margin: 0 }} onClick={toggleEdit}>
        {icon && iconPosition === 'start' ? <div className={`input-icon-left`}>{icon}</div> : null}
        {isEdit ? (
          <input
            className={clsx(
              'input-text-field',
              icon && iconPosition === 'end' && 'with-icon',
              icon && iconPosition === 'start' && 'with-icon-left',
              price && 'with-currency',
              disable && 'disable',
            )}
            type={type}
            id={htmlFor}
            placeholder={placeholder}
            value={inputValueTemp}
            onChange={changeInputHandler}
            onWheel={onWheel}
            readOnly={readOnly}
            disabled={disable}
            {...restProps}
          />
        ) : (
          <input
            className={clsx(
              'input-text-field',
              icon && iconPosition === 'end' && 'with-icon',
              icon && iconPosition === 'start' && 'with-icon-left',
              price && 'with-currency',
              disable && 'disable',
            )}
            type={type}
            id={htmlFor}
            placeholder={placeholder}
            value={value}
            readOnly={readOnly}
            disabled={disable}
            {...restProps}
          />
        )}
        {price ? (
          <div className={`input-price price`} onClick={onIconClick}>
            IDR
          </div>
        ) : null}
        {icon && iconPosition === 'end' ? (
          <div className={`input-icon password`} onClick={onIconClick}>
            {icon}
          </div>
        ) : null}
      </section>
    </div>
  );
};

export default InputPriceAirportPackage;
