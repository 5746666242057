import clsx from 'clsx';
import React from 'react';
import { useState } from 'react';
import { ReactComponent as Mappin } from '../../../icons/mappin-icon-blue.svg';
import { ReactComponent as YellowMappin } from '../../../icons/mappin-icon-yellow.svg';
import { ReactComponent as RedMappin } from '../../../icons/mappin-icon-red.svg';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
// import { getListZone } from 'features/zone/actions';
// import { useInView } from 'react-intersection-observer';

const SelectFieldShuttleZone = ({
  label,
  name,
  id,
  value, // state
  onChange, // setState
  className,
  placeholder,
  disable,
  // allZonesList,
}) => {
  // const dispatch = useDispatch();

  const dropdownRef = useRef(null);
  // const { ref, entry } = useInView();

  const { zoneList } = useSelector((state) => state.zone);

  const [isShowDropdown, setIsShowDropdown] = useState(false);
  // const [listOfZone, setListOfZone] = useState([]);
  // const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  // const [isLoadingIntersection, setIsLoadingIntersection] = useState(false);
  // const [isChoosingList, setIsChoosingList] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  // FUNCTION DROPDOWN
  const toggleDropdown = () => {
    setIsShowDropdown(!isShowDropdown);
  };

  // FUNCTION CHOOSING LIST
  const handleChoose = (zone) => {
    // // temporary value
    // const e = { target: { value: zone } };

    onChange(zone);
    setIsShowDropdown(false);
    // setIsChoosingList(true);
  };

  // CALL ZONE DATA AND ZONE LIST
  // useEffect(() => {
  //   if (Object.keys(zoneList).length === 0) {
  //     dispatch(getListZone({ page: 1 }));
  //   }
  // }, []);

  // useEffect(() => {
  // if (!allZonesList || (allZonesList && allZonesList.length === 0)) return;

  // const timeoutLoading = setTimeout(() => {
  //   setIsLoadingSearch(false);
  // }, 500);

  // return () => clearTimeout(timeoutLoading);
  // }, [zoneList]);

  // FUCNTION OUTSIDE CLICK
  useEffect(() => {
    // Add a click event listener to the document to handle clicks outside the dropdown.
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts.
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // SEARCH FUNCTION
  useEffect(() => {
    if (Object.keys(zoneList).length === 0 || zoneList.length === 0) return;

    if (!inputValue) {
      setFilteredData(zoneList.list_zones);
    } else {
      const zoneFilter = zoneList.list_zones.filter((zone) =>
        zone.name.toLowerCase().includes(inputValue.toLowerCase()),
      );
      if (zoneFilter) {
        setFilteredData(zoneFilter);
      } else {
        setFilteredData(zoneList.list_zones);
      }
    }
  }, [inputValue, zoneList]);

  // SEARCH FUNCTION
  // useEffect(() => {
  //   if (value === '') {
  //     dispatch(getListZone({ page: 1 }));
  //     setIsChoosingList(false);
  //     setIsLoadingSearch(true);
  //     return;
  //   }

  //   const timeout = setTimeout(() => {
  //     if (isChoosingList) {
  //       dispatch(getListZone({ page: 1 }));
  //     } else {
  //       dispatch(getListZone({ page: 1, name: value }));
  //       setIsLoadingSearch(true);
  //     }

  //     setIsChoosingList(false);

  //     if (!isChoosingList) {
  //       setIsShowDropdown(true);
  //     }
  //   }, 500);

  //   () => clearTimeout(timeout);
  // }, [value]);

  // INTERSECTION ACTION
  // useEffect(() => {
  //   if (entry) {
  //     if (entry.isIntersecting) {
  //       const currentPage = zoneList?.pagination.page;
  //       if (currentPage >= zoneList.pagination.last_page) return;
  //       setIsLoadingIntersection(true);

  //       setTimeout(() => {
  //         dispatch(getListZone({ page: currentPage + 1 }));
  //         setIsLoadingIntersection(false);
  //       }, 1000);
  //     }
  //   }
  // }, [entry]);

  return (
    <div className={clsx('select-field-shuttle-zone', className)} ref={dropdownRef}>
      {label && (
        <label htmlFor={id} className="select-field-shuttle-zone__label">
          {label}
        </label>
      )}
      <div
        className={
          disable ? 'select-field-shuttle-zone__input-field disable' : 'select-field-shuttle-zone__input-field'
        }
        onClick={toggleDropdown}
      >
        <Mappin className="select-field-shuttle-zone__input-field__icon" />
        <input
          className={`select-field-shuttle-zone__input-field__input ${disable ? 'disable' : ''}`}
          id={id}
          name={name}
          value={isShowDropdown ? inputValue : value}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeholder}
        />
      </div>

      {/* DROPDOWN */}
      {isShowDropdown && (
        <ul className="select-field-shuttle-zone__dropdown">
          {/* {listOfZone && listOfZone.length !== 0 && (
            <li className="select-field-shuttle-zone__dropdown__head-list">Alamat {label.split(' ')[1]}</li>
          )} */}

          {filteredData.length !== 0 && (
            <>
              <li className="select-field-shuttle-zone__dropdown__head-list">Zona Tersedia</li>
              {
                // listOfZone.length !== 0 &&
                // !isLoadingSearch &&
                filteredData.map((item) => (
                  <li
                    key={item.id}
                    id={item.id}
                    onClick={() => handleChoose(item)}
                    className="select-field-shuttle-zone__dropdown__content-list"
                    // ref={value === '' && idx === listOfZone.length - 1 ? ref : null}
                  >
                    <div>
                      {item.name_zone === 'Zona 0' ? (
                        <Mappin />
                      ) : item.name_zone === 'Zona 1' ? (
                        <YellowMappin />
                      ) : (
                        <RedMappin />
                      )}
                      <span className="select-field-shuttle-zone__dropdown__content-list__name">{item.name}</span>
                    </div>
                    <span
                      className="select-field-shuttle-zone__dropdown__content-list__zone"
                      // style={
                      //   zoneData.length && zoneData?.find((zone) => zone.id == item.zone_id).name === 'Zona 0'
                      //     ? { borderColor: '#1c3d5d', color: '#1c3d5d', backgroundColor: '#e3f1ff' }
                      //     : zoneData.length && zoneData?.find((zone) => zone.id == item.zone_id).name === 'Zona 1'
                      //     ? { borderColor: '#dd7f00', color: '#dd7f00', backgroundColor: '#ffefd9' }
                      //     : { borderColor: '#e5602f', color: '#e5602f', backgroundColor: '#ffebe3' }
                      // }
                    >
                      {`(${item.name_zone})`}
                    </span>
                  </li>
                ))
              }
            </>
          )}

          {!filteredData.length && <li className="select-field-shuttle-zone__dropdown__empty-list">No data found</li>}

          {/* {listOfZone && listOfZone.length === 0 && !isLoadingSearch && (
            <li className="select-field-shuttle-zone__dropdown__empty-list">No data found</li>
          )} */}

          {/* {!listOfZone && !isLoadingSearch && (
            <li className="select-field-shuttle-zone__dropdown__empty-list">No data found</li>
          )} */}

          {/* {isLoadingIntersection && !isLoadingSearch && !value && (
            <li className="select-field-shuttle-zone__dropdown__intersection">Loading ...</li>
          )} */}

          {/* {isLoadingSearch && value && !isLoadingIntersection && (
            <li className="select-field-shuttle-zone__dropdown__empty-list">Loading ...</li>
          )} */}
        </ul>
      )}
    </div>
  );
};

export default SelectFieldShuttleZone;
