import { createSlice } from '@reduxjs/toolkit';
import { getAllBanner } from './actions';

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  errorMessage: '',
};

export const banner = createSlice({
  name: 'banner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBanner.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getAllBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});

export default banner.reducer;
