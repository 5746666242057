import React, { useState } from 'react';
import BankTransferConfirmationModal from '../BankTransferConfirmationModal';
import TransactionDetailModal from './transaction-detail-modal';

const MainTransationDetailModal = () => {
  const [showBankTransferConfirmation, setShowBankTransferConfirmation] = useState(false);
  const [isPaymentValid, setIsPaymentValid] = useState(false);

  return (
    <>
      {showBankTransferConfirmation ? (
        <BankTransferConfirmationModal
          setShowBankTransferConfirmation={setShowBankTransferConfirmation}
          setIsPaymentValid={setIsPaymentValid}
          isPaymentValid={isPaymentValid}
        />
      ) : (
        <TransactionDetailModal
          isPaymentValid={isPaymentValid}
          setShowBankTransferConfirmation={setShowBankTransferConfirmation}
        />
      )}
    </>
  );
};

export default MainTransationDetailModal;
