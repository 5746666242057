import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const downloadOrderDetail = createAsyncThunk('download/orderDetail', async (transactionKey, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/orders/${transactionKey}/export?type=pdf`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.href = url;
    // a.download = `invoice-${orderKey}.pdf`;
    a.download = `${transactionKey}.pdf`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});
