import React from 'react';
import { ReactComponent as LeftArrow } from '../../../icons/left-arrow-progress-icon.svg';

const ProgressBar = ({ prevPage, page, pageData }) => {
  return (
    <div className="progress-bar">
      <LeftArrow className="progress-bar__left-arrow" onClick={() => prevPage()} />

      {/* PROGRESS */}
      <ul className="progress-bar__page-container">
        {pageData.map((item, ind) => (
          <li key={item.id} className={`progress-bar__page-container__page ${ind + 1 <= page ? 'active' : ''}`}>
            {ind !== 0 ? (
              <div className="bar">
                <div />
              </div>
            ) : null}
            <div className="content" style={{ padding: 0 }}>
              <span className="content__number">{ind + 1}</span>
              <span className="content__title">{item.title}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProgressBar;
