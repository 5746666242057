import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

// GET ALL CONTACT US
export const getAllContactUs = createAsyncThunk('contactUs/getAllContactUs', async (__, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios
      .get(`${BASE_URL}/${API_VERSION}/contact`, { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// GET CONTACT US DETAIL BY ID
export const getDetailContactUs = createAsyncThunk('detailContactUs/getDetailContactUs', async (id, thunkAPI) => {
  // const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/contact/${id}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// CREATE NEW CONTACT US
export const createNewContactUs = createAsyncThunk('contactUs/createNewContactUs', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/contact`,
      { ...payload },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    thunkAPI.dispatch(getAllContactUs());

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// DELETE CONTACT US
export const deleteConctactUs = createAsyncThunk('contactUs/deleteConctactUs', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${BASE_URL}/${API_VERSION}/contact/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    thunkAPI.dispatch(getAllContactUs());
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
