import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import Modals from '../Modals';
import { Button, InputField } from 'components/Global';
import { ReactComponent as AirportTransferCarFilledIcon } from 'icons/airport-transfer-car-filled-icon.svg';
import {
  createAirportLocation,
  getAllAirportLocation,
  getDetailAirportLocation,
  updateAirportLocation,
} from 'features/airport-location/actions';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';

const AddAirportLocationModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { setShowAddAirportLocation, showToast } = useAppContext();

  const { selectedLocation } = useSelector((state) => state.airportLocation);
  const { data: airportLocationTypeData } = useSelector((state) => state.airportLocationType);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);

  const [locationValue, setLocationValue] = useState('');
  const [locationTypeValue, setLocationTypeValue] = useState('');
  const [valueName, setValueName] = useState('');
  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');

  const changeInputHandler = (e) => {
    setValueName(e.target.value);
  };

  const backHandler = () => {
    setShowAddAirportLocation(false);
    const params = new URLSearchParams(searchParams);
    params.delete('id');
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const setStateHandler = (state) => {
    setShowAddAirportLocation(state);
    const params = new URLSearchParams(searchParams);
    params.delete('id');
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const sendPayloadData = async (action, payload, fulfilledMessage, errorMessage1) => {
    const filterAttribute = localStorage.getItem('airport-location');

    try {
      action === 'ADD'
        ? await dispatch(createAirportLocation(payload)).unwrap()
        : action === 'UPDATE'
        ? await dispatch(updateAirportLocation({ airportLocationId: id, payloadData: payload })).unwrap()
        : () => {};

      showToast({ type: 'success', message: fulfilledMessage });
      dispatch(
        getAllAirportLocation({
          limit: 10,
          page: JSON.parse(filterAttribute).page,
          sortBy: JSON.parse(filterAttribute).sortBy,
          sortOrder: JSON.parse(filterAttribute).sortOrder,
          search: JSON.parse(filterAttribute).search,
          filters: JSON.parse(filterAttribute).filters,
        }),
      );
      setShowAddAirportLocation(false);
      const params = new URLSearchParams(searchParams);
      params.delete('id');
      navigate(`${location.pathname}?${params.toString()}`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: `${errorMessage1}` });
    }
  };

  const handleSubmit = async () => {
    const validLocation = locationValue !== '';
    const validLocationType = locationTypeValue !== '';
    const validValue = valueName.replace(' ').length !== 0;

    if (!validLocation) {
      return showToast({ type: 'error', message: 'Pilih Lokasi' });
    }
    if (!validLocationType) {
      return showToast({ type: 'error', message: 'Pilih Tipe Daerah' });
    }
    if (!validValue) {
      return showToast({ type: 'error', message: 'Masukan Nama Daerah' });
    }

    const payload = {
      name: valueName,
      location_id: +locationValue,
      airport_location_type_id: +locationTypeValue,
    };

    if (id) {
      sendPayloadData('UPDATE', payload, 'Berhasil Update Tipe Daerah', 'Gagal Update Tipe Daerah');
    } else {
      sendPayloadData('ADD', payload, 'Berhasil Tambah Tipe Daerah', 'Gagal Tambah Tipe Daerah');
    }
  };

  useEffect(() => {
    if (!id) return;

    dispatch(getDetailAirportLocation(id));
  }, [id]);

  useEffect(() => {
    if (!selectedLocation || !Object.keys(selectedLocation).length || !id) return;

    setLocationValue(selectedLocation.location_id);
    setLocationTypeValue(selectedLocation.airport_location_type_id);
    setValueName(selectedLocation.name);
  }, [selectedLocation, id]);

  return (
    <Modals
      setState={setStateHandler}
      title="Tambah Master Daerah"
      icon={<AirportTransferCarFilledIcon fill="#009EF7" width="25px" height="25px" />}
    >
      <div className="airport-location-modal">
        <SelectFieldDropdown
          data={rentalLocationData}
          label="Lokasi"
          htmlFor="lokasi"
          placeholder="Pilih Lokasi"
          value={locationValue}
          onChange={(e) => {
            setLocationValue(e.target.id);
          }}
        />

        <SelectFieldDropdown
          label="Tipe Daerah"
          htmlFor="tipe-daerah"
          placeholder="Pilih Tipe Daerah"
          data={airportLocationTypeData.data || []}
          value={locationTypeValue}
          onChange={(e) => {
            setLocationTypeValue(e.target.id);
          }}
        />

        <InputField
          label="Nama Daerah"
          htmlFor="nama-daerah"
          placeholder="Ketik Nama Daerah"
          value={valueName}
          onChange={changeInputHandler}
        />

        <div className="airport-location-modal__button-field">
          <Button size="sm" variant="outline" width="192px" onClick={backHandler}>
            Kembali
          </Button>
          <Button type="button" size="sm" width="192px" onClick={handleSubmit}>
            Simpan
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default AddAirportLocationModal;
