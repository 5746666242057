import { createSlice } from '@reduxjs/toolkit';
import { postDisbursements } from './actions';

const initialState = {
  data: {},
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const createDisbursements = createSlice({
  name: 'createOrder',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(postDisbursements.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postDisbursements.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(postDisbursements.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      });
  },
});

export default createDisbursements.reducer;
