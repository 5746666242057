import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

const SelectFieldBanks = ({
  label,
  name,
  htmlFor,
  value, // state
  onChange, // setState
  onClick, // when choose dropdown
  data = [],
  className,
  style,
  placeholder,
  disable,
  ...rest
}) => {
  // SHOW DROPDOWN FUNCTION
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  // FILTER BANKS STATE
  const [filteredBanks, setFilteredBanks] = useState([]);

  // BLUR HANDLER FUNCTION
  const handleBlur = () => {
    setTimeout(() => {
      setIsShowDropdown(false);
    }, 200);
  };

  // SET FILTERED BANKS WITH DATA WHEN FIRST LOAD
  useEffect(() => {
    if (!data || data.length === 0) return;
    setFilteredBanks(data);
  }, [data]);

  // FILTER BANKS
  useEffect(() => {
    let filterBanks;

    if (value === '') {
      filterBanks = data;
      setFilteredBanks(filterBanks);
      return;
    }

    filterBanks = data.filter((item) => item.name.includes(value.toUpperCase()));
    setFilteredBanks(filterBanks);
  }, [value]);

  return (
    <div className={clsx('select-field-banks', className)}>
      {label && (
        <label htmlFor={htmlFor} className="select-field-banks__label">
          {label}
        </label>
      )}
      <input
        id={htmlFor}
        type="text"
        placeholder={placeholder}
        name={name}
        style={style}
        className={disable ? 'select-field-banks__input disable' : 'select-field-banks__input'}
        value={value}
        disabled={disable}
        onChange={onChange}
        onFocus={() => setIsShowDropdown(true)}
        onBlur={handleBlur}
        {...rest}
      />
      {isShowDropdown && (
        <ul className="select-field-banks__dropdown">
          {data && data.length !== 0 ? (
            filteredBanks.map((item) => (
              <li
                key={item.id}
                data-code={item.code}
                onClick={() => {
                  onClick(item.name);
                  setIsShowDropdown(false);
                }}
              >
                {/* <div>
                  <img src={item.icon} alt={item.name} />
                </div> */}
                <span>{item.name}</span>
              </li>
            ))
          ) : (
            <li>No banks found</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default React.memo(SelectFieldBanks);
