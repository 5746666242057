import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllBanner = createAsyncThunk('banner/getAllBanner', async (_, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/banner`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

export const createBanner = createAsyncThunk('banner/createBanner', async ({ data, accessToken }, thunkAPI) => {
  const { sort, ...rest } = data;

  const payload = {
    ...rest,
    sort: sort > 0 ? sort : undefined,
  };

  try {
    await axios.post(
      `${BASE_URL}/${API_VERSION}/banner`,
      { ...payload },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );
    thunkAPI.dispatch(getAllBanner());
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const uploadBannerImage = createAsyncThunk('banner/uploadBanner', async ({ payload, data }, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  try {
    const response = await axios.post(`${BASE_URL}/${API_VERSION}/banner/upload`, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    const uploadedImageString = response.data?.file;
    if (!uploadedImageString) return thunkAPI.rejectWithValue('Gagal Upload Image');

    thunkAPI.dispatch(createBanner({ data: { ...data, image: uploadedImageString }, accessToken }));
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const deleteBanner = createAsyncThunk('banner/deleteBanner', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    await axios.delete(`${BASE_URL}/${API_VERSION}/banner/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});
