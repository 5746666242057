import React, { useState } from 'react';
import Modals from '../Modals';
import { Button, InputField } from 'components/Global';
import { useAppContext } from 'components/Context/AppContext';

const AddViolationModal = ({ setShowAddViolationModal, setViolationList, onConfirm }) => {
  const { showToast } = useAppContext();
  const [data, setData] = useState({
    violation: '',
    cost: '',
  });

  const confirmationHandler = () => {
    if (data.violation.trim() === '' || (typeof data.cost === 'string' && data.cost.trim() === '') || data.cost === 0) {
      showToast({ type: 'warning', message: 'Harap isi semua data' });
      return;
    }
    if (onConfirm) {
      onConfirm({ violations: [{ violation: data.violation, cost: data.cost }] });
      setShowAddViolationModal(false);
      return;
    }
    setViolationList((prev) => [...prev, { ...data, id: prev.length + 1 }]);
    setShowAddViolationModal(false);
  };

  return (
    <Modals setState={setShowAddViolationModal}>
      <div className="modal">
        <div className="add-violation">
          <header className="add-violation__header">
            <h1>Tambah Denda</h1>
          </header>
          <div className="add-violation__fine-detail">
            <InputField
              value={data.violation}
              label="Keterangan Denda"
              placeholder="Masukan keterangan denda"
              className="add-violation__fine-name"
              onChange={(e) => setData((prev) => ({ ...prev, violation: e.target.value }))}
            />
            <InputField
              value={data.cost}
              label="Jumlah Denda"
              placeholder="Masukan jumlah denda"
              price
              type="number"
              min={0}
              onChange={(e) => setData((prev) => ({ ...prev, cost: parseInt(e.target.value) }))}
            />
          </div>
          <div>
            <Button wfull height={50} onClick={confirmationHandler}>
              Konfirmasi
            </Button>
          </div>
        </div>
      </div>
    </Modals>
  );
};

export default AddViolationModal;
