import React, { useEffect, useState } from 'react';
import { PaginationTable, Table, TableWrapper } from 'components/Global';

import { ReactComponent as OvertimeIcon } from 'icons/overtime-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOvertimeRules, getAllOvertimeRules } from 'features/overtime/actions';
import { useAppContext } from 'components/Context/AppContext';
import { useNavigate } from 'react-router-dom';
import EmptyState from 'components/Global/EmptyState';
import ButtonAdd from 'components/Global/ButtonAdd';

const OvertimeRulesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setShowConfirmation, showToast, setShowSpinner } = useAppContext();

  const { overtimeRules, status } = useSelector((state) => state.overtime);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getAllOvertimeRules(currentPage));
  }, [currentPage]);

  const handleDelete = (e) => {
    const overtimeRuleId = e.target.parentElement.parentElement.dataset['key'];

    setShowConfirmation({
      message: 'Apakah anda yakin ingin menghapus aturan overtime?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deleteOvertimeRules(overtimeRuleId)).unwrap();

          showToast({ type: 'success', message: 'Berhasil Menghapus Aturan Overtime' });
          dispatch(getAllOvertimeRules(currentPage));
          return;
        } catch (error) {
          showToast({ type: 'error', message: 'Gagal Menghapus Aturan Overtime' });
        }
      },
    });
  };

  const handleEdit = (e) => {
    const overtimeRulesId = e.target.parentElement.parentElement.dataset['key'];

    navigate(`/overtime/edit-rules?id=${overtimeRulesId}`);
  };

  const formattedOvertimeRules = overtimeRules.data
    ? overtimeRules.data.map((item) => {
        const transformMatrix = item.overtime_rule_matrixes
          ? item.overtime_rule_matrixes.map((item) => item.name)
          : ['-'];

        return {
          ...item,
          overtime_limit: `${getSymbolOperator(item.limit_operator)} ${item.limit_value} ${
            item.limit_type === 'hours' ? ' Jam' : ' Menit'
          } `,
          overtime_charge: `${
            item.price_type === 'percentage'
              ? item.price_value + '%'
              : item.price_value !== 0
              ? 'Rp' + item.price_value
              : item.price_value
          }`,
          matrix: transformMatrix.length > 0 ? transformMatrix.join(', ') : transformMatrix,
        };
      })
    : [];

  useEffect(() => {
    if (status === 'loading') {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [status]);

  return (
    <div className="overtime">
      <div className="overtime__button-wrapper">
        <ButtonAdd onClick={() => navigate('/overtime-rule/create-rules')} label="Aturan Overtime" />
      </div>
      {!overtimeRules.data || overtimeRules.data.length === 0 ? (
        <EmptyState />
      ) : (
        <TableWrapper icon={<OvertimeIcon fill="#009EF7" width="25px" height="25px" />} title="Aturen Overtime">
          <Table
            column={rulesColumn}
            actionBtn
            onEdit={handleEdit}
            onDel={handleDelete}
            data={formattedOvertimeRules}
            currentPage={currentPage}
          />
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={Object.keys(overtimeRules).length === 0 ? 0 : overtimeRules.pagination.total}
          />
        </TableWrapper>
      )}
    </div>
  );
};

export default OvertimeRulesList;

const rulesColumn = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Aturan Overtime', value: 'name' },
  { header: 'Batas Overtime', value: 'overtime_limit' },
  { header: 'Denda Overtime', value: 'overtime_charge' },
  { header: 'Matrix', value: 'matrix' },
];

const getSymbolOperator = (operator) => {
  if (operator === 'lt') return '<';
  if (operator === 'lte') return '<=';
  if (operator === 'eq') return '=';
  if (operator === 'gt') return '>';
  if (operator === 'gte') return '>=';
};
