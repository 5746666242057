import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

// GET ALL RENTAL LOCATION
export const getAllRentalLocation = createAsyncThunk('rentalLocation/getAllRentalLocation', async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/location`).then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

// GET ALL RENTAL LOCATION BY SERVICES
export const getRentalLocationByServices = createAsyncThunk(
  'rentalLocation/getRentalLocationByServices',
  async (payload, thunkAPI) => {
    const { service_id, sub_service_id, facility_id } = payload;

    let newUrl = `${BASE_URL}/${API_VERSION}/location`;

    if (service_id) {
      newUrl += `?service_id=${service_id}`;
    }

    if (sub_service_id) {
      newUrl += `&sub_service_id=${sub_service_id}`;
    }

    if (facility_id) {
      newUrl += `&facility_id=${facility_id}`;
    }

    try {
      const response = await axios.get(newUrl);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data.message);
    }
  },
);

// CREATE NEW RENTAL LOCATION
export const createRentalLocation = createAsyncThunk(
  'rentalLocation/createRentalLocation',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/${API_VERSION}/location`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      thunkAPI.dispatch(getAllRentalLocation());

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// DELETE RENTAL LOCATION
export const deleteRentalLocation = createAsyncThunk('rentalLocation/deleteRentalLocation', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${BASE_URL}/${API_VERSION}/location/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    thunkAPI.dispatch(getAllRentalLocation());

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// GET RENTAL LOCATION BY ID
export const getRentalLocationById = createAsyncThunk('rentalLocation/getRentalLocationById', async (id, thunkAPI) => {
  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/location/${id}`).then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

// UPDATE RENTAL LOCATION
export const updateRentalLocation = createAsyncThunk(
  'rentalLocation/updateRentalLocation',
  async ({ id, payload }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.put(
        `${BASE_URL}/${API_VERSION}/location/${id}`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      thunkAPI.dispatch(getAllRentalLocation());

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
