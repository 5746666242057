import LoginComponent from 'components/LoginComponent';
import React from 'react';

const LoginPage = () => {
  return (
    <div className="login-page">
      <div className="container">
        <LoginComponent />
      </div>
    </div>
  );
};

export default LoginPage;
