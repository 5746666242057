import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

// GET ALL ZONE BY LOCATION ID
export const getAllZone = createAsyncThunk('zone/getAllZone', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  const { locationId, categoryId } = payload;

  let mainUrl = `${BASE_URL}/${API_VERSION}/zone`;

  if (locationId) {
    mainUrl += `?location_id=${locationId}`;
  }

  if (categoryId) {
    mainUrl += `&category_id=${categoryId}`;
  }

  try {
    const response = await axios.get(mainUrl, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

// GET ALL ZONE
export const getAllZoneAdmin = createAsyncThunk('zone/getAllZoneAdmin', async (page, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/zone?limit=10&page=${page}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

// GET ALL ZONE DETAIL BY ID
export const getZoneDetailById = createAsyncThunk('zone/getZoneDetailById', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/zone/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});

// GET ALL LIST ZONE
export const getListZone = createAsyncThunk('zone/getListZone', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  const { page, name, all, locationId, categoryId } = payload;

  let mainUrl = `${BASE_URL}/${API_VERSION}/list-zone?limit=10`;

  if (page) {
    mainUrl += `&page=${page}`;
  }

  if (name) {
    mainUrl += `&name=${name}`;
  }

  if (all) {
    mainUrl += `&all=${all}`;
  }

  if (locationId) {
    mainUrl += `&location_id=${locationId}`;
  }

  if (categoryId) {
    mainUrl += `&category_id=${categoryId}`;
  }

  try {
    const response = await axios.get(mainUrl, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue('Failed to get zone list');
  }
});

// ADD NEW ZONE
export const createNewZone = createAsyncThunk('zone/createNewZone', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/zone`,
      { ...payload },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to create new zone');
  }
});

// EDIT ZONE BY ID
export const editZoneById = createAsyncThunk('zone/editZoneById', async ({ payload, id }, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.put(
      `${BASE_URL}/${API_VERSION}/zone/${id}`,
      { ...payload },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to create new zone');
  }
});

// DELETE ZONE BY ID
export const deleteZoneById = createAsyncThunk('zone/deleteZoneById', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${BASE_URL}/${API_VERSION}/zone/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to create new zone');
  }
});

// ADD NEW LIST ZONE
export const addListZones = createAsyncThunk('zone/addListZones', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/list-zone`,
      { ...payload },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to create new zone');
  }
});

// EDIT ZONE BY ID
export const editListZoneById = createAsyncThunk('zone/editListZoneById', async ({ payload, id }, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.put(
      `${BASE_URL}/${API_VERSION}/list-zone/${id}`,
      { ...payload },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to create new zone');
  }
});

// DELETE LIST ZONE BY ID
export const deleteListZoneById = createAsyncThunk('zone/deleteListZoneById', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${BASE_URL}/${API_VERSION}/list-zone/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to create new zone');
  }
});

// UPLOAD ZONE IMAGE
export const uploadZoneImage = createAsyncThunk('zone/uploadZoneImage', async (image, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/zone-image/upload`,
      { file: image },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.slug);
  }
});

// CREATE ZONE IMAGE
export const createZoneImage = createAsyncThunk('zone/createZoneImage', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/zone-image`,
      { ...payload },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to create zone image');
  }
});
