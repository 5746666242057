import { Button, InputField, RadioButton, TableWrapper, UploadImage } from 'components/Global';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import React, { useEffect, useState } from 'react';
import { ReactComponent as PeopleIcon } from 'icons/people-filled-icon.svg';

const LAYANAN_OPTIONS = [
  { id: 1, label: 'With Driver' },
  { id: 2, label: 'With Driver + Tour Guide' },
];

const DUMMY_IMAGE5 = 'assets/image/thumb5.png';
const DUMMY_IMAGE6 = 'assets/image/thumb6.png';
const DUMMY_IMAGE7 = 'assets/image/thumb7.png';
const DUMMY_IMAGE8 = 'assets/image/thumb8.png';

const IMAGE_FILES = [
  {
    id: 1,
    filename: 'foto-1.jpg',
    image: DUMMY_IMAGE5,
  },
  {
    id: 2,
    filename: 'foto-2.jpg',
    image: DUMMY_IMAGE6,
  },
  {
    id: 3,
    filename: 'foto-3.jpg',
    image: DUMMY_IMAGE7,
  },
  {
    id: 4,
    filename: 'foto-4.jpg',
    image: DUMMY_IMAGE8,
  },
];

const TourDetailComponent = ({ setIsDetail }) => {
  const [tourData, setTourData] = useState({
    jenis: '',
    nama: 'Tour A',
    detail: 'Jumlah Hari :  3 Hari',
    destinasi: 'Hari Ke-1 Tempat A - 09:00 AM - 12 : 00 AM',
    jumlah_hari: 3,
  });
  const [layanan, setLayanan] = useState(2);

  useEffect(() => {
    setTourData((prev) => ({ ...prev, jenis: parseInt(layanan) }));
  }, [layanan]);

  return (
    <div className="tour-detail__component">
      <header className="tour-detail__header" onClick={() => setIsDetail(false)}>
        <LeftArrow />
        <p>Kembali</p>
      </header>
      <div className="tour-detail__content">
        <div className="content--left">
          <InputField
            label="Nama Tour"
            value={tourData.nama}
            onChange={(e) => setTourData({ ...tourData, nama: e.target.value })}
          />
          <div className="content--left__layanan">
            <h3>Layanan</h3>
            <div className="input-group">
              <RadioButton data={LAYANAN_OPTIONS} name="layanan" setState={setLayanan} state={layanan} />
            </div>
          </div>
          <div className="textarea-input">
            <h3>Detail</h3>
            <textarea
              placeholder="Tulis Detail"
              value={tourData.detail}
              onChange={(e) => setTourData((prev) => ({ ...prev, detail: e.target.value }))}
            ></textarea>
          </div>
          <div className="textarea-input">
            <h3>Destinasi</h3>
            <textarea
              placeholder="Tulis Destinasi"
              value={tourData.destinasi}
              onChange={(e) => setTourData((prev) => ({ ...prev, destinasi: e.target.value }))}
            ></textarea>
          </div>
          <InputField
            label="Jumlah Hari"
            className="jumlah-input"
            type="number"
            placeholder="Tulis Jumlah Hari"
            value={tourData.jumlah_hari}
            onChange={(e) => setTourData((prev) => ({ ...prev, jumlah_hari: parseInt(e.target.value) }))}
            min={0}
          />
        </div>
        <div className="content--right">
          <TableWrapper icon={<PeopleIcon fill="#FF9900" width="25px" height="25px" />} title="Tour Plan Photo">
            <p>Foto Destinasi 1</p>
            <UploadImage imageFiles={IMAGE_FILES} previewable multiple />
          </TableWrapper>
        </div>
      </div>
      <div className="tour-detail__button">
        <Button size="sm" className="button" width="267px">
          Simpan
        </Button>
      </div>
    </div>
  );
};

export default TourDetailComponent;
