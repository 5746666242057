import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { deleteAirportLocationtype, getAllAirportLocationType } from 'features/airport-location-type/actions';
import { PaginationTable, Table, TableWrapper } from 'components/Global';
import EmptyState from 'components/Global/EmptyState';
import { ReactComponent as AirportTransferCarFilledIcon } from 'icons/airport-transfer-car-filled-icon.svg';
import { checkPermission } from 'utils/functionality';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Tipe Daerah', value: 'name' },
];

const TipeDaerah = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setShowSpinner, setShowAddAirportLocationType, showToast, setShowConfirmation } = useAppContext();

  const { data: airportLocationTypeData, isLoading } = useSelector((state) => state.airportLocationType);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams] = useSearchParams();

  const editHandler = (e) => {
    const tipeDaerahId = e.target.parentElement.parentElement.dataset['key'];
    navigate(`/airport-transfer-car?tab-active=Tipe Daerah&id=${tipeDaerahId}`);
  };

  const deleteHandler = (e) => {
    const tipeDaerahId = e.target.parentElement.parentElement.dataset['key'];

    setShowConfirmation({
      message: 'Apakah anda yakin ingin hapus Tipe Daerah',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deleteAirportLocationtype(tipeDaerahId)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Hapus Tipe Daerah' });
          dispatch(getAllAirportLocationType({ limit: 10, page: currentPage }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          showToast({ type: 'error', message: 'Gagal Hapus Tipe Daerah' });
        }
      },
    });
  };

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      setShowAddAirportLocationType(true);
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(getAllAirportLocationType({ page: currentPage, limit: 10 }));
  }, [currentPage]);

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  return (
    <TableWrapper
      icon={<AirportTransferCarFilledIcon fill="#009EF7" width="25px" height="25px" />}
      title="Airport Transfer Car"
      style={{ minHeight: '603px' }}
    >
      {(!airportLocationTypeData ||
        !Object.keys(airportLocationTypeData).length ||
        !airportLocationTypeData.data.length) &&
      !isLoading ? (
        <EmptyState />
      ) : (
        <>
          <Table
            column={column}
            data={airportLocationTypeData?.data}
            actionBtn
            onEdit={checkPermission(offCanvasMenu, currentMenu, 'update') ? editHandler : false}
            onDel={deleteHandler}
            currentPage={currentPage}
          />
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={airportLocationTypeData?.pagination?.total_data}
          />
        </>
      )}
    </TableWrapper>
  );
};

export default TipeDaerah;
