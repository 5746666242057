import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllAirportLocationType = createAsyncThunk(
  'airportLocationType/getAllAirportLocationType',
  async ({ limit = 10, page = 1 }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.get(
        `${BASE_URL}/${API_VERSION}/airport-location-types?page=${page}&limit=${limit}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getDetailAirportLocationType = createAsyncThunk(
  'airportLocationType/getDetailAirportLocationType',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.get(`${BASE_URL}/${API_VERSION}/airport-location-types/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const createAirportLocationType = createAsyncThunk(
  'airportLocationType/createAirportLocationType',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/${API_VERSION}/airport-location-types`,
        { ...payload },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const data = await response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const updateAirportLocationType = createAsyncThunk(
  'airportLocationType/updateAirportLocationType',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { payloadData, airportLocationTypeId } = payload;

    try {
      const response = await axios.put(
        `${BASE_URL}/${API_VERSION}/airport-location-types/${airportLocationTypeId}`,
        { ...payloadData },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const data = await response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const deleteAirportLocationtype = createAsyncThunk(
  'airportLocationType/deleteAirportLocationtype',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.delete(`${BASE_URL}/${API_VERSION}/airport-location-types/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
