import { createSlice } from '@reduxjs/toolkit';
import { getAllPermissions, getRolePermissionsById } from './actions';

const initialState = {
  data: [],
  selectedPermission: [],
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const permissions = createSlice({
  initialState,
  name: 'permissions',
  reducers: {
    resetSelectedPermission: (state) => {
      return {
        ...state,
        selectedPermission: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPermissions.pending, (state) => {
        state.data = [];
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(getAllPermissions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllPermissions.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(getRolePermissionsById.pending, (state) => {
        state.selectedPermission = [];
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(getRolePermissionsById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getRolePermissionsById.fulfilled, (state, action) => {
        state.selectedPermission = action.payload;
        state.isLoading = false;
      });
  },
});

export const { resetSelectedPermission } = permissions.actions;

export default permissions.reducer;
