import { createSlice } from '@reduxjs/toolkit';
import { getAllShuttleAirport, getShuttleAirportZone, getShuttleTheAirport } from './actions';

const initialState = {
  data: {},
  selectedShuttle: {},
  dataZone: [],
  dataAirport: [],
  isLoading: true,
  isError: false,
  errorMessage: {}, // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const shuttleAirport = createSlice({
  name: 'shuttleAirport',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllShuttleAirport.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllShuttleAirport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllShuttleAirport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getShuttleAirportZone.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.dataZone = {};
      })
      .addCase(getShuttleAirportZone.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getShuttleAirportZone.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dataZone = action.payload;
      })
      .addCase(getShuttleTheAirport.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.dataAirport = {};
      })
      .addCase(getShuttleTheAirport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getShuttleTheAirport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dataAirport = action.payload;
      });
  },
});

export default shuttleAirport.reducer;
