import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      min: 0,
      max: 10000000,
      border: {
        color: '#000000',
      },
    },
    x: {
      border: {
        color: '#000000',
      },
    },
  },
};

const labels = ['Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu', 'Minggu'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Penghasilan',
      tension: 0.2,
      data: [4000000, 6000000, 1000000, 4000000, 2000000, 5000000, 8000000],
      borderColor: '#299B0A',
      backgroundColor: '#299B0A',
    },
  ],
};

const ChartComponent = () => {
  return <Line options={options} data={data} height={293} />;
};

export default ChartComponent;
