import React from 'react';
import { ReactComponent as AddIcon } from 'icons/plus-filled-icon.svg';
import { ReactComponent as DeleteIcon } from 'icons/x-icon.svg';
import clsx from 'clsx';
import { setCurrency } from 'utils/functionality';
import { useState } from 'react';
// import { useAppContext } from 'components/Context/AppContext';
import ReactDOM from 'react-dom';
import AddAdditionalModal from 'components/Modals/AddAdditional';

const InputAdditionals = ({
  isOnEdit = true,
  setAdditionalList,
  additionalList,
  totalPrice,
  additionalPrice,
  onConfirm,
  onDelete,
  data,
}) => {
  const [showAddAdditionalModal, setShowAddAdditionalModal] = useState(false);

  const deleteHandler = (id) => {
    if (onDelete) {
      onDelete(id);
      return;
    }
    setAdditionalList((prev) => prev.filter((item) => item.id !== id));
  };

  const addHandler = () => {
    setShowAddAdditionalModal(true);
  };

  return (
    <>
      <div className="input-violation">
        <div className="input-violation__header">
          <label className="input-violation__header-title">Biaya Tambahan</label>
          {isOnEdit ? (
            <div className="input-violation__header-add" onClick={addHandler}>
              <AddIcon className="input-violation__add-icon" />
              <p>Tambah Biaya</p>
            </div>
          ) : null}
        </div>
        <div className="input-violation__body">
          {data?.length > 0 || additionalList.length > 0 ? (
            <>
              {data?.map((item, idx) => (
                <div key={idx} className={clsx('input-violation__body-item', isOnEdit && 'input-violation__edit')}>
                  <p>{item.name}</p>
                  <div className={'input-violation__item-price'}>
                    <p>{setCurrency(item.value)}</p>
                    {isOnEdit ? (
                      <DeleteIcon className="input-violation__delete-icon" onClick={() => deleteHandler(item.id)} />
                    ) : null}
                  </div>
                </div>
              ))}
              {additionalList.map((item, idx) => (
                <div key={idx} className={clsx('input-violation__body-item', isOnEdit && 'input-violation__edit')}>
                  <p>{item.name}</p>
                  <div className={'input-violation__item-price'}>
                    <p>{setCurrency(item.value)}</p>
                    {isOnEdit ? (
                      <DeleteIcon className="input-violation__delete-icon" onClick={() => deleteHandler(item.id)} />
                    ) : null}
                  </div>
                </div>
              ))}
              <div className="input-violation__body-item-total">
                <p>Total</p>
                <div className="input-violation__item-price">
                  <p>{setCurrency(totalPrice + additionalPrice)}</p>
                </div>
              </div>
            </>
          ) : (
            <div className={clsx('input-violation__body-item input-violation__item-empty')}>
              <p>Tidak ada biaya tambahan</p>
            </div>
          )}
        </div>
      </div>
      {showAddAdditionalModal &&
        ReactDOM.createPortal(
          <AddAdditionalModal
            setShowAddAdditionalModal={setShowAddAdditionalModal}
            setAdditionalList={setAdditionalList}
            onConfirm={onConfirm}
          />,
          document.getElementById('modal'),
        )}
    </>
  );
};

export default InputAdditionals;
