import { createSlice } from '@reduxjs/toolkit';
import { getAllAboutUsImage } from './actions';

const initialState = {
  data: [],
  // uploadedImage: {},
  isLoading: false,
  isError: false,
  errorMessage: '', // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const aboutUs = createSlice({
  name: 'aboutUs',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAboutUsImage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getAllAboutUsImage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllAboutUsImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});

// export const {  } = aboutUs.actions;

export default aboutUs.reducer;
