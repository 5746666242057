/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
// import { useAppContext } from 'components/Context/AppContext';
import { useState } from 'react';
import clsx from 'clsx';
import EmptyState from 'components/Global/EmptyState';
import { Button, PaginationTable } from 'components/Global';
import { ReactComponent as DepositFilledIcon } from 'icons/deposit-filled-icon.svg';
import { ReactComponent as DownloadIcon } from 'icons/download-excel.svg';
import { ReactComponent as UploadIcon } from 'icons/upload-excel.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getDepositOrder } from 'features/orders/depositSlice';
import FilterTransaction from 'components/Global/TransactionFilter';
import { depositColumn } from 'utils/order/order-column';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
  fetchDepositDetail,
  fetchDepositOrder,
  fetchOrderById,
  getDepositReports,
  importDepositReports,
} from 'features/orders/actions';
import StatusButton from 'components/Global/Table/StatusButton';
import ActionButtons from 'components/Global/Table/ActionButtons';
import { setCurrency } from 'utils/functionality';
import { useAppContext } from 'components/Context/AppContext';

const FILTER_DATA = [
  {
    id: 'CREATED',
    name: 'Baru',
    value: false,
  },
  {
    id: 'PROCESSED',
    name: 'Diproses',
    value: false,
  },
  {
    id: 'ON_DEBT',
    name: 'Hutang',
    value: false,
  },
  {
    id: 'TRANSFERED',
    name: 'Selesai',
    value: false,
  },
];

const DepositComponent = () => {
  const { showToast, setShowConfirmation } = useAppContext();
  const targetRef = useRef(null);
  const [filterList, setFilterList] = useState(FILTER_DATA);
  const [currentPage, setCurrentPage] = useState(1);
  const [file, setFile] = useState(null);
  const depositOrder = useSelector(getDepositOrder);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pageNumber = (page, idx) => (page > 1 ? (page - 1) * 10 + idx : idx);
  const [searchParams] = useSearchParams();
  const filteredStatus = searchParams.get('status');

  useEffect(() => {
    if (searchParams.has('status')) {
      setFilterList((prev) =>
        prev.map((item) =>
          filteredStatus?.split(',').find((status) => status === item.id) ? { ...item, value: true } : item,
        ),
      );
    }
  }, []);

  useEffect(() => {
    if (filteredStatus) {
      dispatch(
        fetchDepositOrder({
          page: currentPage,
          order: 'DESC',
          status: filteredStatus?.split(','),
        }),
      );
      return;
    }

    dispatch(
      fetchDepositOrder({
        page: currentPage,
        order: 'DESC',
      }),
    );
  }, [currentPage, filteredStatus]);

  const getURLDestination = async (transaction_key) => {
    const data = await dispatch(fetchOrderById(transaction_key));

    let orderType = '';

    if (data?.payload?.order_type_id === 1) {
      if (data?.payload?.order_detail.without_driver) {
        orderType = 'without-driver';
      } else {
        orderType = 'with-driver';
      }
    } else if (data?.payload?.order_type_id === 2) {
      orderType = 'airport-transfer';
    }

    return orderType;
  };

  const handleDetail = (e) => {
    const orderId = e.target.parentElement.parentElement.dataset['key'];

    const filteredOrderById = depositOrder?.data.filter((item) => item.id === +orderId);
    dispatch(fetchDepositDetail(filteredOrderById[0]?.order.transaction_key));
    navigate({
      pathname: `detail/${filteredOrderById[0]?.order.transaction_key}`,
      search: createSearchParams({
        customerId: filteredOrderById[0]?.order.customer_id,
      }).toString(),
    });
  };

  const handleNavigate = async (e) => {
    const orderId = e.target.parentElement.dataset['key'];

    const filteredOrderById = depositOrder?.data.filter((item) => item.id === +orderId);
    const urlDestination = await getURLDestination(filteredOrderById[0]?.order.transaction_key);
    navigate({
      pathname: `/${urlDestination}/detail/${filteredOrderById[0]?.order.transaction_key}`,
      search: createSearchParams({
        customerId: filteredOrderById[0]?.order.customer_id,
      }).toString(),
    });
  };

  const handleExport = async () => {
    try {
      await dispatch(getDepositReports({ status: filteredStatus?.split(',') })).unwrap();
    } catch (err) {
      showToast({ type: 'error', message: 'Gagal download deposit reports!' });
    }
  };

  const handleImport = (selectedFile) => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    setShowConfirmation({
      message: 'Apakah anda yakin ingin melakukan import deposit reports?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(importDepositReports(formData)).unwrap();
          showToast({ type: 'success', message: 'Berhasil melakukan import deposit reports' });
        } catch (err) {
          showToast({ type: 'error', message: 'Gagal melakukan import deposit reports' });
        } finally {
          dispatch(
            fetchDepositOrder({
              page: currentPage,
              order: 'DESC',
              status: filteredStatus?.split(','),
            }),
          );
          setFile(null);
        }
      },
    });
  };

  const onFileChange = (e) => {
    const { files } = e.target;
    if (files.length === null) return;

    const selectedFile = files[0];
    setFile(selectedFile);

    e.target.value = null;
  };

  useEffect(() => {
    if (file === null || file === undefined) return;
    handleImport(file);
  }, [file]);

  const data = depositOrder?.data?.map((item) => ({
    id: item.id,
    name: item.order.user_name,
    order_key: item.order.order_key,
    booking_deposit: setCurrency(item.booking_deposit + item.deposit_e_toll),
    status: item.status.toLowerCase(),
  }));

  const rows = 10 - data?.length;

  return (
    <div className="deposit">
      <div className="deposit__wrapper">
        <div className="deposit__header">
          <div className="deposit__title">
            <DepositFilledIcon fill="#009EF7" width="25px" height="25px" />
            <h2>Deposit</h2>
          </div>
          <div className="deposit__filter">
            <input
              type="file"
              name="upload-excel-file"
              id="upload-excel-file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              hidden
              ref={targetRef}
              onChange={onFileChange}
            />
            <Button
              style={{
                background: '#F1A33A',
              }}
              height={30}
              onClick={() => targetRef.current.click()}
            >
              <UploadIcon className="upload-icon" />
              Import From Excel
            </Button>
            <Button
              style={{
                background: '#F1A33A',
              }}
              height={30}
              onClick={handleExport}
            >
              <DownloadIcon className="download-icon" />
              Export To Excel
            </Button>
            <FilterTransaction selected={filterList} setSelected={setFilterList} setPage={setCurrentPage} />
          </div>
        </div>

        {data?.length === 0 ? (
          <EmptyState />
        ) : (
          <div className={clsx('table')}>
            <table className="table-wrapper">
              <thead className="table-head">
                <tr>
                  {depositColumn.map((item, i) => (
                    <th key={i}>{item.header}</th>
                  ))}
                  <th className="action-col">Action</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {data?.length > 0 &&
                  data.map((item, no) => (
                    <tr key={no}>
                      {depositColumn.map((col, idx) => {
                        if (col.value === 'no') {
                          return (
                            <td
                              key={idx}
                              data-type="no"
                              className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                            >
                              {pageNumber(currentPage, no + 1)}
                            </td>
                          );
                        } else if (col.value === 'status') {
                          return (
                            <td
                              key={idx}
                              data-type={'status'}
                              className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                            >
                              {<StatusButton status={item[col.value]} />}
                            </td>
                          );
                        } else if (col.value === 'order_key') {
                          return (
                            <td
                              key={idx}
                              data-type={'order_key'}
                              className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                              data-key={item.id}
                            >
                              <span className="order-key-link" onClick={handleNavigate}>
                                {item[col.value]}
                              </span>
                            </td>
                          );
                        } else {
                          return (
                            <td
                              key={idx}
                              data-type={col.value}
                              className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                            >
                              {item[col.value]}
                            </td>
                          );
                        }
                      })}
                      <td className="btn-action" data-type="actionButtons" data-key={item.id}>
                        <ActionButtons success={handleDetail} />
                      </td>
                    </tr>
                  ))}
                {rows > 0 &&
                  [...Array(rows)].map((_, idx) => (
                    <tr key={idx}>
                      {depositColumn.map((_, idx) => (
                        <td key={idx}></td>
                      ))}
                      <td className="btn-action"></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        <PaginationTable
          currentPage={currentPage}
          onPageChange={(newPage) => setCurrentPage(newPage)}
          totalCount={depositOrder.pagination?.total}
        />
      </div>
    </div>
  );
};

export default DepositComponent;
