import React, { useState } from 'react';
import { ReactComponent as AddIcon } from 'icons/add-icon.svg';
import { ReactComponent as SuccessIcon } from 'icons/save-icon.svg';
import { ReactComponent as CancelIcon } from 'icons/cancel-icon.svg';

const CategoryInput = ({ setSelectedCategory, selectedCategory, categories }) => {
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');

  const saveCategory = () => {
    if (!category) return;
    // setCategories((prev) => [...prev, category]);
    setIsAddCategory(false);
    setCategory(() => '');
  };

  const selectCategory = (e) => {
    setSelectedCategory(e);
    setIsOpen(false);
  };

  return (
    <div className="g-input input-category">
      <label htmlFor="category" className="input-text-title">
        Kategori
      </label>
      <section className="field-wrapper">
        {isAddCategory ? (
          <input
            id="category"
            className={'input-text-field'}
            type={'text'}
            placeholder={'Tulis Kategori'}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        ) : (
          <>
            <input
              id="category"
              className={'input-text-field'}
              type={'text'}
              placeholder={'Pilih Kategori'}
              readOnly
              value={selectedCategory}
              onClick={() => setIsOpen(!isOpen)}
            />
            {isOpen && (
              <div className="select-category">
                {categories.length > 0 ? (
                  categories?.map((item, idx) => (
                    <p key={idx} className="item-category" onClick={() => selectCategory(item)}>
                      {item}
                    </p>
                  ))
                ) : (
                  <p className="item-category">Kategori Kosong</p>
                )}
              </div>
            )}
          </>
        )}
        <div className="field-icons">
          {isAddCategory ? (
            <>
              <div className="success-icon">
                <SuccessIcon color="#fff" onClick={saveCategory} />
              </div>
              <div className="cancel-icon">
                <CancelIcon color="#b4b4b4" onClick={() => setIsAddCategory(false)} />
              </div>
            </>
          ) : (
            <div className="add-icon">
              <AddIcon
                color="#0085ff"
                onClick={() => {
                  setCategory('');
                  setIsAddCategory(true);
                }}
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default CategoryInput;
