import React from 'react';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { PaginationTable, Table, TableWrapper } from 'components/Global';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBrand, getAllBrands } from 'features/brands/actions';
import { useState } from 'react';
import { useAppContext } from 'components/Context/AppContext';
import ButtonAdd from 'components/Global/ButtonAdd';

const CarBrandsComponent = () => {
  const brandsData = useSelector((state) => state.brands.data);
  const { setShowAddBrandCar, setShowConfirmation, setShowSpinner, showToast } = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const column = [
    { header: 'No.', value: 'no' },
    { header: 'Merek Mobil', value: 'name' },
  ];

  const addBrandHandle = () => {
    setShowAddBrandCar(true);
  };

  const handleDelete = (e) => {
    const carBrandId = e.target.parentElement.parentElement.dataset['key'];

    setShowConfirmation({
      message: 'Apakah anda yakin ingin menghapus Item',
      show: true,
      onClick: async () => {
        try {
          setShowSpinner(true);
          await dispatch(deleteBrand(carBrandId)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Hapus Item' });
          setCurrentPage(1);
        } catch (error) {
          showToast({ type: 'error', message: 'Gagal Hapus Item' });
        } finally {
          setShowSpinner(false);
        }
      },
    });
  };

  useEffect(() => {
    dispatch(getAllBrands());
  }, []);

  return (
    <div className="car-brands">
      <ButtonAdd onClick={addBrandHandle} label="Tambah Merk Mobil" />

      <TableWrapper title="Merk Mobil" icon={<CarIcon fill="#009EF7" width="25px" height="25px" />}>
        <Table
          column={column}
          data={brandsData.slice(currentPage === 1 ? 0 : currentPage * 10 - 10, currentPage * 10)}
          actionBtn
          onDel={handleDelete}
          currentPage={currentPage}
        />
        <PaginationTable
          currentPage={currentPage}
          onPageChange={(newPage) => setCurrentPage(newPage)}
          totalCount={brandsData.length}
        />
      </TableWrapper>
    </div>
  );
};

export default CarBrandsComponent;
