import React from 'react';
import Button from '../Button';
import { ReactComponent as PlusIcon } from 'icons/plus-icon.svg';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utils/functionality';

const ButtonAdd = ({ onClick, label }) => {
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  return (
    checkPermission(offCanvasMenu, currentMenu, 'create') && (
      <div className="button-wrapper">
        <Button onClick={onClick} style={{ minWidth: 192 }} height="39px" className="button-add">
          <PlusIcon />
          <span className="button-label">{label}</span>
        </Button>
      </div>
    )
  );
};

export default ButtonAdd;
