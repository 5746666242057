import React from 'react';
import clsx from 'clsx';
import { Button, InputField } from 'components/Global';
import { getPaymentMethodString } from 'utils/getPaymentMethod';

const Pembayaran = ({ data, getFileName, imagePreviewHandler, isPaymentValid, setShowBankTransferConfirmation }) => {
  return (
    <div className="detail-order__payment-detail">
      <div className="detail-order__payment-detail-title">
        <h1>Pembayaran</h1>
      </div>
      <div className="detail-order__payment-detail-body">
        <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

        <div
          className={clsx(
            'detail-order__payment-detail-body__payment-method',
            data?.order_status === 'CHECKOUT' ? 'confirmation' : 'not-confirmation',
          )}
        >
          <InputField
            label="Metode Pembayaran"
            disabled
            // value={data?.disbursement?.payment?.method ? data?.disbursement.payment.method : '-'}
            value={getPaymentMethodString(
              data?.order_approval_status?.includes('UPDATE_ORDER')
                ? data?.disbursement_recalculate
                : data.disbursement,
            )}
          />
          {data.order_status === 'CHECKOUT' && data?.order_approval_status !== 'WAITING_APPROVAL_DELETE_ORDER' ? (
            <Button height={44} onClick={() => setShowBankTransferConfirmation(true)} disabled={isPaymentValid}>
              Konfirmasi
            </Button>
          ) : null}
        </div>

        {data?.order_status !== 'CHECKOUT' &&
        data?.order_status !== 'RECONFIRMATION' &&
        Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
          <>
            <InputField
              label="Nama Rekening"
              disabled
              value={
                Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                  ? [...data.disbursement_reconfirmation].pop()?.sender_name
                  : data?.disbursement?.sender_name || '-'
              }
            />

            <InputField
              label="Nama Bank"
              disabled
              value={
                Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                  ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                  : data?.disbursement?.sender_bank_name || '-'
              }
            />

            <div className="detail-order__payment-detail-body__preview-image">
              <InputField
                label="Foto Bukti Transfer"
                disabled
                value={getFileName(
                  Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                    ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                    : data?.disbursement.disbursement_confirmation_image,
                )}
                className="preview-image-input"
              />

              <Button
                bgColor="#D9D9D9"
                textColor="#000000"
                className="preview-image-btn"
                onClick={() =>
                  imagePreviewHandler(
                    Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                      ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                      : data?.disbursement.disbursement_confirmation_image,
                  )
                }
              >
                Lihat
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Pembayaran;
