import React from 'react';
import { InputField } from 'components/Global';

const DetailPenyewa = ({ orderData, customer, selectedCountry }) => {
  return (
    <div className="detail-order-with-driver__renter-detail">
      <div className="detail-order-with-driver__renter-detail-title">
        <h1>Detail Penyewa</h1>
      </div>
      <div className="detail-order-with-driver__renter-detail-body">
        <InputField label="Nama Lengkap" disabled value={orderData?.user_name || '-'} />
        <InputField label="Email" disabled value={orderData?.email || '-'} />

        <div className="detail-order-with-driver__renter-detail-body__phone-number">
          <InputField
            label="No Handphone"
            disabled
            value={orderData?.is_admin_creation ? orderData?.phone_country_code : customer?.phone_code || '-'}
            icon={
              <img
                src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                width={26}
                height={16}
                alt={selectedCountry?.name + ' nation flag'}
              />
            }
          />
          <InputField value={orderData?.phone_number || '-'} disabled />
        </div>

        <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

        <div className="detail-order-with-driver__renter-detail-body__phone-number">
          <div className="detail-order-with-driver__renter-detail-body__phone-number-wrapper">
            <InputField
              label="Whatsapp"
              disabled
              value={orderData?.is_admin_creation ? orderData?.phone_country_code : customer?.phone_code || '-'}
              icon={
                <img
                  src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                  width={26}
                  height={16}
                  alt={selectedCountry?.name + ' nation flag'}
                />
              }
            />
            <div className="detail-order-with-driver__renter-detail-body__image-wrapper"></div>
          </div>
          <InputField disabled value={orderData?.wa_number || '-'} />
        </div>
      </div>
    </div>
  );
};

export default DetailPenyewa;
