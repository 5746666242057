import React from 'react';
import { ReactComponent as BannerIcon } from 'icons/banner-filled-icon.svg';
import { useAppContext } from 'components/Context/AppContext';
import Tooltip from 'components/Global/Tooltip';
import { TableWrapper, SelectField } from 'components/Global';

import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from 'icons/delete-icon.svg';
// import EditIcon from 'icons/edit-icon.svg';
import { useEffect } from 'react';
import { deleteBanner, getAllBanner } from 'features/banner/actions';
import ButtonAdd from 'components/Global/ButtonAdd';
import { checkPermission } from 'utils/functionality';

const imageUrl = process.env.REACT_APP_IMAGES;

const SELECT_DATA = [
  {
    name: '20',
    id: 2,
  },
];

const BannerComponent = () => {
  // const [currentPage, setCurrentPage] = React.useState(1);
  // const pageCount = Math.ceil(tableData.length / 10);

  const { setShowAddBannerModal, setShowConfirmation, showToast } = useAppContext();
  const { data: banners } = useSelector((state) => state.banner);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const deleteHandler = (e) => {
    const imageId = e.target.parentElement.parentElement.dataset['key'];
    if (!imageId) return;

    setShowConfirmation({
      message: 'Apakah anda yakin ingin menghapus data?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deleteBanner(imageId)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Menghapus data' });
          dispatch(getAllBanner());
        } catch (error) {
          showToast({ type: 'error', message: 'Gagal Menghapus data' });
        }
      },
    });
  };

  useEffect(() => {
    dispatch(getAllBanner());
  }, []);

  return (
    <div className="banner">
      <ButtonAdd
        onClick={() => {
          setShowAddBannerModal(true);
        }}
        label="Tambah Banner"
      />

      <TableWrapper icon={<BannerIcon fill="#009EF7" width="25px" height="25px" />} title="Banner">
        <div className="banner__header">
          <h3>Total Data : {banners?.length ?? 0}</h3>
          <h3>View Data</h3>
          <SelectField data={SELECT_DATA} placeholder={10} style={{ width: '345px' }} />
        </div>
        <div className="banner__body">
          {/* <div className="banner__container"> */}
          {banners?.map((item, idx) => (
            <div key={idx} className="banner__item">
              <div className="banner__image-wrapper">
                <img src={imageUrl + item.image} alt="thumbnail" />
              </div>
              <div className="banner__icons-wrapper" data-key={item.id}>
                {checkPermission(offCanvasMenu, currentMenu, 'delete') && (
                  <Tooltip content="Delete">
                    <img src={DeleteIcon} onClick={deleteHandler} />
                  </Tooltip>
                )}
              </div>
            </div>
          ))}
        </div>
        {/* </div> */}
      </TableWrapper>
    </div>
  );
};

export default BannerComponent;
