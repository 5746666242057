import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllInboxes = createAsyncThunk('notificationInbox/getAllInboxes', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  const { limit = 6, page = 1 } = payload;
  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/inboxes?limit=${limit}&page=${page}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getInboxDetail = createAsyncThunk('notificationInbox/getInboxDetail', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/inboxes/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
