import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const fetchAdditionalsOrder = createAsyncThunk(
  'additionals/fetchAdditionalsOrder',
  async (transaction_key, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const res = await axios.get(`${BASE_URL}/${API_VERSION}/admin/orders/${transaction_key}/additional-fees`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const addAdditionalOrder = createAsyncThunk('additionals/addAdditionalOrder', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.post(
      `${BASE_URL}/${API_VERSION}/additional-fees`,
      { ...payload },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteAdditionalOrderById = createAsyncThunk(
  'additionals/deleteAdditionalOrderById',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const res = await axios.delete(`${BASE_URL}/${API_VERSION}/additional-fees/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
