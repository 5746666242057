import React, { useEffect, useState } from 'react';
import Modals from '../Modals';
import { Button, InputField } from 'components/Global';
import { ReactComponent as DepositFilledIcon } from 'icons/deposit-filled-icon.svg';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import BankInputDropdown from './bank-input';
import UploadImage from 'components/Global/UploadImage';
import { convertImageToBase64 } from 'utils/functionality';
import { createDepositConfirmation, fetchDepositOrder } from 'features/orders/actions';
import { getDepositDetail } from 'features/orders/depositSlice';

const DepositConfirmationModal = () => {
  const { setShowDepositConfirmation, setShowSpinner, showToast, setShowDepositDetailModal } = useAppContext();
  const dispatch = useDispatch();
  const bankList = useSelector((state) => state.banks?.data);
  const depositData = useSelector(getDepositDetail);
  const [formData, setFormData] = useState({
    order_deposit_id: 0,
    name: '',
    bank: '',
    account_number: '',
    image_transfer: '',
  });

  const { name, bank, account_number, image_transfer, order_deposit_id } = formData;

  const handleSelectBank = (item) => {
    setFormData((prev) => ({ ...prev, bank: item.name }));
  };

  useEffect(() => {
    if (!depositData) return;
    setFormData((prev) => ({ ...prev, order_deposit_id: depositData?.id }));
  }, []);

  const handleImageSelect = async (item) => {
    const convertedImage = await convertImageToBase64(item[0]);
    setFormData((prev) => ({ ...prev, image_transfer: convertedImage?.file }));
  };

  const handleSubmit = async () => {
    if (!name || !account_number || !image_transfer || !bank || order_deposit_id == 0) {
      showToast({ type: 'warning', message: 'Harap Isi Semua Field' });
      return;
    }

    try {
      const res = await dispatch(createDepositConfirmation(formData));

      setShowSpinner(true);

      if (res.meta?.requestStatus === 'fulfilled') {
        showToast({ type: 'success', message: 'Berhasil Proses Deposit' });
        return;
      }
      showToast({ type: 'error', message: 'Gagal Proses Deposit' });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: `Terjadi Kesalahan: ${error}` });
    } finally {
      dispatch(fetchDepositOrder(1));
      setShowSpinner(false);
      setShowDepositConfirmation(false);
    }
  };

  const handleClickOutside = () => {
    setShowDepositConfirmation(false);
    setShowDepositDetailModal({ show: true, history: false });
  };

  return (
    <Modals
      setState={handleClickOutside}
      title="Pembayaran Deposit"
      icon={<DepositFilledIcon fill="#009EF7" width="25px" height="25px" />}
    >
      <div className="deposit-confirmation">
        <InputField
          label={'Nama Pemilik Rekening'}
          htmlFor="account-name"
          value={name}
          autoComplete="off"
          placeholder={'Tulis nama pemilik rekening'}
          onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
        />
        <BankInputDropdown
          placeholder={'Pilih bank'}
          label="Nama Bank Transfer"
          data={bankList?.data}
          state={formData}
          setState={setFormData}
          dropdownHandler={handleSelectBank}
        />

        <InputField
          placeholder={'Contoh : 123456789'}
          label={'Nomor Rekening'}
          autoComplete="off"
          htmlFor="account-number"
          value={account_number}
          onChange={(e) => setFormData((prev) => ({ ...prev, account_number: e.target.value }))}
        />

        <div className="new-garage__upload">
          <h3 className="new-garage__label">Bukti Transfer</h3>
          <UploadImage value={image_transfer} setState={handleImageSelect} />
        </div>

        <Button wfull height={39} onClick={handleSubmit}>
          Kirim Deposit
        </Button>
      </div>
    </Modals>
  );
};

export default DepositConfirmationModal;
