import { useSelector, useDispatch } from 'react-redux';
import Modals from '../Modals';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { useAppContext } from 'components/Context/AppContext';
import { Button } from 'components/Global';
import { updateOrderCancelation } from 'features/orders/actions';
import { useEffect, useRef, useState } from 'react';
import { setCurrency } from 'utils/functionality';
import { indonesianDateFormat } from 'utils/helpers';
import { ReactComponent as DownloadIcon } from 'icons/download-icon.svg';
import { getCustomerData } from 'features/user/slice';
import { getDetailOrder } from 'features/orders/detailOrderSlice';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const RefundDetailModal = () => {
  const dispatch = useDispatch();

  // CONTEXT
  const { setShowRefundDetail, showToast, setShowConfirmation } = useAppContext();

  // GLOBAL STATE
  const { orderCancelation } = useSelector((state) => state.cancelledOrder);
  const { singleUser } = useSelector((state) => state.user);
  const { selected: selectedVehicle } = useSelector((state) => state.vehicle);
  const { data: detailOrder } = useSelector((state) => state.detailOrder);

  const divRef = useRef(null);
  const [width, setWidth] = useState(0);

  const data = useSelector(getDetailOrder);
  const customer = useSelector(getCustomerData);

  // PROCESS FUNCTION
  const handleProcess = () => {
    setShowConfirmation({
      message: 'Apakah anda yakin ingin memproses pengembalian',
      show: true,
      onClick: async () => {
        const payload = {
          transaction_key: detailOrder.transaction_key,
          status: 'TRANSFERED',
        };

        const response = await dispatch(updateOrderCancelation(payload));

        if (response.meta?.requestStatus === 'fulfilled') {
          showToast({ type: 'success', message: 'Berhasil Memproses Pengembalian' });
          setShowRefundDetail(false);
          return;
        }

        showToast({ type: 'error', message: 'Gagal Memproses Pengembalian' });
        setShowRefundDetail(false);
      },
    });
  };

  useEffect(() => {
    if (!divRef.current) return;
    setWidth(divRef?.current?.offsetWidth);
  }, []);

  const downloadImage = (url_slug) => {
    window.location.href = IMAGE_BASE_URL + url_slug;
  };

  return (
    <Modals
      width={width}
      setState={setShowRefundDetail}
      icon={<CarIcon fill="#009EF7" width="25px" height="25px" />}
      title="Harian"
      secondTitle="Data Pengembalian"
    >
      <div className="refund-modal">
        <div className="refund-modal__wrapper">
          {/* FIRST COLUMN */}
          <div className="refund-modal__column">
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Nama Lengkap</h3>
              <p className="refund-modal__value">{singleUser.name || '-'}</p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Email</h3>
              <p className="refund-modal__value">{singleUser.email || '-'}</p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">No. Handphone</h3>
              <p className="refund-modal__value">{singleUser.phone || '-'}</p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Negara</h3>
              <p className="refund-modal__value">Indonesia</p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Whatsapp</h3>
              <p className="refund-modal__value">{singleUser.wa_number || '-'}</p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Alamat Pengantaran</h3>
              <p className="refund-modal__value">{detailOrder.order_detail.rental_delivery_location || '-'}</p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Alamat Pengembalian</h3>
              <p className="refund-modal__value">{detailOrder.order_detail.rental_return_location || '-'}</p>
            </div>
          </div>

          {/* SECOND COLUMN */}
          <div className="refund-modal__column">
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Tipe Sewa</h3>
              <p className="refund-modal__value">{detailOrder.order_type_id === 1 ? 'Harian' : '-'}</p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Jenis Mobil</h3>
              <p className="refund-modal__value">{selectedVehicle.brand_name || '-'}</p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Tanggal Sewa</h3>
              <p className="refund-modal__value">
                {indonesianDateFormat(detailOrder.order_detail.start_booking_date || '-')}
              </p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Selesai Sewa</h3>
              <p className="refund-modal__value">
                {indonesianDateFormat(detailOrder.order_detail.end_booking_date || '-')}
              </p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Jam Sewa</h3>
              <p className="refund-modal__value">{detailOrder.order_detail.start_booking_time || '-'}</p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Plat Mobil</h3>
              <p className="refund-modal__value">{selectedVehicle.license_number || '-'}</p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Total Harga</h3>
              <p className="refund-modal__value">
                {detailOrder.total_payment ? setCurrency(detailOrder.total_payment) : '-'}
              </p>
            </div>
          </div>

          {/* THIRD COLUMN */}
          <div className="refund-modal__column">
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Status Pembayaran</h3>
              <p className="refund-modal__value">{detailOrder.order_status || '-'}</p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Metode Pembayaran</h3>
              <p className="refund-modal__value">
                {detailOrder.disbursement?.payment?.method ? detailOrder.disbursement.payment.method : '-'}
              </p>
            </div>
            <div className="refund-modal__identity">
              {/* <div className="refund-modal__identity--image">
                <img src="assets/image/ktp-example.png" alt="foto KTP pelanggan" />
              </div> */}
              {customer.PersonalInfos?.ktp === '' && data?.order_detail.identity.ktp === '' ? (
                <p>Belum upload KTP</p>
              ) : (
                <div className="transaction-identity--image">
                  <img
                    src={
                      customer.PersonalInfos?.ktp !== ''
                        ? IMAGE_BASE_URL + customer.PersonalInfos?.ktp
                        : data?.order_detail.identity.ktp
                    }
                    alt="foto KTP pelanggan"
                  />
                  <DownloadIcon onClick={() => downloadImage(customer.PersonalInfos?.ktp)} />
                </div>
              )}
              {/* <div className="refund-modal__identity--image">
                <img src="assets/image/sim-example.png" alt="foto SIM pelanggan" />
              </div> */}
              {customer.PersonalInfos?.sim === '' && data?.order_detail.identity.sim === '' ? (
                <p>Belum upload SIM</p>
              ) : (
                <div className="transaction-identity--image">
                  <img
                    src={
                      customer.PersonalInfos?.sim !== ''
                        ? IMAGE_BASE_URL + customer.PersonalInfos?.sim
                        : data?.order_detail.identity.sim
                    }
                    alt="foto SIM pelanggan"
                  />
                  <DownloadIcon onClick={() => downloadImage(customer.PersonalInfos?.sim)} />
                </div>
              )}
            </div>
          </div>

          {/* FOURTH COLUMN */}
          <div className="refund-modal__column" ref={divRef}>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Nama</h3>
              <p className="refund-modal__value">{orderCancelation.Name || '-'}</p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Nama Bank</h3>
              <p className="refund-modal__value">{orderCancelation.BankName || '-'}</p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Nomor Rekening</h3>
              <p className="refund-modal__value">{orderCancelation.BankAccountNumber || '-'}</p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Jumlah Uang</h3>
              <p className="refund-modal__value">
                IDR {detailOrder.total_payment ? setCurrency(detailOrder.total_payment).replace(/[Rp]/g, '') : '-'}
              </p>
            </div>
            <div className="refund-modal__info-cancelation">
              <h3 className="refund-modal__title">Alasan Pembatalan</h3>
              <p className="refund-modal__value">{orderCancelation.CancelationReason || '-'}</p>
            </div>
            <div className="refund-modal__info">
              <h3 className="refund-modal__title">Status Pengembalian</h3>
              <p className="refund-modal__value">
                {orderCancelation.Status
                  ? orderCancelation.Status.split('')
                      .map((item, i) => (i === 0 ? item : item.toLowerCase()))
                      .join('')
                  : '-'}
              </p>
            </div>
            <div className="refund-modal__form-check">
              <input className="refund-modal__check-input" type="checkbox" id="emailNotif" />
              <label className="refund-modal__check-label" htmlFor="emailNotif">
                Kirim Notifikasi pengembalian via Email
              </label>
            </div>
          </div>
        </div>

        {/* ACTION BUTTON */}
        <div className="refund-modal__buttons">
          <Button variant="outline" className="button" width={208} size="sm" onClick={() => setShowRefundDetail(false)}>
            Kembali
          </Button>
          {detailOrder?.order_cancelation.Status !== 'TRANSFERED' ? (
            <Button variant="success" className="button" width={208} size="sm" onClick={handleProcess}>
              Proses
            </Button>
          ) : null}
        </div>
      </div>
    </Modals>
  );
};

export default RefundDetailModal;
