import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LokasiSection from './Lokasi';
import ServiceSection from './Service';
import Tabs, { TabPane } from 'components/Global/Tabs';
import ButtonAdd from 'components/Global/ButtonAdd';

const TermAndConditionComponent = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const tabActive = searchParams.get('tab-active');

  const handleAdd = () => {
    navigate(`/term-and-condition/${tabActive.toLowerCase()}`);
  };

  useEffect(() => {
    if (!tabActive) {
      setSearchParams({ 'tab-active': 'Lokasi' });
    } else {
      setActiveTab(tabActive);
    }
  }, [tabActive]);

  return (
    <div className="term-and-condition">
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
        <TabPane label="Lokasi">
          <LokasiSection />
        </TabPane>
        <TabPane label="Service">
          <ServiceSection />
        </TabPane>
      </Tabs>

      {/* BUTTON */}
      <ButtonAdd onClick={handleAdd} label={`Tambah ${tabActive} SNK`} />
    </div>
  );
};

export default TermAndConditionComponent;
