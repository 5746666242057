import React, { useEffect } from 'react';
import Modals from '../Modals';
import { ReactComponent as ContactIcon } from '../../../icons/contact-filled-icon.svg';
import { useAppContext } from 'components/Context/AppContext';
import { InputField } from 'components/Global';
import TextAreaInput from 'components/Global/TextArea';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { checkEmptyObject } from 'utils/functionality';

const ContactDetailModal = () => {
  // context
  const { setShowContactUsDetail } = useAppContext();

  // global state selected contact us data
  const selectedContactUs = useSelector((state) => state.contactUs.selected);

  // state of contact us data
  const [contactData, setContactData] = useState({
    id: '',
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  // set contact us data with selected contact
  useEffect(() => {
    if (!checkEmptyObject(selectedContactUs)) {
      setContactData(selectedContactUs);
    }
  }, [selectedContactUs]);

  return (
    <Modals setState={setShowContactUsDetail} title="Hubungi Kami" icon={<ContactIcon width="25px" height="25px" />}>
      <div className="contact-detail">
        {/* CONTENT LEFT*/}
        <div className="contact-detail__left">
          <InputField label="Nama" htmlFor="nama" value={contactData.name} readOnly />
          <InputField label="Email" htmlFor="email" value={contactData.email} readOnly />
          <InputField label="Topik" htmlFor="topik" value={contactData.subject} readOnly />
        </div>

        {/* CONTENT RIGHT */}
        <div className="contact-detail__right">
          <TextAreaInput
            label="Kritik dan Saran"
            htmlFor="kritik"
            className="text-area-input"
            readOnly
            value={contactData.message}
          />
        </div>
      </div>
    </Modals>
  );
};

export default ContactDetailModal;
