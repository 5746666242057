import { AppContext } from 'components/Context/AppContext';
import { Button, InputField } from 'components/Global';
import { useContext } from 'react';
import Modals from '../Modals';

const NotificationTemplate = () => {
  const { setShowNotificationTemplate, setShowSendNotification } = useContext(AppContext);

  const handleBack = () => {
    setShowNotificationTemplate(false);
    setShowSendNotification(true);
  };

  return (
    <Modals setState={setShowNotificationTemplate} title="Buat Template">
      <div className="notificationTemplate">
        <div className="notificationTemplate__form">
          <form action="#" onSubmit={(e) => e.preventDefault()}>
            <div className="notificationTemplate__form-group">
              <InputField
                label="Nama Notifikasi"
                type="text"
                htmlFor="nama-notifikasi"
                placeholder="Tulis Nama Notifikasi"
                className="notificationTemplate__input"
                // value={}
                // onChange={}
              />
              <div className="notificationTemplate__message-area">
                <label htmlFor="notifikasi">Notifikasi</label>
                <textarea name="notifikasi" id="" cols="30" rows="10" />
              </div>
              <InputField
                label="No. Pesanan"
                type="number"
                htmlFor="NoPesanan"
                placeholder="Nomor Pesanan"
                className="notificationTemplate__input"
                // value={}
                // onChange={}
              />
            </div>

            <div className="notificationTemplate__button">
              <Button variant="outline" wfull size="md" className="button" onClick={handleBack}>
                Kembali
              </Button>
              <Button wfull size="md" className="button" type="submit">
                Simpan
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modals>
  );
};

export default NotificationTemplate;
