import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'components/Context/AppContext';
import { deleteTermAndCondition, getAllTermAndconditionService } from 'features/term-and-condition/actions';
import { PaginationTable, Table, TableWrapper } from 'components/Global';
import EmptyState from 'components/Global/EmptyState';
import { ReactComponent as TACFilledIcon } from 'icons/term-and-condition-filled-icon.svg';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Location', value: 'location' },
  { header: 'Service', value: 'sub_service' },
];

const ServiceSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setShowConfirmation, showToast, setShowSpinner } = useAppContext();

  const { data: tacData, isLoading } = useSelector((state) => state.termAndCondition);

  const [currentPage, setCurrentPage] = useState(1);

  const editHandler = (e) => {
    const termAndConditionId = e.target.parentElement.parentElement.dataset['key'];
    navigate(`/term-and-condition/service?id=${termAndConditionId}`);
  };

  const deleteHandler = (e) => {
    const termAndConditionId = e.target.parentElement.parentElement.dataset['key'];

    setShowConfirmation({
      message: 'Apakah anda yakin ingin menghapus Item',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deleteTermAndCondition(termAndConditionId)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Hapus Item' });
          dispatch(getAllTermAndconditionService({ page: 1, limit: 10 }));
          setCurrentPage(1);
        } catch (error) {
          showToast({ type: 'error', message: 'Gagal Hapus Item' });
        }
      },
    });
  };

  useEffect(() => {
    dispatch(getAllTermAndconditionService({ page: currentPage, limit: 10 }));
  }, [currentPage]);

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  return (
    <TableWrapper icon={<TACFilledIcon fill="#009EF7" width="25px" height="25px" />} title="Syarat & Ketentuan">
      {(!Object.keys(tacData).length || !tacData.data || !tacData.data.length) && !isLoading ? (
        <EmptyState />
      ) : (
        <>
          <Table column={column} data={tacData.data} actionBtn onDel={deleteHandler} onEdit={editHandler} />
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={tacData?.pagination?.total_data}
          />
        </>
      )}
    </TableWrapper>
  );
};

export default ServiceSection;
