import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllAirportPackages = createAsyncThunk(
  'airportPackages/getAllAirportPackages',
  async ({ limit = 10, page = 1, categoryId, locationId }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let newUrl = `${BASE_URL}/${API_VERSION}/airport-packages?limit=${limit}&page=${page}`;

    if (categoryId) {
      newUrl += `&category_id=${categoryId}`;
    }

    if (locationId) {
      newUrl += `&location_id=${locationId}`;
    }

    try {
      const response = await axios.get(newUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// CREATE OR UPDATE
export const createAirportPackages = createAsyncThunk(
  'airportPackages/createAirportPackages',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/${API_VERSION}/airport-packages`,
        { ...payload },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const data = await response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const deleteAirportPackages = createAsyncThunk('airportPackages/deleteAirportPackages', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${BASE_URL}/${API_VERSION}/airport-packages/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
