import { InputField, SelectFieldVehicle } from 'components/Global';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVehiclesByFilter } from 'features/vehicle/action';
import { checkEmptyObject } from 'utils/functionality';
import { getAllCategories } from 'features/category/actions';

const AdditionalGarageSetting = ({
  serviceData,
  locationId,
  data,
  setData,
  handleKeyDown,
  numberInputOnWheelPreventChange,
  index,
}) => {
  const dispatch = useDispatch();

  const [mappedCategories, setMappedCategories] = useState([]);
  const [vehicleData, setVehicleData] = useState({});

  const { data: allCategories } = useSelector((state) => state.category);

  useEffect(() => {
    if (checkEmptyObject(allCategories)) return;
    if (allCategories.category?.length > 0) {
      setMappedCategories(
        allCategories.category
          .filter((item) => item?.location?.id === +locationId)
          .map((item) => ({ ...item, value: item.name.toLowerCase() })),
      );
    }
  }, [allCategories, locationId]);

  useEffect(() => {
    if (!locationId || !serviceData.length) return;

    dispatch(
      getVehiclesByFilter({
        page: 0,
        limit: 0,
        locationRental: locationId,
        supportDriver:
          serviceData?.find((item) => item.id === +data.service)?.value === 'without_driver' ? false : true,
        subServiceId: +data.service,
      }),
    ).then((res) =>
      setVehicleData(
        res.payload.vehicles === null
          ? { pagination: res.payload.pagination, vehicles: [] }
          : {
              pagination: { ...res.payload.pagination, page: res.payload.pagination.last_page },
              vehicles: res.payload.vehicles,
            },
      ),
    );
    dispatch(
      getAllCategories({
        page: 1,
        useGroupAirportPackage: true,
      }),
    );
  }, [locationId, data.service]);

  const handleTipeSewa = (e) => {
    setData((prev) =>
      prev.map((item, idx) =>
        idx === index
          ? {
              ...prev[index],
              service: serviceData.find((item) => item.id == e.target.id)?.id,
              vehicle_ids: [],
              category_ids: [],
            }
          : item,
      ),
    );
  };

  const handleSelectVehicle = (cars) => {
    const serviceValue = serviceData?.find((item) => item.id === +data.service)?.value;
    setData((prev) => prev.map((item, idx) => (idx === index ? { ...prev[index], vehicle_ids: cars } : item)));
    if (cars.length === 0) {
      dispatch(
        getVehiclesByFilter({
          page: 0,
          limit: 0,
          locationRental: locationId,
          supportDriver: serviceValue === 'without_driver' ? false : true,
          subServiceId: +data.service,
        }),
      ).then((res) =>
        setVehicleData(
          res.payload.vehicles === null
            ? { pagination: res.payload.pagination, vehicles: [] }
            : {
                pagination: { ...res.payload.pagination, page: res.payload.pagination.last_page },
                vehicles: res.payload.vehicles,
              },
        ),
      );
    }
  };

  const handleSelectCategory = (id, status) => {
    const existedType = data.category_ids.find(
      (item) => item.id === allCategories.category?.find((el) => el.id == id)?.id,
    );

    if (existedType && status) return;
    const categoryValue = mappedCategories?.find((el) => el.id === id);

    if (status) {
      setData((prev) =>
        prev.map((item, idx) =>
          idx === index
            ? {
                ...prev[index],
                category_ids: [
                  ...prev[index].category_ids,
                  { id: categoryValue.id, name: categoryValue.name, value: categoryValue.name.toLowerCase() },
                ],
              }
            : item,
        ),
      );
    } else {
      setData((prev) =>
        prev.map((item, idx) =>
          idx === index
            ? { ...prev[index], category_ids: prev[index].category_ids.filter((item) => item.id !== categoryValue.id) }
            : item,
        ),
      );
    }
  };

  const handleValueInput = (e) => {
    setData((prev) => prev.map((item, idx) => (idx === index ? { ...prev[index], fee: e.target.value } : item)));
  };

  const searchHandler = (value) => {
    const serviceValue = serviceData?.find((item) => item.id === +data.service)?.value;

    setTimeout(() => {
      if (value) {
        dispatch(
          getVehiclesByFilter({
            page: 0,
            limit: 0,
            supportDriver: serviceValue === 'without_driver' ? false : true,
            locationRental: locationId,
            name: value,
            subServiceId: data.service,
          }),
        ).then((res) =>
          setVehicleData(
            res.payload.vehicles === null ? { pagination: res.payload.pagination, vehicles: [] } : res.payload,
          ),
        );
      } else {
        dispatch(
          getVehiclesByFilter({
            page: 0,
            limit: 0,
            locationRental: locationId,
            supportDriver: serviceValue === 'without_driver' ? false : true,
            subServiceId: data.service,
          }),
        ).then((res) =>
          setVehicleData(
            res.payload.vehicles === null
              ? { pagination: res.payload.pagination, vehicles: [] }
              : {
                  pagination: { ...res.payload.pagination, page: res.payload.pagination.last_page },
                  vehicles: res.payload.vehicles,
                },
          ),
        );
      }
    }, 1000);
  };

  const handlePenaltiStatus = () => {
    setData((prev) =>
      prev.map((item, idx) => (idx === index ? { ...prev[index], status: !prev[index].status } : item)),
    );
  };

  return (
    <div className="garage-detail__additional-setting__form">
      <SelectFieldDropdown
        label="Tipe Sewa"
        htmlFor="rent-type-additional"
        data={serviceData}
        placeholder="Pilih Tipe Sewa"
        value={data?.service}
        onChange={handleTipeSewa}
        disable={!locationId}
      />

      <InputField
        label="Tarif Penalti"
        type="number"
        placeholder="Masukkan Jumlah Tarif Penalti"
        value={data?.fee ?? ''}
        onChange={handleValueInput}
        onWheel={numberInputOnWheelPreventChange}
        // min={0}
        onKeyDown={handleKeyDown}
      />

      {serviceData?.find((item) => item.id === +data?.service)?.value === 'airport_transfer' ? (
        <SelectFieldDropdown
          label="Pilih Kategori Mobil"
          htmlFor="pilih-kategori-mobil"
          placeholder="Pilih Kategori Mobil"
          data={mappedCategories}
          value={data?.category_ids}
          onChange={handleSelectCategory}
          disable={!data?.service}
          checkBox
        />
      ) : (
        <SelectFieldVehicle
          label="Jenis Mobil"
          htmlFor="jenis-mobil"
          placeholder="Cari Mobil.."
          data={vehicleData}
          selectedCar={data?.vehicle_ids}
          onSelectCar={handleSelectVehicle}
          className="garage-detail__car-input"
          disable={!data?.service}
          onSearchAction={searchHandler}
        />
      )}

      <div className="garage-detail__operational-status">
        <p>Penalti Status</p>

        <div className="garage-detail__status-info">
          <div className="user__toggle-status">
            <label className="switch">
              <input type="checkbox" onChange={handlePenaltiStatus} role={'switch'} checked={data?.status} />
              <span className="slider"></span>
            </label>
          </div>

          {data?.status ? (
            <span className="user__status-badge user__status-badge--active">Aktif</span>
          ) : (
            <span className="user__status-badge user__status-badge--blocked">Inactive</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdditionalGarageSetting;
