import { createSlice } from '@reduxjs/toolkit';
import {
  getAllAirportLocationType,
  getDetailAirportLocationType,
  createAirportLocationType,
  deleteAirportLocationtype,
  updateAirportLocationType,
} from './actions';

const initialState = {
  data: {},
  selectedLocationType: {},
  isLoading: true,
  isError: false,
  errorMessage: {}, // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const airportLocationType = createSlice({
  name: 'airportLocationType',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAirportLocationType.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllAirportLocationType.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllAirportLocationType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getDetailAirportLocationType.pending, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = {};
        state.selectedLocationType = {};
      })
      .addCase(getDetailAirportLocationType.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDetailAirportLocationType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedLocationType = action.payload;
      })
      .addCase(createAirportLocationType.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(createAirportLocationType.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createAirportLocationType.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateAirportLocationType.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(updateAirportLocationType.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateAirportLocationType.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteAirportLocationtype.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(deleteAirportLocationtype.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteAirportLocationtype.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export default airportLocationType.reducer;
