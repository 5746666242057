import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const fetchViolationsOrder = createAsyncThunk(
  'violations/fetchViolationsOrder',
  async (transaction_key, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const res = await axios.get(`${BASE_URL}/${API_VERSION}/admin/orders/${transaction_key}/violations`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const deleteViolationOrderById = createAsyncThunk(
  'violations/deleteViolationOrderById',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const res = await axios.delete(`${BASE_URL}/${API_VERSION}/violations/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
