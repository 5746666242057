import { Button, InputField, TableWrapper } from 'components/Global';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CategoryInput from './CategoryInput';

const NotificationDetail = ({ setShowDetail, showDetail }) => {
  const [notifName, setNotifName] = useState('');
  const [notifMessage, setNotifMessage] = useState('');
  const [notifOrder, setNotifOrder] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const selectedTemplate = useSelector((state) => state.notification?.selected);
  const { templates } = useSelector((state) => state.notification?.data);

  const getCategories = (data) => {
    return data.map((item) => item.code);
  };

  useEffect(() => {
    if (showDetail == 'edit') {
      setNotifName(() => selectedTemplate?.name);
      setNotifMessage(() => selectedTemplate?.template);
      setSelectedCategory(() => selectedTemplate?.code);
    }
  }, [showDetail, selectedTemplate]);

  useEffect(() => {
    if (templates) {
      setCategories(() => getCategories(templates));
    }
  }, [templates]);

  return (
    <div className="notif-detail">
      <header className="notif-detail__header" onClick={() => setShowDetail('list')}>
        <LeftArrow />
        <p>Kembali</p>
      </header>
      <TableWrapper title={showDetail == 'add' ? 'Buat Template' : 'Edit Template'}>
        <div className="notif-detail__first">
          <InputField
            label="Nama Notifikasi"
            placeholder="Tulis Notifikasi"
            value={notifName || ''}
            onChange={(e) => setNotifName(e.target.value)}
          />
          <CategoryInput
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            categories={categories}
          />
        </div>
        <div className="notif-detail__second">
          <div className="textarea-input">
            <h3>Notifikasi</h3>
            <textarea
              placeholder="Tulis Template"
              value={notifMessage}
              onChange={(e) => setNotifMessage(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="notif-detail__third">
          <InputField
            label="No. Pesanan"
            placeholder="000000"
            value={notifOrder}
            onChange={(e) => setNotifOrder(e.target.value)}
          />
        </div>
        <div className="notif-detail__button">
          <Button variant="outline" width={313} size="sm" onClick={() => setShowDetail('list')}>
            Kembali
          </Button>
          <Button variant="primary" width={313} size="sm" style={{ marginLeft: '16px' }}>
            Simpan
          </Button>
        </div>
      </TableWrapper>
    </div>
  );
};

export default NotificationDetail;
