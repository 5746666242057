import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const downloadInvoice = createAsyncThunk('download/invoice', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { transactionKey, orderKey } = payload;
  // console.log(payload);

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/orders/${transactionKey}/invoice`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = `invoice-${orderKey}.pdf`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});
