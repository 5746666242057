import React, { useEffect } from 'react';
import { TableWrapper, Table } from 'components/Global';
import { ReactComponent as GarageFilledIcon } from 'icons/garage-filled-icon.svg';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { deleteGarages, getAllGarages } from 'features/garages/actions';
import { resetGarageAdditionalSetting, resetGarageDetail } from 'features/garages/slice';
import ButtonAdd from 'components/Global/ButtonAdd';
import { useNavigate } from 'react-router-dom';

const GarageComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showToast, setShowSpinner, setShowConfirmation } = useAppContext();
  const { data, isLoading } = useSelector((state) => state.garages);

  const column = [
    { header: 'No.', value: 'no' },
    { header: 'Nama Garasi', value: 'name' },
    { header: 'Lokasi rental', value: 'location_name' },
    { header: 'Alamat Garasi', value: 'address_details' },
    { header: 'Jam Buka', value: 'start_time' },
    { header: 'Jam  Tutup', value: 'end_time' },
  ];

  useEffect(() => {
    dispatch(getAllGarages());
  }, []);

  // SHOW SPINNER WHEN LOADING
  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  const methods = {
    handleDeleteGarage: (e) => {
      const garagesId = e.target.parentElement.parentElement.dataset['key'];
      if (!garagesId) return;

      setShowConfirmation({
        message: 'Apakah anda yakin ingin menghapus Garasi',
        show: true,
        onClick: async () => {
          try {
            const res = await dispatch(deleteGarages(garagesId));
            if (res?.type.includes('fulfilled')) {
              showToast({ type: 'success', message: 'Berhasil Menghapus Garasi' });
              return;
            }
            showToast({ type: 'error', message: 'Gagal Menghapus Garasi' });
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            showToast({ type: 'error', message: 'Terjadi Kesalahan' });
          }
        },
      });
    },

    handleSelectDetail: async (e) => {
      const garagesId = e.target.parentElement.parentElement.dataset['key'];
      dispatch(resetGarageDetail());
      dispatch(resetGarageAdditionalSetting());
      navigate(`/garages/detail?id=${garagesId}`);
    },
  };

  return (
    <div className="garage">
      <div>
        <ButtonAdd
          onClick={() => {
            dispatch(resetGarageDetail());
            dispatch(resetGarageAdditionalSetting());
            navigate('/garages/add-item');
          }}
          label="Tambah Garasi"
        />
        <TableWrapper icon={<GarageFilledIcon fill="#009EF7" width="25px" height="25px" />} title="Garasi">
          <Table
            column={column}
            data={data}
            actionBtn
            onEdit={methods.handleSelectDetail}
            onDel={methods.handleDeleteGarage}
          />
        </TableWrapper>
      </div>
    </div>
  );
};

export default GarageComponent;
