import { createSlice } from '@reduxjs/toolkit';
import {
  getAllSchedulePriceValue,
  createSchedulePriceValue,
  updateSchedulePriceValue,
  deleteSchedulePriceValue,
} from './actions';

const initialState = {
  data: {},
  selectedHargaPotongan: {},
  isLoading: false,
  isError: false,
  errorMessage: '', // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const schedulePriceValue = createSlice({
  name: 'schedulePriceValue',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSchedulePriceValue.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getAllSchedulePriceValue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllSchedulePriceValue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createSchedulePriceValue.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(createSchedulePriceValue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createSchedulePriceValue.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateSchedulePriceValue.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(updateSchedulePriceValue.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateSchedulePriceValue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteSchedulePriceValue.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(deleteSchedulePriceValue.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteSchedulePriceValue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export default schedulePriceValue.reducer;
