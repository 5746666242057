import { Button, InputField, SelectFieldVehicle, TableWrapper } from 'components/Global';
import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as MinimalRentIcon } from 'icons/min-rent-icon.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRentalLocation } from 'features/rental-location/actions';
import { getVehiclesByFilter } from 'features/vehicle/action';
import {
  createBusinessRule,
  editBusinessRuleById,
  getBusinessRuleById,
  getServices,
} from 'features/business-rules/actions';
import { checkEmptyObject, checkPermission } from 'utils/functionality';
import { getFacilities, getSubServices } from 'features/business-rules/slice';
import { useAppContext } from 'components/Context/AppContext';
import { indonesianDateFormat, isEmpty } from 'utils/helpers';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import { addDays, format, parseISO } from 'date-fns';

const MinimumRentPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showToast, setShowSpinner } = useAppContext();
  const showWarning = (message) => showToast({ type: 'warning', message });

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const isEditPage = location.pathname === '/min-rent/edit-min-rent';

  const { services, subServices, selected: businessRuleDetail } = useSelector((state) => state.businessRules);
  const rentalLocation = useSelector((state) => state.rentalLocation.data);
  const allVehicle = useSelector((state) => state.vehicle.data);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  const [minimumRentData, setMinimumRentData] = useState({
    name: '',
    duration: 0,
    start_date: '',
    end_date: '',
  });

  const [startSchedulePriceDate, setStartSchedulePriceDate] = useState('');
  const [endSchedulePriceDate, setEndSchedulePriceDate] = useState('');

  const [selectedSubService, setSelectedSubService] = useState([]);
  const [editDataSubService, setEditDataSubService] = useState({
    toAdd: [],
    toRemove: [],
  });
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [editDataLocation, setEditDataLocation] = useState({
    toAdd: [],
    toRemove: [],
  });
  const [mappedLocationList, setMappedLocationList] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState([]);

  const backHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getAllRentalLocation());
    dispatch(getServices());
  }, []);

  useEffect(() => {
    if (!isEditPage) return;
    if (!id) return;

    dispatch(getBusinessRuleById(id));
  }, [id, isEditPage]);

  useEffect(() => {
    if (!services?.length) return;
    dispatch(getSubServices('sewa mobil'));
    dispatch(getFacilities());
  }, [services]);

  useEffect(() => {
    if (!selectedLocation || !selectedSubService) return;
    dispatch(
      getVehiclesByFilter({
        locationRental: selectedLocation.map((item) => item.id),
        supportDriver: selectedSubService.includes('with driver') ? 1 : 0,
        page: 1,
      }),
    );
  }, [selectedLocation, selectedSubService]);

  // Populate data value if is detail page
  useEffect(() => {
    if (!isEditPage) return;
    if (checkEmptyObject(businessRuleDetail)) return;
    if (businessRuleDetail.name === '' || businessRuleDetail.code_name === '') return;

    setStartSchedulePriceDate(new Date(businessRuleDetail.applied_start_date));
    setEndSchedulePriceDate(new Date(businessRuleDetail.applied_end_date));

    setMinimumRentData({
      name: businessRuleDetail.name,
      duration: businessRuleDetail.rule_threshold.min,
    });

    setSelectedLocation(
      businessRuleDetail?.business_rule_location?.map((item) => ({ ...item, value: item.name.toLowerCase() })) || [],
    );
  }, [businessRuleDetail, isEditPage]);

  // Map subservice value if detail page
  useEffect(() => {
    if (!subServices.length || checkEmptyObject(businessRuleDetail)) return;
    let newSelectedSubServicesArr = [];
    businessRuleDetail?.business_rule_subservices?.map((item) =>
      subServices.map((sub) =>
        sub.id == item.id
          ? newSelectedSubServicesArr.push(sub.name.toLowerCase())
          : sub.facilities.map((fac) =>
              fac.id == item.id ? newSelectedSubServicesArr.push(fac.name.toLowerCase()) : undefined,
            ),
      ),
    );
    setSelectedSubService(newSelectedSubServicesArr);
  }, [subServices, businessRuleDetail]);

  useEffect(() => {
    if (checkEmptyObject(businessRuleDetail) || !businessRuleDetail.business_rule_vehicle) return;

    const processVehicles = (vehicles) => {
      return vehicles.reduce((acc, vehicle) => {
        const existingVehicle = acc.find((item) => item.cars[0].name === vehicle.name);

        if (existingVehicle) {
          existingVehicle.cars.push({ ...vehicle, checked: true });
          existingVehicle.total += 1;
        } else {
          acc.push({ cars: [{ ...vehicle, checked: true }], total: 1 });
        }

        return acc;
      }, []);
    };

    const newSelectedCar = processVehicles(businessRuleDetail.business_rule_vehicle);
    setSelectedVehicle(newSelectedCar);
  }, [businessRuleDetail]);

  useEffect(() => {
    if (!rentalLocation?.length) return;
    const newSelectedLocationArr = rentalLocation.map((item) => ({ ...item, value: item.name.toLowerCase() }));
    setMappedLocationList(newSelectedLocationArr);
  }, [rentalLocation]);

  const handleTipeSewa = (typeId, status) => {
    const existedType = selectedSubService.find((item) => item === SERVICE_DATA.find((el) => el.id === typeId)?.value);

    if (existedType && status) return;

    const rentTypeValue = SERVICE_DATA.find((el) => el.id === typeId)?.value;
    const isExist = businessRuleDetail.business_rule_subservices?.find(
      (item) => item.name.toLowerCase() === rentTypeValue,
    );

    if (status) {
      setSelectedSubService((prevState) => [...prevState, rentTypeValue]);
      if (id) {
        setEditDataSubService((prev) => ({
          toAdd: isExist ? [...prev.toAdd] : [...prev.toAdd, rentTypeValue],
          toRemove: prev.toRemove.filter((item) => item !== rentTypeValue),
        }));
      }
    } else {
      const filteredData = selectedSubService.filter((item) => item !== rentTypeValue);
      setSelectedSubService(filteredData);
      if (id) {
        setEditDataSubService((prev) => ({
          toRemove: !isExist ? [...prev.toRemove] : [...prev.toRemove, rentTypeValue],
          toAdd: prev.toAdd.filter((item) => item !== rentTypeValue),
        }));
      }
    }
  };

  const handleLocation = (locationId, status) => {
    const existedType = selectedLocation?.find(
      (item) => item.id === rentalLocation.find((el) => el.id === locationId)?.id,
    );

    if (existedType && status) return;
    const locationValue = mappedLocationList?.find((el) => el.id === locationId);
    const isExist = businessRuleDetail.business_rule_location?.find((item) => item.id === locationValue.id);

    if (status) {
      setSelectedLocation(() => [
        ...selectedLocation,
        { id: locationValue.id, name: locationValue.name, value: locationValue.name.toLowerCase() },
      ]);

      if (id) {
        setEditDataLocation((prev) => ({
          toAdd: isExist ? [...prev.toAdd] : [...prev.toAdd, locationValue.id],
          toRemove: prev.toRemove.filter((item) => item !== locationValue.id),
        }));
      }
    } else {
      setSelectedLocation((prev) => prev.filter((item) => item.id !== locationValue.id));
      setSelectedVehicle([]);

      if (id) {
        setEditDataLocation((prev) => ({
          toRemove: !isExist ? [...prev.toRemove] : [...prev.toRemove, locationValue.id],
          toAdd: prev.toAdd.filter((item) => item !== locationValue.id),
        }));
      }
    }
  };

  const handleSelectedCar = (cars) => {
    setSelectedVehicle(cars);
  };

  const handleSubmit = async () => {
    let mappedVehicles = [];
    let mappedServices = [];

    if (isEmpty(minimumRentData.name)) {
      return showWarning('Harap isi nama dari setting!');
    }

    if (!selectedLocation.length) {
      return showWarning('Harap memilih lokasi!');
    }

    if (!selectedSubService.length) {
      return showWarning('Harap memilih jenis layanan!');
    }

    if (!selectedVehicle.length) {
      return showWarning('Harap memilih jenis mobil!');
    }

    if (minimumRentData.duration === 0) {
      return showWarning('Harap memilih minimal rental!');
    }

    if (minimumRentData.start_date === '') {
      return showWarning('Harap memilih tanggal mulai!');
    }

    if (minimumRentData.end_date === '') {
      return showWarning('Harap memilih tanggal selesai!');
    }

    selectedVehicle.map((item) => item.cars).map((cars) => cars.map((car) => mappedVehicles.push(car.id)));

    subServices.map((item) =>
      SERVICE_DATA.map((sub) =>
        sub.value == item.name.toLowerCase()
          ? mappedServices.push({ id: item.id, name: item.name })
          : item.facilities.map((fac) =>
              fac.name.toLowerCase() == sub.value ? mappedServices.push({ id: fac.id, name: fac.name }) : undefined,
            ),
      ),
    );

    let payload = {
      name: minimumRentData.name,
      applied_start_date: minimumRentData.start_date,
      applied_end_date: minimumRentData.end_date,
      // is_applied: true,
    };

    if (isEditPage && businessRuleDetail.name !== '') {
      payload = {
        ...payload,
        location_to_add: editDataLocation.toAdd,
        location_to_delete: editDataLocation.toRemove,
        vehicle_to_add: mappedVehicles?.filter(
          (item) => !businessRuleDetail.business_rule_vehicle?.find((item2) => item2.id == item),
        ),
        vehicle_to_del: businessRuleDetail.business_rule_vehicle
          ?.filter((item) => !mappedVehicles.includes(item.id))
          .map((item) => item.id),
        sub_service_to_add: editDataSubService.toAdd?.map(
          (item) => mappedServices?.find((sub) => sub.name.toLowerCase() == item).id,
        ),
        sub_service_to_del: editDataSubService.toRemove.map(
          (item) => mappedServices?.find((sub) => sub.name.toLowerCase() == item).id,
        ),
        threshold: {
          id: businessRuleDetail.rule_threshold.id,
          min: minimumRentData.duration,
          max: 0,
        },
      };
    } else {
      payload = {
        ...payload,
        code_name: 'Minimum Rental',
        locations_id: selectedLocation?.map((item) => item.id),
        vehicles_id: mappedVehicles,
        sub_services_id: selectedSubService?.map(
          (item) => mappedServices?.find((sub) => sub.name.toLowerCase() == item).id,
        ),
        threshold: { min: minimumRentData.duration, value_type: 'duration' },
      };
    }

    try {
      // if (isEditPage) {
      if (isEditPage && businessRuleDetail.name !== '') {
        setShowSpinner(true);
        await dispatch(editBusinessRuleById({ data: payload, id: id })).unwrap();
        showToast({
          type: 'success',
          message: 'Berhasil Melakukan Edit Data!',
        });
      } else {
        await dispatch(createBusinessRule(payload)).unwrap();
        showToast({
          type: 'success',
          message: 'Berhasil Menambahkan Data!',
        });
      }
      navigate('/min-rent');
    } catch (error) {
      if (isEditPage) {
        showToast({
          type: 'error',
          message: 'Gagal Melakukan Edit Data!',
        });
        return;
      }
      showToast({
        type: 'error',
        message: 'Gagal Menambahkan Data!',
      });
    } finally {
      setShowSpinner(false);
    }
  };

  const searchHandler = (value) => {
    const isWithDriver = selectedSubService.length === 1 && selectedSubService[0] === 'with_driver';
    const isWithAndWithoutDriver = selectedSubService.length === 2;

    setTimeout(() => {
      if (isWithAndWithoutDriver) {
        dispatch(getVehiclesByFilter({ locationIds: selectedLocation.map((item) => item.id), page: 1, name: value }));
      } else {
        dispatch(
          getVehiclesByFilter({
            supportDriver: isWithDriver ? true : false,
            locationIds: selectedLocation.map((item) => item.id),
            page: 1,
            name: value,
          }),
        );
      }
    }, 1000);
  };

  const intersectionAction = () => {
    // if last page then just return nothing
    if (allVehicle.pagination.page >= allVehicle.pagination.last_page) return;

    dispatch(
      getVehiclesByFilter({
        locationRental: selectedLocation.map((item) => item.id),
        supportDriver: selectedSubService.includes('with driver') ? 1 : 0,
        page: allVehicle.pagination.page + 1,
      }),
    );
    // dispatch(getAllVehicles(allVehicle.pagination.page + 1));
  };

  return (
    <>
      <div className="min-rent">
        <header className="min-rent__header" onClick={backHandler}>
          <LeftArrow />
          <p>Kembali</p>
        </header>
        <TableWrapper icon={<MinimalRentIcon fill="#009EF7" width="25px" height="25px" />} title="Minimal Rental">
          <div className="min-rent__wrapper">
            <h1 className="min-rent__title">Set Minimal Rental</h1>
            <div className="min-rent__input-group">
              <InputField
                label="Nama Minimal Rental"
                value={minimumRentData.name}
                onChange={(e) => setMinimumRentData({ ...minimumRentData, name: e.target.value })}
                placeholder="Masukan nama minimal rental"
                className="input-field"
              />

              <SelectFieldDropdown
                label="Lokasi"
                placeholder="Pilih Lokasi"
                data={mappedLocationList}
                value={selectedLocation}
                onChange={handleLocation}
                checkBox
                htmlFor="location"
                disable={!minimumRentData.name}
              />
              <SelectFieldDropdown
                label="Layanan"
                htmlFor="layanan"
                data={SERVICE_DATA}
                placeholder="Pilih Layanan"
                value={selectedSubService}
                onChange={handleTipeSewa}
                checkBox
                disable={!selectedLocation.length}
              />

              {/* VEHICLE */}
              <SelectFieldVehicle
                label="Mobil"
                htmlFor="mobil"
                placeholder="Cari Mobil.."
                data={allVehicle}
                selectedCar={selectedVehicle}
                onSelectCar={handleSelectedCar}
                intersectionAction={intersectionAction}
                className="min-rent__car-input"
                onSearchAction={searchHandler}
                disable={!selectedSubService.length || !selectedLocation.length}
              />

              <SelectFieldDropdown
                label="Lama Rental"
                htmlFor="lama-rental"
                data={lamaRental}
                placeholder="Pilih Minimal Rental"
                value={minimumRentData.duration}
                onChange={(e) => {
                  setMinimumRentData({ ...minimumRentData, duration: +e.target.id });
                }}
                disable={!selectedVehicle.length}
              />

              <div className="min-rent__periode">
                <h3>Periode Minimal Rental</h3>
                <div className="min-rent__periode-input-date">
                  <SelectFieldDate
                    htmlFor="tanggal-mulai"
                    name="tanggal-mulai"
                    placeholder="Pilih Tanggal Mulai"
                    selectedDay={startSchedulePriceDate}
                    value={
                      minimumRentData.start_date !== ''
                        ? startSchedulePriceDate !== ''
                          ? indonesianDateFormat(format(startSchedulePriceDate, 'yyyy-MM-dd').toString())
                          : indonesianDateFormat(format(new Date(minimumRentData.start_date), 'yyyy-MM-dd').toString())
                        : minimumRentData.start_date
                    }
                    handleDaySelect={(date) => {
                      if (date) {
                        setStartSchedulePriceDate(date);
                        setMinimumRentData({
                          ...minimumRentData,
                          start_date: `${addDays(date, 1).toISOString().slice(0, 10)}`,
                        });
                      }
                    }}
                    disable={minimumRentData.duration === 0}
                    dateContainerXOffset="right"
                  />
                  <SelectFieldDate
                    htmlFor="tanggal-selesai"
                    name="tanggal-selesai"
                    placeholder="Pilih Tanggal Selesai"
                    selectedDay={endSchedulePriceDate}
                    value={
                      minimumRentData.end_date !== ''
                        ? endSchedulePriceDate !== ''
                          ? indonesianDateFormat(format(endSchedulePriceDate, 'yyyy-MM-dd').toString())
                          : indonesianDateFormat(format(new Date(minimumRentData.end_date), 'yyyy-MM-dd').toString())
                        : minimumRentData.end_date
                    }
                    fromDate={addDays(
                      startSchedulePriceDate === '' ? parseISO(startSchedulePriceDate) : startSchedulePriceDate,
                      1,
                    )}
                    handleDaySelect={(date) => {
                      if (date) {
                        setEndSchedulePriceDate(date);
                        setMinimumRentData({
                          ...minimumRentData,
                          end_date: `${addDays(date, 1).toISOString().slice(0, 10)}`,
                        });
                      }
                    }}
                    disable={startSchedulePriceDate === '' ? true : false}
                    dateContainerXOffset="left"
                  />
                </div>
              </div>
            </div>
            <div className="min-rent__buttons">
              <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                Kembali
              </Button>

              {(!isEditPage || checkPermission(offCanvasMenu, currentMenu, 'update')) && (
                <Button size="sm" className="button" width={208} onClick={handleSubmit}>
                  Simpan
                </Button>
              )}
            </div>
          </div>
        </TableWrapper>
      </div>
    </>
  );
};

export default MinimumRentPage;

const SERVICE_DATA = [
  {
    id: 0,
    name: 'With Driver',
    value: 'with driver',
  },
  {
    id: 1,
    name: 'Without Driver',
    value: 'without driver',
  },
  // {
  //   id: 2,
  //   name: 'Airport Transfer',
  //   value: 'airport transfer',
  // },
];

const lamaRental = [
  {
    name: '1 Hari',
    id: 1,
  },
  {
    name: '2 Hari',
    id: 2,
  },
  {
    name: '3 Hari',
    id: 3,
  },
  {
    name: '4 Hari',
    id: 4,
  },
  {
    name: '5 Hari',
    id: 5,
  },
];
