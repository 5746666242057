import { createSlice } from '@reduxjs/toolkit';

import { downloadInvoice } from './actions';

const downloadSlice = createSlice({
  name: 'download',
  initialState: {
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadInvoice.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(downloadInvoice.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(downloadInvoice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default downloadSlice.reducer;
