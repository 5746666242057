import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
// import { ReactComponent as PlusIcon } from 'icons/plus-icon.svg';
import { ReactComponent as ZoneFilledIcon } from 'icons/zone-filled-icon.svg';
import { Button, TableWrapper } from 'components/Global';
import ZoneInputCard from 'components/Global/Card/ZoneInputCard';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteZoneById, getAllZone, getZoneDetailById, uploadZoneImage } from 'features/zone/actions';
import { resetZoneData } from 'features/zone/zoneSlice';
import { getAllCategories } from 'features/category/actions';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';

const ZoneDetail = ({ state, id, setShowConfirmation, showToast, setShowSpinner }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedZone = useSelector((state) => state.zone.selectedZone);
  const isLoading = useSelector((state) => state.zone.isLoading);
  const zones = useSelector((state) => state.zone.data);
  const rentalLocation = useSelector((state) => state.rentalLocation.data);
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [newZone, setNewZone] = useState({
    id: 0,
    name: '',
    location_id: 0,
    category: [],
    list: [],
  });
  const [savedZone, setSavedZone] = useState({});
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    if (!id) return;
    dispatch(getZoneDetailById(id));
  }, [id]);

  useEffect(() => {
    dispatch(getAllCategories({ page: 1 }));
  }, []);

  useEffect(() => {
    if (!rentalLocation) return;
    if (state === 'detail' || state === 'edit') {
      const location = rentalLocation?.find((item) => item.id == selectedZone.location_id);
      if (location) {
        setSelectedLocation(location.id);
      }
      setSavedZone(selectedZone);
    }
    if (state === 'create') {
      setNewZone({
        id: 0,
        name: '',
        location_id: 0,
        category: [],
        list: [],
      });
    }
  }, [state, rentalLocation, selectedZone, zones]);

  const backHandler = () => {
    dispatch(resetZoneData());
    navigate(-1);
  };

  const removeZoneHandler = (data) => {
    if (!data) return;
    setShowConfirmation({
      message: 'Apakah anda yakin ingin menghapus Zona ini?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deleteZoneById(+data.id)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Menghapus Data Zona' });
          navigate('/zone');
        } catch (error) {
          showToast({ type: 'error', message: 'Gagal Menghapus Data Zona! - List Detail Zona Harus Kosong' });
        } finally {
          dispatch(getAllZone());
        }
      },
    });
  };

  const uploadImageHandler = async (image) => {
    try {
      const res = await dispatch(uploadZoneImage(image)).unwrap();
      return { name: image.name, url: res.file };
    } catch (error) {
      showToast({
        type: 'error',
        message: `Gagal Upload Gambar Zona - ${error}`,
      });
      return { name: '', url: '' };
    }
  };

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  return (
    <>
      <div className="zone-detail">
        <header className="zone-detail__header" onClick={backHandler}>
          <LeftArrow />
          <p>Kembali</p>
        </header>
        <TableWrapper
          icon={<ZoneFilledIcon fill="#009EF7" width="25px" height="25px" />}
          title={state === 'create' ? 'Tambah Zona' : state === 'edit' ? 'Edit Zona' : 'Detail Zona'}
        >
          <div className="zone-detail__content">
            <header className="zone-detail__content-title">
              <SelectFieldDropdown
                data={rentalLocation}
                label="Nama Kota / Daerah Sewa"
                placeholder="Pilih Daerah Sewa"
                disable={state === 'detail'}
                value={selectedLocation}
                onChange={(e) => setSelectedLocation(e.target.id)}
              />
            </header>
            <div className="zone-detail__content-body">
              <div className="zone-detail__content-list">
                {state === 'create' && (
                  <ZoneInputCard
                    data={newZone}
                    locationId={selectedLocation}
                    uploadAction={uploadImageHandler}
                    showImage={showImage}
                    setShowImage={setShowImage}
                  />
                )}
                {(state === 'edit' || state === 'detail') && (
                  <ZoneInputCard
                    data={savedZone}
                    removeHandler={() => removeZoneHandler(savedZone)}
                    locationId={selectedLocation}
                    uploadAction={uploadImageHandler}
                    showImage={showImage}
                    setShowImage={setShowImage}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="zone-detail__buttons">
            <Button variant="outline" className="button back-btn" width={208} size="sm" onClick={backHandler}>
              Kembali
            </Button>
          </div>
        </TableWrapper>
      </div>
    </>
  );
};

export default ZoneDetail;
