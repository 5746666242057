import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { resetCreateCity } from '../slice';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllCities = createAsyncThunk('cities/getAllCities', async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/cities`).then((res) => res);
    thunkAPI.dispatch(resetCreateCity());
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

export const getMasterCities = createAsyncThunk('cities/getMasterCities', async (provinceId, thunkAPI) => {
  try {
    const response = await axios
      .get(`${BASE_URL}/${API_VERSION}/masters/cities?limit=40&province_id=${provinceId}`)
      .then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

export const getMasterProvinces = createAsyncThunk('cities/getMasterProvinces', async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/masters/provinces`).then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

export const createCity = createAsyncThunk('cities/createCity', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    await axios.post(
      `${BASE_URL}/${API_VERSION}/cities`,
      { name: payload.name },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );

    thunkAPI.dispatch(getAllCities());
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const deleteCity = createAsyncThunk('cities/deleteCity', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    await axios.delete(`${BASE_URL}/${API_VERSION}/cities/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    thunkAPI.dispatch(getAllCities());
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});
