import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardDashboardItem from 'components/Global/Card/CardDashboardItem';
import { ReactComponent as WithoutDriverIcon } from '../../../../icons/without-driver-white.svg';
import { ReactComponent as WithDriverIcon } from '../../../../icons/with-driver-white.svg';
import { ReactComponent as AirportTransferIcon } from '../../../../icons/airport-transfer-filled.svg';
import UserStats from './UserStats/userStats';
import UserTransactions from './userTransactions';
import TransactionStats from './transactionStats';
// import RentedCars from './RentedCars/rentedCars';
import { getUserStats } from 'features/user/actions';
import { getTrxMonthToMonth } from 'features/report/actions';
import { getEligibleVehicle } from 'features/vehicle/action';
import {
  getAirportTransferRevenue,
  getAirportTransferRevenueCount,
  getAirportTransferRevenuetotal,
  getAllRevenue,
  getAllRevenueCount,
  getAllRevenueTotal,
  getWithDriverRevenue,
  getWithDriverRevenueCount,
  getWithDriverRevenueTotal,
  getWithoutDriverRevenue,
  getWithoutDriverRevenueCount,
  getWithoutDriverRevenuetTotal,
} from 'features/revenue/actions';
import { setCurrency } from 'utils/functionality';
import { useAppContext } from 'components/Context/AppContext';
import {
  getTotalDeposit,
  getTotalDepositAirportTransfer,
  getTotalDepositWithDriver,
  getTotalDepositWithoutDriver,
} from 'features/deposit/actions';
import { getAllRentalLocation } from 'features/rental-location/actions';

const DashboardComponent = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const eligibleVehicle = useSelector((state) => state.eligibleVehicle);
  const allRevenue = useSelector((state) => state.allRevenue);
  const withDriverRevenue = useSelector((state) => state.withDriverRevenue);
  const withoutDriverRevenue = useSelector((state) => state.withoutDriverRevenue);
  const airportTransferRevenue = useSelector((state) => state.airportTransferRevenue);
  const userStats = useSelector((state) => state.userStats);
  const trxMonthToMonth = useSelector((state) => state.trxMonthToMonth);
  const totalDeposit = useSelector((state) => state.totalDeposit);
  const totalDepositWithDriver = useSelector((state) => state.totalDepositWithDriver);
  const totalDepositWithoutDriver = useSelector((state) => state.totalDepositWithoutDriver);
  const totalDepositAirport = useSelector((state) => state.totalDepositAirport);

  const { showToast } = useAppContext();

  useEffect(() => {
    if (!auth.data?.access_token) return;
    dispatch(getUserStats());
    dispatch(getEligibleVehicle());
    dispatch(getTrxMonthToMonth());
    dispatch(
      getAllRevenue({
        startDate: `${new Date().getFullYear()}-01-01`,
        endDate: `${new Date().getFullYear()}-12-31`,
      }),
    );
    dispatch(getWithDriverRevenue());
    dispatch(getWithoutDriverRevenue());
    dispatch(getAirportTransferRevenue());
    dispatch(getTotalDeposit());
    dispatch(getTotalDepositWithDriver());
    dispatch(getTotalDepositWithoutDriver());
    dispatch(getTotalDepositAirportTransfer());
    dispatch(getAllRentalLocation());
  }, [auth.data]);

  useEffect(() => {
    if (
      userStats.isError ||
      eligibleVehicle.isError ||
      trxMonthToMonth.isError ||
      allRevenue.isError ||
      withDriverRevenue.isError ||
      withoutDriverRevenue.isError ||
      airportTransferRevenue.isError
    ) {
      return showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }
  }, [
    userStats.isError,
    eligibleVehicle.isError,
    trxMonthToMonth.isError,
    allRevenue.isError,
    withDriverRevenue.isError,
    withoutDriverRevenue.isError,
    airportTransferRevenue.isError,
  ]);

  return (
    <div className="dashboard">
      <CardDashboardItem
        title={'Pendapatan Transaksi (Semua)'}
        value={allRevenue.total && setCurrency(allRevenue?.total)}
        filterAction={getAllRevenueTotal}
        filter
      />
      <CardDashboardItem
        title={'Jumlah Transaksi (Semua)'}
        value={allRevenue?.count}
        filterAction={getAllRevenueCount}
        filter
        filterAlign="end"
      />
      <CardDashboardItem
        title={'Deposit Tersimpan (Semua)'}
        value={totalDeposit.data.count && setCurrency(totalDeposit.data.count)}
        filterAction={getTotalDeposit}
        filter
        filterAlign="end"
      />
      <UserStats />
      <CardDashboardItem title={'Jumlah Mobil'} value={eligibleVehicle?.data?.total} />
      {/* <RentedCars /> */}
      <CardDashboardItem title={'Mobil Tersedia'} value={eligibleVehicle?.data?.eligible} />
      <div className="dashboard-transactions">
        <UserTransactions />
        <TransactionStats />
      </div>

      <div className="dashboard-order">
        <div className="header-logo">
          <div className="logo">
            <WithoutDriverIcon />
          </div>
          <h2>Lepas Kunci</h2>
        </div>
        <div className="items">
          <CardDashboardItem
            title={'Pendapatan Transaksi'}
            filterAction={getWithoutDriverRevenuetTotal}
            customFilterPosition="horizontal"
            filter
            value={withoutDriverRevenue.total && setCurrency(withoutDriverRevenue.total)}
          />
          <CardDashboardItem
            title={'Jumlah Transaksi'}
            filter
            filterAction={getWithoutDriverRevenueCount}
            filterAlign="end"
            customFilterPosition="vertical-reverse"
            value={withoutDriverRevenue?.count}
          />
          <CardDashboardItem
            title={'Deposit Tersimpan'}
            filterAction={getTotalDepositWithoutDriver}
            filter
            filterAlign="end"
            customFilterPosition="horizontal"
            value={totalDepositWithoutDriver.data.count && setCurrency(totalDepositWithoutDriver.data.count)}
          />
        </div>
      </div>

      <div className="dashboard-order">
        <div className="header-logo">
          <div className="logo">
            <WithDriverIcon />
          </div>
          <h2>Dengan Supir</h2>
        </div>
        <div className="items">
          <CardDashboardItem
            title={'Pendapatan Transaksi'}
            filterAction={getWithDriverRevenueTotal}
            customFilterPosition="horizontal"
            filter
            value={withDriverRevenue.total && setCurrency(withDriverRevenue.total)}
          />
          <CardDashboardItem
            title={'Jumlah Transaksi'}
            filter
            filterAlign="end"
            customFilterPosition="vertical-reverse"
            value={withDriverRevenue?.count}
            filterAction={getWithDriverRevenueCount}
          />
          <CardDashboardItem
            title={'Deposit Tersimpan'}
            filterAction={getTotalDepositWithDriver}
            filter
            filterAlign="end"
            value={totalDepositWithDriver.data.count && setCurrency(totalDepositWithDriver.data.count)}
            customFilterPosition="horizontal"
          />
        </div>
      </div>

      <div className="dashboard-order">
        <div className="header-logo">
          <AirportTransferIcon />
          <h2>Airport Transfer</h2>
        </div>
        <div className="items">
          <CardDashboardItem
            title={'Pendapatan Transaksi'}
            filterAction={getAirportTransferRevenuetotal}
            customFilterPosition="horizontal"
            filterPosition="top"
            filter
            value={airportTransferRevenue.total && setCurrency(airportTransferRevenue.total)}
          />
          <CardDashboardItem
            title={'Jumlah Transaksi'}
            filter
            filterAction={getAirportTransferRevenueCount}
            customFilterPosition="vertical"
            filterPosition="top"
            filterAlign="end"
            value={airportTransferRevenue?.count}
          />
          <CardDashboardItem
            title={'Deposit Tersimpan'}
            filterAction={getTotalDepositAirportTransfer}
            filter
            filterAlign="end"
            value={totalDepositAirport.data.count && setCurrency(totalDepositAirport.data.count)}
            customFilterPosition="horizontal"
            filterPosition="top"
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardComponent;
