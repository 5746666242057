/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as DepositFilledIcon } from 'icons/deposit-filled-icon.svg';
import { Button, InputField, TableWrapper } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import ReactDOM from 'react-dom';
import { convertImageToBase64, setCurrency } from 'utils/functionality';
import clsx from 'clsx';
import { fetchDepositDetail, fetchOrderById, updateDepositStatus, updateViolationOrder } from 'features/orders/actions';
import InputViolation from 'components/Global/InputViolation';
import { deleteViolationOrderById, fetchViolationsOrder } from 'features/violations';
import InputAdditionals from 'components/Global/InputAdditionals';
import { getDetailOrder } from 'features/orders/detailOrderSlice';
import { addAdditionalOrder, deleteAdditionalOrderById, fetchAdditionalsOrder } from 'features/additionals';
import { getDetailAccountBank } from 'features/banks/actions';
import PreviewImage from 'components/Modals/PreviewImage';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const limitSize = 1048576;

const DetailDepositOrder = () => {
  const { showToast, setShowConfirmation } = useAppContext();
  const data = useSelector((state) => state.depositOrder?.depositDetail);
  const orderData = useSelector(getDetailOrder);
  const accountBank = useSelector((state) => state.banks?.detailAccountBank);
  const violationsData = useSelector((state) => state.violations?.data);
  const additionalsData = useSelector((state) => state.additionals?.data);
  const [violationList, setViolationList] = useState([]);
  const [additionalList, setAdditionalList] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [image, setImage] = useState(null);
  const [imageBase64, setImageBase64] = useState('');
  // total price, value that will be added to total payment when updating
  const [totalPrice, setTotalPrice] = useState(0);
  // violation price, value to display total violation price
  const [violationPrice, setViolationPrice] = useState(0);
  const [additionalPrice, setAdditionalPrice] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const targetRef = useRef(null);
  const [searchParams] = useSearchParams();
  const { transactionKey } = useParams();
  const customerId = searchParams.get('customerId');

  useEffect(() => {
    if (transactionKey) {
      dispatch(fetchDepositDetail(transactionKey));
      dispatch(fetchOrderById(transactionKey));
      dispatch(fetchAdditionalsOrder(transactionKey));
      dispatch(fetchViolationsOrder(transactionKey));
      dispatch(getDetailAccountBank(customerId));
    }
  }, [transactionKey]);

  useEffect(() => {
    if (Object.keys(violationsData).length > 0) {
      if (violationsData !== null) {
        setViolationPrice(() => violationsData?.data.map((item) => item.cost).reduce((acc, cVal) => acc + cVal, 0));
      }
    }
    if (violationList.length > 0) {
      setTotalPrice(() => violationList.map((item) => item.cost).reduce((acc, cVal) => acc + cVal, 0));
      return;
    }
    setTotalPrice(0);
  }, [violationList, violationsData]);

  useEffect(() => {
    if (Object.keys(additionalsData).length > 0) {
      if (additionalsData !== null) {
        setAdditionalPrice(() => additionalsData?.data.map((item) => item.value).reduce((acc, cVal) => acc + cVal, 0));
      }
    }
    if (additionalList.length > 0) {
      setTotalPrice(() => additionalList.map((item) => item.value).reduce((acc, cVal) => acc + cVal, 0));
      return;
    }
    setTotalPrice(0);
  }, [additionalList, additionalsData]);

  const handleProcess = () => {
    const isOnDebt = data?.total - data?.amount_deposit_used < 0;
    let nextStatus;
    let confirmationMessage;
    let toastSuccessMessage;
    let toastErrorMessage;

    switch (data?.status) {
      case 'CREATED':
        if (isOnDebt) {
          nextStatus = 'ON_DEBT';
          confirmationMessage = 'Apakah anda yakin ingin melanjutkan proses deposit?';
          toastSuccessMessage = 'Deposit Berhasil di update';
          toastErrorMessage = 'Deposit Gagal Di update!';
          break;
        }
        nextStatus = 'PROCESSED';
        confirmationMessage = 'Apakah anda yakin ingin melanjutkan proses deposit?';
        toastSuccessMessage = 'Deposit Berhasil di update';
        toastErrorMessage = 'Deposit Gagal Di update!';
        break;
      case 'ON_DEBT':
        nextStatus = 'TRANSFERED';
        confirmationMessage = 'Apakah anda yakin ingin melanjutkan proses deposit?';
        toastSuccessMessage = 'Deposit Berhasil di update';
        toastErrorMessage = 'Deposit Gagal Di update!';
        break;
      default:
        throw new Error('order status not found!');
    }

    let payload = {
      id: data?.id,
      status: nextStatus,
    };

    if (data?.status === 'CREATED') {
      if (isOnDebt) {
        payload = {
          ...payload,
          debt: Math.abs(data?.total - data?.amount_deposit_used),
        };
      } else {
        payload = {
          ...payload,
          amount_to_transfer: Math.abs(data?.total - data?.amount_deposit_used),
        };
      }
    } else if (data?.status === 'ON_DEBT') {
      payload = {
        ...payload,
        proof_of_debt_transfer: imageBase64?.file,
        amount_to_transfer: 0,
      };
    }

    setShowConfirmation({
      message: confirmationMessage,
      show: true,
      onClick: async () => {
        try {
          await dispatch(updateDepositStatus(payload)).unwrap();
          showToast({ type: 'success', message: toastSuccessMessage });
          navigate(-1);
        } catch (err) {
          showToast({ type: 'error', message: toastErrorMessage });
        }
      },
    });
  };

  /**
   * komponen ini untuk merender tombol Proses di modal detail order
   * untuk tabel Konfirmasi dan tabel Belum Diproses saja
   */
  const RenderedProcessButton = () => {
    const showButtonForCreated = data.status === 'CREATED';
    const showButtonForInDebt = data.status === 'ON_DEBT';

    if (showButtonForCreated) {
      return (
        <Button variant="success" className="button" width={208} size="sm" onClick={handleProcess}>
          Proses Deposit
        </Button>
      );
    } else if (showButtonForInDebt) {
      return (
        <Button
          variant="primary"
          className="button"
          width={208}
          size="sm"
          onClick={handleProcess}
          disabled={!imageBase64}
        >
          Konfirmasi Pelunasan
        </Button>
      );
    } else {
      return null;
    }
  };

  const backHandler = () => {
    navigate(-1);
  };

  const imagePreviewHandler = (image) => {
    setShowImage(true);
    setSelectedImage(image);
  };

  const getFileName = (path = '') => (typeof path === 'string' ? path?.split('/')?.pop() : '-');

  const getURLDestination = async (transaction_key) => {
    const data = await dispatch(fetchOrderById(transaction_key));

    let orderType = '';

    if (data?.payload?.order_type_id === 1) {
      if (data?.payload?.order_detail.without_driver) {
        orderType = 'without-driver';
      } else {
        orderType = 'with-driver';
      }
    } else if (data?.payload?.order_type_id === 2) {
      orderType = 'airport-transfer';
    }

    return orderType;
  };

  const handleNavigate = async () => {
    const urlDestination = await getURLDestination(orderData?.transaction_key);
    navigate({
      pathname: `/${urlDestination}/detail/${orderData?.transaction_key}`,
      search: createSearchParams({
        customerId: customerId,
      }).toString(),
    });
  };

  const handleConfirmViolation = async (payload) => {
    try {
      await dispatch(
        updateViolationOrder({
          ...payload,
          transaction_key: transactionKey,
          total_payment: payload.violations?.map((item) => item.cost).reduce((acc, cVal) => acc + cVal, 0),
        }),
      ).unwrap();
      showToast({ type: 'success', message: 'Biaya Denda Berhasil Ditambahkan' });
    } catch (err) {
      showToast({ type: 'error', message: 'Biaya Denda Gagal Ditambahkan' });
    } finally {
      dispatch(fetchViolationsOrder(transactionKey));
      dispatch(fetchDepositDetail(transactionKey));
    }
  };

  const handleDeleteViolation = (id) => {
    setShowConfirmation({
      message: 'Apakah anda yakin ingin menghapus data?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deleteViolationOrderById(id)).unwrap();
          showToast({ type: 'success', message: 'Biaya Denda Berhasil Dihapus' });
        } catch (err) {
          showToast({ type: 'error', message: 'Biaya Denda Gagal Dihapus' });
        } finally {
          dispatch(fetchViolationsOrder(transactionKey));
          dispatch(fetchDepositDetail(transactionKey));
        }
      },
    });
  };

  const handleConfirmAdditional = async (payload) => {
    try {
      await dispatch(addAdditionalOrder({ ...payload, transaction_key: transactionKey })).unwrap();
      showToast({ type: 'success', message: 'Biaya Additional Berhasil Ditambahkan' });
    } catch (err) {
      showToast({ type: 'error', message: 'Biaya Additional Gagal Ditambahkan' });
    } finally {
      dispatch(fetchAdditionalsOrder(transactionKey));
      dispatch(fetchDepositDetail(transactionKey));
    }
  };

  const handleDeleteAdditional = async (id) => {
    setShowConfirmation({
      message: 'Apakah anda yakin ingin menghapus data?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deleteAdditionalOrderById(id)).unwrap();
          showToast({ type: 'success', message: 'Biaya Additional Berhasil Dihapus' });
        } catch (err) {
          showToast({ type: 'error', message: 'Biaya Additional Gagal Dihapus' });
        } finally {
          dispatch(fetchAdditionalsOrder(transactionKey));
          dispatch(fetchDepositDetail(transactionKey));
        }
      },
    });
  };

  const onImageChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newImages = e.target.files[0];
    // check if there are files
    if (newImages) {
      uploadImages(newImages, limitSize);
    }
  };

  const uploadImages = async (file, limitSize) => {
    if (file.size >= limitSize) {
      showToast({ type: 'error', message: 'Ukuran File Terlalu Besar' });
      return;
    }

    const newObjImages = {
      url: URL.createObjectURL(file),
      name: `rentcar/development/cars/cars/${
        file.name.split(' ').length > 1 ? file.name.split(' ').join('-') : file.name
      }`,
      id: Math.random() + 1 + '', // id sementara
      show: false,
    };

    setImage(newObjImages);

    // upload image
    // if base 64 is true
    // if (base64) {
    const convertToBase64Image = await convertImageToBase64(file);
    // uploadAction(convertToBase64Image, 'upload-image-on-debt', newObjImages.url);
    setImageBase64(convertToBase64Image);
    // return;
    // }

    // if bas64 false
    // uploadAction(file, htmlFor);
  };

  return (
    <>
      <div className="detail-deposit-order">
        <div className="detail-deposit-order__back-btn" onClick={backHandler}>
          <LeftArrow />
          <p>Kembali</p>
        </div>
        <TableWrapper icon={<DepositFilledIcon fill="#009EF7" width="25px" height="25px" />} title="Deposit">
          <div className="detail-deposit-order__detail">
            <div className="detail-deposit-order__detail-title">
              <h1>Detail Deposit</h1>
            </div>
            <div className="detail-deposit-order__detail-body">
              <InputField
                label="Nama Lengkap"
                disabled
                value={
                  orderData?.is_admin_creation
                    ? orderData?.disbursement?.sender_name
                    : accountBank?.AccountBank?.nama_rek || '-'
                }
              />

              <div className="g-input input-order-key">
                <label className="input-text-title" style={{ textAlign: 'left' }}>
                  Kode Order
                </label>
                <section className="field-wrapper" style={{ margin: 0 }}>
                  <div className="input-text-field" onClick={handleNavigate}>
                    {orderData?.order_key || '-'}
                  </div>
                </section>
              </div>

              <InputField
                label="Nama Bank"
                disabled
                value={
                  orderData?.is_admin_creation
                    ? orderData?.disbursement?.sender_bank_name
                    : accountBank?.AccountBank?.nama_bank || '-'
                }
              />
              <InputField
                label="No Rekening"
                disabled
                value={
                  orderData?.is_admin_creation
                    ? orderData?.disbursement?.payment?.code
                    : accountBank?.AccountBank?.no_rek || '-'
                }
              />
              <InputField label="Deposit e-Toll" disabled value={setCurrency(data?.deposit_e_toll) || '-'} />

              <div className="detail-deposit-order__detail-body__preview-image">
                <InputField
                  label="Bukti transfer denda"
                  disabled
                  value={data?.proof_of_bank_transfer || '-'}
                  className="preview-image-input disabled"
                />
                {data?.proof_of_bank_transfer ? (
                  <Button
                    bgColor="#0084FF"
                    textColor="#FFFFFF"
                    className="preview-image-btn"
                    onClick={() =>
                      imagePreviewHandler(`${IMAGE_BASE_URL}/order/disbursements/${data?.proof_of_bank_transfer}`)
                    }
                  >
                    Lihat
                  </Button>
                ) : null}
              </div>

              <InputField label="Booking Deposit" disabled value={setCurrency(data?.booking_deposit) || '-'} />

              {data?.status !== 'CREATED' ? (
                <div className="detail-deposit-order__detail-body__preview-image">
                  <InputField
                    label="Bukti transfer hutang"
                    disabled
                    value={
                      data?.status === 'ON_DEBT'
                        ? image !== null
                          ? getFileName(image?.name)
                          : 'No file chosen'
                        : data?.proof_of_debt_transfer || '-'
                    }
                    className={clsx('preview-image-input', data?.status !== 'ON_DEBT' && 'disabled')}
                  />
                  {data?.proof_of_debt_transfer ? (
                    <Button
                      bgColor="#0084FF"
                      textColor="#FFFFFF"
                      className="preview-image-btn"
                      onClick={() =>
                        imagePreviewHandler(`${IMAGE_BASE_URL}/order/disbursements/${data?.proof_of_debt_transfer}`)
                      }
                    >
                      Lihat
                    </Button>
                  ) : data?.status === 'ON_DEBT' ? (
                    <>
                      <input
                        type="file"
                        name="upload-image-on-debt"
                        id="upload-image-on-debt"
                        accept="image/*"
                        hidden
                        ref={targetRef}
                        onChange={onImageChange}
                      />
                      <Button
                        bgColor="#0084FF"
                        textColor="#FFFFFF"
                        className="preview-image-btn"
                        onClick={() => targetRef.current.click()}
                      >
                        Upload
                      </Button>
                    </>
                  ) : null}
                </div>
              ) : null}

              <InputField label="Jumlah Deposit" disabled value={setCurrency(data?.total) || '-'} />
            </div>

            <div className="detail-deposit-order__violations-detail-body__input-violation">
              <InputViolation
                violationList={violationList}
                setTotalPrice={setTotalPrice}
                setViolationList={setViolationList}
                totalPrice={totalPrice}
                violationPrice={violationPrice}
                data={violationsData?.data || []}
                isOnEdit={data?.status === 'CREATED'}
                onConfirm={handleConfirmViolation}
                onDelete={handleDeleteViolation}
              />
            </div>

            <div className="detail-deposit-order__additionals-detail-body__input-additional">
              <InputAdditionals
                additionalList={additionalList}
                setAdditionalList={setAdditionalList}
                totalPrice={totalPrice}
                additionalPrice={additionalPrice}
                data={additionalsData?.data || []}
                isOnEdit={data?.status === 'CREATED'}
                onConfirm={handleConfirmAdditional}
                onDelete={handleDeleteAdditional}
              />
            </div>
          </div>

          <div className="detail-deposit-order__total-payment">
            <div className="detail-deposit-order__total-payment__total">
              <h4>Total</h4>
              <p>{setCurrency(data?.total)}</p>
            </div>
            <div className="detail-deposit-order__total-payment__total">
              <h4>Kurang Bayar</h4>
              <p>
                {setCurrency(
                  data?.total - data?.amount_deposit_used < 0 ? Math.abs(data?.total - data?.amount_deposit_used) : 0,
                )}
              </p>
            </div>
          </div>

          <div className="detail-deposit-order__transaction-buttons">
            <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
              Kembali
            </Button>

            <RenderedProcessButton />
          </div>
        </TableWrapper>
      </div>
      {showImage &&
        ReactDOM.createPortal(
          <PreviewImage image={selectedImage} setShow={() => setShowImage(false)} />,
          document.getElementById('modal'),
        )}
    </>
  );
};

export default DetailDepositOrder;
