import React from 'react';
import { ReactComponent as HistoryPromoFilledIcon } from 'icons/history-promo-filled-icon.svg';
import { PaginationTable, Table, TableWrapper } from 'components/Global';
import { useState } from 'react';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Promo', value: 'name' },
  { header: 'Jenis Mobil', value: 'vehicle' },
  { header: 'Lama Rental', value: 'rental_period' },
  { header: 'Harga', value: 'price' },
];

const History = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const historyData = {};

  return (
    <>
      {!historyData.data || !historyData.data.length ? (
        <div className="message">Data Kosong</div>
      ) : (
        <TableWrapper icon={<HistoryPromoFilledIcon fill="#009EF7" width="25px" height="25px" />} title="History">
          <Table column={column} actionBtn onDetail={() => {}} onDel={() => {}} />
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            // totalCount={checkoutOrder.pagination.total}
          />
        </TableWrapper>
      )}
    </>
  );
};

export default History;
