import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { format } from 'date-fns';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const fetchCheckoutOrder = createAsyncThunk('orders/checkoutOrder', async ({ page, order }, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.get(
      `${BASE_URL}/${API_VERSION}/orders?page=${page}&limit=10&status=CHECKOUT&order_by=created_at&order_seq=${order}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchReconfirmationOrder = createAsyncThunk(
  'orders/reconfirmationOrder',
  async ({ page, order }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const res = await axios.get(
        `${BASE_URL}/${API_VERSION}/orders?page=${page}&limit=10&status=RECONFIRMATION&order_by=created_at&order_seq=${order}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const fetchPaidOrder = createAsyncThunk('orders/paidOrder', async ({ page, order }, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.get(
      `${BASE_URL}/${API_VERSION}/orders?page=${page}&limit=10&status=PAID&order_by=created_at&order_seq=${order}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchOngoingOrder = createAsyncThunk('orders/ongoingOrder', async ({ page, order }, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.get(
      `${BASE_URL}/${API_VERSION}/orders?page=${page}&limit=10&status=COMPLETED&order_by=created_at&order_seq=${order}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchFinishedOrder = createAsyncThunk('orders/finishedOrder', async ({ page, order }, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.get(
      `${BASE_URL}/${API_VERSION}/orders?page=${page}&limit=10&status=FINISHED&order_by=created_at&order_seq=${order}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const changeOrderStatus = createAsyncThunk('orders/processOrder', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  const data =
    payload.nextStatus === 'COMPLETED'
      ? payload?.airport_package_vehicle_id
        ? {
            order_status: payload.nextStatus,
            courier_id: payload.courier_id,
            airport_package_vehicle_id: payload.airport_package_vehicle_id,
          }
        : { order_status: payload.nextStatus, courier_id: payload.courier_id }
      : { order_status: payload.nextStatus };

  const config = {
    method: 'put',
    url: `${BASE_URL}/${API_VERSION}/orders/${payload.transaction_key}`,
    headers: { Authorization: `Bearer ${accessToken}` },
    data,
  };

  try {
    const response = await axios(config);

    const params = {
      page: 1,
      order: 'DESC',
    };

    switch (payload.prevStatus) {
      case 'CHECKOUT':
        thunkAPI.dispatch(fetchCheckoutOrder(params));
        break;
      case 'PAID':
        thunkAPI.dispatch(fetchPaidOrder(params));
        break;
      case 'COMPLETED':
        thunkAPI.dispatch(fetchOngoingOrder(params));
        break;
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const rejectOrder = createAsyncThunk('orders/rejectOrder', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  const data = { order_status: 'REJECTED', message: payload.message };

  const config = {
    method: 'put',
    url: `${BASE_URL}/${API_VERSION}/orders/${payload.transaction_key}`,
    headers: { Authorization: `Bearer ${accessToken}` },
    data,
  };

  try {
    const response = await axios(config);

    thunkAPI.dispatch(
      fetchCheckoutOrder({
        page: 1,
        order: 'DESC',
      }),
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const rejectBankTransferPayment = createAsyncThunk('orders/rejectPayment', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  const data = { order_status: 'RECONFIRMATION', message: payload.message };

  const config = {
    method: 'put',
    url: `${BASE_URL}/${API_VERSION}/orders/${payload.transaction_key}`,
    headers: { Authorization: `Bearer ${accessToken}` },
    data,
  };

  try {
    const response = await axios(config);

    thunkAPI.dispatch(
      fetchCheckoutOrder({
        page: 1,
        order: 'DESC',
      }),
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const fetchDepositDetail = createAsyncThunk('orders/fetchDepositDetail', async (transaction_key, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.get(`${BASE_URL}/${API_VERSION}/admin/orders/${transaction_key}/deposits`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchOrderById = createAsyncThunk('orders/fetchOrderById', async (transaction_key, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.get(`${BASE_URL}/${API_VERSION}/admin/orders/${transaction_key}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchOrderByIdTaskAdmin = createAsyncThunk(
  'orders/fetchOrderByIdTaskAdmin',
  async ({ transaction_key, approval_id }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const res = await axios.get(
        `${BASE_URL}/${API_VERSION}/admin/orders/${transaction_key}?approval_id=${approval_id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const fetchOrderByIdWithApproval = createAsyncThunk(
  'orders/fetchOrderByIdWithApproval',
  async (transaction_key, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const res = await axios.get(`${BASE_URL}/${API_VERSION}/admin/orders/${transaction_key}?check_approval=1`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const createDepositConfirmation = createAsyncThunk(
  'orders/createDepositConfirmation',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const res = await axios.post(
        `${BASE_URL}/${API_VERSION}/deposit-confirmations`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET CANCELLED ORDER
export const fetchCancelledOrder = createAsyncThunk('orders/fetchCancelledOrder', async ({ page, order }, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.get(
      `${BASE_URL}/${API_VERSION}/orders?page=${page}&limit=10&status=CANCELLED&order_by=created_at&order_seq=${order}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// GET CANCELLED ORDER DETAIL / ORDER CANCELATION
export const fetchOrderCancelation = createAsyncThunk(
  'orders/fetchOrderCancelation',
  async (transaction_key, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const res = await axios.get(`${BASE_URL}/${API_VERSION}/admin/orders/${transaction_key}/cancelation`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// UPDATE ORDER CANCELATION
export const updateOrderCancelation = createAsyncThunk('orders/updateOrderCancelation', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  const { transaction_key, status } = payload;

  try {
    const res = await axios.put(
      `${BASE_URL}/${API_VERSION}/admin/orders/${transaction_key}/cancelation`,
      { status },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    thunkAPI.dispatch(fetchCancelledOrder({ page: 1, order: 'DESC' }));

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// CREATE ORDER
export const createOrder = createAsyncThunk('orders/createOrder', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/admin/orders`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    const data = await response.data;

    return data.data.order.transaction_key;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// UPLOAD FILE KTP OR SIM
export const uploadFile = createAsyncThunk('orders/uploadFile', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  const { file, name } = payload;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/profile/document`,
      { file },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return {
      [name]: response.data?.file,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// GET ORDER SUMMARY
export const fetchSummaryOrder = createAsyncThunk('orders/fetchSummaryOrder', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios
      .post(
        `${BASE_URL}/${API_VERSION}/admin/orders/summary`,
        { ...payload },
        { headers: { Authorization: `Bearer ${accessToken}` } },
      )
      .then((res) => res);

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

// CREATE DISBURSEMENTS
export const postDisbursements = createAsyncThunk('order/postDisbursements', async (payload = {}, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(`${BASE_URL}/${API_VERSION}/disbursements`, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    /**
     * uncomment this if want to use payment using credit/debit card
     * and then make sure payment using manual transfer works just fine
     */

    // if (
    //   Object.prototype.hasOwnProperty.call(
    //     response.data.data.disbursement,
    //     'redirect_url'
    //   )
    // ) {
    //   window.open(response.data.data.disbursement.redirect_url);
    // }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('failed to create disbursement');
  }
});

// UPDATE ORDER CANCELATION
export const updateViolationOrder = createAsyncThunk('orders/updateViolationOrder', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  const { transaction_key, violations, total_payment } = payload;

  try {
    const res = await axios.put(
      `${BASE_URL}/${API_VERSION}/orders/${transaction_key}`,
      { violations: violations, total_payment },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteOrderById = createAsyncThunk('orders/deleteOrderById', async (transaction_key, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.delete(`${BASE_URL}/${API_VERSION}/orders/${transaction_key}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const changePaymentType = createAsyncThunk('orders/changePaymentType', async (transactionKey, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const config = {
    method: 'patch',
    url: `${BASE_URL}/${API_VERSION}/orders/${transactionKey}/dp`,
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  try {
    await axios(config);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchWithoutDriverOrder = createAsyncThunk(
  'orders/fetchWithoutDriverOrder',
  async (
    {
      page,
      order = 'DESC',
      status,
      orderBy = 'created_at',
      endDate,
      startDate,
      vehicleBrand,
      vehicleId,
      locationId,
      user_name,
      timeZoneIdentifier,
    },
    thunkAPI,
  ) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let mainUrl = `${BASE_URL}/${API_VERSION}/orders?page=${page}&limit=10&without_driver=1&order_type_id=1`;

    if (status !== undefined) {
      status.map((item) => {
        mainUrl += `&status[]=${item}`;
      });
    }

    if (locationId !== undefined) {
      locationId.map((item) => {
        mainUrl += `&location_id=${item}`;
      });
    }

    if (timeZoneIdentifier !== undefined) {
      mainUrl += `&time_zone_identifier=${timeZoneIdentifier}`;
    }

    if (order) {
      mainUrl += `&order_seq=${order}`;
    }

    if (orderBy) {
      mainUrl += `&order_by=${orderBy}`;
    }

    if (startDate) {
      mainUrl += `&start_date=${startDate}`;
    }

    if (endDate) {
      mainUrl += `&end_date=${endDate}`;
    }

    if (vehicleBrand) {
      mainUrl += `&vehicle_brand=${vehicleBrand}`;
    }

    if (vehicleId && vehicleId.length !== 0) {
      vehicleId.forEach((id) => {
        mainUrl += `&vehicle_id=${id}`;
      });
    }

    if (user_name !== undefined) {
      mainUrl += `&user_name=${user_name}`;
    }

    try {
      const res = await axios.get(mainUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const fetchWithDriverOrder = createAsyncThunk(
  'orders/fetchWithDriverOrder',
  async (
    {
      page,
      order = 'DESC',
      status,
      orderBy = 'created_at',
      endDate,
      startDate,
      vehicleBrand,
      vehicleId,
      locationId,
      user_name,
      timeZoneIdentifier,
    },
    thunkAPI,
  ) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let mainUrl = `${BASE_URL}/${API_VERSION}/orders?page=${page}&limit=10&with_driver=1&order_type_id=1`;

    if (order) {
      mainUrl += `&order_seq=${order}`;
    }

    if (orderBy) {
      mainUrl += `&order_by=${orderBy}`;
    }

    if (startDate) {
      mainUrl += `&start_date=${startDate}`;
    }

    if (endDate) {
      mainUrl += `&end_date=${endDate}`;
    }

    if (vehicleBrand) {
      mainUrl += `&vehicle_brand=${vehicleBrand}`;
    }

    if (vehicleId && vehicleId.length !== 0) {
      vehicleId.forEach((id) => {
        mainUrl += `&vehicle_id=${id}`;
      });
    }

    if (status !== undefined) {
      status.map((item) => {
        mainUrl += `&status[]=${item}`;
      });
    }

    if (locationId !== undefined) {
      locationId.map((item) => {
        mainUrl += `&location_id=${item}`;
      });
    }

    if (timeZoneIdentifier !== undefined) {
      mainUrl += `&time_zone_identifier=${timeZoneIdentifier}`;
    }

    if (user_name !== undefined) {
      mainUrl += `&user_name=${user_name}`;
    }

    try {
      const res = await axios.get(mainUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const fetchOrderBookingZoneById = createAsyncThunk(
  'orders/fetchOrderBookingZoneById',
  async (transaction_key, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const res = await axios.get(`${BASE_URL}/${API_VERSION}/orders/${transaction_key}/booking-zones`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const fetchAirportTransferOrder = createAsyncThunk(
  'orders/fetchAirportTransferOrder',
  async (
    {
      page,
      order = 'DESC',
      status,
      orderBy = 'created_at',
      endDate,
      startDate,
      vehicleBrand,
      // vehicleId,
      vehicleCategoryId,
      locationId,
      user_name,
      timeZoneIdentifier,
    },
    thunkAPI,
  ) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let mainUrl = `${BASE_URL}/${API_VERSION}/orders?page=${page}&limit=10&order_type_id=2`;

    if (status !== undefined) {
      status.map((item) => {
        mainUrl += `&status[]=${item}`;
      });
    }

    if (timeZoneIdentifier !== undefined) {
      mainUrl += `&time_zone_identifier=${timeZoneIdentifier}`;
    }

    if (locationId !== undefined) {
      locationId.map((item) => {
        mainUrl += `&location_id=${item}`;
      });
    }

    if (user_name !== undefined) {
      mainUrl += `&user_name=${user_name}`;
    }

    if (order) {
      mainUrl += `&order_seq=${order}`;
    }

    if (orderBy) {
      mainUrl += `&order_by=${orderBy}`;
    }

    if (startDate) {
      mainUrl += `&start_date=${startDate}`;
    }

    if (endDate) {
      mainUrl += `&end_date=${endDate}`;
    }

    if (vehicleBrand) {
      mainUrl += `&vehicle_brand=${vehicleBrand}`;
    }

    // if (vehicleId && vehicleId.length !== 0) {
    //   vehicleId.forEach((id) => {
    //     mainUrl += `&vehicle_id=${id}`;
    //   });
    // }

    if (vehicleCategoryId && vehicleCategoryId.length !== 0) {
      vehicleCategoryId.forEach((id) => {
        mainUrl += `&vehicle_category_id=${id}`;
      });
    }

    try {
      const res = await axios.get(mainUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getAirportPackageById = createAsyncThunk('vehicle/getAirportPackageById', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  const { id, pickupTrip } = payload;

  let mainUrl = `${BASE_URL}/${API_VERSION}/vehicle-airport-packages/${id}`;

  if (pickupTrip) {
    mainUrl = mainUrl + `?pickup_trip=${pickupTrip}`;
  }

  try {
    const response = await axios.get(mainUrl, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getVehicleAirportTransferPackage = createAsyncThunk(
  'vehicle/getVehicleAirportTransferPackage',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { id, limit = 10, page = 0, locationRental, pickupTrip } = payload;

    let mainUrl = `${BASE_URL}/${API_VERSION}/vehicle-airport-packages/${id}/vehicles?limit=${limit}&page=${page}`;

    if (pickupTrip) {
      mainUrl = mainUrl + `&pickup_trip=${pickupTrip}`;
    }

    if (locationRental) {
      mainUrl = mainUrl + `&location_id=${locationRental}`;
    }

    try {
      const response = await axios
        .get(mainUrl, { headers: { Authorization: `Bearer ${accessToken}` } })
        .then((res) => res);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const fetchSummaryAirportTransferOrder = createAsyncThunk(
  'orders/fetchSummaryAirportTransferOrder',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/${API_VERSION}/admin/orders/summary`,
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const fetchDepositOrder = createAsyncThunk(
  'orders/fetchDepositOrder',
  async ({ page, order, status }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let mainUrl = `${BASE_URL}/${API_VERSION}/deposits?page=${page}&limit=10&order_by=created_at&order_seq=${order}`;

    if (status !== undefined) {
      status.map((item) => {
        mainUrl += `&status[]=${item}`;
      });
    }

    try {
      const res = await axios.get(mainUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const updateDepositStatus = createAsyncThunk('orders/updateDepositStatus', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  const { id, status, debt, proof_of_debt_transfer, amount_to_transfer } = payload;

  try {
    const res = await axios.put(
      `${BASE_URL}/${API_VERSION}/admin/deposits/${id}`,
      { status, debt, proof_of_debt_transfer, amount_to_transfer },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    thunkAPI.dispatch(fetchCancelledOrder({ page: 1, order: 'DESC' }));

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getDepositReports = createAsyncThunk('orders/getDepositReports', async ({ status }, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  let mainUrl = `${BASE_URL}/${API_VERSION}/deposit-reports`;

  if (status !== undefined) {
    status.map((item, idx) => {
      mainUrl += `${idx == 0 ? '?' : '&'}status[]=${item}`;
    });
  } else if (status === undefined) {
    mainUrl += `?status[]=CREATED&status[]=PROCESSED&status[]=ON_DEBT&status[]=TRANSFERED`;
  }

  try {
    const res = await axios.get(mainUrl, {
      headers: { Authorization: `Bearer ${accessToken}` },
      responseType: 'blob',
    });
    const dateNow = format(new Date(), 'MM-dd-yyyy-hh-mm-ss');
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = `deposit-reports-${dateNow}.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const importDepositReports = createAsyncThunk('orders/importDepositReports', async (file, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    await axios.patch(`${BASE_URL}/${API_VERSION}/deposit-reports`, file, {
      headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'multipart/form-data' },
    });
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// GET ORDER SUMMARY CUSTOMER
export const fetchSummaryCustomerOrder = createAsyncThunk(
  'orders/fetchSummaryCustomerOrder',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios
        .post(
          `${BASE_URL}/${API_VERSION}/orders/summary`,
          { ...payload },
          { headers: { Authorization: `Bearer ${accessToken}` } },
        )
        .then((res) => res);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data.message);
    }
  },
);

export const getOrderAddOnsById = createAsyncThunk('orders/getOrderAddOnsById', async (transaction_key, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.get(`${BASE_URL}/${API_VERSION}/orders/${transaction_key}/addons`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
