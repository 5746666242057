import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as FileIcon } from 'icons/file-icon.svg';
import Thumbnail from './Thumbnail';
// import { uploadImageVehicle } from 'features/vehicle/action';
// import { useDispatch } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { convertImageToBase64 } from 'utils/functionality';

// limit size of image file in byte
const limitSize = 1048576;

const UploadImageCustomOrder = ({ imageFile, handleRemove, htmlFor, uploadAction, disable, base64 = false }) => {
  // const dispatch = useDispatch();

  // context
  const { showToast } = useAppContext();

  const targetRef = useRef(null);
  const [image, setImage] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // drag active state
  const [dragActive, setDragActive] = React.useState(false);

  // will set image list if Image files not empty
  useEffect(() => {
    if (!isFirstLoad) return;

    if (imageFile === undefined || imageFile === '') return;

    const data = {
      id: Math.random() + 1 + '',
      name: imageFile.name ? imageFile.name : imageFile,
      url: base64
        ? imageFile
        : imageFile.name
        ? process.env.REACT_APP_IMAGES + imageFile.name
        : process.env.REACT_APP_IMAGES + imageFile,
      show: false,
    };

    setImage(data);
    return;
  }, [imageFile]);

  // function to loop through Files and upload
  const uploadImages = async (file, limitSize) => {
    if (file.size >= limitSize) {
      showToast({ type: 'error', message: 'Ukuran File Terlalu Besar' });
      return;
    }

    const newObjImages = {
      url: URL.createObjectURL(file),
      name: `rentcar/development/cars/cars/${
        file.name.split(' ').length > 1 ? file.name.split(' ').join('-') : file.name
      }`,
      id: Math.random() + 1 + '', // id sementara
      show: false,
    };

    setImage(newObjImages);

    // upload image
    // if base 64 is true
    if (base64) {
      const convertToBase64Image = await convertImageToBase64(file);
      uploadAction(convertToBase64Image, htmlFor, newObjImages.url);
      return;
    }

    // if bas64 false
    uploadAction(file, htmlFor);
  };

  // handle drag events
  const handleDrag = function (e) {
    setIsFirstLoad(false);
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // add iamge to imagelist when file is dropped
  const handleDrop = function (e) {
    setIsFirstLoad(false);
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const newImages = e.dataTransfer.files[0];
      // check if there are files
      if (newImages) {
        uploadImages(newImages, limitSize);
      }
    }
  };

  // add image to imagelist when file is selected with click
  const onImageChange = (e) => {
    setIsFirstLoad(false);
    e.preventDefault();
    e.stopPropagation();
    const newImages = e.target.files[0];
    // check if there are files
    if (newImages) {
      uploadImages(newImages, limitSize);
    }
  };

  return (
    <div
      className={disable ? 'upload-image-custom-order__container disable' : 'upload-image-custom-order__container'}
      onDragEnter={handleDrag}
    >
      <input
        type="file"
        name="upload-image-custom-order"
        id={htmlFor ? htmlFor : 'upload-image-custom-order'}
        accept="image/*"
        hidden
        ref={targetRef}
        onChange={onImageChange}
      />
      <label className="button" htmlFor={htmlFor ? htmlFor : 'upload-image-custom-order'}>
        <div
          className={disable || dragActive ? 'button__field active' : 'button__field'}
          style={{ justifyContent: 'center' }}
        >
          <FileIcon />
          <p>
            Drop your image here, or{' '}
            <button type="button" onClick={() => targetRef.current.click()}>
              browse
            </button>
          </p>
        </div>
      </label>
      {image ? (
        <div
          className={
            dragActive
              ? 'upload-image-custom-order__container__thumbnails active'
              : 'upload-image-custom-order__container__thumbnails '
          }
          style={{ width: '95%' }}
        >
          <Thumbnail
            imageData={image}
            setState={setImage}
            image={image.url}
            show={image.show}
            id={image.id}
            handleRemove={handleRemove}
            onClick={() => targetRef.current.click()}
          />
        </div>
      ) : null}
      {dragActive && (
        <div
          className="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </div>
  );
};

export default React.memo(UploadImageCustomOrder);
