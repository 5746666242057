import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

// GET ALL SCHEDULE PRICE
export const getAllSchedulePrice = createAsyncThunk(
  'schedulePrice/getAllSchedulePrice',
  async ({ page = 1, type = 1 }, thunkAPI) => {
    // type 1 = schedule price daily
    // type 2 = schedule price airport transfer

    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.get(
        `${BASE_URL}/${API_VERSION}/schedule-price?page=${page}&limit=10&type_id=${type}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET SCHEDULE PRICE BY ID
export const getDetailSchedulePrice = createAsyncThunk('schedulePrice/getDetailSchedulePrice', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/schedule-price/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// CREATE SCHEDULE PRICE
export const createSchedulePrice = createAsyncThunk('schedulePrice/createSchedulePrice', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/schedule-price`,
      { ...payload },
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    const data = await response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// UPDATE SCHEDULE PRICE
export const updateSchedulePrice = createAsyncThunk('schedulePrice/updateSchedulePrice', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { payloadData, schedulePriceId } = payload;

  try {
    const response = await axios.put(
      `${BASE_URL}/${API_VERSION}/schedule-price/${schedulePriceId}`,
      { ...payloadData },
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    const data = await response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// DELETE SCHEDULE PRICE
export const deleteSchedulePrice = createAsyncThunk('schedulePrice/deleteSchedulePrice', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${BASE_URL}/${API_VERSION}/schedule-price/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// GET ALL SCHEDULE PRICE VALUE
export const getAllSchedulePriceValue = createAsyncThunk(
  'schedulePriceValue/getAllSchedulePriceValue',
  async (page = 1, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.get(`${BASE_URL}/${API_VERSION}/schedule-price-value?page=${page}&limit=10`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// CREATE SCHEDULE PRICE VALUE
export const createSchedulePriceValue = createAsyncThunk(
  'schedulePriceValue/createSchedulePriceValue',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/${API_VERSION}/schedule-price-value`,
        { ...payload },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const data = await response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// UPDATE SCHEDULE PRICE VALUE
export const updateSchedulePriceValue = createAsyncThunk(
  'schedulePriceValue/updateSchedulePriceValue',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { payloadData, schedulePriceValueId } = payload;

    try {
      const response = await axios.put(
        `${BASE_URL}/${API_VERSION}/schedule-price-value/${schedulePriceValueId}`,
        { ...payloadData },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const data = await response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// DELETE SCHEDULE PRICE
export const deleteSchedulePriceValue = createAsyncThunk(
  'schedulePriceValue/deleteSchedulePriceValue',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.delete(`${BASE_URL}/${API_VERSION}/schedule-price-value/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
