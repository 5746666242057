import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

// GET ALL PROMO
export const getAllPromo = createAsyncThunk('promo/getAllPromo', async (page = 1, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${URL}/${API_VERSION}/admin/promotions?limit=10&page=${page}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// GET PROMO DETAIL BY ID
export const getDetailPromo = createAsyncThunk('promo/getDetailPromo', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${URL}/${API_VERSION}/admin/promotions/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// CREATE PROMO
export const createPromo = createAsyncThunk('promo/createPromo', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${URL}/${API_VERSION}/admin/promotions`,
      { ...payload },
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    const data = await response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// DELETE PROMO
export const deletePromo = createAsyncThunk('promo/deletePromo', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${URL}/${API_VERSION}/admin/promotions/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// UPDATE PROMO
export const updatePromo = createAsyncThunk('promo/updatePromo', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { payloadData, promoId } = payload;

  try {
    const response = await axios.put(
      `${URL}/${API_VERSION}/admin/promotions/${promoId}`,
      { ...payloadData },
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    const data = await response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// UPLOAD IMAGE
export const uploadImagePromo = createAsyncThunk('promo/uploadImagePromo', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${URL}/${API_VERSION}/admin/promotions/upload`,
      { file: payload },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    const data = await response.data;
    return data.file;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
