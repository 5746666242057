import React, { useRef, useState } from 'react';
import Modals from '../Modals';
import { useAppContext } from 'components/Context/AppContext';
import { Button, InputField, SelectField } from 'components/Global';
import { ReactComponent as BannerIcon } from 'icons/banner-filled-icon.svg';
import { ReactComponent as TrashIcon } from 'icons/trash-icon.svg';
import { useDispatch } from 'react-redux';
import { uploadBannerImage } from 'features/banner/actions';

const AddBannerModal = () => {
  const targetRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);

  const { setShowAddBannerModal, setShowSpinner, showToast } = useAppContext();
  const [selectedImage, setSelectedImage] = useState({
    name: '',
    url: '',
    size: 0,
  });
  const [imageData, setImageData] = useState({
    start_date: '',
    end_date: '',
    image: '',
    sort: 0,
  });
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedImage.size >= 1000000) {
      showToast({
        message: 'Ukuran file tidak boleh lebih dari 1MB!',
        type: 'error',
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      setShowSpinner(true);
      await dispatch(uploadBannerImage({ payload: formData, data: imageData })).unwrap();
      // await dispatch(uploadBannerImage({ payload: formData })).unwrap();
      showToast({ type: 'success', message: 'Berhasil Menambahkan Data' });
    } catch (error) {
      showToast({ type: 'error', message: 'Gagal Menambahkan Banner' });
    } finally {
      setShowSpinner(false);
      setShowAddBannerModal(false);
    }
  };

  const handleClearImage = () => {
    setSelectedImage({ name: '', url: '', size: 0 });
    setFile(null);
  };

  const onImageChange = (e) => {
    e.preventDefault();
    const { files } = e.target;
    if (files.length === null) return;

    const image = files[0];
    setFile(image);
    const previewImage = URL.createObjectURL(image);
    setSelectedImage(() => ({ name: image?.name, url: previewImage, size: image?.size }));
  };

  return (
    <Modals
      setState={() => setShowAddBannerModal(false)}
      icon={<BannerIcon fill="#009EF7" width="25px" height="25px" />}
      title="Banner"
    >
      <form className="banner-modal" onSubmit={handleSubmit}>
        <div className="banner-modal__content">
          <div className="banner-modal__content--left">
            <div className="banner-modal__input-wrapper">
              <InputField
                value={imageData.start_date || ''}
                disabled={isChecked}
                required={!isChecked}
                label="Tanggal Mulai"
                placeholder="Pilih Tanggal Mulai"
                type="date"
                onChange={(e) => setImageData((prev) => ({ ...prev, start_date: e.target.value }))}
              />

              <div className="banner-modal__checkbox-periode">
                <input type="checkbox" name="period" id="period" onChange={(e) => setIsChecked(e.target.checked)} />
                <label htmlFor="period">Banner tidak berperiode</label>
              </div>
            </div>

            <div className="g-input banner-modal__input">
              <label htmlFor="banner" className="input-text-title">
                Banner
              </label>
              <section className="field-wrapper with-icon">
                <input
                  type="file"
                  name="upload-image"
                  id="upload-image"
                  accept="image/*"
                  hidden
                  ref={targetRef}
                  onChange={onImageChange}
                />
                <div className="upload__wrapper">
                  <div className="upload__header">
                    <div className="upload__header-left">
                      <button
                        className="upload__button"
                        onClick={(e) => {
                          e.preventDefault();
                          targetRef.current.click();
                        }}
                      >
                        Browse..
                      </button>
                      {selectedImage.name ? <p>{selectedImage.name}</p> : null}
                    </div>
                    {selectedImage.name ? (
                      <div className="upload__header-right" onClick={handleClearImage}>
                        <TrashIcon width="24px" height="24px" />
                      </div>
                    ) : null}
                  </div>
                  {selectedImage.url ? (
                    <figure className="upload__thumbnail">
                      <img src={selectedImage.url} alt="thumbnail-image" />
                    </figure>
                  ) : null}
                </div>
              </section>
            </div>
          </div>
          <div className="banner-modal__content--right">
            <InputField
              disabled={isChecked}
              required={!isChecked}
              value={imageData.end_date || ''}
              label="Tanggal Berakhir"
              placeholder="Pilih Tanggal Berakhir"
              type="date"
              onChange={(e) => setImageData((prev) => ({ ...prev, end_date: e.target.value }))}
            />
            <div className="banner-modal__input-promo">
              <div className="banner-modal__input-promo-label">
                <label htmlFor="choosePromo">Pilih Promo</label>
                <div className="banner-modal__checkbox-promo">
                  <input type="checkbox" name="period" id="promo" disabled />
                  <label htmlFor="promo">Hubungkan dengan promo</label>
                </div>
              </div>
              <SelectField data={DUMMY_PROMO} disabled />
            </div>
            <InputField
              value={imageData.sort || ''}
              label="Urutan Banner"
              placeholder="0"
              type="number"
              min={0}
              onChange={(e) => setImageData((prev) => ({ ...prev, sort: parseInt(e.target.value) }))}
            />
          </div>
        </div>
        <div className="about-us-buttons">
          <Button className="button" size="sm" width="192px" role="submit">
            Tambah
          </Button>
        </div>
      </form>
    </Modals>
  );
};

export default AddBannerModal;

const DUMMY_PROMO = [
  { id: '01', name: 'Promo 01' },
  { id: '02', name: 'Promo 02' },
  { id: '03', name: 'Promo 03' },
];
