import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllAboutUsImage = createAsyncThunk('aboutUs/getAllAboutUsImage', async (_, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  try {
    const response = await axios
      .get(`${BASE_URL}/${API_VERSION}/admin/about-us`, { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

export const createAboutUsImage = createAsyncThunk(
  'aboutUs/createAboutUsImage',
  async ({ payload, accessToken }, thunkAPI) => {
    try {
      await axios.post(
        `${BASE_URL}/${API_VERSION}/about-us`,
        { ...payload },
        { headers: { Authorization: `Bearer ${accessToken}` } },
      );
      thunkAPI.dispatch(getAllAboutUsImage());
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const uploadAboutUsImage = createAsyncThunk(
  'aboutUs/uploadAboutUsImage',
  async ({ payload, data }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;
    try {
      const response = await axios.post(`${BASE_URL}/${API_VERSION}/about-us/upload`, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      const uploadedImageString = response.data?.file;
      if (!uploadedImageString) return thunkAPI.rejectWithValue('Gagal Upload Image');

      thunkAPI.dispatch(createAboutUsImage({ payload: { ...data, image: uploadedImageString }, accessToken }));
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const deleteAboutUsImage = createAsyncThunk('aboutUs/deleteAboutUsImage', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    await axios.delete(`${BASE_URL}/${API_VERSION}/about-us/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});
