import React, { useEffect, useState } from 'react';
import Modals from '../Modals';
// import { ReactComponent as DeliveryLocationIcon } from 'icons/tab-delivery-icon.svg';
import { useAppContext } from 'components/Context/AppContext';
import { Button, InputField, SelectField } from 'components/Global';
import { ReactComponent as DeliveryLocationIcon } from 'icons/delivery-filled-logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDeliveryLocation,
  getAllDeliveryLocations,
  updateDeliveryLocation,
} from 'features/delivery-location/actions';
import { useSearchParams } from 'react-router-dom';
import { checkPermission } from 'utils/functionality';

const AddDeliveryLocationModal = () => {
  const { setShowAddDeliveryLocation, showAddDeliveryLocation, setShowSpinner, showToast } = useAppContext();
  const RENTAL_LOCATIONS = useSelector((state) => state.rentalLocation?.data);
  const { selectedLocation } = useSelector((state) => state.deliveryLocation);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const [locationData, setLocationData] = useState({
    location_id: 0,
    name: '',
    fee: 0,
    airport: false,
  });
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const locationId = searchParams.get('locationId');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (locationData.location_id === 0) {
      showToast({ type: 'warning', message: 'Anda belum memilih daerah sewa' });
      return;
    }

    const selectedMethod =
      showAddDeliveryLocation.method === 'add'
        ? createDeliveryLocation(locationData)
        : updateDeliveryLocation({ id: selectedLocation?.id, payload: locationData });

    try {
      setShowSpinner(true);
      await dispatch(selectedMethod).unwrap();

      showAddDeliveryLocation.method === 'add'
        ? showToast({ type: 'success', message: 'Berhasil Menambahkan Lokasi' })
        : showToast({ type: 'success', message: 'Berhasil Mengubah Data Lokasi' });
    } catch (error) {
      showAddDeliveryLocation.method === 'add'
        ? showToast({ type: 'error', message: 'Gagal Menambahkan Lokasi' })
        : showToast({ type: 'error', message: 'Gagal Mengubah Data Lokasi' });
    } finally {
      setShowSpinner(false);
      setShowAddDeliveryLocation({ show: false, method: 'add' });
      dispatch(getAllDeliveryLocations({ currentPage: searchParams.get('page'), id: locationId }));
    }
  };

  useEffect(() => {
    if (showAddDeliveryLocation.method === 'add') return;
    setLocationData(() => ({
      location_id: selectedLocation?.location_id,
      name: selectedLocation?.name,
      fee: selectedLocation?.fee,
      airport: selectedLocation?.airport,
    }));
  }, [selectedLocation]);

  return (
    <Modals
      setState={() => setShowAddDeliveryLocation({ show: false, method: 'add' })}
      icon={<DeliveryLocationIcon fill="#009EF7" width="25px" height="25px" />}
      title="Lokasi Pengantaran"
    >
      <form className="delivery-location-modal" onSubmit={handleSubmit}>
        <div className="delivery-location-content">
          <div className="content--left">
            <div className="select-wrapper">
              <SelectField
                value={locationData.location_id || ''}
                label="Daerah Sewa"
                data={RENTAL_LOCATIONS}
                placeholder="Pilih Daerah Sewa"
                required
                className="select-input"
                onChange={(e) => setLocationData((prev) => ({ ...prev, location_id: parseInt(e.target.value) }))}
              />

              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name="airport"
                  id="airport"
                  onChange={(e) => setLocationData((prev) => ({ ...prev, airport: e.target.checked }))}
                  checked={locationData.airport ?? false}
                />
                <label htmlFor="airport">Airport</label>
              </div>
            </div>
            <InputField
              label="Harga Tambahan"
              htmlFor="additional-price"
              className="jumlah-input"
              type="number"
              price
              placeholder="0"
              value={locationData.fee ?? 0}
              onChange={(e) => setLocationData((prev) => ({ ...prev, fee: parseInt(e.target.value) }))}
              min={0}
            />
          </div>
          <div className="content--right">
            <InputField
              label="Nama Tempat"
              htmlFor="nama-tempat"
              className="name-input"
              autoComplete="off"
              required
              placeholder="Tulis Nama Tempat"
              value={locationData.name || ''}
              onChange={(e) => setLocationData((prev) => ({ ...prev, name: e.target.value }))}
            />
          </div>
        </div>
        <div className="delivery-location-buttons">
          {(showAddDeliveryLocation.method === 'add' || checkPermission(offCanvasMenu, currentMenu, 'update')) && (
            <Button width={192} style={{ marginLeft: 'auto' }} role="submit">
              {showAddDeliveryLocation.method === 'add' ? 'Tambah' : 'Simpan'}
            </Button>
          )}
        </div>
      </form>
    </Modals>
  );
};

export default AddDeliveryLocationModal;
