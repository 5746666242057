import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import clsx from 'clsx';
import CheckboxField from '../CheckboxField';
import { getSearchVehicleByCategory, getVehicleByCategory } from 'features/category/actions';

const baseUrl = process.env.REACT_APP_IMAGES;

const SelectFieldAirportTransferCheck = ({
  label,
  id,
  name,
  className,
  style,
  value,
  onChange,
  selectedCar,
  onSelectCar,
  placeholder,
  data,
  intersectionAction,
  disable,
  reset = false,
  setReset = () => {},
}) => {
  const dispatch = useDispatch();

  const dropdownRef = useRef(null);
  const { entry, ref: entryRef } = useInView();

  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isIntersectionLoading, setIsIntersectionLoading] = useState(false);

  // FUNCTION TOGGLE DROPDOWN
  const toggleDropdown = () => {
    setIsShowDropdown(!isShowDropdown);
  };

  // FUNCTION INPUT SEARCH CAR
  const changeInputValueHandler = (e) => {
    onChange(e);

    if (!e.target.value) {
      dispatch(getVehicleByCategory());
      setFilteredData([]);
      return;
    }

    dispatch(getSearchVehicleByCategory({ search: e.target.value, page: 1 }));
    setAllData([]);
  };

  // FUNCTION WHEN CHECKED CAR
  const choosedVehicleHandler = (e) => {
    let newData;
    let checkedCar;

    if (filteredData.length !== 0) {
      newData = [...filteredData];

      checkedCar = newData.find((item) => item.id == e.target.value);
      if (checkedCar) {
        checkedCar.checked = e.target.checked;
        setFilteredData(newData);
      }
    } else {
      newData = [...allData];

      checkedCar = newData.find((item) => item.id == e.target.value);
      if (checkedCar) {
        checkedCar.checked = e.target.checked;
        setAllData(newData);
      }
    }

    // if checked true
    if (e.target.checked) {
      if (selectedCar.length === 0) {
        onSelectCar([{ cars: [checkedCar], total: 1 }]);
      } else {
        const existingName = selectedCar?.find((item) => item.cars[0]?.name === checkedCar?.name);
        if (existingName) {
          const newChoosedVehicle = [...selectedCar];
          newChoosedVehicle[newChoosedVehicle.indexOf(existingName)] = {
            cars: [...existingName.cars, checkedCar],
            total: existingName.total + 1,
          };
          onSelectCar(newChoosedVehicle);
        } else {
          onSelectCar([...selectedCar, { cars: [checkedCar], total: 1 }]);
        }
      }
      // if checked false
    } else {
      const existingName = selectedCar?.find((item) => item.cars[0]?.name === checkedCar?.name);

      if (existingName) {
        if (existingName.total > 1) {
          const newChoosedVehicle = [...selectedCar];
          newChoosedVehicle[newChoosedVehicle.indexOf(existingName)] = {
            cars: existingName.cars.filter((item) => item.id !== checkedCar.id),
            total: existingName.total - 1,
          };
          onSelectCar(newChoosedVehicle);
        } else {
          const copyChoosedVehicle = [...selectedCar];
          const newChoosedVehicle = copyChoosedVehicle.filter((item) => item.cars[0]?.name !== checkedCar?.name);
          onSelectCar(newChoosedVehicle);
        }
      }
    }
  };

  // EFFECT CLICK OUTSIDE
  useEffect(() => {
    // Add a click event listener to the document to handle clicks outside the dropdown.
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts.
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // INTERSECTION
  useEffect(() => {
    let timeout;

    if (entry) {
      if (entry.isIntersecting) {
        if (!allData.length) return;
        if (data?.pagination?.page === data?.pagination?.last_page) return;
        setIsIntersectionLoading(true);

        timeout = setTimeout(() => {
          intersectionAction();
          setIsIntersectionLoading(false);
        }, 1000);
      }
    }

    return () => clearTimeout(timeout);
  }, [entry]);

  // RESET DATA WHEN SELECTEDCAR GOT RESET
  useEffect(() => {
    if (!reset) return;

    if (!selectedCar || !selectedCar.length) {
      if (filteredData.length !== 0) {
        setFilteredData([]);
        setReset(false);
      }

      if (allData.length !== 0) {
        setAllData([]);
        setReset(false);
      }
    }
  }, [selectedCar]);

  // SET ALL DATA OR FILTERED DATA FROM VEHICLE DATA
  useEffect(() => {
    if (!data || !data.category || !data.category.length) return;

    let choosedIds = [];
    for (let choosedData of selectedCar) {
      for (let chooseCar of choosedData.cars) {
        choosedIds.push(chooseCar.id);
      }
    }

    if (value) {
      const checkedData = [...data.category]?.map((item) => ({
        ...item,
        checked: choosedIds.some((id) => id === item.id),
      }));
      setFilteredData(checkedData);
      return;
    } else {
      const newData = data.category.filter((item) => !allData.some((existingItem) => existingItem.id === item.id));
      if (newData.length > 0) {
        const checkedData = [...data.category]?.map((item) => ({
          ...item,
          checked: choosedIds.some((id) => id === item.id),
        }));
        setAllData((prevState) => [...prevState, ...checkedData]);
      }
    }
  }, [data, value]);

  return (
    <div className={clsx('select-field-airport-transfer-check', className)} style={style} ref={dropdownRef}>
      {label && (
        <label htmlFor={id} className="select-field-airport-transfer-check__label">
          {label}
        </label>
      )}
      <div className="select-field-airport-transfer-check__field" onClick={toggleDropdown}>
        {isShowDropdown ? (
          <input
            id={id}
            name={name}
            className="select-field-airport-transfer-check__field__input"
            value={value}
            onChange={changeInputValueHandler}
            placeholder={placeholder}
            autoFocus
          />
        ) : selectedCar.length ? (
          <div className="select-field-airport-transfer-check__field__cars-wrapper">
            {selectedCar.map((item, idx) => (
              <div key={idx + 1} className="chips">
                <p>{item.cars[0]?.name}</p>
                {item.total > 1 && <span>{item.total}</span>}
              </div>
            ))}
          </div>
        ) : (
          <input
            id={id}
            name={name}
            className="select-field-airport-transfer-check__field__input"
            value={value}
            onChange={changeInputValueHandler}
            placeholder={placeholder}
          />
        )}
      </div>

      {isShowDropdown && (
        <div className="select-field-airport-transfer-check__dropdown">
          <ul className="list-container">
            {filteredData.length !== 0 ? (
              filteredData.map((item, idx) => (
                <li key={item.id} ref={idx === filteredData?.length - 1 ? entryRef : null}>
                  <div className="image-container">
                    {item?.vehicle?.photo && item?.vehicle?.photo?.length !== 0 && (
                      <img src={`${baseUrl}${item?.vehicle.photo[0]?.name}`} alt={item?.name} loading="lazy" />
                    )}
                  </div>
                  <div className="vehicle-description">
                    <h3 className="vehicle-description__title">{`${item?.name} ${item?.vehicle?.name}`}</h3>
                    <div className="vehicle-description__desc">
                      <p>{item?.vehicle?.max_passanger} Seats</p>
                      <span />
                      <p>{`${!item.vehicle.min_suitcase ? 1 : item.vehicle.min_suitcase} - ${
                        item.vehicle.max_suitcase
                      } Koper`}</p>
                      <span />
                      <p>Transmisi {item?.vehicle?.transmission}</p>
                    </div>
                  </div>
                  <div className="checkbox">
                    <CheckboxField
                      checked={item.checked}
                      onChange={choosedVehicleHandler}
                      value={item.id}
                      disable={disable}
                    />
                  </div>
                </li>
              ))
            ) : allData.length !== 0 ? (
              allData.map((item, idx) => (
                <li key={item.id} ref={idx === allData?.length - 1 ? entryRef : null}>
                  <div className="image-container">
                    {item?.vehicle?.photo && item?.vehicle?.photo?.length !== 0 && (
                      <img src={`${baseUrl}${item?.vehicle.photo[0]?.name}`} alt={item?.name} loading="lazy" />
                    )}
                  </div>
                  <div className="vehicle-description">
                    <h3 className="vehicle-description__title">{`${item?.name} - ${item?.vehicle?.name}`}</h3>
                    <div className="vehicle-description__desc">
                      <p>{item?.vehicle?.max_passanger} Seats</p>
                      <span />
                      <p>{`${!item.vehicle.min_suitcase ? 1 : item.vehicle.min_suitcase} - ${
                        item.vehicle.max_suitcase
                      } Koper`}</p>
                      <span />
                      <p>Transmisi {item?.vehicle?.transmission}</p>
                    </div>
                  </div>
                  <div className="checkbox">
                    <CheckboxField
                      checked={item.checked}
                      onChange={choosedVehicleHandler}
                      value={item.id}
                      disable={disable}
                    />
                  </div>
                </li>
              ))
            ) : (
              <li>No Data Found</li>
            )}

            {isIntersectionLoading && <li>Loading ...</li>}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectFieldAirportTransferCheck;
