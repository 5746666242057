import { Table, TableWrapper } from 'components/Global';
import { ReactComponent as TrxIn } from 'icons/trx-in-icon.svg';
import React from 'react';
import { indonesianDateFormat } from 'utils/helpers';
import { columnReview } from 'utils/order/order-column';

const TransactionInTable = () => {
  return (
    <TableWrapper icon={<TrxIn width="25px" height="25px" />} title="Transaksi Masuk" links>
      <h2>100</h2>
      <Table column={columnReview} maxRows={4} data={tableData} actionBtn onDetail={() => {}} />
    </TableWrapper>
  );
};

export default TransactionInTable;

const tableData = [
  {
    id: 3,
    name: 'Kevin Sanjaya',
    car_type: 'Toyota Fortuner',
    start_date: indonesianDateFormat('2022-11-20'),
  },
];
