import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailAddon } from 'features/addon/actions';
import { checkEmptyObject, setCurrency } from 'utils/functionality';
import { useAppContext } from 'components/Context/AppContext';

import { ReactComponent as PlusIcon } from 'icons/plus-circle-filled-icon.svg';
import { ReactComponent as MinusIcon } from 'icons/minus-circle-filled-icon.svg';
import { ReactComponent as FullScreen } from 'icons/full-screen-image-icon.svg';

const ListItemAdditional = ({ item, onChoose, selectedAdditionalItem, filterParams, data, setData }) => {
  const dispatch = useDispatch();

  const { setShowAdditionalImageModal, showToast } = useAppContext();

  const { selectedAddon } = useSelector((state) => state.addon);

  const [choosedVariantColor, setChoosedVariantColor] = useState('');
  const [additionalTotalPick, setAdditionalTotalPick] = useState(0);

  const addtotalPickHandler = async () => {
    const totalToPick = additionalTotalPick + 1;
    setAdditionalTotalPick(totalToPick);
    onChoose({ additional: { ...item, varieties: [choosedVariantColor] }, total: totalToPick });

    try {
      await dispatch(
        getDetailAddon({
          id: item.id,
          varietiesId: choosedVariantColor?.id,
          locationId: filterParams?.locationId,
          locTimeId: filterParams?.locTimeId,
          startDate: filterParams?.startDate,
          endDate: filterParams?.endDate,
          startTime: filterParams?.startTime,
          endTime: filterParams?.endTime,
        }),
      ).unwrap();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: `${error.message}` });
    }
  };

  const removeTotalPickHandler = () => {
    if (additionalTotalPick === 0) return;

    const totalToPick = additionalTotalPick - 1;
    setAdditionalTotalPick(totalToPick);
    onChoose({ additional: { ...item, varieties: [choosedVariantColor] }, total: totalToPick });
  };

  const choosedVariantColorHandler = (color) => {
    setChoosedVariantColor(color);
    setAdditionalTotalPick(0);

    onChoose({ additional: { ...item, varieties: [color] }, total: 0 });
  };

  const additionalTotalStockHandler = (variationColor) => {
    if (!variationColor || variationColor.length === 0) return 0;

    const totalStock = variationColor.reduce((acc, cur) => acc + cur.available_stock, 0);
    return totalStock;
  };

  const showAdditionalImageHandler = async () => {
    const varietiesId = choosedVariantColor ? choosedVariantColor.id : item.varieties[0].id;

    try {
      await dispatch(
        getDetailAddon({
          id: item.id,
          varietiesId,
          locationId: filterParams?.locationId,
          locTimeId: filterParams?.locTimeId,
          startDate: filterParams?.startDate,
          endDate: filterParams?.endDate,
          startTime: filterParams?.startTime,
          endTime: filterParams?.endTime,
        }),
      ).unwrap();
      setShowAdditionalImageModal(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: `${error.message}` });
    }
  };

  useEffect(() => {
    if (selectedAdditionalItem) {
      const thisItem = selectedAdditionalItem.find((add) => add.additional.id === item.id);

      if (thisItem) {
        setChoosedVariantColor(thisItem.additional.varieties[0]);
        setAdditionalTotalPick(thisItem.total);
      }
    }
  }, [selectedAdditionalItem]);

  useEffect(() => {
    if (!selectedAddon || checkEmptyObject(selectedAddon)) return;
    const selectedVarieties = selectedAddon.varieties[0];
    const thisItemVarieties = item.varieties.find((vari) => vari.id === selectedVarieties?.id);

    if (thisItemVarieties) {
      const newData = data.map((addon) => {
        if (addon.id === item.id) {
          const newVarieties = addon.varieties?.map((vari) => {
            if (vari.id === selectedVarieties?.id) {
              return selectedVarieties;
            }
            return vari;
          });

          return {
            ...addon,
            varieties: newVarieties,
          };
        }

        return addon;
      });
      setData(newData);
    }
  }, [selectedAddon]);

  return (
    <li className="list-additional" key={item.id}>
      <div className="list-additional__image">
        <img
          src={!choosedVariantColor ? item.varieties[0].images[0].file_name : choosedVariantColor.images[0].file_name}
          alt="foto-barang"
        />
        <span role="button" onClick={showAdditionalImageHandler}>
          <FullScreen />
        </span>
      </div>
      <div className="list-additional__info-item">
        <h3>{item.name}</h3>
        <p className="price">{setCurrency(item.unit_price) || 0}</p>
        <p className="stock">
          Stock :{' '}
          {!choosedVariantColor ? additionalTotalStockHandler(item.varieties) : choosedVariantColor.available_stock}
        </p>
        <div className="description">
          <p>{item.description}</p>
        </div>
        <div className="colors">
          <p>Pilih Warna :</p>
          <div className="colors__button">
            {item.varieties.map((col) => (
              <button
                type="button"
                className={
                  choosedVariantColor.id === col.id ? 'active' : col.available_stock === 0 ? 'disable' : 'not-active'
                }
                key={col.id}
                onClick={() => choosedVariantColorHandler(col)}
              >
                {col.color}
              </button>
            ))}
          </div>
        </div>
        <div className="total">
          <p>Jumlah :</p>
          <div className="total__button">
            <button
              type="button"
              className={clsx('icon', (!choosedVariantColor || additionalTotalPick === 0) && 'disable')}
              onClick={removeTotalPickHandler}
            >
              <MinusIcon className="button-icon" />
            </button>
            <span>{additionalTotalPick}</span>
            <button
              type="button"
              className={clsx(
                'icon',
                (!choosedVariantColor ||
                  choosedVariantColor.max_order === additionalTotalPick ||
                  choosedVariantColor.available_stock === additionalTotalPick) &&
                  'disable',
              )}
              onClick={addtotalPickHandler}
            >
              <PlusIcon className="button-icon" />
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ListItemAdditional;
