import { createSlice } from '@reduxjs/toolkit';

export const menu = createSlice({
  name: 'menu',
  initialState: {
    data: [],
    offCanvasMenu: [],
    currentMenu: '',
  },
  reducers: {
    // reducers goes here
    setCurrentMenu: (state, action) => {
      state.currentMenu = action.payload;
    },
    setOffCanvasMenu: (state, action) => {
      state.offCanvasMenu = action.payload;
    },
    setSelectedMenu: (state, action) => {
      state.data = action.payload;
    },
    resetSelectedMenu: (state) => {
      state.data = [];
    },
  },
});

export const { setCurrentMenu, setOffCanvasMenu, setSelectedMenu, resetSelectedMenu } = menu.actions;

export default menu.reducer;
