import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modals from '../Modals';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { ReactComponent as CreditCardIcon } from 'icons/credit-card-icon.svg';
import { ReactComponent as DownloadIcon } from 'icons/download-icon.svg';
import { Button } from 'components/Global';
import { getDetailOrder } from 'features/orders/detailOrderSlice';
import { rejectBankTransferPayment } from 'features/orders/actions';
import { useAppContext } from 'components/Context/AppContext';

const BankTransferConfirmationModal = ({ isPaymentValid, setIsPaymentValid, setShowBankTransferConfirmation }) => {
  const {
    //   setShowNotificationForm,
    setShowTransactionDetail,
    showToast,
    setShowConfirmation,
  } = useAppContext();
  const data = useSelector(getDetailOrder);
  const [isReconfirmation, setIsReconfirmation] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')) {
      setIsReconfirmation(true);
    } else {
      setIsReconfirmation(false);
    }
  }, [data]);

  const backHandler = () => {
    setShowBankTransferConfirmation(false);
  };

  const rejectHandler = async () => {
    const payload = {
      transaction_key: data.transaction_key,
      message: 'Harap Mengirim Ulang Bukti Transfer',
    };

    setShowTransactionDetail(false);
    setShowBankTransferConfirmation(false);

    setShowConfirmation({
      message: 'Apakah anda yakin menolak pembayaran',
      show: true,
      onClick: async () => {
        try {
          await dispatch(rejectBankTransferPayment(payload)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Menolak Pembayaran' });
        } catch (err) {
          showToast({ type: 'error', message: 'Gagal Menolak Pembayaran' });
        }
      },
    });
  };

  const confirmHandler = () => {
    setIsPaymentValid(true);
    setShowBankTransferConfirmation(false);
  };

  const downloadImage = (url_slug) => {
    window.open(url_slug, '_blank');
  };

  return (
    <Modals setState={setShowBankTransferConfirmation}>
      <div className="modal">
        <div className="bank-transfer">
          <div className="bank-transfer--left">
            <header className="modal-header">
              <div className="modal-title">
                <figure>
                  <CarIcon fill="#009EF7" width="25px" height="25px" />
                </figure>
                <h1>Bank Transfer</h1>
              </div>
            </header>

            <div className="bank-transfer__detail">
              <div className="bank-transfer__data">
                <h3 className="bank-transfer__title">Nama Pengirim</h3>
                <p className="bank-transfer__value">
                  {isReconfirmation ? data.disbursement_reconfirmation[0].sender_name : data.disbursement.sender_name}
                </p>
              </div>
              <div className="bank-transfer__data">
                <h3 className="bank-transfer__title">Nama Bank</h3>
                <p className="bank-transfer__value">
                  {isReconfirmation
                    ? data.disbursement_reconfirmation[0].sender_bank_name
                    : data.disbursement.sender_bank_name}
                </p>
              </div>
            </div>
          </div>

          <div className="bank-transfer--right">
            <header className="modal-header">
              <div className="modal-title">
                <figure>
                  <CreditCardIcon width="25px" height="25px" />
                </figure>
                <h1>Foto Bukti Transfer</h1>
              </div>
            </header>

            <div className="bank-transfer__image">
              <div className="image-container">
                <img
                  src={
                    isReconfirmation
                      ? data.disbursement_reconfirmation[0].disbursement_reconfirmation_image
                      : data.disbursement.disbursement_confirmation_image
                  }
                  alt="bukti-transfer"
                />
                <DownloadIcon
                  onClick={() =>
                    downloadImage(
                      isReconfirmation
                        ? data.disbursement_reconfirmation[0].disbursement_reconfirmation_image
                        : data.disbursement.disbursement_confirmation_image,
                    )
                  }
                />
              </div>
            </div>

            <div className="bank-transfer__buttons">
              <Button variant="outline" className="button" width={134} size="sm" onClick={backHandler}>
                Kembali
              </Button>
              <Button variant="danger" className="button" width={179} size="sm" onClick={rejectHandler}>
                Tolak Pembayaran
              </Button>
              <Button
                variant="success"
                className="button"
                width={192}
                size="sm"
                onClick={confirmHandler}
                disabled={isPaymentValid}
              >
                Konfirmasi Pembayaran
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modals>
  );
};

export default BankTransferConfirmationModal;
