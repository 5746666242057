import { useEffect, useState } from 'react';
import Button from '../Button';
import SelectFieldDate from '../SelectFieldDate';
import { addDays, format, parseISO } from 'date-fns';
import { indonesianDateFormat } from 'utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getAllVehicles } from 'features/vehicle/action';
import SelectFieldAirportPackageCheck from '../SelectFieldAirportPackageCheck';
import { getVehicleByCategory } from 'features/category/actions';
import SelectFieldVehicleFilter from '../SelectFieldVehicleFilter';

const initialFilterObject = {
  vehicle_id: [],
  end_date: '',
  start_date: '',
};

const TransactionFilterCar = ({ onReset, onApply, typeOrder = 'daily' }) => {
  const dispatch = useDispatch();

  const { data: vehiclesData } = useSelector((state) => state.vehicle);
  const { vehicles: vehicleAirportData } = useSelector((state) => state.category);

  const [filterObject, setFilterObject] = useState(initialFilterObject);
  const [selectedCar, setSelectedCar] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [inputVehicleValue, setInputVehicleValue] = useState('');
  const [inputAirportTransferVehicleValue, setInputAirportTransferVehicleValue] = useState('');

  const [reset, setReset] = useState(false);

  const applyHandler = () => {
    onApply(filterObject);
  };

  const resetHandler = () => {
    setReset(true);
    setSelectedCar([]);
    setStartDate('');
    setEndDate('');
    setInputVehicleValue('');
    setInputAirportTransferVehicleValue('');

    setFilterObject({
      vehicle_id: [],
      end_date: '',
      start_date: '',
    });

    if (typeOrder === 'daily') {
      dispatch(getAllVehicles());
    } else if (typeOrder === 'airport-transfer') {
      dispatch(getVehicleByCategory());
    }

    onReset();
  };

  const handleSelectedCar = (cars) => {
    let choosedVehiclesId = [];
    for (let choosedData of cars) {
      for (let chooseCar of choosedData.cars) {
        choosedVehiclesId.push(chooseCar.id);
      }
    }

    setFilterObject({ ...filterObject, vehicle_id: choosedVehiclesId });
    setSelectedCar(cars);
  };

  const selectStartDayHandler = (date) => {
    setStartDate(date);

    setEndDate('');
    setFilterObject({ ...filterObject, start_date: format(date, 'yyyy-MM-dd').toString(), end_date: '' });
  };

  const selectEndDayHandler = (date) => {
    setEndDate(date);
    setFilterObject({ ...filterObject, end_date: format(date, 'yyyy-MM-dd').toString() });
  };

  const intersectionAction = () => {
    // if last page then just return nothing

    if (typeOrder === 'daily') {
      if (vehiclesData.pagination.page >= vehiclesData.pagination.last_page) return;
      dispatch(getAllVehicles(vehiclesData.pagination.page + 1));
    } else if (typeOrder === 'airport-transfer') {
      if (vehicleAirportData.pagination.page >= vehicleAirportData.pagination.last_page) return;
      dispatch(getVehicleByCategory(vehicleAirportData.pagination.page + 1));
    }
  };

  useEffect(() => {
    if (typeOrder === 'daily') {
      dispatch(getAllVehicles());
    } else if (typeOrder === 'airport-transfer') {
      dispatch(getVehicleByCategory());
    }
  }, []);

  return (
    <div className="transaction-filter-car">
      {typeOrder === 'daily' ? (
        <SelectFieldVehicleFilter
          label="Tipe Mobil"
          id="tipe-mobil"
          value={inputVehicleValue}
          onChange={(e) => setInputVehicleValue(e.target.value)}
          placeholder="Cari Mobil ..."
          data={vehiclesData}
          selectedCar={selectedCar}
          onSelectCar={handleSelectedCar}
          intersectionAction={intersectionAction}
          reset={reset}
          setReset={setReset}
        />
      ) : (
        <SelectFieldAirportPackageCheck
          label="Kategori Mobil"
          id="kategori-mobil"
          value={inputAirportTransferVehicleValue}
          onChange={(e) => setInputAirportTransferVehicleValue(e.target.value)}
          placeholder="Cari Mobil.."
          data={vehicleAirportData}
          selectedCar={selectedCar}
          onSelectCar={handleSelectedCar}
          intersectionAction={intersectionAction}
          reset={reset}
          setReset={setReset}
        />
      )}

      <SelectFieldDate
        label="Tanggal Mulai Sewa"
        htmlFor="tanggal-mulai"
        name="tanggal-mulai"
        placeholder="Pilih Tanggal Mulai"
        selectedDay={startDate}
        value={startDate !== '' ? indonesianDateFormat(format(startDate, 'yyyy-MM-dd').toString()) : ''}
        handleDaySelect={selectStartDayHandler}
        dateContainerXOffset="right"
      />

      <SelectFieldDate
        label="Tanggal Selesai Sewa"
        htmlFor="tanggal-selesai"
        name="tanggal-selesai"
        placeholder="Pilih Tanggal Selesai"
        selectedDay={endDate}
        value={endDate !== '' ? indonesianDateFormat(format(endDate, 'yyyy-MM-dd').toString()) : ''}
        handleDaySelect={selectEndDayHandler}
        dateContainerXOffset="left"
        fromDate={addDays(startDate === '' ? parseISO(startDate) : startDate, 1)}
        disable={!startDate}
        defaultMonth={startDate && new Date(new Date(startDate).getMonth())}
      />

      <div className="transaction-filter-car__action">
        <Button type="button" className="reset" onClick={resetHandler}>
          Reset
        </Button>
        <Button type="button" className="apply" onClick={applyHandler}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default TransactionFilterCar;
