import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const UserStats = () => {
  const userStats = useSelector((state) => state.userStats);

  const data = {
    labels: userStats?.data?.details?.map((item) => item.user_type) || [],
    datasets: [
      {
        tension: 0.2,
        data: userStats?.data?.details?.map((item) => item.total),
        backgroundColor: ['rgba(0, 158, 247,1)', 'rgba(1, 106, 164, 1)', 'rgba(0, 0, 0, 0.2)'],
        borderRadius: 6,
      },
    ],
  };

  if (userStats.isLoading) {
    return (
      <div className="card-user-stats-skeleton">
        <div className="value-skeleton">
          <div className="title-skeleton" />
          <div className="total-skeleton" />
          <ul>
            {Array.from({ length: 3 }).map((__, idx) => (
              <li key={idx}>
                <div />
                <span />
              </li>
            ))}
          </ul>
        </div>

        <div className="chart-skeleton">
          <div className="doughnut" />
        </div>
      </div>
    );
  }

  return (
    <div className="card-user-stats">
      <div className="value">
        <h2>Statistik User</h2>
        <span>{userStats?.data?.total}</span>
        <ul>
          {data.labels.map((item, idx) => (
            <li key={idx}>
              <div style={{ background: `${data.datasets[0].backgroundColor[idx]}` }} />
              <span>{`${data.datasets[0].data[idx]}% ${item}`}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="chart">
        <Doughnut options={options} data={data} height={117} width={130} />
      </div>
    </div>
  );
};

export default UserStats;
