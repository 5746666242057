import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllOvertime = createAsyncThunk('overtime/getAllOvertime', async (_, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.get(`${BASE_URL}/${API_VERSION}/admin/overtimes`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getDetailOvertime = createAsyncThunk('overtime/getDetailOvertime', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.get(`${BASE_URL}/${API_VERSION}/admin/overtimes/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createOvertime = createAsyncThunk('overtime/createOvertime', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.post(`${BASE_URL}/${API_VERSION}/admin/overtimes`, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateOvertime = createAsyncThunk('overtime/updateOvertime', async ({ id, payload }, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.put(`${BASE_URL}/${API_VERSION}/admin/overtimes/${id}`, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const deleteOvertime = createAsyncThunk('overtime/deleteOvertime', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.delete(`${BASE_URL}/${API_VERSION}/admin/overtimes/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAllOvertimeRules = createAsyncThunk('overtime/getAllOvertimeRules', async (_, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.get(`${BASE_URL}/${API_VERSION}/admin/overtime-rules`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getDetailOvertimeRules = createAsyncThunk('overtime/getDetailOvertimeRules', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.get(`${BASE_URL}/${API_VERSION}/admin/overtime-rules/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createOvertimeRules = createAsyncThunk('overtime/createOvertimeRules', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.post(`${BASE_URL}/${API_VERSION}/admin/overtime-rules`, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateOvertimeRules = createAsyncThunk(
  'overtime/updateOvertimeRules',
  async ({ id, payload }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const res = await axios.put(`${BASE_URL}/${API_VERSION}/admin/overtime-rules/${id}`, payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const deleteOvertimeRules = createAsyncThunk('overtime/deleteOvertime', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.delete(`${BASE_URL}/${API_VERSION}/admin/overtime-rules/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
