const Modals = ({ children, setState, icon, title, secondTitle, secondIcon, width }) => {
  return (
    <div className="g-modal">
      <div className="g-modal-overlay" onClick={() => (setState ? setState(false) : () => {})} />
      {icon || title ? (
        <div className="modal">
          <header className="modal-header">
            <div className="modal-title" style={{ width: `calc(100% - ${width}px)` }}>
              {icon && <figure>{icon}</figure>}
              <h1>{title}</h1>
            </div>

            {secondTitle ? (
              <div className="modal-title">
                {secondIcon ? <figure>{secondIcon}</figure> : <figure>{icon}</figure>}
                <h1>{secondTitle}</h1>
              </div>
            ) : null}
          </header>
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default Modals;
