import { createSlice } from '@reduxjs/toolkit';
import { getNotificationsTemplate, getTemplateDetail } from './actions';

const initialState = {
  data: {},
  selected: {},
  firebaseToken: '',
  sendNotificationPayload: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const notification = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // reducers goes here
    setFirebaseToken: (state, action) => {
      state.firebaseToken = action.payload;
    },
    setNotificationPayload: (state, action) => {
      state.sendNotificationPayload = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsTemplate.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getNotificationsTemplate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getNotificationsTemplate.fulfilled, (state, action) => {
        let arr = [];

        action.payload.templates.map((item) => arr.push({ name: item.name, code: item.code }));

        state.isLoading = false;
        state.data = { ...action.payload, templates: arr };
      })
      .addCase(getTemplateDetail.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.selected = {};
      })
      .addCase(getTemplateDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getTemplateDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selected = action.payload;
      });
  },
});

export const { setFirebaseToken, setNotificationPayload } = notification.actions;

export default notification.reducer;
