import { createSlice } from '@reduxjs/toolkit';
import {
  getAllAddon,
  getDetailAddon,
  createAddon,
  uploadImageAddon,
  uploadImageAddonUpddate,
  deleteAddon,
  updateAddon,
  getAllAddonByParams,
} from './actions';

const initialState = {
  data: {},
  selectedAddon: {},
  imageVarieties: {},
  imageVarietiesUpdate: {},
  isLoading: false,
  isError: false,
  errorMessage: {}, // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const addon = createSlice({
  name: 'addon',
  initialState,
  reducers: {
    // reducers goes here

    resetImageVarieties: (state) => {
      state.imageVarieties = {};
    },
    resetImageVarietiesUpdate: (state) => {
      state.imageVarietiesUpdate = {};
    },
    resetSelectedAddon: (state) => {
      state.selectedAddon = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAddon.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllAddon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllAddon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getDetailAddon.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.selectedAddon = {};
      })
      .addCase(getDetailAddon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDetailAddon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedAddon = action.payload;
      })
      .addCase(createAddon.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(createAddon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createAddon.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadImageAddon.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(uploadImageAddon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(uploadImageAddon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.imageVarieties = action.payload;
      })
      .addCase(uploadImageAddonUpddate.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(uploadImageAddonUpddate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(uploadImageAddonUpddate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.imageVarietiesUpdate = action.payload;
      })
      .addCase(deleteAddon.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(deleteAddon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteAddon.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateAddon.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(updateAddon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateAddon.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllAddonByParams.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllAddonByParams.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllAddonByParams.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});

export const { resetImageVarieties, resetImageVarietiesUpdate, resetSelectedAddon } = addon.actions;
export default addon.reducer;
