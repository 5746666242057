import { createSlice } from '@reduxjs/toolkit';
import { fetchPaidOrder } from './actions';

const initialState = {
  data: {},
  page: 1,
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const paidOrder = createSlice({
  name: 'paidOrder',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaidOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPaidOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchPaidOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      });
  },
});

export const getPaidOrder = (state) => state.paidOrder.data;
export const getPaidOrderStatus = (state) => state.paidOrder.status;

export default paidOrder.reducer;
