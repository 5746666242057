import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import Modals from '../Modals';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { ReactComponent as DownloadIcon } from 'icons/download-icon.svg';
import { useAppContext } from 'components/Context/AppContext';
import { Button } from 'components/Global';
import { setCurrency } from 'utils/functionality';
import { indonesianDateFormat } from 'utils/helpers';
import { getDetailOrder } from 'features/orders/detailOrderSlice';
// import { selectAllGarages, getGaragesStatus } from 'features/garages/slice';
import { changeOrderStatus } from 'features/orders/actions';
import { getCustomerData } from 'features/user/slice';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const TransactionDetailModal = ({ setShowBankTransferConfirmation, isPaymentValid }) => {
  const dispatch = useDispatch();
  const { setShowTransactionDetail, showToast, setShowConfirmation } = useAppContext();

  const data = useSelector(getDetailOrder);
  const customer = useSelector(getCustomerData);
  // const garages = useSelector(selectAllGarages);
  // const isLoading = useSelector(getGaragesStatus);
  const vehicleById = useSelector((state) => state.vehicle.selected);

  // const getGarageName = (garageId) => {
  //   if (!isLoading) {
  //     const garage = garages.filter((item) => item.id === garageId);

  //     if (garage.length === 0) return '-';
  //     return garage[0].name;
  //   }
  // };

  const handleProcess = () => {
    setShowTransactionDetail(false);
    let prevStatus;
    let nextStatus;
    let confirmationMessage;
    let toastSuccessMessage;
    let toastErrorMessage;

    switch (data.order_status) {
      case 'CHECKOUT':
        prevStatus = 'CHECKOUT';
        nextStatus = 'PAID';
        confirmationMessage = 'Apakah anda yakin ingin mengkonfirmasi transaksi?';
        toastSuccessMessage = 'Transaksi Berhasil Dikonfirmasi';
        toastErrorMessage = 'Transaksi Gagal Dikonfirmasi!';
        break;
      case 'PAID':
        prevStatus = 'PAID';
        nextStatus = 'COMPLETED';
        confirmationMessage = 'Apakah anda yakin ingin memproses transaksi?';
        toastSuccessMessage = 'Transaksi Berhasil Diproses';
        toastErrorMessage = 'Transaksi Gagal Diproses!';
        break;
      default:
        throw new Error('order status not found!');
    }

    const payload = {
      prevStatus,
      nextStatus,
      transaction_key: data.transaction_key,
    };

    setShowConfirmation({
      message: confirmationMessage,
      show: true,
      onClick: async () => {
        try {
          await dispatch(changeOrderStatus(payload)).unwrap();
          showToast({ type: 'success', message: toastSuccessMessage });
        } catch (err) {
          showToast({ type: 'error', message: toastErrorMessage });
        } finally {
          setShowConfirmation(false);
        }
      },
    });
  };

  const downloadImage = (url_slug) => {
    window.location.href = IMAGE_BASE_URL + url_slug;
  };

  /**
   * komponen ini untuk merender tombol Proses di modal detail order
   * untuk tabel Konfirmasi dan tabel Belum Diproses saja
   */
  const RenderedProcessButton = () => {
    const isManualTransfer = data.disbursement?.payment_method_id === 1 || data.disbursement?.payment_method_id === 2;
    const showButtonForConfirmation = isManualTransfer && data.order_status === 'CHECKOUT';
    const showButtonForUnprocess = data.order_status === 'PAID';

    if (showButtonForConfirmation) {
      return (
        <Button
          variant="success"
          className={clsx('button', !isPaymentValid && 'button--disabled')}
          width={208}
          size="sm"
          disabled={!isPaymentValid}
          onClick={handleProcess}
        >
          Proses
        </Button>
      );
    } else if (showButtonForUnprocess) {
      return (
        <Button variant="success" className="button" width={208} size="sm" onClick={handleProcess}>
          Proses
        </Button>
      );
    } else {
      return null;
    }
  };

  return (
    <Modals
      setState={setShowTransactionDetail}
      icon={<CarIcon fill="#009EF7" width="25px" height="25px" />}
      title="Sewa Daily"
    >
      <div className="transaction-modal">
        <div className="transaction-detail">
          <div className="transaction-detail--left">
            <div className="transaction-data">
              <h3 className="transaction-title">Nama Lengkap</h3>
              <p className="transaction-value">{data.user_name}</p>
            </div>
            <div className="transaction-data">
              <h3 className="transaction-title">Email</h3>
              <p className="transaction-value">{data.email}</p>
            </div>
            <div className="transaction-data">
              <h3 className="transaction-title">No. Handphone</h3>
              <p className="transaction-value">{data.phone_number}</p>
            </div>
            <div className="transaction-data">
              <h3 className="transaction-title">Negara</h3>
              <p className="transaction-value">Indonesia</p>
            </div>
            <div className="transaction-data">
              <h3 className="transaction-title">Whatsapp</h3>
              <p className="transaction-value">{data.wa_number}</p>
            </div>
            <div className="transaction-data">
              <h3 className="transaction-title">Alamat Pengambilan</h3>
              <p className="transaction-value">{data.order_detail.rental_delivery_location || '-'}</p>
            </div>
            {Object.prototype.hasOwnProperty.call(data.order_detail, 'flight_number') ? (
              <div className="transaction-data">
                <h3 className="transaction-title">Nomor Penerbangan</h3>
                <p className="transaction-value">{data.order_detail.flight_number || '-'}</p>
              </div>
            ) : null}
            {Object.prototype.hasOwnProperty.call(data.order_detail, 'landing_time') ? (
              <div className="transaction-data">
                <h3 className="transaction-title">Waktu Mendarat</h3>
                <p className="transaction-value">{data.order_detail.landing_time || '-'}</p>
              </div>
            ) : null}
            {data.order_detail.rental_delivery_location_detail && (
              <div className="transaction-data">
                <h3 className="transaction-title">Detail Alamat Pengambilan</h3>
                <p className="transaction-value">{data.order_detail.rental_delivery_location_detail}</p>
              </div>
            )}
            <div className="transaction-data">
              <h3 className="transaction-title">Alamat Pengembalian</h3>
              <p className="transaction-value">{data.order_detail.rental_return_location ?? '-'}</p>
            </div>
            {data.order_detail.rental_return_location_detail && (
              <div className="transaction-data">
                <h3 className="transaction-title">Detail Alamat Pengembalian</h3>
                <p className="transaction-value">{data.order_detail.rental_return_location_detail}</p>
              </div>
            )}
          </div>
          <div className="transaction-detail--center">
            <div className="transaction-detail--center--initial">
              <div className="transaction-data">
                <h3 className="transaction-title">Tipe Sewa</h3>
                <p className="transaction-value">{data.order_type_id === 1 ? 'Harian' : '-'}</p>
              </div>
              <div className="transaction-data">
                <h3 className="transaction-title">Jenis Mobil</h3>
                {/* <p className="transaction-value">{getVehicleName(data.order_detail.vehicle_id)}</p> */}
                <p className="transaction-value">{vehicleById.name ?? '-'}</p>
              </div>
              <div className="transaction-data">
                <h3 className="transaction-title">Tanggal Sewa</h3>
                <p className="transaction-value">{indonesianDateFormat(data.order_detail.start_booking_date)}</p>
              </div>
              <div className="transaction-data">
                <h3 className="transaction-title">Selesai Sewa</h3>
                <p className="transaction-value">{indonesianDateFormat(data.order_detail.end_booking_date)}</p>
              </div>
              <div className="transaction-data">
                <h3 className="transaction-title">Jam Sewa</h3>
                <p className="transaction-value">{data.order_detail.start_booking_time}</p>
              </div>
              <div className="transaction-data">
                <h3 className="transaction-title">Plat Mobil</h3>
                <p className="transaction-value">{vehicleById.license_number ?? '-'}</p>
              </div>
              <div className="transaction-data">
                <h3 className="transaction-title">Total Harga</h3>
                <p className="transaction-value">{setCurrency(data.total_payment)}</p>
              </div>
            </div>
            {/* Hide if it's initial rent / not extend order */}
            {data?.order_status === 'COMPLETED' ? (
              <div className="transaction-detail--center--extend">
                <header>
                  <h3>Perpanjang Sewa</h3>
                </header>
                <div className="transaction-data">
                  <h3 className="transaction-title">Status Sewa</h3>
                  <p className="transaction-value">Perpanjang Sewa</p>
                </div>
                <div className="transaction-data">
                  <h3 className="transaction-title">Jenis Mobil</h3>
                  {/* <p className="transaction-value">{getVehicleName(data.order_detail.vehicle_id)}</p> */}
                  <p className="transaction-value">{vehicleById.name ?? '-'}</p>
                </div>
                <div className="transaction-data">
                  <h3 className="transaction-title">Tanggal Sewa</h3>
                  <p className="transaction-value">{indonesianDateFormat(data.order_detail.start_booking_date)}</p>
                </div>
                <div className="transaction-data">
                  <h3 className="transaction-title">Selesai Sewa</h3>
                  <p className="transaction-value">{indonesianDateFormat(data.order_detail.end_booking_date)}</p>
                </div>
                <div className="transaction-data">
                  <h3 className="transaction-title">Jam Sewa</h3>
                  <p className="transaction-value">{data.order_detail.start_booking_time}</p>
                </div>
                <div className="transaction-data">
                  <h3 className="transaction-title">Plat Mobil</h3>
                  <p className="transaction-value">{vehicleById.license_number ?? '-'}</p>
                </div>
                <div className="transaction-data">
                  <h3 className="transaction-title">Total Harga</h3>
                  <p className="transaction-value">{setCurrency(data.total_payment)}</p>
                </div>
              </div>
            ) : null}
          </div>
          <div className="transaction-detail--right">
            <div className="transaction-data">
              <h3 className="transaction-title">Status Pembayaran</h3>
              <p className="transaction-value">{data.order_status}</p>
            </div>
            <div className="transaction-data">
              <h3 className="transaction-title">Metode Pembayaran</h3>
              <p className="transaction-value">
                {data.disbursement?.payment?.method ? data.disbursement.payment.method : '-'}{' '}
                {(data.disbursement?.payment_method_id === 1 || data.disbursement?.payment_method_id === 2) &&
                  data.order_status === 'CHECKOUT' && (
                    <span className="confirm-btn" onClick={() => setShowBankTransferConfirmation(true)}>
                      Konfirmasi
                    </span>
                  )}
              </p>
            </div>
            <div className="transaction-identity">
              {customer.PersonalInfos?.ktp === '' && data?.order_detail.identity.ktp === '' ? (
                <p>Belum upload KTP</p>
              ) : (
                <div className="transaction-identity--image">
                  <img
                    src={
                      customer.PersonalInfos?.ktp !== ''
                        ? IMAGE_BASE_URL + customer.PersonalInfos?.ktp
                        : data?.order_detail.identity.ktp
                    }
                    alt="foto KTP pelanggan"
                  />
                  <DownloadIcon onClick={() => downloadImage(customer.PersonalInfos?.ktp)} />
                </div>
              )}

              {customer.PersonalInfos?.sim === '' && data?.order_detail.identity.sim === '' ? (
                <p>Belum upload SIM</p>
              ) : (
                <div className="transaction-identity--image">
                  <img
                    src={
                      customer.PersonalInfos?.sim !== ''
                        ? IMAGE_BASE_URL + customer.PersonalInfos?.sim
                        : data?.order_detail.identity.sim
                    }
                    alt="foto SIM pelanggan"
                  />
                  <DownloadIcon onClick={() => downloadImage(customer.PersonalInfos?.sim)} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="transaction-buttons">
          <Button
            variant="outline"
            className="button"
            width={208}
            size="sm"
            onClick={() => setShowTransactionDetail(false)}
          >
            Kembali
          </Button>
          <RenderedProcessButton />
        </div>
      </div>
    </Modals>
  );
};

export default TransactionDetailModal;
