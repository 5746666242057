import { createSlice } from '@reduxjs/toolkit';
import {
  getAllAirportLocation,
  getDetailAirportLocation,
  createAirportLocation,
  deleteAirportLocation,
  updateAirportLocation,
} from './actions';

const initialState = {
  data: {},
  selectedLocation: {},
  isLoading: true,
  isError: false,
  errorMessage: {}, // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const airportLocation = createSlice({
  name: 'airportLocation',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAirportLocation.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllAirportLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllAirportLocation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getDetailAirportLocation.pending, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = {};
        state.selectedLocation = {};
      })
      .addCase(getDetailAirportLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDetailAirportLocation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedLocation = action.payload;
      })
      .addCase(createAirportLocation.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(createAirportLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createAirportLocation.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateAirportLocation.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(updateAirportLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateAirportLocation.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteAirportLocation.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(deleteAirportLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteAirportLocation.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export default airportLocation.reducer;
