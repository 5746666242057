import clsx from 'clsx';
import React from 'react';
import { ReactComponent as ToastError } from 'icons/toast-error-icon.svg';
import { ReactComponent as ToastInfo } from 'icons/toast-info-icon.svg';
import { ReactComponent as ToastSuccess } from 'icons/toast-success-icon.svg';
import { ReactComponent as ToastWarning } from 'icons/toast-warning-icon.svg';

const Toast = ({ type, message }) => {
  let toastStyle = {};
  switch (type) {
    case 'success':
      toastStyle.icon = <ToastSuccess className="toast-icon" />;
      toastStyle.color = 'toast-success';
      break;
    case 'warning':
      toastStyle.icon = <ToastWarning className="toast-icon" />;
      toastStyle.color = 'toast-warning';
      break;
    case 'error':
      toastStyle.icon = <ToastError className="toast-icon" />;
      toastStyle.color = 'toast-error';
      break;
    case 'info':
    default:
      toastStyle.icon = <ToastInfo className="toast-icon" />;
      toastStyle.color = 'toast-info';
      break;
  }

  return (
    <div className={clsx('toast', toastStyle.color)}>
      <p className="toast-content">
        <span>{toastStyle.icon}</span>
        {message}
      </p>
    </div>
  );
};

export default Toast;
