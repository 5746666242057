import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import NotificationTemplateList from './notification-template';
import NotificationDetail from './notification-detail';
import { getNotificationsTemplate } from 'features/notification/actions';
import { useAppContext } from 'components/Context/AppContext';

const NotificationComponent = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { setShowNotificationDetail, showNotificationDetail } = useAppContext();

  useEffect(() => {
    dispatch(getNotificationsTemplate({ page }));
  }, [page]);

  return (
    <div className="notif">
      {showNotificationDetail == 'list' ? (
        <NotificationTemplateList setShowDetail={setShowNotificationDetail} page={page} setPage={setPage} />
      ) : (
        <NotificationDetail setShowDetail={setShowNotificationDetail} showDetail={showNotificationDetail} />
      )}
    </div>
  );
};

export default NotificationComponent;
