import React, { useEffect, useState } from 'react';
import PaginationTable from 'components/Global/Pagination';
import TableWrapper from 'components/Global/TableWrapper';
import { ReactComponent as PeopleIcon } from 'icons/people-filled-icon.svg';
import { Table } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUsers, fetchCustomerInfo } from 'features/user/actions';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/Global/LoadingSpinner';
import EmptyState from 'components/Global/EmptyState';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Lengkap', value: 'name' },
  { header: 'Email', value: 'email' },
  { header: 'No. Handphone', value: 'phone' },
  { header: 'Status User', value: 'status' },
  { header: 'Whatsapp', value: 'wa_number' },
];

const UserListComponent = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchAllUsers(currentPage));
  }, [currentPage]);

  const dispatch = useDispatch();

  const handleDetail = (e) => {
    const userId = e.target.parentElement.parentElement.dataset['key'];
    dispatch(fetchCustomerInfo(userId));
    navigate(`detail/${userId}`);
  };

  return (
    <TableWrapper title="Tabel User Customer" icon={<PeopleIcon fill="#FF9900" width="25px" height="25px" />}>
      {isLoading ? (
        <LoadingSpinner />
      ) : data?.users?.length === 0 ? (
        <EmptyState />
      ) : (
        <>
          <Table column={column} data={data?.users} actionBtn currentPage={currentPage} onDetail={handleDetail} />
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={data?.pagination?.total_data}
          />
        </>
      )}
    </TableWrapper>
  );
};

export default UserListComponent;
