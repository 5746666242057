import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { Button } from 'components/Global';
// import { rejectBankTransferPayment } from 'features/orders/actions';
// import { getDetailOrder } from 'features/orders/detailOrderSlice';
import ModalsNotification from '../ModalsNotification';

const NotificationForm = () => {
  // const dispatch = useDispatch();
  const { setShowNotificationForm, setShowNotificationDetail, setActive } = useAppContext();

  // const data = useSelector(getDetailOrder);

  // const [message, setMessage] = useState('');
  // const [isLoading, setIsLoading] = useState(false);

  const sendHandler = async () => {
    // if (message.trim() === '') return;
    // const payload = {
    //   transaction_key: data.transaction_key,
    //   message: message,
    // };
    // try {
    //   setIsLoading(true);
    //   await dispatch(rejectBankTransferPayment(payload)).unwrap();
    //   showToast({ type: 'success', message: 'Berhasil Menolak Pembayaran' });
    // } catch (err) {
    //   showToast({ type: 'error', message: 'Gagal Menolak Pembayaran' });
    // } finally {
    //   setShowNotificationForm(false);
    //   setIsLoading(false);
    // }
  };

  function handleAddTemplate() {
    setShowNotificationForm(false);
    setActive('Notifikasi');
    setShowNotificationDetail('add');
  }

  return (
    <ModalsNotification setState={setShowNotificationForm} onAddTemplate={handleAddTemplate}>
      <div className="notificationForm">
        {/* <div className="notificationForm__input"> */}
        {/* <textarea value={message} onChange={(e) => setMessage(e.target.value)}></textarea> */}
        <div className="notificationForm__select-wrapper">
          <select
            // id={htmlFor}
            // name={name}
            // onChange={onChange}
            className="notificationForm__select"
            style={{ paddingRight: '40px', fontSize: '16px' }}
            // value={value}
          >
            <option hidden>Pilih Notifikasi</option>
            <option>Pembayaran Belum Valid</option>
            <option>Reminder Selesai Sewa</option>
            <option>Pesan Terima Kasih</option>
            <option>Verifikasi Ditolak</option>
          </select>
        </div>
        {/* </div> */}
        <div className="notificationForm__button">
          <Button wfull size="md" className="button" onClick={sendHandler}>
            Kirim Notifikasi
          </Button>
          <Button variant="outline" wfull size="md" className="button" onClick={() => setShowNotificationForm(false)}>
            Kembali
          </Button>
        </div>
      </div>
    </ModalsNotification>
  );
};

export default NotificationForm;
