const PricesInputField = ({
  label,
  id,
  value,
  handleChange = () => {},
  disable = false,
  onWheel = () => {},
  ...rest
}) => {
  return (
    <div className="input">
      <label htmlFor={id} className="input__label">
        {label}
      </label>
      <div className={disable ? 'input__field disable' : 'input__field'}>
        <div className="input__field-currency">IDR</div>
        <input
          type="number"
          className="input__field-number"
          value={value}
          name={id}
          onChange={handleChange}
          id={id}
          min={0}
          onWheel={onWheel}
          {...rest}
        />
      </div>
    </div>
  );
};

export default PricesInputField;
