import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllEmployees = createAsyncThunk('employees/getAllEmployees', async (page = 1, thunkAPI) => {
  const access_token = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/employees?limit=10&page=${page}`, {
      headers: { Authorization: `Bearer ${access_token}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
