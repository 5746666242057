import React from 'react';
import { InputField } from 'components/Global';

const DetailDeposit = ({ customerAccountBank }) => {
  return (
    <div className="detail-order-with-driver__deposit-detail">
      <div className="detail-order-with-driver__deposit-detail-title">
        <h1>Detail Deposit</h1>
      </div>
      <div className="detail-order-with-driver__deposit-detail-body">
        <InputField label="Nama Akun" disabled value={customerAccountBank.nama_rek || '-'} />
        <InputField label="No Rekening" disabled value={customerAccountBank.no_rek || '-'} />
        <InputField label="Nama Bank" disabled value={customerAccountBank.nama_bank || '-'} />
      </div>
    </div>
  );
};

export default DetailDeposit;
