import React from 'react';
import { TableWrapper, SelectField } from 'components/Global';
import { ReactComponent as AboutUsFilledIcon } from 'icons/about-us-filled.svg';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from 'icons/delete-icon.svg';
// import EditIcon from 'icons/edit-icon.svg';
import Tooltip from 'components/Global/Tooltip';
import { useEffect } from 'react';
import { deleteAboutUsImage, getAllAboutUsImage } from 'features/about-us/actions';
import ButtonAdd from 'components/Global/ButtonAdd';
import { checkPermission } from 'utils/functionality';

const imageUrl = process.env.REACT_APP_IMAGES;

const SELECT_DATA = [
  {
    name: '20',
    id: 2,
  },
];

const AboutUsComponent = () => {
  const { showToast, setShowAddAboutUsImage, setShowConfirmation } = useAppContext();
  const aboutUsImages = useSelector((state) => state.aboutUs?.data) || [];
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const deleteHandler = (e) => {
    const imageId = e.target.parentElement.parentElement.dataset['key'];
    if (!imageId) return;

    setShowConfirmation({
      message: 'Apakah anda yakin ingin menghapus data?',
      show: true,
      onClick: async () => {
        const res = await dispatch(deleteAboutUsImage(imageId));
        if (res?.type.includes('fulfilled')) {
          showToast({ type: 'success', message: 'Berhasil Menghapus data' });
          dispatch(getAllAboutUsImage());
          return;
        }
        showToast({ type: 'error', message: 'Menghapus data' });
      },
    });
  };

  useEffect(() => {
    dispatch(getAllAboutUsImage());
  }, []);

  return (
    <div className="about-us">
      <ButtonAdd
        onClick={() => {
          setShowAddAboutUsImage(true);
        }}
        label="Tambah Gambar"
      />

      <TableWrapper icon={<AboutUsFilledIcon fill="#009EF7" width="25px" height="25px" />} title="About Us">
        {/* content */}
        <header className="about-us-header">
          <h3>Total Data : {aboutUsImages?.length}</h3>
          <h3>View Data</h3>
          <SelectField data={SELECT_DATA} placeholder={10} style={{ width: '345px' }} />
        </header>
        <div className="about-us-body">
          {aboutUsImages.map((item, idx) => (
            <div key={idx} className="about-us-item">
              <figure className="about-us-item__image-wrapper">
                <img src={imageUrl + item.image} alt="thumbnail" />
              </figure>
              <div className="about-us-item__icons-wrapper" data-key={item.id}>
                {/* <Tooltip content="Edit">
                  <img src={EditIcon} onClick={editHandler} />
                </Tooltip> */}
                {checkPermission(offCanvasMenu, currentMenu, 'delete') && (
                  <Tooltip content="Delete">
                    <img src={DeleteIcon} onClick={deleteHandler} />
                  </Tooltip>
                )}
              </div>
            </div>
          ))}
        </div>
      </TableWrapper>
    </div>
  );
};

export default AboutUsComponent;
