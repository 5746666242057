import React from 'react';
import { PaginationTable, Table, TableWrapper } from 'components/Global';
import { ReactComponent as ContactIcon } from '../../../../icons/contact-filled-icon.svg';
import { useAppContext } from 'components/Context/AppContext';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllContactUs, getDetailContactUs } from 'features/contact-us/action';
import { useState } from 'react';

// column header table
const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama', value: 'name' },
  { header: 'Email', value: 'email' },
  { header: 'Topik', value: 'subject' },
  { header: 'Kritik dan Saran', value: 'message' },
];

const ContactUsComponent = () => {
  const dispatch = useDispatch();

  // context
  const { setShowContactUsDetail, showToast, setShowSpinner } = useAppContext();

  // global state of all contact  us
  const { data, isError, errorMessage, isLoading } = useSelector((state) => state.contactUs);
  // const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  // is detail is clicked state
  const [isDetailClicked, setIsDetailClicked] = useState(false);

  // state of page
  const [currentPage, setCurrentPage] = useState(1);
  const maxRowsInOnePage = 10;

  // call all contact us data
  useEffect(() => {
    dispatch(getAllContactUs());
  }, []);

  // will show the error message when there is an error
  useEffect(() => {
    if (!isError) return;

    try {
      if (Object?.prototype?.hasOwnProperty?.call(errorMessage, 'slug')) {
        showToast({ type: 'error', message: errorMessage.slug });
      } else {
        showToast({ type: 'error', message: 'Terjadi Kesalahan' });
      }
    } catch (error) {
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }
  }, [isError, errorMessage]);

  // show spinner when loading true
  useEffect(() => {
    if (isLoading && !isDetailClicked) {
      setShowSpinner(true);
    } else setShowSpinner(false);
  }, [isLoading]);

  // show detail contact us when icon was clicked
  const handleDetail = (e) => {
    setIsDetailClicked(true);

    const contactUsId = e.target.parentElement.parentElement.dataset['key'];
    dispatch(getDetailContactUs(contactUsId));
    setShowContactUsDetail(true);
  };

  return (
    <div className="contact">
      <TableWrapper icon={<ContactIcon width="25px" height="25px" />} title="Hubungi Kami">
        <Table
          column={column}
          data={Array.from(data).slice(
            currentPage === 1 ? 0 : currentPage * maxRowsInOnePage - maxRowsInOnePage,
            currentPage * maxRowsInOnePage,
          )}
          actionBtn
          onDetail={handleDetail}
          currentPage={currentPage}
        />
        <PaginationTable
          currentPage={currentPage}
          onPageChange={(newPage) => setCurrentPage(newPage)}
          totalCount={data.length}
        />
      </TableWrapper>
    </div>
  );
};

export default ContactUsComponent;
