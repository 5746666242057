import { useSelector, useDispatch } from 'react-redux';
import Modals from '../Modals';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { ReactComponent as DownloadIcon } from 'icons/download-icon.svg';
import { useAppContext } from 'components/Context/AppContext';
import { Button } from 'components/Global';
import { setCurrency } from 'utils/functionality';
import { indonesianDateFormat } from 'utils/helpers';
import { getDetailOrder } from 'features/orders/detailOrderSlice';
// import { selectAllGarages, getGaragesStatus } from 'features/garages/slice';
import { ReactComponent as DepositFilledIcon } from 'icons/deposit-filled-icon.svg';
import { getCustomerData } from 'features/user/slice';
import { useEffect, useRef, useState } from 'react';
import { getAllBanks } from 'features/banks/actions';
import { getDepositDetail } from 'features/orders/depositSlice';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const DepositDetailModal = () => {
  const dispatch = useDispatch();
  const { setShowDepositDetailModal, showDepositDetailModal, setShowDepositConfirmation } = useAppContext();

  // calculate last column width to adjust secondTitle positition
  const divRef = useRef(null);
  const [width, setWidth] = useState(0);

  const data = useSelector(getDetailOrder);
  const customer = useSelector(getCustomerData);
  // const garages = useSelector(selectAllGarages);
  // const isLoading = useSelector(getGaragesStatus);
  const vehicleById = useSelector((state) => state.vehicle.selected);
  const depositData = useSelector(getDepositDetail);
  const { depositPrice } = useSelector((state) => state.depositOrder);

  // const getGarageName = (garageId) => {
  //   if (!isLoading) {
  //     const garage = garages.filter((item) => item.id === garageId);

  //     if (garage.length === 0) return '-';
  //     return garage[0].name;
  //   }
  // };

  const handleProcess = () => {
    setShowDepositDetailModal({ show: false, history: false });
    setShowDepositConfirmation(true);
  };

  const downloadImage = (url_slug) => {
    window.location.href = IMAGE_BASE_URL + url_slug;
  };

  useEffect(() => {
    dispatch(getAllBanks());
  }, []);

  useEffect(() => {
    if (!divRef.current) return;
    setWidth(divRef?.current?.offsetWidth);
  }, []);

  return (
    <Modals
      width={width}
      setState={() => setShowDepositDetailModal({ show: false, history: false })}
      icon={<CarIcon fill="#009EF7" width="25px" height="25px" />}
      secondIcon={<DepositFilledIcon fill="#009EF7" width="25px" height="25px" />}
      title="Harian"
      secondTitle="Data Pengembalian Deposit"
    >
      <div className="deposit-modal">
        <div className="deposit-modal__wrapper">
          <div className="deposit-modal__column">
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Nama Lengkap</h3>
              <p className="deposit-modal__value">{data?.user_name}</p>
            </div>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Email</h3>
              <p className="deposit-modal__value">{data?.email}</p>
            </div>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">No. Handphone</h3>
              <p className="deposit-modal__value">{data?.phone_number}</p>
            </div>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Negara</h3>
              <p className="deposit-modal__value">Indonesia</p>
            </div>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Whatsapp</h3>
              <p className="deposit-modal__value">{data?.wa_number}</p>
            </div>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Alamat Pengambilan</h3>
              <p className="deposit-modal__value">{data?.order_detail?.rental_delivery_location || '-'}</p>
            </div>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Alamat Pengembalian</h3>
              <p className="deposit-modal__value">
                {
                  // getGarageName(data?.order_detail?.rental_return_office_id)
                  data?.order_detail?.rental_return_location || '-'
                }
              </p>
            </div>
          </div>

          <div className="deposit-modal__column">
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Tipe Sewa</h3>
              <p className="deposit-modal__value">{data?.order_type_id === 1 ? 'Harian' : '-'}</p>
            </div>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Jenis Mobil</h3>
              {/* <p className="deposit-modal__value">{getVehicleName(data.order_detail.vehicle_id)}</p> */}
              <p className="deposit-modal__value">{vehicleById.name ?? '-'}</p>
            </div>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Tanggal Sewa</h3>
              <p className="deposit-modal__value">
                {indonesianDateFormat(data?.order_detail?.start_booking_date ?? '-')}
              </p>
            </div>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Selesai Sewa</h3>
              <p className="deposit-modal__value">
                {indonesianDateFormat(data?.order_detail?.end_booking_date ?? '-')}
              </p>
            </div>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Jam Sewa</h3>
              <p className="deposit-modal__value">{data?.order_detail?.end_booking_time}</p>
            </div>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Plat Mobil</h3>
              <p className="deposit-modal__value">{vehicleById.license_number ?? '-'}</p>
            </div>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Total Harga</h3>
              <p className="deposit-modal__value">{setCurrency(data?.total_payment)}</p>
            </div>
          </div>

          <div className="deposit-modal__column">
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Status Pembayaran</h3>
              <p className="deposit-modal__value">{data?.order_status}</p>
            </div>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Metode Pembayaran</h3>
              <p className="deposit-modal__value">{data?.disbursement?.payment?.method}</p>
            </div>
            <div className="transaction-identity">
              <div className="transaction-identity--image">
                <img src={IMAGE_BASE_URL + customer.PersonalInfos?.ktp} alt="foto KTP pelanggan" />
                <DownloadIcon onClick={() => downloadImage(customer.PersonalInfos?.ktp)} />
              </div>
              <div className="transaction-identity--image">
                <img src={IMAGE_BASE_URL + customer.PersonalInfos?.sim} alt="foto SIM pelanggan" />
                <DownloadIcon onClick={() => downloadImage(customer.PersonalInfos?.sim)} />
              </div>
            </div>
          </div>

          <div className="deposit-modal__column" ref={divRef}>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Nama</h3>
              <p className="deposit-modal__value">{depositData?.recipient_name}</p>
            </div>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Nama Bank</h3>
              <p className="deposit-modal__value">{depositData?.account_bank}</p>
            </div>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Nomor Rekening</h3>
              <p className="deposit-modal__value">{depositData?.account_number}</p>
            </div>
            <div className="deposit-modal__info">
              <h3 className="deposit-modal__title">Jumlah Uang</h3>
              <p className="deposit-modal__value">{setCurrency(depositPrice)}</p>
            </div>
            {showDepositDetailModal.history ? null : (
              <div className="deposit-modal__form-check">
                <input className="deposit-modal__check-input" type="checkbox" id="emailNotif" defaultChecked />
                <label className="deposit-modal__check-label" htmlFor="emailNotif">
                  Kirim Notifikasi via Email
                </label>
              </div>
            )}
          </div>
        </div>
        <div className="deposit-modal__buttons">
          <Button
            variant="outline"
            className="button"
            width={208}
            size="sm"
            onClick={() => setShowDepositDetailModal({ show: false, history: false })}
          >
            Kembali
          </Button>
          {showDepositDetailModal.history ? null : (
            <Button variant="success" className="button" width={208} size="sm" onClick={handleProcess}>
              Proses Deposit
            </Button>
          )}
        </div>
      </div>
    </Modals>
  );
};

export default DepositDetailModal;
