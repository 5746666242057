import {
  addGarages,
  createGarageAdditionalSettings,
  deleteGarageAdditionalSettings,
  getGarageAdditionalSettings,
  getGarageDetail,
  updateGarageAdditionalSettings,
  updateGarages,
} from 'features/garages/actions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as GarageFilledIcon } from 'icons/garage-filled-icon.svg';
import { ReactComponent as MagnifierIcon } from 'icons/magnifier.svg';
import { ReactComponent as PlusIcon } from 'icons/plus-icon.svg';
import { ReactComponent as DeleteIcon } from 'icons/delete-rounded-icon.svg';
import { Button, InputField, TableWrapper } from 'components/Global';
import { useAppContext } from 'components/Context/AppContext';
import SelectFieldTime from 'components/Global/SelectFieldTime';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import { resetGarageDetail } from 'features/garages/slice';
import { checkPermission } from 'utils/functionality';
import AdditionalGarageSetting from '../AdditionalGarageSetting';
import { getAllRentalLocation } from 'features/rental-location/actions';
import { getServicesByRentalLocation } from 'features/services/actions';

const GarageDetailComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showToast, setShowConfirmation } = useAppContext();

  const { data } = useSelector((state) => state.rentalLocation);
  const { garageDetail, garageAdditionalSetting } = useSelector((state) => state.garages);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const { data: rentalServicesData } = useSelector((state) => state.services);

  const [mainSettingValue, setMainSettingValue] = useState({
    id: 0,
    name: '',
    location_id: 0,
    start_time: '',
    end_time: '',
    address_details: '',
    map_link: '',
    outside_operational_fee: '',
    outside_operational_status: false,
    outside_operational_service: [],
  });
  const [additionalSettingValue, setAdditionalSettingValue] = useState([
    {
      id: 0,
      garage_id: 0,
      fee: '',
      status: false,
      service: '',
      vehicle_ids: [],
      vehicles: [],
      category_ids: [],
      categories: [],
    },
  ]);
  const [additionalSettingToUpdate, setAdditionalSettingToUpdate] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [mappedRentTypes, setMappedRentTypes] = useState([]);

  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');

  useEffect(() => {
    if (!id) return;
    dispatch(getGarageDetail(id));
    dispatch(getGarageAdditionalSettings(id));
  }, [id]);

  useEffect(() => {
    dispatch(getAllRentalLocation());
  }, []);

  useEffect(() => {
    if (!mainSettingValue.location_id) return;
    dispatch(getServicesByRentalLocation(mainSettingValue.location_id));
  }, [mainSettingValue.location_id]);

  useEffect(() => {
    if (!rentalServicesData.length) return;
    setMappedRentTypes(
      rentalServicesData?.map((item) => ({ ...item, value: item.name?.toLowerCase()?.split(' ')?.join('_') })),
    );
  }, [rentalServicesData]);

  useEffect(() => {
    if (Object.keys(garageDetail).length === 0) return;

    setMainSettingValue({
      ...mainSettingValue,
      name: garageDetail.name,
      start_time: garageDetail.start_time,
      end_time: garageDetail.end_time,
      location_id: garageDetail.location_id,
      map_link: garageDetail.map_link,
      address_details: garageDetail.address_details,
      outside_operational_fee: garageDetail.outside_operational_fee,
    });
    setSelectedService(garageDetail.outside_operational_service ?? []);
    setIsActive(garageDetail.outside_operational_status ?? false);
  }, [garageDetail]);

  useEffect(() => {
    if (garageAdditionalSetting === null || !mappedRentTypes.length) return;

    setAdditionalSettingToUpdate(
      garageAdditionalSetting.map((item) => {
        let newSelectedCar = [];

        for (let vehicle of item.vehicles) {
          if (newSelectedCar.length === 0) {
            newSelectedCar.push({ cars: [{ ...vehicle, checked: true }], total: 1 });
          } else {
            const existingVehicle = newSelectedCar.find((item) => item.cars[0].name === vehicle.name);
            if (existingVehicle) {
              const copyNewSelectedCar = [...newSelectedCar];
              copyNewSelectedCar[copyNewSelectedCar.indexOf(existingVehicle)] = {
                cars: [...existingVehicle.cars, { ...vehicle, checked: true }],
                total: existingVehicle.total + 1,
              };
              newSelectedCar = copyNewSelectedCar;
            } else {
              newSelectedCar = [...newSelectedCar, { cars: [{ ...vehicle, checked: true }], total: 1 }];
            }
          }
        }

        return {
          ...item,
          vehicle_ids: newSelectedCar,
          category_ids: item.categories.map((item) => ({ ...item, value: item.name.toLowerCase() })),
          service: mappedRentTypes.find((type) => type.value == item.service)?.id,
        };
      }) || [],
    );
  }, [garageAdditionalSetting, mappedRentTypes]);

  const sendPayloadData = async (action, payload, fulfilledMessage, errorMessage1, withAdditionalSetting) => {
    try {
      let res;
      if (action === 'ADD') {
        res = await dispatch(addGarages(payload)).unwrap();
      } else if (action === 'UPDATE') {
        await dispatch(updateGarages({ id: garageDetail.id, payload })).unwrap();
      }

      if (withAdditionalSetting) {
        additionalSettingToUpdate.map(async (item) => {
          await dispatch(
            updateGarageAdditionalSettings({
              payload: {
                ...item,
                fee: +item.fee,
                category_ids: item.category_ids.map((item) => item.id),
                vehicle_ids: item.vehicle_ids
                  .map((item) => item.cars)
                  .map((cars) => cars.map((car) => car.id))
                  .flat(),
                service: mappedRentTypes.find((type) => type.id == item.service)?.value,
              },
              id: item.id,
            }),
          ).unwrap();
        });
        additionalSettingValue.map(async (item) => {
          if (!item.service) return;
          await dispatch(
            createGarageAdditionalSettings({
              ...item,
              garage_id: action === 'ADD' ? res.id : garageDetail.id,
              fee: +item.fee,
              category_ids: item.category_ids.map((item) => item.id),
              vehicle_ids: item.vehicle_ids
                .map((item) => item.cars)
                .map((cars) => cars.map((car) => car.id))
                .flat(),
              service: mappedRentTypes.find((type) => type.id == item.service)?.value,
            }),
          ).unwrap();
        });
      }
      showToast({ type: 'success', message: fulfilledMessage });
      navigate(-1);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: `${errorMessage1}: ${error}` });
    }
  };

  const handleTipeSewa = (id, status) => {
    const existedType = selectedService.find((item) => item === mappedRentTypes.find((el) => el.id === id).value);

    if (existedType && status) return;

    if (status) {
      setSelectedService((prevState) => [...prevState, mappedRentTypes.find((el) => el.id === id).value]);
    } else {
      const filteredData = selectedService.filter((item) => item !== mappedRentTypes.find((el) => el.id === id).value);
      setSelectedService(filteredData);
    }
  };

  const handleKeyDown = (event) => {
    // Prevent the input of commas, dots, and other non-numeric characters
    if (event.key === ',' || event.key === '.' || event.key === 'e' || event.key === '-') {
      event.preventDefault();
    }
  };

  const handleValueInput = (e) => {
    setMainSettingValue((prev) => ({ ...prev, outside_operational_fee: e.target.value }));
  };

  const handleAddGarage = async () => {
    const payload = {
      name: mainSettingValue.name,
      location_id: parseInt(mainSettingValue.location_id),
      start_time: mainSettingValue.start_time,
      end_time: mainSettingValue.end_time,
      address_details: mainSettingValue.address_details,
      map_link: mainSettingValue.map_link,
      outside_operational_fee: +mainSettingValue.outside_operational_fee,
      outside_operational_status: isActive,
      outside_operational_service: selectedService,
    };

    // validation
    const validName = mainSettingValue.name.replace(/\s/).length !== 0;
    const validLocation = mainSettingValue.location_id !== 0;
    const validStartTime = mainSettingValue.start_time.replace(/\s/).length !== 0;
    const validationEndTime = mainSettingValue.end_time.replace(/\s/).length !== 0;
    const validAddressDetails = mainSettingValue.address_details.replace(/\s/).length !== 0;
    const validMapLink = mainSettingValue.map_link.replace(/\s/).length !== 0;
    const validService = selectedService.length !== 0;
    const validFee = mainSettingValue.outside_operational_fee !== '';
    let isWithAddtionalSetting = false;

    // check validation
    if (!validName) {
      return showToast({ type: 'error', message: 'Masukan Nama Garasi' });
    } else if (!validStartTime) {
      return showToast({ type: 'error', message: 'Pilih Jam Buka' });
    } else if (!validationEndTime) {
      return showToast({ type: 'error', message: 'Pilih Jam Tutup' });
    } else if (!validLocation) {
      return showToast({ type: 'error', message: 'Pilih Lokasi Rental' });
    } else if (!validMapLink) {
      return showToast({ type: 'error', message: 'Masukan Link Gmaps' });
    } else if (!validAddressDetails) {
      return showToast({ type: 'error', message: 'Masukan Alamat Garasi' });
    } else if (!validService) {
      return showToast({ type: 'error', message: 'Pilih Tipe Sewa' });
    } else if (!validFee) {
      return showToast({ type: 'error', message: 'Masukkan Tarif Penalti' });
    }

    // check additional settings validation
    if (additionalSettingValue.length > 0) {
      for (let additionalChild of additionalSettingValue) {
        if (additionalChild.service) {
          isWithAddtionalSetting = true;
          if (additionalChild.fee === '') {
            return showToast({ type: 'error', message: 'Masukkan Tarif Penalti Setting' });
          }
        }
      }
    }

    if (additionalSettingToUpdate.length > 0) {
      for (let additionalChild of additionalSettingToUpdate) {
        if (additionalChild.service) {
          isWithAddtionalSetting = true;
          if (additionalChild.fee === '') {
            return showToast({ type: 'error', message: 'Masukkan Tarif Penalti Setting' });
          }
        }
      }
    }

    // if add garage
    if (Object.keys(garageDetail).length === 0) {
      sendPayloadData('ADD', payload, 'Berhasil Tambah Garasi', 'Gagal Tambah Garasi', isWithAddtionalSetting);
      return;
    }

    // if update garage
    sendPayloadData('UPDATE', payload, 'Berhasil Update Garasi', 'Gagal Update Garasi', isWithAddtionalSetting);
    dispatch(resetGarageDetail());
  };

  const handlePenaltiStatus = () => {
    setIsActive((prev) => !prev);
  };

  const handleLocation = (e) => {
    setMainSettingValue((prev) => ({ ...prev, location_id: e.target.id }));
    setSelectedService([]);
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const handleAddGarageSetting = () => {
    setAdditionalSettingValue((prev) => [
      ...prev,
      {
        id: 0,
        garage_id: 0,
        fee: '',
        status: false,
        service: '',
        vehicle_ids: [],
        vehicles: [],
        category_ids: [],
        categories: [],
      },
    ]);
  };

  const handleRemoveGarageSetting = async (index, settingId) => {
    if (garageAdditionalSetting?.length > 0 && settingId) {
      setShowConfirmation({
        message: 'Apakah anda yakin ingin menghapus Garasi',
        show: true,
        onClick: async () => {
          try {
            await dispatch(deleteGarageAdditionalSettings(settingId)).unwrap();
            showToast({ type: 'success', message: 'Berhasil Menghapus Pengaturan Garasi' });
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            showToast({ type: 'error', message: 'Gagal Menghapus Pengaturan Garasi' });
          } finally {
            dispatch(getGarageAdditionalSettings(id));
          }
        },
      });
      return;
    }
    setAdditionalSettingValue((prev) => prev.filter((_, idx) => idx !== index));
  };

  return (
    <div className="garage-detail">
      <header className="detail-setting-harga__header" onClick={() => navigate(-1)}>
        <LeftArrow />
        <p>Kembali</p>
      </header>

      <TableWrapper
        title={id ? 'Detail Garasi' : 'Tambah Garasi'}
        icon={<GarageFilledIcon fill="#009EF7" width="25px" height="25px" />}
      >
        <div className="garage-detail__main-setting">
          <h2 className="garage-detail__main-header">Pengaturan Utama</h2>
          <div className="garage-detail__main-setting__form">
            <InputField
              className={'garage-input'}
              label={'Nama Garasi'}
              placeholder={'Masukkan Nama Garasi'}
              value={mainSettingValue.name}
              onChange={(e) => setMainSettingValue((prev) => ({ ...prev, name: e.target.value }))}
              id="nama-garasi"
              name={'nama-garasi'}
            />

            <div className="garage-detail__time">
              <SelectFieldTime
                label="Jam Buka"
                htmlFor="jam-buka"
                placeholder="00:00"
                value={mainSettingValue.start_time}
                onChange={(hour, minute) => {
                  setMainSettingValue((prev) => ({
                    ...prev,
                    start_time: `${hour ? hour : '00'}:${minute ? minute : '00'}`,
                  }));
                }}
                icon={false}
                showAllHours={true}
              />
              <SelectFieldTime
                label="Jam Tutup"
                htmlFor="jam-tutup"
                placeholder="00:00"
                value={mainSettingValue.end_time}
                onChange={(hour, minute) => {
                  setMainSettingValue((prev) => ({
                    ...prev,
                    end_time: `${hour ? hour : '00'}:${minute ? minute : '00'}`,
                  }));
                }}
                disable={mainSettingValue.start_time === '' ? true : false}
                icon={false}
                showAllHours={true}
              />
            </div>

            <SelectFieldDropdown
              label="Lokasi Rental"
              type="select"
              htmlFor="garage-city"
              data={data}
              value={mainSettingValue.location_id}
              placeholder={'Pilih Lokasi'}
              onChange={handleLocation}
            />

            <InputField
              className={'garage-input'}
              label={'Link Gmaps'}
              placeholder={'Masukkan Link'}
              value={mainSettingValue.map_link}
              onChange={(e) => setMainSettingValue((prev) => ({ ...prev, map_link: e.target.value }))}
              id="garasi-map-link"
              name={'map_link'}
            />

            <InputField
              className={'garage-input'}
              label={'Alamat Garasi'}
              placeholder={'Masukkan Alamat Garasi'}
              value={mainSettingValue.address_details}
              onChange={(e) => setMainSettingValue((prev) => ({ ...prev, address_details: e.target.value }))}
              icon={<MagnifierIcon />}
            />

            <InputField
              label="Tarif Penalti"
              type="number"
              placeholder="Masukkan Jumlah Tarif Penalti"
              value={mainSettingValue.outside_operational_fee ?? ''}
              onChange={handleValueInput}
              onWheel={numberInputOnWheelPreventChange}
              // min={0}
              onKeyDown={handleKeyDown}
            />

            <SelectFieldDropdown
              label="Tipe Sewa"
              htmlFor="rent-type"
              data={mappedRentTypes}
              placeholder="Pilih Tipe Sewa"
              value={selectedService}
              onChange={handleTipeSewa}
              checkBox
              disable={!mainSettingValue.location_id}
            />

            <div className="garage-detail__operational-status">
              <p>Penalti Status</p>

              <div className="garage-detail__status-info">
                <div className="user__toggle-status">
                  <label className="switch">
                    <input type="checkbox" onChange={handlePenaltiStatus} role={'switch'} checked={isActive} />
                    <span className="slider"></span>
                  </label>
                </div>

                {isActive ? (
                  <span className="user__status-badge user__status-badge--active">Aktif</span>
                ) : (
                  <span className="user__status-badge user__status-badge--blocked">Inactive</span>
                )}
              </div>
            </div>
          </div>
        </div>

        {isActive && (
          <div className="garage-detail__additional-setting">
            <h2 className="garage-detail__additional-header">Pengaturan Lainnya</h2>
            {additionalSettingToUpdate.map((item, idx) => (
              <div key={idx}>
                <AdditionalGarageSetting
                  serviceData={mappedRentTypes?.filter((item) => selectedService.find((type) => type === item.value))}
                  locationId={mainSettingValue.location_id}
                  data={item}
                  setData={setAdditionalSettingToUpdate}
                  numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
                  handleKeyDown={handleKeyDown}
                  index={idx}
                />
                <div className="garage-detail__divider">
                  <Button
                    onClick={() => handleRemoveGarageSetting(idx, item.id)}
                    className="garage-detail__remove-button"
                    width={26}
                    height={26}
                  >
                    <DeleteIcon />
                  </Button>
                </div>
              </div>
            ))}
            {additionalSettingValue.map((item, idx) => (
              <div key={idx}>
                <AdditionalGarageSetting
                  serviceData={mappedRentTypes?.filter((item) => selectedService.find((type) => type === item.value))}
                  locationId={mainSettingValue.location_id}
                  data={item}
                  setData={setAdditionalSettingValue}
                  numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
                  handleKeyDown={handleKeyDown}
                  index={idx}
                />
                {idx === additionalSettingValue.length - 1 ? (
                  <div className="garage-detail__divider">
                    <Button
                      onClick={handleAddGarageSetting}
                      className="garage-detail__add-button"
                      width={26}
                      height={26}
                    >
                      <PlusIcon />
                    </Button>
                  </div>
                ) : (
                  <div className="garage-detail__divider">
                    <Button
                      onClick={() => handleRemoveGarageSetting(idx, item.id)}
                      className="garage-detail__remove-button"
                      width={26}
                      height={26}
                    >
                      <DeleteIcon />
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        <div className="garage-detail__buttons">
          <Button type="button" variant="outline" onClick={() => navigate(-1)} width={253} className="garage-btn">
            Kembali
          </Button>
          {(checkPermission(offCanvasMenu, currentMenu, 'update') || Object.keys(garageDetail).length === 0) && (
            <Button
              type="button"
              onClick={handleAddGarage}
              width={253}
              className="garage-btn"
              style={{ marginLeft: '10px' }}
            >
              {Object.keys(garageDetail).length === 0 ? 'Tambah' : 'Simpan'}
            </Button>
          )}
        </div>
      </TableWrapper>
    </div>
  );
};

export default GarageDetailComponent;
