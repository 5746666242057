import { AppContext } from 'components/Context/AppContext';
import { useContext } from 'react';
import ReactDOM from 'react-dom';

// import semua modal ke sini
import TransactionDetailModal from './TransactionDetailModal';
import Spinner from './Spinner';
import SendNotificationModal from './SendNotificationModal';
import BlockConfirmationModal from './BlockConfirmationModal';
import AddCarModal from './AddCarModal';
import ConfirmationModal from './ConfirmationModal';
import AddTourScheduleModal from './AddTourModal';
import AddCityModal from './AddCityModal';
import RefundDetailModal from './RefundDetailModal';
import NotificationForm from './NotificationForm';
import NotificationTemplate from './NotificationTemplateModal';
import DeleteCityConfirmation from './DeleteCityConfirmation';
import DepositDetailModal from './DepositDetailModal/deposit-detail-modal';
import DepositConfirmationModal from './DepositConfirmationModal/deposit-confirmation-modal';
import AddDeliveryLocationModal from './AddDeliveryModal';
import ContactDetailModal from './ContactDetailModal';
import AddAboutUsImageModal from './AddAboutUsImageModal';
import AddBannerModal from './AddBannerModal';
import AddBrandCarModal from './AddBrandCarModal';
import CancelPaymentMessageModal from './CancelPaymentMessageModal';
import AddRolesModal from './AddRolesModal';
import AddCategoryModal from './AddCategoryModal';
import AddViolations from './AddViolations';
import AssignDriverModal from './AssignDriverModal';
import ZonePrice from './ZonePrice';
import RejectOrderModal from './RejectOrderModal';
import LanguageModal from './AddLanguageModal';
import AddRolesAssignModal from './AddRolesAssignModal';
import AddAirportLocationTypeModal from './AddAirportLocationTypeModal';
import AddAirportLocationModal from './AddAirportLocationModal';
import AdditionalImageModal from './AdditionalImageModal';
import ReviewIdentityModal from './ReviewIdentityModal';
import PreviewImageIdentity from './PreviewImageIdentity';

export default function ModalRenderer() {
  const {
    // supaya modal bisa diakses di mana aja, state ditaro di context
    showSpinner,
    showTransactionDetail,
    showSendNotification,
    showBlockConfirmation,
    showAddCar,
    showConfirmation,
    showAddTourSchedule,
    showAddNewCity,
    showRefundDetail,
    showNotificationForm,
    showNotificationTemplate,
    showCityDeleteConfirmation,
    showDepositDetailModal,
    showDepositConfirmation,
    showAddDeliveryLocation,
    showContactUsDetail,
    showAddAboutUsImage,
    showAddBannerModal,
    showAddBrandCar,
    showCancelPaymentMessage,
    showAddRolesModal,
    showAddRolesAssignModal,
    showAddCategory,
    showAddViolationsModal,
    showAssignDriver,
    showZonePrice,
    showRejectOrderModal,
    showAddNewLanguage,
    showAddAirportLocationType,
    showAddAirportLocation,
    showAdditionalImageModal,
    showReviewIdentityModal,
    showPreviewImageIdentity,
  } = useContext(AppContext);

  // render setiap modal pake if kaya cara di bawah

  if (showTransactionDetail) {
    return ReactDOM.createPortal(<TransactionDetailModal />, document.getElementById('modal'));
  }

  if (showSpinner) {
    return ReactDOM.createPortal(<Spinner />, document.getElementById('modal'));
  }

  if (showSendNotification) {
    return ReactDOM.createPortal(<SendNotificationModal />, document.getElementById('modal'));
  }

  if (showBlockConfirmation.show) {
    return ReactDOM.createPortal(<BlockConfirmationModal />, document.getElementById('modal'));
  }

  if (showAddCar) {
    return ReactDOM.createPortal(<AddCarModal />, document.getElementById('modal'));
  }

  if (showConfirmation.show) {
    return ReactDOM.createPortal(<ConfirmationModal />, document.getElementById('modal'));
  }

  if (showAddTourSchedule) {
    return ReactDOM.createPortal(<AddTourScheduleModal />, document.getElementById('modal'));
  }

  if (showAddNewCity) {
    return ReactDOM.createPortal(<AddCityModal />, document.getElementById('modal'));
  }

  if (showRefundDetail) {
    return ReactDOM.createPortal(<RefundDetailModal />, document.getElementById('modal'));
  }

  if (showNotificationForm) {
    return ReactDOM.createPortal(<NotificationForm />, document.getElementById('modal'));
  }

  if (showNotificationTemplate) {
    return ReactDOM.createPortal(<NotificationTemplate />, document.getElementById('modal'));
  }

  if (showCityDeleteConfirmation) {
    return ReactDOM.createPortal(<DeleteCityConfirmation />, document.getElementById('modal'));
  }

  if (showDepositDetailModal.show) {
    return ReactDOM.createPortal(<DepositDetailModal />, document.getElementById('modal'));
  }

  if (showDepositConfirmation) {
    return ReactDOM.createPortal(<DepositConfirmationModal />, document.getElementById('modal'));
  }

  if (showAddDeliveryLocation.show) {
    return ReactDOM.createPortal(<AddDeliveryLocationModal />, document.getElementById('modal'));
  }

  if (showContactUsDetail) {
    return ReactDOM.createPortal(<ContactDetailModal />, document.getElementById('modal'));
  }

  if (showAddAboutUsImage) {
    return ReactDOM.createPortal(<AddAboutUsImageModal />, document.getElementById('modal'));
  }

  if (showAddBannerModal) {
    return ReactDOM.createPortal(<AddBannerModal />, document.getElementById('modal'));
  }

  if (showAddBrandCar) {
    return ReactDOM.createPortal(<AddBrandCarModal />, document.getElementById('modal'));
  }

  if (showCancelPaymentMessage) {
    return ReactDOM.createPortal(<CancelPaymentMessageModal />, document.getElementById('modal'));
  }

  if (showAddRolesModal) {
    return ReactDOM.createPortal(<AddRolesModal />, document.getElementById('modal'));
  }

  if (showAddRolesAssignModal) {
    return ReactDOM.createPortal(<AddRolesAssignModal />, document.getElementById('modal'));
  }

  if (showAddCategory) {
    return ReactDOM.createPortal(<AddCategoryModal />, document.getElementById('modal'));
  }

  if (showAddViolationsModal) {
    return ReactDOM.createPortal(<AddViolations />, document.getElementById('modal'));
  }

  if (showAssignDriver) {
    return ReactDOM.createPortal(<AssignDriverModal />, document.getElementById('modal'));
  }

  if (showZonePrice) {
    return ReactDOM.createPortal(<ZonePrice />, document.getElementById('modal'));
  }

  if (showRejectOrderModal) {
    return ReactDOM.createPortal(<RejectOrderModal />, document.getElementById('modal'));
  }

  if (showAddNewLanguage) {
    return ReactDOM.createPortal(<LanguageModal />, document.getElementById('modal'));
  }

  if (showAddAirportLocationType) {
    return ReactDOM.createPortal(<AddAirportLocationTypeModal />, document.getElementById('modal'));
  }

  if (showAddAirportLocation) {
    return ReactDOM.createPortal(<AddAirportLocationModal />, document.getElementById('modal'));
  }

  if (showAdditionalImageModal) {
    return ReactDOM.createPortal(<AdditionalImageModal />, document.getElementById('modal'));
  }

  if (showReviewIdentityModal.show) {
    return ReactDOM.createPortal(
      <>
        <ReviewIdentityModal />
        {showPreviewImageIdentity.show && <PreviewImageIdentity />}
      </>,
      document.getElementById('modal'),
    );
  }

  return '';
}
