import { createSlice } from '@reduxjs/toolkit';
import { fetchAirportTransferOrder, getAirportPackageById, getVehicleAirportTransferPackage } from './actions';

const initialState = {
  data: {},
  selected: {},
  airportTransferCar: {},
  page: 1,
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const airportTransferOrder = createSlice({
  name: 'airportTransferOrder',
  initialState,
  reducers: {
    // reducers goes here
    saveVehicleData: (state, action) => {
      return { ...state, vehicleList: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAirportTransferOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAirportTransferOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchAirportTransferOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(getVehicleAirportTransferPackage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.airportTransferCar = {};
      })
      .addCase(getVehicleAirportTransferPackage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getVehicleAirportTransferPackage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.airportTransferCar = action.payload;
      })
      .addCase(getAirportPackageById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.selected = {};
      })
      .addCase(getAirportPackageById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAirportPackageById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selected = action.payload;
      });
  },
});

export const { saveVehicleData } = airportTransferOrder.actions;

export default airportTransferOrder.reducer;
