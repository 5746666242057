import { createSlice } from '@reduxjs/toolkit';
import { getAllDeliveryLocations, getDeliveryLocationDetail, updateDeliveryLocation } from './actions';

const initialState = {
  data: {},
  selectedLocation: {},
  isLoading: true,
  isError: false,
  errorMessage: {}, // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const deliveryLocation = createSlice({
  name: 'deliveryLocation',
  initialState,
  reducers: {
    // reducers goes here
    resetSelection: (state) => {
      state.selectedLocation = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDeliveryLocations.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllDeliveryLocations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllDeliveryLocations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateDeliveryLocation.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(updateDeliveryLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateDeliveryLocation.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getDeliveryLocationDetail.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.selectedLocation = {};
      })
      .addCase(getDeliveryLocationDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDeliveryLocationDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedLocation = action.payload;
      });
  },
});

export const { resetSelection } = deliveryLocation.actions;
export const getDeliveryLocations = (state) => state.deliveryLocation.data;
export const isDeliveryLocationsLoading = (state) => state.deliveryLocation.isLoading;

export default deliveryLocation.reducer;
