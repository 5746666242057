import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { uploadImageAddon, uploadImageAddonUpddate } from 'features/addon/actions';
import { resetImageVarieties, resetImageVarietiesUpdate } from 'features/addon/slice';
import { InputField } from 'components/Global';
import UploadVariationImage from '../UploadVariationImage';
import { ReactComponent as PlusIcon } from 'icons/plus-icon.svg';
import { ReactComponent as DeleteIcon } from 'icons/delete-icon.svg';
import { checkEmptyObject } from 'utils/functionality';

const ColorVariation = ({ colorVariation, setColorVariation, updateObj, setUpdateObj }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const parentColorVariationRef = useRef(null);

  const { showToast } = useAppContext();

  const { imageVarieties, imageVarietiesUpdate } = useSelector((state) => state.addon);

  const [variationColorName, setVariationColorName] = useState('');
  const [variationStock, setVariationStock] = useState('');
  const [variationMaxQuantity, setVariationMaxQuantity] = useState('');
  const [variationImages, setVariationImages] = useState([]);

  const [scrollVariationWidth, setScrollVariationWidth] = useState(520);

  const pathName = location.pathname.split('/')[2];

  // FUNCTION TO PREVENT SCROLLING ON INPUT NUMBER TYPE
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const addVariationImageHandler = async (image) => {
    try {
      await dispatch(uploadImageAddon(image)).unwrap();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: `${error.message}` });
    }
  };

  const deleteVariationImageHandler = (id) => {
    const newVariationImages = [...variationImages].filter((img) => img.id !== id);
    setVariationImages(newVariationImages);
  };

  const addVariationHandler = () => {
    const validNameVariation = variationColorName.replace(/\s/, '') !== '';
    const validStockVariation = variationStock !== 0 && variationStock !== '';
    const validMaxQuantity = variationMaxQuantity !== 0 && variationMaxQuantity !== '';
    const validImagesVariation = variationImages.length !== 0;

    if (!validNameVariation) {
      return showToast({ type: 'error', message: 'Masukan Variasi Warna' });
    }

    if (!validStockVariation) {
      return showToast({ type: 'error', message: 'Masukan Jumlah Stock' });
    }

    if (!validMaxQuantity) {
      return showToast({ type: 'error', message: 'Masukan Maksimal Kuantitas' });
    }

    if (!validImagesVariation) {
      return showToast({ type: 'error', message: 'Masukan Foto Barang' });
    }

    const variationColorObject = {
      id: Math.random() + 1 + '', // id temporary
      color: variationColorName,
      stock: variationStock,
      max_order: variationMaxQuantity,
      images: variationImages,
    };

    setColorVariation((prevState) => [...prevState, variationColorObject]);

    setVariationColorName('');
    setVariationStock('');
    setVariationMaxQuantity('');
    setVariationImages([]);
    dispatch(resetImageVarieties());
    dispatch(resetImageVarietiesUpdate());

    if (pathName === 'detail') {
      if (updateObj.variaties_to_add) {
        setUpdateObj({
          ...updateObj,
          variaties_to_add: [...updateObj.variaties_to_add, variationColorObject],
        });
      } else {
        setUpdateObj({ ...updateObj, variaties_to_add: [variationColorObject] });
      }
    }
  };

  const deleteVariationHandler = (id) => {
    const filteredColorVariation = colorVariation.filter((item) => item.id !== id);
    setColorVariation(filteredColorVariation);

    if (pathName === 'detail') {
      if (updateObj.variaties_to_add) {
        const exisitingVariety = updateObj.variaties_to_add.find((item) => item.id == id);

        if (exisitingVariety) {
          const copyUpdateObj = { ...updateObj };

          if (updateObj.variaties_to_add.length === 1) {
            delete copyUpdateObj.variaties_to_add;
            setUpdateObj(copyUpdateObj);
          } else {
            const filteredCopyUpdateObj = copyUpdateObj.variaties_to_add.filter(
              (item) => item.id != exisitingVariety.id,
            );
            setUpdateObj({ ...copyUpdateObj, variaties_to_add: filteredCopyUpdateObj });
          }
        }
      } else {
        if (updateObj.varieties_to_delete) {
          setUpdateObj({ ...updateObj, varieties_to_delete: [...updateObj.varieties_to_delete, id] });
        } else {
          setUpdateObj({ ...updateObj, varieties_to_delete: [id] });
        }
      }
    }
  };

  const addVariationImageUpdateHandler = async (image, id) => {
    try {
      await dispatch(uploadImageAddonUpddate({ payload: image, id })).unwrap();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: `${error.message}` });
    }
  };

  const deleteImageThatHasBeedAdded = (item, img) => {
    if (pathName === 'add-item') {
      const newColorVariation = colorVariation.map((variation) => {
        if (variation.id === item.id) {
          return {
            ...variation,
            images: variation.images.filter((image) => image.id !== img.id),
          };
        }

        return item;
      });

      setColorVariation(newColorVariation);
    }

    if (pathName === 'detail') {
      const newColorVariation = colorVariation.map((variation) => {
        if (variation.id === item.id) {
          if (variation.images_to_add) {
            const existingImage = variation.images_to_add.find(
              (image) =>
                image.file_name ===
                `/${img.file_name
                  .split('/')
                  .slice(img.file_name.split('/').length - 3, img.file_name.split('/').length)
                  .join('/')}`,
            );

            if (existingImage) {
              if (variation.images_to_add.length === 1) {
                // eslint-disable-next-line no-unused-vars
                const { images_to_add, ...rest } = variation;
                return {
                  ...rest,
                  images: variation.images.filter((image) => image.id !== img.id),
                };
              } else {
                return {
                  ...variation,
                  images: variation.images.filter((image) => image.id !== img.id),
                  images_to_add: variation.images_to_add.filter(
                    (image) =>
                      image.file_name !==
                      `/${existingImage.file_name
                        .split('/')
                        .slice(existingImage.file_name.split('/').length - 3, existingImage.file_name.split('/').length)
                        .join('/')}`,
                  ),
                };
              }
            }
          } else {
            if (variation.images_to_delete) {
              return {
                ...variation,
                images: variation.images.filter((image) => image.id !== img.id),
                images_to_delete: [...variation.images_to_delete, img.id],
              };
            } else {
              return {
                ...variation,
                images: variation.images.filter((image) => image.id !== img.id),
                images_to_delete: [img.id],
              };
            }
          }
        }

        return variation;
      });

      setColorVariation(newColorVariation);
    }
  };

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        const width = entries[0].contentRect.width;
        setScrollVariationWidth(width);
      }
    });

    if (parentColorVariationRef.current) {
      observer.observe(parentColorVariationRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!imageVarieties || checkEmptyObject(imageVarieties)) return;

    const objImage = {
      id: Math.random() + '',
      file_name: `${process.env.REACT_APP_IMAGES}${imageVarieties}`,
    };

    setVariationImages((prevState) => [...prevState, objImage]);
  }, [imageVarieties]);

  useEffect(() => {
    if (!imageVarietiesUpdate || checkEmptyObject(imageVarietiesUpdate)) return;

    const objImage = {
      id: Math.random() + '',
      file_name: `${process.env.REACT_APP_IMAGES}${imageVarietiesUpdate.data}`,
    };

    if (pathName === 'add-item') {
      const newColorVariation = colorVariation.map((item) => {
        if (item.id === imageVarietiesUpdate.id) {
          return {
            ...item,
            images: [...item.images, objImage],
          };
        }

        return item;
      });
      setColorVariation(newColorVariation);
    }

    if (pathName === 'detail') {
      const newColorVariation = colorVariation.map((item) => {
        if (item.id === imageVarietiesUpdate.id) {
          if (item.images_to_add) {
            return {
              ...item,
              images: [...item.images, objImage],
              images_to_add: [...item.images_to_add, { file_name: imageVarietiesUpdate.data }],
            };
          } else {
            return {
              ...item,
              images: [...item.images, objImage],
              images_to_add: [{ file_name: imageVarietiesUpdate.data }],
            };
          }
        }

        return item;
      });

      setColorVariation(newColorVariation);
      return;
    }
  }, [imageVarietiesUpdate]);

  return (
    <div className="additional-color-variation">
      <ul>
        {/* LIST VARIATION COLOR COMPONENT */}
        {colorVariation &&
          colorVariation.length !== 0 &&
          colorVariation.map((item) => (
            <li key={item.id}>
              <InputField
                label="Variasi Warna (Optional)"
                htmlFor={'variasi-warna-' + item.id}
                value={item.color}
                onChange={(e) => {
                  const newColorVariation = colorVariation.map((variation) => {
                    if (variation.id === item.id) {
                      return {
                        ...variation,
                        color: e.target.value,
                      };
                    }

                    return variation;
                  });

                  setColorVariation(newColorVariation);
                }}
                placeholder="Tulis Variasi Warna"
              />

              <div className="stock-and-quantity">
                <InputField
                  type="number"
                  label="Jumlah Stock"
                  htmlFor={'jumlah-stock-' + item.id}
                  value={item.stock}
                  onChange={(e) => {
                    const newColorVariation = colorVariation.map((variation) => {
                      if (variation.id === item.id) {
                        return {
                          ...variation,
                          stock: e.target.value,
                        };
                      }

                      return variation;
                    });

                    setColorVariation(newColorVariation);
                  }}
                  placeholder="0"
                  onWheel={numberInputOnWheelPreventChange}
                />
                <InputField
                  type="number"
                  label="Max Kuantitas"
                  htmlFor={'max-kuantitas-' + item.id}
                  value={item.max_order}
                  onChange={(e) => {
                    const newColorVariation = colorVariation.map((variation) => {
                      if (variation.id === item.id) {
                        return {
                          ...variation,
                          max_order: e.target.value,
                        };
                      }

                      return variation;
                    });

                    setColorVariation(newColorVariation);
                  }}
                  placeholder="0"
                  onWheel={numberInputOnWheelPreventChange}
                />
              </div>

              <div className="images" ref={parentColorVariationRef}>
                <h3>Foto Barang</h3>
                <div style={{ width: `${scrollVariationWidth}px` }} className="list-container">
                  <div className="image-list">
                    <UploadVariationImage
                      id={'upload-image-component-' + item.id}
                      uploadImageAction={(image) => addVariationImageUpdateHandler(image, item.id)}
                    />

                    {item.images &&
                      item.images.length !== 0 &&
                      item.images.map((img) => (
                        <div key={img.id} className="uploaded-image">
                          <div className="image-wrapper">
                            <img
                              src={img.file_name}
                              alt={img.file_name.split('/')[img.file_name.split('/').length - 1]}
                            />
                          </div>
                          <div className="icon-wrapper">
                            <DeleteIcon className="icon" onClick={() => deleteImageThatHasBeedAdded(item, img)} />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="add-button">
                <div className="line" />
                <div className="circle">
                  <div role="button" onClick={() => deleteVariationHandler(item.id)}>
                    <DeleteIcon />
                  </div>
                </div>
              </div>
            </li>
          ))}

        {/* ADD VARIATION COLOR COMPONENT */}
        <li>
          <InputField
            label="Variasi Warna (Optional)"
            htmlFor="variasi-warna"
            value={variationColorName}
            onChange={(e) => setVariationColorName(e.target.value)}
            placeholder="Tulis Variasi Warna"
          />

          <div className="stock-and-quantity">
            <InputField
              type="number"
              label="Jumlah Stock"
              htmlFor="jumlah-stock"
              value={variationStock}
              onChange={(e) => setVariationStock(e.target.value)}
              placeholder="0"
              onWheel={numberInputOnWheelPreventChange}
            />
            <InputField
              type="number"
              label="Max Kuantitas"
              htmlFor="max-kuantitas"
              value={variationMaxQuantity}
              onChange={(e) => setVariationMaxQuantity(e.target.value)}
              placeholder="0"
              onWheel={numberInputOnWheelPreventChange}
            />
          </div>

          <div className="images" ref={parentColorVariationRef}>
            <h3>Foto Barang</h3>
            <div style={{ width: `${scrollVariationWidth}px` }} className="list-container">
              <div className="image-list">
                <UploadVariationImage id="upload-image-add-component" uploadImageAction={addVariationImageHandler} />

                {variationImages.map((img) => (
                  <div key={img.id} className="uploaded-image">
                    <div className="image-wrapper">
                      <img src={img.file_name} alt={img.file_name.split('/')[img.file_name.split('/').length - 1]} />
                    </div>
                    <div className="icon-wrapper">
                      <DeleteIcon className="icon" onClick={() => deleteVariationImageHandler(img.id)} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="add-button">
            <div className="line" />
            <div className="circle">
              <div role="button" onClick={addVariationHandler}>
                <PlusIcon />
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ColorVariation;
