import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useAppContext } from 'components/Context/AppContext';
import { Button } from 'components/Global';
import { ReactComponent as Mail } from 'icons/message-icon.svg';
import { ReactComponent as Phone } from 'icons/phone-icon.svg';
import ModalsNotification from '../ModalsNotification';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getNotificationsTemplate, sendNotification } from 'features/notification/actions';
import { setNotificationPayload } from 'features/notification/notifSlice';
import { toPascalCase } from 'utils/functionality';

const SendNotificationModal = () => {
  const { setShowSendNotification, showToast } = useAppContext();
  const { templates } = useSelector((state) => state.notification?.data);
  const { sendNotificationPayload } = useSelector((state) => state.notification);
  const [method, setMethod] = useState('native');
  const [selectedTemplate, setSelectedTemplate] = useState({
    name: '',
    code: '',
  });
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotificationsTemplate({ page: 1, limit: 0 }));
  }, []);

  const notifications = templates?.map((item) => ({ name: item.name, code: item.code }));

  const sendHandler = async () => {
    if (!selectedTemplate.name) {
      showToast({
        type: 'warning',
        message: 'Harap Memilih Template Notifikasi',
      });
      return;
    }

    let payload = {
      ...sendNotificationPayload,
      provider: method,
      type: 'reminder',
      title: `Get And Ride - ${toPascalCase(selectedTemplate.name)}`,
      template_code: selectedTemplate.code,
    };

    try {
      await dispatch(sendNotification(payload)).unwrap();
      showToast({
        type: 'success',
        message: 'Berhasil Mengirim Notifikasi',
      });
    } catch (error) {
      showToast({
        type: 'error',
        message: 'Gagal Mengirim Notifikasi',
      });
    }

    dispatch(setNotificationPayload({}));
    setShowSendNotification(false);
  };

  function handleAddTemplate() {
    setShowSendNotification(false);
    navigate('/notification');
  }

  const handleChooseTemplate = (template) => {
    setSelectedTemplate(template);
    setShow(false);
  };

  return (
    <ModalsNotification setState={setShowSendNotification} onAddTemplate={handleAddTemplate}>
      <div className="notification-modal">
        <div className="notification__select-wrapper">
          <div
            // id={htmlFor}
            // name={name}
            // onChange={onChange}
            className="notification__select"
            style={{ paddingRight: '40px', fontSize: '16px' }}
            onClick={() => setShow((prev) => !prev)}
          >
            {selectedTemplate.name ? selectedTemplate.name : <p>Pilih Notifikasi</p>}
          </div>
          {show && (
            <div className="notification__list">
              {notifications &&
                notifications.length > 0 &&
                notifications.map((item) => (
                  <span
                    key={item.code}
                    className="notification__list-item"
                    onClick={() => handleChooseTemplate({ name: item.name, code: item.code })}
                  >
                    {item.name}
                  </span>
                ))}
            </div>
          )}
        </div>
        <div className="notification-methods">
          <h3>Pilih Salah Satu</h3>
          <button onClick={() => setMethod('native')} className={clsx('method', method === 'native' && 'active')}>
            <figure className="icon">
              <Phone height="23px" />
            </figure>
            <span>Native</span>
          </button>
          <button onClick={() => setMethod('email')} className={clsx('method', method === 'email' && 'active')}>
            <figure className="icon">
              <Mail width="20px" />
            </figure>
            <span>Email</span>
          </button>
          {/* <button onClick={() => setMethod('wa')} className={clsx('method', method === 'wa' && 'active')}>
            <figure className="icon">
              <Whatsapp width="21px" height="21px" />
            </figure>
            <span>Whatsapp</span>
          </button> */}
        </div>
        <div className="notification-buttons">
          <Button wfull size="md" className="button" onClick={sendHandler}>
            Kirim Notifikasi
          </Button>
          <Button variant="outline" wfull size="md" className="button" onClick={() => setShowSendNotification(false)}>
            Kembali
          </Button>
        </div>
      </div>
    </ModalsNotification>
  );
};

export default SendNotificationModal;
