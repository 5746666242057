import clsx from 'clsx';

const InputField = ({
  label,
  placeholder,
  value, // state
  onChange, // setState
  className,
  type = 'text',
  style,
  icon,
  htmlFor,
  onIconClick,
  price,
  disable,
  onWheel = () => {},
  iconPosition,
  readOnly = false,
  ...restProps
}) => {
  return (
    <div className={clsx('g-input', className)} style={style}>
      {label && (
        <label htmlFor={htmlFor} className="input-text-title" style={{ textAlign: 'left' }}>
          {label}
        </label>
      )}
      <section className="field-wrapper with-icon" style={{ margin: 0 }}>
        {icon && iconPosition === 'start' ? <div className={`input-icon-left`}>{icon}</div> : null}
        <input
          className={clsx(
            'input-text-field',
            icon && iconPosition === 'end' && 'with-icon',
            icon && iconPosition === 'start' && 'with-icon-left',
            price && 'with-currency',
            disable && 'disable',
          )}
          type={type}
          id={htmlFor}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onWheel={onWheel}
          readOnly={readOnly}
          disabled={disable}
          {...restProps}
        />
        {price ? (
          <div className={`input-price price`} onClick={onIconClick}>
            IDR
          </div>
        ) : null}
        {icon && iconPosition === 'end' ? (
          <div className={`input-icon password`} onClick={onIconClick}>
            {icon}
          </div>
        ) : null}
      </section>
    </div>
  );
};

export default InputField;
