import { Button, InputField, SelectFieldVehicle, TableWrapper } from 'components/Global';
import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as DPFilledIcon } from 'icons/dp-filled-icon.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRentalLocation } from 'features/rental-location/actions';
import { getVehiclesByFilter } from 'features/vehicle/action';
import CheckboxField from 'components/Global/CheckboxField';
import {
  createBusinessRule,
  editBusinessRuleById,
  getBusinessRuleById,
  getBusinessRuleFormula,
  getServices,
} from 'features/business-rules/actions';
import { checkEmptyObject, checkPermission } from 'utils/functionality';
import { getFacilities, getSubServices } from 'features/business-rules/slice';
import { useAppContext } from 'components/Context/AppContext';
import { isEmpty } from 'utils/helpers';
import ReactDOM from 'react-dom';
import PriceSimulationModal from '../../../../Modals/PriceSimulationModal';

const SERVICE_DATA = [
  {
    id: 0,
    name: 'With Driver',
    value: 'with driver',
  },
  {
    id: 1,
    name: 'Without Driver',
    value: 'without driver',
  },
  {
    id: 2,
    name: 'Airport Transfer',
    value: 'airport transfer',
  },
];

const TIPE_SETTING = [
  {
    id: 1,
    name: 'Lokasi',
    value: 'location',
  },
  {
    id: 2,
    name: 'Layanan',
    value: 'service',
  },
  {
    id: 3,
    name: 'Kendaraan',
    value: 'vehicle',
  },
];

const DownPaymentDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rentalLocation = useSelector((state) => state.rentalLocation.data);
  const allVehicle = useSelector((state) => state.vehicle.data);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const {
    formula: businessRuleFormula,
    services,
    subServices,
    selected: businessRuleDetail,
  } = useSelector((state) => state.businessRules);
  const { showToast } = useAppContext();
  const [businessRuleName, setBusinessRuleName] = useState('');
  const [selectedTipe, setSelectedTipe] = useState(0);
  const [selectedSubService, setSelectedSubService] = useState([]);
  const [editDataSubService, setEditDataSubService] = useState({
    toAdd: [],
    toRemove: [],
  });
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [editDataLocation, setEditDataLocation] = useState({
    toAdd: [],
    toRemove: [],
  });
  const [mappedLocationList, setMappedLocationList] = useState([]);
  const [mappedFormula, setMappedFormula] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [selectedFormula, setSelectedFormula] = useState([]);
  const [editDataFormula, setEditDataFormula] = useState({
    toAdd: [],
    toRemove: [],
  });
  const [selectedThreshold, setSelectedThreshold] = useState({
    min: 0,
    max: 0,
  });
  const [formulaValue, setFormulaValue] = useState(0);
  const [showPriceSimulationModal, setShowPriceSimulationModal] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const backHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getAllRentalLocation());
    dispatch(getBusinessRuleFormula());
    dispatch(getServices());
  }, []);

  useEffect(() => {
    if (!id) return;
    dispatch(getBusinessRuleById(id));
  }, [id]);

  useEffect(() => {
    if (!services?.length) return;
    dispatch(getSubServices('sewa mobil'));
    dispatch(getFacilities());
  }, [services]);

  useEffect(() => {
    if (!selectedLocation || !selectedSubService) return;
    dispatch(
      getVehiclesByFilter({
        locationRental: selectedLocation.map((item) => item.id),
        supportDriver: selectedSubService.includes('with driver') ? 1 : 0,
        page: 1,
      }),
    );
  }, [selectedLocation, selectedSubService]);

  // Populate data value if is detail page
  useEffect(() => {
    if (checkEmptyObject(businessRuleDetail)) return;
    if (businessRuleDetail?.business_rule_vehicle?.length > 0) {
      setSelectedTipe(3);
    } else if (businessRuleDetail?.business_rule_subservices?.length > 0) {
      setSelectedTipe(2);
    } else if (businessRuleDetail?.business_rule_location?.length > 0) {
      setSelectedTipe(1);
    }
    setSelectedLocation(
      businessRuleDetail?.business_rule_location?.map((item) => ({ ...item, value: item.name.toLowerCase() })) || [],
    );
    setSelectedFormula(businessRuleDetail?.formula_variables || []);
    setSelectedThreshold(businessRuleDetail.rule_threshold || []);
    setBusinessRuleName(businessRuleDetail.name);
    setFormulaValue(businessRuleDetail?.formula_percentage?.value);
  }, [businessRuleDetail]);

  // Map subservice value if detail page
  useEffect(() => {
    if (!subServices.length || checkEmptyObject(businessRuleDetail)) return;
    let newSelectedSubServicesArr = [];
    businessRuleDetail?.business_rule_subservices?.map((item) =>
      subServices.map((sub) =>
        sub.id == item.id
          ? newSelectedSubServicesArr.push(sub.name.toLowerCase())
          : sub.facilities.map((fac) =>
              fac.id == item.id ? newSelectedSubServicesArr.push(fac.name.toLowerCase()) : undefined,
            ),
      ),
    );
    setSelectedSubService(newSelectedSubServicesArr);
  }, [subServices, businessRuleDetail]);

  useEffect(() => {
    if (checkEmptyObject(businessRuleDetail)) return;
    if (!businessRuleDetail.business_rule_vehicle) return;
    let newSelectedCar = [];

    for (let vehicle of businessRuleDetail.business_rule_vehicle) {
      if (newSelectedCar.length === 0) {
        newSelectedCar.push({ cars: [{ ...vehicle, checked: true }], total: 1 });
      } else {
        const existingVehicle = newSelectedCar.find((item) => item.cars[0].name === vehicle.name);
        if (existingVehicle) {
          const copyNewSelectedCar = [...newSelectedCar];
          copyNewSelectedCar[copyNewSelectedCar.indexOf(existingVehicle)] = {
            cars: [...existingVehicle.cars, { ...vehicle, checked: true }],
            total: existingVehicle.total + 1,
          };
          newSelectedCar = copyNewSelectedCar;
        } else {
          newSelectedCar = [...newSelectedCar, { cars: [{ ...vehicle, checked: true }], total: 1 }];
        }
      }
    }
    setSelectedVehicle(newSelectedCar);
  }, [businessRuleDetail]);

  useEffect(() => {
    if (!rentalLocation?.length) return;
    const newSelectedLocationArr = rentalLocation.map((item) => ({ ...item, value: item.name.toLowerCase() }));
    setMappedLocationList(newSelectedLocationArr);
  }, [rentalLocation]);

  useEffect(() => {
    if (checkEmptyObject(businessRuleFormula)) return;
    if (businessRuleDetail && id) {
      if (!checkEmptyObject(businessRuleDetail)) {
        setMappedFormula(
          businessRuleFormula.formula_variable?.map((item) =>
            businessRuleDetail.formula_variables?.find((formula) => formula.id == item.id)
              ? { ...item, name: item.name, checked: true, isDisabled: false }
              : businessRuleDetail.formula_variables?.find((formula) => formula.name.toLowerCase() === 'no dp')
              ? item.name.toLowerCase() !== 'no dp'
                ? { ...item, name: item.name, checked: false, isDisabled: true }
                : { ...item, name: item.name, checked: false, isDisabled: false }
              : businessRuleDetail.formula_variables?.find((formula) => formula.name.toLowerCase() !== 'no dp')
              ? item.name.toLowerCase() === 'no dp'
                ? { ...item, name: item.name, checked: false, isDisabled: true }
                : { ...item, name: item.name, checked: false, isDisabled: false }
              : { ...item, name: item.name, checked: false, isDisabled: false },
          ),
        );
      }
    } else {
      setMappedFormula(
        businessRuleFormula.formula_variable.map((item) => ({
          ...item,
          name: item.name,
          checked: false,
          isDisabled: false,
        })),
      );
    }
  }, [businessRuleFormula, businessRuleDetail]);

  const handleTipeSewa = (typeId, status) => {
    const existedType = selectedSubService.find((item) => item === SERVICE_DATA.find((el) => el.id == typeId)?.value);

    // if exist will return nothing
    if (existedType && status) return;

    // set tipe sewa if false
    const rentTypeValue = SERVICE_DATA.find((el) => el.id == typeId)?.value;
    const isExist = businessRuleDetail.business_rule_subservices?.find(
      (item) => item.name.toLowerCase() == rentTypeValue,
    );

    if (status) {
      setSelectedSubService((prevState) => [...prevState, rentTypeValue]);
      if (id) {
        setEditDataSubService((prev) => ({
          toAdd: isExist ? [...prev.toAdd] : [...prev.toAdd, rentTypeValue],
          toRemove: prev.toRemove.filter((item) => item !== rentTypeValue),
        }));
      }
    } else {
      const filteredData = selectedSubService.filter((item) => item !== rentTypeValue);
      setSelectedSubService(filteredData);
      if (id) {
        setEditDataSubService((prev) => ({
          toRemove: !isExist ? [...prev.toRemove] : [...prev.toRemove, rentTypeValue],
          toAdd: prev.toAdd.filter((item) => item !== rentTypeValue),
        }));
      }
    }
  };

  const handleLocation = (locationId, status) => {
    const existedType = selectedLocation?.find(
      (item) => item.id === rentalLocation.find((el) => el.id == locationId)?.id,
    );

    // if exist will return nothing
    if (existedType && status) return;
    const locationValue = mappedLocationList?.find((el) => el.id == locationId);
    const isExist = businessRuleDetail.business_rule_location?.find((item) => item.id == locationValue.id);

    if (status) {
      setSelectedLocation(() => [
        ...selectedLocation,
        { id: locationValue.id, name: locationValue.name, value: locationValue.name.toLowerCase() },
      ]);
      if (id) {
        setEditDataLocation((prev) => ({
          toAdd: isExist ? [...prev.toAdd] : [...prev.toAdd, locationValue.id],
          toRemove: prev.toRemove.filter((item) => item !== locationValue.id),
        }));
      }
    } else {
      setSelectedLocation((prev) => prev.filter((item) => item.id !== locationValue.id));
      if (id) {
        setEditDataLocation((prev) => ({
          toRemove: !isExist ? [...prev.toRemove] : [...prev.toRemove, locationValue.id],
          toAdd: prev.toAdd.filter((item) => item !== locationValue.id),
        }));
      }
    }
  };

  const handleFormula = (e) => {
    const { checked, value, id } = e.target;
    const name = id?.split('-')[0];
    const isExist = businessRuleDetail.formula_variables?.find((item) => item.id == value);
    if (checked) {
      if (name.toLowerCase() === 'no dp') {
        setMappedFormula((prev) =>
          prev.map((item) => (item.id == +value ? { ...item, checked: true } : { ...item, isDisabled: true })),
        );
        setSelectedFormula([...selectedFormula, { id: +value, name: name }]);
        setFormulaValue(0);
      } else {
        setMappedFormula((prev) =>
          prev.map((item) =>
            item.name.toLowerCase() === 'no dp'
              ? { ...item, isDisabled: true }
              : item.id == +value
              ? { ...item, checked: true }
              : item,
          ),
        );
        setSelectedFormula([...selectedFormula, { id: +value, name: name }]);
      }
      if (id) {
        setEditDataFormula((prev) => ({
          toAdd: isExist ? [...prev.toAdd] : [...prev.toAdd, +value],
          toRemove: prev.toRemove.filter((item) => item !== +value),
        }));
      }
    } else {
      if (name.toLowerCase() === 'no dp') {
        setMappedFormula((prev) =>
          prev.map((item) => (item.id == +value ? { ...item, checked: false } : { ...item, isDisabled: false })),
        );
        setSelectedFormula((prev) => prev.filter((item) => item.id !== +value));
      } else {
        if (selectedFormula.length - 1 === 0) {
          setMappedFormula((prev) =>
            prev.map((item) =>
              item.name.toLowerCase() === 'no dp'
                ? { ...item, isDisabled: false }
                : item.id == +value
                ? { ...item, checked: false }
                : item,
            ),
          );
        } else {
          setMappedFormula((prev) => prev.map((item) => (item.id == +value ? { ...item, checked: false } : item)));
        }
        setSelectedFormula((prev) => prev.filter((item) => item.id !== +value));
      }
      if (id) {
        setEditDataFormula((prev) => ({
          toRemove: !isExist ? [...prev.toRemove] : [...prev.toRemove, +value],
          toAdd: prev.toAdd.filter((item) => item !== +value),
        }));
      }
    }
  };

  const handleSelectedCar = (cars) => {
    setSelectedVehicle(cars);
  };

  const handleThreshold = (e) => {
    const { id, value } = e.target;
    const type = id.split('-')?.[1];
    setSelectedThreshold((prev) => ({ ...prev, [type]: +value }));
  };
  const handleTipeSetting = (e) => {
    const { id: tipeId } = e.target;
    const getTipeSetting = TIPE_SETTING.find((item) => item.id === +selectedTipe);
    if (selectedTipe > +tipeId) {
      if (getTipeSetting.name.toLowerCase() == 'kendaraan') {
        setSelectedVehicle([]);
        if (selectedTipe == 3 && +tipeId == 1) {
          setSelectedSubService([]);
          if (id && businessRuleDetail?.business_rule_subservices?.length > 0) {
            setEditDataSubService((prev) => ({
              ...prev,
              toRemove: businessRuleDetail?.business_rule_subservices?.map((item) => item.name.toLowerCase()),
            }));
          }
        }
      } else if (getTipeSetting.name.toLowerCase() == 'layanan') {
        setSelectedSubService([]);
        if (id && businessRuleDetail?.business_rule_subservices?.length > 0) {
          setEditDataSubService((prev) => ({
            ...prev,
            toRemove: businessRuleDetail?.business_rule_subservices?.map((item) => item.name.toLowerCase()),
          }));
        }
      } else if (getTipeSetting.name.toLowerCase() == 'lokasi') {
        setSelectedLocation([]);
      }
    }
    setSelectedTipe(+tipeId);
  };

  const saveHandler = async () => {
    let mappedVehicles = [];
    let mappedServices = [];

    if (isEmpty(businessRuleName)) {
      return showToast({
        type: 'warning',
        message: 'Harap isi nama dari setting!',
      });
    } else if ((selectedTipe == 1 || selectedTipe == 2 || selectedTipe == 3) && !selectedLocation.length) {
      return showToast({
        type: 'warning',
        message: 'Harap memilih input lokasi!',
      });
    } else if ((selectedTipe == 2 || selectedTipe == 3) && !selectedSubService.length) {
      return showToast({
        type: 'warning',
        message: 'Harap memilih jenis layanan!',
      });
    } else if (selectedTipe == 3 && !selectedVehicle.length) {
      return showToast({
        type: 'warning',
        message: 'Harap memilih jenis mobil!',
      });
    } else if (!selectedFormula.length) {
      return showToast({
        type: 'warning',
        message: 'Harap memilih jenis formula perhitungan!',
      });
    } else if (!selectedFormula.find((item) => item.name.toLowerCase() === 'no dp')) {
      if (!selectedThreshold.min && !selectedThreshold.max) {
        return showToast({
          type: 'warning',
          message: 'Harap mengisi salah satu atau kedua value threshold!',
        });
      }
    }

    selectedVehicle.map((item) => item.cars).map((cars) => cars.map((car) => mappedVehicles.push(car.id)));

    subServices.map((item) =>
      SERVICE_DATA.map((sub) =>
        sub.value == item.name.toLowerCase()
          ? mappedServices.push({ id: item.id, name: item.name })
          : item.facilities.map((fac) =>
              fac.name.toLowerCase() == sub.value ? mappedServices.push({ id: fac.id, name: fac.name }) : undefined,
            ),
      ),
    );

    let payload = {
      name: businessRuleName,
      is_applied: true,
    };

    if (id) {
      payload = {
        ...payload,
        location_to_add: editDataLocation.toAdd,
        location_to_delete: editDataLocation.toRemove,
        formula_variable_to_add: editDataFormula.toAdd,
        formula_varibale_to_del: editDataFormula.toRemove,
        vehicle_to_add: mappedVehicles?.filter(
          (item) => !businessRuleDetail.business_rule_vehicle?.find((item2) => item2.id == item),
        ),
        vehicle_to_del: businessRuleDetail.business_rule_vehicle
          ?.filter((item) => !mappedVehicles.includes(item.id))
          .map((item) => item.id),
        sub_service_to_add: editDataSubService.toAdd?.map(
          (item) => mappedServices?.find((sub) => sub.name.toLowerCase() == item).id,
        ),
        sub_service_to_del: editDataSubService.toRemove.map(
          (item) => mappedServices?.find((sub) => sub.name.toLowerCase() == item).id,
        ),
        threshold: {
          id: businessRuleDetail.rule_threshold.id,
          min: selectedThreshold.min,
          max: selectedThreshold.max,
        },
        formula_percentage: {
          id: businessRuleDetail?.formula_percentage?.id,
          value: formulaValue,
        },
      };
    } else {
      payload = {
        ...payload,
        code_name: 'Minimum Down Payment',
        locations_id: selectedLocation?.map((item) => item.id),
        formula_variables_id: selectedFormula?.map((item) => item.id),
        vehicles_id: mappedVehicles,
        sub_services_id: selectedSubService?.map(
          (item) => mappedServices?.find((sub) => sub.name.toLowerCase() == item).id,
        ),
        threshold: { ...selectedThreshold, value_type: 'currency' },
        formula_percentage: {
          value: formulaValue,
        },
      };
    }

    try {
      if (id) {
        await dispatch(editBusinessRuleById({ data: payload, id: id })).unwrap();
        showToast({
          type: 'success',
          message: 'Berhasil Melakukan Edit Setting!',
        });
      } else {
        await dispatch(createBusinessRule(payload)).unwrap();
        showToast({
          type: 'success',
          message: 'Berhasil Menambahkan Setting!',
        });
      }
      navigate('/setting-dp');
    } catch (error) {
      if (id) {
        showToast({
          type: 'error',
          message: 'Gagal Melakukan edit Setting!',
        });
        return;
      }
      showToast({
        type: 'error',
        message: 'Gagal Menambahkan Setting!',
      });
    }
  };

  const intersectionAction = () => {
    // if last page then just return nothing
    if (allVehicle.pagination.page >= allVehicle.pagination.last_page) return;

    dispatch(
      getVehiclesByFilter({
        locationRental: selectedLocation.map((item) => item.id),
        supportDriver: selectedSubService.includes('with driver') ? 1 : 0,
        page: allVehicle.pagination.page + 1,
      }),
    );
    // dispatch(getAllVehicles(allVehicle.pagination.page + 1));
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <>
      <div className="down-payment">
        <header className="down-payment__header" onClick={backHandler}>
          <LeftArrow />
          <p>Kembali</p>
        </header>
        <TableWrapper icon={<DPFilledIcon fill="#009EF7" width="25px" height="25px" />} title="Setting DP">
          <div className="down-payment__input-group">
            <InputField
              label="Nama Setting DP"
              value={businessRuleName}
              onChange={(e) => setBusinessRuleName(e.target.value)}
              placeholder="Masukan nama setting"
              className="input-field"
            />

            <SelectFieldDropdown
              label="Tipe Setting"
              placeholder="Pilih Tipe Setting"
              data={TIPE_SETTING}
              value={selectedTipe}
              onChange={handleTipeSetting}
              htmlFor="tipe"
            />
            <SelectFieldDropdown
              label="Lokasi"
              placeholder="Pilih Lokasi"
              data={mappedLocationList}
              value={selectedLocation}
              onChange={handleLocation}
              checkBox
              htmlFor="location"
              disable={selectedTipe < 1}
            />
            <SelectFieldDropdown
              label="Layanan"
              htmlFor="layanan"
              data={SERVICE_DATA}
              placeholder="Pilih Layanan"
              value={selectedSubService}
              onChange={handleTipeSewa}
              checkBox
              disable={selectedTipe < 2}
            />

            {/* VEHICLE */}
            <SelectFieldVehicle
              label="Jenis Mobil"
              htmlFor="jenis-mobil"
              placeholder="Cari Mobil.."
              data={allVehicle}
              selectedCar={selectedVehicle}
              onSelectCar={handleSelectedCar}
              intersectionAction={intersectionAction}
              className="down-payment__car-input"
              disable={selectedTipe < 3}
            />

            <div className="form-field__formula">
              <h3>Formula</h3>
              <div className="form-field__formula__items">
                {mappedFormula.map((item) => (
                  <CheckboxField
                    key={item.id}
                    label={item.name}
                    value={item.id}
                    onChange={handleFormula}
                    htmlfor={`${item.name}-${item.id}`}
                    checked={item.checked}
                    disable={item.isDisabled}
                  />
                ))}
              </div>
              <div className="form-field__formula-field">
                {selectedFormula.length > 0 ? (
                  selectedFormula.map((item) => (
                    <span key={item.id} className="formula-item">
                      {item.name} {item.value > 0 ? item.value : null}
                    </span>
                  ))
                ) : (
                  <span className="formula-placeholder">Pilih Formula Perhitungan</span>
                )}
              </div>
            </div>

            <div className="form-field__percentage">
              <InputField
                label="Percentage (%)"
                value={formulaValue || ''}
                placeholder="0"
                onChange={(e) => setFormulaValue(+e.target.value)}
                disable={selectedFormula.find((item) => item.name.toLowerCase() === 'no dp')}
              />
            </div>

            <div className="form-field__threshold-value">
              <div className="form-field__threshold">
                <InputField label="Threshold" value="Minimal" readOnly className="form-with-label" />
                <InputField value="Maksimal" readOnly />
              </div>
              <div className="form-field__value">
                <InputField
                  label="Value"
                  type="number"
                  htmlFor="threshold-min"
                  placeholder="0"
                  className="form-with-label"
                  min={0}
                  onWheel={numberInputOnWheelPreventChange}
                  value={selectedThreshold.min || ''}
                  disable={selectedFormula.find((item) => item.name.toLowerCase() === 'no dp')}
                  onChange={handleThreshold}
                />
                <InputField
                  type="number"
                  htmlFor="threshold-max"
                  placeholder="0"
                  min={0}
                  onWheel={numberInputOnWheelPreventChange}
                  value={selectedThreshold.max || ''}
                  disable={selectedFormula.find((item) => item.name.toLowerCase() === 'no dp')}
                  onChange={handleThreshold}
                />
              </div>
            </div>

            <div className="down-payment__prices">
              <Button className="button btn-simulate" onClick={() => setShowPriceSimulationModal(true)}>
                Simulasikan Harga DP
              </Button>
            </div>
          </div>
          <div className="down-payment__buttons">
            <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
              Kembali
            </Button>

            {(!id || checkPermission(offCanvasMenu, currentMenu, 'update')) && (
              <Button size="sm" className="button" width={208} onClick={saveHandler}>
                Simpan
              </Button>
            )}
          </div>
        </TableWrapper>
      </div>
      {showPriceSimulationModal &&
        ReactDOM.createPortal(
          <PriceSimulationModal
            setShowPriceSimulationModal={setShowPriceSimulationModal}
            selectedFormula={selectedFormula}
            formulaValue={formulaValue}
            numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
          />,
          document.getElementById('modal'),
        )}
    </>
  );
};

export default DownPaymentDetail;
