import { setCookieDays, setCookieSeconds } from '../../../utils/cookies';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const login = createAsyncThunk('auth/login', async ({ email, password }, thunkAPI) => {
  try {
    const res = await axios.post(`${BASE_URL}/${API_VERSION}/authorization`, {
      email,
      password,
      scope: 'backoffice',
    });

    const data = await res.data;

    setCookieDays('grbut', data?.access_token, 1);
    setCookieDays('grbrt', data?.refresh_token, 1);
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  setCookieSeconds('grbut', '', 1);
  setCookieSeconds('grbrt', '', 1);
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    await axios.get(`${BASE_URL}/${API_VERSION}/authorization/destroy`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const refreshToken = createAsyncThunk('auth/refresh-token', async (_, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  const refreshToken = thunkAPI.getState().auth.data.refresh_token;

  try {
    const res = await axios.post(
      `${BASE_URL}/${API_VERSION}/authorization/refresh`,
      { refresh_token: refreshToken },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    const data = await res.data;

    setCookieDays('grbut', data?.access_token, 1);
    setCookieDays('grbrt', data?.refresh_token, 1);

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
