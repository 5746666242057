import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import CardDashboardItem from 'components/Global/Card/CardDashboardItem';
import { useSelector } from 'react-redux';

ChartJS.register(...registerables);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      callbacks: {
        label: function ({ dataset, dataIndex }) {
          return (dataset.data[dataIndex] / 1000000).toFixed(1) + ' Juta';
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 8,
          weight: 600,
        },
      },
      border: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      max: 20000000,
      min: 0,
      ticks: {
        stepSize: 5000000,
        font: {
          size: 8,
          weight: 600,
        },
        callback: function (value) {
          if (value === 0) {
            return value;
          } else {
            return this.getLabelForValue(value / 1000000) + ' Juta';
          }
        },
      },
      border: {
        display: false,
      },
    },
  },
};

const labels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

const TransactionStats = () => {
  const { data: allRevenueData } = useSelector((state) => state.allRevenue);

  const data = {
    labels,
    datasets: [
      {
        data: allRevenueData.month_to_month?.map((item) => item.this_month_revenue),
        borderRadius: 100,
        barThickness: 10,
        backgroundColor: 'rgba(0, 158, 247, 1)',
        borderSkipped: false,
      },
    ],
  };

  // FUNCTION TO ADD BACKGROUND BEHIND THE CHART BAR
  const barPattern = {
    id: 'barPattern',
    beforeDatasetsDraw(chart) {
      const {
        ctx,
        chartArea: { top, height, bottom },
        scales: { x },
      } = chart;

      const width = chart.getDatasetMeta(0).data[0]?.width;
      const radius = data.datasets[0].borderRadius;

      chart.getDatasetMeta(0).data.forEach((__, index) => {
        ctx.beginPath();
        ctx.fillStyle = '#F2F7FF';

        ctx.roundRect(x.getPixelForValue(index) - width / 2, top, width, height, radius);
        ctx.fill();

        ctx.fillRect(x.getPixelForValue(index) - width / 2, bottom, width, -50);
      });
    },
  };

  return (
    <CardDashboardItem title="Statistik Transaksi" separator asChild>
      <Bar options={options} data={data} plugins={[barPattern]} />
    </CardDashboardItem>
  );
};

export default TransactionStats;
