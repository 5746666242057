import React from 'react';
import { PaginationTable, Table, TableWrapper } from 'components/Global';
import { ReactComponent as SchedulePriceFilledIcon } from 'icons/schedule-price-filled-icon.svg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { deleteSchedulePrice, getAllSchedulePrice } from 'features/schedule-price/actions';
import { getVehicleById } from 'features/vehicle/action';
import { getAllRentalLocation } from 'features/rental-location/actions';
import { format } from 'date-fns';
import { indonesianDateFormat } from 'utils/helpers';
import { useAppContext } from 'components/Context/AppContext';
import { resetSelectedSchedulePrice } from 'features/schedule-price/schedulePriceSlice';
import EmptyState from 'components/Global/EmptyState';
import ButtonAdd from 'components/Global/ButtonAdd';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Periode Mulai', value: 'start_time' },
  { header: 'Periode Selesai', value: 'end_time' },
  { header: 'Jenis Mobil', value: 'schedule_price_vehicles' },
  { header: 'Lokasi Sewa', value: 'schedule_price_locations' },
];

const SchedulePriceList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setShowConfirmation, showToast, setShowSpinner } = useAppContext();

  const {
    data: schedulePriceData,
    isLoading: loadingSchedulePrice,
    isError: errorSchedulePrice,
  } = useSelector((state) => state.schedulePrice);
  const { isError: errorFewVehicle, selected: selectedVehicle } = useSelector((state) => state.vehicle);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);

  const [currentPage, setCurrentPage] = useState(1);

  const [schedulePriceList, setSchedulePriceList] = useState([]);

  const [allVehicle, setAllVehicle] = useState([]);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [schedulePriceVehicleIdList, setSchedulePriceVechicleIdList] = useState([]);

  // FUNCTION TO EDIT SCHEDULE PRICE
  const handleEdit = (e) => {
    const schedulePriceId = e.target.parentElement.parentElement.dataset['key'];
    dispatch(resetSelectedSchedulePrice());
    navigate(`/schedule-price/detail?tab=Schedule Price&schedule-price_id=${schedulePriceId}`);
  };

  // FUNTION TO DELETE SCHEDULE PRICE
  const handleDelete = (e) => {
    const schedulePriceId = e.target.parentElement.parentElement.dataset['key'];

    setShowConfirmation({
      message: 'Apakah anda yakin ingin hapus schedule price',
      show: true,
      onClick: async () => {
        try {
          const res = await dispatch(deleteSchedulePrice(schedulePriceId));

          if (res.meta?.requestStatus === 'fulfilled') {
            showToast({ type: 'success', message: 'Berhasil Hapus schedule price' });
            setSchedulePriceList([]);
            dispatch(getAllSchedulePrice({ page: 1 }));
            return;
          }
          showToast({ type: 'error', message: 'Gagal Hapus schedule price' });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          showToast({ type: 'error', message: 'Terjadi Kesalahan' });
        }
      },
    });
  };

  // GET SCHEDULE PRICE DEPENDING ON THE PAGE
  useEffect(() => {
    dispatch(getAllSchedulePrice({ page: currentPage, type: 1 }));
    dispatch(getAllRentalLocation());
  }, [currentPage]);

  // GET ALL SCEHDULE PRICE VEHICLES ID
  useEffect(() => {
    if (Object.keys(schedulePriceData).length === 0) return;
    if (!schedulePriceData.schedule_price) return;

    let vehiclesIdArray = [];

    for (let child of schedulePriceData.schedule_price) {
      for (let vehicleChild of child.vehicles) {
        vehiclesIdArray.push(vehicleChild.vehicle_id);
      }
    }

    const removeSameValueArray = [...new Set(vehiclesIdArray)];
    setSchedulePriceVechicleIdList(removeSameValueArray);
  }, [schedulePriceData]);

  // GET DETAIL VEHICLE FROM ALL VEHICLE IN SCHEDULE PRICE DATA
  useEffect(() => {
    if (Object.keys(schedulePriceVehicleIdList).length === 0) return;

    if (isFirstLoad) {
      setIsFirstLoad(false);

      for (let child of schedulePriceVehicleIdList) {
        dispatch(getVehicleById(child));
      }
    }
  }, [schedulePriceVehicleIdList, isFirstLoad]);

  // SET SELECTED VEHICLE DATA OR PUSH IT TO SCHEDULE PRICE VEHICLES DATA STATE
  useEffect(() => {
    if (Object.keys(selectedVehicle).length === 0) return;
    if (allVehicle.length >= schedulePriceVehicleIdList.length) return;

    setAllVehicle((prevState) => [...prevState, selectedVehicle]);
  }, [selectedVehicle]);

  // GENERATE THE TABLE DATA
  useEffect(() => {
    if (allVehicle.length === 0) return;
    if (Object.keys(schedulePriceData).length === 0) return;
    if (!schedulePriceData.schedule_price) return;
    if (Object.keys(rentalLocationData).length === 0) return;

    let newSchedulePriceData = [];

    for (let childSchedule of schedulePriceData.schedule_price) {
      let vehiclesName = [];
      let locationsName = [];

      for (let childLocation of childSchedule.locations) {
        const location = rentalLocationData?.find((item) => item.id === childLocation)?.name;
        locationsName.push(location);
      }

      for (let childVehicle of childSchedule.vehicles) {
        if (vehiclesName.length === 0) {
          const foundedVehicle = allVehicle?.find((item) => item.id === childVehicle.vehicle_id);
          vehiclesName.push({ name: foundedVehicle?.name, total: 1 });
        } else {
          const foundedVehicle = allVehicle?.find((item) => item.id === childVehicle.vehicle_id);
          const existingVehicle = vehiclesName.find((item) => item.name === foundedVehicle?.name);
          if (existingVehicle) {
            existingVehicle.total += 1;
          } else {
            vehiclesName.push({ name: foundedVehicle?.name, total: 1 });
          }
        }
      }

      newSchedulePriceData.push({
        id: childSchedule.id,
        start_time: indonesianDateFormat(format(new Date(childSchedule.start_time), 'yyyy-MM-dd').toString()),
        end_time: indonesianDateFormat(format(new Date(childSchedule.end_time), 'yyyy-MM-dd').toString()),
        schedule_price_vehicles: vehiclesName,
        schedule_price_locations: locationsName,
      });
    }

    setSchedulePriceList(newSchedulePriceData);
  }, [allVehicle, schedulePriceData, rentalLocationData]);

  const handleAdd = () => {
    navigate(`/schedule-price/add-item?tab=Schedule%20Price`);
  };

  // SHOW SPINNER WHEN LOADING
  useEffect(() => {
    if (loadingSchedulePrice) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [loadingSchedulePrice]);

  // SHOW TOAST ERROR WHEN ERROR
  useEffect(() => {
    if (errorSchedulePrice || errorFewVehicle) {
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }
  }, [errorSchedulePrice, errorFewVehicle]);

  return (
    <div className="schedule-price">
      <ButtonAdd onClick={handleAdd} label="Tambah Schedule Price" />
      {
        // RETURN EMPTY STATE
        !Object.keys(schedulePriceData).length || !schedulePriceList.length ? (
          <EmptyState />
        ) : (
          <TableWrapper
            icon={<SchedulePriceFilledIcon fill="#009EF7" width="25px" height="25px" />}
            title="Schedule Price"
          >
            <Table
              column={column}
              data={schedulePriceList || null}
              actionBtn
              onEdit={handleEdit}
              onDel={handleDelete}
            />
            <PaginationTable
              currentPage={currentPage}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              totalCount={schedulePriceData?.pagination?.total_data}
            />
          </TableWrapper>
        )
      }
    </div>
  );
};

export default SchedulePriceList;
