import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { Button, TableWrapper } from 'components/Global';
import { getAllLanguage } from 'features/language/actions';
// import { getRentalLocationByServices } from 'features/rental-location/actions';
import {
  createTermAndConditionService,
  getAllTermAndconditionService,
  getTermAndConditionById,
  updateTermAndCondition,
} from 'features/term-and-condition/actions';
import { getServices } from 'features/services/actions';
import { getRentalLocationByServices } from 'features/rental-location/actions';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import Tabs, { TabPane } from 'components/Global/Tabs';
import LanguageEditor from '../../LanguageEditor';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as TACFilledIcon } from 'icons/term-and-condition-filled-icon.svg';
import { checkPermission } from 'utils/functionality';

const DetailService = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showToast, setShowSpinner } = useAppContext();

  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const { data: languageData } = useSelector((state) => state.language);
  const { data: servicesData } = useSelector((state) => state.services);
  const { selectedData: selectedTACData } = useSelector((state) => state.termAndCondition);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  const [activeTab, setActiveTab] = useState('Indonesia');
  const [allRentTypeData, setAllRentTypeData] = useState([]);

  const [rentType, setRentType] = useState('');
  const [locationValue, setLocationValue] = useState('');
  const [values, setValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAutofillLocation, setIsAutofillLocation] = useState(false);

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const actionMutation = async (method, payload, successMessage) => {
    if (method === 'ADD') {
      try {
        setShowSpinner(true);
        await dispatch(createTermAndConditionService(payload)).unwrap();
        showToast({ type: 'success', message: successMessage });
        navigate('/term-and-condition?tab-active=Service');
        setShowSpinner(false);
      } catch (error) {
        showToast({ type: 'error', message: error.message });
        setShowSpinner(false);
      }
    } else {
      try {
        setShowSpinner(true);
        await dispatch(updateTermAndCondition(payload)).unwrap();
        showToast({ type: 'success', message: successMessage });
        navigate('/term-and-condition?tab-active=Service');
        setShowSpinner(false);
        dispatch(getAllTermAndconditionService({ page: 1, limit: 10 }));
      } catch (error) {
        showToast({ type: 'error', message: error.message });
        setShowSpinner(false);
      }
    }
  };

  const chooseRentTypeHandler = (e) => {
    setRentType(e.target.id);
    setLocationValue('');

    const mappingServiceSewaMobil = servicesData?.find((item) => item.name === 'Sewa Mobil');

    const choosedService = mappingServiceSewaMobil?.sub_services?.find((item) => item.id === +e.target.id);

    if (choosedService) {
      dispatch(getRentalLocationByServices({ service_id: mappingServiceSewaMobil?.id, sub_service_id: +e.target.id }));
    } else {
      const mappingSubservicesDaily = mappingServiceSewaMobil?.sub_services?.find((item) => item.name === 'Daily');
      dispatch(
        getRentalLocationByServices({
          service_id: mappingServiceSewaMobil?.id,
          sub_service_id: mappingSubservicesDaily?.id,
          facility_id: +e.target.id,
        }),
      );
    }
  };

  const submitHandler = async () => {
    const validValues = values.every(
      (item) => item.value.replace(/\s/g, '').length !== 0 && item.value !== '<p><br></p>',
    );

    if (!rentType) {
      return showToast({ type: 'error', message: 'Pilih Tipe Rental' });
    }

    if (!locationValue) {
      return showToast({ type: 'error', message: 'Pilih Lokasi Rental' });
    }

    if (!validValues) {
      return showToast({ type: 'error', message: 'Masukan Syarat dan Ketentuan' });
    }

    const payload = {
      reference_id: +rentType,
      location_id: locationValue.id,
      reference_type: 'sub_service',
      term_condition_lines: values.map((item) => ({ language_id: item.id, term: item.value })),
    };

    if (id) {
      actionMutation('UPDATE', { payloadData: payload, tacId: id }, 'Berhasil Update Item', 'Terjadi Kesalahan');
    } else {
      actionMutation('ADD', payload, 'Berhasil Tambah Item', 'Terjadi Kesalahan');
    }
  };

  useEffect(() => {
    dispatch(getServices());
    dispatch(getAllLanguage());

    if (id) {
      setIsLoading(true);
      dispatch(getTermAndConditionById(id));
    }
  }, []);

  useEffect(() => {
    if (!Object.keys(servicesData).length || !servicesData.length) return;

    const mappingRentTypeData = [];

    const mappingServiceSewaMobil = servicesData.find((item) => item.name === 'Sewa Mobil');

    mappingServiceSewaMobil.sub_services.forEach((item) => {
      if (item.name === 'Tour') return;

      if (!item.facilities || !item.facilities.length) {
        return mappingRentTypeData.push({ id: item.id, name: item.name });
      } else {
        item.facilities.forEach((fac) => mappingRentTypeData.push({ id: fac.id, name: fac.name }));
      }
    });

    setAllRentTypeData(mappingRentTypeData);
  }, [servicesData]);

  useEffect(() => {
    if (!Object.keys(languageData).length || !languageData.data?.length || id) return;

    const mappingValues = languageData.data.map((item) => ({ id: item.id, value: '' }));
    setValues(mappingValues);
  }, [languageData]);

  useEffect(() => {
    if (!Object.keys(selectedTACData).length || !allRentTypeData.length || !Object.keys(languageData).length || !id)
      return;

    const selectedRentType = allRentTypeData.find((item) => item.name === selectedTACData.sub_service)?.id;

    const mappingValues = languageData.data?.map((item) => ({ id: item.id, value: '' }));
    for (let selectedTerm of selectedTACData.term_condition_lines) {
      const choosedValues = mappingValues?.find((item) => item.id === selectedTerm.language.id);
      if (choosedValues) {
        mappingValues[mappingValues.indexOf(choosedValues)].value = selectedTerm.term;
      }
    }

    const mappingServiceSewaMobil = servicesData?.find((item) => item.name === 'Sewa Mobil');

    const choosedService = mappingServiceSewaMobil?.sub_services?.find((item) => item.id === +selectedRentType);
    if (choosedService) {
      dispatch(
        getRentalLocationByServices({ service_id: mappingServiceSewaMobil?.id, sub_service_id: +selectedRentType }),
      );
    } else {
      const mappingSubservicesDaily = mappingServiceSewaMobil?.sub_services?.find((item) => item.name === 'Daily');
      dispatch(
        getRentalLocationByServices({
          service_id: mappingServiceSewaMobil?.id,
          sub_service_id: mappingSubservicesDaily?.id,
          facility_id: +selectedRentType,
        }),
      );
    }

    setIsAutofillLocation(true);

    setRentType(selectedRentType);
    setValues(mappingValues);
    setIsLoading(false);
  }, [selectedTACData, allRentTypeData, languageData]);

  useEffect(() => {
    if (
      !Object.keys(selectedTACData).length ||
      !rentalLocationData ||
      !rentalLocationData.length ||
      !isAutofillLocation ||
      !id
    )
      return;

    const selectedLocation = rentalLocationData?.find((item) => item.id === selectedTACData.location_id);
    setLocationValue(selectedLocation);
    setIsAutofillLocation(false);
  }, [rentalLocationData, isAutofillLocation]);

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  return (
    <div className="tac-service-detail">
      <header className="tac-service-detail__header" onClick={() => navigate(-1)}>
        <LeftArrow />
        <p>Kembali</p>
      </header>

      <TableWrapper
        icon={<TACFilledIcon fill="#009EF7" width="25px" height="25px" />}
        title="Syarat & Ketentuan - Service"
      >
        <div className="tac-service-detail__location-wrapper">
          <SelectFieldDropdown
            label="Tipe Rental"
            htmlFor="tipe-rental"
            value={rentType}
            data={allRentTypeData}
            onChange={chooseRentTypeHandler}
            placeholder="Tipe Sewa"
          />

          <SelectFieldShuttle
            label="Lokasi Sewa"
            placeholder="Pilih Lokasi Sewa"
            htmlFor="lokasi-sewa"
            data={rentalLocationData || []}
            value={locationValue.id}
            onChange={setLocationValue}
            searchFeature
            disable={!rentType}
          />
        </div>

        {!Object.keys(languageData).length || !languageData.data.length ? null : (
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
            {languageData.data.map((item) => (
              <TabPane key={item.id} label={item.name}>
                <LanguageEditor
                  lang={item}
                  allValue={values}
                  value={values?.find((val) => val.id === item.id)?.value}
                  onChange={setValues}
                />
              </TabPane>
            ))}
          </Tabs>
        )}

        <div className="tac-service-detail__button">
          <Button width={192} role="button" variant="outline" type="button" onClick={() => navigate(-1)}>
            Kembali
          </Button>

          {(!id || checkPermission(offCanvasMenu, currentMenu, 'update')) && (
            <Button width={192} role="button" type="button" onClick={submitHandler}>
              Simpan
            </Button>
          )}
        </div>
      </TableWrapper>
    </div>
  );
};

export default DetailService;
