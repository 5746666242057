import React, { useState } from 'react';
import { Button, InputField, RadioButton, SelectFieldVehicle, TableWrapper } from 'components/Global';
import { ReactComponent as SchedulePriceFilledIcon } from 'icons/schedule-price-filled-icon.svg';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { indonesianDateFormat } from 'utils/helpers';
import { addDays, format, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllRentalLocation } from 'features/rental-location/actions';
import { getAllVehicles, getVehicleById, getVehiclesByFilter } from 'features/vehicle/action';
import {
  createSchedulePrice,
  getAllSchedulePriceValue,
  getDetailSchedulePrice,
  updateSchedulePrice,
} from 'features/schedule-price/actions';
import { useAppContext } from 'components/Context/AppContext';
import { checkPermission } from 'utils/functionality';
import CheckboxField from 'components/Global/CheckboxField';

// TIPE SEWA ARRAY
const tipeSewa = [
  {
    id: 0,
    name: 'Dengan Supir',
    value: 'with_driver',
  },
  {
    id: 1,
    name: 'Tanpa Supir',
    value: 'without_driver',
  },
];

// LAMA RENTAL  ARRAY
const lamaRental = [
  {
    name: '1 Hari',
    id: 1,
  },
  {
    name: '2 Hari',
    id: 2,
  },
  {
    name: '3 Hari',
    id: 3,
  },
  {
    name: '4 Hari',
    id: 4,
  },
  {
    name: '5 Hari',
    id: 5,
  },
];

// PERUBAHAN HARGA ARRAY
const priceChange = [
  {
    name: 'Harga Berkurang',
    id: 'pc1',
    change_to: 'min',
  },
  {
    name: 'Harga Bertambah',
    id: 'pc2',
    change_to: 'plus',
  },
];

// ARRAY RADIO BUTTON
const arrayRadionButton = [
  {
    id: 'pr1',
    label: 'Harga Fixed',
    value: 'FIXED_PRICE',
  },
  {
    id: 'pr2',
    label: 'Harga Percentage',
    value: 'PERCENTAGE',
  },
];

// INITIAL VALUE SCHEDULE PRICE
const initialValue = {
  start_time: '',
  end_time: '',
  without_driver: false,
  with_driver: false,
  change_price: '',
  price: '',
  vehicle_id: [],
  min_rental_durations: 0,
  locations: [],
  value_type: '',
  is_high_season: false,
};

const SchedulePriceDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { showToast, setShowConfirmation, setShowSpinner } = useAppContext();

  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const { data: vehiclesData } = useSelector((state) => state.vehicle);
  const { selectedSchedulePrice, isLoading, isError } = useSelector((state) => state.schedulePrice);
  const { selected: selectedVehicle } = useSelector((state) => state.vehicle);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  const [searchParams] = useSearchParams();

  const [schedulePriceData, setSchedulePriceData] = useState(initialValue);
  const [schedulePriceUpdateData, setSchedulePriceUpdateData] = useState([]);

  // start & end date
  const [startSchedulePriceDate, setStartSchedulePriceDate] = useState('');
  const [endSchedulePriceDate, setEndSchedulePriceDate] = useState('');

  // selected car
  const [selectedCar, setSelectedCar] = useState([]);

  // rent type
  const [rentType, setRentType] = useState([]);

  const [detailVehicles, setDetailVehicles] = useState([]);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [priceType, setPriceType] = useState('');

  // SET TIPE SEWA WHEN CHECKED
  const handleTipeSewa = (id, status) => {
    const existedType = rentType.find((item) => item === tipeSewa.find((el) => el.id == id).value);

    // if exist will return nothing
    if (existedType && status) return;

    // set tipe sewa if false
    if (status) {
      setRentType((prevState) => [...prevState, tipeSewa.find((el) => el.id == id).value]);
      if (id == 1) {
        setSchedulePriceData({ ...schedulePriceData, without_driver: true });
      } else {
        setSchedulePriceData({ ...schedulePriceData, with_driver: true });
      }
    } else {
      const filteredData = rentType.filter((item) => item != tipeSewa.find((el) => el.id == id).value);
      setRentType(filteredData);
      if (id == 1) {
        setSchedulePriceData({ ...schedulePriceData, without_driver: false });
      } else {
        setSchedulePriceData({ ...schedulePriceData, with_driver: false });
      }
    }
  };

  // SET LOKASI SEWA WHEN CHECKED
  const handleLokasiSewa = (id, status) => {
    const existedId = schedulePriceData.locations.find((item) => item == id);

    // if exist will return nothing
    if ((existedId && status) || (existedId == 0 && status)) return;

    // set tipe sewa if false
    if (status) {
      setSchedulePriceData({ ...schedulePriceData, locations: [...schedulePriceData.locations, +id] });

      // if update
      if (location.pathname.split('/')[2] !== 'add-item') {
        setSchedulePriceData({ ...schedulePriceData, locations: [...schedulePriceData.locations, +id] });
        setSchedulePriceUpdateData({ location_update: { to_be_added: [+id] } });
      }
    } else {
      const filteredData = schedulePriceData.locations.filter((item) => item != id);
      setSchedulePriceData({ ...schedulePriceData, locations: filteredData });

      // if update
      if (location.pathname.split('/')[2] !== 'add-item') {
        setSchedulePriceData({ ...schedulePriceData, locations: filteredData });
        setSchedulePriceUpdateData({ ...schedulePriceUpdateData, location_update: { to_be_deleted: [+id] } });
      }
    }
  };

  // INTERSECTION FUNCTION OF VEHICLES
  const intersectionAction = () => {
    // if last page then just return nothing
    if (vehiclesData.pagination.page >= vehiclesData.pagination.last_page) return;

    // if not last page fetch
    if (rentType.length === 2) {
      dispatch(getAllVehicles(vehiclesData.pagination.page + 1));
    } else if (rentType.length === 1 && rentType[0] === 'with_driver') {
      dispatch(getVehiclesByFilter({ supportDriver: true, page: vehiclesData.pagination.page + 1 }));
    } else if (rentType.length === 1 && rentType[0] === 'without_driver') {
      dispatch(getVehiclesByFilter({ supportDriver: false, page: vehiclesData.pagination.page + 1 }));
    }
  };

  // FUNCTION WHEN CAR IS SELECTED
  const handleSelectedCar = (cars) => {
    let choosedVehiclesId = [];
    for (let choosedData of cars) {
      for (let chooseCar of choosedData.cars) {
        choosedVehiclesId.push(chooseCar.id);
      }
    }

    setSchedulePriceData({ ...schedulePriceData, vehicle_id: choosedVehiclesId });
    setSelectedCar(cars);
  };

  // FUNCTION ACTION REQUEST
  const sendPayloadData = async (action, payload, fulfilledMessage, errorMessage1, errorMessage2) => {
    try {
      const res =
        action === 'ADD'
          ? await dispatch(createSchedulePrice(payload))
          : action === 'UPDATE'
          ? await dispatch(updateSchedulePrice(payload))
          : () => {};

      if (res.meta?.requestStatus === 'fulfilled') {
        showToast({ type: 'success', message: fulfilledMessage });
        navigate('/schedule-price');
        return;
      }
      showToast({ type: 'error', message: errorMessage1 });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: `${errorMessage2}: ${error}` });
    }
  };

  // FUNCTION TO PREVENT SCROLLING ON INPUT NUMBER TYPE
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  // SET VALUE TYPE
  const handleValueType = (id) => {
    setPriceType(id);
    const ValueType = arrayRadionButton.find((item) => item.id == id)?.value;

    setSchedulePriceData({ ...schedulePriceData, value_type: ValueType });
  };

  // SET VALUE INPUT
  const handleValueInput = (e) => {
    let inputValue;

    if (e.target.value === '') {
      inputValue = '';
    } else {
      inputValue = e.target.value.replace(/[^0-9]/g, '');
    }

    setSchedulePriceData({ ...schedulePriceData, price: inputValue });
    if (location.pathname.split('/')[2] !== 'add-item')
      setSchedulePriceUpdateData({ ...schedulePriceUpdateData, price: inputValue });

    // setSchedulePriceData({ ...schedulePriceData, price: isNaN(e.target.value) ? 0 : +e.target.value });

    // if (location.pathname.split('/')[2] !== 'add-item')
    //   setSchedulePriceUpdateData({ ...schedulePriceUpdateData, price: isNaN(e.target.value) ? 0 : +e.target.value });
  };

  // FUNTION TO SUBMIT
  const handleSubmit = async () => {
    // some validation
    let validRentType;
    if (schedulePriceData.with_driver) {
      if (schedulePriceData.without_driver) {
        validRentType = true;
      } else {
        validRentType = true;
      }
    } else {
      if (schedulePriceData.without_driver) {
        validRentType = true;
      } else {
        validRentType = false;
      }
    }
    const validMinRentalDurations = schedulePriceData.min_rental_durations !== 0;
    const validVehicles = schedulePriceData.vehicle_id.length !== 0;
    const validStartTime = schedulePriceData.start_time !== '';
    const validEndTime = schedulePriceData.end_time !== '';
    const validLocations = schedulePriceData.locations.length !== 0;
    const validChangePrice = schedulePriceData.change_price !== '';
    const validValueType = schedulePriceData.value_type !== '';
    const validPrice =
      schedulePriceData.price !== '' && schedulePriceData.price !== '0' && schedulePriceData.price !== 0;

    // check validation
    if (!validRentType) {
      return showToast({ type: 'error', message: 'Pilih tipe rental' });
    } else if (!validMinRentalDurations) {
      return showToast({ type: 'error', message: 'Pilih lama rental' });
    } else if (!validVehicles) {
      return showToast({ type: 'error', message: 'Pilih mobil' });
    } else if (!validStartTime) {
      return showToast({ type: 'error', message: 'Pilih periode mulai' });
    } else if (!validEndTime) {
      return showToast({ type: 'error', message: 'Pilih periode selesai' });
    } else if (!validLocations) {
      return showToast({ type: 'error', message: 'Pilih lokasi sewa' });
    } else if (!validChangePrice) {
      return showToast({ type: 'error', message: 'Pilih perubahan harga' });
    } else if (!validValueType) {
      return showToast({ type: 'error', message: 'Pilih potongan harga' });
    } else if (!validPrice) {
      if (schedulePriceData.value_type !== 'FIXED_PRICE') {
        return showToast({ type: 'error', message: 'Masukan harga' });
      }
    }

    // if form is valid
    let payload;

    if (location.pathname.split('/')[2] === 'add-item') {
      payload = {
        ...schedulePriceData,
        change_price: priceChange.find((item) => item.id == schedulePriceData.change_price)?.change_to,
        min_rental_durations: +schedulePriceData.min_rental_durations,
        price: +schedulePriceData.price,
      };
    } else {
      const payloadData = {
        ...schedulePriceUpdateData,
        ...schedulePriceData,
        change_price: priceChange.find((item) => item.id == schedulePriceData.change_price).change_to,
        min_rental_durations: +schedulePriceData.min_rental_durations,
      };
      delete payloadData.locations;

      payload = {
        payloadData,
        schedulePriceId: schedulePriceData.id,
      };
    }

    // if add item
    if (location.pathname.split('/')[2] === 'add-item') {
      setShowConfirmation({
        message: 'Apakah anda yakin ingin lanjut membuat schedule price',
        show: true,
        onClick: () =>
          sendPayloadData(
            'ADD',
            payload,
            'Berhasil Tambah schedule price',
            'Gagal Tambah schedule price',
            'Terjadi Kesalahan',
          ),
      });
    }

    // if update item
    if (location.pathname.split('/')[2] !== 'add-item') {
      setShowConfirmation({
        message: 'Apakah anda yakin ingin lanjut update schedule price',
        show: true,
        onClick: () =>
          sendPayloadData(
            'UPDATE',
            payload,
            'Berhasil Update schedule price',
            'Gagal Update schedule price',
            'Terjadi Kesalahan',
          ),
      });
    }
  };

  // GET ALL RENTAL LOCATION & VEHICLE
  useEffect(() => {
    dispatch(getAllRentalLocation());
    dispatch(getAllSchedulePriceValue());

    // call detail if add item false
    if (location.pathname.split('/')[2] !== 'add-item') {
      const schedulePriceId = searchParams.get('schedule-price_id');
      dispatch(getDetailSchedulePrice(schedulePriceId));
    }
  }, []);

  // GET DETAIL SCHEDULE PRICE IF ADD ITEM FALSE
  useEffect(() => {
    if (location.pathname.split('/')[2] === 'add-item') return;
    if (Object.keys(selectedSchedulePrice).length === 0) return;

    if (isFirstLoad) {
      setIsFirstLoad(false);

      const vehiclesId = selectedSchedulePrice.vehicles.map((item) => item.vehicle_id);
      vehiclesId.forEach((item) => dispatch(getVehicleById(item)));
    }
  }, [selectedSchedulePrice, isFirstLoad]);

  // SET DETAIL VEHICLE THAT SELECTED IN ORDER TO GET THE VEHICLE DATA
  useEffect(() => {
    if (location.pathname.split('/')[2] === 'add-item') return;
    if (Object.keys(selectedVehicle).length === 0) return;

    setDetailVehicles((prevState) => [...prevState, selectedVehicle]);
  }, [selectedVehicle]);

  // SET SELECTED CAR IF ADD ITEM IS FALSE
  useEffect(() => {
    if (location.pathname.split('/')[2] === 'add-item') return;
    if (detailVehicles.length === 0) return;

    // loop the vehicle schedule price to set selected car
    let newSelectedCar = [];

    for (let vehicle of detailVehicles) {
      if (newSelectedCar.length === 0) {
        newSelectedCar.push({ cars: [{ ...vehicle, checked: true }], total: 1 });
      } else {
        const existingVehicle = newSelectedCar.find((item) => item.cars[0].name === vehicle.name);
        if (existingVehicle) {
          const copyNewSelectedCar = [...newSelectedCar];
          copyNewSelectedCar[copyNewSelectedCar.indexOf(existingVehicle)] = {
            cars: [...existingVehicle.cars, { ...vehicle, checked: true }],
            total: existingVehicle.total + 1,
          };
          newSelectedCar = copyNewSelectedCar;
        } else {
          newSelectedCar = [...newSelectedCar, { cars: [{ ...vehicle, checked: true }], total: 1 }];
        }
      }
    }

    setSelectedCar(newSelectedCar);
  }, [location, detailVehicles]);

  // SET THE SCHEDULE PRICE DATA WHEN ADD ITEM FALSE
  useEffect(() => {
    if (location.pathname.split('/')[2] === 'add-item') return;
    if (Object.keys(selectedSchedulePrice).length === 0) return;

    if (rentType.length === 0) {
      if (selectedSchedulePrice.with_driver && !selectedSchedulePrice.without_driver) {
        setRentType(['with_driver']);
      } else if (selectedSchedulePrice.without_driver && !selectedSchedulePrice.with_driver) {
        setRentType(['without_driver']);
      } else if (selectedSchedulePrice.with_driver && selectedSchedulePrice.without_driver) {
        setRentType(['with_driver', 'without_driver']);
      } else {
        setRentType([]);
      }
    }

    const vehiclesId = selectedSchedulePrice.vehicles.map((item) => item.vehicle_id);

    const startTime = `${new Date(selectedSchedulePrice.start_time).toISOString().slice(0, 10)} ${new Date(
      selectedSchedulePrice.start_time,
    )
      .toTimeString()
      .slice(0, 8)}`;

    const endTime = `${new Date(selectedSchedulePrice.end_time).toISOString().slice(0, 10)} ${new Date(
      selectedSchedulePrice.end_time,
    )
      .toTimeString()
      .slice(0, 8)}`;

    setStartSchedulePriceDate(new Date(selectedSchedulePrice.start_time));
    setEndSchedulePriceDate(new Date(selectedSchedulePrice.end_time));
    setPriceType(arrayRadionButton.find((item) => item.value === selectedSchedulePrice.value_type)?.id);

    setSchedulePriceData({
      id: selectedSchedulePrice.id,
      start_time: startTime,
      end_time: endTime,
      without_driver: selectedSchedulePrice.without_driver,
      with_driver: selectedSchedulePrice.with_driver,
      change_price: priceChange.find((item) => item.change_to === selectedSchedulePrice.change_price)
        ? priceChange.find((item) => item.change_to === selectedSchedulePrice.change_price).id
        : 0,
      price: selectedSchedulePrice.price,
      vehicle_id: vehiclesId,
      min_rental_durations: selectedSchedulePrice.min_rental_durations,
      locations: selectedSchedulePrice.locations,
      value_type: selectedSchedulePrice.value_type,
      is_high_season: selectedSchedulePrice?.is_high_season,
    });
  }, [selectedSchedulePrice]);

  // SET VEHICLE BASE ON RENT TYPE
  useEffect(() => {
    if (!rentType.length) return;

    setSelectedCar([]);
    setSchedulePriceData({ ...schedulePriceData, vehicle_id: [] });

    if (rentType.length === 2) {
      dispatch(getAllVehicles());
    } else if (rentType.length === 1 && rentType[0] === 'with_driver') {
      dispatch(getVehiclesByFilter({ supportDriver: true, page: 1 }));
    } else if (rentType.length === 1 && rentType[0] === 'without_driver') {
      dispatch(getVehiclesByFilter({ supportDriver: false, page: 1 }));
    }
  }, [rentType]);

  // SHOW SPINNER WHEN LOADING
  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  // SHOW TOAST ERROR WHEN ERROR
  useEffect(() => {
    if (isError) {
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }
  }, [isError]);

  return (
    <TableWrapper
      icon={<SchedulePriceFilledIcon fill="#009EF7" width="25px" height="25px" />}
      title="Schedule Price"
      style={{ position: 'relative' }}
    >
      <div className="schedule-price-detail">
        <h1>Set Schedue Price</h1>
        <div className="first-section">
          {/* TIPE SEWA */}
          <SelectFieldDropdown
            label="Tipe Rental"
            htmlFor="tipe-sewa"
            data={tipeSewa}
            placeholder="Tipe Sewa"
            value={rentType}
            onChange={handleTipeSewa}
            checkBox={true}
          />

          {/* TIPE SEWA */}
          <SelectFieldDropdown
            label="Lama Rental"
            htmlFor="lama-rental"
            data={lamaRental}
            placeholder="Pilih Lama Rental"
            value={schedulePriceData.min_rental_durations}
            onChange={(e) => {
              setSchedulePriceData({ ...schedulePriceData, min_rental_durations: e.target.id });
            }}
          />

          {/* JENIS MOBIL */}
          <SelectFieldVehicle
            label="Jenis Mobil"
            htmlFor="jenis-mobil"
            placeholder="Cari Mobil.."
            data={vehiclesData}
            selectedCar={selectedCar}
            onSelectCar={handleSelectedCar}
            intersectionAction={intersectionAction}
            disable={!rentType.length}
          />

          <div className="first-section__schedule-periode">
            <h3>Periode Schedule Price</h3>
            <div className="first-section__schedule-periode__date">
              <SelectFieldDate
                htmlFor="tanggal-mulai"
                name="tanggal-mulai"
                placeholder="Pilih Tanggal Mulai"
                selectedDay={startSchedulePriceDate}
                value={
                  schedulePriceData.start_time !== ''
                    ? startSchedulePriceDate !== ''
                      ? indonesianDateFormat(format(startSchedulePriceDate, 'yyyy-MM-dd').toString())
                      : indonesianDateFormat(format(new Date(schedulePriceData.start_time), 'yyyy-MM-dd').toString())
                    : schedulePriceData.start_time
                }
                handleDaySelect={(date) => {
                  if (date) {
                    setStartSchedulePriceDate(date);
                    setSchedulePriceData({
                      ...schedulePriceData,
                      start_time: `${addDays(date, 1).toISOString().slice(0, 10)} ${addDays(date, 1)
                        .toTimeString()
                        .slice(0, 8)}`,
                    });
                  }
                }}
                dateContainerXOffset="left"
              />
              <SelectFieldDate
                htmlFor="tanggal-selesai"
                name="tanggal-selesai"
                placeholder="Pilih Tanggal Selesai"
                selectedDay={endSchedulePriceDate}
                value={
                  schedulePriceData.end_time !== ''
                    ? endSchedulePriceDate !== ''
                      ? indonesianDateFormat(format(endSchedulePriceDate, 'yyyy-MM-dd').toString())
                      : indonesianDateFormat(format(new Date(schedulePriceData.end_time), 'yyyy-MM-dd').toString())
                    : schedulePriceData.end_time
                }
                fromDate={addDays(
                  startSchedulePriceDate === '' ? parseISO(startSchedulePriceDate) : startSchedulePriceDate,
                  1,
                )}
                handleDaySelect={(date) => {
                  if (date) {
                    setEndSchedulePriceDate(date);
                    setSchedulePriceData({
                      ...schedulePriceData,
                      end_time: `${addDays(date, 1).toISOString().slice(0, 10)} ${addDays(date, 1)
                        .toTimeString()
                        .slice(0, 8)}`,
                    });
                  }
                }}
                disable={startSchedulePriceDate === '' ? true : false}
                dateContainerXOffset="left"
              />
            </div>
          </div>

          {/* LOKASI SEWA */}
          <SelectFieldShuttle
            label="Lokasi Sewa"
            htmlFor="lokasi-sewa"
            data={rentalLocationData}
            placeholder="Masukan Lokasi Pengiriman"
            value={schedulePriceData.locations}
            onChange={handleLokasiSewa}
            checkbox={true}
          />

          {/* PERUBAHAN HARGA */}
          <SelectFieldDropdown
            label="Perubahan Harga Mobil"
            htmlFor="perubahan-harga-mobil"
            data={priceChange}
            placeholder="Perubahan Harga Mobil"
            value={schedulePriceData.change_price}
            onChange={(e) => {
              setSchedulePriceData({ ...schedulePriceData, change_price: e.target.id });
            }}
          />
        </div>

        <div className="second-section">
          {/* PRICE */}
          <h3>Potongan Harga:</h3>
          <div className="radio">
            <RadioButton name="price" data={arrayRadionButton} state={priceType} setState={handleValueType} />
            <CheckboxField
              className="checkbox"
              label="High Season"
              htmlfor="high-season"
              onChange={(e) => setSchedulePriceData({ ...schedulePriceData, is_high_season: e.target.checked })}
              checked={schedulePriceData.is_high_season}
            />
          </div>
          <InputField
            type="number"
            placeholder={priceType === 'pr1' ? 'Rp 0' : priceType === 'pr2' ? '0%' : 'Rp 0'}
            value={schedulePriceData.price}
            onChange={handleValueInput}
            onWheel={numberInputOnWheelPreventChange}
            disable={!priceType}
          />
        </div>

        {/* ACTION BUTTON */}
        <div className="actions-button">
          <Button type="button" onClick={() => navigate(-1)} width="192px" height="39px" variant="outline">
            Kembali
          </Button>
          {(location.pathname.split('/')[2] === 'add-item' ||
            checkPermission(offCanvasMenu, currentMenu, 'delete')) && (
            <Button type="button" onClick={handleSubmit} width="192px" height="39px" variant="primary">
              Simpan
            </Button>
          )}
        </div>
      </div>
    </TableWrapper>
  );
};

export default SchedulePriceDetail;
