import { ReactComponent as PlusIcon } from 'icons/plus-icon-black.svg';

const ModalsNotification = ({ children, setState, onAddTemplate }) => {
  return (
    <div className="g-modal">
      <div className="g-modal-overlay" onClick={() => (setState ? setState(false) : () => {})} />
      <div className="modal">
        <header className="modal-headerNotification">
          <div className="modal-title">
            <h1>Kirim Notifikasi</h1>
          </div>

          <button className="modal-btn" onClick={onAddTemplate}>
            <PlusIcon />
            Buat Template
          </button>
        </header>
        {children}
      </div>
    </div>
  );
};

export default ModalsNotification;
