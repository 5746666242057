import { createSlice } from '@reduxjs/toolkit';

import { getAllBanks, getDetailAccountBank } from './actions';

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  detailAccountBank: {},
  errorMessage: '', // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const banks = createSlice({
  name: 'banks',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBanks.pending, (state) => {
        state.data = [];
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getAllBanks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllBanks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getDetailAccountBank.pending, (state) => {
        state.detailAccountBank = {};
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(getDetailAccountBank.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDetailAccountBank.fulfilled, (state, action) => {
        state.isLoading = false;
        state.detailAccountBank = action.payload;
      });
  },
});

export default banks.reducer;
