import { createSlice } from '@reduxjs/toolkit';
import { getAirportTransferRevenue, getAirportTransferRevenuetotal, getAirportTransferRevenueCount } from './actions';

const initialState = {
  data: {},
  total: null,
  count: null,
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const airportTransferRevenue = createSlice({
  name: 'airportTransferRevenue',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAirportTransferRevenue.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
        state.total = null;
        state.count = null;
      })
      .addCase(getAirportTransferRevenue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAirportTransferRevenue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.data = action.payload;
        state.total = action.payload.total;
        state.count = action.payload.count;
      })
      .addCase(getAirportTransferRevenuetotal.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.total = null;
      })
      .addCase(getAirportTransferRevenuetotal.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAirportTransferRevenuetotal.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.total = action.payload;
      })
      .addCase(getAirportTransferRevenueCount.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.count = null;
      })
      .addCase(getAirportTransferRevenueCount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAirportTransferRevenueCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.count = action.payload;
      });
  },
});

export default airportTransferRevenue.reducer;
