import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { getAllRentalLocation } from 'features/rental-location/actions';
import { createAddon, getDetailAddon, updateAddon } from 'features/addon/actions';
import { checkEmptyObject } from 'utils/functionality';
import { Button, InputField, TableWrapper } from 'components/Global';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import TextAreaInput from 'components/Global/TextArea';
import ColorVariation from './ColorVariation';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as AdditionalFilledIcon } from 'icons/additional-filled-icon.svg';

const getRealNameImage = (image) => {
  const newImageUrl = image.file_name
    .split('/')
    .slice(image.file_name.split('/').length - 3, image.file_name.split('/').length)
    .join('/');

  return {
    file_name: '/' + newImageUrl,
  };
};

const DetailAdditionalPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { showToast, setShowSpinner, setShowConfirmation } = useAppContext();

  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const { isLoading, selectedAddon } = useSelector((state) => state.addon);

  const [locationValue, setLocationValue] = useState('');
  const [additionalName, setAdditionalName] = useState('');
  const [additionalDescription, setAdditionalDescription] = useState('');
  const [additionalPrice, setAdditionalPrice] = useState('');
  const [colorVariation, setColorVariation] = useState([]);
  const [updateObj, setUpdateObj] = useState({});

  const [searchParams] = useSearchParams();

  const pathName = location.pathname.split('/')[2];
  const id = searchParams.get('id');
  const varieties_id = searchParams.getAll('variety_id');

  // FUNCTION TO PREVENT SCROLLING ON INPUT NUMBER TYPE
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const submitHandler = async () => {
    const validLocation = locationValue !== '';
    const validAddtionalName = additionalName.replace(/\s/, '') !== '';
    const validAdditionalDescription = additionalDescription.replace(/\s/, '') !== '';
    const validAdditionalPrice = additionalPrice !== '' && additionalPrice !== 0;
    const validVariation = colorVariation.length !== 0;

    if (!validLocation) {
      return showToast({ type: 'error', message: 'Pilih Lokasi' });
    }

    if (!validAddtionalName) {
      return showToast({ type: 'error', message: 'Masukan Nama Additional' });
    }

    if (!validAdditionalDescription) {
      return showToast({ type: 'error', message: 'Masukan Deskripsi Additional' });
    }

    if (!validAdditionalPrice) {
      return showToast({ type: 'error', message: 'Masukan Harga Additional' });
    }

    if (!validVariation) {
      return showToast({ type: 'error', message: 'Masukan Variasi Warna' });
    }

    if (pathName === 'add-item') {
      const newColorVariation = colorVariation.map((item) => {
        const newImages = item.images.map((img) => getRealNameImage(img));

        // eslint-disable-next-line no-unused-vars
        const { id, color, max_order, stock, images, ...rest } = item;

        return {
          color: item.color,
          max_order: +item.max_order,
          stock: +item.stock,
          images: newImages,
          ...rest,
        };
      });

      const additionalObject = {
        warehouse_id: locationValue.id,
        name: additionalName,
        description: additionalDescription,
        unit_price: +additionalPrice,
        varieties: newColorVariation,
      };

      setShowConfirmation({
        show: true,
        message: 'Apakah anda yakin ingin menambah additional?',
        onClick: async () => {
          try {
            await dispatch(createAddon(additionalObject)).unwrap();
            showToast({ type: 'success', message: 'Berhasil Tambah Additional' });
            navigate('/additional');
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            showToast({ type: 'error', message: `${error.message}` });
          }
        },
      });
    }

    if (pathName === 'detail') {
      const newColorVariation = colorVariation.map((item) => {
        const newImages = item.images.map((img) => getRealNameImage(img));

        // eslint-disable-next-line no-unused-vars
        const { id, color, max_order, stock, images, ...rest } = item;

        return {
          id: updateObj.variaties_to_add?.find((vari) => vari.id === item.id) ? undefined : id,
          color: item.color,
          max_order: +item.max_order,
          stock: +item.stock,
          images: newImages,
          ...rest,
        };
      });

      const additionalObject = {
        warehouse_id: locationValue.id,
        name: additionalName,
        description: additionalDescription,
        unit_price: +additionalPrice,
        varieties: newColorVariation,
      };

      let updateAdditionalObject = {};

      if (updateObj.variaties_to_add) {
        const newColorVariation = updateObj.variaties_to_add.map((item) => {
          const newImages = item.images.map((img) => getRealNameImage(img));

          return {
            color: item.color,
            max_order: +item.max_order,
            stock: +item.stock,
            images: newImages,
          };
        });

        // eslint-disable-next-line no-unused-vars
        const { variaties_to_add, ...rest } = updateObj;

        updateAdditionalObject = {
          ...rest,
          variaties_to_add: newColorVariation,
        };
      } else {
        updateAdditionalObject = { ...updateObj };
      }
      const newPayload = { ...additionalObject, ...updateAdditionalObject };

      setShowConfirmation({
        show: true,
        message: 'Apakah anda yakin ingin update additional?',
        onClick: async () => {
          try {
            await dispatch(updateAddon({ payloadData: newPayload, addonId: id })).unwrap();
            showToast({ type: 'success', message: 'Berhasil Update Additional' });
            navigate('/additional');
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            showToast({ type: 'error', message: `${error.message}` });
          }
        },
      });
    }
  };

  useEffect(() => {
    dispatch(getAllRentalLocation());

    if (pathName === 'detail') {
      varieties_id.forEach((item) => dispatch(getDetailAddon({ id, varietiesId: item })));
    }
  }, []);

  useEffect(() => {
    if (
      pathName !== 'detail' ||
      !selectedAddon ||
      checkEmptyObject(selectedAddon) ||
      !rentalLocationData ||
      rentalLocationData.length === 0
    )
      return;

    const wareHouseLocation = rentalLocationData.find((item) => item.id == selectedAddon.warehouse_id);

    setLocationValue(wareHouseLocation);
    setAdditionalName(selectedAddon.name);
    setAdditionalDescription(selectedAddon.description);
    setAdditionalPrice(selectedAddon.unit_price);

    if (colorVariation.some((obj) => obj.id === selectedAddon.varieties[0]?.id)) return;
    setColorVariation((prevState) => [...prevState, selectedAddon.varieties[0]]);
  }, [selectedAddon, rentalLocationData]);

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  return (
    <div className="detail-additional">
      <header className="detail-setting-harga__header" onClick={() => navigate(-1)}>
        <LeftArrow />
        <p>Kembali</p>
      </header>

      <TableWrapper
        title={pathName === 'add-item' ? 'Tambah Additional' : 'Additional'}
        icon={<AdditionalFilledIcon fill="#009EF7" width="25px" height="25px" />}
      >
        <div className="detail-additional__body">
          <SelectFieldShuttle
            label="Lokasi"
            htmlFor="lokasi"
            value={locationValue.id}
            data={rentalLocationData}
            onChange={setLocationValue}
            placeholder="Pilih Lokasi"
            searchFeature
          />

          <InputField
            label="Nama Additional"
            htmlFor="name-additional"
            value={additionalName}
            onChange={(e) => setAdditionalName(e.target.value)}
            placeholder="Tulis Nama Additional"
          />

          <TextAreaInput
            label="Deskripsi"
            className="additional-description"
            htmlFor="deskripsi"
            value={additionalDescription}
            onChange={(e) => setAdditionalDescription(e.target.value)}
            placeholder="Tulis Detail"
          />

          <InputField
            type="number"
            label="Harga Tambahan"
            htmlFor="harga-tambahan"
            value={additionalPrice}
            onChange={(e) => setAdditionalPrice(e.target.value)}
            placeholder="Rp.0"
            onWheel={numberInputOnWheelPreventChange}
          />

          <ColorVariation
            colorVariation={colorVariation}
            setColorVariation={setColorVariation}
            updateObj={updateObj}
            setUpdateObj={setUpdateObj}
          />

          <div className="submit-button">
            <Button width={192} role="button" variant="outline" type="button" onClick={() => navigate(-1)}>
              Kembali
            </Button>

            <Button width={192} role="button" type="button" onClick={submitHandler}>
              Simpan
            </Button>
          </div>
        </div>
      </TableWrapper>
    </div>
  );
};

export default DetailAdditionalPage;
