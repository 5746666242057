import clsx from 'clsx';
import React from 'react';

const TextAreaInput = ({ className, label, htmlFor, placeholder, value, onChange, disable, ...restProps }) => {
  return (
    <div className={clsx('g-input', className)} style={{ pointerEvents: disable ? 'none' : 'auto' }}>
      {label && (
        <label htmlFor={htmlFor} className="input-text-area-title">
          {label}
        </label>
      )}
      <section className="field-wrapper">
        <textarea
          className={clsx('input-text-area-field', disable && 'disable')}
          id={htmlFor}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...restProps}
        />
      </section>
    </div>
  );
};

export default TextAreaInput;
