import React, { useEffect, useState } from 'react';
import Modals from '../Modals';
import { ReactComponent as PeopleIcon } from 'icons/people-filled-icon.svg';
import { useAppContext } from 'components/Context/AppContext';
import { Button, InputField, RadioButton } from 'components/Global';

const LAYANAN_OPTIONS = [
  { id: 1, label: 'With Driver' },
  { id: 2, label: 'With Driver + Tour Guide' },
];

const AddTourScheduleModal = () => {
  const { setShowAddTourSchedule } = useAppContext();
  const [tourData, setTourData] = useState({
    jenis: '',
    nama: '',
    detail: '',
    destinasi: '',
    jumlah_hari: 0,
  });
  const [layanan, setLayanan] = useState(1);

  useEffect(() => {
    setTourData((prev) => ({ ...prev, jenis: parseInt(layanan) }));
  }, [layanan]);

  return (
    <Modals
      setState={setShowAddTourSchedule}
      icon={<PeopleIcon fill="#FF9900" width="25px" height="25px" />}
      title="Tour Schedule"
    >
      <div className="add-tour-modal">
        <div className="add-tour-content">
          <div className="content--left">
            <InputField
              label="Nama Tour"
              htmlFor="nama-tour"
              className="name-input"
              placeholder="Tulis Nama Tour"
              value={tourData.nama}
              onChange={(e) => setTourData((prev) => ({ ...prev, nama: e.target.value }))}
            />
            <div className="radio-input">
              <h3>Layanan</h3>
              <div className="input-group">
                <RadioButton data={LAYANAN_OPTIONS} name="jenis" setState={setLayanan} state={layanan} />
              </div>
            </div>
            <div className="textarea-input">
              <h3>Detail</h3>
              <textarea
                placeholder="Tulis Detail"
                value={tourData.detail}
                onChange={(e) => setTourData((prev) => ({ ...prev, detail: e.target.value }))}
              ></textarea>
            </div>
          </div>
          <div className="content--right">
            <div className="textarea-input">
              <h3>Destinasi</h3>
              <textarea
                placeholder="Tulis Destinasi"
                value={tourData.destinasi}
                onChange={(e) => setTourData((prev) => ({ ...prev, destinasi: e.target.value }))}
              ></textarea>
            </div>
            <InputField
              label="Jumlah Hari"
              htmlFor="jumlah-hari"
              className="jumlah-input"
              type="number"
              placeholder="Tulis Jumlah Hari"
              value={tourData.jumlah_hari}
              onChange={(e) => setTourData((prev) => ({ ...prev, jumlah_hari: parseInt(e.target.value) }))}
              min={0}
            />
          </div>
        </div>
        <div className="add-tour-buttons">
          <Button className="button" size="sm" width="192px" onClick={() => setShowAddTourSchedule(false)}>
            Tambah
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default AddTourScheduleModal;
