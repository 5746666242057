import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, InputField, TableWrapper } from 'components/Global';
// import { ReactComponent as CarChair } from 'icons/car-chair-icon.svg';
import { ReactComponent as AirportIcon } from 'icons/airport-transfer-filled.svg';
import { ReactComponent as PointsIcon } from 'icons/points-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerData } from 'features/user/slice';
import countryCodes from 'utils/country-codes.json';
import clsx from 'clsx';
import { getDetailAccountBank } from 'features/banks/actions';
import { getVehicleById } from 'features/vehicle/action';
import Tabs, { TabPane } from 'components/Global/Tabs';
import { checkEmptyObject, setCurrency } from 'utils/functionality';
import { indonesianDateFormat } from 'utils/helpers';
import { getPaymentMethodString } from 'utils/getPaymentMethod';
import { getLocationTimezone } from 'utils/getTimezone';
import TextAreaInput from 'components/Global/TextArea';
import { downloadOrderDetail } from 'features/order-detail-pdf/actions';
import { useAppContext } from 'components/Context/AppContext';
import { fetchCustomerInfo } from 'features/user/actions';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const AirportTransferDetail = ({
  orderData: data,
  getFileName,
  imagePreviewHandler,
  transactionKey,
  newOrderData,
  oldOrderData,
  confirmHandler,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const customer = useSelector(getCustomerData);
  const customerAccountBank = useSelector((state) => state.banks?.detailAccountBank ?? {});
  const airportPackageVehicle = useSelector((state) => state.airportTransferOrder.select);

  const { showToast } = useAppContext();

  const approvalMethod = newOrderData?.approval_setting?.name.includes('update order') ? 'update' : 'delete';

  const [selectedCountry, setSelectedCountry] = useState({
    name: '',
    code: '',
  });
  const [activeTab, setActiveTab] = useState('Data Sekarang');
  const [oldVehicle, setOldVehicle] = useState('');
  const [newVehicle, setNewVehicle] = useState('');

  const backHandler = () => {
    navigate(-1);
  };

  const findCountryByCode = (code) => countryCodes.find((item) => item.dial_code == code);
  const customerId = searchParams.get('customerId');

  useEffect(() => {
    if (!customerId) return;

    dispatch(getDetailAccountBank(customerId));
    dispatch(fetchCustomerInfo(customerId));
  }, [customerId]);

  useEffect(() => {
    if (!Object.prototype.hasOwnProperty.call(data, 'order_detail')) return;
    if (!transactionKey) return;
    dispatch(getVehicleById(data.order_detail.vehicle_id)).then((res) => setOldVehicle(res.payload));
  }, [data]);

  useEffect(() => {
    if (!oldVehicle) return;
    if (!Object.prototype.hasOwnProperty.call(newOrderData, 'data')) return;
    if (newOrderData.data === null) return;
    dispatch(getVehicleById(newOrderData.data.order_detail.vehicle_id)).then((res) => setNewVehicle(res.payload));
  }, [newOrderData, oldVehicle]);

  useEffect(() => {
    if (customer) {
      setSelectedCountry(findCountryByCode(data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code));
    }
  }, [customer, data]);

  const handleExportToPdf = async () => {
    try {
      await dispatch(downloadOrderDetail(data?.transaction_key)).unwrap();
    } catch (error) {
      showToast({ type: 'error', message: 'Gagal Export ke PDF' });
    }
  };

  const timezone = !data?.order_detail?.loc_time_id ? '' : getLocationTimezone(data?.order_detail?.loc_time_id);

  return (
    <div className="detail-order-airport">
      <TableWrapper
        title="Airport Transfer"
        icon={<AirportIcon fill="#009EF7" width="25px" height="25px" />}
        showExportButton={approvalMethod === 'delete'}
        onExportToPdf={handleExportToPdf}
      >
        {approvalMethod === 'update' ? (
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
            <TabPane label="Data Sekarang">
              <div className="detail-order-airport__renter-detail">
                <div className="detail-order-airport__renter-detail-title">
                  <h1>Detail Penyewa</h1>
                </div>
                <div className="detail-order-airport__renter-detail-body">
                  <InputField label="Nama Lengkap" disabled value={newOrderData?.data?.user_name || '-'} />
                  <InputField label="Email" disabled value={newOrderData?.data?.email || '-'} />

                  <div className="detail-order-airport__renter-detail-body__phone-number">
                    <InputField
                      label="No Handphone"
                      disabled
                      value={
                        newOrderData?.data?.is_admin_creation
                          ? newOrderData?.data?.phone_country_code
                          : customer?.phone_code || '-'
                      }
                      icon={
                        <img
                          src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                          width={26}
                          height={16}
                          alt={selectedCountry?.name + ' nation flag'}
                        />
                      }
                    />
                    <InputField value={newOrderData?.data?.phone_number || '-'} disabled />
                  </div>

                  <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                  <div className="detail-order-airport__renter-detail-body__phone-number">
                    <div className="detail-order-airport__renter-detail-body__phone-number-wrapper">
                      <InputField
                        label="Whatsapp"
                        disabled
                        value={
                          newOrderData?.data?.is_admin_creation
                            ? newOrderData?.data?.phone_country_code
                            : customer?.phone_code || '-'
                        }
                        icon={
                          <img
                            src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                            width={26}
                            height={16}
                            alt={selectedCountry?.name + ' nation flag'}
                          />
                        }
                      />
                      <div className="detail-order-airport__renter-detail-body__image-wrapper"></div>
                    </div>
                    <InputField disabled value={newOrderData?.data?.wa_number || '-'} />
                  </div>
                </div>

                <div className="detail-order-airport__identity-detail-body">
                  <div className="detail-order-airport__identity-detail-body__preview-image">
                    <InputField
                      label="KTP"
                      disabled
                      value={
                        newOrderData?.data?.is_admin_creation &&
                        getFileName(newOrderData?.data?.order_detail?.identity?.ktp) !== ''
                          ? getFileName(newOrderData?.data?.order_detail?.identity?.ktp)
                          : customer?.PersonalInfos?.ktp
                          ? getFileName(customer?.PersonalInfos?.ktp)
                          : 'Belum Upload KTP'
                      }
                      className="preview-image-input"
                    />
                    {(newOrderData?.data?.is_admin_creation &&
                      getFileName(newOrderData?.data?.order_detail?.identity?.ktp) !== '') ||
                    customer?.PersonalInfos?.ktp ? (
                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            newOrderData?.data?.is_admin_creation
                              ? newOrderData?.data?.order_detail?.identity?.ktp
                              : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    ) : null}
                  </div>

                  <div className="detail-order-airport__identity-detail-body__preview-image">
                    <InputField
                      label="SIM"
                      disabled
                      value={
                        newOrderData?.data?.is_admin_creation &&
                        getFileName(newOrderData?.data?.order_detail?.identity?.sim) !== ''
                          ? getFileName(newOrderData?.data?.order_detail?.identity?.sim)
                          : customer?.PersonalInfos?.sim
                          ? getFileName(customer?.PersonalInfos?.sim)
                          : 'Belum Upload SIM'
                      }
                      className="preview-image-input"
                    />
                    {(newOrderData?.data?.is_admin_creation &&
                      getFileName(newOrderData?.data?.order_detail?.identity?.sim) !== '') ||
                    customer?.PersonalInfos?.sim ? (
                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            newOrderData?.data?.is_admin_creation
                              ? newOrderData?.data?.order_detail?.identity?.sim
                              : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="detail-order-airport__rent-detail">
                <div className="detail-order-airport__rent-detail-title">
                  <h1>Detail Order</h1>
                </div>
                <div className="detail-order-airport__rent-detail-body">
                  <InputField
                    label="Lokasi Penjemputan"
                    disabled
                    value={newOrderData?.data?.order_detail?.rental_delivery_location || '-'}
                  />
                  <InputField
                    label="Lokasi Pengantaran"
                    disabled
                    value={newOrderData?.data?.order_detail?.rental_return_location || '-'}
                  />
                  <InputField
                    label="Detail Lokasi Penjemputan"
                    disabled
                    value={newOrderData?.data?.order_detail?.rental_delivery_location_detail || '-'}
                  />
                  <InputField
                    label="Detail Lokasi Pengantaran"
                    disabled
                    value={newOrderData?.data?.order_detail?.rental_return_location_detail || '-'}
                  />
                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Tanggal"
                      disabled
                      value={
                        newOrderData?.data?.order_detail?.start_booking_date
                          ? indonesianDateFormat(newOrderData?.data?.order_detail?.start_booking_date)
                          : '-'
                      }
                    />
                    <InputField
                      label="Jam"
                      disabled
                      value={(newOrderData?.data?.order_detail?.start_booking_time || '-') + ' ' + timezone}
                    />
                  </div>
                  <InputField
                    label="Nomor Penerbangan"
                    disabled
                    value={newOrderData?.data?.order_detail?.flight_number || '-'}
                  />
                  <TextAreaInput
                    style={{
                      height:
                        newOrderData?.data?.airport_package_vehicle_type?.split(', ').length >= 10
                          ? 96
                          : newOrderData?.data?.airport_package_vehicle_type?.split(', ').length >= 5
                          ? 44 * 2
                          : 44,
                    }}
                    label="Jenis Mobil"
                    disabled
                    value={newOrderData?.data?.airport_package_vehicle_type ?? '-'}
                    className="text-area-car-package"
                  />

                  <div className="detail-order__rent-detail-body__rent-date">
                    <InputField
                      label="Kapasitas Koper"
                      disabled
                      value={
                        newOrderData?.data?.order_status === 'PENDING' ||
                        newOrderData?.data?.order_status === 'CHECKOUT'
                          ? airportPackageVehicle?.max_suitecase
                          : newOrderData?.data?.order_status === 'PAID' && !newVehicle
                          ? airportPackageVehicle?.max_suitecase
                          : newVehicle?.max_suitcase ?? '-'
                      }
                    />
                    <InputField
                      label="Koper yang dibawa"
                      disabled
                      value={newOrderData?.data?.order_detail?.baggage ?? 0}
                    />
                  </div>
                </div>
              </div>

              <div className="detail-order-airport__price-detail">
                <div className="detail-order-airport__price-detail-title">
                  <h1>Rincian Biaya</h1>
                </div>

                <div className="detail-order-airport__price-detail-body">
                  <InputField
                    label="Biaya Sewa Mobil"
                    disabled
                    value={setCurrency(newOrderData?.data?.booking_price) || '-'}
                  />
                  <InputField
                    label="Biaya Pengantaran"
                    disabled
                    value={setCurrency(newOrderData?.data?.rental_delivery_fee) || '-'}
                  />
                  <InputField
                    label="Outside Operational Hour Charge"
                    disabled
                    value={setCurrency(newOrderData?.data?.outside_operational_charge) || '-'}
                  />
                  <InputField
                    label="Pembayaran Dengan Point"
                    disabled
                    value={newOrderData?.data?.point}
                    icon={<PointsIcon />}
                    iconPosition="start"
                  />
                  <InputField
                    label="Charge Payment Gateway Platform"
                    disabled
                    value={setCurrency(newOrderData?.data?.disbursement?.total_amount_external_payment_fee) || '-'}
                  />
                  <InputField
                    label="Customer Service Platform"
                    disabled
                    value={setCurrency(newOrderData?.data?.disbursement?.total_amount_customer_service_fee) || '-'}
                  />
                  <InputField
                    label="Total Keseluruhan"
                    disabled
                    value={setCurrency(newOrderData?.data?.total_payment) || '-'}
                  />
                </div>
              </div>

              <div className="detail-order-with-driver__deposit-detail">
                <div className="detail-order-with-driver__deposit-detail-title">
                  <h1>Detail Deposit</h1>
                </div>
                <div className="detail-order-with-driver__deposit-detail-body">
                  <InputField label="Nama Akun" disabled value={customerAccountBank.nama_rek || '-'} />
                  <InputField label="No Rekening" disabled value={customerAccountBank.no_rek || '-'} />
                  <InputField label="Nama Bank" disabled value={customerAccountBank.nama_bank || '-'} />
                </div>
              </div>

              <div className="detail-order__voucher-detail">
                <div className="detail-order__voucher-detail-title">
                  <h1>Voucher</h1>
                </div>
                <div className="detail-order__voucher-detail-body">
                  <InputField label="Nama Voucher" disabled value={newOrderData?.data?.vouchers?.[0].name || '-'} />
                  <InputField label="Kode Voucher" disabled value={newOrderData?.data?.vouchers?.[0].code || '-'} />
                  <InputField
                    label="Harga Potongan"
                    disabled
                    value={setCurrency(newOrderData?.data?.vouchers?.[0].value) || '-'}
                  />
                </div>
              </div>

              <div className="detail-order-airport__payment-detail">
                <div className="detail-order-airport__payment-detail-title">
                  <h1>Pembayaran</h1>
                </div>
                <div className="detail-order-airport__payment-detail-body">
                  <InputField label="Status Pembayaran" disabled value={newOrderData?.data?.order_status || '-'} />

                  <div
                    className={clsx(
                      'detail-order-airport__payment-detail-body__payment-method',
                      data.order_status === 'CHECKOUT' ? 'confirmation' : 'not-confirmation',
                    )}
                  >
                    <InputField
                      label="Metode Pembayaran"
                      disabled
                      // value={newOrderData?.data?.disbursement?.payment?.method ? newOrderData?.data?.disbursement.payment.method : '-'}
                      value={getPaymentMethodString(data?.disbursement)}
                    />
                  </div>

                  {data.order_status !== 'CHECKOUT' &&
                  data.order_status !== 'RECONFIRMATION' &&
                  Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                    <>
                      <InputField
                        label="Nama Rekening"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_name
                            : newOrderData?.data?.disbursement?.sender_name || '-'
                        }
                      />

                      <InputField
                        label="Nama Bank"
                        disabled
                        value={
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                            : newOrderData?.data?.disbursement?.sender_bank_name || '-'
                        }
                      />

                      <div className="detail-order__payment-detail-body__preview-image">
                        <InputField
                          label="Foto Bukti Transfer"
                          disabled
                          value={getFileName(
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                              : newOrderData?.data?.disbursement?.disbursement_confirmation_image,
                          )}
                          className="preview-image-input"
                        />

                        <Button
                          bgColor="#D9D9D9"
                          textColor="#000000"
                          className="preview-image-btn"
                          onClick={() =>
                            imagePreviewHandler(
                              Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                : newOrderData?.data?.disbursement?.disbursement_confirmation_image,
                            )
                          }
                        >
                          Lihat
                        </Button>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </TabPane>

            {!checkEmptyObject(oldOrderData) && approvalMethod === 'update' ? (
              <TabPane label="Data Sebelum">
                <div className="detail-order-airport__renter-detail">
                  <div className="detail-order-airport__renter-detail-title">
                    <h1>Detail Penyewa</h1>
                  </div>
                  <div className="detail-order-airport__renter-detail-body">
                    <InputField label="Nama Lengkap" disabled value={oldOrderData?.user_name || '-'} />
                    <InputField label="Email" disabled value={oldOrderData?.email || '-'} />

                    <div className="detail-order-airport__renter-detail-body__phone-number">
                      <InputField
                        label="No Handphone"
                        disabled
                        value={
                          oldOrderData?.is_admin_creation
                            ? oldOrderData?.phone_country_code
                            : customer?.phone_code || '-'
                        }
                        icon={
                          <img
                            src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                            width={26}
                            height={16}
                            alt={selectedCountry?.name + ' nation flag'}
                          />
                        }
                      />
                      <InputField value={oldOrderData?.phone_number || '-'} disabled />
                    </div>

                    <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                    <div className="detail-order-airport__renter-detail-body__phone-number">
                      <div className="detail-order-airport__renter-detail-body__phone-number-wrapper">
                        <InputField
                          label="Whatsapp"
                          disabled
                          value={
                            oldOrderData?.is_admin_creation
                              ? oldOrderData?.phone_country_code
                              : customer?.phone_code || '-'
                          }
                          icon={
                            <img
                              src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                              width={26}
                              height={16}
                              alt={selectedCountry?.name + ' nation flag'}
                            />
                          }
                        />
                        <div className="detail-order-airport__renter-detail-body__image-wrapper"></div>
                      </div>
                      <InputField disabled value={oldOrderData?.wa_number || '-'} />
                    </div>
                  </div>

                  <div className="detail-order-airport__identity-detail-body">
                    <div className="detail-order-airport__identity-detail-body__preview-image">
                      <InputField
                        label="KTP"
                        disabled
                        value={
                          oldOrderData?.is_admin_creation &&
                          getFileName(oldOrderData?.order_detail?.identity?.ktp) !== ''
                            ? getFileName(oldOrderData?.order_detail?.identity?.ktp)
                            : customer?.PersonalInfos?.ktp
                            ? getFileName(customer?.PersonalInfos?.ktp)
                            : 'Belum Upload KTP'
                        }
                        className="preview-image-input"
                      />
                      {(oldOrderData?.is_admin_creation &&
                        getFileName(oldOrderData?.order_detail?.identity?.ktp) !== '') ||
                      customer?.PersonalInfos?.ktp ? (
                        <Button
                          bgColor="#D9D9D9"
                          textColor="#000000"
                          className="preview-image-btn"
                          onClick={() =>
                            imagePreviewHandler(
                              oldOrderData?.is_admin_creation
                                ? oldOrderData?.order_detail?.identity?.ktp
                                : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                            )
                          }
                        >
                          Lihat
                        </Button>
                      ) : null}
                    </div>

                    <div className="detail-order-airport__identity-detail-body__preview-image">
                      <InputField
                        label="SIM"
                        disabled
                        value={
                          oldOrderData?.is_admin_creation &&
                          getFileName(oldOrderData?.order_detail?.identity?.sim) !== ''
                            ? getFileName(oldOrderData?.order_detail?.identity?.sim)
                            : customer?.PersonalInfos?.sim
                            ? getFileName(customer?.PersonalInfos?.sim)
                            : 'Belum Upload SIM'
                        }
                        className="preview-image-input"
                      />
                      {(oldOrderData?.is_admin_creation &&
                        getFileName(oldOrderData?.order_detail?.identity?.sim) !== '') ||
                      customer?.PersonalInfos?.sim ? (
                        <Button
                          bgColor="#D9D9D9"
                          textColor="#000000"
                          className="preview-image-btn"
                          onClick={() =>
                            imagePreviewHandler(
                              oldOrderData?.is_admin_creation
                                ? oldOrderData?.order_detail?.identity?.sim
                                : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                            )
                          }
                        >
                          Lihat
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="detail-order-airport__rent-detail">
                  <div className="detail-order-airport__rent-detail-title">
                    <h1>Detail Order</h1>
                  </div>
                  <div className="detail-order-airport__rent-detail-body">
                    <InputField
                      label="Lokasi Penjemputan"
                      disabled
                      value={oldOrderData?.order_detail?.rental_delivery_location || '-'}
                    />
                    <InputField
                      label="Lokasi Pengantaran"
                      disabled
                      value={oldOrderData?.order_detail?.rental_return_location || '-'}
                    />
                    <InputField
                      label="Detail Lokasi Penjemputan"
                      disabled
                      value={oldOrderData?.order_detail?.rental_delivery_location_detail || '-'}
                    />
                    <InputField
                      label="Detail Lokasi Pengantaran"
                      disabled
                      value={oldOrderData?.order_detail?.rental_return_location_detail || '-'}
                    />
                    <div className="detail-order__rent-detail-body__rent-date">
                      <InputField
                        label="Tanggal"
                        disabled
                        value={
                          oldOrderData?.order_detail?.start_booking_date
                            ? indonesianDateFormat(oldOrderData?.order_detail?.start_booking_date)
                            : '-'
                        }
                      />
                      <InputField
                        label="Jam"
                        disabled
                        value={(oldOrderData?.order_detail?.start_booking_time || '-') + ' ' + timezone}
                      />
                    </div>
                    <InputField
                      label="Nomor Penerbangan"
                      disabled
                      value={oldOrderData?.order_detail?.flight_number || '-'}
                    />
                    <TextAreaInput
                      style={{
                        height:
                          oldOrderData?.airport_package_vehicle_type?.split(', ').length >= 10
                            ? 96
                            : oldOrderData?.airport_package_vehicle_type?.split(', ').length >= 5
                            ? 44 * 2
                            : 44,
                      }}
                      label="Jenis Mobil"
                      disabled
                      value={oldOrderData?.airport_package_vehicle_type ?? '-'}
                      className="text-area-car-package"
                    />

                    <div className="detail-order__rent-detail-body__rent-date">
                      <InputField
                        label="Kapasitas Koper"
                        disabled
                        value={
                          oldOrderData?.order_status === 'PENDING' || oldOrderData?.order_status === 'CHECKOUT'
                            ? airportPackageVehicle?.max_suitecase
                            : oldOrderData?.order_status === 'PAID' && !oldVehicle
                            ? airportPackageVehicle?.max_suitecase
                            : oldVehicle?.max_suitcase ?? '-'
                        }
                      />
                      <InputField label="Koper yang dibawa" disabled value={oldOrderData?.order_detail?.baggage ?? 0} />
                    </div>
                  </div>
                </div>

                <div className="detail-order-airport__price-detail">
                  <div className="detail-order-airport__price-detail-title">
                    <h1>Rincian Biaya</h1>
                  </div>

                  <div className="detail-order-airport__price-detail-body">
                    <InputField
                      label="Biaya Sewa Mobil"
                      disabled
                      value={setCurrency(oldOrderData?.booking_price) || '-'}
                    />
                    <InputField
                      label="Biaya Pengantaran"
                      disabled
                      value={setCurrency(oldOrderData?.rental_delivery_fee) || '-'}
                    />
                    <InputField
                      label="Outside Operational Hour Charge"
                      disabled
                      value={setCurrency(oldOrderData?.outside_operational_charge) || '-'}
                    />
                    <InputField
                      label="Pembayaran Dengan Point"
                      disabled
                      value={oldOrderData?.point}
                      icon={<PointsIcon />}
                      iconPosition="start"
                    />
                    <InputField
                      label="Charge Payment Gateway Platform"
                      disabled
                      value={setCurrency(oldOrderData?.disbursement?.total_amount_external_payment_fee) || '-'}
                    />
                    <InputField
                      label="Customer Service Platform"
                      disabled
                      value={setCurrency(oldOrderData?.disbursement?.total_amount_customer_service_fee) || '-'}
                    />
                    <InputField
                      label="Total Keseluruhan"
                      disabled
                      value={setCurrency(oldOrderData?.total_payment) || '-'}
                    />
                  </div>
                </div>

                <div className="detail-order-with-driver__deposit-detail">
                  <div className="detail-order-with-driver__deposit-detail-title">
                    <h1>Detail Deposit</h1>
                  </div>
                  <div className="detail-order-with-driver__deposit-detail-body">
                    <InputField label="Nama Akun" disabled value={customerAccountBank.nama_rek || '-'} />
                    <InputField label="No Rekening" disabled value={customerAccountBank.no_rek || '-'} />
                    <InputField label="Nama Bank" disabled value={customerAccountBank.nama_bank || '-'} />
                  </div>
                </div>

                <div className="detail-order__voucher-detail">
                  <div className="detail-order__voucher-detail-title">
                    <h1>Voucher</h1>
                  </div>
                  <div className="detail-order__voucher-detail-body">
                    <InputField label="Nama Voucher" disabled value={oldOrderData?.vouchers?.[0].name || '-'} />
                    <InputField label="Kode Voucher" disabled value={oldOrderData?.vouchers?.[0].code || '-'} />
                    <InputField
                      label="Harga Potongan"
                      disabled
                      value={setCurrency(oldOrderData?.vouchers?.[0].value) || '-'}
                    />
                  </div>
                </div>

                <div className="detail-order-airport__payment-detail">
                  <div className="detail-order-airport__payment-detail-title">
                    <h1>Pembayaran</h1>
                  </div>
                  <div className="detail-order-airport__payment-detail-body">
                    <InputField label="Status Pembayaran" disabled value={oldOrderData?.order_status || '-'} />

                    <div
                      className={clsx(
                        'detail-order-airport__payment-detail-body__payment-method',
                        data.order_status === 'CHECKOUT' ? 'confirmation' : 'not-confirmation',
                      )}
                    >
                      <InputField
                        label="Metode Pembayaran"
                        disabled
                        // value={oldOrderData?.disbursement?.payment?.method ? oldOrderData?.disbursement.payment.method : '-'}
                        value={getPaymentMethodString(data?.disbursement)}
                      />
                    </div>

                    {data.order_status !== 'CHECKOUT' &&
                    data.order_status !== 'RECONFIRMATION' &&
                    Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                      <>
                        <InputField
                          label="Nama Rekening"
                          disabled
                          value={
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.sender_name
                              : oldOrderData?.disbursement?.sender_name || '-'
                          }
                        />

                        <InputField
                          label="Nama Bank"
                          disabled
                          value={
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                              : oldOrderData?.disbursement?.sender_bank_name || '-'
                          }
                        />

                        <div className="detail-order__payment-detail-body__preview-image">
                          <InputField
                            label="Foto Bukti Transfer"
                            disabled
                            value={getFileName(
                              Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                : oldOrderData?.disbursement?.disbursement_confirmation_image,
                            )}
                            className="preview-image-input"
                          />

                          <Button
                            bgColor="#D9D9D9"
                            textColor="#000000"
                            className="preview-image-btn"
                            onClick={() =>
                              imagePreviewHandler(
                                Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                  ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                  : oldOrderData?.disbursement?.disbursement_confirmation_image,
                              )
                            }
                          >
                            Lihat
                          </Button>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </TabPane>
            ) : (
              <div />
            )}
          </Tabs>
        ) : (
          <>
            <div className="detail-order-airport__renter-detail">
              <div className="detail-order-airport__renter-detail-title">
                <h1>Detail Penyewa</h1>
              </div>
              <div className="detail-order-airport__renter-detail-body">
                <InputField label="Nama Lengkap" disabled value={newOrderData?.data?.user_name || '-'} />
                <InputField label="Email" disabled value={newOrderData?.data?.email || '-'} />

                <div className="detail-order-airport__renter-detail-body__phone-number">
                  <InputField
                    label="No Handphone"
                    disabled
                    value={
                      newOrderData?.data?.is_admin_creation
                        ? newOrderData?.data?.phone_country_code
                        : customer?.phone_code || '-'
                    }
                    icon={
                      <img
                        src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                        width={26}
                        height={16}
                        alt={selectedCountry?.name + ' nation flag'}
                      />
                    }
                  />
                  <InputField value={newOrderData?.data?.phone_number || '-'} disabled />
                </div>

                <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                <div className="detail-order-airport__renter-detail-body__phone-number">
                  <div className="detail-order-airport__renter-detail-body__phone-number-wrapper">
                    <InputField
                      label="Whatsapp"
                      disabled
                      value={
                        newOrderData?.data?.is_admin_creation
                          ? newOrderData?.data?.phone_country_code
                          : customer?.phone_code || '-'
                      }
                      icon={
                        <img
                          src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                          width={26}
                          height={16}
                          alt={selectedCountry?.name + ' nation flag'}
                        />
                      }
                    />
                    <div className="detail-order-airport__renter-detail-body__image-wrapper"></div>
                  </div>
                  <InputField disabled value={newOrderData?.data?.wa_number || '-'} />
                </div>
              </div>

              <div className="detail-order-airport__identity-detail-body">
                <div className="detail-order-airport__identity-detail-body__preview-image">
                  <InputField
                    label="KTP"
                    disabled
                    value={
                      newOrderData?.data?.is_admin_creation &&
                      getFileName(newOrderData?.data?.order_detail?.identity?.ktp) !== ''
                        ? getFileName(newOrderData?.data?.order_detail?.identity?.ktp)
                        : customer?.PersonalInfos?.ktp
                        ? getFileName(customer?.PersonalInfos?.ktp)
                        : 'Belum Upload KTP'
                    }
                    className="preview-image-input"
                  />
                  {(newOrderData?.data?.is_admin_creation &&
                    getFileName(newOrderData?.data?.order_detail?.identity?.ktp) !== '') ||
                  customer?.PersonalInfos?.ktp ? (
                    <Button
                      bgColor="#D9D9D9"
                      textColor="#000000"
                      className="preview-image-btn"
                      onClick={() =>
                        imagePreviewHandler(
                          newOrderData?.data?.is_admin_creation
                            ? newOrderData?.data?.order_detail?.identity?.ktp
                            : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                        )
                      }
                    >
                      Lihat
                    </Button>
                  ) : null}
                </div>

                <div className="detail-order-airport__identity-detail-body__preview-image">
                  <InputField
                    label="SIM"
                    disabled
                    value={
                      newOrderData?.data?.is_admin_creation &&
                      getFileName(newOrderData?.data?.order_detail?.identity?.sim) !== ''
                        ? getFileName(newOrderData?.data?.order_detail?.identity?.sim)
                        : customer?.PersonalInfos?.sim
                        ? getFileName(customer?.PersonalInfos?.sim)
                        : 'Belum Upload SIM'
                    }
                    className="preview-image-input"
                  />
                  {(newOrderData?.data?.is_admin_creation &&
                    getFileName(newOrderData?.data?.order_detail?.identity?.sim) !== '') ||
                  customer?.PersonalInfos?.sim ? (
                    <Button
                      bgColor="#D9D9D9"
                      textColor="#000000"
                      className="preview-image-btn"
                      onClick={() =>
                        imagePreviewHandler(
                          newOrderData?.data?.is_admin_creation
                            ? newOrderData?.data?.order_detail?.identity?.sim
                            : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                        )
                      }
                    >
                      Lihat
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="detail-order-airport__rent-detail">
              <div className="detail-order-airport__rent-detail-title">
                <h1>Detail Order</h1>
              </div>
              <div className="detail-order-airport__rent-detail-body">
                <InputField
                  label="Lokasi Penjemputan"
                  disabled
                  value={newOrderData?.data?.order_detail?.rental_delivery_location || '-'}
                />
                <InputField
                  label="Lokasi Pengantaran"
                  disabled
                  value={newOrderData?.data?.order_detail?.rental_return_location || '-'}
                />
                <InputField
                  label="Detail Lokasi Penjemputan"
                  disabled
                  value={newOrderData?.data?.order_detail?.rental_delivery_location_detail || '-'}
                />
                <InputField
                  label="Detail Lokasi Pengantaran"
                  disabled
                  value={newOrderData?.data?.order_detail?.rental_return_location_detail || '-'}
                />
                <div className="detail-order__rent-detail-body__rent-date">
                  <InputField
                    label="Tanggal"
                    disabled
                    value={
                      newOrderData?.data?.order_detail?.start_booking_date
                        ? indonesianDateFormat(newOrderData?.data?.order_detail?.start_booking_date)
                        : '-'
                    }
                  />
                  <InputField
                    label="Jam"
                    disabled
                    value={(newOrderData?.data?.order_detail?.start_booking_time || '-') + ' ' + timezone}
                  />
                </div>
                <InputField
                  label="Nomor Penerbangan"
                  disabled
                  value={newOrderData?.data?.order_detail?.flight_number || '-'}
                />
                <TextAreaInput
                  style={{
                    height:
                      newOrderData?.data?.airport_package_vehicle_type?.split(', ').length >= 10
                        ? 96
                        : newOrderData?.data?.airport_package_vehicle_type?.split(', ').length >= 5
                        ? 44 * 2
                        : 44,
                  }}
                  label="Jenis Mobil"
                  disabled
                  value={newOrderData?.data?.airport_package_vehicle_type ?? '-'}
                  className="text-area-car-package"
                />

                <div className="detail-order__rent-detail-body__rent-date">
                  <InputField
                    label="Kapasitas Koper"
                    disabled
                    value={
                      newOrderData?.data?.order_status === 'PENDING' || newOrderData?.data?.order_status === 'CHECKOUT'
                        ? airportPackageVehicle?.max_suitecase
                        : newOrderData?.data?.order_status === 'PAID' && !newVehicle
                        ? airportPackageVehicle?.max_suitecase
                        : newVehicle?.max_suitcase ?? '-'
                    }
                  />
                  <InputField
                    label="Koper yang dibawa"
                    disabled
                    value={newOrderData?.data?.order_detail?.baggage ?? 0}
                  />
                </div>
              </div>
            </div>

            <div className="detail-order-airport__price-detail">
              <div className="detail-order-airport__price-detail-title">
                <h1>Rincian Biaya</h1>
              </div>

              <div className="detail-order-airport__price-detail-body">
                <InputField
                  label="Biaya Sewa Mobil"
                  disabled
                  value={setCurrency(newOrderData?.data?.booking_price) || '-'}
                />
                <InputField
                  label="Biaya Pengantaran"
                  disabled
                  value={setCurrency(newOrderData?.data?.rental_delivery_fee) || '-'}
                />
                <InputField
                  label="Outside Operational Hour Charge"
                  disabled
                  value={setCurrency(newOrderData?.data?.outside_operational_charge) || '-'}
                />
                <InputField
                  label="Pembayaran Dengan Point"
                  disabled
                  value={newOrderData?.data?.point}
                  icon={<PointsIcon />}
                  iconPosition="start"
                />
                <InputField
                  label="Charge Payment Gateway Platform"
                  disabled
                  value={setCurrency(newOrderData?.data?.disbursement?.total_amount_external_payment_fee) || '-'}
                />
                <InputField
                  label="Customer Service Platform"
                  disabled
                  value={setCurrency(newOrderData?.data?.disbursement?.total_amount_customer_service_fee) || '-'}
                />
                <InputField
                  label="Total Harga Bersih"
                  disabled
                  value={setCurrency(newOrderData?.data?.disbursement?.total_net_amount) || '-'}
                />
                <InputField
                  label="Total Bruto"
                  disabled
                  value={setCurrency(newOrderData?.data?.disbursement?.total_amount_with_customer_service_fee) || '-'}
                />
                <InputField
                  label="Total Keseluruhan"
                  disabled
                  value={setCurrency(newOrderData?.data?.total_payment) || '-'}
                />
                <InputField
                  label="Harga Selisih"
                  disabled
                  value={setCurrency(newOrderData?.data?.total_diff_price_with_approval) || '-'}
                />
              </div>
            </div>

            <div className="detail-order-with-driver__deposit-detail">
              <div className="detail-order-with-driver__deposit-detail-title">
                <h1>Detail Deposit</h1>
              </div>
              <div className="detail-order-with-driver__deposit-detail-body">
                <InputField label="Nama Akun" disabled value={customerAccountBank.nama_rek || '-'} />
                <InputField label="No Rekening" disabled value={customerAccountBank.no_rek || '-'} />
                <InputField label="Nama Bank" disabled value={customerAccountBank.nama_bank || '-'} />
              </div>
            </div>

            <div className="detail-order__voucher-detail">
              <div className="detail-order__voucher-detail-title">
                <h1>Voucher</h1>
              </div>
              <div className="detail-order__voucher-detail-body">
                <InputField label="Nama Voucher" disabled value={newOrderData?.data?.vouchers?.[0].name || '-'} />
                <InputField label="Kode Voucher" disabled value={newOrderData?.data?.vouchers?.[0].code || '-'} />
                <InputField
                  label="Harga Potongan"
                  disabled
                  value={setCurrency(newOrderData?.data?.vouchers?.[0].value) || '-'}
                />
              </div>
            </div>

            <div className="detail-order-airport__payment-detail">
              <div className="detail-order-airport__payment-detail-title">
                <h1>Pembayaran</h1>
              </div>
              <div className="detail-order-airport__payment-detail-body">
                <InputField label="Status Pembayaran" disabled value={newOrderData?.data?.order_status || '-'} />

                <div
                  className={clsx(
                    'detail-order-airport__payment-detail-body__payment-method',
                    data.order_status === 'CHECKOUT' ? 'confirmation' : 'not-confirmation',
                  )}
                >
                  <InputField
                    label="Metode Pembayaran"
                    disabled
                    // value={newOrderData?.data?.disbursement?.payment?.method ? newOrderData?.data?.disbursement.payment.method : '-'}
                    value={getPaymentMethodString(data?.disbursement)}
                  />
                </div>

                {data.order_status !== 'CHECKOUT' &&
                data.order_status !== 'RECONFIRMATION' &&
                Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                  <>
                    <InputField
                      label="Nama Rekening"
                      disabled
                      value={
                        Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                          ? [...data.disbursement_reconfirmation].pop()?.sender_name
                          : newOrderData?.data?.disbursement?.sender_name || '-'
                      }
                    />

                    <InputField
                      label="Nama Bank"
                      disabled
                      value={
                        Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                          ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                          : newOrderData?.data?.disbursement?.sender_bank_name || '-'
                      }
                    />

                    <div className="detail-order__payment-detail-body__preview-image">
                      <InputField
                        label="Foto Bukti Transfer"
                        disabled
                        value={getFileName(
                          Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                            ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                            : newOrderData?.data?.disbursement?.disbursement_confirmation_image,
                        )}
                        className="preview-image-input"
                      />

                      <Button
                        bgColor="#D9D9D9"
                        textColor="#000000"
                        className="preview-image-btn"
                        onClick={() =>
                          imagePreviewHandler(
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                              : newOrderData?.data?.disbursement?.disbursement_confirmation_image,
                          )
                        }
                      >
                        Lihat
                      </Button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </>
        )}

        <div className="detail-order__transaction-buttons">
          <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
            Kembali
          </Button>

          {newOrderData?.status === 'pending' ? (
            <Button className="button" width={208} size="sm" onClick={() => confirmHandler('processed')}>
              Process
            </Button>
          ) : newOrderData?.status === 'processed' ? (
            <>
              <Button
                size="sm"
                className="button"
                width={208}
                variant="danger"
                onClick={() => confirmHandler('rejected')}
              >
                Tolak
              </Button>
              <Button className="button" width={208} size="sm" onClick={() => confirmHandler('approved')}>
                Konfirmasi
              </Button>
            </>
          ) : null}
        </div>
      </TableWrapper>
    </div>
  );
};

export default AirportTransferDetail;
