import { getCookie } from '../../utils/cookies';
import { createSlice } from '@reduxjs/toolkit';
import { login, logout, refreshToken } from './actions';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  errorResponse: {}, // kalo retry setelah error, harus hapus errorMessage dan isError!
  isLoggedIn: false,
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // reducers goes here
    rehydrateToken: (state) => {
      const accessToken = getCookie('grbut');
      const refreshToken = getCookie('grbrt');

      if (!accessToken) return;
      state.data.access_token = accessToken;

      if (!refreshToken) return;
      state.data.refresh_token = refreshToken;

      state.isLoggedIn = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.data = {};
        state.isLoggedIn = false;
        state.isError = false;
        state.errorResponse = {};
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorResponse = action.payload;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.isLoggedIn = true;
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorResponse = {};
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorResponse = action.payload;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.data = {};
      })
      .addCase(refreshToken.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorResponse = {};
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorResponse = action.payload;
        state.isLoggedIn = false;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.data = action.payload;
      });
  },
});

export const { rehydrateToken } = auth.actions;

export default auth.reducer;
