import { createSlice } from '@reduxjs/toolkit';
import { getAllEmployees } from './actions';

const initialState = {
  data: [],
  selected: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const employeesSlice = createSlice({
  initialState,
  name: 'employees',
  reducers: {
    selectRole: (state, action) => {
      state.selected.data = action.payload;
    },
    resetSelectedRole: (state) => {
      state.selected = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEmployees.pending, (state) => {
        state.data = [];
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(getAllEmployees.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllEmployees.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      });
  },
});

export default employeesSlice.reducer;
