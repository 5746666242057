import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getVehicleById = createAsyncThunk('vehicle/getVehicleById', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/vehicles/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAllVehicles = createAsyncThunk('vehicle/getAllVehicles', async (page = 1, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios
      .get(`${BASE_URL}/${API_VERSION}/admin/vehicles?limit=10&page=${page}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createNewVehicle = createAsyncThunk('vehicle/createNewVehicle', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios
      .post(
        `${BASE_URL}/${API_VERSION}/vehicles`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )
      .then((res) => res);

    thunkAPI.dispatch(getAllVehicles());

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const editVehicle = createAsyncThunk('vehicle/editVehicle', async ({ payload, id }, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios
      .put(
        `${BASE_URL}/${API_VERSION}/vehicles/${id}`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )
      .then((res) => res);

    thunkAPI.dispatch(getAllVehicles());

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteVehicle = createAsyncThunk('vehicle/deleteVehicle', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios
      .delete(`${BASE_URL}/${API_VERSION}/vehicles/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => res);
    thunkAPI.dispatch(getAllVehicles());
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// UPLOAD FILE IMAGE
export const uploadImageVehicle = createAsyncThunk('vehicle/uploadImageVehicle', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/vehicles/upload`,
      { file: payload },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data.file;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getVehiclesByFilter = createAsyncThunk('vehicle/getVehiclesByFilter', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const {
    limit = 10,
    page = 0,
    passenger = 4,
    minPrice = 0,
    sort,
    startTrip,
    endTrip,
    locationRental,
    locationIds,
    supportDriver = false,
    customOrder = false,
    name,
    name_sort,
    brand,
    maxPrice = 3000000,
    order_id,
    subServiceId,
  } = payload;

  let mainUrl = `${BASE_URL}/${API_VERSION}/admin/vehicles?limit=${limit}&page=${page}&min_price=${minPrice}&max_price=${maxPrice}&custom_order=${customOrder}&support_driver=${supportDriver}`;

  if (passenger) {
    mainUrl = mainUrl + `&passenger=${passenger}`;
  }

  if (sort) {
    mainUrl = mainUrl + `&price_sort=${sort}`;
  }

  if (startTrip && endTrip) {
    mainUrl = mainUrl + `&start_trip=${startTrip}&end_trip=${endTrip}`;
  }

  if (locationRental) {
    if (Array.isArray(locationRental)) {
      const newLocations = locationRental.map((item) => `&location_id=${item}`).join('');
      mainUrl = mainUrl + `${newLocations}`;
    } else {
      mainUrl = mainUrl + `&location_id=${locationRental}`;
    }
  }

  if (name) {
    mainUrl = mainUrl + `&name=${name}`;
  }

  if (name_sort) {
    mainUrl = mainUrl + `&name_sort=${name_sort}`;
  }

  if (brand && brand.length !== 0) {
    if (Array.isArray(brand)) {
      const newBrand = brand.map((item) => `&brand=${item}`).join('');
      mainUrl = mainUrl + `${newBrand}`;
    } else {
      mainUrl = mainUrl + `&brand=${brand}`;
    }
  }

  if (locationIds && locationIds.length !== 0) {
    const ids = locationIds.map((id) => `location_id=${id}`).join('&');
    mainUrl = mainUrl + `&${ids}`;
  }

  if (order_id) {
    mainUrl = mainUrl + `&order_id=${order_id}`;
  }

  if (subServiceId) {
    mainUrl = mainUrl + `&sub_service_id=${subServiceId}`;
  }

  try {
    const response = await axios
      .get(mainUrl, { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// GET VEHIVLE AIRPORT PACKAGE
export const getAllVehicleAirportPackage = createAsyncThunk(
  'vehicleAirportPackage/getAllVehicleAirportPackage',
  async (params, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const {
      page = 1,
      limit = 10,
      minPrice,
      maxPrice,
      pickupTrip,
      priceSort = 'asc',
      name,
      // locationId,
      // shuttleId,
      id,
      brand,
      pickUpLocationId,
      dropOffLocationId,
    } = params;

    let mainUrl = `${BASE_URL}/${API_VERSION}/vehicle-airport-packages?limit=${limit}&page=${page}&support_driver=${true}`;

    if (minPrice) {
      mainUrl = mainUrl + `&min_price=${minPrice}`;
    }

    if (maxPrice) {
      mainUrl = mainUrl + `&max_price=${maxPrice}`;
    }

    if (pickupTrip) {
      mainUrl = mainUrl + `&pickup_trip=${pickupTrip}`;
    }

    if (priceSort) {
      mainUrl = mainUrl + `&price_sort=${priceSort}`;
    }

    if (name) {
      mainUrl = mainUrl + `&name=${name}`;
    }

    // if (locationId) {
    //   mainUrl = mainUrl + `&location_id=${locationId}`;
    // }

    // if (shuttleId) {
    //   mainUrl = mainUrl + `&shuttle_id=${shuttleId}`;
    // }

    if (pickUpLocationId) {
      mainUrl = mainUrl + `&pickup_location_id=${pickUpLocationId}`;
    }

    if (dropOffLocationId) {
      mainUrl = mainUrl + `&dropoff_location_id=${dropOffLocationId}`;
    }

    if (brand && brand.length !== 0) {
      if (Array.isArray(brand)) {
        const newBrand = brand.map((item) => `&brand=${item}`).join('');
        mainUrl = mainUrl + `${newBrand}`;
      } else {
        mainUrl = mainUrl + `&brand=${brand}`;
      }
    }

    if (id && id.length !== 0) {
      if (Array.isArray(id)) {
        const newId = id.map((item) => `&id=${item}`).join('');
        mainUrl = mainUrl + `${newId}`;
      } else {
        mainUrl = mainUrl + `&id=${id}`;
      }
    }

    try {
      const response = await axios
        .get(mainUrl, { headers: { Authorization: `Bearer ${accessToken}` } })
        .then((res) => res);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// GET VEHICLE BASE ON AIRPORT PACKAGE
export const getVehicleByIdAirportPackage = createAsyncThunk(
  'vehicleAirportPackage/getVehicleByIdAirportPackage',
  async (params, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { id, pickupTrip, pickUpLocationId, dropOffLocationId, order_id } = params;

    let mainUrl = `${BASE_URL}/${API_VERSION}/vehicle-airport-packages/${id}/vehicles?pickup_trip=${pickupTrip}`;

    if (pickUpLocationId) {
      mainUrl = mainUrl + `&pickup_location_id=${pickUpLocationId}`;
    }

    if (dropOffLocationId) {
      mainUrl = mainUrl + `&dropoff_location_id=${dropOffLocationId}`;
    }

    if (order_id) {
      mainUrl = mainUrl + `&order_id=${order_id}`;
    }

    try {
      const response = await axios
        .get(mainUrl, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => res);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getAFewVehicles = createAsyncThunk('vehicle/getAFewVehicles', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { ids, page = 1, limit = 10 } = payload;

  let mainUrl = `${BASE_URL}/${API_VERSION}/vehicles?limit=${limit}&page=${page}`;

  for (let id of ids) {
    mainUrl += `&id=${id}`;
  }

  try {
    const response = await axios
      .get(mainUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// GET ELIGIBLE VEHICLE
export const getEligibleVehicle = createAsyncThunk(
  'vehicle/getEligibleVehicle',
  async (params = { startDate: '', endDate: '' }, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    let url = `${BASE_URL}/${API_VERSION}/vehicle-eligible`;

    const { startDate, endDate } = params;

    if (startDate) {
      url = url + `?start_date=${startDate}`;
    }

    if (endDate) {
      url = url + `&end_date=${endDate}`;
    }

    try {
      const response = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => res);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getVehicleDetailByFilter = createAsyncThunk(
  'vehicle/getVehicleDetailByFilter',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;
    const { id, supportDriver = false, startTrip, endTrip } = payload;

    let mainUrl = `${BASE_URL}/${API_VERSION}/vehicles/${id}?support_driver=${supportDriver}`;

    if (startTrip && endTrip) {
      mainUrl = mainUrl + `&start_trip=${startTrip}&end_trip=${endTrip}`;
    }

    try {
      const response = await axios.get(mainUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
