import { useAppContext } from 'components/Context/AppContext';
import React, { useEffect, useRef, useState } from 'react';
import { Button, InputField, SelectFieldVehicle, TableWrapper } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';

import { getVehicleById, getVehiclesByFilter } from 'features/vehicle/action';

import { getAllRentalLocation } from 'features/rental-location/actions';
import { createOvertime, getAllOvertimeRules, getDetailOvertime, updateOvertime } from 'features/overtime/actions';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as OvertimeIcon } from 'icons/overtime-icon.svg';
import { ReactComponent as ChevronDown } from 'icons/chevron-down.svg';
import clsx from 'clsx';
import { checkPermission } from 'utils/functionality';

const initialData = {
  overtime_name: '',
  location: [],
  service: [],
  vehicle: [],
  location_rule: [],
  service_rule: [],
  vehicle_rule: [],
};

const CreateOvertimePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const locationRef = useRef(null);
  const serviceRef = useRef(null);
  const locationRuleRef = useRef(null);
  const serviceRuleRef = useRef(null);
  const vehicleRuleRef = useRef(null);

  const [searchParams] = useSearchParams();
  const location = useLocation();

  const overtimeId = searchParams.get('id');
  const isEditPage = location.pathname === '/overtime/edit-overtime';

  const { showToast, setShowSpinner, setShowConfirmation } = useAppContext();

  const { data: vehiclesData } = useSelector((state) => state.vehicle);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const { overtimeRules } = useSelector((state) => state.overtime);
  const { selectedOvertime } = useSelector((state) => state.overtime);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  const [showLocationOptions, setShowLocationOptions] = useState(false);
  const [showServiceOptions, setShowServiceOptions] = useState(false);
  const [showLocationRuleOptions, setShowLocationRuleOptions] = useState(false);
  const [showServiceRuleOptions, setShowServiceRuleOptions] = useState(false);
  const [showVehicleRuleOptions, setShowVehicleRuleOptions] = useState(false);

  const [overtimeData, setOvertimeData] = useState(initialData);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [selectedLocationRule, setSelectedLocationRule] = useState([]);
  const [selectedServiceRule, setSelectedServiceRule] = useState([]);
  const [selectedVehicleRule, setSelectedVehicleRule] = useState([]);
  const [detailVehicles, setDetailVehicles] = useState([]);

  const [selectedCar, setSelectedCar] = useState([]);

  useEffect(() => {
    dispatch(getAllRentalLocation());
    dispatch(getAllOvertimeRules());
  }, []);

  useEffect(() => {
    if (!isEditPage) return;

    dispatch(getDetailOvertime(overtimeId));
  }, [isEditPage]);

  const handleSelectedCar = (cars) => {
    let choosedVehiclesId = [];
    for (let choosedData of cars) {
      for (let chooseCar of choosedData.cars) {
        choosedVehiclesId.push(chooseCar.id);
      }
    }

    setOvertimeData({ ...overtimeData, vehicle: choosedVehiclesId });
    setSelectedCar(cars);
  };

  const intersectionAction = () => {
    // if last page then just return nothing
    if (vehiclesData.pagination.page >= vehiclesData.pagination.last_page) return;

    const isWithDriver = selectedService.length === 1 && selectedService[0] === 'with_driver';
    const isWithAndWithoutDriver = selectedService.length === 2;

    if (isWithAndWithoutDriver) {
      dispatch(getVehiclesByFilter({ locationIds: selectedLocations, page: vehiclesData.pagination.page + 1 }));
    } else {
      dispatch(
        getVehiclesByFilter({
          supportDriver: isWithDriver ? true : false,
          locationIds: selectedLocations,
          page: vehiclesData.pagination.page + 1,
        }),
      );
    }
  };

  const handleSubmit = async () => {
    const payload = {
      name: overtimeData.overtime_name,
      overtime_location: { location_ids: selectedLocations, rule_ids: selectedLocationRule },
      overtime_service: { services: selectedService, rule_ids: selectedServiceRule },
      overtime_vehicle: { vehicle_ids: overtimeData.vehicle, rule_ids: selectedVehicleRule },
    };

    // validation
    const validName = overtimeData.overtime_name.trim();
    const validLocation = selectedLocations.length !== 0;
    const validLocationRule = selectedLocationRule.length !== 0;
    const validService = selectedService.length !== 0;
    const validServiceRule = selectedServiceRule.length !== 0;
    const validVehicle = overtimeData?.vehicle.length !== 0;
    const validVehicleRule = selectedVehicleRule.length !== 0;

    if (!validName) {
      return showToast({ type: 'error', message: 'Isi nama overtime terlebih dahulu' });
    } else if (!validLocation) {
      return showToast({ type: 'error', message: 'Lokasi tidak boleh kosong' });
    } else if (validLocation && !validLocationRule) {
      return showToast({ type: 'error', message: 'Aturan Overtime untuk lokasi tidak boleh kosong' });
    } else if (validService && !validServiceRule) {
      return showToast({ type: 'error', message: 'Aturan Overtime untuk tipe sewa tidak boleh kosong' });
    } else if (validVehicle && !validVehicleRule) {
      return showToast({ type: 'error', message: 'Aturan Overtime untuk jenis mobil tidak boleh kosong' });
    }

    if (!isEditPage) {
      setShowConfirmation({
        message: 'Apakah anda yakin ingin menambah overtime?',
        show: true,
        onClick: async () => {
          try {
            setShowSpinner(true);
            await dispatch(createOvertime(payload)).unwrap();
            showToast({ type: 'success', message: 'Berhasil Menambah Overtime' });
            navigate('/overtime');
            return;
          } catch (error) {
            showToast({ type: 'error', message: 'Gagal Menambah Overtime' });
          } finally {
            setShowSpinner(false);
          }
        },
      });
    } else {
      setShowConfirmation({
        message: 'Apakah anda yakin ingin mengubah overtime?',
        show: true,
        onClick: async () => {
          try {
            setShowSpinner(true);
            await dispatch(updateOvertime({ id: overtimeId, payload })).unwrap();
            showToast({ type: 'success', message: 'Berhasil Mengubah Overtime' });
            navigate('/overtime');
            return;
          } catch (error) {
            showToast({ type: 'error', message: 'Gagal Mengubah Overtime' });
          } finally {
            setShowSpinner(false);
          }
        },
      });
    }
  };

  const handleLocationChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedLocations((prevState) =>
      prevState.includes(value) ? prevState.filter((option) => option !== value) : [...prevState, value],
    );
  };

  const handleServiceChange = (event) => {
    const value = event.target.value;
    setSelectedService((prevState) =>
      prevState.includes(value) ? prevState.filter((option) => option !== value) : [...prevState, value],
    );
  };

  const handleLocationRuleChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedLocationRule((prevState) =>
      prevState.includes(value) ? prevState.filter((option) => option !== value) : [...prevState, value],
    );
  };

  const handleServiceRuleChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedServiceRule((prevState) =>
      prevState.includes(value) ? prevState.filter((option) => option !== value) : [...prevState, value],
    );
  };

  const handleVehicleRuleChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedVehicleRule((prevState) =>
      prevState.includes(value) ? prevState.filter((option) => option !== value) : [...prevState, value],
    );
  };

  const renderSelectedLocation = () => {
    return rentalLocationData
      .filter((option) => selectedLocations.includes(option.id))
      .map((option) => option.name)
      .join(', ');
  };

  const renderSelectedService = () => {
    return serviceOptions
      .filter((option) => selectedService.includes(option.id))
      .map((option) => option.label)
      .join(', ');
  };

  const renderSelectedLocationRule = () => {
    return overtimeRules.data
      ? overtimeRules.data
          .filter((option) => selectedLocationRule.includes(option.id))
          .map((option) => option.name)
          .join(', ')
      : '';
  };

  const renderSelectedServiceRule = () => {
    return overtimeRules.data
      ? overtimeRules.data
          .filter((option) => selectedServiceRule.includes(option.id))
          .map((option) => option.name)
          .join(', ')
      : '';
  };

  const renderSelectedVehicleRule = () => {
    return overtimeRules?.data
      ? overtimeRules.data
          .filter((option) => selectedVehicleRule.includes(option.id))
          .map((option) => option.name)
          .join(', ')
      : '';
  };

  useEffect(() => {
    if (!selectedService.length) return;

    setSelectedCar([]);

    const isWithDriver = selectedService.length === 1 && selectedService[0] === 'with_driver';
    const isWithAndWithoutDriver = selectedService.length === 2;

    if (isWithAndWithoutDriver) {
      dispatch(getVehiclesByFilter({ locationIds: selectedLocations, page: 1 }));
    } else {
      dispatch(
        getVehiclesByFilter({ supportDriver: isWithDriver ? true : false, locationIds: selectedLocations, page: 1 }),
      );
    }
  }, [selectedService, selectedLocations]);

  const handleClickOutside = (event) => {
    if (locationRef.current && !locationRef.current.contains(event.target)) {
      setShowLocationOptions(false);
    }

    if (serviceRef.current && !serviceRef.current.contains(event.target)) {
      setShowServiceOptions(false);
    }

    if (locationRuleRef.current && !locationRuleRef.current.contains(event.target)) {
      setShowLocationRuleOptions(false);
    }

    if (serviceRuleRef.current && !serviceRuleRef.current.contains(event.target)) {
      setShowServiceRuleOptions(false);
    }

    if (vehicleRuleRef.current && !vehicleRuleRef.current.contains(event.target)) {
      setShowVehicleRuleOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(selectedOvertime).length === 0) return;
    if (!isEditPage) return;

    const locationRule = selectedOvertime?.overtime_location.overtime_rules?.map((rule) => rule.id);
    const serviceRule = selectedOvertime?.overtime_service.overtime_rules?.map((rule) => rule.id);
    const vehicleRule = selectedOvertime?.overtime_vehicle.overtime_rules?.map((rule) => rule.id);

    setOvertimeData({
      overtime_name: selectedOvertime.name,
      vehicle: selectedOvertime.overtime_vehicle.vehicle_ids ?? [],
    });
    setSelectedLocations(selectedOvertime.overtime_location.location_ids || []);
    setSelectedLocationRule(locationRule ?? []);
    setSelectedServiceRule(serviceRule ?? []);
    setSelectedVehicleRule(vehicleRule ?? []);
    setSelectedService(selectedOvertime.overtime_service.services ?? []);
  }, [selectedOvertime, isEditPage]);

  useEffect(() => {
    if (Object.keys(selectedOvertime).length === 0) return;
    if (!isEditPage) return;

    const fetchVehicles = async () => {
      const promises = selectedOvertime?.overtime_vehicle.vehicle_ids?.map((vehicleId) =>
        dispatch(getVehicleById(vehicleId)).unwrap(),
      );

      if (promises) {
        const results = await Promise.allSettled(promises);
        const fulfilledResults = results
          .filter((result) => result.status === 'fulfilled')
          .map((vehicle) => vehicle.value);

        setDetailVehicles(fulfilledResults);
        return;
      }

      setDetailVehicles([]);
    };

    fetchVehicles();
  }, [selectedOvertime, isEditPage]);

  useEffect(() => {
    if (detailVehicles.length === 0) return;
    if (!isEditPage) return;

    // loop the vehicle schedule price to set selected car
    let newSelectedCar = [];

    for (let vehicle of detailVehicles) {
      if (newSelectedCar.length === 0) {
        newSelectedCar.push({ cars: [{ ...vehicle, checked: true }], total: 1 });
      } else {
        const existingVehicle = newSelectedCar.find((item) => item.cars[0].name === vehicle.name);
        if (existingVehicle) {
          const copyNewSelectedCar = [...newSelectedCar];
          copyNewSelectedCar[copyNewSelectedCar.indexOf(existingVehicle)] = {
            cars: [...existingVehicle.cars, { ...vehicle, checked: true }],
            total: existingVehicle.total + 1,
          };
          newSelectedCar = copyNewSelectedCar;
        } else {
          newSelectedCar = [...newSelectedCar, { cars: [{ ...vehicle, checked: true }], total: 1 }];
        }
      }
    }

    setSelectedCar(newSelectedCar);
  }, [detailVehicles, isEditPage]);

  const searchHandler = (value) => {
    const isWithDriver = selectedService.length === 1 && selectedService[0] === 'with_driver';
    const isWithAndWithoutDriver = selectedService.length === 2;

    setTimeout(() => {
      // if (isWithDriver) {
      //   dispatch(
      //     getVehiclesByFilter({
      //       locationIds: selectedLocations,
      //       support_driver: true,
      //       name: value,
      //       page: value === '' ? 1 : 0,
      //     }),
      //   );
      // }

      if (isWithAndWithoutDriver) {
        dispatch(getVehiclesByFilter({ locationIds: selectedLocations, page: 1, name: value }));
      } else {
        dispatch(
          getVehiclesByFilter({
            supportDriver: isWithDriver ? true : false,
            locationIds: selectedLocations,
            page: 1,
            name: value,
          }),
        );
      }
    }, 1000);
  };

  return (
    <>
      <div className="overtime-rules__header" onClick={() => navigate(-1)}>
        <LeftArrow />
        <p>Kembali</p>
      </div>

      <TableWrapper
        icon={<OvertimeIcon fill="#009EF7" width="25px" height="25px" />}
        title="Overtime"
        style={{ position: 'relative' }}
      >
        <div className="create-overtime">
          <div className="create-overtime__input-overtime">
            <InputField
              className=""
              label="Nama Overtime"
              placeholder="Tulis Nama Overtime"
              value={overtimeData.overtime_name}
              onChange={(e) => setOvertimeData({ ...overtimeData, overtime_name: e.target.value })}
              id="nama-overtime"
              // name='username'
            />
          </div>

          {/* LOCATIONS */}
          <div className="create-overtime__group">
            <div className="create-overtime__select-container" ref={locationRef}>
              <label
                className="create-overtime__select-label"
                htmlFor="select-box"
                onClick={() => setShowLocationOptions((prev) => !prev)}
              >
                Lokasi:
              </label>
              <div
                id="select-box"
                className="create-overtime__select-box"
                onClick={() => setShowLocationOptions((prev) => !prev)}
              >
                {renderSelectedLocation() || 'Pilih Lokasi'}
                <ChevronDown
                  className={!showLocationOptions ? 'create-overtime__arrow' : 'create-overtime__arrow arrow-down'}
                />
              </div>

              {showLocationOptions && (
                <div className={`create-overtime__options-container ${showLocationOptions ? 'show' : ''}`}>
                  {rentalLocationData.map((location) => (
                    <div className="option" key={location.id}>
                      <label htmlFor={`option-${location.id}`}>{location.name}</label>
                      <input
                        type="checkbox"
                        id={`option-${location.id}`}
                        value={location.id}
                        checked={selectedLocations.includes(location.id)}
                        onChange={handleLocationChange}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="create-overtime__select-container" ref={locationRuleRef}>
              <div
                className={clsx('create-overtime__select-box', selectedLocations.length === 0 && 'disabled')}
                onClick={() => setShowLocationRuleOptions((prev) => !prev)}
              >
                {renderSelectedLocationRule() || 'Pilih Aturan Overtime'}
                <ChevronDown
                  className={!showLocationRuleOptions ? 'create-overtime__arrow' : 'create-overtime__arrow arrow-down'}
                />
              </div>
              {selectedLocations.length > 0 && showLocationRuleOptions && (
                <div className={`create-overtime__options-container ${showLocationRuleOptions ? 'show' : ''}`}>
                  {overtimeRules.data ? (
                    overtimeRules.data.map((rule) => (
                      <div className="option" key={rule.id}>
                        <label htmlFor={`option-${rule.id}`}>{rule.name}</label>
                        <input
                          type="checkbox"
                          id={`option-${rule.id}`}
                          value={rule.id}
                          checked={selectedLocationRule.includes(rule.id)}
                          onChange={handleLocationRuleChange}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="option option-empty">data tidak ditemukan!</div>
                  )}
                </div>
              )}
            </div>
            {/* END OF LOCATIONS */}

            {/* RENT TYPE */}
            <div className="create-overtime__select-container" ref={serviceRef}>
              <label
                className="create-overtime__select-label"
                htmlFor="select-box"
                onClick={() => setShowServiceOptions((prev) => !prev)}
              >
                Tipe Sewa:
              </label>
              <div
                id="select-box"
                className={clsx('create-overtime__select-box', selectedLocations.length === 0 && 'disable')}
                onClick={() => setShowServiceOptions((prev) => !prev)}
              >
                {renderSelectedService() || 'Pilih Tipe Sewa'}
                <ChevronDown
                  className={!showServiceOptions ? 'create-overtime__arrow' : 'create-overtime__arrow arrow-down'}
                />
              </div>

              {selectedLocations.length > 0 && showServiceOptions && (
                <div className={`create-overtime__options-container ${showServiceOptions ? 'show' : ''}`}>
                  {serviceOptions.map((service) => (
                    <div className="option" key={service.id}>
                      <label htmlFor={`option-${service.id}`}>{service.label}</label>
                      <input
                        type="checkbox"
                        id={`option-${service.id}`}
                        value={service.id}
                        checked={selectedService.includes(service.id)}
                        onChange={handleServiceChange}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="create-overtime__select-container" ref={serviceRuleRef}>
              <div
                className={clsx('create-overtime__select-box', selectedService.length === 0 && 'disabled')}
                onClick={() => setShowServiceRuleOptions((prev) => !prev)}
              >
                {renderSelectedServiceRule() || 'Pilih Aturan Overtime'}
                <ChevronDown
                  className={!showServiceRuleOptions ? 'create-overtime__arrow' : 'create-overtime__arrow arrow-down'}
                />
              </div>
              {selectedService.length > 0 && showServiceRuleOptions && (
                <div className={`create-overtime__options-container ${showServiceRuleOptions ? 'show' : ''}`}>
                  {overtimeRules.data ? (
                    overtimeRules.data.map((rule) => (
                      <div className="option" key={rule.id}>
                        <label htmlFor={`option-${rule.id}`}>{rule.name}</label>
                        <input
                          type="checkbox"
                          id={`option-${rule.id}`}
                          value={rule.id}
                          checked={selectedServiceRule.includes(rule.id)}
                          onChange={handleServiceRuleChange}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="option option-empty">data tidak ditemukan!</div>
                  )}
                </div>
              )}
            </div>
            {/* END OF RENT TYPE */}

            {/* VEHICLE */}
            <SelectFieldVehicle
              label="Jenis Mobil"
              htmlFor="jenis-mobil"
              placeholder="Cari Mobil.."
              data={vehiclesData}
              selectedCar={selectedCar}
              onSelectCar={handleSelectedCar}
              intersectionAction={intersectionAction}
              disable={!selectedService.length}
              onSearchAction={searchHandler}
            />

            <div className="create-overtime__select-container" ref={vehicleRuleRef}>
              <div
                className={clsx(
                  'create-overtime__select-box',
                  overtimeData.vehicle && overtimeData?.vehicle?.length === 0 && 'disabled',
                )}
                onClick={() => setShowVehicleRuleOptions((prev) => !prev)}
              >
                {renderSelectedVehicleRule() || 'Pilih Aturan Overtime'}
                <ChevronDown
                  className={!showVehicleRuleOptions ? 'create-overtime__arrow' : 'create-overtime__arrow arrow-down'}
                />
              </div>
              {overtimeData.vehicle && overtimeData.vehicle.length > 0 && showVehicleRuleOptions && (
                // {/* {showVehicleRuleOptions && ( */}
                <div className={`create-overtime__options-container ${showVehicleRuleOptions ? 'show' : ''}`}>
                  {overtimeRules.data ? (
                    overtimeRules.data.map((rule) => (
                      <div className="option" key={rule.id}>
                        <label htmlFor={`option-${rule.id}`}>{rule.name}</label>
                        <input
                          type="checkbox"
                          id={`option-${rule.id}`}
                          value={rule.id}
                          checked={selectedVehicleRule.includes(rule.id)}
                          onChange={handleVehicleRuleChange}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="option option-empty">data tidak ditemukan!</div>
                  )}
                </div>
              )}
            </div>
            {/* END OF VEHICLE */}
          </div>

          <div className="create-overtime__btn-group">
            <Button type="button" onClick={() => navigate(-1)} width="192px" height="39px" variant="outline">
              Kembali
            </Button>
            {(!isEditPage || checkPermission(offCanvasMenu, currentMenu, 'update')) && (
              <Button type="button" width="192px" height="39px" variant="primary" onClick={handleSubmit}>
                Simpan
              </Button>
            )}
          </div>
        </div>

        {/* <Button
          type="button"
          onClick={handleAddGarage}
          width={267}
          style={{ marginLeft: 'auto' }}
          className="create-overtime__btn"
        >
          {Object.keys(garageDetail).length === 0 ? 'Tambah' : 'Simpan'}
        </Button> */}
      </TableWrapper>
    </>
  );
};

export default CreateOvertimePage;

const serviceOptions = [
  {
    id: 'with_driver',
    label: 'Dengan Supir',
  },
  {
    id: 'without_driver',
    label: 'Tanpa Supir',
  },
];
