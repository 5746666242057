import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

// GET ALL LANGUAGE
export const getAllLanguage = createAsyncThunk('language/getAllLanguage', async (page = 1, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/languages?page=${page}&limit=10`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// GET LANGUAGE BY ID
export const getLanguageById = createAsyncThunk('language/getLanguageById', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/languages/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// CREATE LANGUAGE
export const createLanguage = createAsyncThunk('language/createLanguage', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/languages`,
      { ...payload },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    thunkAPI.dispatch(getAllLanguage());

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// UPDATE LANGUAGE
export const updateLanguage = createAsyncThunk('language/updateLanguage', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { payloadData, languageId } = payload;

  try {
    const response = await axios.put(
      `${BASE_URL}/${API_VERSION}/languages/${languageId}`,
      { ...payloadData },
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    thunkAPI.dispatch(getAllLanguage());

    const data = await response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// DELETE LANGUAGE
export const deleteLanguage = createAsyncThunk('language/deleteLanguage', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${BASE_URL}/${API_VERSION}/languages/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    thunkAPI.dispatch(getAllLanguage());

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
