import React from 'react';
import { useAppContext } from 'components/Context/AppContext';
import { useSelector } from 'react-redux';
import { getDetailOrder } from 'features/orders/detailOrderSlice';
import { getCustomerData } from 'features/user/slice';
import { Button } from 'components/Global';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const IdentityImageSection = ({ setSection }) => {
  const { showReviewIdentityModal, setShowReviewIdentityModal, setShowPreviewImageIdentity } = useAppContext();

  const orderData = useSelector(getDetailOrder);
  const customer = useSelector(getCustomerData);

  const identity = showReviewIdentityModal.type.toUpperCase();

  let identityImage = '';
  if (orderData?.is_admin_creation) {
    identityImage = orderData?.order_detail?.identity[`${showReviewIdentityModal.type}`];
  } else {
    identityImage = IMAGE_BASE_URL + customer?.PersonalInfos[`${showReviewIdentityModal.type}`];
  }

  return (
    <div className="identity-image-section">
      <div className="identity-image-section__image-wrapper">
        <img
          src={identityImage}
          alt={identity + ' image'}
          onClick={() => setShowPreviewImageIdentity({ image: identityImage, show: true })}
        />
      </div>

      <div className="identity-image-section__requirement">
        <h3>Syarat {identity} tervalidasi</h3>
        <p>{`Foto ${identity} jelas`}</p>
        <p>{`${identity} difoto dengan pencahayaan bagus`}</p>
        <p>{`Foto ${identity} sesuai dengan detail penyewa`}</p>
      </div>

      <div className="identity-image-section__button-action">
        <Button
          variant="outline"
          onClick={() => setShowReviewIdentityModal({ ...showReviewIdentityModal, show: false })}
        >
          Back
        </Button>
        <Button
          onClick={() => setSection((prevState) => prevState + 1)}
          disabled={
            (customer?.PersonalInfos?.need_review_ktp && showReviewIdentityModal.type === 'ktp') ||
            (customer?.PersonalInfos?.need_review_sim && showReviewIdentityModal.type === 'sim')
          }
        >
          Request Upload ulang
        </Button>
      </div>
    </div>
  );
};

export default IdentityImageSection;
