import React from 'react';
import { decimalToBinary } from '..';

const RoleAssignmentRow = ({ item, setData, setSelected }) => {
  const onChange = (e) => {
    const { checked, value, name } = e.target;
    const id = +e.target.parentElement.dataset['key'];
    const permission_name = name.substring(name.indexOf('-') + 1);

    setData((prev) =>
      prev.map((item) =>
        item.id === id
          ? {
              ...item,
              access: checked ? item.access + parseInt(value) : item.access - parseInt(value),
            }
          : item,
      ),
    );

    setSelected({ id, permission_name });
  };

  return (
    <tr>
      <td>
        <div className="tr-menu">
          <p>{item.name}</p>
        </div>
      </td>

      <td className="td-center br-left" data-key={item.id}>
        <input
          type="checkbox"
          id={`create-${item.name}`}
          name={`create-${item.name}`}
          onChange={onChange}
          value={2}
          checked={+decimalToBinary(item.access)[2]}
        />
      </td>
      <td className="td-center br-left" data-key={item.id}>
        <input
          type="checkbox"
          id={`update-${item.name}`}
          name={`update-${item.name}`}
          onChange={onChange}
          value={4}
          checked={+decimalToBinary(item.access)[1]}
        />
      </td>
      <td className="td-center br-left" data-key={item.id}>
        <input
          type="checkbox"
          id={`delete-${item.name}`}
          name={`delete-${item.name}`}
          onChange={onChange}
          value={8}
          checked={+decimalToBinary(item.access)[0]}
        />
      </td>
      <td className="td-center br-left" data-key={item.id}>
        <input
          type="checkbox"
          id={`read-${item.name}`}
          name={`read-${item.name}`}
          onChange={onChange}
          value={1}
          checked={+decimalToBinary(item.access)[3]}
        />
      </td>
    </tr>
  );
};

export default RoleAssignmentRow;
