import { createSlice } from '@reduxjs/toolkit';
import { getAllRevenue, getAllRevenueTotal, getAllRevenueCount } from './actions';

const initialState = {
  data: {},
  total: null,
  count: null,
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const allRevenue = createSlice({
  name: 'allRevenue',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRevenue.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
        state.total = null;
        state.count = null;
      })
      .addCase(getAllRevenue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllRevenue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.data = action.payload;
        state.total = action.payload.total;
        state.count = action.payload.count;
      })
      .addCase(getAllRevenueTotal.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.total = null;
      })
      .addCase(getAllRevenueTotal.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllRevenueTotal.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.total = action.payload;
      })
      .addCase(getAllRevenueCount.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.count = null;
      })
      .addCase(getAllRevenueCount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllRevenueCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.count = action.payload;
      });
  },
});

export default allRevenue.reducer;
