import React from 'react';
import { useEffect } from 'react';

const UnderDevelopment = ({ children }) => {
  // useEffect(() => {
  //   // document.body.style.overflow = 'hidden';
  //   const targetElement = document.querySelector('development-dialog');
  //   console.log('asu', targetElement);
  //   if (targetElement) {
  //     targetElement.style.overflow = 'hidden';
  //     // return () => (document.body.style.overflow = 'unset');
  //     return () => (targetElement.style.overflow = 'unset');
  //   }
  // }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = 'unset');
  }, []);
  return (
    <>
      <div className="development-dialog">
        <div className="development-dialog__panel">
          <div className="development-dialog__content">
            <div className="development-dialog__image">
              <img src="/assets/image/Isolation_Mode.png" alt="" />
            </div>
            <p className="development-dialog__description">
              Fitur ini masih dalam pengembangan, untuk saat ini belum bisa digunakan.
            </p>
          </div>
        </div>
      </div>
      {children}
    </>
  );
};

export default UnderDevelopment;
