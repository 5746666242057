import { createSlice } from '@reduxjs/toolkit';
import { assignCourier, getDetailTaskCourier } from './actions';

const initialState = {
  data: {},
  selectedCourierTasks: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const couriersTask = createSlice({
  name: 'couriersTask',
  initialState,
  reducers: {
    // reducers goes here
    resetSelectedCourierTask: (state) => {
      state.selectedCourierTasks = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(assignCourier.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(assignCourier.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(assignCourier.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = {};
        state.selectedCourierTasks = action.payload;
      })
      .addCase(getDetailTaskCourier.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getDetailTaskCourier.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDetailTaskCourier.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = {};
        state.selectedCourierTasks = action.payload;
      });
  },
});

export const { resetSelectedCourierTask } = couriersTask.actions;
export default couriersTask.reducer;
