import clsx from 'clsx';
import ClickOutside from 'components/Global/ClickOutside';
import React, { useEffect, useState } from 'react';

const chevronDown = 'assets/icon/chevron-down.svg';

const BankInputDropdown = ({
  state,
  setState,
  data,
  dropdownHandler, // bakal nerima item argumen
  placeholder,
  label,
  htmlFor,
  className,
  style,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    if (data == undefined) return;

    setBankList(data);
  }, [data]);

  const handleClickOutside = () => {
    setShowDropdown(false);
  };

  const filtering = (input) => {
    return data?.filter((item) => {
      if (input === '') {
        return item;
      } else if (item.name.toLowerCase().includes(input.toLowerCase())) {
        return item;
      }
    });
  };
  const handleChangeBank = (e) => {
    setBankList(filtering(e.target.value));

    setState((prev) => ({ ...prev, bank: e.target.value }));
  };

  const handleSelectBank = (item) => {
    setShowDropdown(false);
    dropdownHandler(item);
    setBankList(data);
  };

  return (
    <div className={clsx('g-input', className)}>
      <div style={style}>
        <label htmlFor={htmlFor} className="input-title">
          {label}
        </label>
        <section className="field-wrapper with-icon">
          <input
            required
            value={state.bank}
            onFocus={() => setShowDropdown(true)}
            onChange={handleChangeBank}
            type="text"
            id={htmlFor}
            placeholder={placeholder}
            className="input-text-field with-icon"
          />

          <img src={chevronDown} className="icon" alt="expand" />
        </section>
      </div>

      {showDropdown ? (
        <ClickOutside onClickOutside={handleClickOutside}>
          <div className="bank-input-modal">
            <div className="bank-input-modal-wrapper">
              {data.isLoading ? (
                <div className="bank-input-modal-loading">Loading...</div>
              ) : bankList.length > 0 ? (
                bankList.map((item) => (
                  <div key={item.id} className="bank-input-modal-item" onClick={() => handleSelectBank(item)}>
                    <p>{item.name}</p>
                  </div>
                ))
              ) : (
                <div className="bank-input-modal-unavailable">Bank tidak tersedia...</div>
              )}
            </div>
          </div>
        </ClickOutside>
      ) : null}
    </div>
  );
};

export default BankInputDropdown;
