import { createSlice } from '@reduxjs/toolkit';
import { getWithDriverRevenue, getWithDriverRevenueTotal, getWithDriverRevenueCount } from './actions';

const initialState = {
  data: {},
  total: null,
  count: null,
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const withDriverRevenue = createSlice({
  name: 'withDriverRevenue',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWithDriverRevenue.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
        state.total = null;
        state.count = null;
      })
      .addCase(getWithDriverRevenue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getWithDriverRevenue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.data = action.payload;
        state.total = action.payload.total;
        state.count = action.payload.count;
      })
      .addCase(getWithDriverRevenueTotal.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.total = null;
      })
      .addCase(getWithDriverRevenueTotal.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getWithDriverRevenueTotal.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.total = action.payload;
      })
      .addCase(getWithDriverRevenueCount.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.count = null;
      })
      .addCase(getWithDriverRevenueCount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getWithDriverRevenueCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.count = action.payload;
      });
  },
});

export default withDriverRevenue.reducer;
