import { createSlice } from '@reduxjs/toolkit';
import { getAllContactUs, getDetailContactUs } from './action';

const initialState = {
  data: {},
  selected: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const contactUs = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllContactUs.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllContactUs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllContactUs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getDetailContactUs.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.selected = {};
      })
      .addCase(getDetailContactUs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDetailContactUs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selected = action.payload;
      });
  },
});

export default contactUs.reducer;
