import React, { useEffect, useState } from 'react';
import PaginationTable from 'components/Global/Pagination';
import TableWrapper from 'components/Global/TableWrapper';
import { ReactComponent as PeopleIcon } from 'icons/people-filled-icon.svg';
import { Table } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserById, fetchAllUsersInfluencer, fetchCustomerInfo } from 'features/user/actions';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/Global/LoadingSpinner';
import EmptyState from 'components/Global/EmptyState';
import { useAppContext } from 'components/Context/AppContext';
import ButtonAdd from 'components/Global/ButtonAdd';
import { checkPermission } from 'utils/functionality';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Lengkap', value: 'name' },
  { header: 'Kode Referral', value: 'referral' },
  { header: 'Redeem', value: 'total_redeem' },
  { header: 'Total Komisi', value: 'total_commission' },
  { header: 'Status User', value: 'status' },
];

const UserInfluencerListComponent = () => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading } = useSelector((state) => state.userInfluencer);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  const { setShowConfirmation, setShowSpinner, showToast } = useAppContext();

  useEffect(() => {
    dispatch(fetchAllUsersInfluencer(currentPage));
  }, [currentPage]);

  const dispatch = useDispatch();

  const handleDetail = (e) => {
    const userId = e.target.parentElement.parentElement.dataset['key'];
    dispatch(fetchCustomerInfo(userId));
    navigate(`detail/${userId}`);
  };

  const handleDelete = (e) => {
    const userId = e.target.parentElement.parentElement.dataset['key'];

    setShowConfirmation({
      message: 'Apakah anda yakin ingin menghapus User?',
      show: true,
      onClick: async () => {
        try {
          setShowSpinner(true);
          await dispatch(deleteUserById(userId)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Hapus User Influencer' });
          setCurrentPage(1);
        } catch (error) {
          showToast({ type: 'error', message: 'Gagal Hapus User Influencer' });
        } finally {
          dispatch(fetchAllUsersInfluencer(currentPage));
          setShowSpinner(false);
        }
      },
    });
  };

  const handleCopy = async (e) => {
    const userId = e.target.parentElement.parentElement.dataset['key'];

    const filteredUserById = data.users.filter((item) => item.id === userId);
    const domain = window.location.origin;
    const excludeStringBackOffice = domain.replace('backoffices.', '');

    if (!filteredUserById.length) return;

    const copyText = `${excludeStringBackOffice}/referral/${filteredUserById[0].referral}`;

    try {
      await navigator.clipboard.writeText(copyText);
      showToast({ type: 'success', message: 'Berhasil Copy Link Referral' });
    } catch (error) {
      showToast({ type: 'error', message: 'Gagal Copy Link Referral' });
    }
  };

  const handleAdd = () => {
    navigate(`/user-influencer/add-item`);
  };

  const usersData = data?.users?.map((item) => ({
    ...item,
    status: item.is_influencer_active ? 'active' : 'inactive',
    total_redeem: item?.redeem ? item.redeem : 0,
    total_commission: item?.commission ? item.commission : 0,
  }));

  return (
    <div className="user">
      <ButtonAdd onClick={handleAdd} label="Tambah Influencer" />

      <TableWrapper title="Tabel User Influencer" icon={<PeopleIcon fill="#FF9900" width="25px" height="25px" />}>
        {isLoading ? (
          <LoadingSpinner />
        ) : usersData?.length === 0 ? (
          <EmptyState />
        ) : (
          <>
            <Table
              column={column}
              data={usersData}
              actionBtn
              currentPage={currentPage}
              onDetail={handleDetail}
              onDel={checkPermission(offCanvasMenu, currentMenu, 'delete') ? handleDelete : false}
              onCopy={handleCopy}
            />
            <PaginationTable
              currentPage={currentPage}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              totalCount={data?.pagination?.total_data}
            />
          </>
        )}
      </TableWrapper>
    </div>
  );
};

export default UserInfluencerListComponent;
