import React, { useEffect, useState } from 'react';
import { TableWrapper, Table, PaginationTable } from 'components/Global';
import { ReactComponent as ZoneFilledIcon } from 'icons/zone-filled-icon.svg';
import ZoneDetail from './zone-detail';
import { useDispatch, useSelector } from 'react-redux';
import { getAllZoneAdmin } from 'features/zone/actions';
import { getAllRentalLocation } from 'features/rental-location/actions';
import { useSearchParams } from 'react-router-dom';
import { useAppContext } from 'components/Context/AppContext';
import ButtonAdd from 'components/Global/ButtonAdd';
import { checkPermission } from 'utils/functionality';

const ZoneComponent = () => {
  const { showToast, setShowConfirmation, setShowSpinner } = useAppContext();
  const dispatch = useDispatch();
  const [searhParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const isLoading = useSelector((state) => state.zone.isLoading);
  const zonesData = useSelector((state) => state.zone.dataAdmin);
  const rentalLocation = useSelector((state) => state.rentalLocation.data);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const showDetailPage = searhParams.get('set-active');
  const zoneIdQuery = searhParams.get('id');
  const locationIdQuery = searhParams.get('locationId');

  const column = [
    { header: 'No.', value: 'no' },
    { header: 'Nama Kota / Daerah Sewa', value: 'location_name' },
    { header: 'Nama Zona', value: 'name' },
    { header: 'Nama Daerah', value: 'list_zone' },
  ];

  const getLocationName = (id) => {
    const selected = rentalLocation.find((item) => item.id == id);
    return selected ? selected.name : '-';
  };

  useEffect(() => {
    if (showDetailPage) return;
    dispatch(getAllZoneAdmin(currentPage));
  }, [currentPage, showDetailPage]);

  useEffect(() => {
    dispatch(getAllRentalLocation());
  }, []);

  const handleDetail = (e) => {
    const zoneId = e.target.parentElement.parentElement.dataset['key'];

    setSearchParams({ id: zoneId, 'set-active': 'detail' });
  };

  const handleEdit = (e) => {
    const zoneId = e.target.parentElement.parentElement.dataset['key'];

    setSearchParams({ id: zoneId, 'set-active': 'edit' });
  };

  const handleAdd = () => {
    setSearchParams({ 'set-active': 'create' });
  };

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  const data = zonesData?.zones?.map((item) => ({
    id: item.id,
    name: item.name,
    location_name: getLocationName(item.location_id),
    list_zone: item.list?.length > 0 ? item.list.filter((item) => !item?.includes('Zona')).join(', ') : '',
  }));

  return (
    <div className="zone">
      {showDetailPage ? (
        <ZoneDetail
          state={showDetailPage}
          locationId={locationIdQuery}
          id={zoneIdQuery}
          setShowConfirmation={setShowConfirmation}
          showToast={showToast}
          setShowSpinner={setShowSpinner}
        />
      ) : (
        <div>
          <ButtonAdd onClick={handleAdd} label="Tambah Zona" />

          <TableWrapper icon={<ZoneFilledIcon fill="#009EF7" width="25px" height="25px" />} title="Zona">
            <Table
              column={column}
              actionBtn
              onDetail={handleDetail}
              onEdit={checkPermission(offCanvasMenu, currentMenu, 'update') ? handleEdit : false}
              data={data}
              currentPage={currentPage}
            />
            <PaginationTable
              currentPage={currentPage}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              totalCount={zonesData?.pagination?.total_data}
            />
          </TableWrapper>
        </div>
      )}
    </div>
  );
};

export default ZoneComponent;
