import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { getVehiclesByFilter } from 'features/vehicle/action';

const baseUrl = process.env.REACT_APP_IMAGES;

const SelectFieldVehicle = ({
  id,
  label,
  className,
  style,
  disable,
  placeholder,
  data,
  selectedCar,
  onSelectCar,
  intersectionAction,
  onSearchAction,
}) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  // const fieldRef = useRef(null);
  const { entry, ref: entryRef } = useInView();
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  // const [isChoosed, setIsChoosed] = useState(false);
  // const [choosedVehicle, setChoosedVehicle] = useState([]);
  const [vehicleValue, setVehicleValue] = useState('');
  const [filteredData, setfilteredData] = useState([]);
  const [isLoadingIntersection, setIsLoadingIntersection] = useState(false);
  // const [fieldHeight, setFieldHeight] = useState(44);

  // SET FILTERED DATA BASE ON DATA
  useEffect(() => {
    if (Object.keys(data).length === 0 || !data.vehicles) return;
    if (filteredData.length !== 0 && filteredData.every((item, idx) => item === data.vehicles[idx])) return;

    let choosedIds = [];
    for (let choosedData of selectedCar) {
      for (let chooseCar of choosedData.cars) {
        choosedIds.push(chooseCar.id);
      }
    }

    if (entry && entry.isIntersecting && !vehicleValue && data.pagination.limit !== 0 && data.pagination.page !== 0) {
      const checkedData = [...filteredData, ...data.vehicles].map((item) => ({
        ...item,
        checked: choosedIds.some((id) => id === item.id),
      }));
      setfilteredData(checkedData);
    } else {
      const checkedData = [...data.vehicles].map((item) => ({
        ...item,
        checked: choosedIds.some((id) => id === item.id),
      }));
      setfilteredData(checkedData);
    }
  }, [data]);

  // WHEN SELECTED CAR DOESNT EMPTY
  useEffect(() => {
    if (!selectedCar || !selectedCar.length) {
      setVehicleValue('');
      // setChoosedVehicle([]);
      setfilteredData([]);
    }

    let choosedIds = [];
    for (let choosedData of selectedCar) {
      for (let chooseCar of choosedData.cars) {
        choosedIds.push(chooseCar.id);
      }
    }

    const checkedData = [...filteredData].map((item) => ({
      ...item,
      checked: choosedIds.some((id) => id === item.id),
    }));
    setfilteredData(checkedData);
    // setChoosedVehicle(selectedCar);
    // selectedCar.length === 0 ? setIsChoosed(false) : setIsChoosed(true);
  }, [selectedCar]);

  // useEffect(() => {
  //   if (choosedVehicle.length === 0) return;
  //   onSelectCar(choosedVehicle);
  // }, [choosedVehicle]);

  // CLICK OUTSIDE
  useEffect(() => {
    // Add a click event listener to the document to handle clicks outside the dropdown.
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts.
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // INTERSECTION
  useEffect(() => {
    let timeout;

    if (entry) {
      if (entry.isIntersecting) {
        if (data?.pagination?.page === data?.pagination?.last_page) return;
        if (vehicleValue) return;
        setIsLoadingIntersection(true);

        timeout = setTimeout(() => {
          intersectionAction();
          setIsLoadingIntersection(false);
        }, 1000);
      }
    }

    return () => clearTimeout(timeout);
  }, [entry]);

  // CATCH THE FIELD HEIGHT
  // useEffect(() => {
  //   if (fieldRef.current) {
  //     const height = fieldRef.current.clientHeight;
  //     setFieldHeight(height);
  //   }
  // }, [fieldRef.current]);

  const searchHandler = (value, data) => {
    const isWithDriver = data?.every((item) => item.support_driver === true);
    const isWithoutDriver = data?.every((item) => item.support_driver === false);
    const isWithAndWithoutDriver =
      data?.some((item) => item.support_driver === true) && data?.some((item) => item.support_driver === false);

    setTimeout(() => {
      if (isWithDriver) {
        dispatch(getVehiclesByFilter({ supportDriver: true, name: value, page: value === '' ? 1 : 0 }));
      } else if (isWithoutDriver) {
        dispatch(getVehiclesByFilter({ supportDriver: false, name: value, page: value === '' ? 1 : 0 }));
      } else if (isWithAndWithoutDriver) {
        dispatch(getVehiclesByFilter({ name: value, page: value === '' ? 1 : 0 }));
      }
    }, 1000);
  };

  // DROPDOWN SHOW FUNCTION
  const toggleDropdown = () => {
    setIsShowDropdown(!isShowDropdown);
  };

  // CHOOSE CAR FUNCTION
  const handleChooseVehicle = (e) => {
    // setIsChoosed(true);
    const newFilteredData = [...filteredData];

    const checkedCar = newFilteredData?.find((item) => item.id == e.target.value);
    checkedCar.checked = e.target.checked;

    setfilteredData(newFilteredData);

    // if checked true
    if (e.target.checked) {
      if (selectedCar.length === 0) {
        onSelectCar([{ cars: [checkedCar], total: 1 }]);
      } else {
        const existingName = selectedCar?.find((item) => item.cars[0]?.name === checkedCar?.name);
        if (existingName) {
          const newChoosedVehicle = [...selectedCar];
          newChoosedVehicle[newChoosedVehicle.indexOf(existingName)] = {
            cars: [...existingName.cars, checkedCar],
            total: existingName.total + 1,
          };
          onSelectCar(newChoosedVehicle);
        } else {
          onSelectCar([...selectedCar, { cars: [checkedCar], total: 1 }]);
        }
      }
      // if checked false
    } else {
      const existingName = selectedCar?.find((item) => item.cars[0]?.name === checkedCar?.name);

      if (existingName) {
        if (existingName.total > 1) {
          const newChoosedVehicle = [...selectedCar];
          newChoosedVehicle[newChoosedVehicle.indexOf(existingName)] = {
            cars: existingName.cars.filter((item) => item.id !== checkedCar.id),
            total: existingName.total - 1,
          };
          onSelectCar(newChoosedVehicle);
        } else {
          const copyChoosedVehicle = [...selectedCar];
          const newChoosedVehicle = copyChoosedVehicle.filter((item) => item.cars[0]?.name !== checkedCar?.name);
          onSelectCar(newChoosedVehicle);

          // if (newChoosedVehicle.length === 0) {
          //   setIsChoosed(false);
          // }
        }
      }
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setVehicleValue(value);

    setIsShowDropdown(true);

    onSearchAction ? onSearchAction(value) : searchHandler(value, filteredData);
  };

  return (
    <div className={clsx('select-field-vehicle', className)} style={style} ref={dropdownRef}>
      {label && (
        <label htmlFor={id} className="select-field-vehicle__label">
          {label}
        </label>
      )}
      <div
        className={clsx('select-field-vehicle__field', disable ? 'disable' : '')}
        onClick={toggleDropdown}
        // ref={fieldRef}
      >
        {isShowDropdown ? (
          <input
            ref={inputRef}
            className="select-field-vehicle__field__input"
            id={id}
            placeholder={placeholder}
            value={vehicleValue}
            onChange={handleInputChange}
            readOnly={disable}
            autoFocus
          />
        ) : selectedCar.length ? (
          <div className="select-field-vehicle__field__car">
            {selectedCar?.map((item, i) => (
              <span className={'name'} key={Math.random() + i}>
                <p>{item.cars[0].name}</p>
                {item.total > 1 && <span>{item.total}</span>}
              </span>
            ))}
          </div>
        ) : (
          <input
            ref={inputRef}
            className="select-field-vehicle__field__input"
            id={id}
            placeholder={placeholder}
            value={vehicleValue}
            onChange={handleInputChange}
            readOnly={disable}
          />
        )}
      </div>

      {/* drop down */}
      {isShowDropdown && (
        <ul
          className="select-field-vehicle__dropdown"
          // style={{ top: `${fieldHeight + 40}px` }}
        >
          {filteredData?.length !== 0 && filteredData ? (
            filteredData.map((item, idx) => (
              <label key={idx} ref={idx === filteredData.length - 1 ? entryRef : null} htmlFor={`option-${item.id}`}>
                <div>
                  {item.photo.length !== 0 && (
                    <img src={`${baseUrl}${item.photo[0].name}`} alt={item.name} loading="lazy" />
                  )}
                </div>
                <div>
                  <h3>{`${item.brand_name} ${item.name}`}</h3>
                  <span>
                    <p>{`${item.max_passanger} Seats`}</p>
                    <span />
                    <p>{`${item.max_suitcase} Koper`}</p>
                    <span />
                    <p>{`Transmisi ${item.transmission}`}</p>
                    <span />
                    <p>{`${item.license_number}`}</p>
                  </span>
                </div>
                <div className={item.checked ? 'check' : ''}>
                  {/* <CheckboxField
                    htmlFor={`option-${item.id}`}
                    checked={item.checked}
                    onChange={handleChooseVehicle}
                    value={item.id}
                    disable={disable}
                  /> */}

                  <input
                    type="checkbox"
                    id={`option-${item.id}`}
                    checked={item.checked}
                    onChange={handleChooseVehicle}
                    value={item.id}
                    disabled={disable}
                    className="select-field-vehicle__checkbox"
                  />
                </div>
              </label>
            ))
          ) : (
            <li className="select-field-vehicle__not-found">Data Not Found</li>
          )}
          {isLoadingIntersection && filteredData?.length !== 0 && filteredData && !vehicleValue && <li>Loading ...</li>}
        </ul>
      )}
    </div>
  );
};

export default SelectFieldVehicle;
