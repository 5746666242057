import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllRoles = createAsyncThunk('roles/getAllRoles', async ({ page = 1, limit = 10 }, thunkAPI) => {
  const access_token = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/roles?limit=${limit}&page=${page}`, {
      headers: { Authorization: `Bearer ${access_token}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createNewRole = createAsyncThunk('roles/createNewRole', async (payload, thunkAPI) => {
  const access_token = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/roles`,
      { ...payload },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      },
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateRole = createAsyncThunk(
  'roles/updateRole',
  async ({ role_name, description, id, location_id }, thunkAPI) => {
    const access_token = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.put(
        `${BASE_URL}/${API_VERSION}/roles/${id}`,
        { role_name, description, location_id },
        {
          headers: { Authorization: `Bearer ${access_token}` },
        },
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const deleteRoleById = createAsyncThunk('roles/deleteRoleById', async (id, thunkAPI) => {
  const access_token = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${BASE_URL}/${API_VERSION}/roles/${id}`, {
      headers: { Authorization: `Bearer ${access_token}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const assignUserRole = createAsyncThunk('roles/assignUserRole', async ({ user_id, role_id }, thunkAPI) => {
  const access_token = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/user-roles`,
      { user_id, role_id },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      },
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const editUserRole = createAsyncThunk('roles/editUserRole', async ({ user_id, role_id }, thunkAPI) => {
  const access_token = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.put(
      `${BASE_URL}/${API_VERSION}/user-roles/user/${user_id}`,
      { role_id },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      },
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getRoleById = createAsyncThunk('roles/getRoleById', async (id, thunkAPI) => {
  const access_token = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/roles/${id}`, {
      headers: { Authorization: `Bearer ${access_token}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
