import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const dummyImage = '/assets/image/dummy-image.png';

const NavbarHomepage = ({ selectedPage }) => {
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <header className="navbar">
      <div className="navbar-left">
        <h1 className="navbar-active">{selectedPage}</h1>
        <p className="navbar-date">{format(date, 'EEEE, d MMMM yyyy | HH:mm:ss')}</p>
      </div>
      <div className="navbar-right">
        <figure className="btn-message">
          <img src="/assets/icon/message-icon.svg" alt="" />
        </figure>
        <figure className="btn-notification" onClick={() => navigate('/notification-inbox')}>
          <img src="/assets/icon/notification-icon.svg" alt="" />
        </figure>
        <figure className="btn-profile">
          <img src={dummyImage} alt="" />
        </figure>
      </div>
    </header>
  );
};

export default NavbarHomepage;
