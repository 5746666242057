import { createSlice } from '@reduxjs/toolkit';
import { getAllBusinessRules, getBusinessRuleById, getBusinessRuleFormula, getServices } from './actions';

const initialState = {
  data: {},
  selected: {},
  formula: {},
  services: [],
  subServices: [],
  facilities: [],
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  isError: false,
  errorMessage: {},
};

export const businessRule = createSlice({
  name: 'businessRule',
  initialState,
  reducers: {
    getSubServices: (state, action) => {
      const selected = state.services.find((item) => item.name.toLowerCase() == action.payload);
      if (!selected) return;
      state.subServices = selected.sub_services;
    },
    getFacilities: (state) => {
      const facilities = state.subServices.map((item) => item.facilities);
      state.facilities = facilities;
    },
    resetBusinessRuleSelected: (state) => {
      state.selected = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBusinessRules.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllBusinessRules.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllBusinessRules.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(getBusinessRuleById.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = {};
        state.selected = {};
      })
      .addCase(getBusinessRuleById.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getBusinessRuleById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selected = action.payload;
      })
      .addCase(getBusinessRuleFormula.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = {};
        state.formula = {};
      })
      .addCase(getBusinessRuleFormula.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getBusinessRuleFormula.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.formula = action.payload;
      })
      .addCase(getServices.pending, (state) => {
        state.status = 'loading';
        state.isError = false;
        state.errorMessage = {};
        state.services = [];
      })
      .addCase(getServices.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.services = action.payload;
      });
  },
});

export const { getSubServices, getFacilities, resetBusinessRuleSelected } = businessRule.actions;

export default businessRule.reducer;
