import { createSlice } from '@reduxjs/toolkit';
import { getSubservices } from './actions';

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const subservices = createSlice({
  name: 'subservices',
  initialState,
  reducers: {
    resetSubservicesData: (state) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubservices.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = [];
      })
      .addCase(getSubservices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getSubservices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});

export const { resetSubservicesData } = subservices.actions;

export default subservices.reducer;
