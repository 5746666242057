import React from 'react';
import { ReactComponent as RemoveIcon } from 'icons/close-filled-icon.svg';
import PreviewImage from 'components/Modals/PreviewImage';
import ReactDOM from 'react-dom';

const Thumbnail = ({ image, filename, setState, show, id, handleRemove = () => {} }) => {
  const clickHandler = (e) => {
    setState((prev) =>
      prev.map((item) => {
        if (item.id == e.target.dataset.id) {
          return { ...item, show: true };
        } else {
          return { ...item, show: false };
        }
      }),
    );
  };

  const hideHandler = () => {
    setState((prev) => prev.map((item) => ({ ...item, show: false })));
  };

  return (
    <>
      <div className="thumbnail">
        <figure className="image" onClick={clickHandler}>
          <img src={image} alt="thumbnail" data-id={id} />
        </figure>
        <p className="filename">{filename.slice(0, 32) + ' ...'}</p>
        <figure className="icon" onClick={() => handleRemove(filename)}>
          <RemoveIcon />
        </figure>
      </div>
      {show &&
        ReactDOM.createPortal(<PreviewImage image={image} setShow={hideHandler} />, document.getElementById('modal'))}
    </>
  );
};

export default Thumbnail;
