import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { setCurrency } from 'utils/functionality';

const baseUrl = process.env.REACT_APP_IMAGES;

const SelectFieldAirportTransferCar = ({
  label,
  name,
  htmlFor,
  value, // state
  onChange, // setState
  onClick, // when choose dropdown
  data = [],
  className,
  style,
  placeholder,
  disable,
  selectedCar,
  onSelectCar,
  ...rest
}) => {
  const resetString = '';

  // SHOW DROPDOWN FUNCTION
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  // FIRST LOAD STATE
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // SEARCH STATE
  const [isSearch, setIsSearch] = useState(false);

  // FILTER CAR STATE
  const [filteredCar, setFilteredCar] = useState([]);

  // IS LOADING WHEN SEARCH VEHICLE ACTIVE
  const [isLoadingSearchVehicle, setIsLoadingSearchVehicle] = useState(false);

  // FUNCTION WHEN CLICK DROPDOWN
  const handleClick = (item) => {
    onClick(item.vehicle_id, resetString);
    onSelectCar(item);
    setIsSearch(false);
    setIsShowDropdown(false);
  };

  // FUNCTION WHEN BLUR
  const handleBlur = () => {
    setTimeout(() => {
      setIsShowDropdown(false);
      setIsSearch(false);
    }, 200);
  };

  // SET ISSEARCH WHEN FIRST LOAD
  useEffect(() => {
    // if firstload and user didnt choose yet
    if (typeof value !== 'number' && isFirstLoad && !selectedCar) {
      setIsSearch(true);
      return;
    }

    // when user has choosed a car before
    if (isFirstLoad && value !== '' && data && data.length !== 0 && selectedCar) {
      setIsSearch(false);
      const car = data.find((item) => item.vehicle_id == value);
      onSelectCar(car);
      return;
    }
  }, [isFirstLoad]);

  // SET FILTERED CAR WITH ALL CAR WHEN FIRST LOAD
  useEffect(() => {
    if (!data || data.length === 0) return;
    setFilteredCar(data);
  }, [data]);

  // FILTER CAR
  useEffect(() => {
    if ((!isSearch && value !== '' && typeof value === 'number') || isFirstLoad) return;
    const searchCarByName = data?.filter((item) => item?.vehicle_name.toLowerCase().includes(value.toLowerCase()));
    setIsLoadingSearchVehicle(true);

    if (value === '') {
      setFilteredCar(data);
      setIsLoadingSearchVehicle(false);
      return;
    }
    // debbounce
    const timeout = setTimeout(() => {
      setIsLoadingSearchVehicle(false);
      setFilteredCar(searchCarByName);
    }, 500);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <div className={clsx('select-field-car', className)}>
      {label && (
        <label htmlFor={htmlFor} className="select-field-car__label">
          {label}
        </label>
      )}
      <div
        style={{ width: '100%' }}
        className={disable ? 'select-field-car__input-field disable' : 'select-field-car__input-field'}
        tabIndex={!isSearch ? 0 : 1}
        onFocus={
          !isSearch
            ? () => {
                setIsFirstLoad(false);
                setIsSearch(true);
                onClick('', resetString);
              }
            : () => {
                setIsFirstLoad(false);
              }
        }
        onBlur={isSearch ? () => {} : handleBlur}
      >
        {(isFirstLoad && !isSearch && !selectedCar) ||
        (isFirstLoad && isSearch && !selectedCar) ||
        (!isFirstLoad && isSearch && selectedCar) ||
        (!isFirstLoad && isSearch && !selectedCar) ||
        (!isFirstLoad && !isSearch && !selectedCar) ? (
          <input
            autoFocus={isSearch ? true : false}
            id={htmlFor}
            type="text"
            placeholder={placeholder}
            autoComplete="off"
            name={name}
            style={style}
            className="select-field-car__input-field__input"
            value={value}
            onChange={onChange}
            onFocus={() => setIsShowDropdown(true)}
            onBlur={handleBlur}
            {...rest}
          />
        ) : (
          <div className="select-field-car__input-field__car">
            <div>
              <div>
                {selectedCar?.images?.length !== 0 && (
                  <img src={`${baseUrl}${selectedCar?.vehicle_image}`} alt={selectedCar?.name} loading="lazy" />
                )}
              </div>
              <div>
                <h3>{`${selectedCar?.category} - ${selectedCar?.vehicle_name} - ${selectedCar?.licence_number}`}</h3>
                <span>
                  <p>{`${selectedCar?.max_passenger} Seats`}</p>
                  <span />
                  <p>{`${selectedCar?.max_suitecase} Koper`}</p>
                  <span />
                  <p>{`Transmisi ${selectedCar?.transmission}`}</p>
                </span>
              </div>
            </div>
            <span>IDR {setCurrency(selectedCar?.package_price)?.replace('Rp', '')}</span>
          </div>
        )}
      </div>
      {isShowDropdown && !isLoadingSearchVehicle && (
        <ul className="select-field-car__dropdown">
          {filteredCar &&
            filteredCar?.map((item) => (
              <li key={item.vehicle_id} data-id={item.vehicle_id} onClick={() => handleClick(item)}>
                <div>
                  {item?.images?.length !== 0 && (
                    <img src={`${baseUrl}${item?.vehicle_image}`} alt={item?.name} loading="lazy" />
                  )}
                </div>
                <div>
                  <h3>{`${item?.category} - ${item?.vehicle_name} - ${item?.licence_number}`}</h3>
                  <span>
                    <p>{`${item.max_passenger} Seats`}</p>
                    <span />
                    <p>{`${item.max_suitecase} Koper`}</p>
                    <span />
                    <p>{`Transmisi ${item.transmission}`}</p>
                  </span>
                  <span>IDR {setCurrency(item?.package_price).replace('Rp', '')}</span>
                </div>
              </li>
            ))}
        </ul>
      )}
      {isShowDropdown && filteredCar?.length === 0 && (
        <ul className="select-field-car__dropdown">
          <li>No Vehicles found</li>
        </ul>
      )}
      {isShowDropdown && isLoadingSearchVehicle && (
        <ul className="select-field-car__dropdown">
          <li>Loading ...</li>
        </ul>
      )}
    </div>
  );
};

export default React.memo(SelectFieldAirportTransferCar);
