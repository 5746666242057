import TextAreaInput from 'components/Global/TextArea';
import Modals from '../Modals';
import { Button } from 'components/Global';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { rejectBankTransferPayment } from 'features/orders/actions';
import { getDetailOrder } from 'features/orders/detailOrderSlice';
// import { useState } from 'react';

const CancelPaymentMessageModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // CONTEXT
  const {
    showToast,
    setShowCancelPaymentMessage,
    setShowConfirmation,
    cancelPaymentMessageValue,
    setCancelPaymentMessageValue,
  } = useAppContext();

  // GLOBAL STATE
  const orderData = useSelector(getDetailOrder);

  // LOCAL STATE
  // const [cancelMessage, setCancelMessage] = useState('');

  // SUBMIT ACTION
  const handleSubmit = async () => {
    // validation
    const validCancelMessage = cancelPaymentMessageValue.replace(/\s/g, '').length !== 0;

    // check if not valid return error toast
    if (!validCancelMessage) {
      return showToast({ type: 'error', message: 'Tulis Alasan Pembatalan' });
    }

    // cancel if form valid
    const payload = {
      transaction_key: orderData.transaction_key,
      message: cancelPaymentMessageValue,
    };

    setShowConfirmation({
      message: 'Apakah anda yakin menolak pembayaran',
      show: true,
      onClick: async () => {
        setShowCancelPaymentMessage(false);

        try {
          await dispatch(rejectBankTransferPayment(payload)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Menolak Pembayaran' });
        } catch (err) {
          showToast({ type: 'error', message: 'Gagal Menolak Pembayaran' });
        } finally {
          navigate(-1);
        }
      },
    });
  };

  return (
    <Modals setState={setShowCancelPaymentMessage}>
      <div className="cancel-payment-message">
        <h3>Tulis Alasan Pembatalan</h3>

        {/* TEXT AREA */}
        <div className="cancel-payment-message__message">
          <span>Keterangan Pembatalan</span>
          <TextAreaInput
            className="cancel-payment-message__message__textarea"
            style={{ height: '292px' }}
            value={cancelPaymentMessageValue}
            onChange={(e) => setCancelPaymentMessageValue(e.target.value)}
            placeholder="Tulis keterangan pembatalan"
          />
        </div>

        {/* BUTTON */}
        <div className="cancel-payment-message__button">
          <Button
            type="button"
            width="165px"
            height="39px"
            variant="primary"
            size="md"
            className="button"
            onClick={handleSubmit}
          >
            Lanjutkan
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default CancelPaymentMessageModal;
