import { createSlice } from '@reduxjs/toolkit';
import { createIdentityReview } from './actions';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  errorMessage: {}, // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const reviewIdentity = createSlice({
  name: 'reviewIdentity',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(createIdentityReview.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(createIdentityReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createIdentityReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});

export default reviewIdentity.reducer;
