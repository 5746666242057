import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as FileIcon } from 'icons/file-icon.svg';
import Thumbnail from './Thumbnail';

const UploadImage = ({ imageFiles, previewable, multiple, setState }) => {
  const targetRef = useRef(null);
  const [imageList, setImageList] = useState([]); // Image come from BE data
  const [imageData, setImageData] = useState([]); // Image come from selecting images
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (imageFiles === undefined) return;
    const data = imageFiles.map((image) => ({
      ...image,
      url: process.env.REACT_APP_IMAGES + image.name,
    }));

    setImageList(data);
  }, [imageFiles]);

  useEffect(() => {
    if (imageList.length > 0) {
      setImageList((prev) => prev.map((item) => ({ ...item, show: false })));
    }
  }, []);

  const onImageChange = (e) => {
    setImages([...e.target.files]);
    setState && setState(e.target.files);
  };

  useEffect(() => {
    if (images.length < 1) return;
    const newImageUrl = [];
    images.map((img) => newImageUrl.push(URL.createObjectURL(img)));
    setImageData(newImageUrl.map((url, idx) => ({ url: url, show: false, filename: images[idx]?.name, id: idx + 1 })));
  }, [images]);

  // @TODO: add drag n drop file functionality

  return (
    <div className="upload-image__container">
      <input
        type="file"
        name="upload-image"
        multiple={multiple}
        id="upload-image"
        accept="image/*"
        hidden
        ref={targetRef}
        onChange={onImageChange}
      />
      {!multiple && imageData.length > 0 ? (
        <img src={imageData[0]?.url} alt="upload-thumbnail" />
      ) : (
        <>
          <div className="upload-image__button">
            <FileIcon />
            <p>
              Drop your image here, or <span onClick={() => targetRef.current.click()}>browse</span>
            </p>
          </div>
        </>
      )}
      {(imageList.length > 0 || imageData.length > 0) && multiple ? (
        <div className="upload-image__thumbnails">
          {imageList.map((img, idx) => (
            <Thumbnail
              key={idx}
              filename={img.name}
              setState={setImageList}
              image={img.url}
              show={img.show}
              id={img.id}
            />
          ))}
          {imageData.map((img, idx) => (
            <Thumbnail
              key={idx}
              image={img.url}
              filename={img.filename}
              id={img.id}
              show={img.show}
              setState={previewable ? setImageData : () => {}}
            />
          ))}
        </div>
      ) : null}
      {imageData.length > 0 && !multiple && (
        <div className="upload-image__button-sm">
          <FileIcon />
          <p>
            Drop your image here, or <span onClick={() => targetRef.current.click()}>browse</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default UploadImage;
