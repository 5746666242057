import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRentalLocation } from 'features/rental-location/actions';
import { getAllLanguage } from 'features/language/actions';
import { useAppContext } from 'components/Context/AppContext';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import { Button, TableWrapper } from 'components/Global';
import Tabs, { TabPane } from 'components/Global/Tabs';
import LanguageEditor from '../../LanguageEditor';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as TACFilledIcon } from 'icons/term-and-condition-filled-icon.svg';
import {
  createTermAndConditionLocation,
  getAllTermAndconditionLocation,
  getTermAndConditionById,
  updateTermAndCondition,
} from 'features/term-and-condition/actions';
import { checkPermission } from 'utils/functionality';

const DetailLokasi = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showToast, setShowSpinner } = useAppContext();

  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const { data: languageData } = useSelector((state) => state.language);
  const { selectedData: selectedTACData } = useSelector((state) => state.termAndCondition);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  const [activeTab, setActiveTab] = useState('Indonesia');

  const [locationValue, setLocationValue] = useState('');
  const [values, setValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const actionMutation = async (method, payload, successMessage, errorMessage) => {
    if (method === 'ADD') {
      try {
        await dispatch(createTermAndConditionLocation(payload)).unwrap();
        showToast({ type: 'success', message: successMessage });
        navigate('/term-and-condition?tab-active=Lokasi');
      } catch (error) {
        showToast({ type: 'error', message: errorMessage });
      }
    } else {
      try {
        await dispatch(updateTermAndCondition(payload)).unwrap();
        showToast({ type: 'success', message: successMessage });
        navigate('/term-and-condition?tab-active=Lokasi');
        dispatch(getAllTermAndconditionLocation({ page: 1, limit: 10 }));
      } catch (error) {
        showToast({ type: 'error', message: errorMessage });
      }
    }
  };

  const submitHandler = () => {
    const validValues = values.every(
      (item) => item.value.replace(/\s/g, '').length !== 0 && item.value !== '<p><br></p>',
    );

    if (!locationValue) {
      return showToast({ type: 'error', message: 'Pilih Lokasi Rental' });
    }

    if (!validValues) {
      return showToast({ type: 'error', message: 'Masukan Syarat dan Ketentuan' });
    }

    const payload = {
      reference_id: locationValue.id,
      reference_type: 'location',
      term_condition_lines: values.map((item) => ({ language_id: item.id, term: item.value })),
    };

    if (id) {
      actionMutation('UPDATE', { payloadData: payload, tacId: id }, 'Berhasil Update Item', 'Terjadi Kesalahan');
    } else {
      actionMutation('ADD', payload, 'Berhasil Tambah Item', 'Terjadi Kesalahan');
    }
  };

  useEffect(() => {
    dispatch(getAllRentalLocation());
    dispatch(getAllLanguage());

    if (id) {
      setIsLoading(true);
      dispatch(getTermAndConditionById(id));
    }
  }, []);

  useEffect(() => {
    if (!Object.keys(languageData).length || !languageData.data?.length || id) return;

    const mappingValues = languageData.data.map((item) => ({ id: item.id, value: '' }));
    setValues(mappingValues);
  }, [languageData]);

  useEffect(() => {
    if (!Object.keys(selectedTACData).length || !rentalLocationData.length || !Object.keys(languageData).length || !id)
      return;

    const selectedLocation = rentalLocationData.find((item) => item.id === selectedTACData.location_id);

    const mappingValues = languageData.data?.map((item) => ({ id: item.id, value: '' }));
    for (let selectedTerm of selectedTACData.term_condition_lines) {
      const choosedValues = mappingValues?.find((item) => item.id === selectedTerm.language.id);
      if (choosedValues) {
        mappingValues[mappingValues.indexOf(choosedValues)].value = selectedTerm.term;
      }
    }

    setLocationValue(selectedLocation);
    setValues(mappingValues);
    setIsLoading(false);
  }, [selectedTACData, rentalLocationData, languageData]);

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  return (
    <div className="tac-location-detail">
      <header className="tac-location-detail__header" onClick={() => navigate(-1)}>
        <LeftArrow />
        <p>Kembali</p>
      </header>

      <TableWrapper
        icon={<TACFilledIcon fill="#009EF7" width="25px" height="25px" />}
        title="Syarat & Ketentuan - Lokasi"
      >
        <div className="tac-location-detail__location-wrapper">
          <SelectFieldShuttle
            label="Lokasi Sewa"
            placeholder="Pilih Lokasi Sewa"
            htmlFor="lokasi-sewa"
            data={rentalLocationData || []}
            value={locationValue.id}
            onChange={setLocationValue}
            searchFeature
          />
        </div>

        {!Object.keys(languageData).length || !languageData.data.length ? null : (
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
            {languageData.data.map((item) => (
              <TabPane key={item.id} label={item.name}>
                <LanguageEditor
                  lang={item}
                  allValue={values}
                  value={values?.find((val) => val.id === item.id)?.value}
                  onChange={setValues}
                />
              </TabPane>
            ))}
          </Tabs>
        )}

        <div className="tac-location-detail__button">
          <Button width={192} role="button" variant="outline" type="button" onClick={() => navigate(-1)}>
            Kembali
          </Button>

          {(!id || checkPermission(offCanvasMenu, currentMenu, 'update')) && (
            <Button width={192} role="button" type="button" onClick={submitHandler}>
              Simpan
            </Button>
          )}
        </div>
      </TableWrapper>
    </div>
  );
};

export default DetailLokasi;
