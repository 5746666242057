/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useAppContext } from 'components/Context/AppContext';
import { Button, InputField, SelectField, UploadImage } from 'components/Global';
import Modals from '../Modals';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { createNewVehicle } from 'features/vehicle/action';

const AddCarModal = () => {
  const { setShowAddCar, showToast, setShowSpinner } = useAppContext();

  const [carBrand, setCarBrand] = useState('');
  const [carName, setCarName] = useState('');
  const [carYear, setCarYear] = useState('');
  const [carPrice, setCarPrice] = useState(0);
  const [carStatus, setCarStatus] = useState(0);
  const [carTransmission, setCarTransmission] = useState(0);
  const [carLicensePlate, setCarLicensePlate] = useState({ kodeArea: '', nomorPlat: '', kodeHuruf: '' });
  const { kodeArea, nomorPlat, kodeHuruf } = carLicensePlate;
  const brands = useSelector((state) => state.brands?.data);

  const [maxSeat, setMaxSeat] = useState(1);
  const [maxBaggage, setMaxBaggage] = useState(1);
  const [priceTerms, setPriceTerms] = useState([]);
  const [additionals, setAdditionals] = useState([]);
  const garages = useSelector((state) => state.garages?.data);
  const dispatch = useDispatch();

  useEffect(() => {
    setPriceTerms(priceTermsOptions);
    setAdditionals(additionalOptions);
  }, []);

  const handleLicensePlate = (e) => {
    if (e.target.value.length > 4) return;
    setCarLicensePlate((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handlePriceTerms = (e) => {
    const { name, checked } = e.target;

    let tempPriceTerms = priceTerms.map((item) => (item.label === name ? { ...item, isChecked: checked } : item));
    setPriceTerms(tempPriceTerms);
  };

  const handleAdditional = (e) => {
    const { name, checked } = e.target;

    let tempAdditional = additionals.map((item) => (item.name === name ? { ...item, isChecked: checked } : item));
    setAdditionals(tempAdditional);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const selectedPriceTerms = priceTerms.filter((item) => item.isChecked);
    const selectedAdditionals = additionals.filter((item) => item.isChecked);
    const selectedGarage = garages[0].id;
    const status = carStatus == 1 ? 'available' : carStatus == 2 ? 'booked' : 'locked';
    const transmission = carTransmission == 1 ? 'matic' : 'manual';

    try {
      const res = await dispatch(
        createNewVehicle({
          name: carName,
          year: +carYear,
          price: +carPrice,
          license_number: `${kodeArea.toUpperCase()} ${nomorPlat} ${kodeHuruf.toUpperCase()}`,
          max_passanger: +maxSeat,
          max_suitcase: +maxBaggage,
          disablility_allowed: selectedAdditionals.find((i) => i.name === 'for_disability') ? true : false,
          smoke_allowed: selectedAdditionals.find((i) => i.name === 'smoke_allowed') ? true : false,
          pet_allowed: selectedAdditionals.find((i) => i.name === 'pet_allowed') ? true : false,
          status: status,
          transmission: transmission,
          brand_vehicles: +carBrand,
          garage_vehicles: selectedGarage,
          photo: ['/rentcar/development/cars/veloz.jpg', '/rentcar/development/cars/veloz2.jpg'],
        }),
      );

      setShowSpinner(true);

      if (res.meta?.requestStatus === 'fulfilled') {
        showToast({ type: 'success', message: 'Berhasil Menambahkan Mobil' });
        return;
      }
      showToast({ type: 'error', message: 'Gagal Menambahkan Mobil' });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: `Terjadi Kesalahan: ${error}` });
    } finally {
      setShowSpinner(false);
      setShowAddCar(false);
    }
  };

  return (
    <Modals setState={setShowAddCar} title="Tambah Mobil" icon={<CarIcon fill="#009EF7" width="25px" height="25px" />}>
      <div className="add-car">
        <div className="add-car__wrapper">
          <form className="add-car__form-group" onSubmit={handleSubmit}>
            <SelectField
              label="Merk Mobil"
              type="text"
              data={brands}
              htmlFor="merk-mobil"
              placeholder="Pilih Merk Mobil"
              value={carBrand}
              onChange={(e) => setCarBrand(e.target.value)}
            />
            <InputField
              label="Nama Mobil"
              type="text"
              htmlFor="nama-mobil"
              placeholder="Tulis Nama Mobil"
              value={carName}
              onChange={(e) => setCarName(e.target.value)}
            />
            <InputField
              label="Tahun Mobil"
              type="text"
              htmlFor="tahun-mobil"
              placeholder="Tulis Tahun Mobil"
              value={carYear}
              onChange={(e) => setCarYear(e.target.value)}
            />
            <InputField
              label="Harga Mobil"
              type="number"
              htmlFor="harga-mobil"
              placeholder="Tulis Harga Mobil"
              value={carPrice}
              price
              onChange={(e) => setCarPrice(e.target.value)}
            />
            <SelectField
              label="Status Mobil"
              type="text"
              placeholder="Pilih Status Mobil"
              htmlFor="status-mobil"
              data={carStatusOptions}
              value={carStatus}
              onChange={(e) => setCarStatus(e.target.value)}
            />

            <div className="add-car__license-plate">
              <label htmlFor="kode-area" className="add-car__label">
                No Plat
              </label>
              <div className="add-car__license-input-group">
                <InputField
                  type="text"
                  id="kode-area"
                  name="kodeArea"
                  placeholder="Kode Area"
                  onChange={handleLicensePlate}
                  value={kodeArea.toUpperCase()}
                  maxLength="2"
                />
                <InputField
                  type="number"
                  name="nomorPlat"
                  placeholder="Nomor Plat"
                  onChange={handleLicensePlate}
                  value={nomorPlat}
                />
                <InputField
                  type="text"
                  name="kodeHuruf"
                  placeholder="Kode Huruf"
                  onChange={handleLicensePlate}
                  value={kodeHuruf.toUpperCase()}
                />
              </div>
            </div>

            <div className="add-car__max-select-group">
              <div className="">
                <label htmlFor="seat" className="add-car__label">
                  Jumlah Seat
                </label>
                <div className="add-car__dropdown">
                  <select
                    id="seat"
                    className="add-car__dropdown"
                    defaultValue={maxSeat}
                    onChange={(e) => setMaxSeat(e.target.value)}
                  >
                    {seatOptions.map(({ value }) => (
                      <option value={value} key={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <label htmlFor="baggage" className="add-car__label">
                  Jumlah Koper
                </label>
                <div className="add-car__dropdown">
                  <select
                    id="baggage"
                    className="add-car__dropdown"
                    defaultValue={maxBaggage}
                    onChange={(e) => setMaxBaggage(e.target.value)}
                  >
                    <option value={1}>1 Koper (L) L : 28″ (47 x 32 x 73 cm)</option>
                    <option value={2}>2 Koper (M) M : 22″ (28x22x58)</option>
                    <option value={3}>3 Koper (S) M : 16″ (32x17x47)</option>
                  </select>
                </div>
              </div>
            </div>

            <SelectField
              label="Transmisi"
              type="text"
              placeholder="Pilih Transmisi Mobil"
              htmlFor="transmisi-mobil"
              data={carTransmissionOptions}
              value={carTransmission}
              onChange={(e) => setCarTransmission(e.target.value)}
            />

            <div className="add-car__price-terms">
              <h3 className="add-car__label">Ketentuan Harga (Termasuk)</h3>
              <div className="add-car__checkbox-group">
                {priceTerms.map((item) => (
                  <div className="add-car__checkbox-item" key={item.id}>
                    <input
                      type="checkbox"
                      id={item.label}
                      name={item.label}
                      onChange={handlePriceTerms}
                      checked={item?.isChecked || false}
                    />
                    <label htmlFor={item.label}>{item.label}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className="add-car__additional">
              <h3 className="add-car__label">Additional</h3>
              <div className="add-car__checkbox-group">
                {additionals.map((item) => (
                  <div className="add-car__checkbox-item" key={item.id}>
                    <input
                      type="checkbox"
                      id={item.name}
                      name={item.name}
                      onChange={handleAdditional}
                      checked={item?.isChecked || false}
                    />
                    <label htmlFor={item.name}>{item.label}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="add-car__upload">
              <h3 className="add-car__label">Upload Foto</h3>
              <UploadImage multiple />
            </div>
            <div className="add-car__btn-action">
              <Button wfull role="submit">
                Tambah
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modals>
  );
};

export default AddCarModal;

const carTransmissionOptions = [
  { id: 1, name: 'matic' },
  { id: 2, name: 'manual' },
];

const carStatusOptions = [
  { id: 1, name: 'ready' },
  { id: 2, name: 'in use' },
  { id: 3, name: 'in repair' },
];

const priceTermsOptions = [
  { id: 1, label: 'Driver' },
  { id: 2, label: 'Parkir' },
  { id: 3, label: 'Snacks' },
  { id: 4, label: 'Necessities' },
  { id: 5, label: 'BBM' },
  { id: 6, label: 'Tol' },
];

const additionalOptions = [
  { id: 1, name: 'for_disability', label: 'For Disability' },
  { id: 2, name: 'pet_allowed', label: 'Pet Allowed' },
  { id: 3, name: 'smoke_allowed', label: 'Smoke Allowed' },
];

const seatOptions = [...new Array(10)].map((_, index) => {
  const value = index + 1;
  return {
    value,
  };
});
