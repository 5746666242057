import React from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import SchedulePriceDetail from './SchedulePriceDetail';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import HargaPotonganDetail from './HargaPotonganDetail';

const DetailSchedulePage = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [params, setParams] = useState();

  useEffect(() => {
    const params = searchParams.get('tab');
    setParams(params);
  }, [searchParams]);

  return (
    <div className="detail-schedule-page">
      {/* ARROW BACK */}
      <header className="detail-schedule-page__header" onClick={() => navigate(-1)}>
        <LeftArrow />
        <span>Kembali</span>
      </header>

      {/* CONTENT */}
      {params === 'Schedule Price' ? (
        <SchedulePriceDetail />
      ) : params === 'List Harga Potongan' ? (
        <HargaPotonganDetail />
      ) : null}
    </div>
  );
};

export default DetailSchedulePage;
