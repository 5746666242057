import React from 'react';
import { ReactComponent as PetAllowed } from 'icons/pet-allowed-icon.svg';
import { ReactComponent as SmokedAllowed } from 'icons/smoked-allowed-icon.svg';
import { ReactComponent as DisabilityAllowed } from 'icons/disability-allowed-icon.svg';
import { ReactComponent as Check } from 'icons/check-icon.svg';
import { InputField, SelectField, PricesInputField } from 'components/Global';

import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { getSubservices } from 'features/subservices/actions';
import { resetSubservicesData } from 'features/subservices/subservicesSlice';

// rules array
const rules = [
  {
    id: 'smoke_allowed',
    icon: <SmokedAllowed />,
    label: 'Boleh merokok',
  },
  {
    id: 'disability_allowed',
    icon: <DisabilityAllowed />,
    label: 'Disabilitas Support',
  },
  {
    id: 'pet_allowed',
    icon: <PetAllowed />,
    label: 'Peliharaan diperbolehkan',
  },
];

// car status array
const status = [
  {
    id: '1',
    name: 'Ready',
  },
  {
    id: '2',
    name: 'In Use',
  },
  {
    id: '3',
    name: 'In Repair',
  },
];

// transmision array
const transmission = [
  {
    id: '1',
    name: 'manual',
  },
  {
    id: '2',
    name: 'matic',
  },
];

const ContentLeft = ({
  dataCar,
  setDataCar,
  isFirstLoad,
  setIsFirstLoad,
  withoutDriver,
  setWithoutDriver,
  withDriver,
  setWithDriver,
}) => {
  const dispatch = useDispatch();
  // global state
  const brands = useSelector((state) => state.brands?.data);
  const garages = useSelector((state) => state.garages?.data);
  const category = useSelector((state) => state.category.data.category);
  const subservices = useSelector((state) => state.subservices?.data);

  // plat number state
  const [codeArea, setCodeArea] = useState('');
  const [carNumber, setCarNumber] = useState('');
  const [codeLetters, setCodeLetters] = useState('');

  // function to handle checkbox rules
  const handleCheck = (e) => {
    setIsFirstLoad(false);
    const { name, checked } = e.target;
    setDataCar({ ...dataCar, [name]: checked });
  };

  // function to handle radio button drivers
  const handleRadio = (e) => {
    setIsFirstLoad(false);
    const { value, checked } = e.target;
    if (value === 'tanpa-supir') {
      setWithoutDriver(checked);
    } else if (value === 'dengan-supir') {
      setWithDriver(checked);
    }
  };

  const handleChangeGarage = (e) => {
    setIsFirstLoad(false);

    setWithoutDriver(false);
    setWithDriver(false);

    setDataCar({
      ...dataCar,
      garage_vehicles: parseInt(e.target.value),

      price_with_driver: '',
      price: '',
    });
  };

  // function to handle input prices
  const handleChangePrice = (e) => {
    setIsFirstLoad(false);
    const { name, value } = e.target;
    setDataCar({ ...dataCar, [name]: value });
  };

  // function to prevent scroll behavior input number
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  // will set a licence number when code area, car number, and code letter changing
  useEffect(() => {
    setDataCar({ ...dataCar, license_number: `${codeArea} ${carNumber} ${codeLetters}` });
  }, [codeArea, carNumber, codeLetters]);

  // set inital value codearea car number code letter
  useEffect(() => {
    if (dataCar.license_number) {
      const splittedDataCar = dataCar.license_number.split(' ');
      setCodeArea(splittedDataCar[0]);
      setCarNumber(splittedDataCar[1]);
      setCodeLetters(splittedDataCar[2]);
    }
  }, [dataCar]);

  // will set a new total price and only calulate price & gasoline when with drivers was clicked
  useEffect(() => {
    if (isFirstLoad) return;

    if (!withDriver && withoutDriver) {
      setDataCar({
        ...dataCar,
        price_with_driver: 0,
        support_driver: false,
        required_driver: false,
      });
    }

    if (withDriver && withoutDriver) {
      setDataCar({
        ...dataCar,
        support_driver: true,
        required_driver: false,
      });
    }

    if (withDriver && !withoutDriver) {
      setDataCar({
        ...dataCar,
        price: 0,
        support_driver: true,
        required_driver: true,
      });
    }
  }, [withDriver, withoutDriver]);

  const getLocationIdByGarage = (garageId) => {
    const selectedGarage = garages.filter((garage) => garage.id === garageId);
    return selectedGarage[0]?.location_id;
  };

  useEffect(() => {
    if (!dataCar.garage_vehicles) return;
    if (garages.length === 0) return;

    const locId = getLocationIdByGarage(dataCar.garage_vehicles);

    dispatch(getSubservices(locId));

    return () => dispatch(resetSubservicesData());
  }, [dataCar.garage_vehicles, garages]);

  let driversAvailability = [];

  let hasWithoutDriver = false;
  let hasWithDriver = false;

  for (const item of subservices) {
    if (item.name === 'Without Driver') {
      driversAvailability.push({
        id: 'tanpa-supir',
        label: 'Tanpa Supir',
        checked: true,
      });
      hasWithoutDriver = true;
    } else if (item.name === 'With Driver') {
      driversAvailability.push({
        id: 'dengan-supir',
        label: 'Dengan Supir',
        checked: false,
      });
      hasWithDriver = true;
    }
  }

  return (
    <div className="content--left">
      {/* GARAGE */}
      <div className="first-section__garage">
        <SelectField
          label="Pilih Garasi"
          type="text"
          data={garages}
          value={dataCar.garage_vehicles}
          htmlFor="garasi-mobil"
          name="garasi-mobil"
          placeholder="Pilih Garasi"
          // onChange={(e) => setDataCar({ ...dataCar, garage_vehicles: parseInt(e.target.value) })}
          onChange={handleChangeGarage}
        />
      </div>
      <div className="first-section">
        <SelectField
          label="Merk Mobil"
          type="text"
          data={brands}
          name="merk-mobil"
          value={dataCar.brand_vehicles}
          htmlFor="merk-mobil"
          placeholder="Pilih Merk Mobil"
          onChange={(e) => setDataCar({ ...dataCar, brand_vehicles: parseInt(e.target.value) })}
        />
        <InputField
          label="Nama Mobil"
          htmlFor="nama-mobil"
          placeholder="Contoh : Xpander"
          value={dataCar.name || ''}
          onChange={(e) => setDataCar({ ...dataCar, name: e.target.value })}
        />
        <InputField
          type="number"
          label="Tahun Mobil"
          htmlFor="tahun-mobil"
          placeholder="Tulis Tahun Mobil"
          value={dataCar.year || ''}
          onChange={(e) => {
            if (e.target.value.length > 4) return;
            setDataCar({ ...dataCar, year: e.target.value });
          }}
          onWheel={numberInputOnWheelPreventChange}
        />
        <SelectField
          label="Kategori Mobil"
          htmlFor="categori-mobil"
          placeholder="Pilih Kategori"
          data={category}
          name="category-mobil"
          value={dataCar.category_vehicles}
          onChange={(e) =>
            setDataCar({
              ...dataCar,
              category_vehicles: e.target.value,
            })
          }
        />

        {/* ADD VEHICLE BUTTON */}
        {/* <div className="first-section__add-button" onClick={() => setShowAddBrandCar(true)}>
          <span className="circle">
            <PlusIcon />
          </span>
          <p>Tambah Merk Mobil</p>
        </div> */}
      </div>

      {/* SECOND SECTION */}
      <div className="second-section">
        {/* RULES */}
        <div className="second-section__rules">
          {rules.map((item) => (
            <div className="checkbox-input" key={item.id}>
              <div className="checkbox-input__box">
                <input type="checkbox" name={item.id} id={item.id} onChange={handleCheck} checked={dataCar[item.id]} />
                <div className={item.id && dataCar[item.id] ? 'checkmark show' : 'checkmark'}>
                  <Check />
                </div>
              </div>
              <label htmlFor={item.id} className="checkbox-input__label">
                {item.icon}
                {item.label}
              </label>
            </div>
          ))}
        </div>

        {/* AVAILABILITY OF DRIVERS */}
        <div className="second-section__drivers-availability">
          {driversAvailability.map((item) => (
            <div key={item.id} className="radio-input">
              <div className="radio-input__box">
                <input
                  type="checkbox"
                  id={item.id}
                  value={item.id}
                  onChange={handleRadio}
                  // disabled={
                  //   item.id === 'tanpa-supir' && !withDriver && withoutDriver
                  //     ? true
                  //     : item.id === 'dengan-supir' && withDriver && !withoutDriver
                  //     ? true
                  //     : false
                  // }
                  checked={item.id === 'tanpa-supir' ? withoutDriver : withDriver}
                />
                <div
                  className={
                    item.id === 'tanpa-supir' && withoutDriver
                      ? 'checkmark show'
                      : item.id === 'dengan-supir' && withDriver
                      ? 'checkmark show'
                      : 'checkmark'
                  }
                >
                  <Check />
                </div>
              </div>
              <label htmlFor={item.id}>{item.label}</label>
            </div>
          ))}
        </div>

        {/* PRICES */}
        <div className="second-section__prices">
          {hasWithoutDriver && (
            <PricesInputField
              label="Harga Mobil Tanpa Supir"
              id="price"
              value={dataCar.price || ''}
              handleChange={handleChangePrice}
              disable={!withoutDriver}
              onWheel={numberInputOnWheelPreventChange}
            />
          )}

          {/* <PricesInputField
            label="Harga Mobil Tanpa Supir"
            id="price"
            value={dataCar.price || ''}
            handleChange={handleChangePrice}
            disable={!withoutDriver}
            onWheel={numberInputOnWheelPreventChange}
          /> */}

          {hasWithDriver && (
            <PricesInputField
              label="Harga Mobil Dengan Supir"
              id="price_with_driver"
              value={dataCar.price_with_driver || ''}
              handleChange={handleChangePrice}
              disable={!withDriver}
              onWheel={numberInputOnWheelPreventChange}
            />
          )}

          {/* <PricesInputField
            label="Harga Mobil Dengan Supir"
            id="price_with_driver"
            value={dataCar.price_with_driver || ''}
            handleChange={handleChangePrice}
            disable={!withDriver}
            onWheel={numberInputOnWheelPreventChange}
          /> */}

          {/* <div className="second-section__prices-driver--section">
            <PricesInputField
              label="Supir"
              id="driver_service"
              value={dataCar.driver_service}
              handleChange={handleChangePrice}
              disable={isFirstLoad && dataCar.driver_service === 0 ? true : !withDriver ? true : false}
            />
            <PricesInputField
              label="Makan"
              id="meal_driver"
              value={dataCar.meal_driver}
              handleChange={handleChangePrice}
              disable={isFirstLoad && dataCar.meal_driver === 0 ? true : !withDriver ? true : false}
            />
            <PricesInputField
              label="Bensin"
              id="gasoline"
              value={dataCar.gasoline}
              handleChange={handleChangePrice}
              disable={isFirstLoad && dataCar.gasoline === 0 ? true : !withDriver ? true : false}
            />
          </div> */}
          <PricesInputField
            label="Slash Price"
            id="slash_price"
            value={dataCar.slash_price || ''}
            handleChange={handleChangePrice}
            onWheel={numberInputOnWheelPreventChange}
          />
        </div>
      </div>

      {/* THIRD SECTION */}
      <div className="third-section">
        {/* STATUS CAR */}
        <SelectField
          label="Status Mobil"
          type="text"
          data={status}
          htmlFor="status-mobil"
          placeholder="Ready"
          name="status-mobil"
          value={dataCar.status === 'available' ? 1 : dataCar.status === 'booked' ? 2 : 3}
          onChange={(e) =>
            setDataCar({
              ...dataCar,
              status: e.target.value === '1' ? 'available' : e.target.value === '2' ? 'booked' : 'locked',
            })
          }
        />

        {/* PLAT NO */}
        <div className="third-section__plat">
          <InputField
            label="No. Plat"
            maxLength="2"
            value={codeArea.toUpperCase() || ''}
            onChange={(e) => setCodeArea(e.target.value)}
            placeholder="Contoh : DK"
          />
          <InputField
            type="number"
            onChange={(e) => {
              if (e.target.value.length > 4) return;
              setCarNumber(e.target.value || '');
            }}
            value={carNumber}
            placeholder="1234"
            onWheel={numberInputOnWheelPreventChange}
          />
          <InputField
            maxLength="3"
            value={codeLetters.toUpperCase() || ''}
            onChange={(e) => setCodeLetters(e.target.value)}
            placeholder="HY"
          />
        </div>

        {/* SEATS & CASE */}
        <div className="third-section__seats">
          <InputField
            label="Jumlah Kursi"
            value={dataCar.max_passanger}
            type="number"
            onChange={(e) => {
              if (e.target.value.length > 2) return;
              setDataCar({ ...dataCar, max_passanger: parseInt(e.target.value) });
            }}
            placeholder="0"
            onWheel={numberInputOnWheelPreventChange}
          />
          {/* <InputField
            label="Minimal Koper"
            value={dataCar.min_suitcase}
            type="number"
            onChange={(e) => {
              if (e.target.value.length > 1) return;
              setDataCar({ ...dataCar, min_suitcase: parseInt(e.target.value) });
            }}
          /> */}
          <InputField
            label="Maksimal Koper"
            value={dataCar.max_suitcase}
            type="number"
            onChange={(e) => {
              if (e.target.value.length > 2) return;
              setDataCar({ ...dataCar, max_suitcase: parseInt(e.target.value) });
            }}
            placeholder="0"
            onWheel={numberInputOnWheelPreventChange}
          />
        </div>

        {/* TRANSMISSION */}
        <div className="third-section__transmission">
          <SelectField
            label="Transmisi"
            type="text"
            data={transmission}
            htmlFor="transmisi-mobil"
            name="transmisi-mobil"
            value={dataCar.transmission === 'manual' ? 1 : 2}
            placeholder="Manual"
            onChange={(e) => setDataCar({ ...dataCar, transmission: e.target.value == '1' ? 'manual' : 'matic' })}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ContentLeft);
