import React, { useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import clsx from 'clsx';
import ClickOutside from 'components/Global/ClickOutside';
import { ReactComponent as Calendar } from '../../../../icons/calendar-today-blue.svg';
import { useDispatch } from 'react-redux';
import { addDays, endOfWeek, format, getDaysInMonth, startOfWeek } from 'date-fns';

const css = `
  .my-selected:not([disabled]) { 
    font-weight: bold; 
    background-color: black;
    color: white;
  }
  .my-selected:hover([disabled]) { 
    color: currentColor;
    border: 1px solid #000;
  }
`;

const filterList = [
  {
    id: 1,
    name: 'Hari ini',
  },
  {
    id: 2,
    name: 'Minggu',
  },
  {
    id: 3,
    name: 'Bulan',
  },
  {
    id: 4,
    name: 'Tahun',
  },
];

const CardDashboardItem = ({
  className,
  title,
  value,
  style,
  filter,
  filterAlign = 'start',
  filterPosition = 'bottom',
  customFilterPosition = 'vertical',
  separator,
  children,
  filterAction,
  asChild = false,
}) => {
  const dispatch = useDispatch();

  const [isOpenDropdownFilter, setIsOpenDropdownFilter] = useState(false);
  const [isOpenCustomDate, setIsOpenCustomDate] = useState(false);

  const [valueState, setValueState] = useState(0);

  const [filterValue, setFiltervalue] = useState('Tahun');
  const [prevFilterValue, setPrevFilterValue] = useState(null);

  const [days, setDays] = useState({ from: undefined, to: undefined });
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');

  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const toggleDropdown = () => {
    setIsOpenDropdownFilter(!isOpenDropdownFilter);
    setIsOpenCustomDate(false);
    setIsFilterClicked(true);
  };

  const chooseFilter = (filter) => {
    setFiltervalue(filter.name);
    setIsOpenDropdownFilter(!isOpenDropdownFilter);
    setIsOpenCustomDate(false);

    if (!filterAction) return;

    let startDate = '';
    let endDate = '';

    if (filter.name === 'Hari ini') {
      startDate = format(new Date(), 'yyyy-MM-dd');
      endDate = format(new Date(), 'yyyy-MM-dd');

      dispatch(filterAction({ startDate, endDate }));
    } else if (filter.name === 'Minggu') {
      const today = new Date();
      const startOfThisWeek = startOfWeek(today);
      const endOfThisWeek = endOfWeek(today);

      startDate = format(startOfThisWeek, 'yyyy-MM-dd');
      endDate = format(endOfThisWeek, 'yyyy-MM-dd');

      dispatch(filterAction({ startDate, endDate }));
    } else if (filter.name === 'Bulan') {
      const thisMonthIndex = new Date().getMonth();
      const thisYear = new Date().getFullYear();
      const totalDate = getDaysInMonth(new Date(thisYear, thisMonthIndex));

      const thisMonth = thisMonthIndex + 1 < 10 ? `0${thisMonthIndex + 1}` : `${thisMonthIndex + 1}`;

      startDate = `${thisYear}-${thisMonth}-01`;
      endDate = format(addDays(new Date(`${thisYear}-${thisMonth}-01`), totalDate), 'yyyy-MM-dd');

      dispatch(filterAction({ startDate, endDate }));
    } else if (filter.name === 'Tahun') {
      dispatch(filterAction({ startDate: '', endDate: '' }));
    }
  };

  const customFilterHandler = () => {
    setIsOpenCustomDate(!isOpenCustomDate);
    setFiltervalue('Custom');
  };

  const chooseDate = (date) => {
    setDays(date);
    setCustomStartDate(date.from);
    setCustomEndDate(date.to);
  };

  useEffect(() => {
    if (!value && value !== 0) return;
    if (!isFilterClicked && prevFilterValue !== null) return;

    setValueState(value);
    setPrevFilterValue(filterValue);
    setIsFilterClicked(false);
    setIsLoading(false);
  }, [value]);

  useEffect(() => {
    if (!children) return;

    setIsLoading(false);
  }, [children]);

  useEffect(() => {
    if (!filterAction) return;
    if (!customStartDate || !customEndDate) return;

    const startDate = format(new Date(customStartDate), 'yyyy-MM-dd');
    const endDate = format(new Date(customEndDate), 'yyyy-MM-dd');

    dispatch(filterAction({ startDate, endDate }));
  }, [customStartDate, customEndDate]);

  if (isLoading) {
    return (
      <div className="card-dashboard-item-skeleton">
        <div className={`card-dashboard-item-skeleton__header ${separator ? 'separator' : ''}`}>
          <div className="title-skeleton" />
          {filter && <div className="filter-skeleton" />}
        </div>

        {!children && !asChild && <div className="card-dashboard-item-skeleton__value" />}

        {children && asChild && <div className="card-dashboard-item-skeleton__content" />}
      </div>
    );
  }

  return (
    <div className={clsx(className, 'card-dashboard-item')} style={style}>
      <div className={`card-dashboard-item__header ${separator ? 'separator' : ''}`}>
        <h2 className={`title ${separator ? 'bold' : ''}`}>{title}</h2>

        {filter && (
          <div className="dropdown">
            <ClickOutside onClickOutside={() => setIsOpenDropdownFilter(false)}>
              <div className="dropdown__filter" onClick={toggleDropdown}>
                <Calendar />
                <span className="dropdown__filter__value">{filterValue}</span>
              </div>

              {isOpenDropdownFilter && (
                <div className={`dropdown__list ${filterAlign} ${filterPosition}`}>
                  <ul className="list-item">
                    {filterList.map((item) => (
                      <li key={item.id} role="button" onClick={() => chooseFilter(item)}>
                        <Calendar />
                        <span>{item.name}</span>
                      </li>
                    ))}
                  </ul>

                  <div className="custom-filter">
                    <ClickOutside onClickOutside={() => setIsOpenCustomDate(false)}>
                      <div className="button" onClick={customFilterHandler}>
                        Custom
                      </div>

                      {isOpenCustomDate && (
                        <>
                          <style>{css}</style>
                          <DayPicker
                            selected={days}
                            numberOfMonths={2}
                            onSelect={chooseDate}
                            mode="range"
                            className={`custom-filter__dropdown ${filterAlign} ${
                              filterPosition === 'top' && customFilterPosition === 'vertical'
                                ? 'vertical-top'
                                : filterPosition === 'bottom' && customFilterPosition === 'vertical'
                                ? 'vertical-bottom'
                                : filterPosition === 'top' && customFilterPosition === 'vertical-reverse'
                                ? 'vertical-reverse-top'
                                : filterPosition === 'bottom' && customFilterPosition === 'vertical-reverse'
                                ? 'vertical-reverse-bottom'
                                : customFilterPosition
                            }`}
                            modifiersClassNames={{
                              selected: 'my-selected',
                              today: 'my-today',
                            }}
                            styles={{
                              caption_label: { fontSize: '16px' },
                              caption: { marginBottom: '33px' },
                              head: { color: '#8E8C9A' },
                              head_cell: { textTransform: 'capitalize' },
                            }}
                          />
                        </>
                      )}
                    </ClickOutside>
                  </div>
                </div>
              )}
            </ClickOutside>
          </div>
        )}
      </div>

      {!children && !asChild && (value || value === 0) && <p className="card-dashboard-item__value">{valueState}</p>}

      {children && asChild && <div className="card-dashboard-item__content">{children}</div>}
    </div>
  );
};

export default React.memo(CardDashboardItem);
