import React from 'react';
import clsx from 'clsx';

const Button = ({
  children,
  className,
  size,
  wfull, // to set button width=100%
  disabled,
  isLoading,
  variant,
  style,
  textColor, // text color
  bgColor, // background color
  borderColor, // border color
  width,
  height,
  ...rest
}) => {
  const BUTTON_VARIANT = {
    primary: 'btn--primary',
    success: 'btn--success',
    danger: 'btn--danger',
    outline: 'btn--outline--dark',
  };

  const buttonSizes = (size) => {
    switch (size) {
      case 'xs': {
        return 'btn--xs'; //button height: 36px font-size: 12px
      }
      case 'sm': {
        return 'btn--sm'; //button height: 39px font-size: 12px
      }
      case 'md': {
        return 'btn--md';
      }
      case 'lg': {
        return 'btn--lg'; //button height: 44px font-size: 14px
      }
      // default: {
      //   return "";
      // }
    }
  };

  const variantClassName = BUTTON_VARIANT[variant || 'primary'];
  const buttonClassName = clsx('btn', variantClassName, buttonSizes(size), wfull && 'w-full', className);

  const buttonStyle = {
    width: width,
    height: height,
    color: textColor,
    background: bgColor,
    outlineColor: borderColor,
    ...style,
  };

  return (
    <button className={buttonClassName} disabled={isLoading ?? disabled} style={buttonStyle} {...rest}>
      {children}
    </button>
  );
};

export default Button;
