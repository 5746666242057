import clsx from 'clsx';
import React from 'react';
import { useState, useEffect } from 'react';
import { ReactComponent as Calendar } from '../../../icons/calendar-icon.svg';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import ClickOutside from '../ClickOutside';

const css = `
  .my-selected:not([disabled]) { 
    font-weight: bold; 
    background-color: black;
    color: white;
  }
  .my-selected:hover([disabled]) { 
    color: currentColor;
    border: 1px solid #000;
  }
`;

const SelectFieldDate = ({
  label,
  name,
  htmlFor,
  value, // state
  className,
  style,
  placeholder,
  fromDate,
  selectedDay,
  handleDaySelect,
  dateContainerXOffset = 'right',
  disable,
  defaultMonth,
  ...rest
}) => {
  // SHOW DROPDOWN FUNCTION
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [isDateChoosed, setIsDateChoosed] = useState(false);

  // FUNCTION TO HANDLE CHOOSE DATE
  const onChooseDate = (date) => {
    handleDaySelect(date);
    setIsDateChoosed(true);
  };

  // CLOSE THE DROPDOWN AFTER CLICK DATE
  useEffect(() => {
    isDateChoosed && setIsShowDropdown(false);
  }, [isDateChoosed]);

  return (
    <div className={clsx('select-field-date', className)} style={{ width: '100%' }}>
      {label && (
        <label
          htmlFor={htmlFor}
          className="select-field-date__label"
          style={{ pointerEvents: disable ? 'none' : 'auto' }}
        >
          {label}
        </label>
      )}
      <div
        className={disable ? 'select-field-date__input-field disable' : 'select-field-date__input-field'}
        style={{ width: '100%' }}
      >
        <Calendar className="select-field-date__input-field__calendar" />
        <input
          id={htmlFor}
          type="text"
          placeholder={placeholder}
          name={name}
          style={style}
          disabled={disable}
          className="select-field-date__input-field__input"
          value={value}
          onFocus={() => {
            setIsShowDropdown(true);
            setIsDateChoosed(false);
          }}
          readOnly
          {...rest}
        />
      </div>
      {isShowDropdown && (
        <ClickOutside onClickOutside={() => setIsShowDropdown(false)}>
          <style>{css}</style>
          <DayPicker
            fromDate={fromDate}
            selected={selectedDay}
            numberOfMonths={2}
            onSelect={onChooseDate}
            mode="single"
            className={`select-field-date__dropdown ${dateContainerXOffset}`}
            modifiersClassNames={{
              selected: 'my-selected',
              today: 'my-today',
            }}
            styles={{
              caption_label: { fontSize: '16px' },
              caption: { marginBottom: '33px' },
              head: { color: '#8E8C9A' },
              head_cell: { textTransform: 'capitalize' },
            }}
            defaultMonth={defaultMonth}
          />
        </ClickOutside>
      )}
    </div>
  );
};

export default SelectFieldDate;
