import React from 'react';
import Tabs, { TabPane } from 'components/Global/Tabs';
import PromoList from './PromoList';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import History from './History';
import ButtonAdd from 'components/Global/ButtonAdd';

const PromoComponent = () => {
  const navigate = useNavigate();

  // LOCAL STATE
  const [activeTab, setActiveTab] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  // GET THE PARAMS TAB ACTIVE WHEN PAGE RELOAD
  const tabActive = searchParams.get('tab-active');

  const handleAdd = () => {
    navigate(`/promo/add-item?tab=${tabActive}`);
  };

  // SET ACTIVE TAB WHEN PAGE RELOAD
  useEffect(() => {
    if (!tabActive) {
      setSearchParams({ 'tab-active': 'Promo' });
    } else {
      setActiveTab(tabActive);
    }
  }, [tabActive]);

  return (
    <div className="promo">
      {/* TABLE */}
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
        <TabPane label="Promo">
          <PromoList />
        </TabPane>
        <TabPane label="History">
          <History />
        </TabPane>
      </Tabs>

      {/* BUTTON */}
      {activeTab === 'History' ? null : <ButtonAdd onClick={handleAdd} label={`Tambah ${activeTab}`} />}
    </div>
  );
};

export default PromoComponent;
