import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllPermissions = createAsyncThunk('permissions/getAllPermissions', async (_, thunkAPI) => {
  const access_token = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/permissions`, {
      headers: { Authorization: `Bearer ${access_token}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Assign Role Permission == Create New Main Menu
export const createNewPermission = createAsyncThunk(
  'permissions/createNewPermission',
  async ({ permission_name }, thunkAPI) => {
    const access_token = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/${API_VERSION}/permissions`,
        { permission_name },
        {
          headers: { Authorization: `Bearer ${access_token}` },
        },
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

// Assign Role Permission == Create New Sub Menu
export const assignRolePermission = createAsyncThunk('permissions/assignRolePermission', async (payload, thunkAPI) => {
  const access_token = thunkAPI.getState().auth.data.access_token;
  const { role_id, permission_action } = payload;

  try {
    const response = await axios.post(
      `${BASE_URL}/${API_VERSION}/role-permissions`,
      { role_id, permission_action },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      },
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateAssignRolePermission = createAsyncThunk(
  'permissions/updateAssignRolePermission',
  async (payload, thunkAPI) => {
    const access_token = thunkAPI.getState().auth.data.access_token;
    const { role_id, action_permission } = payload;

    try {
      const response = await axios.put(
        `${BASE_URL}/${API_VERSION}/role-permissions/${role_id}`,
        { role_id, action_permission },
        {
          headers: { Authorization: `Bearer ${access_token}` },
        },
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getRolePermissionsById = createAsyncThunk('permissions/getRolePermissionsById', async (id, thunkAPI) => {
  const access_token = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/roles/${id}/permissions`, {
      headers: { Authorization: `Bearer ${access_token}` },
    });

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
