import React, { useEffect, useState } from 'react';
import ToastPortal from './toast-portal';
import Toast from './toast';
import { TransitionGroup } from 'react-transition-group';
import ToastWrapper from './toast-wrapper';
import { useAppContext } from 'components/Context/AppContext';

const ToastList = () => {
  const { toastList, setToastList } = useAppContext();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (toastList.length > 0) {
      setShow(true);
      if (toastList.length > 2) {
        setToastList((toasts) => toasts.slice(1));
      }
      const timer = setTimeout(() => {
        setToastList((toasts) => toasts.slice(1));
        // console.log(toastList);
      }, 3000);

      return () => clearTimeout(timer);
    }
    setShow(false);
  }, [setToastList, toastList]);

  // useEffect(() => {
  //   console.log(toastList);
  //   console.log(show);
  // }, [toastList, show]);

  const controls = toastList.map((toast) => (
    <ToastWrapper
      key={toast.id}
      id={toast.id}
      onClick={(e) => setToastList(toastList.filter((toast) => toast.id !== e.currentTarget.dataset.id))}
      mountOnEnter
      unmountOnExit
      in={show}
    >
      <Toast message={toast.payload.message} type={toast.payload.type} />
    </ToastWrapper>
  ));

  return (
    <ToastPortal>
      <TransitionGroup className="toast-container">{controls}</TransitionGroup>
    </ToastPortal>
  );
};

export default ToastList;
