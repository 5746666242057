import { useAppContext } from 'components/Context/AppContext';
import { Table, TableWrapper } from 'components/Global';
import PaginationTable from 'components/Global/Pagination';
import { ReactComponent as PeopleIcon } from 'icons/people-filled-icon.svg';
import React, { useState } from 'react';
import TourDetailComponent from './TourDetail';
import UnderDevelopment from 'components/Global/UnderDevelopment';
import ButtonAdd from 'components/Global/ButtonAdd';

const TourComponent = () => {
  const { setShowAddTourSchedule } = useAppContext();
  const [isDetail, setIsDetail] = useState(false);
  const column = [
    { header: 'No.', value: 'no' },
    { header: 'Nama Tour', value: 'nama' },
    { header: 'Layanan', value: 'jenis' },
    { header: 'Detail', value: 'detail' },
    { header: 'Destinasi', value: 'destinasi' },
    { header: 'Jumlah Hari', value: 'jumlah_hari' },
  ];

  const tableData = [
    {
      jenis: 'With Driver + Tour Guide',
      nama: 'Tour A..',
      detail: 'Jumlah Hari.....',
      destinasi: 'Hari Ke-1 Tempat A - 09:00 AM - 12 : 00 AM',
      jumlah_hari: 3,
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(0 / 10);

  return (
    <UnderDevelopment>
      <div className="tour">
        {isDetail ? (
          <TourDetailComponent setIsDetail={setIsDetail} />
        ) : (
          <>
            <ButtonAdd onClick={() => setShowAddTourSchedule(true)} label="Tambah Tour" />

            <TableWrapper icon={<PeopleIcon fill="#FF9900" width="25px" height="25px" />} title="Tour Schedule" filter>
              <Table column={column} data={tableData} actionBtn onDetail={() => setIsDetail(true)} onDel={() => {}} />
              <PaginationTable
                currentPage={currentPage}
                onPageChange={(newPage) => setCurrentPage(newPage)}
                pageCount={pageCount}
              />
            </TableWrapper>
          </>
        )}
      </div>
    </UnderDevelopment>
  );
};

export default TourComponent;
