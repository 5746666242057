import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import Modals from '../Modals';
import { Button, InputField } from 'components/Global';
import { ReactComponent as AirportTransferCarFilledIcon } from 'icons/airport-transfer-car-filled-icon.svg';
import {
  createAirportLocationType,
  getAllAirportLocationType,
  getDetailAirportLocationType,
  updateAirportLocationType,
} from 'features/airport-location-type/actions';

const AddAirportLocationTypeModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setShowAddAirportLocationType, showToast } = useAppContext();

  const { selectedLocationType } = useSelector((state) => state.airportLocationType);

  const [inputValue, setInputValue] = useState('');
  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');

  const changeInputHandler = (e) => {
    setInputValue(e.target.value);
  };

  const backHandler = () => {
    setShowAddAirportLocationType(false);
    navigate('/airport-transfer-car?tab-active=Tipe Daerah');
  };

  const setStateHandler = (state) => {
    setShowAddAirportLocationType(state);
    navigate('/airport-transfer-car?tab-active=Tipe Daerah');
  };

  const sendPayloadData = async (action, payload, fulfilledMessage, errorMessage1) => {
    try {
      action === 'ADD'
        ? await dispatch(createAirportLocationType({ name: inputValue })).unwrap()
        : action === 'UPDATE'
        ? await dispatch(updateAirportLocationType({ airportLocationTypeId: id, payloadData: payload })).unwrap()
        : () => {};

      showToast({ type: 'success', message: fulfilledMessage });
      dispatch(getAllAirportLocationType({ limit: 10, page: 1 }));
      setShowAddAirportLocationType(false);
      navigate('/airport-transfer-car?tab-active=Tipe Daerah');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: `${errorMessage1}: ${error}` });
    }
  };

  const handleSubmit = async () => {
    const validValue = inputValue.replace(' ').length !== 0;

    if (!validValue) {
      return showToast({ type: 'error', message: 'Masukan Nama Tipe Daerah' });
    }

    if (id) {
      sendPayloadData('UPDATE', { name: inputValue }, 'Berhasil Update Tipe Daerah', 'Gagal Update Tipe Daerah');
    } else {
      sendPayloadData('ADD', { name: inputValue }, 'Berhasil Tambah Tipe Daerah', 'Gagal Tambah Tipe Daerah');
    }
  };

  useEffect(() => {
    if (!id) return;

    dispatch(getDetailAirportLocationType(id));
  }, [id]);

  useEffect(() => {
    if (!selectedLocationType || !Object.keys(selectedLocationType).length || !id) return;

    setInputValue(selectedLocationType.name);
  }, [selectedLocationType, id]);

  return (
    <Modals
      setState={setStateHandler}
      title="Tambah Nama Tipe Daerah"
      icon={<AirportTransferCarFilledIcon fill="#009EF7" width="25px" height="25px" />}
    >
      <div className="airport-location-type-modal">
        <InputField
          label="Nama Tipe Daerah"
          htmlFor="nama-tipe-daerah"
          placeholder="Ketik Nama Tipe Daerah"
          value={inputValue}
          onChange={changeInputHandler}
        />

        <div className="airport-location-type-modal__button-field">
          <Button size="sm" variant="outline" width="192px" onClick={backHandler}>
            Kembali
          </Button>
          <Button type="button" size="sm" width="192px" onClick={handleSubmit}>
            Simpan
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default AddAirportLocationTypeModal;
