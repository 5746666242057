import { PaginationTable, Table, TableWrapper } from 'components/Global';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { useState } from 'react';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { deleteVehicle, getVehicleById, getVehiclesByFilter } from 'features/vehicle/action';
import { useEffect } from 'react';
import EmptyState from 'components/Global/EmptyState';
import useDebounce from 'utils/useDebounce';
import LoadingSpinner from 'components/Global/LoadingSpinner';
import VehiclesFilter from 'components/Global/VehiclesFilter';
import VehiclesSort from 'components/Global/VehiclesSort';
import { useNavigate } from 'react-router-dom';
import { getAllBrands } from 'features/brands/actions';
import { getAllGarages } from 'features/garages/actions';
import ButtonAdd from 'components/Global/ButtonAdd';

// SORT ARRAY
const sortArray = [
  {
    id: 'sar1',
    name: 'Name ( A - Z )',
    value: 'asc',
    type: 'name_sort',
  },
  {
    id: 'sar2',
    name: 'Name ( Z - A )',
    value: 'desc',
    type: 'name_sort',
  },
  {
    id: 'sar3',
    name: 'Price (Lowest)',
    value: 'asc',
    type: 'sort',
  },
  {
    id: 'sar4',
    name: 'Price (Highest)',
    value: 'desc',
    type: 'sort',
  },
];

const VehicleComponent = () => {
  const navigate = useNavigate();
  const { showToast, setShowConfirmation } = useAppContext();
  const { vehicles, pagination } = useSelector((state) => state.vehicle?.data);
  const { isError, errorMessage, isLoading } = useSelector((state) => state.vehicle);
  const { data: brandData } = useSelector((state) => state.brands);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const searchValueDebounce = useDebounce(search, 1000);

  const [arraySort, setArraySort] = useState([]);
  const [sortValue, setSortValue] = useState('');

  const [arrayBrands, setArrayBrands] = useState([]);
  const [filterBrandValue, setFilterBrandValue] = useState([]);

  const column = [
    { header: 'No.', value: 'no' },
    { header: 'Merek Mobil', value: 'brand_name' },
    { header: 'Nama Mobil', value: 'name' },
    { header: 'Status Mobil', value: 'status' },
    { header: 'No. Plat', value: 'license_number' },
    { header: 'Jumlah Seat', value: 'max_passanger' },
    { header: 'Jumlah Koper', value: 'max_suitcase' },
    { header: 'Additional', value: 'additional' },
  ];

  const handleDetail = (e) => {
    const vehicleId = e.target.parentElement.parentElement.dataset['key'];
    dispatch(getVehicleById(vehicleId));
    navigate(`detail/${vehicleId}`);
  };

  const handleDelete = (e) => {
    const vehicleId = e.target.parentElement.parentElement.dataset['key'];
    setShowConfirmation({
      message: 'Apakah anda yakin ingin menghapus Mobil',
      show: true,
      onClick: async () => {
        try {
          await dispatch(deleteVehicle(vehicleId)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Menghapus Data Mobil' });
          setCurrentPage(1);
        } catch {
          showToast({ type: 'error', message: 'Gagal Menghapus Data Mobil!' });
        }
      },
    });
  };

  const handleSort = (e) => {
    const value = e.target.value;

    if (value) {
      const copyArraySort = [...arraySort];
      const choosenSort = copyArraySort.find((item) => item.id === value);
      const newSort = copyArraySort.map((item) =>
        item.id === value ? { ...item, checked: true } : { ...item, checked: false },
      );

      setArraySort(newSort);
      setSortValue(choosenSort);
    }
  };

  const handleFilterBrand = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (value) {
      if (checked) {
        const copyArrayBrands = [...arrayBrands];
        const filterBrand = copyArrayBrands.find((item) => item.id == value);
        filterBrand.checked = checked;
        setArrayBrands(copyArrayBrands);

        setFilterBrandValue((prevState) => [...prevState, filterBrand.id]);
      } else {
        const copyArrayBrands = [...arrayBrands];
        const filterBrand = copyArrayBrands.find((item) => item.id == value);
        filterBrand.checked = checked;
        setArrayBrands(copyArrayBrands);

        const filteredBrandValue = filterBrandValue.filter((item) => item != value);
        setFilterBrandValue(filteredBrandValue);
      }
    }
  };

  useEffect(() => {
    dispatch(getAllBrands());
    dispatch(getAllGarages());
    // dispatch(getSubservices());
  }, []);

  useEffect(() => {
    if (searchValueDebounce || sortValue || filterBrandValue.length !== 0) {
      setCurrentPage(1);
      dispatch(
        getVehiclesByFilter({
          page: 1,
          limit: 10,
          // supportDriver: true,
          name: searchValueDebounce ? searchValueDebounce : undefined,
          brand: filterBrandValue,
          [sortValue.type]: sortValue.value,
          maxPrice: 0,
        }),
      );
      return;
    }
    setCurrentPage(1);
    dispatch(
      getVehiclesByFilter({
        page: 1,
        limit: 10,
        // supportDriver: true,
        name: searchValueDebounce ? searchValueDebounce : undefined,
        brand: filterBrandValue,
        [sortValue.type]: sortValue.value,
        maxPrice: 0,
      }),
    );
  }, [searchValueDebounce, sortValue, filterBrandValue]);

  useEffect(() => {
    dispatch(
      getVehiclesByFilter({
        page: currentPage,
        limit: 10,
        // supportDriver: true,
        name: searchValueDebounce ? searchValueDebounce : undefined,
        brand: filterBrandValue,
        [sortValue.type]: sortValue.value,
        maxPrice: 0,
      }),
    );
  }, [currentPage]);

  useEffect(() => {
    if (!isError) return;

    try {
      if (Object?.prototype?.hasOwnProperty?.call(errorMessage, 'slug')) {
        showToast({ type: 'error', message: errorMessage.slug });
      } else {
        showToast({ type: 'error', message: 'Terjadi Kesalahan' });
      }
    } catch (error) {
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }
  }, [isError, errorMessage]);

  useEffect(() => {
    const newArraySort = sortArray.map((item) => {
      return { ...item, checked: false };
    });
    setArraySort(newArraySort);
  }, [sortArray]);

  useEffect(() => {
    if (brandData.length === 0) return;

    const newArrayBrands = brandData.map((item) => {
      return { ...item, checked: false };
    });
    setArrayBrands(newArrayBrands);
  }, [brandData]);

  return (
    <div className="vehicle">
      <div>
        <ButtonAdd onClick={() => navigate('add-vehicle')} label="Tambah Mobil" />

        <TableWrapper
          icon={<CarIcon fill="#009EF7" width="25px" height="25px" />}
          title="Mobil"
          CustomFilterComponent={
            <VehiclesFilter placeholder="Filter By Brand" data={arrayBrands} onChange={handleFilterBrand} />
          }
          CustomSortComponent={<VehiclesSort placeholder="Sort By" data={arraySort} onChange={handleSort} />}
          filter
          searchPlaceholder="Search example (Daihatsu Xenia)"
          search={search}
          setSearch={setSearch}
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : vehicles === null || (vehicles == [] && vehicles.length === 0) ? (
            <EmptyState />
          ) : (
            <>
              <Table
                column={column}
                data={vehicles}
                actionBtn
                onDetail={handleDetail}
                onDel={handleDelete}
                currentPage={currentPage}
              />
              <PaginationTable
                currentPage={currentPage}
                totalCount={pagination?.total_data}
                onPageChange={(newPage) => setCurrentPage(newPage)}
              />
            </>
          )}
        </TableWrapper>
      </div>
    </div>
  );
};

export default VehicleComponent;
